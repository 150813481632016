import { gray, selectedTabGreen, URL, sortLogic, collegeLogos } from "../dictionary"
import { DetailsOverlay } from "./DetailsOverlay"
import { useState } from "react"
import { AggregateRecommendation } from "./AggregateRecommendation"
import { SaveButtonCollegeCard } from "../SaveButton"
import { TooltipComponent } from "../Tooltip"
import { TestScoreStrategyReactGAHandlers } from "../ReactGAHandlers"
import { LoadingIndicator } from "../LoadingIndicator"

const Cards = ({ results, setResults, collegesCDS, setCollegesCDS, collegeListLength, setCollegeOptions, sortOrder, sortKey, sortKeys, sortKeyReturn, handleSortKeyChange, handleSortOrderChange, userSATVerbal, userSATMath, userSATSuperScore, userActEnglish, userActMath, userActWriting, userActScience, userActReading, userACTBestScore, setUserSATVerbal, setUserSATMath, setUserSATSuperScore, setUserACTBestScore, setUserActEnglish, setUserActMath, setUserActWriting, setUserActScience, setUserActReading, returnUserSATScoreOutput, returnUserACTScoreOutput, setUserprofile, user, userCollegeInterest, setUserCollegeInterest, showOnlyMyInterestsFilter, setFilteredResults, setRequiredCount, setOptionalCount, setBlindCount, collegeFilter, collegeSetFilter, policyFilter, satFilter, actFilter, showLength, setShowLength, resultsFull }) => {

  const [modalData, setModalData] = useState<any>([])

  const { handleGAEventOpenOverlay } = TestScoreStrategyReactGAHandlers()

  const testPolicyReturn = (val) => {
    switch (val) {
      case 1:
        return 'Required'
      case 2:
        return 'Optional'
      case 3:
        return 'Blind'
      default:
        return '-'
    }
  }

  const admissionReturn = (testScores, testPolicy) => {

    // if testPolicy == 3 is override with:
    if (testPolicy == 3) {
      return 'N/A'
    }

    // enum file CDS Data Section C7
    switch (true) {
      case testScores === 0:
        return 'Very Important'
      case testScores === 1:
        return 'Important'
      case testScores === 2:
        return 'Considered'
      case testScores === 3:
        return 'Not Considered'
      default:
        return '-'
    }
  }

  const returnAdmissionConsiderationTooltip = (colName, colData, testPolicy) => {

    if (testPolicy == 3) {
      return (
        <div>{colName} has a test blind policy so therefore test scores are not considered for admissions.</div>
      )
    }

    const convertGPA = (gpa) => {
      switch (true) {
        case gpa == 0:
          return 'Very Important'
        case gpa == 1:
          return 'Important'
        case gpa == 2:
          return 'Considered'
        case gpa == 3:
          return 'Not Considered'
      }
    }

    let first = `This is ${colName}'s stated importance of standardized test scores in admissions decisions.`

    let second = ``

    if ((colData.testScores != null || colData.testScores != undefined) && (colData.academicGPA != null || colData.academicGPA != undefined)) {

      if (colData.testScores < colData.academicGPA) {
        second = `In relative comparison, test scores are a more important admission consideration for ${colName} than grades (academic GPA), which is indicated as ${convertGPA(colData.academicGPA)}.`
      }

      if (colData.testScores == colData.academicGPA) {
        second = `In relative comparison, test scores are indicated to be equally important as grades (academic GPA) for admission consideration at ${colName}.`
      }

      if (colData.testScores > colData.academicGPA) {
        second = `In relative comparison, test scores are a less important admission consideration for ${colName} than grades (academic GPA), which is indicated as ${convertGPA(colData.academicGPA)}.`
      }

    }

    return (
      <div>{first} {second}</div>
    )
  }

  const scoreReturn = (val) => {
    if (val !== null) {
      return val
    }
    else {
      return '-'
    }
  }

  const [loading, setLoading] = useState(false)

  // const showMoreResultsLength = () => {
  //   let max = collegeListLength
  //   let end = max
  //   let current = results.length

  //   if (current + 25 < max) {
  //     end = current + 25
  //   }

  //   else {
  //     end = end
  //   }
  //   let res: any = collegesCDS

  //   res = res.filter(collegeFilter).filter(collegeSetFilter).filter(policyFilter).filter(satFilter).filter(actFilter).filter(showOnlyMyInterestsFilter)
  //   let requiredCount = res.filter((item) => item.testPolicy === 1).length
  //   let optionalCount = res.filter((item) => item.testPolicy === 2).length
  //   let blindCount = res.filter((item) => item.testPolicy === 3).length

  //   setRequiredCount(requiredCount)
  //   setOptionalCount(optionalCount)
  //   setBlindCount(blindCount)

  //   res = res.splice(0, end)

  //   return res.length
  // }

  const handleShowMore = (e) => {
    e.preventDefault();

    // let max = collegeListLength
    let max = resultsFull.length
    let end = max
    let current = results.length

    // if (current + 25 < max) {
    //   end = current + 25
    // }

    if (showLength + 25 < max) {
      end = showLength + 25
    }

    else {
      end = end
    }

    try {
      setLoading(true)

      setShowLength(end)

      // console.log(`requested to show more. ${current} to ${end}`)

      // const sortAndFilter = (collegeFilter, collegeSetFilter, policyFilter, satFilter, actFilter, sortOrder, sortKey) => {
      //   let res: any = collegesCDS

      //   if (collegesCDS.filter(showOnlyMyInterestsFilter).length === 0) {
      //     return setFilteredResults(false)
      //   }

      //   res = res.filter(collegeFilter).filter(collegeSetFilter).filter(policyFilter).filter(satFilter).filter(actFilter).filter(showOnlyMyInterestsFilter)

      //   if (res.length === 0) {
      //     return setFilteredResults(false)
      //   }

      //   if (sortKey === 'name') {
      //     sortOrder === 'Descending' ?
      //       res = res.sort((a, b) => sortLogic(b, a, sortKey, 'name')) :
      //       res = res.sort((a, b) => sortLogic(a, b, sortKey, 'name'))


      //     let requiredCount = res.filter((item) => item.testPolicy === 1).length
      //     let optionalCount = res.filter((item) => item.testPolicy === 2).length
      //     let blindCount = res.filter((item) => item.testPolicy === 3).length

      //     setRequiredCount(requiredCount)
      //     setOptionalCount(optionalCount)
      //     setBlindCount(blindCount)

      //     res = res.splice(0, end)

      //     setResults(res)
      //     setFilteredResults(true)
      //   }

      //   else {
      //     const expandedSortLogic = (a, b, sortKey) => {
      //       if (typeof a.college_cds[0][sortKey] === 'string' && typeof b.college_cds[0][sortKey] === 'string') {
      //         return a.college_cds[0][sortKey].localeCompare(b.college_cds[0][sortKey])
      //       }

      //       else {
      //         return a.college_cds[0][sortKey] - b.college_cds[0][sortKey]
      //       }
      //     }
      //     sortOrder === 'Descending' ?
      //       res = res.sort((a, b) => expandedSortLogic(b, a, sortKey)) :
      //       res = res.sort((a, b) => expandedSortLogic(a, b, sortKey))

      //     let requiredCount = res.filter((item) => item.testPolicy === 1).length
      //     let optionalCount = res.filter((item) => item.testPolicy === 2).length
      //     let blindCount = res.filter((item) => item.testPolicy === 3).length

      //     setRequiredCount(requiredCount)
      //     setOptionalCount(optionalCount)
      //     setBlindCount(blindCount)

      //     res = res.splice(0, end)

      //     setResults(res)
      //     setFilteredResults(true)
      //   }
      // }

      // sortAndFilter(collegeFilter, collegeSetFilter, policyFilter, satFilter, actFilter, sortOrder, sortKey);

    }

    catch (error) {
      throw (error)
    }

    finally {
      setLoading(false)
    }

  }

  const returnShowMoreButton = (loading) => {

    let loadingFalse = (
      <div className='d-flex justify-content-center'><button
        onClick={handleShowMore}
        style={{
          cursor: 'pointer',
          marginTop: '20px',
          fontFamily: 'ColfaxWebMedium',
          border: '1px solid',
          borderRadius: '20px',
          borderColor: '#ededed',
          backgroundColor: '#F9F9F9',
          padding: '10px'
        }}>Show more</button></div>
    )

    let loadingTrue = (
      <div className='d-flex justify-content-center'><button
        disabled
        style={{
          cursor: 'pointer',
          marginTop: '20px',
          fontFamily: 'ColfaxWebMedium',
          border: '1px solid',
          borderRadius: '20px',
          borderColor: '#ededed',
          backgroundColor: '#F9F9F9',
          padding: '10px'
        }}>Loading ...</button></div>
    )

    return loading === true ? loadingTrue : loadingFalse

  }

  const returnWhy = (userSATVerbal, userSATMath, userActEnglish, userActMath, userActWriting, userActScience, userActReading, userACTBestScore, res) => {

    let scores = [userSATVerbal, userSATMath, userActEnglish, userActMath, userActWriting, userActScience, userActReading, userACTBestScore]

    if (scores.every(score => score === null) && returnUserSATScoreOutput(userSATMath, userSATVerbal, res) === -1 && returnUserACTScoreOutput(userACTBestScore, res) === -1) {
      return <div></div>;
    }

    return <a className="dca-test-score-modal-why-text">Why?</a>
  }

  return results ? (
    <div className='dca-card-container'>

      {/* HEADER */}
      <div className="d-flex flex-row justify-content-between align-items-end">

        <div className="d-flex flex-row">
          <div className='me-3 align-bottom dca-card-items-found-text'>Displaying {results.length} colleges</div>

          <div
            onClick={handleSortOrderChange}
            style={{ cursor: 'pointer', fontSize: '15px', lineHeight: '18px', color: '#585555', marginBottom: '2px' }}
            className='text-muted fs-7 fw-bolder align-self-end'
          >by {sortOrder} {sortKeyReturn(sortKey)} {sortOrder === 'Ascending' && (<i className="bi bi-arrow-up"></i>)}
            {sortOrder === 'Descending' && (<i className="bi bi-arrow-down"></i>)}
          </div>
        </div>

        <select
          className='form-select w-25 align-bottom'
          data-control="select2"
          value={sortKey}
          onChange={handleSortKeyChange}
        >
          {sortKeys.map((res, id) => (
            <option key={id} value={Object.values(res)}>{Object.keys(res)}</option>
          ))}
        </select>
      </div>
      {/* END HEADER */}

      <div>{results.map((res) => (
        <div
          key={res.id}
          className="mt-5 hover-elevate-up dca-card-item">

          {user && userCollegeInterest && <SaveButtonCollegeCard
            list={userCollegeInterest}
            user={user}
            collegeId={res.id}
            setUserCollegeInterest={setUserCollegeInterest}
            testScoreStrategy={true}
          />}

          <div
            data-bs-toggle="modal"
            data-bs-target="#overlay"
            onClick={() => {
              setModalData(res);
              handleGAEventOpenOverlay(res.id)
            }}
            className="d-flex flex-column"
            style={{ cursor: 'pointer' }}
          >

            <div className="d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '15px' }}>
              <img
                style={{ width: '75px', height: '75px', margin: '10px 0 10px 0' }}
                alt="college_logo"
                src={collegeLogos[res.id]} />

              <div className="dca-card-item-nameField">{res.name}</div>

              <div className="d-flex flex-row justify-content-center align-items-center">
                <AggregateRecommendation
                  userSATVerbal={userSATVerbal}
                  userSATMath={userSATMath}
                  userSATSuperScore={userSATSuperScore}
                  userActEnglish={userActEnglish}
                  userActMath={userActMath}
                  userActWriting={userActWriting}
                  userActScience={userActScience}
                  userActReading={userActReading}
                  userACTBestScore={userACTBestScore}
                  setUserSATVerbal={setUserSATVerbal}
                  setUserSATMath={setUserSATMath}
                  setUserACTBestScore={setUserACTBestScore}
                  setUserActEnglish={setUserActEnglish}
                  setUserActMath={setUserActMath}
                  setUserActWriting={setUserActWriting}
                  setUserActScience={setUserActScience}
                  setUserActReading={setUserActReading}
                  userSATScoreOutput={returnUserSATScoreOutput(userSATMath, userSATVerbal, res)}
                  userACTScoreOutput={returnUserACTScoreOutput(userACTBestScore, res)}
                  res={res}
                  setUserprofile={setUserprofile}
                  setUserSATSuperScore={setUserSATSuperScore} />
                {returnWhy(userSATVerbal, userSATMath, userActEnglish, userActMath, userActWriting, userActScience, userActReading, userACTBestScore, res)}
              </div>

            </div>

            <div className="d-flex flex-row justify-content-evenly" style={{ margin: '37px 20px 20px 20px' }}>

              <div className="d-flex flex-column" style={{ margin: '0 15px 0 15px' }}>
                <div className='dca-card-item-header'>TEST POLICY</div>
                <div className="dca-card-item-text">{testPolicyReturn(res.testPolicy)}</div>
              </div>

              <div className="d-flex flex-column" style={{ marginRight: '15px' }}>
                <div className='dca-card-item-header'>ADMISSION CONSIDERATION</div>
                <div className="dca-card-item-text">

                  {admissionReturn(res.college_cds[0].testScores, res.testPolicy)}

                  <span className="ms-2"><TooltipComponent content={returnAdmissionConsiderationTooltip(res.name, res.college_cds[0], res.testPolicy)} /></span></div>
              </div>

              <div className="d-flex flex-column" style={{ marginRight: '15px' }}>
                <div className='dca-card-item-header'>SAT COMPOSITE SCORES</div>
                <div className='dca-card-item-header'>25 / 50 / 75%</div>
                <div className="dca-card-item-text">{scoreReturn(res.college_cds[0].satComposite25)} / {scoreReturn(res.college_cds[0].satComposite50)} / {scoreReturn(res.college_cds[0].satComposite75)}</div>
              </div>

              <div className="d-flex flex-column" style={{ marginRight: '15px' }}>
                <div className='dca-card-item-header'>ACT COMPOSITE SCORES</div>
                <div className='dca-card-item-header'>25 / 50 / 75%</div>
                <div className="dca-card-item-text">{scoreReturn(res.college_cds[0].actComposite25)} / {scoreReturn(res.college_cds[0].actComposite50)} / {scoreReturn(res.college_cds[0].actComposite75)}</div>
              </div>

            </div>
          </div>
        </div>
      ))}
        <DetailsOverlay
          modalData={modalData}
          userSATVerbal={userSATVerbal}
          userSATMath={userSATMath}
          userSATSuperScore={userSATSuperScore}
          userActEnglish={userActEnglish}
          userActMath={userActMath}
          userActWriting={userActWriting}
          userActScience={userActScience}
          userActReading={userActReading}
          userACTBestScore={userACTBestScore}
          setUserSATVerbal={setUserSATVerbal}
          setUserSATMath={setUserSATMath}
          setUserSATSuperScore={setUserSATSuperScore}
          setUserACTBestScore={setUserACTBestScore}
          setUserActEnglish={setUserActEnglish}
          setUserActMath={setUserActMath}
          setUserActWriting={setUserActWriting}
          setUserActScience={setUserActScience}
          setUserActReading={setUserActReading}
          returnUserSATScoreOutput={returnUserSATScoreOutput}
          returnUserACTScoreOutput={returnUserACTScoreOutput}
          setUserprofile={setUserprofile}
        />
      </div>
      {/* {
        (collegeListLength && collegeListLength > 0 && results.length < collegeListLength) && showMoreResultsLength() > results.length && returnShowMoreButton(loading)
      } */}

      {
        (collegeListLength && collegeListLength > 0 && results.length < collegeListLength) && resultsFull.length > results.length && returnShowMoreButton(loading)
      }
    </div >
  ) : (
    <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginLeft: '300px' }}>
      <LoadingIndicator text={"Getting Major Data ... "} />
    </div>
  )
}

export { Cards }
