import Chart from 'react-apexcharts'

const ACTScoreData = ({ modalData }) => {

  let cdsArr: any = []

  // Chart 1
  let xAxisComposite: any = []
  let composite25: any = []
  let composite50: any = []
  let composite75: any = []

  // Chart 2
  let xAxisLatest: any = ['Math', 'English', 'Reading', 'Science', 'Writing']
  let latest25: any = []
  let latest50: any = []
  let latest75: any = []

  if (modalData && typeof modalData === 'object' && modalData.college_cds) {

    modalData.college_cds.forEach((res, index) => {
      if (index < 3) {
        cdsArr.push(res);
        cdsArr.sort((a, b) => a.year - b.year);
      }
    })

    cdsArr.map((cds, index) => {
      xAxisComposite.push(`${cds.year} - ${cds.year + 1}`);
      composite25.push(cds.actComposite25 ? cds.actComposite25 : null);
      composite50.push(cds.actComposite50 ? cds.actComposite50 : null);
      composite75.push(cds.actComposite75 ? cds.actComposite75 : null);

      if (index === cdsArr.length - 1) {

        const returnVal = (val) => {
          if (val) {
            return val
          }

          else {
            return null
          }
        }

        latest25 = [returnVal(cds.actMath25), returnVal(cds.actEnglish25), returnVal(cds.actReading25), returnVal(cds.actScience25), returnVal(cds.actWriting25)]

        latest50 = [returnVal(cds.actMath50), returnVal(cds.actEnglish50), returnVal(cds.actReading50), returnVal(cds.actScience50), returnVal(cds.actWriting50)]

        latest75 = [returnVal(cds.actMath75), returnVal(cds.actEnglish75), returnVal(cds.actReading75), returnVal(cds.actScience75), returnVal(cds.actWriting75)]

        // console.log('latest25', latest25)
        // console.log('latest50', latest50)
        // console.log('latest75', latest75)
      }
    })

  }

  return (
    <div className="card card-custom shadow p-4" style={{ marginTop: '40px' }}>

      <div style={{ padding: '30px' }}>
        <div className='fw-bold' style={{ fontSize: '22px' }}>ACT Composite Scores</div>

        <Chart
          options={{
            chart: {
              id: "Total Admit Rate"
            },
            xaxis: {
              categories: xAxisComposite,
              labels: {
                show: true,
                style: {
                  colors: '#9AA2B6',
                },
              }
            },
            tooltip: {
              enabled: false,
            },
            yaxis: {
              min: 24,
              max: 36,
              forceNiceScale: false,
              labels: {
                style: {
                  colors: ['#9AA2B6'],
                },
                formatter: function (val) {
                  if (val != null && val != undefined) {
                    return val.toFixed(0);
                  }

                  else {
                    return ''
                  }
                }
              }
            },
            colors: ["#90F892", "#FFEF68", "#97E6FF"],
            legend: {
              show: true,
              onItemClick: {
                toggleDataSeries: false
              },
              onItemHover: {
                highlightDataSeries: true
              },
            },
            dataLabels: {
              enabled: true,
              background: {
                borderWidth: 0,
                borderRadius: 5,
                foreColor: '#000',
              },
            },
            fill: {
              type: "solid",
              gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.6,
                opacityTo: 0.9,
                stops: [0, 90, 100]
              }
            },
            stroke: {
              curve: 'smooth'
            }
          }}
          series={[
            {
              name: "25th percentile score per year",
              data: composite25
            },
            {
              name: "50th percentile score per year",
              data: composite50
            },
            {
              name: "75th percentile score per year",
              data: composite75
            }
          ]}
          type='line'
          width={'600'}
          height={'350'}
        />

        <div className='fw-bold' style={{ fontSize: '22px', marginTop: '40px' }}>ACT Scores ({modalData.college_cds[0].year ? `${modalData.college_cds[0].year} - ${modalData.college_cds[0].year + 1}` : null})</div>

        <Chart
          series={[
            {
              name: '25th percentile score',
              type: 'column',
              data: latest25
            },
            {
              name: '50th percentile score',
              type: 'column',
              data: latest50
            },
            {
              name: '75th percentile score',
              type: 'column',
              data: latest75
            }
          ]}
          options={{
            chart: {
              id: "ACT Scores (latest year)",
              type: 'area',
              stacked: false
            },
            xaxis: {
              categories: xAxisLatest,
              labels: {
                show: true,
                style: {
                  colors: '#9AA2B6',
                },
              }
            },
            yaxis: {
              min: 24,//0.00,
              max: 36,//composite75[0] * 1.5,
              forceNiceScale: false,
              labels: {
                style: {
                  colors: ['#9AA2B6'],
                },
                formatter: function (val) {
                  if (val != null && val != undefined) {
                    return val.toFixed(0);
                  }
                  else {
                    return ''
                  }
                }
              }
            },
            tooltip: {
              enabled: false,
            },
            colors: ["#90F892", "#FFEF68", "#97E6FF"],
            legend: {
              show: true,
              onItemClick: {
                toggleDataSeries: false
              },
              onItemHover: {
                highlightDataSeries: true
              },
            },
            dataLabels: {
              enabled: true,
              background: {
                borderWidth: 0,
                foreColor: '#000',
              },
            },
            stroke: {
              curve: 'smooth',
              show: false,
            },
            plotOptions: {
              bar: {
                columnWidth: '70%',
                borderRadius: 5,
                dataLabels: {
                  position: 'top',
                }
              }
            }
          }}
          width={'600'}
          height={'350'}
        />
      </div>
    </div>
  )
}

export { ACTScoreData }
