import { useState, useEffect } from 'react'
import Select from 'react-select'
import { useLocation } from 'react-router';

const MajorCollegeSearch = ({ collegeOptions, setCollegeSelect }) => {

  const location = useLocation()
  const [selectedColleges, setSelectedColleges] = useState<any>([]);

  const handleCollegeSelectChange = (selectedColleges) => {
    setSelectedColleges(selectedColleges);
    let collegeArr: any = []
    selectedColleges.map((college) =>
      collegeArr.push(college.value))
    setCollegeSelect(collegeArr)
  }

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let namesParam = params.get('collegeName');

    if (namesParam) {

      let res: any[] = []
      namesParam.split(',').map(val => {
        let collegeObj = collegeOptions.find((obj => obj.value === parseInt(val)))

        if (collegeObj) {
          res.push(collegeObj)
        }
      })

      if (res.length > 0) {
        handleCollegeSelectChange(res)
      }

      else {
        setSelectedColleges([])
      }
    }

    else {
      setSelectedColleges([])
    }

  }, [location.search, collegeOptions]);


  return (
    <div className='mt-4 d-flex flex-column'>
      <div className='dca-aside-header'>COLLEGE</div>
      <Select
        options={collegeOptions}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 'transparent',
            backgroundColor: '#F9F9F9'
          })
        }}
        isMulti
        value={selectedColleges}
        onChange={handleCollegeSelectChange}
      />
    </div>
  )
}

export { MajorCollegeSearch }