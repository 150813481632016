const Borderline = ({ userACTScoreOutput, userSATScoreOutput, collegeName, satSuperScore, actComposite, testPolicy }) => {

  ////////////////
  // EDIT TEXT //
  //////////////

  //// SAT borderline for test optional school ////
  // actScoreOutput = -1
  // const SATcaseA = `We estimate your SAT score (${satSuperScore}) is near the median value (approximately between the 40th and 60th percentile) for ${collegeName}, and because they are test-optional, we consider this a borderline case for submitting. Your current score might be considered a neutral factor but we recommend students make an individual decision in the context of their overall application. You should also consider trying to improve your SAT score, or explore taking the ACT, before deciding to submit.`

  const SATcaseA = <div>We estimate your SAT score ({satSuperScore}) is <span className='fw-bold'>near the median value</span> (approximately between the 40th and 60th percentile) for {collegeName}, and because they are test-optional, we consider this a <span className='fw-bold'>borderline case</span> for submitting. Your score might be considered a neutral factor but we recommend students make an individual decision in the context of their overall application. You may also wish to <span className='fw-bold'>consider trying to improve your SAT score, or explore taking the ACT</span>, before deciding to submit.</div>

  //actScoreOutput = -2
  const SATcaseB = <div>We estimate your SAT score ({satSuperScore}) is <span className='fw-bold'>near the median value</span> (approximately between the 40th and 60th percentile) for {collegeName}, and because they are test-optional, we consider this a <span className='fw-bold'>borderline case</span> for submitting. Your current score might be considered a neutral factor but we recommend students make an individual decision in the context of their overall application. You should also <span className='fw-bold'>consider trying to improve your SAT score</span> before deciding to submit. Note there is insufficient data published by {collegeName} on their ACT score benchmarks to evaluate the relative strength of your ACT score and so that has not been considered for this recommendation.</div>

  // actScoreOutput > 0
  const SATcaseC = <div>We estimate your SAT score ({satSuperScore}) to be stronger than your ACT score ({actComposite}), as compared to the respective benchmarks published by {collegeName}. In addition, we estimate your SAT score is near the <span className='fw-bold'>near the median value</span> (approximately between the 40th and 60th percentile) for {collegeName}, and because they are test-optional, we consider this a borderline case for submitting. Your current score might be considered a neutral factor but we recommend students make an individual decision in the context of their overall application. You should also <span className='fw-bold'>consider trying to improve your score</span> before deciding to submit.</div>

  //// ACT borderline for test optional school ////
  // satScoreOutput = -1
  const ACTcaseA = <div>We estimate your ACT score ({actComposite}) is <span className='fw-bold'>near the median value</span> (approximately between the 40th and 60th percentile) for {collegeName}, and because they are test-optional, we consider this a <span className='fw-bold'>borderline case</span> for submitting. Your current score might be considered a neutral factor but we recommend students make an individual decision in the context of their overall application. You should also <span className='fw-bold'>consider trying to improve your ACT score, or explore taking the SAT</span>, before deciding to submit.</div>

  // satScoreOutput = -2
  const ACTcaseB = <div>We estimate your ACT score ({actComposite}) is <span className='fw-bold'>near the median value</span> (approximately between the 40th and 60th percentile) for {collegeName}, and because they are test-optional, we consider this a <span className='fw-bold'>borderline case</span> for submitting. Your current score might be considered a neutral factor but we recommend students make an individual decision in the context of their overall application. You should also <span className='fw-bold'>consider trying to improve your ACT score</span> before deciding to submit. Note there is insufficient data published by {collegeName} on their SAT score benchmarks to evaluate the relative strength of your SAT score and so that has not been considered for this recommendation.</div>

  // satScoreOutput > 0
  const ACTcaseC = <div>We estimate your ACT score ({actComposite}) to be stronger than your SAT score ({satSuperScore}), as compared to the respective benchmarks published by {collegeName}. In addition, we estimate your ACT score is near the <span className='fw-bold'>near the median value</span> (approximately between the 40th and 60th percentile) for {collegeName}, and because they are test-optional, we consider this a borderline case for submitting. Your current score might be considered a neutral factor but we recommend students make an individual decision in the context of their overall application. You should also <span className='fw-bold'>consider trying to improve your score</span> before deciding to submit.</div>

  //// Case 15, Both tests borderline for test optional school
  const case15 = <div>We estimate your SAT score ({satSuperScore}) and ACT score ({actComposite}) are approximately equally strong relative to the respective benchmarks for {collegeName}. Both scores are near the median value (approximately between the 40th and 60th percentile) for this school, and because {collegeName} is test-optional, we consider this a borderline case for submitting. Your current score might be considered a neutral factor but we recommend students make an individual decision in the context of their overall application. You should also consider <span className='fw-bold'>trying to improve either or both of your scores</span> before deciding to submit.</div>

  //////////////////////
  //  END EDIT TEXT  //
  ////////////////////

  let outputGreater = true
  let caseCheck = false
  let subcase = 'a'

  const determineSATSubCase = (userACTScoreOutput) => {
    switch (true) {
      case userACTScoreOutput == -1:
        subcase = 'a'
        break;
      case userACTScoreOutput == -2:
        subcase = 'b'
        break;
      case userACTScoreOutput > 0:
        subcase = 'c'
        break;
      default:
        subcase = subcase
    }
  }

  const determineACTSubCase = (userSATScoreOutput) => {
    switch (true) {
      case userSATScoreOutput == -1:
        subcase = 'a'
        break;
      case userSATScoreOutput == -2:
        subcase = 'b'
        break;
      case userSATScoreOutput > 0:
        subcase = 'c'
        break;
      default:
        subcase = subcase
    }
  }

  const checkCase = (testPolicy, userSATScoreOutput, userACTScoreOutput) => {
    if ((testPolicy == 2) && (userSATScoreOutput == userACTScoreOutput && 40 <= userSATScoreOutput && userSATScoreOutput < 60)) {
      caseCheck = true
    }

    else {

      userSATScoreOutput > userACTScoreOutput ? outputGreater = true : outputGreater = false

      outputGreater ? determineSATSubCase(userACTScoreOutput) : determineACTSubCase(userSATScoreOutput)

    }

  }

  checkCase(testPolicy, userSATScoreOutput, userACTScoreOutput)



  const aSAT = (
    <div>{SATcaseA}</div>
  )

  const bSAT = (
    <div>{SATcaseB}</div>
  )

  const cSAT = (
    <div>{SATcaseC}</div>
  )

  const aACT = (
    <div>{ACTcaseA}</div>
  )

  const bACT = (
    <div>{ACTcaseB}</div>
  )

  const cACT = (
    <div>{ACTcaseC}</div>
  )

  const c15 = (
    <div>{case15}</div>
  )

  const returnExplanationSAT = (subcase) => {
    switch (subcase) {
      case 'a':
        return aSAT
      case 'b':
        return bSAT
      case 'c':
        return cSAT
      default:
        return <div> - </div>
    }
  }

  const returnExplanationACT = (subcase) => {
    switch (subcase) {
      case 'a':
        return aACT
      case 'b':
        return bACT
      case 'c':
        return cACT
      default:
        return <div> - </div>
    }
  }

  return caseCheck ? (
    <div>
      {c15}
    </div>
  ) : (
    <div>
      {outputGreater ? returnExplanationSAT(subcase) : returnExplanationACT(subcase)}
    </div>
  )
}

export { Borderline }
