import Chart from 'react-apexcharts'

export const SubmitChart = ({ seriesData }) => {
  return (
    <Chart
      options={{
        chart: {
          id: 'SAT Submission vs ACT Submission'
        },
        xaxis: {
          type: 'numeric',
          //decimalsInFloat: 0,
          min: 0,
          max: 1,
          tickAmount: 10,
          labels: {
            formatter: function (val: any) {
              return ((val * 100).toFixed(0)) + "%";
            }
          },
          title: {
            text: "Percent of Students Submitting SAT Score(s)",
            style: {
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
            },
          },
        },
        yaxis: {
          min: 0,
          max: 1,
          tickAmount: 10,
          //decimalsInFloat: 0,
          labels: {
            formatter: function (val) {
              return ((val * 100).toFixed(0)) + "%";
            }
          },
          title: {
            text: "Percent of Students Submitting ACT Score(s)",
            style: {
              fontSize: '12px',
              fontFamily: 'Helvetica, Arial, sans-serif',
              fontWeight: 400,
            },
          },
        },
        colors: ["#98F29B"],
        fill: {
          opacity: 0.6,
          type: 'fill',
        },
        plotOptions: {
          bubble: {
            zScaling: true,
            minBubbleRadius: 5,
            maxBubbleRadius: 12,
          },
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          offsetY: 8,
          onItemClick: {
            toggleDataSeries: false
          },
          onItemHover: {
            highlightDataSeries: true
          },
        },
        tooltip: {
          enabled: true,
          shared: false,
          custom: function ({ seriesIndex, dataPointIndex, w }) {

            if ((seriesIndex != null && seriesIndex != undefined) && (dataPointIndex != null && dataPointIndex != undefined)) {

              let data = w.globals.initialSeries[seriesIndex].data[dataPointIndex]

              return (
                '<div class="dca-apexcharts-tooltip">' +
                `<span>${data.name}</span>` +
                '<ul>' +
                `<li>Submitted SAT score: ${(data.x * 100).toFixed(1)}%</li>` +
                `<li>Submitted ACT score: ${(data.y * 100).toFixed(1)}%</li>` +
                `<li>Admit Rate: ${(data.z * 100).toFixed(1)}%</li>` +
                `<li>Metarank: #${data.metaRank ? data.metaRank : 'metaRank'}</li>` +
                '</ul>' +
                "</div>"
              )
            }

            else {
              return '<div>Error generating tooltip data</div>'
            }

          },
        }
      }}
      series={[
        {
          name: 'submissionSeries',
          data: seriesData
        }
      ]}
      type='bubble'
      height={600}
      width={800}
    />
  )
}
