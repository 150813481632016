import React, { useState, useCallback } from 'react'
import ReactGA from 'react-ga4'
import { selectedTabGreen, unselectedTabGray, rdSpendAccessCode, specializedRankingsAccessCode, safetyAccessCode, gray } from '../dictionary'
import { CollegeDetailTabContent } from './CollegeDetailTabContent'
import { useNavigate } from 'react-router'
import { getAuth, useAuth } from '../../modules/auth'
import { setCollegeDetailTabModal, setPurchaseModal } from '../Auth/setPrompts'
import { CollegeReactGAHandlers } from '../ReactGAHandlers'
import { AuthReactGAHandlers } from '../ReactGAHandlers'

const CollegeDetailTabs = ({ college, setUser, collegeListLength, setPrompt, setNavigateTo, setPurchaseID, setPurchaseModalCollegeName, collegesId, setSelectedTab, selectedTab }) => {

  let apiToken: any = undefined
  let permissionGroups: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
    permissionGroups = getAuth()?.groups
  }

  const { handleGAEventSignInModalOpen } = AuthReactGAHandlers()

  const {
    handleGAEventTabClick,
    handleGAEventPurchaseModalOpen
  } = CollegeReactGAHandlers()

  const restrictedTabOptions = ['Specialized Rankings', 'R&D Spend', 'Crime & Safety']

  // All access
  const tabOptions = ['Admission Info', 'GPA Data', 'SAT Score Data', 'ACT Score Data', 'Industry Rankings', 'Specialized Rankings', 'R&D Spend', 'Crime & Safety']

  // Open access
  const openAccessTabOptions = ['Admission Info', 'GPA Data', 'SAT Score Data', 'ACT Score Data', 'Industry Rankings']

  // Purchased Access Tab Options

  let optionATrue: any = undefined
  let optionBTrue: any = undefined
  let optionCTrue: any = undefined
  let optionDTrue: any = undefined
  let optionETrue: any = undefined
  let optionFTrue: any = undefined
  let optionGTrue: any = undefined

  const tabOptionsA = openAccessTabOptions.concat(restrictedTabOptions[0])
  const tabOptionsB = openAccessTabOptions.concat(restrictedTabOptions[1])
  const tabOptionsC = openAccessTabOptions.concat(restrictedTabOptions[2])
  const tabOptionsD = openAccessTabOptions.concat(restrictedTabOptions[0]).concat(restrictedTabOptions[1])
  const tabOptionsE = openAccessTabOptions.concat(restrictedTabOptions[1]).concat(restrictedTabOptions[2])
  const tabOptionsF = openAccessTabOptions.concat(restrictedTabOptions[0]).concat(restrictedTabOptions[2])

  if (permissionGroups !== undefined && Array.isArray(permissionGroups)) {
    optionATrue = permissionGroups.indexOf(specializedRankingsAccessCode) !== -1

    optionBTrue = permissionGroups.indexOf(rdSpendAccessCode) !== -1

    optionCTrue = permissionGroups.indexOf(safetyAccessCode) !== -1

    optionDTrue = [specializedRankingsAccessCode, rdSpendAccessCode].every((code) => permissionGroups.includes(code));

    optionETrue = [rdSpendAccessCode, safetyAccessCode].every((code) => permissionGroups.includes(code))

    optionFTrue = [specializedRankingsAccessCode, safetyAccessCode].every((code) => permissionGroups.includes(code))

    optionGTrue = [specializedRankingsAccessCode, rdSpendAccessCode, safetyAccessCode].every((code) => permissionGroups.includes(code));
  }

  // const [selectedTab, setSelectedTab] = useState(tabOptions[0])

  const handleSelectedTab = (val) => {
    setSelectedTab(val)
  }

  const guestOrNoAuthTabs = (
    <div className='d-flex flex-row'>

      {openAccessTabOptions.map((tab, index) => (
        <div
          key={tab}
          className="d-flex rounded rounded-3 fw-bold justify-content-center align-items-center"
          style={{
            backgroundColor: selectedTab === tabOptions[index] ? selectedTabGreen : unselectedTabGray,
            height: '70px',
            cursor: 'pointer',
            border: '1px rgba(219, 223, 233, 0.4) dashed',
            borderRadius: '50%',
            display: 'inline-block',
            marginLeft: index === 0 ? '0px' : '15px'
          }}

          onClick={() => {
            handleSelectedTab(tabOptions[index]);
            handleGAEventTabClick(tabOptions[index], college.id)
          }}
        >
          <span className='m-3 text-nowrap align-self-end'>{tab}</span>
        </div>))}

      {restrictedTabOptions.map((tab) => (

        <a
          key={tab}
          className="d-flex rounded rounded-3 fw-bold align-items-end"
          data-bs-toggle="modal"
          href="#login_modal"
          onClick={() => {
            setCollegeDetailTabModal(setPrompt, setNavigateTo, tab);
            handleGAEventTabClick(tab, college.id);
            handleGAEventSignInModalOpen('forced')
          }}
          style={{
            backgroundColor: gray,
            height: '70px',
            cursor: 'pointer',
            border: '1px rgba(219, 223, 233, 0.4) dashed',
            borderRadius: '50%',
            display: 'inline-block',
            marginLeft: '15px',
            color: 'black'
          }}
        >
          <span className='d-flex flex-column'>
            <i className="bi bi-lock align-self-center" style={{ color: 'black' }}></i>
            <span className='m-3 text-nowrap align-self-end'>{tab}</span>
          </span>
        </a>

      ))}

    </div>
  )

  const returnTabRequiringPurchase = (text, purchaseID) => {
    return (
      <span
        className="d-flex rounded rounded-3 fw-bold align-items-end"
        data-bs-toggle="modal"
        data-bs-target="#purchase_modal"
        onClick={() => {
          setPurchaseModal(setPurchaseID, purchaseID);
          setPurchaseModalCollegeName(college.name);
          handleGAEventTabClick(text, college.id);
          handleGAEventPurchaseModalOpen(text)
        }}
        style={{
          backgroundColor: gray,
          height: '70px',
          cursor: 'pointer',
          border: '1px rgba(219, 223, 233, 0.4) dashed',
          borderRadius: '50%',
          display: 'inline-block',
          marginLeft: '15px',
          color: 'black'
        }}
      >
        <span className='d-flex flex-column'>
          <i className="bi bi-lock align-self-center" style={{ color: 'black' }}></i>
          <span className='m-3 text-nowrap align-self-end'>{text}</span>
        </span>
      </span>
    )
  }

  const authWithoutAccessTabs = (

    <div className='d-flex flex-row'>

      {openAccessTabOptions.map((tab, index) => (
        <div
          key={tab}
          className="d-flex rounded rounded-3 fw-bold justify-content-center align-items-center"
          style={{
            backgroundColor: selectedTab === tabOptions[index] ? selectedTabGreen : unselectedTabGray,
            height: '70px',
            cursor: 'pointer',
            border: '1px rgba(219, 223, 233, 0.4) dashed',
            borderRadius: '50%',
            display: 'inline-block',
            marginLeft: index === 0 ? '0px' : '15px'
          }}

          onClick={() => {
            handleSelectedTab(tabOptions[index]);
            handleGAEventTabClick(tabOptions[index], college.id)
          }}
        >
          <span className='m-3 text-nowrap align-self-end'>{tab}</span>
        </div>))}

      {returnTabRequiringPurchase('Specialized Rankings', specializedRankingsAccessCode)}

      {returnTabRequiringPurchase('R&D Spend', rdSpendAccessCode)}

      {returnTabRequiringPurchase('Crime & Safety', safetyAccessCode)}

    </div>
  )

  const fullAccessTabs = (

    <div className='d-flex flex-row'>

      {tabOptions.map((tab, index) => (
        <div
          key={tab}
          className="d-flex rounded rounded-3 fw-bold justify-content-center align-items-center"
          style={{
            backgroundColor: selectedTab === tabOptions[index] ? selectedTabGreen : unselectedTabGray,
            height: '70px',
            cursor: 'pointer',
            border: '1px rgba(219, 223, 233, 0.4) dashed',
            borderRadius: '50%',
            display: 'inline-block',
            marginLeft: index === 0 ? '0px' : '15px'
          }}
          onClick={() => {
            handleSelectedTab(tabOptions[index]);
            handleGAEventTabClick(tabOptions[index], college.id)
          }}
        >
          <span className='m-3 text-nowrap align-self-end'>{tab}</span>
        </div>))}

    </div>
  )

  const returnPurchaseTab = (tabName) => {
    switch (true) {
      case tabName === 'Specialized Rankings':
        return returnTabRequiringPurchase('Specialized Rankings', specializedRankingsAccessCode)
      case tabName === 'R&D Spend':
        return returnTabRequiringPurchase('R&D Spend', rdSpendAccessCode)
      case tabName === 'Crime & Safety':
        return returnTabRequiringPurchase('Crime & Safety', safetyAccessCode)
      default:
        return null
    }
  }

  const returnAccessTab = (userTabOptions) => {

    return (
      <div className='d-flex flex-row'>

        {tabOptions.map((tab, index) => {

          return userTabOptions.some((option) => option.includes(tab)) ? <div
            key={index}
            className="d-flex rounded rounded-3 fw-bold justify-content-center align-items-center"
            style={{
              backgroundColor: selectedTab === tabOptions[index] ? selectedTabGreen : unselectedTabGray,
              height: '70px',
              cursor: 'pointer',
              border: '1px rgba(219, 223, 233, 0.4) dashed',
              borderRadius: '50%',
              display: 'inline-block',
              marginLeft: index === 0 ? '0px' : '15px'
            }}

            onClick={() => {
              handleSelectedTab(tabOptions[index]);
              handleGAEventTabClick(tabOptions[index], college.id)
            }}
          >
            <span className='m-3 text-nowrap align-self-end'>{tab}</span>
          </div> : returnPurchaseTab(tab)
        })}

      </div>
    )
  }

  const returnTabs = (apiToken) => {
    switch (true) {
      case !apiToken:
        return guestOrNoAuthTabs
      case apiToken !== undefined && optionGTrue:
        return fullAccessTabs
      case apiToken !== undefined && optionFTrue:
        return returnAccessTab(tabOptionsF)
      case apiToken !== undefined && optionETrue:
        return returnAccessTab(tabOptionsE)
      case apiToken !== undefined && optionDTrue:
        return returnAccessTab(tabOptionsD)
      case apiToken !== undefined && optionCTrue:
        return returnAccessTab(tabOptionsC)
      case apiToken !== undefined && optionBTrue:
        return returnAccessTab(tabOptionsB)
      case apiToken !== undefined && optionATrue:
        return returnAccessTab(tabOptionsA)
      case apiToken !== undefined:
        return authWithoutAccessTabs
      default:
        return guestOrNoAuthTabs
    }
  }

  return (
    <div className='mt-5'>

      {returnTabs(apiToken)}

      <CollegeDetailTabContent
        college={college}
        selectedTab={selectedTab}
        tabOptions={tabOptions}
        collegeListLength={collegeListLength}
        collegesId={collegesId}
        setSelectedTab={setSelectedTab}
      />

    </div>
  )
}

export { CollegeDetailTabs }