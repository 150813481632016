const MissingUserData = () => {

  ////////////////
  // EDIT TEXT //
  //////////////

  let text = 'A personalized recommendation requires entering your SAT and/or ACT test scores. Please enter your score(s) and the recommendations will automatically update.'

  //////////////////////
  //  END EDIT TEXT  //
  ////////////////////

  return (
    <div>
      {text}
    </div>)
}

export { MissingUserData }