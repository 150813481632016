import noUiSlider from 'nouislider'
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router'

const MajorEarningsSearch = ({ setEarningsMin, setEarningsMax }) => {

  const numberConverter = (num) => {
    if (typeof num !== 'number') {
      return '-'
    }

    else {
      let res = num.toFixed(0)
      return res.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }

  const location = useLocation()
  const sliderRef = useRef(null);

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let earningsParam = params.get('earnings');

    if (earningsParam) {
      const earningsVals = earningsParam.split(',').map(val => (parseInt(val)))
      setEarningsMin(earningsVals[0])
      setEarningsMax(earningsVals[1])
    }

    else {
      setEarningsMin(0)
      setEarningsMax(250000)
    }

  }, [location.search]);

  useEffect(() => {

    if (!sliderRef.current) {

      const slider: any = document.querySelector("#kt_slider_earnings");
      let params = new URLSearchParams(location.search);
      let earningsParam = params.get('earnings');

      if (earningsParam) {
        const earningsVals = earningsParam.split(',').map(val => (parseInt(val)))

        noUiSlider.create(slider, {
          start: [earningsVals[0], earningsVals[1]],
          step: 1000,
          range: {
            "min": 0,
            "max": 250000
          },
          tooltips: {
            to: function (value) {
              return `$${numberConverter(value)}`;
            }
          },
        });
      }

      else {
        noUiSlider.create(slider, {
          start: [0, 250000],
          step: 1000,
          range: {
            "min": 0,
            "max": 250000
          },
          tooltips: {
            to: function (value) {
              return `$${numberConverter(value)}`;
            }
          },
        });
      }


      slider.noUiSlider.on("set", function (values, handle) {
        if (handle === 0) {
          setEarningsMin(parseInt(values[0]))
        }
        if (handle === 1) {
          setEarningsMax(parseInt(values[1]))
        }

      });

      sliderRef.current = slider;

    }


  }, [])

  return (
    <div className='mt-4 d-flex flex-column'>
      <div className='dca-aside-header'>EARNINGS</div>
      <div className="mb-0" style={{ marginTop: '50px' }}>
        <div id="kt_slider_earnings"></div>
      </div>
    </div>
  )
}

export { MajorEarningsSearch }