import { pageRoutes, gray, selectedTabGreen } from './dictionary';
import { KTIcon, toAbsoluteUrl } from '../../_metronic/helpers';
import { useNavigate } from 'react-router'
import { logout } from '../modules/auth/core/_requests';
import { getAuth, useAuth } from '../modules/auth';
import { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { BiChevronDown } from 'react-icons/bi'
import { clearModal, setTestScoreStrategyModal } from '../components/Auth/setPrompts'
import avatar from '../../custom_assets/avatar.png'
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { AuthReactGAHandlers } from './ReactGAHandlers';

const Header = ({ setPrompt, setNavigateTo }) => {

  const {
    handleGAEventSignInModalOpen,
    handleGAEventSignUpModalOpen
  } = AuthReactGAHandlers()
  const { currentUser, setCurrentUser, saveAuth } = useAuth()
  let navigate = useNavigate()
  let apiToken: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
  }

  const currentUserFalse = (
    <div className='topbar d-flex align-items-stretch flex-shrink-0'>

      <a
        className="fw-bold align-self-center dca-header-signin-bttn"
        data-bs-toggle="modal"
        href="#login_modal"
        onClick={() => {
          clearModal(setPrompt, setNavigateTo);
          handleGAEventSignInModalOpen('')
        }}
      >Sign In</a>

      <a
        className="fw-bold align-self-center dca-header-signup-bttn"
        data-bs-toggle="modal"
        href="#signup_modal"
        onClick={() => {
          clearModal(setPrompt, setNavigateTo);
          handleGAEventSignUpModalOpen()
        }}
      >Sign Up</a>
    </div>
  )

  const currentUserTrue = currentUser && currentUser.emailAddress && (
    <div className='topbar d-flex align-items-stretch flex-shrink-0'>

      <button
        className="fw-bold align-self-center"
        onClick={() => {
          logout();
          saveAuth(undefined)
          setCurrentUser(undefined)
        }}
        style={{
          color: 'black',
          background: selectedTabGreen,
          borderRadius: '10px',
          border: 'transparent',
          padding: '12px'
        }}
      >Sign Out</button>

      <div className='symbol symbol-30px symbol-md-40px align-self-center ms-5'>

        <img
          className='rounded-circle'
          src={avatar}
          alt='avatar'
          style={{ cursor: 'pointer' }}
          onClick={() => {
            navigate(`/user-profile/${currentUser?.user_id}`)
          }} />

      </div>

    </div>
  )

  const [showDropdownOverview, setShowDropdownOverview] = useState(false)
  const [sidebarActive, setSidebarActive] = useState(false)

  const handleMouseEnterOverview = () => {
    if (showDropdownOverview == false) {
      setShowDropdownOverview(true)
    }
  }

  const handleMouseLeaveOverview = () => {
    setTimeout(() => {
      if (showDropdownOverview == true) {
        setShowDropdownOverview(false);
      }
    }, 600);
  }

  const [showDropdownToolsResources, setShowDropdownToolsResources] = useState(false)

  const handleMouseEnterToolsResources = () => {
    if (showDropdownToolsResources == false) {
      setShowDropdownToolsResources(true)
    }
  }

  const handleMouseLeaveToolsResources = () => {
    setTimeout(() => {
      if (showDropdownToolsResources == true) {
        setShowDropdownToolsResources(false);
      }
    }, 600);
  }

  const handleClickOffcanvas = () => {
    setSidebarActive(!sidebarActive)
  }

  const sidebar = (
    <div className={`offcanvas offcanvas-start ${sidebarActive ? 'show' : ''}`} tabIndex={-1} id="offcanvas" aria-labelledby="offcanvasLabel">
      <div className="offcanvas-header dca-header-sidebar-title">
        <div className="offcanvas-title dca-header-logo" id="offcanvasLabel">
          NEXTFOUR
        </div>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" onClick={() => setSidebarActive(!sidebarActive)}></button>
      </div>
      <div className="offcanvas-body">

        <div
          className='dca-header-sidebar-navgroup dca-header-item'
          onClick={() => {
            setSidebarActive(!sidebarActive);
            navigate(pageRoutes.home)
          }}>
          HOME
        </div>

        <div className='dca-header-sidebar-navgroup dca-header-item'>
          OVERVIEW
          <ul>
            <li
              className='dca-header-dropdown-item dca-header-sidebar-navitem'
              onClick={() => {
                setSidebarActive(!sidebarActive);
                navigate(pageRoutes.overviewIntroduction)
              }}>Introduction</li>
            <li
              className='dca-header-dropdown-item dca-header-sidebar-navitem'
              onClick={() => {
                setSidebarActive(!sidebarActive);
                navigate(pageRoutes.overviewCollegeAppWalkthrough)
              }}>College Application Walkthrough</li>
            <li
              className='dca-header-dropdown-item dca-header-sidebar-navitem'
              onClick={() => {
                setSidebarActive(!sidebarActive);
                navigate(pageRoutes.overviewAdmissionsAdvice)
              }}>Advice for Navigating Admissions Process</li>
          </ul>
        </div>

        <div className='dca-header-sidebar-navgroup dca-header-item'>
          TOOLS & RESOURCES
          <ul>
            <li
              className='dca-header-dropdown-item dca-header-sidebar-navitem'
              onClick={() => {
                setSidebarActive(!sidebarActive);
                navigate(pageRoutes.collegeSearch)
              }}>Explore Colleges</li>
            <li
              className='dca-header-dropdown-item dca-header-sidebar-navitem'
              onClick={() => {
                setSidebarActive(!sidebarActive);
                navigate(pageRoutes.collegeDataVisualization)
              }}>College Scattergrams</li>
            <li
              className='dca-header-dropdown-item dca-header-sidebar-navitem'
              onClick={() => {
                setSidebarActive(!sidebarActive);
                navigate(pageRoutes.majorSearch)
              }}>Explore Majors</li>
            <li
              className='dca-header-dropdown-item dca-header-sidebar-navitem'
              onClick={() => {
                setSidebarActive(!sidebarActive);
                navigate(pageRoutes.ecSearch)
              }}>Explore Extracurriculars</li>
            <li
              className='dca-header-dropdown-item dca-header-sidebar-navitem'
            ><a
              data-bs-toggle={apiToken == undefined ? "modal" : ""}
              href={apiToken != undefined ? "" : "#login_modal"}
              style={{ color: 'black' }}
              onClick={() => {
                setSidebarActive(!sidebarActive);
                setTestScoreStrategyModal(apiToken, navigate, setPrompt, setNavigateTo);

                apiToken == undefined && handleGAEventSignInModalOpen('forced')
              }}
            >Test Score Guidance</a></li>
          </ul>
        </div>

      </div>
    </div>
  )

  return (
    <div className='dca-header-wrapper'>

      <div className='dca-header-menu-toggle d-flex d-lg-none align-items-center me-n2' title='Show header menu' onClick={() => { handleClickOffcanvas() }}>
        <button
          className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
          id='kt_header_menu_mobile_toggle'
        >
          <KTIcon iconName='text-align-left' className='fs-1' />
        </button>
      </div>

      <div className='dca-header-container'>

        <div className='dca-header-dropdowns-container'>
          <div
            className='dca-header-logo'
            onClick={() => navigate(pageRoutes.home)}>
            NEXTFOUR
          </div>

          <div className='dca-header-dropdown-menu'><Dropdown
            show={showDropdownOverview}
            onMouseEnter={handleMouseEnterOverview}
            onMouseLeave={handleMouseLeaveOverview}
          >
            <Dropdown.Toggle
              className='dca-header-item'
              id="dropdown-overview">
              OVERVIEW <BiChevronDown />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                className='dca-header-dropdown-item'
                onClick={() => navigate(pageRoutes.overviewIntroduction)}>Introduction</Dropdown.Item>
              <Dropdown.Item
                className='dca-header-dropdown-item'
                onClick={() => navigate(pageRoutes.overviewCollegeAppWalkthrough)}
              >College Application Walkthrough</Dropdown.Item>
              <Dropdown.Item
                className='dca-header-dropdown-item'
                onClick={() => navigate(pageRoutes.overviewAdmissionsAdvice)}>Advice for Navigating Admissions Process</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown></div>

          <Dropdown
            show={showDropdownToolsResources}
            onMouseEnter={handleMouseEnterToolsResources}
            onMouseLeave={handleMouseLeaveToolsResources}
          >
            <Dropdown.Toggle
              className='dca-header-item'
              id="dropdown-tools-resources">
              TOOLS & RESOURCES <BiChevronDown />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                className='dca-header-dropdown-item'
                onClick={() => navigate(pageRoutes.collegeSearch)}>Explore Colleges</Dropdown.Item>
              <Dropdown.Item
                className='dca-header-dropdown-item'
                onClick={() => navigate(pageRoutes.collegeDataVisualization)}>College Scattergrams</Dropdown.Item>
              <Dropdown.Item
                className='dca-header-dropdown-item'
                onClick={() => navigate(pageRoutes.majorSearch)}>Explore Majors</Dropdown.Item>
              <Dropdown.Item
                className='dca-header-dropdown-item'
                onClick={() => navigate(pageRoutes.ecSearch)}>Explore Extracurriculars</Dropdown.Item>
              <Dropdown.Item
                className='dca-header-dropdown-item'
                data-bs-toggle={apiToken == undefined ? "modal" : ""}
                href={apiToken != undefined ? "" : "#login_modal"}
                onClick={() => {
                  setTestScoreStrategyModal(apiToken, navigate, setPrompt, setNavigateTo);
                  apiToken == undefined && handleGAEventSignInModalOpen('forced')
                }}>Test Score Guidance</Dropdown.Item>
              {/* <Dropdown.Item
            className='dca-header-dropdown-item'
            onClick={() => navigate(pageRoutes.advisorSearch)}>Advisors</Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>

          <a
            href={pageRoutes.blog}
            rel="noopener noreferrer"
            className='dca-header-item'
            id='dca-header-blog-item'
          >BLOG
          </a>
        </div>

        {currentUser && currentUser.emailAddress ? currentUserTrue : currentUserFalse}
      </div>

      <div className='dca-search-aside'>
        {sidebar}
      </div>

    </div>
  )
}

export { Header }