import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { getUserByToken, getUserProfile, login } from '../../modules/auth/core/_requests'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { useAuth } from '../../modules/auth/core/Auth'
import { closeModalIcon, selectedTabGreen, unselectedTabGray } from '../dictionary'
import * as Yup from 'yup'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { getAuth } from '../../modules/auth'
import { LoadingIndicator } from '../LoadingIndicator'

let apiToken: any = undefined
let permissionGroups: any = undefined

if (getAuth()?.api_token && getAuth()?.groups) {
  apiToken = getAuth()?.api_token
  permissionGroups = getAuth()?.groups
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

// When using this in a modal, wrap with parent divs like
{/* <div className="modal fade" id="login_modal">
    <div className="modal-dialog">
    <div className="modal-content" style={{ width: '920px', maxWidth: '920px', marginTop: '220px', padding: '30px' }}></div> */}

// Trigger with a component wrapped with
// data-bs-toggle="modal"
// data-bs-target="#login_modal"

export const UpgradeAccountModal = () => {

  const [groupIds, setGroupIds] = useState<any>(null)
  const [clicked, setClicked] = useState<any>(null)

  const returnTierCard = (tier: number) => {
    return (
      <button
        onClick={() => {
          clicked != tier ? setClicked(tier) : setClicked(null)
          setGroupIds([tier])
        }}
        style={{
          width: '30%',
          border: '2px dotted',
          borderRadius: '8px',
          color: clicked == tier ? selectedTabGreen : unselectedTabGray,
          background: clicked == tier ? selectedTabGreen : unselectedTabGray
        }}
      >
        <div className='d-flex flex-column' style={{ padding: '20px' }}>
          <span style={{ color: 'black' }}>Tier {tier}</span>

          <ul style={{ color: 'black', marginTop: '10px' }}>
            <li>Lorem ipsum dolor sit amet</li>
            <li>Phasellus dictum urna sit</li>
            <li>Quisque lacinia ante felis</li>
          </ul>

          <span className='text-center' style={{ color: 'black', marginTop: '10px' }}> $5.99 </span>

        </div>


      </button>
    )
  }

  const upgradeCards = (
    <div>
      <div
        className='d-flex flex-row justify-content-between'
        style={{ marginTop: '40px' }}>

        {returnTierCard(2)}

        {returnTierCard(3)}

        {returnTierCard(4)}

      </div>

      <div className="d-flex flex-row justify-content-center" style={{ marginTop: '20px' }}>
        {clicked != null ? <a
          onClick={() => {
            // console.log('Selected Group ids:', groupIds)

            try {
              // handleUpdateUserGroups(apiToken, [2])
              if (clicked) {
                alert(`Selected Tier 2 Upgrade`)
              }
            } catch (error) {
              console.log(error)
              alert(error)
            }

          }}
          style={{
            background: "linear-gradient(rgba(218, 248, 185, 1) 0%, rgba(183, 255, 181, 1) 100%)",
            border: 'transparent',
            borderRadius: '8px',
            color: 'black',
            padding: '10px 20px 10px 20px'
          }}
          data-bs-toggle="modal"
          href="#add_payment_method_modal"
        >
          Upgrade
        </a> : <button
          onClick={() => {
            alert('You must select an upgrade tier ')
          }}
          style={{
            background: "linear-gradient(rgba(218, 248, 185, 1) 0%, rgba(183, 255, 181, 1) 100%)",
            border: 'transparent',
            borderRadius: '8px',
            color: 'black',
            padding: '10px 20px 10px 20px'
          }}
        >
          Upgrade
        </button>}
      </div>
    </div>
  )



  return apiToken ? (
    <div className="modal fade" id="upgrade_account_modal">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content" style={{ minWidth: '75vw', maxWidth: '75vw', padding: '60px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', overflowY: 'auto' }}>
          {closeModalIcon('')}

          {upgradeCards}

        </div>
      </div>
    </div>
  ) : (

    <div className="modal fade" id="upgrade_account_modal">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content" style={{ minWidth: '75vw', maxWidth: '75vw', padding: '60px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', overflowY: 'auto' }}>
          {closeModalIcon('')}

          <LoadingIndicator text={"Loading ... "} />

        </div>
      </div>
    </div>
  )
}