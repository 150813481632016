import { collegeLogos, gray, numberConverter } from "../dictionary"
import Logo from '../../../custom_assets/ec_logo.png'
import { SaveButtonInCard } from "../SaveButton"
import { PopularityIndex } from "./PopularityIndex"
import { useEffect, useState } from "react"
import { TooltipComponent } from "../Tooltip"
import { SaveButtonCardNoAuth, SaveButtonMajorCard } from "../SaveButton"
import { MajorReactGAHandlers } from "../ReactGAHandlers"
import { LoadingIndicator } from "../LoadingIndicator"

const Cards = ({ results, colleges, majors, sortOrder, sortKey, sortKeys, handleSortKeyChange, sortKeyReturn, handleSortOrderChange, apiToken, user, setUser, setPrompt, setNavigateTo, collegesData }) => {

  const { handleGAEventExtLink } = MajorReactGAHandlers()

  if (!results) {
    return (
      <div className='dca-card-container'>

        {/* HEADER */}
        <div
          className="d-flex flex-row justify-content-between align-items-end">

          <div className="d-flex flex-row">
            <div className="me-3 align-bottom dca-card-items-found-text">Displaying {results.length} programs</div>
            <div
              onClick={handleSortOrderChange}
              style={{ cursor: 'pointer', fontSize: '15px', lineHeight: '18px', color: '#585555', marginBottom: '2px' }}
              className='text-muted fs-7 fw-bolder align-self-end'
            >by {sortOrder} {sortKeyReturn(sortKey)} {sortOrder === 'Ascending' && (<i className="bi bi-arrow-up"></i>)}
              {sortOrder === 'Descending' && (<i className="bi bi-arrow-down"></i>)}
            </div>
          </div>


          <select
            className='form-select w-25 align-bottom'
            data-control="select2"
            value={sortKey}
            onChange={handleSortKeyChange}
            style={{ cursor: 'pointer' }}
          >
            {sortKeys.map((res, id) => (
              <option key={id} value={Object.values(res)}>{Object.keys(res)}</option>
            ))}
          </select>
        </div>

        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ marginTop: '30px' }}>
          <LoadingIndicator text={"Getting Data ... "} />
        </div>

      </div>)
  }

  const collegeIdConversion = (majorId, collegeId) => {
    let major: any = '-'
    let college: any = '-'
    // let metaRank: any = '-'

    if (majors.length > 0 && colleges.length > 0) {
      major = majors.filter((item) => { return parseInt(item.id) == majorId })[0].name

      college = colleges.filter((item) => { return parseInt(item.id) == collegeId })[0].name

      // metaRank = colleges.filter((item) => { return item.id == collegeId })[0].metaRank
    }

    return `${major} at ${college}`
  }

  const returnTooltip = (sizeIndex, collegeId, numEnrollees, degreesConferred) => {

    if (sizeIndex === null || sizeIndex === undefined) {
      return <TooltipComponent content={"There is insufficient data from the college to compute the popularity index for this major."} />
    }

    if (!colleges || colleges.length === 0) {
      return <TooltipComponent content={`College data loading...`} />;
    }

    let college = colleges.find((item) => parseInt(item.id) === collegeId);
    let collegeData = collegesData.find((item) => parseInt(item.id) === collegeId)

    if (!college) {
      return <TooltipComponent content={`College ID ${collegeId} data could not be found`} />;
    }

    if (college && collegeData && collegeData.majorSizeMetric) {
      switch (true) {
        case collegeData.majorSizeMetric === 1:
          return (
            <TooltipComponent
              content={`Indicates the popularity of this major at ${college.name} (on a 0-10 decile scale, 10 being the most popular.) Index value is based on ${degreesConferred} degrees conferred in this major in ${collegeData.majorSizeTimeframe}.`}
            />
          );
        case collegeData.majorSizeMetric === 2:
          return (
            <TooltipComponent
              content={`Indicates the popularity of this major at ${college.name} (on a 0-10 decile scale, 10 being the most popular.) Index value is based on ${numEnrollees} students enrolled in this major in ${collegeData.majorSizeTimeframe}.`}
            />
          )
      }
    }

    return <TooltipComponent content={`There is insufficient data from the college to compute the popularity index for this major.`} />;

  };

  let cardContentState = false

  // Using conditional to only render the card content when the major and college variables of the result object are not equal to '-'. Will otherwise render the cards before the text is assigned and will appear as '- at -'. Then after a short duration, all text will render correctly

  const cardContent = (
    <div>
      {results.map((res, index) => {
        const major = majors.find((item) => item.id === res.major_id)?.name || '-';
        const college = colleges.find((item) => parseInt(item.id) === res.college_id)?.name || '-';

        if (major !== '-' && college !== '-') {
          cardContentState = true
          return (
            <div
              key={res.id}
              className="mt-5 hover-elevate-up dca-card-item">

              {apiToken != undefined ? <SaveButtonMajorCard
                user={user}
                majorId={res.major_id}
                setUser={setUser}
                programID={res.id}
              /> : <SaveButtonCardNoAuth setPrompt={setPrompt}
                setNavigateTo={setNavigateTo} />}

              <div className="d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '15px' }}>
                <img style={{ width: '75px', height: '75px', margin: '10px 0 10px 0' }} alt="college_logo" src={collegeLogos[res.college_id]} />

                <h3 className="mt-5 d-flex">{collegeIdConversion(res.major_id, res.college_id)}</h3>

              </div>

              <div className="d-flex justify-content-center">
                <span
                  className="align-self-center"
                  style={{ width: '90%', marginTop: '20px' }}>{res.programDescription ? res.programDescription : '-'}</span>
              </div>

              <div className="d-flex flex-column justify-content-center" style={{ marginTop: '17px' }}>
                <div className="d-flex flex-row align-self-center justify-content-between" style={{ width: '90%', margin: '20px' }}>

                  <div className="d-flex flex-column">
                    <div className="dca-card-item-header d-flex flex-row">POPULARITY INDEX

                      <div className="ms-1">{returnTooltip(res.sizeIndex, res.college_id, res.numEnrollees, res.degreesConferred)}</div>

                    </div>
                    <div className="d-flex flex-row align-items-center">
                      <PopularityIndex index={res.sizeIndex} />
                    </div>
                  </div>

                  <div className="d-flex flex-column">
                    <div className="dca-card-item-header">MEDIAN ANNUAL EARNINGS {res.mdn4yrEarnings ? <span className="ms-1"><TooltipComponent content={`This is the median annual income (at 4 years after program completion) for working graduates from ${college} with this major.`} /></span> : <span className="ms-1"><TooltipComponent content={`Earnings data for ${college} graduates with this major is not currently available.`} /></span>}</div>
                    <div className="dca-card-item-text">{res.mdn4yrEarnings ? `$${numberConverter(res.mdn4yrEarnings)}` : '-'}</div>
                  </div>

                  <div className="d-flex flex-column">
                    <div className="dca-card-item-header">MORE INFO</div>
                    <a
                      className="dca-card-item-text"
                      href={res.overviewURL}
                      target="_blank"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleGAEventExtLink(res.id)
                      }}>Link</a>
                  </div>

                </div>
              </div>
            </div>
          )
        }

        else {

          return null
        }
      })}

    </div>
  )

  const cardContentIfFalse = (
    <div className="d-flex flex-column justify-content-center align-items-center" style={{ maxWidth: '1129px', marginTop: '80px' }}>
      <div className='mt-5'>No results meet your criteria</div>
    </div>
  )

  return (
    <div className='dca-card-container'>

      {/* HEADER */}
      <div className="d-flex flex-row justify-content-between align-items-end">

        <div className="d-flex flex-row">
          <div className="me-3 align-bottom dca-card-items-found-text">Displaying {results.length} programs</div>
          <div
            onClick={handleSortOrderChange}
            style={{ cursor: 'pointer', fontSize: '15px', lineHeight: '18px', color: '#585555', marginBottom: '2px' }}
            className='text-muted fs-7 fw-bolder align-self-end'
          >by {sortOrder} {sortKeyReturn(sortKey)} {sortOrder === 'Ascending' && (<i className="bi bi-arrow-up"></i>)}
            {sortOrder === 'Descending' && (<i className="bi bi-arrow-down"></i>)}
          </div>
        </div>

        <select
          className='form-select w-25 align-bottom'
          data-control="select2"
          value={sortKey}
          onChange={handleSortKeyChange}
        >
          {sortKeys.map((res, id) => (
            <option key={id} value={Object.values(res)}>{Object.keys(res)}</option>
          ))}
        </select>
      </div>
      {/* END HEADER */}

      {cardContentState ? cardContent : cardContentIfFalse}

    </div>
  )
}

export { Cards }
