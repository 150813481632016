export const collegeMap = [
    {
        "id": "186131",
        "name": "Princeton University",
        "hyphenated": "princeton-university"
    },
    {
        "id": "166683",
        "name": "Massachusetts Institute of Technology",
        "hyphenated": "massachusetts-institute-of-technology"
    },
    {
        "id": "130794",
        "name": "Yale University",
        "hyphenated": "yale-university"
    },
    {
        "id": "166027",
        "name": "Harvard University",
        "hyphenated": "harvard-university"
    },
    {
        "id": "243744",
        "name": "Stanford University",
        "hyphenated": "stanford-university"
    },
    {
        "id": "144050",
        "name": "University of Chicago",
        "hyphenated": "university-of-chicago"
    },
    {
        "id": "162928",
        "name": "Johns Hopkins University",
        "hyphenated": "johns-hopkins-university"
    },
    {
        "id": "215062",
        "name": "University of Pennsylvania",
        "hyphenated": "university-of-pennsylvania"
    },
    {
        "id": "110404",
        "name": "California Institute of Technology",
        "hyphenated": "california-institute-of-technology"
    },
    {
        "id": "147767",
        "name": "Northwestern University",
        "hyphenated": "northwestern-university"
    },
    {
        "id": "198419",
        "name": "Duke University",
        "hyphenated": "duke-university"
    },
    {
        "id": "182670",
        "name": "Dartmouth College",
        "hyphenated": "dartmouth-college"
    },
    {
        "id": "217156",
        "name": "Brown University",
        "hyphenated": "brown-university"
    },
    {
        "id": "221999",
        "name": "Vanderbilt University",
        "hyphenated": "vanderbilt-university"
    },
    {
        "id": "179867",
        "name": "Washington University in St Louis",
        "hyphenated": "washington-university-in-st-louis"
    },
    {
        "id": "227757",
        "name": "Rice University",
        "hyphenated": "rice-university"
    },
    {
        "id": "190415",
        "name": "Cornell University",
        "hyphenated": "cornell-university"
    },
    {
        "id": "152080",
        "name": "University of Notre Dame",
        "hyphenated": "university-of-notre-dame"
    },
    {
        "id": "190150",
        "name": "Columbia University",
        "hyphenated": "columbia-university"
    },
    {
        "id": "110635",
        "name": "University of California-Berkeley",
        "hyphenated": "university-of-california-berkeley"
    },
    {
        "id": "110662",
        "name": "University of California-Los Angeles",
        "hyphenated": "university-of-california-los-angeles"
    },
    {
        "id": "131496",
        "name": "Georgetown University",
        "hyphenated": "georgetown-university"
    },
    {
        "id": "139658",
        "name": "Emory University",
        "hyphenated": "emory-university"
    },
    {
        "id": "211440",
        "name": "Carnegie Mellon University",
        "hyphenated": "carnegie-mellon-university"
    },
    {
        "id": "123961",
        "name": "University of Southern California",
        "hyphenated": "university-of-southern-california"
    },
    {
        "id": "170976",
        "name": "University of Michigan",
        "hyphenated": "university-of-michigan"
    },
    {
        "id": "193900",
        "name": "New York University",
        "hyphenated": "new-york-university"
    },
    {
        "id": "234076",
        "name": "University of Virginia",
        "hyphenated": "university-of-virginia"
    },
    {
        "id": "134130",
        "name": "University of Florida",
        "hyphenated": "university-of-florida"
    },
    {
        "id": "199120",
        "name": "University of North Carolina at Chapel Hill",
        "hyphenated": "university-of-north-carolina-at-chapel-hill"
    },
    {
        "id": "199847",
        "name": "Wake Forest University",
        "hyphenated": "wake-forest-university"
    },
    {
        "id": "110705",
        "name": "University of California-Santa Barbara",
        "hyphenated": "university-of-california-santa-barbara"
    },
    {
        "id": "168148",
        "name": "Tufts University",
        "hyphenated": "tufts-university"
    },
    {
        "id": "110653",
        "name": "University of California-Irvine",
        "hyphenated": "university-of-california-irvine"
    },
    {
        "id": "110680",
        "name": "University of California-San Diego",
        "hyphenated": "university-of-california-san-diego"
    },
    {
        "id": "164924",
        "name": "Boston College",
        "hyphenated": "boston-college"
    },
    {
        "id": "195030",
        "name": "University of Rochester",
        "hyphenated": "university-of-rochester"
    },
    {
        "id": "110644",
        "name": "University of California-Davis",
        "hyphenated": "university-of-california-davis"
    },
    {
        "id": "228778",
        "name": "The University of Texas at Austin",
        "hyphenated": "the-university-of-texas-at-austin"
    },
    {
        "id": "240444",
        "name": "University of Wisconsin-Madison",
        "hyphenated": "university-of-wisconsin-madison"
    },
    {
        "id": "145637",
        "name": "University of Illinois Urbana-Champaign",
        "hyphenated": "university-of-illinois-urbana-champaign"
    },
    {
        "id": "164988",
        "name": "Boston University",
        "hyphenated": "boston-university"
    },
    {
        "id": "231624",
        "name": "William & Mary",
        "hyphenated": "william-&-mary"
    },
    {
        "id": "139755",
        "name": "Georgia Institute of Technology",
        "hyphenated": "georgia-institute-of-technology"
    },
    {
        "id": "160755",
        "name": "Tulane University of Louisiana",
        "hyphenated": "tulane-university-of-louisiana"
    },
    {
        "id": "165015",
        "name": "Brandeis University",
        "hyphenated": "brandeis-university"
    },
    {
        "id": "167358",
        "name": "Northeastern University",
        "hyphenated": "northeastern-university"
    },
    {
        "id": "201645",
        "name": "Case Western Reserve University",
        "hyphenated": "case-western-reserve-university"
    },
    {
        "id": "139959",
        "name": "University of Georgia",
        "hyphenated": "university-of-georgia"
    },
    {
        "id": "204796",
        "name": "Ohio State University",
        "hyphenated": "ohio-state-university"
    },
    {
        "id": "194824",
        "name": "Rensselaer Polytechnic Institute",
        "hyphenated": "rensselaer-polytechnic-institute"
    },
    {
        "id": "213543",
        "name": "Lehigh University",
        "hyphenated": "lehigh-university"
    },
    {
        "id": "216597",
        "name": "Villanova University",
        "hyphenated": "villanova-university"
    },
    {
        "id": "243780",
        "name": "Purdue University",
        "hyphenated": "purdue-university"
    },
    {
        "id": "121150",
        "name": "Pepperdine University",
        "hyphenated": "pepperdine-university"
    },
    {
        "id": "122931",
        "name": "Santa Clara University",
        "hyphenated": "santa-clara-university"
    },
    {
        "id": "134097",
        "name": "Florida State University",
        "hyphenated": "florida-state-university"
    },
    {
        "id": "135726",
        "name": "University of Miami",
        "hyphenated": "university-of-miami"
    },
    {
        "id": "163286",
        "name": "University of Maryland",
        "hyphenated": "university-of-maryland"
    },
    {
        "id": "236948",
        "name": "University of Washington",
        "hyphenated": "university-of-washington"
    },
    {
        "id": "131469",
        "name": "George Washington University",
        "hyphenated": "george-washington-university"
    },
    {
        "id": "174066",
        "name": "University of Minnesota-Twin Cities",
        "hyphenated": "university-of-minnesota-twin-cities"
    },
    {
        "id": "196413",
        "name": "Syracuse University",
        "hyphenated": "syracuse-university"
    },
    {
        "id": "215293",
        "name": "University of Pittsburgh-Pittsburgh Campus",
        "hyphenated": "university-of-pittsburgh-pittsburgh-campus"
    },
    {
        "id": "233921",
        "name": "Virginia Tech",
        "hyphenated": "virginia-tech"
    },
    {
        "id": "129020",
        "name": "University of Connecticut",
        "hyphenated": "university-of-connecticut"
    },
    {
        "id": "166629",
        "name": "University of Massachusetts-Amherst",
        "hyphenated": "university-of-massachusetts-amherst"
    },
    {
        "id": "168421",
        "name": "Worcester Polytechnic Institute",
        "hyphenated": "worcester-polytechnic-institute"
    },
    {
        "id": "197708",
        "name": "Yeshiva University",
        "hyphenated": "yeshiva-university"
    },
    {
        "id": "228723",
        "name": "Texas A & M University",
        "hyphenated": "texas-a-&-m-university"
    },
    {
        "id": "131159",
        "name": "American University",
        "hyphenated": "american-university"
    },
    {
        "id": "151351",
        "name": "Indiana University",
        "hyphenated": "indiana-university"
    },
    {
        "id": "191241",
        "name": "Fordham University",
        "hyphenated": "fordham-university"
    },
    {
        "id": "199193",
        "name": "North Carolina State University at Raleigh",
        "hyphenated": "north-carolina-state-university-at-raleigh"
    },
    {
        "id": "228246",
        "name": "Southern Methodist University",
        "hyphenated": "southern-methodist-university"
    },
    {
        "id": "117946",
        "name": "Loyola Marymount University",
        "hyphenated": "loyola-marymount-university"
    },
    {
        "id": "171100",
        "name": "Michigan State University",
        "hyphenated": "michigan-state-university"
    },
    {
        "id": "196097",
        "name": "Stony Brook University",
        "hyphenated": "stony-brook-university"
    },
    {
        "id": "217882",
        "name": "Clemson University",
        "hyphenated": "clemson-university"
    },
    {
        "id": "223232",
        "name": "Baylor University",
        "hyphenated": "baylor-university"
    },
    {
        "id": "495767",
        "name": "The Pennsylvania State University",
        "hyphenated": "the-pennsylvania-state-university"
    },
    {
        "id": "110714",
        "name": "University of California-Santa Cruz",
        "hyphenated": "university-of-california-santa-cruz"
    },
    {
        "id": "153658",
        "name": "University of Iowa",
        "hyphenated": "university-of-iowa"
    },
    {
        "id": "186867",
        "name": "Stevens Institute of Technology",
        "hyphenated": "stevens-institute-of-technology"
    },
    {
        "id": "196079",
        "name": "Binghamton University",
        "hyphenated": "binghamton-university"
    },
    {
        "id": "235316",
        "name": "Gonzaga University",
        "hyphenated": "gonzaga-university"
    },
    {
        "id": "239105",
        "name": "Marquette University",
        "hyphenated": "marquette-university"
    },
    {
        "id": "110671",
        "name": "University of California-Riverside",
        "hyphenated": "university-of-california-riverside"
    },
    {
        "id": "126775",
        "name": "Colorado School of Mines",
        "hyphenated": "colorado-school-of-mines"
    },
    {
        "id": "130943",
        "name": "University of Delaware",
        "hyphenated": "university-of-delaware"
    },
    {
        "id": "131520",
        "name": "Howard University",
        "hyphenated": "howard-university"
    },
    {
        "id": "196088",
        "name": "University at Buffalo",
        "hyphenated": "university-at-buffalo"
    },
    {
        "id": "198516",
        "name": "Elon University",
        "hyphenated": "elon-university"
    },
    {
        "id": "228875",
        "name": "Texas Christian University",
        "hyphenated": "texas-christian-university"
    },
    {
        "id": "230038",
        "name": "Brigham Young University",
        "hyphenated": "brigham-young-university"
    },
    {
        "id": "100858",
        "name": "Auburn University",
        "hyphenated": "auburn-university"
    },
    {
        "id": "122436",
        "name": "University of San Diego",
        "hyphenated": "university-of-san-diego"
    },
    {
        "id": "126614",
        "name": "University of Colorado Boulder",
        "hyphenated": "university-of-colorado-boulder"
    },
    {
        "id": "137351",
        "name": "University of South Florida",
        "hyphenated": "university-of-south-florida"
    },
    {
        "id": "145600",
        "name": "University of Illinois Chicago",
        "hyphenated": "university-of-illinois-chicago"
    },
    {
        "id": "165334",
        "name": "Clark University",
        "hyphenated": "clark-university"
    },
    {
        "id": "185828",
        "name": "New Jersey Institute of Technology",
        "hyphenated": "new-jersey-institute-of-technology"
    },
    {
        "id": "445188",
        "name": "University of California-Merced",
        "hyphenated": "university-of-california-merced"
    },
    {
        "id": "104179",
        "name": "University of Arizona",
        "hyphenated": "university-of-arizona"
    },
    {
        "id": "122612",
        "name": "University of San Francisco",
        "hyphenated": "university-of-san-francisco"
    },
    {
        "id": "127060",
        "name": "University of Denver",
        "hyphenated": "university-of-denver"
    },
    {
        "id": "179159",
        "name": "Saint Louis University",
        "hyphenated": "saint-louis-university"
    },
    {
        "id": "195003",
        "name": "Rochester Institute of Technology",
        "hyphenated": "rochester-institute-of-technology"
    },
    {
        "id": "196103",
        "name": "SUNY College of Environmental Science and Forestry",
        "hyphenated": "suny-college-of-environmental-science-and-forestry"
    },
    {
        "id": "204024",
        "name": "Miami University-Oxford",
        "hyphenated": "miami-university-oxford"
    },
    {
        "id": "209551",
        "name": "University of Oregon",
        "hyphenated": "university-of-oregon"
    },
    {
        "id": "212054",
        "name": "Drexel University",
        "hyphenated": "drexel-university"
    },
    {
        "id": "230764",
        "name": "University of Utah",
        "hyphenated": "university-of-utah"
    },
    {
        "id": "129242",
        "name": "Fairfield University",
        "hyphenated": "fairfield-university"
    },
    {
        "id": "146719",
        "name": "Loyola University Chicago",
        "hyphenated": "loyola-university-chicago"
    },
    {
        "id": "181002",
        "name": "Creighton University",
        "hyphenated": "creighton-university"
    },
    {
        "id": "186399",
        "name": "Rutgers University-Newark",
        "hyphenated": "rutgers-university-newark"
    },
    {
        "id": "218663",
        "name": "University of South Carolina-Columbia",
        "hyphenated": "university-of-south-carolina-columbia"
    },
    {
        "id": "221759",
        "name": "The University of Tennessee-Knoxville",
        "hyphenated": "the-university-of-tennessee-knoxville"
    },
    {
        "id": "104151",
        "name": "Arizona State University",
        "hyphenated": "arizona-state-university"
    },
    {
        "id": "111948",
        "name": "Chapman University",
        "hyphenated": "chapman-university"
    },
    {
        "id": "155317",
        "name": "University of Kansas",
        "hyphenated": "university-of-kansas"
    },
    {
        "id": "178396",
        "name": "University of Missouri-Columbia",
        "hyphenated": "university-of-missouri-columbia"
    },
    {
        "id": "216339",
        "name": "Temple University",
        "hyphenated": "temple-university"
    },
    {
        "id": "231174",
        "name": "University of Vermont",
        "hyphenated": "university-of-vermont"
    },
    {
        "id": "102049",
        "name": "Samford University",
        "hyphenated": "samford-university"
    },
    {
        "id": "131450",
        "name": "Gallaudet University",
        "hyphenated": "gallaudet-university"
    },
    {
        "id": "145725",
        "name": "Illinois Institute of Technology",
        "hyphenated": "illinois-institute-of-technology"
    },
    {
        "id": "153603",
        "name": "Iowa State University",
        "hyphenated": "iowa-state-university"
    },
    {
        "id": "186371",
        "name": "Rutgers University-Camden",
        "hyphenated": "rutgers-university-camden"
    },
    {
        "id": "190044",
        "name": "Clarkson University",
        "hyphenated": "clarkson-university"
    },
    {
        "id": "193654",
        "name": "The New School",
        "hyphenated": "the-new-school"
    },
    {
        "id": "202480",
        "name": "University of Dayton",
        "hyphenated": "university-of-dayton"
    },
    {
        "id": "207500",
        "name": "University of Oklahoma-Norman Campus",
        "hyphenated": "university-of-oklahoma-norman-campus"
    },
    {
        "id": "216366",
        "name": "Thomas Jefferson University",
        "hyphenated": "thomas-jefferson-university"
    },
    {
        "id": "100663",
        "name": "University of Alabama at Birmingham",
        "hyphenated": "university-of-alabama-at-birmingham"
    },
    {
        "id": "100751",
        "name": "The University of Alabama",
        "hyphenated": "the-university-of-alabama"
    },
    {
        "id": "110583",
        "name": "California State University-Long Beach",
        "hyphenated": "california-state-university-long-beach"
    },
    {
        "id": "132903",
        "name": "University of Central Florida",
        "hyphenated": "university-of-central-florida"
    },
    {
        "id": "144740",
        "name": "DePaul University",
        "hyphenated": "depaul-university"
    },
    {
        "id": "153269",
        "name": "Drake University",
        "hyphenated": "drake-university"
    },
    {
        "id": "157085",
        "name": "University of Kentucky",
        "hyphenated": "university-of-kentucky"
    },
    {
        "id": "163268",
        "name": "University of Maryland-Baltimore County",
        "hyphenated": "university-of-maryland-baltimore-county"
    },
    {
        "id": "174914",
        "name": "University of St Thomas",
        "hyphenated": "university-of-st-thomas"
    },
    {
        "id": "183044",
        "name": "University of New Hampshire-Main Campus",
        "hyphenated": "university-of-new-hampshire-main-campus"
    },
    {
        "id": "186584",
        "name": "Seton Hall University",
        "hyphenated": "seton-hall-university"
    },
    {
        "id": "207971",
        "name": "University of Tulsa",
        "hyphenated": "university-of-tulsa"
    },
    {
        "id": "232186",
        "name": "George Mason University",
        "hyphenated": "george-mason-university"
    },
    {
        "id": "236595",
        "name": "Seattle University",
        "hyphenated": "seattle-university"
    },
    {
        "id": "168342",
        "name": "Williams College",
        "hyphenated": "williams-college"
    },
    {
        "id": "164465",
        "name": "Amherst College",
        "hyphenated": "amherst-college"
    },
    {
        "id": "121345",
        "name": "Pomona College",
        "hyphenated": "pomona-college"
    },
    {
        "id": "216287",
        "name": "Swarthmore College",
        "hyphenated": "swarthmore-college"
    },
    {
        "id": "168218",
        "name": "Wellesley College",
        "hyphenated": "wellesley-college"
    },
    {
        "id": "161004",
        "name": "Bowdoin College",
        "hyphenated": "bowdoin-college"
    },
    {
        "id": "164155",
        "name": "United States Naval Academy",
        "hyphenated": "united-states-naval-academy"
    },
    {
        "id": "173258",
        "name": "Carleton College",
        "hyphenated": "carleton-college"
    },
    {
        "id": "112260",
        "name": "Claremont McKenna College",
        "hyphenated": "claremont-mckenna-college"
    },
    {
        "id": "197036",
        "name": "United States Military Academy",
        "hyphenated": "united-states-military-academy"
    },
    {
        "id": "230959",
        "name": "Middlebury College",
        "hyphenated": "middlebury-college"
    },
    {
        "id": "234207",
        "name": "Washington and Lee University",
        "hyphenated": "washington-and-lee-university"
    },
    {
        "id": "167835",
        "name": "Smith College",
        "hyphenated": "smith-college"
    },
    {
        "id": "197133",
        "name": "Vassar College",
        "hyphenated": "vassar-college"
    },
    {
        "id": "153384",
        "name": "Grinnell College",
        "hyphenated": "grinnell-college"
    },
    {
        "id": "191515",
        "name": "Hamilton College",
        "hyphenated": "hamilton-college"
    },
    {
        "id": "198385",
        "name": "Davidson College",
        "hyphenated": "davidson-college"
    },
    {
        "id": "128328",
        "name": "United States Air Force Academy",
        "hyphenated": "united-states-air-force-academy"
    },
    {
        "id": "130697",
        "name": "Wesleyan University",
        "hyphenated": "wesleyan-university"
    },
    {
        "id": "189097",
        "name": "Barnard College",
        "hyphenated": "barnard-college"
    },
    {
        "id": "190099",
        "name": "Colgate University",
        "hyphenated": "colgate-university"
    },
    {
        "id": "212911",
        "name": "Haverford College",
        "hyphenated": "haverford-college"
    },
    {
        "id": "233374",
        "name": "University of Richmond",
        "hyphenated": "university-of-richmond"
    },
    {
        "id": "161086",
        "name": "Colby College",
        "hyphenated": "colby-college"
    },
    {
        "id": "160977",
        "name": "Bates College",
        "hyphenated": "bates-college"
    },
    {
        "id": "156295",
        "name": "Berea College",
        "hyphenated": "berea-college"
    },
    {
        "id": "126678",
        "name": "Colorado College",
        "hyphenated": "colorado-college"
    },
    {
        "id": "173902",
        "name": "Macalester College",
        "hyphenated": "macalester-college"
    },
    {
        "id": "115409",
        "name": "Harvey Mudd College",
        "hyphenated": "harvey-mudd-college"
    },
    {
        "id": "399911",
        "name": "Soka University of America",
        "hyphenated": "soka-university-of-america"
    },
    {
        "id": "186380",
        "name": "Rutgers University",
        "hyphenated": "rutgers-university"
    },
    {
        "id": "100654",
        "name": "Alabama A & M University",
        "hyphenated": "alabama-a-&-m-university"
    },
    {
        "id": "100690",
        "name": "Amridge University",
        "hyphenated": "amridge-university"
    },
    {
        "id": "100706",
        "name": "University of Alabama in Huntsville",
        "hyphenated": "university-of-alabama-in-huntsville"
    },
    {
        "id": "100724",
        "name": "Alabama State University",
        "hyphenated": "alabama-state-university"
    },
    {
        "id": "100760",
        "name": "Central Alabama Community College",
        "hyphenated": "central-alabama-community-college"
    },
    {
        "id": "100812",
        "name": "Athens State University",
        "hyphenated": "athens-state-university"
    },
    {
        "id": "100830",
        "name": "Auburn University at Montgomery",
        "hyphenated": "auburn-university-at-montgomery"
    },
    {
        "id": "100937",
        "name": "Birmingham-Southern College",
        "hyphenated": "birmingham-southern-college"
    },
    {
        "id": "101028",
        "name": "Chattahoochee Valley Community College",
        "hyphenated": "chattahoochee-valley-community-college"
    },
    {
        "id": "101116",
        "name": "South University-Montgomery",
        "hyphenated": "south-university-montgomery"
    },
    {
        "id": "101143",
        "name": "Enterprise State Community College",
        "hyphenated": "enterprise-state-community-college"
    },
    {
        "id": "101161",
        "name": "Coastal Alabama Community College",
        "hyphenated": "coastal-alabama-community-college"
    },
    {
        "id": "101189",
        "name": "Faulkner University",
        "hyphenated": "faulkner-university"
    },
    {
        "id": "101240",
        "name": "Gadsden State Community College",
        "hyphenated": "gadsden-state-community-college"
    },
    {
        "id": "101277",
        "name": "New Beginning College of Cosmetology",
        "hyphenated": "new-beginning-college-of-cosmetology"
    },
    {
        "id": "101286",
        "name": "George C Wallace Community College-Dothan",
        "hyphenated": "george-c-wallace-community-college-dothan"
    },
    {
        "id": "101295",
        "name": "George C Wallace State Community College-Hanceville",
        "hyphenated": "george-c-wallace-state-community-college-hanceville"
    },
    {
        "id": "101301",
        "name": "George C Wallace State Community College-Selma",
        "hyphenated": "george-c-wallace-state-community-college-selma"
    },
    {
        "id": "101365",
        "name": "Herzing University-Birmingham",
        "hyphenated": "herzing-university-birmingham"
    },
    {
        "id": "101435",
        "name": "Huntingdon College",
        "hyphenated": "huntingdon-college"
    },
    {
        "id": "101453",
        "name": "Heritage Christian University",
        "hyphenated": "heritage-christian-university"
    },
    {
        "id": "101462",
        "name": "J. F. Drake State Community and Technical College",
        "hyphenated": "j.-f.-drake-state-community-and-technical-college"
    },
    {
        "id": "101471",
        "name": "J F Ingram State Technical College",
        "hyphenated": "j-f-ingram-state-technical-college"
    },
    {
        "id": "101480",
        "name": "Jacksonville State University",
        "hyphenated": "jacksonville-state-university"
    },
    {
        "id": "101505",
        "name": "Jefferson State Community College",
        "hyphenated": "jefferson-state-community-college"
    },
    {
        "id": "101514",
        "name": "John C Calhoun State Community College",
        "hyphenated": "john-c-calhoun-state-community-college"
    },
    {
        "id": "101569",
        "name": "Lawson State Community College",
        "hyphenated": "lawson-state-community-college"
    },
    {
        "id": "101587",
        "name": "University of West Alabama",
        "hyphenated": "university-of-west-alabama"
    },
    {
        "id": "101602",
        "name": "Lurleen B Wallace Community College",
        "hyphenated": "lurleen-b-wallace-community-college"
    },
    {
        "id": "101648",
        "name": "Marion Military Institute",
        "hyphenated": "marion-military-institute"
    },
    {
        "id": "101675",
        "name": "Miles College",
        "hyphenated": "miles-college"
    },
    {
        "id": "101693",
        "name": "University of Mobile",
        "hyphenated": "university-of-mobile"
    },
    {
        "id": "101709",
        "name": "University of Montevallo",
        "hyphenated": "university-of-montevallo"
    },
    {
        "id": "101736",
        "name": "Northwest-Shoals Community College",
        "hyphenated": "northwest-shoals-community-college"
    },
    {
        "id": "101879",
        "name": "University of North Alabama",
        "hyphenated": "university-of-north-alabama"
    },
    {
        "id": "101897",
        "name": "Northeast Alabama Community College",
        "hyphenated": "northeast-alabama-community-college"
    },
    {
        "id": "101912",
        "name": "Oakwood University",
        "hyphenated": "oakwood-university"
    },
    {
        "id": "101994",
        "name": "Reid State Technical College",
        "hyphenated": "reid-state-technical-college"
    },
    {
        "id": "102030",
        "name": "Bishop State Community College",
        "hyphenated": "bishop-state-community-college"
    },
    {
        "id": "102067",
        "name": "Shelton State Community College",
        "hyphenated": "shelton-state-community-college"
    },
    {
        "id": "102076",
        "name": "Snead State Community College",
        "hyphenated": "snead-state-community-college"
    },
    {
        "id": "102094",
        "name": "University of South Alabama",
        "hyphenated": "university-of-south-alabama"
    },
    {
        "id": "102234",
        "name": "Spring Hill College",
        "hyphenated": "spring-hill-college"
    },
    {
        "id": "102270",
        "name": "Stillman College",
        "hyphenated": "stillman-college"
    },
    {
        "id": "102298",
        "name": "Talladega College",
        "hyphenated": "talladega-college"
    },
    {
        "id": "102313",
        "name": "H Councill Trenholm State Community College",
        "hyphenated": "h-councill-trenholm-state-community-college"
    },
    {
        "id": "102368",
        "name": "Troy University",
        "hyphenated": "troy-university"
    },
    {
        "id": "102377",
        "name": "Tuskegee University",
        "hyphenated": "tuskegee-university"
    },
    {
        "id": "102395",
        "name": "United States Sports Academy",
        "hyphenated": "united-states-sports-academy"
    },
    {
        "id": "102429",
        "name": "Bevill State Community College",
        "hyphenated": "bevill-state-community-college"
    },
    {
        "id": "102553",
        "name": "University of Alaska Anchorage",
        "hyphenated": "university-of-alaska-anchorage"
    },
    {
        "id": "102580",
        "name": "Alaska Bible College",
        "hyphenated": "alaska-bible-college"
    },
    {
        "id": "102614",
        "name": "University of Alaska Fairbanks",
        "hyphenated": "university-of-alaska-fairbanks"
    },
    {
        "id": "102632",
        "name": "University of Alaska Southeast",
        "hyphenated": "university-of-alaska-southeast"
    },
    {
        "id": "102669",
        "name": "Alaska Pacific University",
        "hyphenated": "alaska-pacific-university"
    },
    {
        "id": "102711",
        "name": "Alaska Vocational Technical Center",
        "hyphenated": "alaska-vocational-technical-center"
    },
    {
        "id": "102845",
        "name": "Charter College",
        "hyphenated": "charter-college"
    },
    {
        "id": "103501",
        "name": "Alaska Career College",
        "hyphenated": "alaska-career-college"
    },
    {
        "id": "103741",
        "name": "Empire Beauty School-Tucson",
        "hyphenated": "empire-beauty-school-tucson"
    },
    {
        "id": "103893",
        "name": "Carrington College-Phoenix North",
        "hyphenated": "carrington-college-phoenix-north"
    },
    {
        "id": "103909",
        "name": "Carrington College-Mesa",
        "hyphenated": "carrington-college-mesa"
    },
    {
        "id": "103927",
        "name": "Carrington College-Tucson",
        "hyphenated": "carrington-college-tucson"
    },
    {
        "id": "103954",
        "name": "Arizona Academy of Beauty",
        "hyphenated": "arizona-academy-of-beauty"
    },
    {
        "id": "103963",
        "name": "Miller-Motte College-Arizona Automotive Institute",
        "hyphenated": "miller-motte-college-arizona-automotive-institute"
    },
    {
        "id": "104090",
        "name": "Brookline College-Phoenix",
        "hyphenated": "brookline-college-phoenix"
    },
    {
        "id": "104160",
        "name": "Arizona Western College",
        "hyphenated": "arizona-western-college"
    },
    {
        "id": "104346",
        "name": "Central Arizona College",
        "hyphenated": "central-arizona-college"
    },
    {
        "id": "104391",
        "name": "Charles of Italy Beauty College",
        "hyphenated": "charles-of-italy-beauty-college"
    },
    {
        "id": "104425",
        "name": "Cochise County Community College District",
        "hyphenated": "cochise-county-community-college-district"
    },
    {
        "id": "104443",
        "name": "Empire Beauty School-Chandler",
        "hyphenated": "empire-beauty-school-chandler"
    },
    {
        "id": "104568",
        "name": "Avalon Institute-Mesa",
        "hyphenated": "avalon-institute-mesa"
    },
    {
        "id": "104577",
        "name": "Eastern Arizona College",
        "hyphenated": "eastern-arizona-college"
    },
    {
        "id": "104586",
        "name": "Embry-Riddle Aeronautical University-Prescott",
        "hyphenated": "embry-riddle-aeronautical-university-prescott"
    },
    {
        "id": "104665",
        "name": "The School of Architecture",
        "hyphenated": "the-school-of-architecture"
    },
    {
        "id": "104708",
        "name": "Glendale Community College",
        "hyphenated": "glendale-community-college"
    },
    {
        "id": "104717",
        "name": "Grand Canyon University",
        "hyphenated": "grand-canyon-university"
    },
    {
        "id": "105145",
        "name": "GateWay Community College",
        "hyphenated": "gateway-community-college"
    },
    {
        "id": "105154",
        "name": "Mesa Community College",
        "hyphenated": "mesa-community-college"
    },
    {
        "id": "105206",
        "name": "Mohave Community College",
        "hyphenated": "mohave-community-college"
    },
    {
        "id": "105215",
        "name": "Universal Technical Institute of Arizona Inc-Motorcycle Mechanics Institute Division",
        "hyphenated": "universal-technical-institute-of-arizona-inc-motorcycle-mechanics-institute-division"
    },
    {
        "id": "105297",
        "name": "Dine College",
        "hyphenated": "dine-college"
    },
    {
        "id": "105330",
        "name": "Northern Arizona University",
        "hyphenated": "northern-arizona-university"
    },
    {
        "id": "105349",
        "name": "Northland Pioneer College",
        "hyphenated": "northland-pioneer-college"
    },
    {
        "id": "105367",
        "name": "Ottawa University-Phoenix",
        "hyphenated": "ottawa-university-phoenix"
    },
    {
        "id": "105428",
        "name": "Phoenix College",
        "hyphenated": "phoenix-college"
    },
    {
        "id": "105525",
        "name": "Pima Community College",
        "hyphenated": "pima-community-college"
    },
    {
        "id": "105534",
        "name": "Pima Medical Institute-Tucson",
        "hyphenated": "pima-medical-institute-tucson"
    },
    {
        "id": "105543",
        "name": "Pima Medical Institute-Albuquerque",
        "hyphenated": "pima-medical-institute-albuquerque"
    },
    {
        "id": "105589",
        "name": "Prescott College",
        "hyphenated": "prescott-college"
    },
    {
        "id": "105659",
        "name": "Refrigeration School Inc",
        "hyphenated": "refrigeration-school-inc"
    },
    {
        "id": "105668",
        "name": "Rio Salado College",
        "hyphenated": "rio-salado-college"
    },
    {
        "id": "105677",
        "name": "Roberto-Venn School of Luthiery",
        "hyphenated": "roberto-venn-school-of-luthiery"
    },
    {
        "id": "105747",
        "name": "Scottsdale Community College",
        "hyphenated": "scottsdale-community-college"
    },
    {
        "id": "105792",
        "name": "South Mountain Community College",
        "hyphenated": "south-mountain-community-college"
    },
    {
        "id": "105899",
        "name": "Arizona Christian University",
        "hyphenated": "arizona-christian-university"
    },
    {
        "id": "106041",
        "name": "Universal Technical Institute of Arizona Inc",
        "hyphenated": "universal-technical-institute-of-arizona-inc"
    },
    {
        "id": "106148",
        "name": "Yavapai College",
        "hyphenated": "yavapai-college"
    },
    {
        "id": "106245",
        "name": "University of Arkansas at Little Rock",
        "hyphenated": "university-of-arkansas-at-little-rock"
    },
    {
        "id": "106263",
        "name": "University of Arkansas for Medical Sciences",
        "hyphenated": "university-of-arkansas-for-medical-sciences"
    },
    {
        "id": "106306",
        "name": "Arkansas Baptist College",
        "hyphenated": "arkansas-baptist-college"
    },
    {
        "id": "106315",
        "name": "Arkansas Beauty School-Little Rock",
        "hyphenated": "arkansas-beauty-school-little-rock"
    },
    {
        "id": "106324",
        "name": "Arkansas Beauty College",
        "hyphenated": "arkansas-beauty-college"
    },
    {
        "id": "106342",
        "name": "Lyon College",
        "hyphenated": "lyon-college"
    },
    {
        "id": "106351",
        "name": "Arkansas Technical School",
        "hyphenated": "arkansas-technical-school"
    },
    {
        "id": "106360",
        "name": "Arthur's Beauty College",
        "hyphenated": "arthur's-beauty-college"
    },
    {
        "id": "106397",
        "name": "University of Arkansas",
        "hyphenated": "university-of-arkansas"
    },
    {
        "id": "106412",
        "name": "University of Arkansas at Pine Bluff",
        "hyphenated": "university-of-arkansas-at-pine-bluff"
    },
    {
        "id": "106449",
        "name": "Arkansas State University-Beebe",
        "hyphenated": "arkansas-state-university-beebe"
    },
    {
        "id": "106458",
        "name": "Arkansas State University",
        "hyphenated": "arkansas-state-university"
    },
    {
        "id": "106467",
        "name": "Arkansas Tech University",
        "hyphenated": "arkansas-tech-university"
    },
    {
        "id": "106485",
        "name": "University of Arkansas at Monticello",
        "hyphenated": "university-of-arkansas-at-monticello"
    },
    {
        "id": "106494",
        "name": "Arthur's Beauty College",
        "hyphenated": "arthur's-beauty-college"
    },
    {
        "id": "106546",
        "name": "Baptist Health College Little Rock",
        "hyphenated": "baptist-health-college-little-rock"
    },
    {
        "id": "106625",
        "name": "Black River Technical College",
        "hyphenated": "black-river-technical-college"
    },
    {
        "id": "106704",
        "name": "University of Central Arkansas",
        "hyphenated": "university-of-central-arkansas"
    },
    {
        "id": "106713",
        "name": "Central Baptist College",
        "hyphenated": "central-baptist-college"
    },
    {
        "id": "106795",
        "name": "Cossatot Community College of the University of Arkansas",
        "hyphenated": "cossatot-community-college-of-the-university-of-arkansas"
    },
    {
        "id": "106810",
        "name": "Crowley's Ridge College",
        "hyphenated": "crowley's-ridge-college"
    },
    {
        "id": "106883",
        "name": "East Arkansas Community College",
        "hyphenated": "east-arkansas-community-college"
    },
    {
        "id": "106892",
        "name": "Eastern College of Health Vocations-Little Rock",
        "hyphenated": "eastern-college-of-health-vocations-little-rock"
    },
    {
        "id": "106908",
        "name": "Paul Mitchell the School-Little Rock",
        "hyphenated": "paul-mitchell-the-school-little-rock"
    },
    {
        "id": "106980",
        "name": "National Park College",
        "hyphenated": "national-park-college"
    },
    {
        "id": "106999",
        "name": "University of Arkansas Community College-Batesville",
        "hyphenated": "university-of-arkansas-community-college-batesville"
    },
    {
        "id": "107044",
        "name": "Harding University",
        "hyphenated": "harding-university"
    },
    {
        "id": "107071",
        "name": "Henderson State University",
        "hyphenated": "henderson-state-university"
    },
    {
        "id": "107080",
        "name": "Hendrix College",
        "hyphenated": "hendrix-college"
    },
    {
        "id": "107099",
        "name": "Hot Springs Beauty College",
        "hyphenated": "hot-springs-beauty-college"
    },
    {
        "id": "107123",
        "name": "Jefferson Regional School of Nursing",
        "hyphenated": "jefferson-regional-school-of-nursing"
    },
    {
        "id": "107141",
        "name": "John Brown University",
        "hyphenated": "john-brown-university"
    },
    {
        "id": "107220",
        "name": "Career Academy of Hair Design",
        "hyphenated": "career-academy-of-hair-design"
    },
    {
        "id": "107293",
        "name": "Academy of Salon and Spa",
        "hyphenated": "academy-of-salon-and-spa"
    },
    {
        "id": "107318",
        "name": "Arkansas State University Mid-South",
        "hyphenated": "arkansas-state-university-mid-south"
    },
    {
        "id": "107327",
        "name": "Arkansas Northeastern College",
        "hyphenated": "arkansas-northeastern-college"
    },
    {
        "id": "107442",
        "name": "New Tyler Barber College Inc",
        "hyphenated": "new-tyler-barber-college-inc"
    },
    {
        "id": "107460",
        "name": "North Arkansas College",
        "hyphenated": "north-arkansas-college"
    },
    {
        "id": "107488",
        "name": "Northwest Technical Institute",
        "hyphenated": "northwest-technical-institute"
    },
    {
        "id": "107512",
        "name": "Ouachita Baptist University",
        "hyphenated": "ouachita-baptist-university"
    },
    {
        "id": "107521",
        "name": "Arkansas State University Three Rivers",
        "hyphenated": "arkansas-state-university-three-rivers"
    },
    {
        "id": "107549",
        "name": "Ozarka College",
        "hyphenated": "ozarka-college"
    },
    {
        "id": "107558",
        "name": "University of the Ozarks",
        "hyphenated": "university-of-the-ozarks"
    },
    {
        "id": "107585",
        "name": "University of Arkansas Community College-Morrilton",
        "hyphenated": "university-of-arkansas-community-college-morrilton"
    },
    {
        "id": "107600",
        "name": "Philander Smith College",
        "hyphenated": "philander-smith-college"
    },
    {
        "id": "107619",
        "name": "Phillips Community College of the University of Arkansas",
        "hyphenated": "phillips-community-college-of-the-university-of-arkansas"
    },
    {
        "id": "107637",
        "name": "Southeast Arkansas College",
        "hyphenated": "southeast-arkansas-college"
    },
    {
        "id": "107655",
        "name": "Professional Cosmetology Education Center",
        "hyphenated": "professional-cosmetology-education-center"
    },
    {
        "id": "107664",
        "name": "University of Arkansas-Pulaski Technical College",
        "hyphenated": "university-of-arkansas-pulaski-technical-college"
    },
    {
        "id": "107725",
        "name": "University of Arkansas Hope-Texarkana",
        "hyphenated": "university-of-arkansas-hope-texarkana"
    },
    {
        "id": "107743",
        "name": "University of Arkansas Community College Rich Mountain",
        "hyphenated": "university-of-arkansas-community-college-rich-mountain"
    },
    {
        "id": "107789",
        "name": "Paul Mitchell the School-Arkansas",
        "hyphenated": "paul-mitchell-the-school-arkansas"
    },
    {
        "id": "107831",
        "name": "Searcy Beauty College",
        "hyphenated": "searcy-beauty-college"
    },
    {
        "id": "107840",
        "name": "Shorter College",
        "hyphenated": "shorter-college"
    },
    {
        "id": "107877",
        "name": "Williams Baptist University",
        "hyphenated": "williams-baptist-university"
    },
    {
        "id": "107974",
        "name": "South Arkansas Community College",
        "hyphenated": "south-arkansas-community-college"
    },
    {
        "id": "107983",
        "name": "Southern Arkansas University Main Campus",
        "hyphenated": "southern-arkansas-university-main-campus"
    },
    {
        "id": "107992",
        "name": "Southern Arkansas University Tech",
        "hyphenated": "southern-arkansas-university-tech"
    },
    {
        "id": "108065",
        "name": "Velvatex College of Beauty Culture",
        "hyphenated": "velvatex-college-of-beauty-culture"
    },
    {
        "id": "108092",
        "name": "University of Arkansas-Fort Smith",
        "hyphenated": "university-of-arkansas-fort-smith"
    },
    {
        "id": "108232",
        "name": "Academy of Art University",
        "hyphenated": "academy-of-art-university"
    },
    {
        "id": "108269",
        "name": "Academy of Chinese Culture and Health Sciences",
        "hyphenated": "academy-of-chinese-culture-and-health-sciences"
    },
    {
        "id": "108667",
        "name": "College of Alameda",
        "hyphenated": "college-of-alameda"
    },
    {
        "id": "108807",
        "name": "Allan Hancock College",
        "hyphenated": "allan-hancock-college"
    },
    {
        "id": "108852",
        "name": "American Academy of Dramatic Arts-Los Angeles",
        "hyphenated": "american-academy-of-dramatic-arts-los-angeles"
    },
    {
        "id": "108861",
        "name": "Berkeley School of Theology",
        "hyphenated": "berkeley-school-of-theology"
    },
    {
        "id": "108870",
        "name": "American Film Institute Conservatory",
        "hyphenated": "american-film-institute-conservatory"
    },
    {
        "id": "108977",
        "name": "American Beauty College",
        "hyphenated": "american-beauty-college"
    },
    {
        "id": "109040",
        "name": "American Career College-Los Angeles",
        "hyphenated": "american-career-college-los-angeles"
    },
    {
        "id": "109086",
        "name": "American Conservatory Theater",
        "hyphenated": "american-conservatory-theater"
    },
    {
        "id": "109208",
        "name": "American River College",
        "hyphenated": "american-river-college"
    },
    {
        "id": "109350",
        "name": "Antelope Valley Community College District",
        "hyphenated": "antelope-valley-community-college-district"
    },
    {
        "id": "109651",
        "name": "Art Center College of Design",
        "hyphenated": "art-center-college-of-design"
    },
    {
        "id": "109721",
        "name": "Associated Technical College-Los Angeles",
        "hyphenated": "associated-technical-college-los-angeles"
    },
    {
        "id": "109730",
        "name": "Associated Technical College-San Diego",
        "hyphenated": "associated-technical-college-san-diego"
    },
    {
        "id": "109785",
        "name": "Azusa Pacific University",
        "hyphenated": "azusa-pacific-university"
    },
    {
        "id": "109819",
        "name": "Bakersfield College",
        "hyphenated": "bakersfield-college"
    },
    {
        "id": "109907",
        "name": "Barstow Community College",
        "hyphenated": "barstow-community-college"
    },
    {
        "id": "109934",
        "name": "Bellus Academy-Chula Vista",
        "hyphenated": "bellus-academy-chula-vista"
    },
    {
        "id": "110060",
        "name": "Bethesda University",
        "hyphenated": "bethesda-university"
    },
    {
        "id": "110097",
        "name": "Biola University",
        "hyphenated": "biola-university"
    },
    {
        "id": "110194",
        "name": "Brownson Technical School",
        "hyphenated": "brownson-technical-school"
    },
    {
        "id": "110219",
        "name": "Bryan University",
        "hyphenated": "bryan-university"
    },
    {
        "id": "110246",
        "name": "Butte College",
        "hyphenated": "butte-college"
    },
    {
        "id": "110316",
        "name": "California Institute of Integral Studies",
        "hyphenated": "california-institute-of-integral-studies"
    },
    {
        "id": "110334",
        "name": "Cabrillo College",
        "hyphenated": "cabrillo-college"
    },
    {
        "id": "110361",
        "name": "California Baptist University",
        "hyphenated": "california-baptist-university"
    },
    {
        "id": "110370",
        "name": "California College of the Arts",
        "hyphenated": "california-college-of-the-arts"
    },
    {
        "id": "110398",
        "name": "University of California-Hastings College of Law",
        "hyphenated": "university-of-california-hastings-college-of-law"
    },
    {
        "id": "110413",
        "name": "California Lutheran University",
        "hyphenated": "california-lutheran-university"
    },
    {
        "id": "110422",
        "name": "California Polytechnic State University-San Luis Obispo",
        "hyphenated": "california-polytechnic-state-university-san-luis-obispo"
    },
    {
        "id": "110468",
        "name": "Alliant International University-San Diego",
        "hyphenated": "alliant-international-university-san-diego"
    },
    {
        "id": "110486",
        "name": "California State University-Bakersfield",
        "hyphenated": "california-state-university-bakersfield"
    },
    {
        "id": "110495",
        "name": "California State University-Stanislaus",
        "hyphenated": "california-state-university-stanislaus"
    },
    {
        "id": "110510",
        "name": "California State University-San Bernardino",
        "hyphenated": "california-state-university-san-bernardino"
    },
    {
        "id": "110529",
        "name": "California State Polytechnic University-Pomona",
        "hyphenated": "california-state-polytechnic-university-pomona"
    },
    {
        "id": "110538",
        "name": "California State University-Chico",
        "hyphenated": "california-state-university-chico"
    },
    {
        "id": "110547",
        "name": "California State University-Dominguez Hills",
        "hyphenated": "california-state-university-dominguez-hills"
    },
    {
        "id": "110556",
        "name": "California State University-Fresno",
        "hyphenated": "california-state-university-fresno"
    },
    {
        "id": "110565",
        "name": "California State University-Fullerton",
        "hyphenated": "california-state-university-fullerton"
    },
    {
        "id": "110574",
        "name": "California State University-East Bay",
        "hyphenated": "california-state-university-east-bay"
    },
    {
        "id": "110592",
        "name": "California State University-Los Angeles",
        "hyphenated": "california-state-university-los-angeles"
    },
    {
        "id": "110608",
        "name": "California State University-Northridge",
        "hyphenated": "california-state-university-northridge"
    },
    {
        "id": "110617",
        "name": "California State University-Sacramento",
        "hyphenated": "california-state-university-sacramento"
    },
    {
        "id": "110699",
        "name": "University of California-San Francisco",
        "hyphenated": "university-of-california-san-francisco"
    },
    {
        "id": "110778",
        "name": "Sofia University",
        "hyphenated": "sofia-university"
    },
    {
        "id": "110875",
        "name": "California Beauty School",
        "hyphenated": "california-beauty-school"
    },
    {
        "id": "110918",
        "name": "California Christian College",
        "hyphenated": "california-christian-college"
    },
    {
        "id": "111045",
        "name": "Haven University",
        "hyphenated": "haven-university"
    },
    {
        "id": "111054",
        "name": "California Hair Design Academy",
        "hyphenated": "california-hair-design-academy"
    },
    {
        "id": "111081",
        "name": "California Institute of the Arts",
        "hyphenated": "california-institute-of-the-arts"
    },
    {
        "id": "111188",
        "name": "California State University Maritime Academy",
        "hyphenated": "california-state-university-maritime-academy"
    },
    {
        "id": "111391",
        "name": "California Western School of Law",
        "hyphenated": "california-western-school-of-law"
    },
    {
        "id": "111434",
        "name": "Canada College",
        "hyphenated": "canada-college"
    },
    {
        "id": "111461",
        "name": "College of the Canyons",
        "hyphenated": "college-of-the-canyons"
    },
    {
        "id": "111513",
        "name": "Career Academy of Beauty",
        "hyphenated": "career-academy-of-beauty"
    },
    {
        "id": "111638",
        "name": "Casa Loma College-Los Angeles",
        "hyphenated": "casa-loma-college-los-angeles"
    },
    {
        "id": "111708",
        "name": "CET-San Diego",
        "hyphenated": "cet-san-diego"
    },
    {
        "id": "111717",
        "name": "CET-San Jose",
        "hyphenated": "cet-san-jose"
    },
    {
        "id": "111726",
        "name": "CET-El Centro",
        "hyphenated": "cet-el-centro"
    },
    {
        "id": "111744",
        "name": "CET-Colton",
        "hyphenated": "cet-colton"
    },
    {
        "id": "111762",
        "name": "CET-Watsonville",
        "hyphenated": "cet-watsonville"
    },
    {
        "id": "111799",
        "name": "CET-Salinas",
        "hyphenated": "cet-salinas"
    },
    {
        "id": "111887",
        "name": "Cerritos College",
        "hyphenated": "cerritos-college"
    },
    {
        "id": "111896",
        "name": "Cerro Coso Community College",
        "hyphenated": "cerro-coso-community-college"
    },
    {
        "id": "111920",
        "name": "Chabot College",
        "hyphenated": "chabot-college"
    },
    {
        "id": "111939",
        "name": "Chaffey College",
        "hyphenated": "chaffey-college"
    },
    {
        "id": "111966",
        "name": "Charles R Drew University of Medicine and Science",
        "hyphenated": "charles-r-drew-university-of-medicine-and-science"
    },
    {
        "id": "112075",
        "name": "Concordia University-Irvine",
        "hyphenated": "concordia-university-irvine"
    },
    {
        "id": "112084",
        "name": "San Diego Christian College",
        "hyphenated": "san-diego-christian-college"
    },
    {
        "id": "112127",
        "name": "Church Divinity School of the Pacific",
        "hyphenated": "church-divinity-school-of-the-pacific"
    },
    {
        "id": "112172",
        "name": "Citrus College",
        "hyphenated": "citrus-college"
    },
    {
        "id": "112181",
        "name": "Citrus Heights Beauty College",
        "hyphenated": "citrus-heights-beauty-college"
    },
    {
        "id": "112190",
        "name": "City College of San Francisco",
        "hyphenated": "city-college-of-san-francisco"
    },
    {
        "id": "112251",
        "name": "Claremont Graduate University",
        "hyphenated": "claremont-graduate-university"
    },
    {
        "id": "112288",
        "name": "Milan Institute-Visalia",
        "hyphenated": "milan-institute-visalia"
    },
    {
        "id": "112367",
        "name": "Clovis Adult Education",
        "hyphenated": "clovis-adult-education"
    },
    {
        "id": "112385",
        "name": "Coastline Community College",
        "hyphenated": "coastline-community-college"
    },
    {
        "id": "112394",
        "name": "University of Silicon Valley",
        "hyphenated": "university-of-silicon-valley"
    },
    {
        "id": "112455",
        "name": "Coba Academy",
        "hyphenated": "coba-academy"
    },
    {
        "id": "112525",
        "name": "Western University of Health Sciences",
        "hyphenated": "western-university-of-health-sciences"
    },
    {
        "id": "112561",
        "name": "Columbia College",
        "hyphenated": "columbia-college"
    },
    {
        "id": "112570",
        "name": "Columbia College Hollywood",
        "hyphenated": "columbia-college-hollywood"
    },
    {
        "id": "112686",
        "name": "Compton College",
        "hyphenated": "compton-college"
    },
    {
        "id": "112826",
        "name": "Contra Costa College",
        "hyphenated": "contra-costa-college"
    },
    {
        "id": "113096",
        "name": "Cosumnes River College",
        "hyphenated": "cosumnes-river-college"
    },
    {
        "id": "113111",
        "name": "Crafton Hills College",
        "hyphenated": "crafton-hills-college"
    },
    {
        "id": "113193",
        "name": "Cuesta College",
        "hyphenated": "cuesta-college"
    },
    {
        "id": "113218",
        "name": "Cuyamaca College",
        "hyphenated": "cuyamaca-college"
    },
    {
        "id": "113236",
        "name": "Cypress College",
        "hyphenated": "cypress-college"
    },
    {
        "id": "113333",
        "name": "De Anza College",
        "hyphenated": "de-anza-college"
    },
    {
        "id": "113449",
        "name": "International School of Beauty Inc",
        "hyphenated": "international-school-of-beauty-inc"
    },
    {
        "id": "113537",
        "name": "Dell'Arte International School of Physical Theatre",
        "hyphenated": "dell'arte-international-school-of-physical-theatre"
    },
    {
        "id": "113573",
        "name": "College of the Desert",
        "hyphenated": "college-of-the-desert"
    },
    {
        "id": "113582",
        "name": "Design Institute of San Diego",
        "hyphenated": "design-institute-of-san-diego"
    },
    {
        "id": "113634",
        "name": "Diablo Valley College",
        "hyphenated": "diablo-valley-college"
    },
    {
        "id": "113698",
        "name": "Dominican University of California",
        "hyphenated": "dominican-university-of-california"
    },
    {
        "id": "113704",
        "name": "Dominican School of Philosophy & Theology",
        "hyphenated": "dominican-school-of-philosophy-&-theology"
    },
    {
        "id": "113856",
        "name": "East Los Angeles College",
        "hyphenated": "east-los-angeles-college"
    },
    {
        "id": "113980",
        "name": "El Camino Community College District",
        "hyphenated": "el-camino-community-college-district"
    },
    {
        "id": "114114",
        "name": "Emperor's College of Traditional Oriental Medicine",
        "hyphenated": "emperor's-college-of-traditional-oriental-medicine"
    },
    {
        "id": "114123",
        "name": "Empire College",
        "hyphenated": "empire-college"
    },
    {
        "id": "114266",
        "name": "Evergreen Valley College",
        "hyphenated": "evergreen-valley-college"
    },
    {
        "id": "114354",
        "name": "FIDM-Fashion Institute of Design & Merchandising",
        "hyphenated": "fidm-fashion-institute-of-design-&-merchandising"
    },
    {
        "id": "114433",
        "name": "Feather River Community College District",
        "hyphenated": "feather-river-community-college-district"
    },
    {
        "id": "114460",
        "name": "Federico Beauty Institute",
        "hyphenated": "federico-beauty-institute"
    },
    {
        "id": "114549",
        "name": "Fielding Graduate University",
        "hyphenated": "fielding-graduate-university"
    },
    {
        "id": "114585",
        "name": "Five Branches University",
        "hyphenated": "five-branches-university"
    },
    {
        "id": "114637",
        "name": "Redondo Beach Beauty College",
        "hyphenated": "redondo-beach-beauty-college"
    },
    {
        "id": "114716",
        "name": "Foothill College",
        "hyphenated": "foothill-college"
    },
    {
        "id": "114734",
        "name": "Franciscan School of Theology",
        "hyphenated": "franciscan-school-of-theology"
    },
    {
        "id": "114761",
        "name": "Fredrick and Charles Beauty College",
        "hyphenated": "fredrick-and-charles-beauty-college"
    },
    {
        "id": "114789",
        "name": "Fresno City College",
        "hyphenated": "fresno-city-college"
    },
    {
        "id": "114813",
        "name": "Fresno Pacific University",
        "hyphenated": "fresno-pacific-university"
    },
    {
        "id": "114840",
        "name": "Fuller Theological Seminary",
        "hyphenated": "fuller-theological-seminary"
    },
    {
        "id": "114859",
        "name": "Fullerton College",
        "hyphenated": "fullerton-college"
    },
    {
        "id": "114938",
        "name": "Gavilan College",
        "hyphenated": "gavilan-college"
    },
    {
        "id": "114947",
        "name": "Gemological Institute of America-Carlsbad",
        "hyphenated": "gemological-institute-of-america-carlsbad"
    },
    {
        "id": "115001",
        "name": "Glendale Community College",
        "hyphenated": "glendale-community-college"
    },
    {
        "id": "115010",
        "name": "Glendale Career College",
        "hyphenated": "glendale-career-college"
    },
    {
        "id": "115083",
        "name": "Golden Gate University",
        "hyphenated": "golden-gate-university"
    },
    {
        "id": "115126",
        "name": "Golden West College",
        "hyphenated": "golden-west-college"
    },
    {
        "id": "115214",
        "name": "Graduate Theological Union",
        "hyphenated": "graduate-theological-union"
    },
    {
        "id": "115296",
        "name": "Grossmont College",
        "hyphenated": "grossmont-college"
    },
    {
        "id": "115357",
        "name": "Salon Success Academy-Riverside",
        "hyphenated": "salon-success-academy-riverside"
    },
    {
        "id": "115393",
        "name": "Hartnell College",
        "hyphenated": "hartnell-college"
    },
    {
        "id": "115658",
        "name": "Hilltop Beauty School",
        "hyphenated": "hilltop-beauty-school"
    },
    {
        "id": "115728",
        "name": "Holy Names University",
        "hyphenated": "holy-names-university"
    },
    {
        "id": "115746",
        "name": "Pacifica Graduate Institute",
        "hyphenated": "pacifica-graduate-institute"
    },
    {
        "id": "115755",
        "name": "California State Polytechnic University-Humboldt",
        "hyphenated": "california-state-polytechnic-university-humboldt"
    },
    {
        "id": "115773",
        "name": "Humphreys University-Stockton and Modesto Campuses",
        "hyphenated": "humphreys-university-stockton-and-modesto-campuses"
    },
    {
        "id": "115861",
        "name": "Imperial Valley College",
        "hyphenated": "imperial-valley-college"
    },
    {
        "id": "115931",
        "name": "Institute for Business and Technology",
        "hyphenated": "institute-for-business-and-technology"
    },
    {
        "id": "116226",
        "name": "Interior Designers Institute",
        "hyphenated": "interior-designers-institute"
    },
    {
        "id": "116350",
        "name": "International School of Cosmetology",
        "hyphenated": "international-school-of-cosmetology"
    },
    {
        "id": "116439",
        "name": "Irvine Valley College",
        "hyphenated": "irvine-valley-college"
    },
    {
        "id": "116563",
        "name": "Bellus Academy-El Cajon",
        "hyphenated": "bellus-academy-el-cajon"
    },
    {
        "id": "116846",
        "name": "American Jewish University",
        "hyphenated": "american-jewish-university"
    },
    {
        "id": "117052",
        "name": "Reedley College",
        "hyphenated": "reedley-college"
    },
    {
        "id": "117104",
        "name": "Life Pacific University",
        "hyphenated": "life-pacific-university"
    },
    {
        "id": "117140",
        "name": "University of La Verne",
        "hyphenated": "university-of-la-verne"
    },
    {
        "id": "117168",
        "name": "Laguna College of Art and Design",
        "hyphenated": "laguna-college-of-art-and-design"
    },
    {
        "id": "117195",
        "name": "Lake Tahoe Community College",
        "hyphenated": "lake-tahoe-community-college"
    },
    {
        "id": "117238",
        "name": "Lancaster Beauty School",
        "hyphenated": "lancaster-beauty-school"
    },
    {
        "id": "117247",
        "name": "Laney College",
        "hyphenated": "laney-college"
    },
    {
        "id": "117274",
        "name": "Lassen Community College",
        "hyphenated": "lassen-community-college"
    },
    {
        "id": "117283",
        "name": "Latin American Bible Institute",
        "hyphenated": "latin-american-bible-institute"
    },
    {
        "id": "117520",
        "name": "Life Chiropractic College West",
        "hyphenated": "life-chiropractic-college-west"
    },
    {
        "id": "117557",
        "name": "Lincoln University",
        "hyphenated": "lincoln-university"
    },
    {
        "id": "117575",
        "name": "Southern California Seminary",
        "hyphenated": "southern-california-seminary"
    },
    {
        "id": "117627",
        "name": "La Sierra University",
        "hyphenated": "la-sierra-university"
    },
    {
        "id": "117636",
        "name": "Loma Linda University",
        "hyphenated": "loma-linda-university"
    },
    {
        "id": "117645",
        "name": "Long Beach City College",
        "hyphenated": "long-beach-city-college"
    },
    {
        "id": "117672",
        "name": "Southern California University of Health Sciences",
        "hyphenated": "southern-california-university-of-health-sciences"
    },
    {
        "id": "117690",
        "name": "Los Angeles Harbor College",
        "hyphenated": "los-angeles-harbor-college"
    },
    {
        "id": "117706",
        "name": "Los Angeles Pierce College",
        "hyphenated": "los-angeles-pierce-college"
    },
    {
        "id": "117715",
        "name": "Los Angeles Southwest College",
        "hyphenated": "los-angeles-southwest-college"
    },
    {
        "id": "117724",
        "name": "Los Angeles Trade Technical College",
        "hyphenated": "los-angeles-trade-technical-college"
    },
    {
        "id": "117733",
        "name": "Los Angeles Valley College",
        "hyphenated": "los-angeles-valley-college"
    },
    {
        "id": "117751",
        "name": "The Master's University and Seminary",
        "hyphenated": "the-master's-university-and-seminary"
    },
    {
        "id": "117788",
        "name": "Los Angeles City College",
        "hyphenated": "los-angeles-city-college"
    },
    {
        "id": "117803",
        "name": "Los Angeles County College of Nursing and Allied Health",
        "hyphenated": "los-angeles-county-college-of-nursing-and-allied-health"
    },
    {
        "id": "117867",
        "name": "Los Angeles Mission College",
        "hyphenated": "los-angeles-mission-college"
    },
    {
        "id": "117894",
        "name": "Los Medanos College",
        "hyphenated": "los-medanos-college"
    },
    {
        "id": "117955",
        "name": "Lu Ross Academy",
        "hyphenated": "lu-ross-academy"
    },
    {
        "id": "118134",
        "name": "Lyle's College of Beauty",
        "hyphenated": "lyle's-college-of-beauty"
    },
    {
        "id": "118143",
        "name": "Lyle's College of Beauty",
        "hyphenated": "lyle's-college-of-beauty"
    },
    {
        "id": "118161",
        "name": "Lytles Redwood Empire Beauty College",
        "hyphenated": "lytles-redwood-empire-beauty-college"
    },
    {
        "id": "118198",
        "name": "MTI College",
        "hyphenated": "mti-college"
    },
    {
        "id": "118347",
        "name": "College of Marin",
        "hyphenated": "college-of-marin"
    },
    {
        "id": "118541",
        "name": "Marymount California University",
        "hyphenated": "marymount-california-university"
    },
    {
        "id": "118684",
        "name": "Mendocino College",
        "hyphenated": "mendocino-college"
    },
    {
        "id": "118693",
        "name": "Menlo College",
        "hyphenated": "menlo-college"
    },
    {
        "id": "118718",
        "name": "Merced College",
        "hyphenated": "merced-college"
    },
    {
        "id": "118772",
        "name": "Merritt College",
        "hyphenated": "merritt-college"
    },
    {
        "id": "118888",
        "name": "Mills College",
        "hyphenated": "mills-college"
    },
    {
        "id": "118912",
        "name": "MiraCosta College",
        "hyphenated": "miracosta-college"
    },
    {
        "id": "118930",
        "name": "Mission College",
        "hyphenated": "mission-college"
    },
    {
        "id": "118958",
        "name": "Modern Beauty Academy",
        "hyphenated": "modern-beauty-academy"
    },
    {
        "id": "118976",
        "name": "Modesto Junior College",
        "hyphenated": "modesto-junior-college"
    },
    {
        "id": "118994",
        "name": "Moler Barber College",
        "hyphenated": "moler-barber-college"
    },
    {
        "id": "119058",
        "name": "Middlebury Institute of International Studies at Monterey",
        "hyphenated": "middlebury-institute-of-international-studies-at-monterey"
    },
    {
        "id": "119067",
        "name": "Monterey Peninsula College",
        "hyphenated": "monterey-peninsula-college"
    },
    {
        "id": "119137",
        "name": "Moorpark College",
        "hyphenated": "moorpark-college"
    },
    {
        "id": "119164",
        "name": "Mt San Antonio College",
        "hyphenated": "mt-san-antonio-college"
    },
    {
        "id": "119173",
        "name": "Mount Saint Mary's University",
        "hyphenated": "mount-saint-mary's-university"
    },
    {
        "id": "119216",
        "name": "Mt San Jacinto Community College District",
        "hyphenated": "mt-san-jacinto-community-college-district"
    },
    {
        "id": "119270",
        "name": "Musicians Institute",
        "hyphenated": "musicians-institute"
    },
    {
        "id": "119331",
        "name": "Napa Valley College",
        "hyphenated": "napa-valley-college"
    },
    {
        "id": "119553",
        "name": "National Holistic Institute",
        "hyphenated": "national-holistic-institute"
    },
    {
        "id": "119605",
        "name": "National University",
        "hyphenated": "national-university"
    },
    {
        "id": "119678",
        "name": "Naval Postgraduate School",
        "hyphenated": "naval-postgraduate-school"
    },
    {
        "id": "119775",
        "name": "Newschool of Architecture and Design",
        "hyphenated": "newschool-of-architecture-and-design"
    },
    {
        "id": "119951",
        "name": "North Adrian's College of Beauty Inc",
        "hyphenated": "north-adrian's-college-of-beauty-inc"
    },
    {
        "id": "120069",
        "name": "North-West College-Pomona",
        "hyphenated": "north-west-college-pomona"
    },
    {
        "id": "120078",
        "name": "North-West College-West Covina",
        "hyphenated": "north-west-college-west-covina"
    },
    {
        "id": "120087",
        "name": "North-West College-Van Nuys",
        "hyphenated": "north-west-college-van-nuys"
    },
    {
        "id": "120166",
        "name": "San Francisco Bay University",
        "hyphenated": "san-francisco-bay-university"
    },
    {
        "id": "120184",
        "name": "Notre Dame de Namur University",
        "hyphenated": "notre-dame-de-namur-university"
    },
    {
        "id": "120254",
        "name": "Occidental College",
        "hyphenated": "occidental-college"
    },
    {
        "id": "120290",
        "name": "Ohlone College",
        "hyphenated": "ohlone-college"
    },
    {
        "id": "120342",
        "name": "Orange Coast College",
        "hyphenated": "orange-coast-college"
    },
    {
        "id": "120403",
        "name": "Otis College of Art and Design",
        "hyphenated": "otis-college-of-art-and-design"
    },
    {
        "id": "120421",
        "name": "Oxnard College",
        "hyphenated": "oxnard-college"
    },
    {
        "id": "120537",
        "name": "Hope International University",
        "hyphenated": "hope-international-university"
    },
    {
        "id": "120661",
        "name": "Concorde Career College-San Diego",
        "hyphenated": "concorde-career-college-san-diego"
    },
    {
        "id": "120698",
        "name": "Palo Alto University",
        "hyphenated": "palo-alto-university"
    },
    {
        "id": "120768",
        "name": "Pacific Oaks College",
        "hyphenated": "pacific-oaks-college"
    },
    {
        "id": "120795",
        "name": "Pacific School of Religion",
        "hyphenated": "pacific-school-of-religion"
    },
    {
        "id": "120838",
        "name": "Pacific States University",
        "hyphenated": "pacific-states-university"
    },
    {
        "id": "120865",
        "name": "Pacific Union College",
        "hyphenated": "pacific-union-college"
    },
    {
        "id": "120883",
        "name": "University of the Pacific",
        "hyphenated": "university-of-the-pacific"
    },
    {
        "id": "120953",
        "name": "Palo Verde College",
        "hyphenated": "palo-verde-college"
    },
    {
        "id": "120971",
        "name": "Palomar College",
        "hyphenated": "palomar-college"
    },
    {
        "id": "121044",
        "name": "Pasadena City College",
        "hyphenated": "pasadena-city-college"
    },
    {
        "id": "121257",
        "name": "Pitzer College",
        "hyphenated": "pitzer-college"
    },
    {
        "id": "121275",
        "name": "Platt College-San Diego",
        "hyphenated": "platt-college-san-diego"
    },
    {
        "id": "121309",
        "name": "Point Loma Nazarene University",
        "hyphenated": "point-loma-nazarene-university"
    },
    {
        "id": "121363",
        "name": "Porterville College",
        "hyphenated": "porterville-college"
    },
    {
        "id": "121433",
        "name": "Professional Institute of Beauty",
        "hyphenated": "professional-institute-of-beauty"
    },
    {
        "id": "121619",
        "name": "Santa Ana College",
        "hyphenated": "santa-ana-college"
    },
    {
        "id": "121628",
        "name": "Pardee RAND Graduate School",
        "hyphenated": "pardee-rand-graduate-school"
    },
    {
        "id": "121637",
        "name": "Shasta School of Cosmetology",
        "hyphenated": "shasta-school-of-cosmetology"
    },
    {
        "id": "121691",
        "name": "University of Redlands",
        "hyphenated": "university-of-redlands"
    },
    {
        "id": "121707",
        "name": "College of the Redwoods",
        "hyphenated": "college-of-the-redwoods"
    },
    {
        "id": "121859",
        "name": "Salon Success Academy-Upland",
        "hyphenated": "salon-success-academy-upland"
    },
    {
        "id": "121868",
        "name": "Salon Success Academy-Corona",
        "hyphenated": "salon-success-academy-corona"
    },
    {
        "id": "121886",
        "name": "Rio Hondo College",
        "hyphenated": "rio-hondo-college"
    },
    {
        "id": "121901",
        "name": "Riverside City College",
        "hyphenated": "riverside-city-college"
    },
    {
        "id": "122117",
        "name": "Dongguk University Los Angeles",
        "hyphenated": "dongguk-university-los-angeles"
    },
    {
        "id": "122180",
        "name": "Sacramento City College",
        "hyphenated": "sacramento-city-college"
    },
    {
        "id": "122205",
        "name": "Saddleback College",
        "hyphenated": "saddleback-college"
    },
    {
        "id": "122296",
        "name": "Samuel Merritt University",
        "hyphenated": "samuel-merritt-university"
    },
    {
        "id": "122339",
        "name": "San Diego City College",
        "hyphenated": "san-diego-city-college"
    },
    {
        "id": "122375",
        "name": "San Diego Mesa College",
        "hyphenated": "san-diego-mesa-college"
    },
    {
        "id": "122384",
        "name": "San Diego Miramar College",
        "hyphenated": "san-diego-miramar-college"
    },
    {
        "id": "122409",
        "name": "San Diego State University",
        "hyphenated": "san-diego-state-university"
    },
    {
        "id": "122454",
        "name": "San Francisco Art Institute",
        "hyphenated": "san-francisco-art-institute"
    },
    {
        "id": "122506",
        "name": "San Francisco Conservatory of Music",
        "hyphenated": "san-francisco-conservatory-of-music"
    },
    {
        "id": "122597",
        "name": "San Francisco State University",
        "hyphenated": "san-francisco-state-university"
    },
    {
        "id": "122649",
        "name": "San Joaquin College of Law",
        "hyphenated": "san-joaquin-college-of-law"
    },
    {
        "id": "122658",
        "name": "San Joaquin Delta College",
        "hyphenated": "san-joaquin-delta-college"
    },
    {
        "id": "122685",
        "name": "San Joaquin Valley College-Visalia",
        "hyphenated": "san-joaquin-valley-college-visalia"
    },
    {
        "id": "122694",
        "name": "San Joaquin Valley College-Bakersfield",
        "hyphenated": "san-joaquin-valley-college-bakersfield"
    },
    {
        "id": "122728",
        "name": "William Jessup University",
        "hyphenated": "william-jessup-university"
    },
    {
        "id": "122746",
        "name": "San Jose City College",
        "hyphenated": "san-jose-city-college"
    },
    {
        "id": "122755",
        "name": "San Jose State University",
        "hyphenated": "san-jose-state-university"
    },
    {
        "id": "122791",
        "name": "College of San Mateo",
        "hyphenated": "college-of-san-mateo"
    },
    {
        "id": "122834",
        "name": "Santa Barbara Business College-Bakersfield",
        "hyphenated": "santa-barbara-business-college-bakersfield"
    },
    {
        "id": "122889",
        "name": "Santa Barbara City College",
        "hyphenated": "santa-barbara-city-college"
    },
    {
        "id": "122977",
        "name": "Santa Monica College",
        "hyphenated": "santa-monica-college"
    },
    {
        "id": "123013",
        "name": "Santa Rosa Junior College",
        "hyphenated": "santa-rosa-junior-college"
    },
    {
        "id": "123095",
        "name": "Saybrook University",
        "hyphenated": "saybrook-university"
    },
    {
        "id": "123165",
        "name": "Scripps College",
        "hyphenated": "scripps-college"
    },
    {
        "id": "123217",
        "name": "College of the Sequoias",
        "hyphenated": "college-of-the-sequoias"
    },
    {
        "id": "123280",
        "name": "Shasta Bible College and Graduate School",
        "hyphenated": "shasta-bible-college-and-graduate-school"
    },
    {
        "id": "123299",
        "name": "Shasta College",
        "hyphenated": "shasta-college"
    },
    {
        "id": "123341",
        "name": "Sierra College",
        "hyphenated": "sierra-college"
    },
    {
        "id": "123350",
        "name": "Sierra College of Beauty",
        "hyphenated": "sierra-college-of-beauty"
    },
    {
        "id": "123448",
        "name": "Trinity Law School",
        "hyphenated": "trinity-law-school"
    },
    {
        "id": "123457",
        "name": "Simpson University",
        "hyphenated": "simpson-university"
    },
    {
        "id": "123484",
        "name": "College of the Siskiyous",
        "hyphenated": "college-of-the-siskiyous"
    },
    {
        "id": "123493",
        "name": "Charles A Jones Career and Education Center",
        "hyphenated": "charles-a-jones-career-and-education-center"
    },
    {
        "id": "123509",
        "name": "Skyline College",
        "hyphenated": "skyline-college"
    },
    {
        "id": "123527",
        "name": "San Bernardino Valley College",
        "hyphenated": "san-bernardino-valley-college"
    },
    {
        "id": "123554",
        "name": "Saint Mary's College of California",
        "hyphenated": "saint-mary's-college-of-california"
    },
    {
        "id": "123563",
        "name": "Solano Community College",
        "hyphenated": "solano-community-college"
    },
    {
        "id": "123572",
        "name": "Sonoma State University",
        "hyphenated": "sonoma-state-university"
    },
    {
        "id": "123633",
        "name": "South Baylo University",
        "hyphenated": "south-baylo-university"
    },
    {
        "id": "123642",
        "name": "South Coast College",
        "hyphenated": "south-coast-college"
    },
    {
        "id": "123651",
        "name": "Vanguard University of Southern California",
        "hyphenated": "vanguard-university-of-southern-california"
    },
    {
        "id": "123679",
        "name": "Concorde Career College-Garden Grove",
        "hyphenated": "concorde-career-college-garden-grove"
    },
    {
        "id": "123800",
        "name": "Southwestern College",
        "hyphenated": "southwestern-college"
    },
    {
        "id": "123916",
        "name": "Starr King School for the Ministry",
        "hyphenated": "starr-king-school-for-the-ministry"
    },
    {
        "id": "123943",
        "name": "Marshall B Ketchum University",
        "hyphenated": "marshall-b-ketchum-university"
    },
    {
        "id": "123952",
        "name": "Southern California Institute of Architecture",
        "hyphenated": "southern-california-institute-of-architecture"
    },
    {
        "id": "123970",
        "name": "Southwestern Law School",
        "hyphenated": "southwestern-law-school"
    },
    {
        "id": "124113",
        "name": "Taft College",
        "hyphenated": "taft-college"
    },
    {
        "id": "124283",
        "name": "Claremont School of Theology",
        "hyphenated": "claremont-school-of-theology"
    },
    {
        "id": "124292",
        "name": "Thomas Aquinas College",
        "hyphenated": "thomas-aquinas-college"
    },
    {
        "id": "124487",
        "name": "Epic Bible College & Graduate School",
        "hyphenated": "epic-bible-college-&-graduate-school"
    },
    {
        "id": "124681",
        "name": "UEI College-Huntington Park",
        "hyphenated": "uei-college-huntington-park"
    },
    {
        "id": "124706",
        "name": "Concorde Career College-San Bernardino",
        "hyphenated": "concorde-career-college-san-bernardino"
    },
    {
        "id": "124937",
        "name": "Concorde Career College-North Hollywood",
        "hyphenated": "concorde-career-college-north-hollywood"
    },
    {
        "id": "125028",
        "name": "Ventura College",
        "hyphenated": "ventura-college"
    },
    {
        "id": "125037",
        "name": "The Santa Barbara and Ventura Colleges of Law at Ventura",
        "hyphenated": "the-santa-barbara-and-ventura-colleges-of-law-at-ventura"
    },
    {
        "id": "125082",
        "name": "Bridges Beauty College",
        "hyphenated": "bridges-beauty-college"
    },
    {
        "id": "125091",
        "name": "Victor Valley College",
        "hyphenated": "victor-valley-college"
    },
    {
        "id": "125170",
        "name": "Berkeley City College",
        "hyphenated": "berkeley-city-college"
    },
    {
        "id": "125231",
        "name": "Walden University",
        "hyphenated": "walden-university"
    },
    {
        "id": "125310",
        "name": "Waynes College of Beauty",
        "hyphenated": "waynes-college-of-beauty"
    },
    {
        "id": "125462",
        "name": "West Hills College-Coalinga",
        "hyphenated": "west-hills-college-coalinga"
    },
    {
        "id": "125471",
        "name": "West Los Angeles College",
        "hyphenated": "west-los-angeles-college"
    },
    {
        "id": "125499",
        "name": "West Valley College",
        "hyphenated": "west-valley-college"
    },
    {
        "id": "125532",
        "name": "Carrington College-Sacramento",
        "hyphenated": "carrington-college-sacramento"
    },
    {
        "id": "125718",
        "name": "Westminster Theological Seminary in California",
        "hyphenated": "westminster-theological-seminary-in-california"
    },
    {
        "id": "125727",
        "name": "Westmont College",
        "hyphenated": "westmont-college"
    },
    {
        "id": "125763",
        "name": "Whittier College",
        "hyphenated": "whittier-college"
    },
    {
        "id": "125897",
        "name": "Woodbury University",
        "hyphenated": "woodbury-university"
    },
    {
        "id": "126012",
        "name": "The Wright Institute",
        "hyphenated": "the-wright-institute"
    },
    {
        "id": "126049",
        "name": "Thomas Jefferson School of Law",
        "hyphenated": "thomas-jefferson-school-of-law"
    },
    {
        "id": "126076",
        "name": "Yeshiva Ohr Elchonon Chabad West Coast Talmudical Seminary",
        "hyphenated": "yeshiva-ohr-elchonon-chabad-west-coast-talmudical-seminary"
    },
    {
        "id": "126119",
        "name": "Yuba College",
        "hyphenated": "yuba-college"
    },
    {
        "id": "126164",
        "name": "The Salon Professional Academy-Grand Junction",
        "hyphenated": "the-salon-professional-academy-grand-junction"
    },
    {
        "id": "126182",
        "name": "Adams State University",
        "hyphenated": "adams-state-university"
    },
    {
        "id": "126207",
        "name": "Aims Community College",
        "hyphenated": "aims-community-college"
    },
    {
        "id": "126289",
        "name": "Arapahoe Community College",
        "hyphenated": "arapahoe-community-college"
    },
    {
        "id": "126359",
        "name": "Bel-Rea Institute of Animal Technology",
        "hyphenated": "bel-rea-institute-of-animal-technology"
    },
    {
        "id": "126562",
        "name": "University of Colorado Denver/Anschutz Medical Campus",
        "hyphenated": "university-of-colorado-denver/anschutz-medical-campus"
    },
    {
        "id": "126580",
        "name": "University of Colorado Colorado Springs",
        "hyphenated": "university-of-colorado-colorado-springs"
    },
    {
        "id": "126605",
        "name": "Spartan College of Aeronautics and Technology",
        "hyphenated": "spartan-college-of-aeronautics-and-technology"
    },
    {
        "id": "126669",
        "name": "Colorado Christian University",
        "hyphenated": "colorado-christian-university"
    },
    {
        "id": "126687",
        "name": "Concorde Career College-Aurora",
        "hyphenated": "concorde-career-college-aurora"
    },
    {
        "id": "126711",
        "name": "Colorado Mountain College",
        "hyphenated": "colorado-mountain-college"
    },
    {
        "id": "126748",
        "name": "Colorado Northwestern Community College",
        "hyphenated": "colorado-northwestern-community-college"
    },
    {
        "id": "126784",
        "name": "Colorado School of Trades",
        "hyphenated": "colorado-school-of-trades"
    },
    {
        "id": "126818",
        "name": "Colorado State University-Fort Collins",
        "hyphenated": "colorado-state-university-fort-collins"
    },
    {
        "id": "126827",
        "name": "Colorado Technical University-Colorado Springs",
        "hyphenated": "colorado-technical-university-colorado-springs"
    },
    {
        "id": "126863",
        "name": "Community College of Aurora",
        "hyphenated": "community-college-of-aurora"
    },
    {
        "id": "126915",
        "name": "Technical College of the Rockies",
        "hyphenated": "technical-college-of-the-rockies"
    },
    {
        "id": "126942",
        "name": "Community College of Denver",
        "hyphenated": "community-college-of-denver"
    },
    {
        "id": "126951",
        "name": "Lincoln College of Technology-Denver",
        "hyphenated": "lincoln-college-of-technology-denver"
    },
    {
        "id": "126979",
        "name": "Denver Seminary",
        "hyphenated": "denver-seminary"
    },
    {
        "id": "127158",
        "name": "Emily Griffith Technical College",
        "hyphenated": "emily-griffith-technical-college"
    },
    {
        "id": "127185",
        "name": "Fort Lewis College",
        "hyphenated": "fort-lewis-college"
    },
    {
        "id": "127200",
        "name": "Front Range Community College",
        "hyphenated": "front-range-community-college"
    },
    {
        "id": "127273",
        "name": "Iliff School of Theology",
        "hyphenated": "iliff-school-of-theology"
    },
    {
        "id": "127316",
        "name": "International Salon and Spa Academy",
        "hyphenated": "international-salon-and-spa-academy"
    },
    {
        "id": "127389",
        "name": "Lamar Community College",
        "hyphenated": "lamar-community-college"
    },
    {
        "id": "127556",
        "name": "Colorado Mesa University",
        "hyphenated": "colorado-mesa-university"
    },
    {
        "id": "127565",
        "name": "Metropolitan State University of Denver",
        "hyphenated": "metropolitan-state-university-of-denver"
    },
    {
        "id": "127617",
        "name": "Morgan Community College",
        "hyphenated": "morgan-community-college"
    },
    {
        "id": "127653",
        "name": "Naropa University",
        "hyphenated": "naropa-university"
    },
    {
        "id": "127714",
        "name": "Nazarene Bible College",
        "hyphenated": "nazarene-bible-college"
    },
    {
        "id": "127732",
        "name": "Northeastern Junior College",
        "hyphenated": "northeastern-junior-college"
    },
    {
        "id": "127741",
        "name": "University of Northern Colorado",
        "hyphenated": "university-of-northern-colorado"
    },
    {
        "id": "127778",
        "name": "Otero College",
        "hyphenated": "otero-college"
    },
    {
        "id": "127820",
        "name": "Pikes Peak State College",
        "hyphenated": "pikes-peak-state-college"
    },
    {
        "id": "127884",
        "name": "Pueblo Community College",
        "hyphenated": "pueblo-community-college"
    },
    {
        "id": "127909",
        "name": "Red Rocks Community College",
        "hyphenated": "red-rocks-community-college"
    },
    {
        "id": "127918",
        "name": "Regis University",
        "hyphenated": "regis-university"
    },
    {
        "id": "127945",
        "name": "Rocky Mountain College of Art and Design",
        "hyphenated": "rocky-mountain-college-of-art-and-design"
    },
    {
        "id": "127954",
        "name": "Montessori Education Center of the Rockies",
        "hyphenated": "montessori-education-center-of-the-rockies"
    },
    {
        "id": "128106",
        "name": "Colorado State University Pueblo",
        "hyphenated": "colorado-state-university-pueblo"
    },
    {
        "id": "128151",
        "name": "Pickens Technical College",
        "hyphenated": "pickens-technical-college"
    },
    {
        "id": "128179",
        "name": "Intellitec College-Colorado Springs",
        "hyphenated": "intellitec-college-colorado-springs"
    },
    {
        "id": "128188",
        "name": "Intellitec College-Grand Junction",
        "hyphenated": "intellitec-college-grand-junction"
    },
    {
        "id": "128258",
        "name": "Trinidad State College",
        "hyphenated": "trinidad-state-college"
    },
    {
        "id": "128337",
        "name": "Empire Beauty School-Thornton",
        "hyphenated": "empire-beauty-school-thornton"
    },
    {
        "id": "128391",
        "name": "Western Colorado University",
        "hyphenated": "western-colorado-university"
    },
    {
        "id": "128498",
        "name": "Albertus Magnus College",
        "hyphenated": "albertus-magnus-college"
    },
    {
        "id": "128577",
        "name": "Asnuntuck Community College",
        "hyphenated": "asnuntuck-community-college"
    },
    {
        "id": "128586",
        "name": "Bais Binyomin Academy",
        "hyphenated": "bais-binyomin-academy"
    },
    {
        "id": "128744",
        "name": "University of Bridgeport",
        "hyphenated": "university-of-bridgeport"
    },
    {
        "id": "128771",
        "name": "Central Connecticut State University",
        "hyphenated": "central-connecticut-state-university"
    },
    {
        "id": "128780",
        "name": "Charter Oak State College",
        "hyphenated": "charter-oak-state-college"
    },
    {
        "id": "128896",
        "name": "Cortiva Institute",
        "hyphenated": "cortiva-institute"
    },
    {
        "id": "128902",
        "name": "Connecticut College",
        "hyphenated": "connecticut-college"
    },
    {
        "id": "129002",
        "name": "Porter & Chester Institute of Hamden",
        "hyphenated": "porter-&-chester-institute-of-hamden"
    },
    {
        "id": "129154",
        "name": "Goodwin University",
        "hyphenated": "goodwin-university"
    },
    {
        "id": "129181",
        "name": "Lincoln Technical Institute-East Windsor",
        "hyphenated": "lincoln-technical-institute-east-windsor"
    },
    {
        "id": "129215",
        "name": "Eastern Connecticut State University",
        "hyphenated": "eastern-connecticut-state-university"
    },
    {
        "id": "129349",
        "name": "Paul Mitchell the School-North Haven",
        "hyphenated": "paul-mitchell-the-school-north-haven"
    },
    {
        "id": "129367",
        "name": "Capital Community College",
        "hyphenated": "capital-community-college"
    },
    {
        "id": "129428",
        "name": "Rensselaer at Hartford",
        "hyphenated": "rensselaer-at-hartford"
    },
    {
        "id": "129482",
        "name": "American Institute-West Hartford",
        "hyphenated": "american-institute-west-hartford"
    },
    {
        "id": "129491",
        "name": "Hartford International University for Religion and Peace",
        "hyphenated": "hartford-international-university-for-religion-and-peace"
    },
    {
        "id": "129525",
        "name": "University of Hartford",
        "hyphenated": "university-of-hartford"
    },
    {
        "id": "129534",
        "name": "Holy Apostles College and Seminary",
        "hyphenated": "holy-apostles-college-and-seminary"
    },
    {
        "id": "129543",
        "name": "Housatonic Community College",
        "hyphenated": "housatonic-community-college"
    },
    {
        "id": "129695",
        "name": "Manchester Community College",
        "hyphenated": "manchester-community-college"
    },
    {
        "id": "129729",
        "name": "Naugatuck Valley Community College",
        "hyphenated": "naugatuck-valley-community-college"
    },
    {
        "id": "129756",
        "name": "Middlesex Community College",
        "hyphenated": "middlesex-community-college"
    },
    {
        "id": "129774",
        "name": "Mitchell College",
        "hyphenated": "mitchell-college"
    },
    {
        "id": "129808",
        "name": "Three Rivers Community College",
        "hyphenated": "three-rivers-community-college"
    },
    {
        "id": "129923",
        "name": "Lincoln Technical Institute-New Britain",
        "hyphenated": "lincoln-technical-institute-new-britain"
    },
    {
        "id": "129932",
        "name": "New England Tractor Trailer Training School of Connecticut",
        "hyphenated": "new-england-tractor-trailer-training-school-of-connecticut"
    },
    {
        "id": "129941",
        "name": "University of New Haven",
        "hyphenated": "university-of-new-haven"
    },
    {
        "id": "130004",
        "name": "Norwalk Community College",
        "hyphenated": "norwalk-community-college"
    },
    {
        "id": "130040",
        "name": "Northwestern Connecticut Community College",
        "hyphenated": "northwestern-connecticut-community-college"
    },
    {
        "id": "130110",
        "name": "Paier College",
        "hyphenated": "paier-college"
    },
    {
        "id": "130174",
        "name": "Porter & Chester Institute",
        "hyphenated": "porter-&-chester-institute"
    },
    {
        "id": "130183",
        "name": "Post University",
        "hyphenated": "post-university"
    },
    {
        "id": "130217",
        "name": "Quinebaug Valley Community College",
        "hyphenated": "quinebaug-valley-community-college"
    },
    {
        "id": "130226",
        "name": "Quinnipiac University",
        "hyphenated": "quinnipiac-university"
    },
    {
        "id": "130253",
        "name": "Sacred Heart University",
        "hyphenated": "sacred-heart-university"
    },
    {
        "id": "130314",
        "name": "University of Saint Joseph",
        "hyphenated": "university-of-saint-joseph"
    },
    {
        "id": "130396",
        "name": "Gateway Community College",
        "hyphenated": "gateway-community-college"
    },
    {
        "id": "130493",
        "name": "Southern Connecticut State University",
        "hyphenated": "southern-connecticut-state-university"
    },
    {
        "id": "130509",
        "name": "Stone Academy-West Haven",
        "hyphenated": "stone-academy-west-haven"
    },
    {
        "id": "130590",
        "name": "Trinity College",
        "hyphenated": "trinity-college"
    },
    {
        "id": "130606",
        "name": "Tunxis Community College",
        "hyphenated": "tunxis-community-college"
    },
    {
        "id": "130624",
        "name": "United States Coast Guard Academy",
        "hyphenated": "united-states-coast-guard-academy"
    },
    {
        "id": "130776",
        "name": "Western Connecticut State University",
        "hyphenated": "western-connecticut-state-university"
    },
    {
        "id": "130837",
        "name": "Margaret H Rollins School of Nursing at Beebe Medical Center",
        "hyphenated": "margaret-h-rollins-school-of-nursing-at-beebe-medical-center"
    },
    {
        "id": "130873",
        "name": "Dawn Career Institute LLC",
        "hyphenated": "dawn-career-institute-llc"
    },
    {
        "id": "130907",
        "name": "Delaware Technical Community College-Terry",
        "hyphenated": "delaware-technical-community-college-terry"
    },
    {
        "id": "130934",
        "name": "Delaware State University",
        "hyphenated": "delaware-state-university"
    },
    {
        "id": "130989",
        "name": "Goldey-Beacom College",
        "hyphenated": "goldey-beacom-college"
    },
    {
        "id": "131061",
        "name": "Schilling-Douglas School of Hair Design LLC",
        "hyphenated": "schilling-douglas-school-of-hair-design-llc"
    },
    {
        "id": "131113",
        "name": "Wilmington University",
        "hyphenated": "wilmington-university"
    },
    {
        "id": "131283",
        "name": "The Catholic University of America",
        "hyphenated": "the-catholic-university-of-america"
    },
    {
        "id": "131399",
        "name": "University of the District of Columbia",
        "hyphenated": "university-of-the-district-of-columbia"
    },
    {
        "id": "131405",
        "name": "Pontifical Faculty of the Immaculate Conception at the Dominican House of Studies",
        "hyphenated": "pontifical-faculty-of-the-immaculate-conception-at-the-dominican-house-of-studies"
    },
    {
        "id": "131803",
        "name": "Strayer University-District of Columbia",
        "hyphenated": "strayer-university-district-of-columbia"
    },
    {
        "id": "131830",
        "name": "National Conservatory of Dramatic Arts",
        "hyphenated": "national-conservatory-of-dramatic-arts"
    },
    {
        "id": "131876",
        "name": "Trinity Washington University",
        "hyphenated": "trinity-washington-university"
    },
    {
        "id": "131973",
        "name": "Wesley Theological Seminary",
        "hyphenated": "wesley-theological-seminary"
    },
    {
        "id": "132374",
        "name": "Atlantic Technical College",
        "hyphenated": "atlantic-technical-college"
    },
    {
        "id": "132408",
        "name": "The Baptist College of Florida",
        "hyphenated": "the-baptist-college-of-florida"
    },
    {
        "id": "132471",
        "name": "Barry University",
        "hyphenated": "barry-university"
    },
    {
        "id": "132602",
        "name": "Bethune-Cookman University",
        "hyphenated": "bethune-cookman-university"
    },
    {
        "id": "132657",
        "name": "Lynn University",
        "hyphenated": "lynn-university"
    },
    {
        "id": "132675",
        "name": "North Florida Technical College",
        "hyphenated": "north-florida-technical-college"
    },
    {
        "id": "132693",
        "name": "Eastern Florida State College",
        "hyphenated": "eastern-florida-state-college"
    },
    {
        "id": "132709",
        "name": "Broward College",
        "hyphenated": "broward-college"
    },
    {
        "id": "132842",
        "name": "Albizu University-Miami",
        "hyphenated": "albizu-university-miami"
    },
    {
        "id": "132851",
        "name": "College of Central Florida",
        "hyphenated": "college-of-central-florida"
    },
    {
        "id": "132879",
        "name": "Johnson University Florida",
        "hyphenated": "johnson-university-florida"
    },
    {
        "id": "132976",
        "name": "Charlotte Technical College",
        "hyphenated": "charlotte-technical-college"
    },
    {
        "id": "133021",
        "name": "Chipola College",
        "hyphenated": "chipola-college"
    },
    {
        "id": "133155",
        "name": "Lorenzo Walker Technical College",
        "hyphenated": "lorenzo-walker-technical-college"
    },
    {
        "id": "133386",
        "name": "Daytona State College",
        "hyphenated": "daytona-state-college"
    },
    {
        "id": "133465",
        "name": "South University-West Palm Beach",
        "hyphenated": "south-university-west-palm-beach"
    },
    {
        "id": "133492",
        "name": "Eckerd College",
        "hyphenated": "eckerd-college"
    },
    {
        "id": "133508",
        "name": "Florida SouthWestern State College",
        "hyphenated": "florida-southwestern-state-college"
    },
    {
        "id": "133526",
        "name": "Edward Waters University",
        "hyphenated": "edward-waters-university"
    },
    {
        "id": "133553",
        "name": "Embry-Riddle Aeronautical University-Daytona Beach",
        "hyphenated": "embry-riddle-aeronautical-university-daytona-beach"
    },
    {
        "id": "133650",
        "name": "Florida Agricultural and Mechanical University",
        "hyphenated": "florida-agricultural-and-mechanical-university"
    },
    {
        "id": "133669",
        "name": "Florida Atlantic University",
        "hyphenated": "florida-atlantic-university"
    },
    {
        "id": "133702",
        "name": "Florida State College at Jacksonville",
        "hyphenated": "florida-state-college-at-jacksonville"
    },
    {
        "id": "133711",
        "name": "Flagler College",
        "hyphenated": "flagler-college"
    },
    {
        "id": "133809",
        "name": "Florida College",
        "hyphenated": "florida-college"
    },
    {
        "id": "133845",
        "name": "Concorde Career Institute-Jacksonville",
        "hyphenated": "concorde-career-institute-jacksonville"
    },
    {
        "id": "133854",
        "name": "Concorde Career Institute-Miramar",
        "hyphenated": "concorde-career-institute-miramar"
    },
    {
        "id": "133863",
        "name": "Concorde Career Institute-Tampa",
        "hyphenated": "concorde-career-institute-tampa"
    },
    {
        "id": "133872",
        "name": "AdventHealth University",
        "hyphenated": "adventhealth-university"
    },
    {
        "id": "133881",
        "name": "Florida Institute of Technology",
        "hyphenated": "florida-institute-of-technology"
    },
    {
        "id": "133942",
        "name": "Florida Institute of Ultrasound Inc",
        "hyphenated": "florida-institute-of-ultrasound-inc"
    },
    {
        "id": "133951",
        "name": "Florida International University",
        "hyphenated": "florida-international-university"
    },
    {
        "id": "133960",
        "name": "The College of the Florida Keys",
        "hyphenated": "the-college-of-the-florida-keys"
    },
    {
        "id": "133979",
        "name": "Florida Memorial University",
        "hyphenated": "florida-memorial-university"
    },
    {
        "id": "133997",
        "name": "Florida Career College-Miami",
        "hyphenated": "florida-career-college-miami"
    },
    {
        "id": "134024",
        "name": "Florida School of Massage",
        "hyphenated": "florida-school-of-massage"
    },
    {
        "id": "134079",
        "name": "Florida Southern College",
        "hyphenated": "florida-southern-college"
    },
    {
        "id": "134228",
        "name": "Fort Pierce Beauty Academy",
        "hyphenated": "fort-pierce-beauty-academy"
    },
    {
        "id": "134237",
        "name": "Full Sail University",
        "hyphenated": "full-sail-university"
    },
    {
        "id": "134291",
        "name": "George Stone Technical College",
        "hyphenated": "george-stone-technical-college"
    },
    {
        "id": "134307",
        "name": "George T Baker Aviation Technical College",
        "hyphenated": "george-t-baker-aviation-technical-college"
    },
    {
        "id": "134343",
        "name": "Gulf Coast State College",
        "hyphenated": "gulf-coast-state-college"
    },
    {
        "id": "134422",
        "name": "Key College",
        "hyphenated": "key-college"
    },
    {
        "id": "134495",
        "name": "Hillsborough Community College",
        "hyphenated": "hillsborough-community-college"
    },
    {
        "id": "134510",
        "name": "Hobe Sound Bible College",
        "hyphenated": "hobe-sound-bible-college"
    },
    {
        "id": "134529",
        "name": "Hollywood Institute of Beauty Careers",
        "hyphenated": "hollywood-institute-of-beauty-careers"
    },
    {
        "id": "134574",
        "name": "Cortiva Institute",
        "hyphenated": "cortiva-institute"
    },
    {
        "id": "134608",
        "name": "Indian River State College",
        "hyphenated": "indian-river-state-college"
    },
    {
        "id": "134714",
        "name": "International Academy",
        "hyphenated": "international-academy"
    },
    {
        "id": "134811",
        "name": "AI Miami International University of Art and Design",
        "hyphenated": "ai-miami-international-university-of-art-and-design"
    },
    {
        "id": "134945",
        "name": "Jacksonville University",
        "hyphenated": "jacksonville-university"
    },
    {
        "id": "135081",
        "name": "Keiser University-Ft Lauderdale",
        "hyphenated": "keiser-university-ft-lauderdale"
    },
    {
        "id": "135142",
        "name": "La Belle Beauty School",
        "hyphenated": "la-belle-beauty-school"
    },
    {
        "id": "135160",
        "name": "Florida Gateway College",
        "hyphenated": "florida-gateway-college"
    },
    {
        "id": "135179",
        "name": "Lake Technical College",
        "hyphenated": "lake-technical-college"
    },
    {
        "id": "135188",
        "name": "Lake-Sumter State College",
        "hyphenated": "lake-sumter-state-college"
    },
    {
        "id": "135267",
        "name": "Fort Myers Technical College",
        "hyphenated": "fort-myers-technical-college"
    },
    {
        "id": "135276",
        "name": "Lively Technical College",
        "hyphenated": "lively-technical-college"
    },
    {
        "id": "135294",
        "name": "Lindsey Hopkins Technical College",
        "hyphenated": "lindsey-hopkins-technical-college"
    },
    {
        "id": "135337",
        "name": "Loraines Academy & Spa",
        "hyphenated": "loraines-academy-&-spa"
    },
    {
        "id": "135364",
        "name": "Luther Rice College & Seminary",
        "hyphenated": "luther-rice-college-&-seminary"
    },
    {
        "id": "135391",
        "name": "State College of Florida-Manatee-Sarasota",
        "hyphenated": "state-college-of-florida-manatee-sarasota"
    },
    {
        "id": "135407",
        "name": "Manatee Technical College",
        "hyphenated": "manatee-technical-college"
    },
    {
        "id": "135522",
        "name": "Traviss Technical College",
        "hyphenated": "traviss-technical-college"
    },
    {
        "id": "135559",
        "name": "Merryfield School of Pet Grooming",
        "hyphenated": "merryfield-school-of-pet-grooming"
    },
    {
        "id": "135610",
        "name": "Trinity International University-Florida",
        "hyphenated": "trinity-international-university-florida"
    },
    {
        "id": "135647",
        "name": "Miami Lakes Educational Center and Technical College",
        "hyphenated": "miami-lakes-educational-center-and-technical-college"
    },
    {
        "id": "135717",
        "name": "Miami Dade College",
        "hyphenated": "miami-dade-college"
    },
    {
        "id": "135735",
        "name": "Orange Technical College-Mid Florida Campus",
        "hyphenated": "orange-technical-college-mid-florida-campus"
    },
    {
        "id": "136109",
        "name": "Paul Mitchell the School-Jacksonville",
        "hyphenated": "paul-mitchell-the-school-jacksonville"
    },
    {
        "id": "136145",
        "name": "North Florida College",
        "hyphenated": "north-florida-college"
    },
    {
        "id": "136172",
        "name": "University of North Florida",
        "hyphenated": "university-of-north-florida"
    },
    {
        "id": "136215",
        "name": "Nova Southeastern University",
        "hyphenated": "nova-southeastern-university"
    },
    {
        "id": "136233",
        "name": "Northwest Florida State College",
        "hyphenated": "northwest-florida-state-college"
    },
    {
        "id": "136303",
        "name": "Orange Technical College",
        "hyphenated": "orange-technical-college"
    },
    {
        "id": "136330",
        "name": "Palm Beach Atlantic University",
        "hyphenated": "palm-beach-atlantic-university"
    },
    {
        "id": "136358",
        "name": "Palm Beach State College",
        "hyphenated": "palm-beach-state-college"
    },
    {
        "id": "136400",
        "name": "Pasco-Hernando State College",
        "hyphenated": "pasco-hernando-state-college"
    },
    {
        "id": "136473",
        "name": "Pensacola State College",
        "hyphenated": "pensacola-state-college"
    },
    {
        "id": "136491",
        "name": "Pinellas Technical College-Clearwater",
        "hyphenated": "pinellas-technical-college-clearwater"
    },
    {
        "id": "136516",
        "name": "Polk State College",
        "hyphenated": "polk-state-college"
    },
    {
        "id": "136659",
        "name": "Radford M Locklin Technical College",
        "hyphenated": "radford-m-locklin-technical-college"
    },
    {
        "id": "136701",
        "name": "Saint Vincent de Paul Regional Seminary",
        "hyphenated": "saint-vincent-de-paul-regional-seminary"
    },
    {
        "id": "136765",
        "name": "Ridge Technical College",
        "hyphenated": "ridge-technical-college"
    },
    {
        "id": "136774",
        "name": "Ringling College of Art and Design",
        "hyphenated": "ringling-college-of-art-and-design"
    },
    {
        "id": "136826",
        "name": "Robert Morgan Educational Center and Technical College",
        "hyphenated": "robert-morgan-educational-center-and-technical-college"
    },
    {
        "id": "136950",
        "name": "Rollins College",
        "hyphenated": "rollins-college"
    },
    {
        "id": "137023",
        "name": "First Coast Technical College",
        "hyphenated": "first-coast-technical-college"
    },
    {
        "id": "137032",
        "name": "Saint Leo University",
        "hyphenated": "saint-leo-university"
    },
    {
        "id": "137078",
        "name": "St Petersburg College",
        "hyphenated": "st-petersburg-college"
    },
    {
        "id": "137087",
        "name": "Pinellas Technical College-St. Petersburg",
        "hyphenated": "pinellas-technical-college-st.-petersburg"
    },
    {
        "id": "137096",
        "name": "Santa Fe College",
        "hyphenated": "santa-fe-college"
    },
    {
        "id": "137120",
        "name": "Suncoast Technical College",
        "hyphenated": "suncoast-technical-college"
    },
    {
        "id": "137209",
        "name": "Seminole State College of Florida",
        "hyphenated": "seminole-state-college-of-florida"
    },
    {
        "id": "137245",
        "name": "Sheridan Technical College",
        "hyphenated": "sheridan-technical-college"
    },
    {
        "id": "137272",
        "name": "St. John Vianney College Seminary",
        "hyphenated": "st.-john-vianney-college-seminary"
    },
    {
        "id": "137281",
        "name": "Saint Johns River State College",
        "hyphenated": "saint-johns-river-state-college"
    },
    {
        "id": "137315",
        "name": "South Florida State College",
        "hyphenated": "south-florida-state-college"
    },
    {
        "id": "137476",
        "name": "St. Thomas University",
        "hyphenated": "st.-thomas-university"
    },
    {
        "id": "137546",
        "name": "Stetson University",
        "hyphenated": "stetson-university"
    },
    {
        "id": "137564",
        "name": "Southeastern University",
        "hyphenated": "southeastern-university"
    },
    {
        "id": "137591",
        "name": "Bene's Career Academy",
        "hyphenated": "bene's-career-academy"
    },
    {
        "id": "137713",
        "name": "Riveroak Technical College",
        "hyphenated": "riveroak-technical-college"
    },
    {
        "id": "137759",
        "name": "Tallahassee Community College",
        "hyphenated": "tallahassee-community-college"
    },
    {
        "id": "137777",
        "name": "Talmudic College of Florida",
        "hyphenated": "talmudic-college-of-florida"
    },
    {
        "id": "137801",
        "name": "Altierus Career College-Tampa",
        "hyphenated": "altierus-career-college-tampa"
    },
    {
        "id": "137847",
        "name": "The University of Tampa",
        "hyphenated": "the-university-of-tampa"
    },
    {
        "id": "137856",
        "name": "Big Bend Technical College",
        "hyphenated": "big-bend-technical-college"
    },
    {
        "id": "137865",
        "name": "Tom P. Haney Technical College",
        "hyphenated": "tom-p.-haney-technical-college"
    },
    {
        "id": "137953",
        "name": "Trinity Baptist College",
        "hyphenated": "trinity-baptist-college"
    },
    {
        "id": "137962",
        "name": "Trinity College of Florida",
        "hyphenated": "trinity-college-of-florida"
    },
    {
        "id": "138187",
        "name": "Valencia College",
        "hyphenated": "valencia-college"
    },
    {
        "id": "138275",
        "name": "Warner University",
        "hyphenated": "warner-university"
    },
    {
        "id": "138284",
        "name": "Florida Panhandle Technical College",
        "hyphenated": "florida-panhandle-technical-college"
    },
    {
        "id": "138293",
        "name": "Webber International University",
        "hyphenated": "webber-international-university"
    },
    {
        "id": "138309",
        "name": "Rasmussen University-Florida",
        "hyphenated": "rasmussen-university-florida"
    },
    {
        "id": "138354",
        "name": "The University of West Florida",
        "hyphenated": "the-university-of-west-florida"
    },
    {
        "id": "138372",
        "name": "Orange Technical College-Westside Campus",
        "hyphenated": "orange-technical-college-westside-campus"
    },
    {
        "id": "138479",
        "name": "William T McFatter Technical College",
        "hyphenated": "william-t-mcfatter-technical-college"
    },
    {
        "id": "138488",
        "name": "Orange Technical College-East Campus",
        "hyphenated": "orange-technical-college-east-campus"
    },
    {
        "id": "138497",
        "name": "Withlacoochee Technical College",
        "hyphenated": "withlacoochee-technical-college"
    },
    {
        "id": "138558",
        "name": "Abraham Baldwin Agricultural College",
        "hyphenated": "abraham-baldwin-agricultural-college"
    },
    {
        "id": "138600",
        "name": "Agnes Scott College",
        "hyphenated": "agnes-scott-college"
    },
    {
        "id": "138655",
        "name": "Interactive College of Technology-Chamblee",
        "hyphenated": "interactive-college-of-technology-chamblee"
    },
    {
        "id": "138664",
        "name": "Interactive College of Technology-Morrow",
        "hyphenated": "interactive-college-of-technology-morrow"
    },
    {
        "id": "138682",
        "name": "Albany Technical College",
        "hyphenated": "albany-technical-college"
    },
    {
        "id": "138716",
        "name": "Albany State University",
        "hyphenated": "albany-state-university"
    },
    {
        "id": "138761",
        "name": "Andrew College",
        "hyphenated": "andrew-college"
    },
    {
        "id": "138813",
        "name": "The Art Institute of Atlanta",
        "hyphenated": "the-art-institute-of-atlanta"
    },
    {
        "id": "138822",
        "name": "Georgia Career Institute",
        "hyphenated": "georgia-career-institute"
    },
    {
        "id": "138840",
        "name": "Atlanta Technical College",
        "hyphenated": "atlanta-technical-college"
    },
    {
        "id": "138868",
        "name": "Point University",
        "hyphenated": "point-university"
    },
    {
        "id": "138901",
        "name": "Atlanta Metropolitan State College",
        "hyphenated": "atlanta-metropolitan-state-college"
    },
    {
        "id": "138929",
        "name": "Atlanta's John Marshall Law School",
        "hyphenated": "atlanta's-john-marshall-law-school"
    },
    {
        "id": "138938",
        "name": "Atlanta School of Massage",
        "hyphenated": "atlanta-school-of-massage"
    },
    {
        "id": "138947",
        "name": "Clark Atlanta University",
        "hyphenated": "clark-atlanta-university"
    },
    {
        "id": "138956",
        "name": "Augusta Technical College",
        "hyphenated": "augusta-technical-college"
    },
    {
        "id": "139144",
        "name": "Berry College",
        "hyphenated": "berry-college"
    },
    {
        "id": "139153",
        "name": "Beulah Heights University",
        "hyphenated": "beulah-heights-university"
    },
    {
        "id": "139199",
        "name": "Brenau University",
        "hyphenated": "brenau-university"
    },
    {
        "id": "139205",
        "name": "Brewton-Parker College",
        "hyphenated": "brewton-parker-college"
    },
    {
        "id": "139250",
        "name": "College of Coastal Georgia",
        "hyphenated": "college-of-coastal-georgia"
    },
    {
        "id": "139278",
        "name": "West Georgia Technical College",
        "hyphenated": "west-georgia-technical-college"
    },
    {
        "id": "139311",
        "name": "Clayton State University",
        "hyphenated": "clayton-state-university"
    },
    {
        "id": "139348",
        "name": "Columbia Theological Seminary",
        "hyphenated": "columbia-theological-seminary"
    },
    {
        "id": "139357",
        "name": "Columbus Technical College",
        "hyphenated": "columbus-technical-college"
    },
    {
        "id": "139366",
        "name": "Columbus State University",
        "hyphenated": "columbus-state-university"
    },
    {
        "id": "139384",
        "name": "Georgia Northwestern Technical College",
        "hyphenated": "georgia-northwestern-technical-college"
    },
    {
        "id": "139393",
        "name": "Covenant College",
        "hyphenated": "covenant-college"
    },
    {
        "id": "139463",
        "name": "Dalton State College",
        "hyphenated": "dalton-state-college"
    },
    {
        "id": "139506",
        "name": "Pro Way Hair School",
        "hyphenated": "pro-way-hair-school"
    },
    {
        "id": "139579",
        "name": "South University-Savannah",
        "hyphenated": "south-university-savannah"
    },
    {
        "id": "139621",
        "name": "East Georgia State College",
        "hyphenated": "east-georgia-state-college"
    },
    {
        "id": "139630",
        "name": "Emmanuel College",
        "hyphenated": "emmanuel-college"
    },
    {
        "id": "139700",
        "name": "Georgia Highlands College",
        "hyphenated": "georgia-highlands-college"
    },
    {
        "id": "139719",
        "name": "Fort Valley State University",
        "hyphenated": "fort-valley-state-university"
    },
    {
        "id": "139746",
        "name": "Grady Health System Professional Schools",
        "hyphenated": "grady-health-system-professional-schools"
    },
    {
        "id": "139764",
        "name": "Georgia Southwestern State University",
        "hyphenated": "georgia-southwestern-state-university"
    },
    {
        "id": "139861",
        "name": "Georgia College & State University",
        "hyphenated": "georgia-college-&-state-university"
    },
    {
        "id": "139931",
        "name": "Georgia Southern University",
        "hyphenated": "georgia-southern-university"
    },
    {
        "id": "139940",
        "name": "Georgia State University",
        "hyphenated": "georgia-state-university"
    },
    {
        "id": "139968",
        "name": "Gordon State College",
        "hyphenated": "gordon-state-college"
    },
    {
        "id": "139986",
        "name": "Southern Crescent Technical College",
        "hyphenated": "southern-crescent-technical-college"
    },
    {
        "id": "139995",
        "name": "Gupton Jones College of Funeral Service",
        "hyphenated": "gupton-jones-college-of-funeral-service"
    },
    {
        "id": "140003",
        "name": "Gwinnett College-Lilburn",
        "hyphenated": "gwinnett-college-lilburn"
    },
    {
        "id": "140012",
        "name": "Gwinnett Technical College",
        "hyphenated": "gwinnett-technical-college"
    },
    {
        "id": "140146",
        "name": "Interdenominational Theological Center",
        "hyphenated": "interdenominational-theological-center"
    },
    {
        "id": "140234",
        "name": "LaGrange College",
        "hyphenated": "lagrange-college"
    },
    {
        "id": "140243",
        "name": "Lanier Technical College",
        "hyphenated": "lanier-technical-college"
    },
    {
        "id": "140252",
        "name": "Life University",
        "hyphenated": "life-university"
    },
    {
        "id": "140331",
        "name": "Chattahoochee Technical College",
        "hyphenated": "chattahoochee-technical-college"
    },
    {
        "id": "140340",
        "name": "Herzing University-Atlanta",
        "hyphenated": "herzing-university-atlanta"
    },
    {
        "id": "140447",
        "name": "Mercer University",
        "hyphenated": "mercer-university"
    },
    {
        "id": "140553",
        "name": "Morehouse College",
        "hyphenated": "morehouse-college"
    },
    {
        "id": "140562",
        "name": "Morehouse School of Medicine",
        "hyphenated": "morehouse-school-of-medicine"
    },
    {
        "id": "140678",
        "name": "North Georgia Technical College",
        "hyphenated": "north-georgia-technical-college"
    },
    {
        "id": "140696",
        "name": "Oglethorpe University",
        "hyphenated": "oglethorpe-university"
    },
    {
        "id": "140720",
        "name": "Paine College",
        "hyphenated": "paine-college"
    },
    {
        "id": "140818",
        "name": "Piedmont University",
        "hyphenated": "piedmont-university"
    },
    {
        "id": "140827",
        "name": "Miami Ad School-Atlanta",
        "hyphenated": "miami-ad-school-atlanta"
    },
    {
        "id": "140872",
        "name": "Reinhardt University",
        "hyphenated": "reinhardt-university"
    },
    {
        "id": "140942",
        "name": "Savannah Technical College",
        "hyphenated": "savannah-technical-college"
    },
    {
        "id": "140951",
        "name": "Savannah College of Art and Design",
        "hyphenated": "savannah-college-of-art-and-design"
    },
    {
        "id": "140960",
        "name": "Savannah State University",
        "hyphenated": "savannah-state-university"
    },
    {
        "id": "140988",
        "name": "Shorter University",
        "hyphenated": "shorter-university"
    },
    {
        "id": "141006",
        "name": "South Georgia Technical College",
        "hyphenated": "south-georgia-technical-college"
    },
    {
        "id": "141015",
        "name": "The Creative Circus",
        "hyphenated": "the-creative-circus"
    },
    {
        "id": "141060",
        "name": "Spelman College",
        "hyphenated": "spelman-college"
    },
    {
        "id": "141167",
        "name": "Thomas University",
        "hyphenated": "thomas-university"
    },
    {
        "id": "141185",
        "name": "Toccoa Falls College",
        "hyphenated": "toccoa-falls-college"
    },
    {
        "id": "141237",
        "name": "Truett McConnell University",
        "hyphenated": "truett-mcconnell-university"
    },
    {
        "id": "141255",
        "name": "Wiregrass Georgia Technical College",
        "hyphenated": "wiregrass-georgia-technical-college"
    },
    {
        "id": "141264",
        "name": "Valdosta State University",
        "hyphenated": "valdosta-state-university"
    },
    {
        "id": "141325",
        "name": "Wesleyan College",
        "hyphenated": "wesleyan-college"
    },
    {
        "id": "141334",
        "name": "University of West Georgia",
        "hyphenated": "university-of-west-georgia"
    },
    {
        "id": "141361",
        "name": "Young Harris College",
        "hyphenated": "young-harris-college"
    },
    {
        "id": "141486",
        "name": "Chaminade University of Honolulu",
        "hyphenated": "chaminade-university-of-honolulu"
    },
    {
        "id": "141565",
        "name": "University of Hawaii at Hilo",
        "hyphenated": "university-of-hawaii-at-hilo"
    },
    {
        "id": "141574",
        "name": "University of Hawaii at Manoa",
        "hyphenated": "university-of-hawaii-at-manoa"
    },
    {
        "id": "141608",
        "name": "Hawaii Institute of Hair Design",
        "hyphenated": "hawaii-institute-of-hair-design"
    },
    {
        "id": "141644",
        "name": "Hawaii Pacific University",
        "hyphenated": "hawaii-pacific-university"
    },
    {
        "id": "141680",
        "name": "Honolulu Community College",
        "hyphenated": "honolulu-community-college"
    },
    {
        "id": "141796",
        "name": "Kapiolani Community College",
        "hyphenated": "kapiolani-community-college"
    },
    {
        "id": "141802",
        "name": "Kauai Community College",
        "hyphenated": "kauai-community-college"
    },
    {
        "id": "141811",
        "name": "Leeward Community College",
        "hyphenated": "leeward-community-college"
    },
    {
        "id": "141839",
        "name": "University of Hawaii Maui College",
        "hyphenated": "university-of-hawaii-maui-college"
    },
    {
        "id": "141981",
        "name": "University of Hawaii-West Oahu",
        "hyphenated": "university-of-hawaii-west-oahu"
    },
    {
        "id": "141990",
        "name": "Windward Community College",
        "hyphenated": "windward-community-college"
    },
    {
        "id": "142054",
        "name": "Carrington College-Boise",
        "hyphenated": "carrington-college-boise"
    },
    {
        "id": "142090",
        "name": "Boise Bible College",
        "hyphenated": "boise-bible-college"
    },
    {
        "id": "142115",
        "name": "Boise State University",
        "hyphenated": "boise-state-university"
    },
    {
        "id": "142179",
        "name": "College of Eastern Idaho",
        "hyphenated": "college-of-eastern-idaho"
    },
    {
        "id": "142276",
        "name": "Idaho State University",
        "hyphenated": "idaho-state-university"
    },
    {
        "id": "142285",
        "name": "University of Idaho",
        "hyphenated": "university-of-idaho"
    },
    {
        "id": "142294",
        "name": "The College of Idaho",
        "hyphenated": "the-college-of-idaho"
    },
    {
        "id": "142328",
        "name": "Lewis-Clark State College",
        "hyphenated": "lewis-clark-state-college"
    },
    {
        "id": "142407",
        "name": "Aveda Institute-Twin Falls",
        "hyphenated": "aveda-institute-twin-falls"
    },
    {
        "id": "142416",
        "name": "Mr Leon's School of Hair Design-Moscow",
        "hyphenated": "mr-leon's-school-of-hair-design-moscow"
    },
    {
        "id": "142443",
        "name": "North Idaho College",
        "hyphenated": "north-idaho-college"
    },
    {
        "id": "142461",
        "name": "Northwest Nazarene University",
        "hyphenated": "northwest-nazarene-university"
    },
    {
        "id": "142489",
        "name": "Nathan Layne Institute of Cosmetology",
        "hyphenated": "nathan-layne-institute-of-cosmetology"
    },
    {
        "id": "142522",
        "name": "Brigham Young University-Idaho",
        "hyphenated": "brigham-young-university-idaho"
    },
    {
        "id": "142559",
        "name": "College of Southern Idaho",
        "hyphenated": "college-of-southern-idaho"
    },
    {
        "id": "142832",
        "name": "Adler University",
        "hyphenated": "adler-university"
    },
    {
        "id": "142887",
        "name": "American Academy of Art College",
        "hyphenated": "american-academy-of-art-college"
    },
    {
        "id": "142957",
        "name": "American Islamic College",
        "hyphenated": "american-islamic-college"
    },
    {
        "id": "143048",
        "name": "School of the Art Institute of Chicago",
        "hyphenated": "school-of-the-art-institute-of-chicago"
    },
    {
        "id": "143084",
        "name": "Augustana College",
        "hyphenated": "augustana-college"
    },
    {
        "id": "143118",
        "name": "Aurora University",
        "hyphenated": "aurora-university"
    },
    {
        "id": "143215",
        "name": "Southwestern Illinois College",
        "hyphenated": "southwestern-illinois-college"
    },
    {
        "id": "143233",
        "name": "Bethany Theological Seminary",
        "hyphenated": "bethany-theological-seminary"
    },
    {
        "id": "143279",
        "name": "Black Hawk College",
        "hyphenated": "black-hawk-college"
    },
    {
        "id": "143288",
        "name": "Blackburn College",
        "hyphenated": "blackburn-college"
    },
    {
        "id": "143297",
        "name": "Blessing Rieman College of Nursing and Health Sciences",
        "hyphenated": "blessing-rieman-college-of-nursing-and-health-sciences"
    },
    {
        "id": "143358",
        "name": "Bradley University",
        "hyphenated": "bradley-university"
    },
    {
        "id": "143376",
        "name": "Paul Mitchell The School Tinley Park",
        "hyphenated": "paul-mitchell-the-school-tinley-park"
    },
    {
        "id": "143464",
        "name": "Cameo Beauty Academy",
        "hyphenated": "cameo-beauty-academy"
    },
    {
        "id": "143473",
        "name": "Cannella School of Hair Design-Villa Park",
        "hyphenated": "cannella-school-of-hair-design-villa-park"
    },
    {
        "id": "143482",
        "name": "Cannella School of Hair Design-Chicago",
        "hyphenated": "cannella-school-of-hair-design-chicago"
    },
    {
        "id": "143491",
        "name": "Cannella School of Hair Design-Chicago",
        "hyphenated": "cannella-school-of-hair-design-chicago"
    },
    {
        "id": "143552",
        "name": "Capri Beauty College",
        "hyphenated": "capri-beauty-college"
    },
    {
        "id": "143613",
        "name": "Carl Sandburg College",
        "hyphenated": "carl-sandburg-college"
    },
    {
        "id": "143659",
        "name": "Catholic Theological Union at Chicago",
        "hyphenated": "catholic-theological-union-at-chicago"
    },
    {
        "id": "143853",
        "name": "Midwestern University-Downers Grove",
        "hyphenated": "midwestern-university-downers-grove"
    },
    {
        "id": "143978",
        "name": "The Chicago School of Professional Psychology at Chicago",
        "hyphenated": "the-chicago-school-of-professional-psychology-at-chicago"
    },
    {
        "id": "144005",
        "name": "Chicago State University",
        "hyphenated": "chicago-state-university"
    },
    {
        "id": "144014",
        "name": "Chicago Theological Seminary",
        "hyphenated": "chicago-theological-seminary"
    },
    {
        "id": "144157",
        "name": "City Colleges of Chicago-Kennedy-King College",
        "hyphenated": "city-colleges-of-chicago-kennedy-king-college"
    },
    {
        "id": "144166",
        "name": "City Colleges of Chicago-Malcolm X College",
        "hyphenated": "city-colleges-of-chicago-malcolm-x-college"
    },
    {
        "id": "144175",
        "name": "City Colleges of Chicago-Olive-Harvey College",
        "hyphenated": "city-colleges-of-chicago-olive-harvey-college"
    },
    {
        "id": "144184",
        "name": "City Colleges of Chicago-Harry S Truman College",
        "hyphenated": "city-colleges-of-chicago-harry-s-truman-college"
    },
    {
        "id": "144193",
        "name": "City Colleges of Chicago-Richard J Daley College",
        "hyphenated": "city-colleges-of-chicago-richard-j-daley-college"
    },
    {
        "id": "144209",
        "name": "City Colleges of Chicago-Harold Washington College",
        "hyphenated": "city-colleges-of-chicago-harold-washington-college"
    },
    {
        "id": "144218",
        "name": "City Colleges of Chicago-Wilbur Wright College",
        "hyphenated": "city-colleges-of-chicago-wilbur-wright-college"
    },
    {
        "id": "144281",
        "name": "Columbia College Chicago",
        "hyphenated": "columbia-college-chicago"
    },
    {
        "id": "144351",
        "name": "Concordia University-Chicago",
        "hyphenated": "concordia-university-chicago"
    },
    {
        "id": "144564",
        "name": "Danville Area Community College",
        "hyphenated": "danville-area-community-college"
    },
    {
        "id": "144573",
        "name": "Tricoci University of Beauty Culture",
        "hyphenated": "tricoci-university-of-beauty-culture"
    },
    {
        "id": "144795",
        "name": "Cosmetology & Spa Academy",
        "hyphenated": "cosmetology-&-spa-academy"
    },
    {
        "id": "144865",
        "name": "College of DuPage",
        "hyphenated": "college-of-dupage"
    },
    {
        "id": "144883",
        "name": "East-West University",
        "hyphenated": "east-west-university"
    },
    {
        "id": "144892",
        "name": "Eastern Illinois University",
        "hyphenated": "eastern-illinois-university"
    },
    {
        "id": "144944",
        "name": "Elgin Community College",
        "hyphenated": "elgin-community-college"
    },
    {
        "id": "144962",
        "name": "Elmhurst University",
        "hyphenated": "elmhurst-university"
    },
    {
        "id": "144971",
        "name": "Eureka College",
        "hyphenated": "eureka-college"
    },
    {
        "id": "145099",
        "name": "First Institute of Travel Inc.",
        "hyphenated": "first-institute-of-travel-inc."
    },
    {
        "id": "145239",
        "name": "Fox College",
        "hyphenated": "fox-college"
    },
    {
        "id": "145275",
        "name": "Garrett-Evangelical Theological Seminary",
        "hyphenated": "garrett-evangelical-theological-seminary"
    },
    {
        "id": "145309",
        "name": "Gem City College",
        "hyphenated": "gem-city-college"
    },
    {
        "id": "145336",
        "name": "Governors State University",
        "hyphenated": "governors-state-university"
    },
    {
        "id": "145354",
        "name": "Graham Hospital School of Nursing",
        "hyphenated": "graham-hospital-school-of-nursing"
    },
    {
        "id": "145372",
        "name": "Greenville University",
        "hyphenated": "greenville-university"
    },
    {
        "id": "145406",
        "name": "G Skin & Beauty Institute",
        "hyphenated": "g-skin-&-beauty-institute"
    },
    {
        "id": "145424",
        "name": "Hair Professionals Career College",
        "hyphenated": "hair-professionals-career-college"
    },
    {
        "id": "145433",
        "name": "Hair Professionals School of Cosmetology",
        "hyphenated": "hair-professionals-school-of-cosmetology"
    },
    {
        "id": "145497",
        "name": "Hebrew Theological College",
        "hyphenated": "hebrew-theological-college"
    },
    {
        "id": "145521",
        "name": "Highland Community College",
        "hyphenated": "highland-community-college"
    },
    {
        "id": "145558",
        "name": "Rosalind Franklin University of Medicine and Science",
        "hyphenated": "rosalind-franklin-university-of-medicine-and-science"
    },
    {
        "id": "145619",
        "name": "Benedictine University",
        "hyphenated": "benedictine-university"
    },
    {
        "id": "145628",
        "name": "Illinois College of Optometry",
        "hyphenated": "illinois-college-of-optometry"
    },
    {
        "id": "145646",
        "name": "Illinois Wesleyan University",
        "hyphenated": "illinois-wesleyan-university"
    },
    {
        "id": "145682",
        "name": "Illinois Central College",
        "hyphenated": "illinois-central-college"
    },
    {
        "id": "145691",
        "name": "Illinois College",
        "hyphenated": "illinois-college"
    },
    {
        "id": "145707",
        "name": "Olney Central College",
        "hyphenated": "olney-central-college"
    },
    {
        "id": "145813",
        "name": "Illinois State University",
        "hyphenated": "illinois-state-university"
    },
    {
        "id": "145831",
        "name": "Illinois Valley Community College",
        "hyphenated": "illinois-valley-community-college"
    },
    {
        "id": "145886",
        "name": "Institute for Clinical Social Work",
        "hyphenated": "institute-for-clinical-social-work"
    },
    {
        "id": "146205",
        "name": "John A Logan College",
        "hyphenated": "john-a-logan-college"
    },
    {
        "id": "146278",
        "name": "John Wood Community College",
        "hyphenated": "john-wood-community-college"
    },
    {
        "id": "146296",
        "name": "Joliet Junior College",
        "hyphenated": "joliet-junior-college"
    },
    {
        "id": "146339",
        "name": "Judson University",
        "hyphenated": "judson-university"
    },
    {
        "id": "146348",
        "name": "Kankakee Community College",
        "hyphenated": "kankakee-community-college"
    },
    {
        "id": "146366",
        "name": "Kaskaskia College",
        "hyphenated": "kaskaskia-college"
    },
    {
        "id": "146418",
        "name": "Kishwaukee College",
        "hyphenated": "kishwaukee-college"
    },
    {
        "id": "146427",
        "name": "Knox College",
        "hyphenated": "knox-college"
    },
    {
        "id": "146454",
        "name": "Educators of Beauty College of Cosmetology-Peru",
        "hyphenated": "educators-of-beauty-college-of-cosmetology-peru"
    },
    {
        "id": "146472",
        "name": "College of Lake County",
        "hyphenated": "college-of-lake-county"
    },
    {
        "id": "146481",
        "name": "Lake Forest College",
        "hyphenated": "lake-forest-college"
    },
    {
        "id": "146490",
        "name": "Lake Forest Graduate School of Management",
        "hyphenated": "lake-forest-graduate-school-of-management"
    },
    {
        "id": "146506",
        "name": "Lake Land College",
        "hyphenated": "lake-land-college"
    },
    {
        "id": "146533",
        "name": "Lakeview College of Nursing",
        "hyphenated": "lakeview-college-of-nursing"
    },
    {
        "id": "146603",
        "name": "Lewis and Clark Community College",
        "hyphenated": "lewis-and-clark-community-college"
    },
    {
        "id": "146612",
        "name": "Lewis University",
        "hyphenated": "lewis-university"
    },
    {
        "id": "146667",
        "name": "Lincoln Christian University",
        "hyphenated": "lincoln-christian-university"
    },
    {
        "id": "146676",
        "name": "Lincoln College",
        "hyphenated": "lincoln-college"
    },
    {
        "id": "146685",
        "name": "Lincoln Land Community College",
        "hyphenated": "lincoln-land-community-college"
    },
    {
        "id": "146700",
        "name": "Lincoln College of Technology-Melrose Park",
        "hyphenated": "lincoln-college-of-technology-melrose-park"
    },
    {
        "id": "146728",
        "name": "Lutheran School of Theology at Chicago",
        "hyphenated": "lutheran-school-of-theology-at-chicago"
    },
    {
        "id": "146755",
        "name": "Trinity College of Nursing & Health Sciences",
        "hyphenated": "trinity-college-of-nursing-&-health-sciences"
    },
    {
        "id": "146807",
        "name": "Steven Papageorge Hair Academy",
        "hyphenated": "steven-papageorge-hair-academy"
    },
    {
        "id": "146816",
        "name": "Generations College",
        "hyphenated": "generations-college"
    },
    {
        "id": "146977",
        "name": "McCormick Theological Seminary",
        "hyphenated": "mccormick-theological-seminary"
    },
    {
        "id": "147004",
        "name": "McHenry County College",
        "hyphenated": "mchenry-county-college"
    },
    {
        "id": "147013",
        "name": "McKendree University",
        "hyphenated": "mckendree-university"
    },
    {
        "id": "147031",
        "name": "Meadville Theological School of Lombard College",
        "hyphenated": "meadville-theological-school-of-lombard-college"
    },
    {
        "id": "147129",
        "name": "Methodist College",
        "hyphenated": "methodist-college"
    },
    {
        "id": "147244",
        "name": "Millikin University",
        "hyphenated": "millikin-university"
    },
    {
        "id": "147341",
        "name": "Monmouth College",
        "hyphenated": "monmouth-college"
    },
    {
        "id": "147369",
        "name": "Moody Bible Institute",
        "hyphenated": "moody-bible-institute"
    },
    {
        "id": "147378",
        "name": "Moraine Valley Community College",
        "hyphenated": "moraine-valley-community-college"
    },
    {
        "id": "147396",
        "name": "Morrison Institute of Technology",
        "hyphenated": "morrison-institute-of-technology"
    },
    {
        "id": "147411",
        "name": "Morton College",
        "hyphenated": "morton-college"
    },
    {
        "id": "147536",
        "name": "National Louis University",
        "hyphenated": "national-louis-university"
    },
    {
        "id": "147590",
        "name": "National University of Health Sciences",
        "hyphenated": "national-university-of-health-sciences"
    },
    {
        "id": "147642",
        "name": "Cosmetology Concepts Niles",
        "hyphenated": "cosmetology-concepts-niles"
    },
    {
        "id": "147660",
        "name": "North Central College",
        "hyphenated": "north-central-college"
    },
    {
        "id": "147679",
        "name": "North Park University",
        "hyphenated": "north-park-university"
    },
    {
        "id": "147697",
        "name": "Northern Baptist Theological Seminary",
        "hyphenated": "northern-baptist-theological-seminary"
    },
    {
        "id": "147703",
        "name": "Northern Illinois University",
        "hyphenated": "northern-illinois-university"
    },
    {
        "id": "147776",
        "name": "Northeastern Illinois University",
        "hyphenated": "northeastern-illinois-university"
    },
    {
        "id": "147800",
        "name": "Oakton Community College",
        "hyphenated": "oakton-community-college"
    },
    {
        "id": "147819",
        "name": "Oehrlein School of Cosmetology",
        "hyphenated": "oehrlein-school-of-cosmetology"
    },
    {
        "id": "147828",
        "name": "Olivet Nazarene University",
        "hyphenated": "olivet-nazarene-university"
    },
    {
        "id": "147916",
        "name": "Parkland College",
        "hyphenated": "parkland-college"
    },
    {
        "id": "148007",
        "name": "Prairie State College",
        "hyphenated": "prairie-state-college"
    },
    {
        "id": "148016",
        "name": "Principia College",
        "hyphenated": "principia-college"
    },
    {
        "id": "148098",
        "name": "Professional's Choice Hair Design Academy",
        "hyphenated": "professional's-choice-hair-design-academy"
    },
    {
        "id": "148131",
        "name": "Quincy University",
        "hyphenated": "quincy-university"
    },
    {
        "id": "148256",
        "name": "Rend Lake College",
        "hyphenated": "rend-lake-college"
    },
    {
        "id": "148292",
        "name": "Richland Community College",
        "hyphenated": "richland-community-college"
    },
    {
        "id": "148380",
        "name": "Rock Valley College",
        "hyphenated": "rock-valley-college"
    },
    {
        "id": "148405",
        "name": "Rockford University",
        "hyphenated": "rockford-university"
    },
    {
        "id": "148487",
        "name": "Roosevelt University",
        "hyphenated": "roosevelt-university"
    },
    {
        "id": "148496",
        "name": "Dominican University",
        "hyphenated": "dominican-university"
    },
    {
        "id": "148511",
        "name": "Rush University",
        "hyphenated": "rush-university"
    },
    {
        "id": "148575",
        "name": "Saint Francis Medical Center College of Nursing",
        "hyphenated": "saint-francis-medical-center-college-of-nursing"
    },
    {
        "id": "148584",
        "name": "University of St Francis",
        "hyphenated": "university-of-st-francis"
    },
    {
        "id": "148593",
        "name": "St. John's College-Department of Nursing",
        "hyphenated": "st.-john's-college-department-of-nursing"
    },
    {
        "id": "148627",
        "name": "Saint Xavier University",
        "hyphenated": "saint-xavier-university"
    },
    {
        "id": "148654",
        "name": "University of Illinois Springfield",
        "hyphenated": "university-of-illinois-springfield"
    },
    {
        "id": "148672",
        "name": "Sauk Valley Community College",
        "hyphenated": "sauk-valley-community-college"
    },
    {
        "id": "148821",
        "name": "Shawnee Community College",
        "hyphenated": "shawnee-community-college"
    },
    {
        "id": "148876",
        "name": "St. Augustine College",
        "hyphenated": "st.-augustine-college"
    },
    {
        "id": "148885",
        "name": "University of Saint Mary of the Lake",
        "hyphenated": "university-of-saint-mary-of-the-lake"
    },
    {
        "id": "148937",
        "name": "Southeastern Illinois College",
        "hyphenated": "southeastern-illinois-college"
    },
    {
        "id": "148955",
        "name": "National Latino Education Institute",
        "hyphenated": "national-latino-education-institute"
    },
    {
        "id": "148982",
        "name": "Spertus College",
        "hyphenated": "spertus-college"
    },
    {
        "id": "148991",
        "name": "Spoon River College",
        "hyphenated": "spoon-river-college"
    },
    {
        "id": "149028",
        "name": "Saint Anthony College of Nursing",
        "hyphenated": "saint-anthony-college-of-nursing"
    },
    {
        "id": "149204",
        "name": "Educators of Beauty College of Cosmetology-Sterling",
        "hyphenated": "educators-of-beauty-college-of-cosmetology-sterling"
    },
    {
        "id": "149222",
        "name": "Southern Illinois University-Carbondale",
        "hyphenated": "southern-illinois-university-carbondale"
    },
    {
        "id": "149231",
        "name": "Southern Illinois University-Edwardsville",
        "hyphenated": "southern-illinois-university-edwardsville"
    },
    {
        "id": "149310",
        "name": "Taylor Business Institute",
        "hyphenated": "taylor-business-institute"
    },
    {
        "id": "149329",
        "name": "Telshe Yeshiva-Chicago",
        "hyphenated": "telshe-yeshiva-chicago"
    },
    {
        "id": "149365",
        "name": "South Suburban College",
        "hyphenated": "south-suburban-college"
    },
    {
        "id": "149499",
        "name": "Tri-County Beauty Academy",
        "hyphenated": "tri-county-beauty-academy"
    },
    {
        "id": "149505",
        "name": "Trinity Christian College",
        "hyphenated": "trinity-christian-college"
    },
    {
        "id": "149514",
        "name": "Trinity International University-Illinois",
        "hyphenated": "trinity-international-university-illinois"
    },
    {
        "id": "149532",
        "name": "Triton College",
        "hyphenated": "triton-college"
    },
    {
        "id": "149550",
        "name": "University of Spa & Cosmetology Arts",
        "hyphenated": "university-of-spa-&-cosmetology-arts"
    },
    {
        "id": "149639",
        "name": "VanderCook College of Music",
        "hyphenated": "vandercook-college-of-music"
    },
    {
        "id": "149727",
        "name": "Waubonsee Community College",
        "hyphenated": "waubonsee-community-college"
    },
    {
        "id": "149763",
        "name": "Oak Point University",
        "hyphenated": "oak-point-university"
    },
    {
        "id": "149772",
        "name": "Western Illinois University",
        "hyphenated": "western-illinois-university"
    },
    {
        "id": "149781",
        "name": "Wheaton College",
        "hyphenated": "wheaton-college"
    },
    {
        "id": "149842",
        "name": "William Rainey Harper College",
        "hyphenated": "william-rainey-harper-college"
    },
    {
        "id": "150066",
        "name": "Anderson University",
        "hyphenated": "anderson-university"
    },
    {
        "id": "150136",
        "name": "Ball State University",
        "hyphenated": "ball-state-university"
    },
    {
        "id": "150145",
        "name": "Bethel University",
        "hyphenated": "bethel-university"
    },
    {
        "id": "150163",
        "name": "Butler University",
        "hyphenated": "butler-university"
    },
    {
        "id": "150172",
        "name": "Calumet College of Saint Joseph",
        "hyphenated": "calumet-college-of-saint-joseph"
    },
    {
        "id": "150215",
        "name": "Christian Theological Seminary",
        "hyphenated": "christian-theological-seminary"
    },
    {
        "id": "150251",
        "name": "College of Court Reporting Inc",
        "hyphenated": "college-of-court-reporting-inc"
    },
    {
        "id": "150288",
        "name": "Concordia Theological Seminary",
        "hyphenated": "concordia-theological-seminary"
    },
    {
        "id": "150303",
        "name": "Tricoci University of Beauty Culture-Highland",
        "hyphenated": "tricoci-university-of-beauty-culture-highland"
    },
    {
        "id": "150400",
        "name": "DePauw University",
        "hyphenated": "depauw-university"
    },
    {
        "id": "150428",
        "name": "Don Roberts School of Hair Design",
        "hyphenated": "don-roberts-school-of-hair-design"
    },
    {
        "id": "150455",
        "name": "Earlham College",
        "hyphenated": "earlham-college"
    },
    {
        "id": "150534",
        "name": "University of Evansville",
        "hyphenated": "university-of-evansville"
    },
    {
        "id": "150604",
        "name": "Franklin College",
        "hyphenated": "franklin-college"
    },
    {
        "id": "150668",
        "name": "Goshen College",
        "hyphenated": "goshen-college"
    },
    {
        "id": "150677",
        "name": "Grace College and Theological Seminary",
        "hyphenated": "grace-college-and-theological-seminary"
    },
    {
        "id": "150756",
        "name": "Hanover College",
        "hyphenated": "hanover-college"
    },
    {
        "id": "150765",
        "name": "J Michael Harrold Beauty Academy",
        "hyphenated": "j-michael-harrold-beauty-academy"
    },
    {
        "id": "150774",
        "name": "Holy Cross College",
        "hyphenated": "holy-cross-college"
    },
    {
        "id": "150853",
        "name": "PJ's College of Cosmetology-Clarksville",
        "hyphenated": "pj's-college-of-cosmetology-clarksville"
    },
    {
        "id": "150941",
        "name": "Huntington University",
        "hyphenated": "huntington-university"
    },
    {
        "id": "150987",
        "name": "Ivy Tech Community College",
        "hyphenated": "ivy-tech-community-college"
    },
    {
        "id": "151102",
        "name": "Purdue University Fort Wayne",
        "hyphenated": "purdue-university-fort-wayne"
    },
    {
        "id": "151111",
        "name": "Indiana University-Purdue University-Indianapolis",
        "hyphenated": "indiana-university-purdue-university-indianapolis"
    },
    {
        "id": "151263",
        "name": "University of Indianapolis",
        "hyphenated": "university-of-indianapolis"
    },
    {
        "id": "151290",
        "name": "Indiana Institute of Technology",
        "hyphenated": "indiana-institute-of-technology"
    },
    {
        "id": "151306",
        "name": "University of Southern Indiana",
        "hyphenated": "university-of-southern-indiana"
    },
    {
        "id": "151324",
        "name": "Indiana State University",
        "hyphenated": "indiana-state-university"
    },
    {
        "id": "151333",
        "name": "Indiana University-Kokomo",
        "hyphenated": "indiana-university-kokomo"
    },
    {
        "id": "151342",
        "name": "Indiana University-South Bend",
        "hyphenated": "indiana-university-south-bend"
    },
    {
        "id": "151360",
        "name": "Indiana University-Northwest",
        "hyphenated": "indiana-university-northwest"
    },
    {
        "id": "151379",
        "name": "Indiana University-Southeast",
        "hyphenated": "indiana-university-southeast"
    },
    {
        "id": "151388",
        "name": "Indiana University-East",
        "hyphenated": "indiana-university-east"
    },
    {
        "id": "151467",
        "name": "International Business College-Indianapolis",
        "hyphenated": "international-business-college-indianapolis"
    },
    {
        "id": "151564",
        "name": "Taylor Institute of Cosmetology II",
        "hyphenated": "taylor-institute-of-cosmetology-ii"
    },
    {
        "id": "151607",
        "name": "Tricoci University of Beauty Culture-Lafayette",
        "hyphenated": "tricoci-university-of-beauty-culture-lafayette"
    },
    {
        "id": "151661",
        "name": "Lincoln College of Technology-Indianapolis",
        "hyphenated": "lincoln-college-of-technology-indianapolis"
    },
    {
        "id": "151777",
        "name": "Manchester University",
        "hyphenated": "manchester-university"
    },
    {
        "id": "151786",
        "name": "Marian University",
        "hyphenated": "marian-university"
    },
    {
        "id": "151801",
        "name": "Indiana Wesleyan University-Marion",
        "hyphenated": "indiana-wesleyan-university-marion"
    },
    {
        "id": "151810",
        "name": "Martin University",
        "hyphenated": "martin-university"
    },
    {
        "id": "151865",
        "name": "Anabaptist Mennonite Biblical Seminary",
        "hyphenated": "anabaptist-mennonite-biblical-seminary"
    },
    {
        "id": "151962",
        "name": "Mid-America College of Funeral Service",
        "hyphenated": "mid-america-college-of-funeral-service"
    },
    {
        "id": "152044",
        "name": "West Michigan College of Barbering and Beauty",
        "hyphenated": "west-michigan-college-of-barbering-and-beauty"
    },
    {
        "id": "152099",
        "name": "Oakland City University",
        "hyphenated": "oakland-city-university"
    },
    {
        "id": "152150",
        "name": "PJ's College of Cosmetology-Richmond",
        "hyphenated": "pj's-college-of-cosmetology-richmond"
    },
    {
        "id": "152275",
        "name": "Ravenscroft Beauty College",
        "hyphenated": "ravenscroft-beauty-college"
    },
    {
        "id": "152309",
        "name": "Rogers Academy of Hair Design",
        "hyphenated": "rogers-academy-of-hair-design"
    },
    {
        "id": "152318",
        "name": "Rose-Hulman Institute of Technology",
        "hyphenated": "rose-hulman-institute-of-technology"
    },
    {
        "id": "152336",
        "name": "University of Saint Francis-Fort Wayne",
        "hyphenated": "university-of-saint-francis-fort-wayne"
    },
    {
        "id": "152381",
        "name": "Saint Mary-of-the-Woods College",
        "hyphenated": "saint-mary-of-the-woods-college"
    },
    {
        "id": "152390",
        "name": "Saint Mary's College",
        "hyphenated": "saint-mary's-college"
    },
    {
        "id": "152451",
        "name": "Saint Meinrad School of Theology",
        "hyphenated": "saint-meinrad-school-of-theology"
    },
    {
        "id": "152497",
        "name": "Saint Elizabeth School of Nursing",
        "hyphenated": "saint-elizabeth-school-of-nursing"
    },
    {
        "id": "152530",
        "name": "Taylor University",
        "hyphenated": "taylor-university"
    },
    {
        "id": "152567",
        "name": "Trine University",
        "hyphenated": "trine-university"
    },
    {
        "id": "152600",
        "name": "Valparaiso University",
        "hyphenated": "valparaiso-university"
    },
    {
        "id": "152628",
        "name": "Vincennes Beauty College",
        "hyphenated": "vincennes-beauty-college"
    },
    {
        "id": "152637",
        "name": "Vincennes University",
        "hyphenated": "vincennes-university"
    },
    {
        "id": "152673",
        "name": "Wabash College",
        "hyphenated": "wabash-college"
    },
    {
        "id": "152798",
        "name": "Allen College",
        "hyphenated": "allen-college"
    },
    {
        "id": "152992",
        "name": "Briar Cliff University",
        "hyphenated": "briar-cliff-university"
    },
    {
        "id": "153001",
        "name": "Buena Vista University",
        "hyphenated": "buena-vista-university"
    },
    {
        "id": "153074",
        "name": "Capri College-Dubuque",
        "hyphenated": "capri-college-dubuque"
    },
    {
        "id": "153083",
        "name": "Capri College-Cedar Rapids",
        "hyphenated": "capri-college-cedar-rapids"
    },
    {
        "id": "153108",
        "name": "Central College",
        "hyphenated": "central-college"
    },
    {
        "id": "153126",
        "name": "Clarke University",
        "hyphenated": "clarke-university"
    },
    {
        "id": "153144",
        "name": "Coe College",
        "hyphenated": "coe-college"
    },
    {
        "id": "153162",
        "name": "Cornell College",
        "hyphenated": "cornell-college"
    },
    {
        "id": "153214",
        "name": "Des Moines Area Community College",
        "hyphenated": "des-moines-area-community-college"
    },
    {
        "id": "153241",
        "name": "Divine Word College",
        "hyphenated": "divine-word-college"
    },
    {
        "id": "153250",
        "name": "Dordt University",
        "hyphenated": "dordt-university"
    },
    {
        "id": "153278",
        "name": "University of Dubuque",
        "hyphenated": "university-of-dubuque"
    },
    {
        "id": "153296",
        "name": "Ellsworth Community College",
        "hyphenated": "ellsworth-community-college"
    },
    {
        "id": "153302",
        "name": "Emmaus Bible College",
        "hyphenated": "emmaus-bible-college"
    },
    {
        "id": "153311",
        "name": "Eastern Iowa Community College District",
        "hyphenated": "eastern-iowa-community-college-district"
    },
    {
        "id": "153320",
        "name": "Faith Baptist Bible College and Theological Seminary",
        "hyphenated": "faith-baptist-bible-college-and-theological-seminary"
    },
    {
        "id": "153366",
        "name": "Graceland University-Lamoni",
        "hyphenated": "graceland-university-lamoni"
    },
    {
        "id": "153375",
        "name": "Grand View University",
        "hyphenated": "grand-view-university"
    },
    {
        "id": "153427",
        "name": "Orion Technical College",
        "hyphenated": "orion-technical-college"
    },
    {
        "id": "153445",
        "name": "Hawkeye Community College",
        "hyphenated": "hawkeye-community-college"
    },
    {
        "id": "153463",
        "name": "American Hair Academy",
        "hyphenated": "american-hair-academy"
    },
    {
        "id": "153472",
        "name": "Indian Hills Community College",
        "hyphenated": "indian-hills-community-college"
    },
    {
        "id": "153524",
        "name": "Iowa Central Community College",
        "hyphenated": "iowa-central-community-college"
    },
    {
        "id": "153533",
        "name": "Iowa Lakes Community College",
        "hyphenated": "iowa-lakes-community-college"
    },
    {
        "id": "153542",
        "name": "UnityPoint Health-Des Moines School of Radiologic Technology",
        "hyphenated": "unitypoint-health-des-moines-school-of-radiologic-technology"
    },
    {
        "id": "153597",
        "name": "Iowa School of Beauty-Des Moines",
        "hyphenated": "iowa-school-of-beauty-des-moines"
    },
    {
        "id": "153621",
        "name": "Iowa Wesleyan University",
        "hyphenated": "iowa-wesleyan-university"
    },
    {
        "id": "153630",
        "name": "Iowa Western Community College",
        "hyphenated": "iowa-western-community-college"
    },
    {
        "id": "153737",
        "name": "Kirkwood Community College",
        "hyphenated": "kirkwood-community-college"
    },
    {
        "id": "153746",
        "name": "La James International College-Cedar Falls",
        "hyphenated": "la-james-international-college-cedar-falls"
    },
    {
        "id": "153764",
        "name": "La James International College-Johnston",
        "hyphenated": "la-james-international-college-johnston"
    },
    {
        "id": "153773",
        "name": "La James College of Hairstyling and Cosmetology",
        "hyphenated": "la-james-college-of-hairstyling-and-cosmetology"
    },
    {
        "id": "153782",
        "name": "La James International College-Ft Dodge",
        "hyphenated": "la-james-international-college-ft-dodge"
    },
    {
        "id": "153825",
        "name": "Loras College",
        "hyphenated": "loras-college"
    },
    {
        "id": "153834",
        "name": "Luther College",
        "hyphenated": "luther-college"
    },
    {
        "id": "153861",
        "name": "Maharishi International University",
        "hyphenated": "maharishi-international-university"
    },
    {
        "id": "153922",
        "name": "Marshalltown Community College",
        "hyphenated": "marshalltown-community-college"
    },
    {
        "id": "153977",
        "name": "Mercy College of Health Sciences",
        "hyphenated": "mercy-college-of-health-sciences"
    },
    {
        "id": "154004",
        "name": "Morningside University",
        "hyphenated": "morningside-university"
    },
    {
        "id": "154013",
        "name": "Mount Mercy University",
        "hyphenated": "mount-mercy-university"
    },
    {
        "id": "154022",
        "name": "Ashford University",
        "hyphenated": "ashford-university"
    },
    {
        "id": "154059",
        "name": "North Iowa Area Community College",
        "hyphenated": "north-iowa-area-community-college"
    },
    {
        "id": "154095",
        "name": "University of Northern Iowa",
        "hyphenated": "university-of-northern-iowa"
    },
    {
        "id": "154101",
        "name": "Northwestern College",
        "hyphenated": "northwestern-college"
    },
    {
        "id": "154110",
        "name": "Northeast Iowa Community College",
        "hyphenated": "northeast-iowa-community-college"
    },
    {
        "id": "154129",
        "name": "Northwest Iowa Community College",
        "hyphenated": "northwest-iowa-community-college"
    },
    {
        "id": "154156",
        "name": "Des Moines University-Osteopathic Medical Center",
        "hyphenated": "des-moines-university-osteopathic-medical-center"
    },
    {
        "id": "154165",
        "name": "Iowa School of Beauty-Ottumwa",
        "hyphenated": "iowa-school-of-beauty-ottumwa"
    },
    {
        "id": "154174",
        "name": "Palmer College of Chiropractic",
        "hyphenated": "palmer-college-of-chiropractic"
    },
    {
        "id": "154208",
        "name": "PCI Academy-Ames",
        "hyphenated": "pci-academy-ames"
    },
    {
        "id": "154235",
        "name": "Saint Ambrose University",
        "hyphenated": "saint-ambrose-university"
    },
    {
        "id": "154262",
        "name": "St Luke's College",
        "hyphenated": "st-luke's-college"
    },
    {
        "id": "154332",
        "name": "La James International College-Davenport",
        "hyphenated": "la-james-international-college-davenport"
    },
    {
        "id": "154350",
        "name": "Simpson College",
        "hyphenated": "simpson-college"
    },
    {
        "id": "154378",
        "name": "Southeastern Community College",
        "hyphenated": "southeastern-community-college"
    },
    {
        "id": "154396",
        "name": "Southwestern Community College",
        "hyphenated": "southwestern-community-college"
    },
    {
        "id": "154448",
        "name": "Capri College-Davenport",
        "hyphenated": "capri-college-davenport"
    },
    {
        "id": "154457",
        "name": "Iowa School of Beauty-Sioux City",
        "hyphenated": "iowa-school-of-beauty-sioux-city"
    },
    {
        "id": "154466",
        "name": "E Q School of Hair Design",
        "hyphenated": "e-q-school-of-hair-design"
    },
    {
        "id": "154493",
        "name": "Upper Iowa University",
        "hyphenated": "upper-iowa-university"
    },
    {
        "id": "154518",
        "name": "Waldorf University",
        "hyphenated": "waldorf-university"
    },
    {
        "id": "154527",
        "name": "Wartburg College",
        "hyphenated": "wartburg-college"
    },
    {
        "id": "154536",
        "name": "Wartburg Theological Seminary",
        "hyphenated": "wartburg-theological-seminary"
    },
    {
        "id": "154545",
        "name": "The Salon Professional Academy-Cedar Falls",
        "hyphenated": "the-salon-professional-academy-cedar-falls"
    },
    {
        "id": "154572",
        "name": "Western Iowa Tech Community College",
        "hyphenated": "western-iowa-tech-community-college"
    },
    {
        "id": "154590",
        "name": "William Penn University",
        "hyphenated": "william-penn-university"
    },
    {
        "id": "154642",
        "name": "Allen County Community College",
        "hyphenated": "allen-county-community-college"
    },
    {
        "id": "154688",
        "name": "Baker University",
        "hyphenated": "baker-university"
    },
    {
        "id": "154697",
        "name": "Barton County Community College",
        "hyphenated": "barton-county-community-college"
    },
    {
        "id": "154712",
        "name": "Benedictine College",
        "hyphenated": "benedictine-college"
    },
    {
        "id": "154721",
        "name": "Bethany College",
        "hyphenated": "bethany-college"
    },
    {
        "id": "154749",
        "name": "Bethel College-North Newton",
        "hyphenated": "bethel-college-north-newton"
    },
    {
        "id": "154800",
        "name": "Butler Community College",
        "hyphenated": "butler-community-college"
    },
    {
        "id": "154855",
        "name": "Central Christian College of Kansas",
        "hyphenated": "central-christian-college-of-kansas"
    },
    {
        "id": "154907",
        "name": "Cloud County Community College",
        "hyphenated": "cloud-county-community-college"
    },
    {
        "id": "154925",
        "name": "Coffeyville Community College",
        "hyphenated": "coffeyville-community-college"
    },
    {
        "id": "154934",
        "name": "Colby Community College",
        "hyphenated": "colby-community-college"
    },
    {
        "id": "154952",
        "name": "Cowley County Community College",
        "hyphenated": "cowley-county-community-college"
    },
    {
        "id": "154998",
        "name": "Dodge City Community College",
        "hyphenated": "dodge-city-community-college"
    },
    {
        "id": "155007",
        "name": "Donnelly College",
        "hyphenated": "donnelly-college"
    },
    {
        "id": "155025",
        "name": "Emporia State University",
        "hyphenated": "emporia-state-university"
    },
    {
        "id": "155052",
        "name": "Flint Hills Technical College",
        "hyphenated": "flint-hills-technical-college"
    },
    {
        "id": "155061",
        "name": "Fort Hays State University",
        "hyphenated": "fort-hays-state-university"
    },
    {
        "id": "155070",
        "name": "Barclay College",
        "hyphenated": "barclay-college"
    },
    {
        "id": "155089",
        "name": "Friends University",
        "hyphenated": "friends-university"
    },
    {
        "id": "155098",
        "name": "Fort Scott Community College",
        "hyphenated": "fort-scott-community-college"
    },
    {
        "id": "155104",
        "name": "Garden City Community College",
        "hyphenated": "garden-city-community-college"
    },
    {
        "id": "155140",
        "name": "Haskell Indian Nations University",
        "hyphenated": "haskell-indian-nations-university"
    },
    {
        "id": "155159",
        "name": "Hays Academy of Hair Design",
        "hyphenated": "hays-academy-of-hair-design"
    },
    {
        "id": "155177",
        "name": "Hesston College",
        "hyphenated": "hesston-college"
    },
    {
        "id": "155186",
        "name": "Highland Community College",
        "hyphenated": "highland-community-college"
    },
    {
        "id": "155195",
        "name": "Hutchinson Community College",
        "hyphenated": "hutchinson-community-college"
    },
    {
        "id": "155201",
        "name": "Independence Community College",
        "hyphenated": "independence-community-college"
    },
    {
        "id": "155210",
        "name": "Johnson County Community College",
        "hyphenated": "johnson-county-community-college"
    },
    {
        "id": "155283",
        "name": "Concorde Career College-Kansas City",
        "hyphenated": "concorde-career-college-kansas-city"
    },
    {
        "id": "155292",
        "name": "Kansas City Kansas Community College",
        "hyphenated": "kansas-city-kansas-community-college"
    },
    {
        "id": "155308",
        "name": "Kansas Christian College",
        "hyphenated": "kansas-christian-college"
    },
    {
        "id": "155335",
        "name": "Newman University",
        "hyphenated": "newman-university"
    },
    {
        "id": "155353",
        "name": "Old Town Barber College-Wichita",
        "hyphenated": "old-town-barber-college-wichita"
    },
    {
        "id": "155399",
        "name": "Kansas State University",
        "hyphenated": "kansas-state-university"
    },
    {
        "id": "155414",
        "name": "Kansas Wesleyan University",
        "hyphenated": "kansas-wesleyan-university"
    },
    {
        "id": "155423",
        "name": "Washburn Institute of Technology",
        "hyphenated": "washburn-institute-of-technology"
    },
    {
        "id": "155450",
        "name": "Labette Community College",
        "hyphenated": "labette-community-college"
    },
    {
        "id": "155487",
        "name": "Manhattan Area Technical College",
        "hyphenated": "manhattan-area-technical-college"
    },
    {
        "id": "155496",
        "name": "Manhattan Christian College",
        "hyphenated": "manhattan-christian-college"
    },
    {
        "id": "155511",
        "name": "McPherson College",
        "hyphenated": "mcpherson-college"
    },
    {
        "id": "155520",
        "name": "MidAmerica Nazarene University",
        "hyphenated": "midamerica-nazarene-university"
    },
    {
        "id": "155566",
        "name": "Neosho County Community College",
        "hyphenated": "neosho-county-community-college"
    },
    {
        "id": "155593",
        "name": "North Central Kansas Technical College",
        "hyphenated": "north-central-kansas-technical-college"
    },
    {
        "id": "155618",
        "name": "Northwest Kansas Technical College",
        "hyphenated": "northwest-kansas-technical-college"
    },
    {
        "id": "155627",
        "name": "Ottawa University-Ottawa",
        "hyphenated": "ottawa-university-ottawa"
    },
    {
        "id": "155636",
        "name": "Ottawa University-Kansas City",
        "hyphenated": "ottawa-university-kansas-city"
    },
    {
        "id": "155681",
        "name": "Pittsburg State University",
        "hyphenated": "pittsburg-state-university"
    },
    {
        "id": "155715",
        "name": "Pratt Community College",
        "hyphenated": "pratt-community-college"
    },
    {
        "id": "155812",
        "name": "University of Saint Mary",
        "hyphenated": "university-of-saint-mary"
    },
    {
        "id": "155830",
        "name": "Salina Area Technical College",
        "hyphenated": "salina-area-technical-college"
    },
    {
        "id": "155858",
        "name": "Seward County Community College",
        "hyphenated": "seward-county-community-college"
    },
    {
        "id": "155900",
        "name": "Southwestern College",
        "hyphenated": "southwestern-college"
    },
    {
        "id": "155937",
        "name": "Sterling College",
        "hyphenated": "sterling-college"
    },
    {
        "id": "155973",
        "name": "Tabor College",
        "hyphenated": "tabor-college"
    },
    {
        "id": "156082",
        "name": "Washburn University",
        "hyphenated": "washburn-university"
    },
    {
        "id": "156107",
        "name": "Wichita State University-Campus of Applied Sciences and Technology",
        "hyphenated": "wichita-state-university-campus-of-applied-sciences-and-technology"
    },
    {
        "id": "156125",
        "name": "Wichita State University",
        "hyphenated": "wichita-state-university"
    },
    {
        "id": "156134",
        "name": "Wichita Technical Institute",
        "hyphenated": "wichita-technical-institute"
    },
    {
        "id": "156189",
        "name": "Alice Lloyd College",
        "hyphenated": "alice-lloyd-college"
    },
    {
        "id": "156213",
        "name": "Asbury University",
        "hyphenated": "asbury-university"
    },
    {
        "id": "156222",
        "name": "Asbury Theological Seminary",
        "hyphenated": "asbury-theological-seminary"
    },
    {
        "id": "156231",
        "name": "Ashland Community and Technical College",
        "hyphenated": "ashland-community-and-technical-college"
    },
    {
        "id": "156277",
        "name": "Barrett and Company School of Hair Design",
        "hyphenated": "barrett-and-company-school-of-hair-design"
    },
    {
        "id": "156286",
        "name": "Bellarmine University",
        "hyphenated": "bellarmine-university"
    },
    {
        "id": "156310",
        "name": "PJ's College of Cosmetology-Bowling Green",
        "hyphenated": "pj's-college-of-cosmetology-bowling-green"
    },
    {
        "id": "156338",
        "name": "Southcentral Kentucky Community and Technical College",
        "hyphenated": "southcentral-kentucky-community-and-technical-college"
    },
    {
        "id": "156356",
        "name": "Brescia University",
        "hyphenated": "brescia-university"
    },
    {
        "id": "156365",
        "name": "Campbellsville University",
        "hyphenated": "campbellsville-university"
    },
    {
        "id": "156392",
        "name": "Bluegrass Community and Technical College",
        "hyphenated": "bluegrass-community-and-technical-college"
    },
    {
        "id": "156408",
        "name": "Centre College",
        "hyphenated": "centre-college"
    },
    {
        "id": "156417",
        "name": "Clear Creek Baptist Bible College",
        "hyphenated": "clear-creek-baptist-bible-college"
    },
    {
        "id": "156426",
        "name": "Paul Mitchell the School-Lexington",
        "hyphenated": "paul-mitchell-the-school-lexington"
    },
    {
        "id": "156471",
        "name": "Galen College of Nursing-Louisville",
        "hyphenated": "galen-college-of-nursing-louisville"
    },
    {
        "id": "156541",
        "name": "University of the Cumberlands",
        "hyphenated": "university-of-the-cumberlands"
    },
    {
        "id": "156620",
        "name": "Eastern Kentucky University",
        "hyphenated": "eastern-kentucky-university"
    },
    {
        "id": "156648",
        "name": "Elizabethtown Community and Technical College",
        "hyphenated": "elizabethtown-community-and-technical-college"
    },
    {
        "id": "156727",
        "name": "Frontier Nursing University",
        "hyphenated": "frontier-nursing-university"
    },
    {
        "id": "156745",
        "name": "Georgetown College",
        "hyphenated": "georgetown-college"
    },
    {
        "id": "156754",
        "name": "PJ's College of Cosmetology-Glasgow",
        "hyphenated": "pj's-college-of-cosmetology-glasgow"
    },
    {
        "id": "156790",
        "name": "Hazard Community and Technical College",
        "hyphenated": "hazard-community-and-technical-college"
    },
    {
        "id": "156842",
        "name": "Paul Mitchell the School-Louisville",
        "hyphenated": "paul-mitchell-the-school-louisville"
    },
    {
        "id": "156851",
        "name": "Henderson Community College",
        "hyphenated": "henderson-community-college"
    },
    {
        "id": "156860",
        "name": "Hopkinsville Community College",
        "hyphenated": "hopkinsville-community-college"
    },
    {
        "id": "156921",
        "name": "Jefferson Community and Technical College",
        "hyphenated": "jefferson-community-and-technical-college"
    },
    {
        "id": "156967",
        "name": "Summit Salon Academy-Lexington",
        "hyphenated": "summit-salon-academy-lexington"
    },
    {
        "id": "157021",
        "name": "American National University-Pikeville",
        "hyphenated": "american-national-university-pikeville"
    },
    {
        "id": "157030",
        "name": "Kentucky Mountain Bible College",
        "hyphenated": "kentucky-mountain-bible-college"
    },
    {
        "id": "157058",
        "name": "Kentucky State University",
        "hyphenated": "kentucky-state-university"
    },
    {
        "id": "157076",
        "name": "Kentucky Wesleyan College",
        "hyphenated": "kentucky-wesleyan-college"
    },
    {
        "id": "157100",
        "name": "Kentucky Christian University",
        "hyphenated": "kentucky-christian-university"
    },
    {
        "id": "157207",
        "name": "Lexington Theological Seminary",
        "hyphenated": "lexington-theological-seminary"
    },
    {
        "id": "157216",
        "name": "Lindsey Wilson College",
        "hyphenated": "lindsey-wilson-college"
    },
    {
        "id": "157289",
        "name": "University of Louisville",
        "hyphenated": "university-of-louisville"
    },
    {
        "id": "157298",
        "name": "Louisville Presbyterian Theological Seminary",
        "hyphenated": "louisville-presbyterian-theological-seminary"
    },
    {
        "id": "157304",
        "name": "Madisonville Community College",
        "hyphenated": "madisonville-community-college"
    },
    {
        "id": "157331",
        "name": "Maysville Community and Technical College",
        "hyphenated": "maysville-community-and-technical-college"
    },
    {
        "id": "157377",
        "name": "Midway University",
        "hyphenated": "midway-university"
    },
    {
        "id": "157386",
        "name": "Morehead State University",
        "hyphenated": "morehead-state-university"
    },
    {
        "id": "157401",
        "name": "Murray State University",
        "hyphenated": "murray-state-university"
    },
    {
        "id": "157438",
        "name": "Gateway Community and Technical College",
        "hyphenated": "gateway-community-and-technical-college"
    },
    {
        "id": "157447",
        "name": "Northern Kentucky University",
        "hyphenated": "northern-kentucky-university"
    },
    {
        "id": "157483",
        "name": "West Kentucky Community and Technical College",
        "hyphenated": "west-kentucky-community-and-technical-college"
    },
    {
        "id": "157535",
        "name": "University of Pikeville",
        "hyphenated": "university-of-pikeville"
    },
    {
        "id": "157553",
        "name": "Big Sandy Community and Technical College",
        "hyphenated": "big-sandy-community-and-technical-college"
    },
    {
        "id": "157614",
        "name": "Empire Beauty School-Chenoweth",
        "hyphenated": "empire-beauty-school-chenoweth"
    },
    {
        "id": "157650",
        "name": "Empire Beauty School-Elizabethtown",
        "hyphenated": "empire-beauty-school-elizabethtown"
    },
    {
        "id": "157669",
        "name": "Empire Beauty School-Dixie",
        "hyphenated": "empire-beauty-school-dixie"
    },
    {
        "id": "157678",
        "name": "Empire Beauty School-Florence",
        "hyphenated": "empire-beauty-school-florence"
    },
    {
        "id": "157711",
        "name": "Somerset Community College",
        "hyphenated": "somerset-community-college"
    },
    {
        "id": "157739",
        "name": "Southeast Kentucky Community & Technical College",
        "hyphenated": "southeast-kentucky-community-&-technical-college"
    },
    {
        "id": "157748",
        "name": "The Southern Baptist Theological Seminary",
        "hyphenated": "the-southern-baptist-theological-seminary"
    },
    {
        "id": "157757",
        "name": "Spalding University",
        "hyphenated": "spalding-university"
    },
    {
        "id": "157793",
        "name": "Sullivan University",
        "hyphenated": "sullivan-university"
    },
    {
        "id": "157809",
        "name": "Thomas More University",
        "hyphenated": "thomas-more-university"
    },
    {
        "id": "157818",
        "name": "Transylvania University",
        "hyphenated": "transylvania-university"
    },
    {
        "id": "157863",
        "name": "Union College",
        "hyphenated": "union-college"
    },
    {
        "id": "157951",
        "name": "Western Kentucky University",
        "hyphenated": "western-kentucky-university"
    },
    {
        "id": "158088",
        "name": "Central Louisiana Technical Community College",
        "hyphenated": "central-louisiana-technical-community-college"
    },
    {
        "id": "158246",
        "name": "Ayers Career College",
        "hyphenated": "ayers-career-college"
    },
    {
        "id": "158325",
        "name": "Baton Rouge General Medical Center School of Nursing & School of Radiologic Technology",
        "hyphenated": "baton-rouge-general-medical-center-school-of-nursing-&-school-of-radiologic-technology"
    },
    {
        "id": "158343",
        "name": "Baton Rouge School of Computers",
        "hyphenated": "baton-rouge-school-of-computers"
    },
    {
        "id": "158431",
        "name": "Bossier Parish Community College",
        "hyphenated": "bossier-parish-community-college"
    },
    {
        "id": "158477",
        "name": "Centenary College of Louisiana",
        "hyphenated": "centenary-college-of-louisiana"
    },
    {
        "id": "158538",
        "name": "Cloyd's Beauty School 1 Inc",
        "hyphenated": "cloyd's-beauty-school-1-inc"
    },
    {
        "id": "158617",
        "name": "Crescent City Bartending School",
        "hyphenated": "crescent-city-bartending-school"
    },
    {
        "id": "158662",
        "name": "Delgado Community College",
        "hyphenated": "delgado-community-college"
    },
    {
        "id": "158778",
        "name": "Avery James College",
        "hyphenated": "avery-james-college"
    },
    {
        "id": "158787",
        "name": "Denham Springs Beauty School",
        "hyphenated": "denham-springs-beauty-school"
    },
    {
        "id": "158802",
        "name": "Dillard University",
        "hyphenated": "dillard-university"
    },
    {
        "id": "158884",
        "name": "Nunez Community College",
        "hyphenated": "nunez-community-college"
    },
    {
        "id": "159009",
        "name": "Grambling State University",
        "hyphenated": "grambling-state-university"
    },
    {
        "id": "159027",
        "name": "Guy's Shreveport Academy of Cosmetology Inc",
        "hyphenated": "guy's-shreveport-academy-of-cosmetology-inc"
    },
    {
        "id": "159197",
        "name": "ITI Technical College",
        "hyphenated": "iti-technical-college"
    },
    {
        "id": "159328",
        "name": "John Jay Beauty College",
        "hyphenated": "john-jay-beauty-college"
    },
    {
        "id": "159373",
        "name": "Louisiana State University Health Sciences Center-New Orleans",
        "hyphenated": "louisiana-state-university-health-sciences-center-new-orleans"
    },
    {
        "id": "159382",
        "name": "Louisiana State University-Alexandria",
        "hyphenated": "louisiana-state-university-alexandria"
    },
    {
        "id": "159391",
        "name": "Louisiana State University and Agricultural & Mechanical College",
        "hyphenated": "louisiana-state-university-and-agricultural-&-mechanical-college"
    },
    {
        "id": "159407",
        "name": "Louisiana State University-Eunice",
        "hyphenated": "louisiana-state-university-eunice"
    },
    {
        "id": "159416",
        "name": "Louisiana State University-Shreveport",
        "hyphenated": "louisiana-state-university-shreveport"
    },
    {
        "id": "159513",
        "name": "Aveda Arts & Sciences Institute-Baton Rouge",
        "hyphenated": "aveda-arts-&-sciences-institute-baton-rouge"
    },
    {
        "id": "159522",
        "name": "Aveda Arts & Sciences Institute-Lafayette",
        "hyphenated": "aveda-arts-&-sciences-institute-lafayette"
    },
    {
        "id": "159531",
        "name": "Louisiana Academy of Beauty",
        "hyphenated": "louisiana-academy-of-beauty"
    },
    {
        "id": "159568",
        "name": "Louisiana Christian University",
        "hyphenated": "louisiana-christian-university"
    },
    {
        "id": "159601",
        "name": "Opelousas School of Cosmetology",
        "hyphenated": "opelousas-school-of-cosmetology"
    },
    {
        "id": "159647",
        "name": "Louisiana Tech University",
        "hyphenated": "louisiana-tech-university"
    },
    {
        "id": "159656",
        "name": "Loyola University New Orleans",
        "hyphenated": "loyola-university-new-orleans"
    },
    {
        "id": "159717",
        "name": "McNeese State University",
        "hyphenated": "mcneese-state-university"
    },
    {
        "id": "159939",
        "name": "University of New Orleans",
        "hyphenated": "university-of-new-orleans"
    },
    {
        "id": "159948",
        "name": "New Orleans Baptist Theological Seminary",
        "hyphenated": "new-orleans-baptist-theological-seminary"
    },
    {
        "id": "159966",
        "name": "Nicholls State University",
        "hyphenated": "nicholls-state-university"
    },
    {
        "id": "159993",
        "name": "University of Louisiana at Monroe",
        "hyphenated": "university-of-louisiana-at-monroe"
    },
    {
        "id": "160010",
        "name": "Northwest Louisiana Technical Community College",
        "hyphenated": "northwest-louisiana-technical-community-college"
    },
    {
        "id": "160038",
        "name": "Northwestern State University of Louisiana",
        "hyphenated": "northwestern-state-university-of-louisiana"
    },
    {
        "id": "160065",
        "name": "University of Holy Cross",
        "hyphenated": "university-of-holy-cross"
    },
    {
        "id": "160074",
        "name": "Franciscan Missionaries of Our Lady University",
        "hyphenated": "franciscan-missionaries-of-our-lady-university"
    },
    {
        "id": "160117",
        "name": "Pat Goins Benton Road Beauty School",
        "hyphenated": "pat-goins-benton-road-beauty-school"
    },
    {
        "id": "160126",
        "name": "Celebrity Stylist Beauty School",
        "hyphenated": "celebrity-stylist-beauty-school"
    },
    {
        "id": "160199",
        "name": "Pineville Beauty School",
        "hyphenated": "pineville-beauty-school"
    },
    {
        "id": "160320",
        "name": "Aveda Arts & Sciences Institute-Covington",
        "hyphenated": "aveda-arts-&-sciences-institute-covington"
    },
    {
        "id": "160409",
        "name": "Saint Joseph Seminary College",
        "hyphenated": "saint-joseph-seminary-college"
    },
    {
        "id": "160472",
        "name": "LaSalle Tech",
        "hyphenated": "lasalle-tech"
    },
    {
        "id": "160481",
        "name": "Fletcher Technical Community College",
        "hyphenated": "fletcher-technical-community-college"
    },
    {
        "id": "160524",
        "name": "Remington College-Lafayette Campus",
        "hyphenated": "remington-college-lafayette-campus"
    },
    {
        "id": "160579",
        "name": "SOWELA Technical Community College",
        "hyphenated": "sowela-technical-community-college"
    },
    {
        "id": "160597",
        "name": "Stevensons Academy of Hair Design",
        "hyphenated": "stevensons-academy-of-hair-design"
    },
    {
        "id": "160612",
        "name": "Southeastern Louisiana University",
        "hyphenated": "southeastern-louisiana-university"
    },
    {
        "id": "160621",
        "name": "Southern University and A & M College",
        "hyphenated": "southern-university-and-a-&-m-college"
    },
    {
        "id": "160630",
        "name": "Southern University at New Orleans",
        "hyphenated": "southern-university-at-new-orleans"
    },
    {
        "id": "160649",
        "name": "Southern University at Shreveport",
        "hyphenated": "southern-university-at-shreveport"
    },
    {
        "id": "160658",
        "name": "University of Louisiana at Lafayette",
        "hyphenated": "university-of-louisiana-at-lafayette"
    },
    {
        "id": "160667",
        "name": "Northshore Technical Community College",
        "hyphenated": "northshore-technical-community-college"
    },
    {
        "id": "160904",
        "name": "Xavier University of Louisiana",
        "hyphenated": "xavier-university-of-louisiana"
    },
    {
        "id": "160959",
        "name": "College of the Atlantic",
        "hyphenated": "college-of-the-atlantic"
    },
    {
        "id": "160995",
        "name": "Beal University",
        "hyphenated": "beal-university"
    },
    {
        "id": "161022",
        "name": "Maine College of Health Professions",
        "hyphenated": "maine-college-of-health-professions"
    },
    {
        "id": "161077",
        "name": "Central Maine Community College",
        "hyphenated": "central-maine-community-college"
    },
    {
        "id": "161138",
        "name": "Eastern Maine Community College",
        "hyphenated": "eastern-maine-community-college"
    },
    {
        "id": "161192",
        "name": "Kennebec Valley Community College",
        "hyphenated": "kennebec-valley-community-college"
    },
    {
        "id": "161208",
        "name": "The Landing School",
        "hyphenated": "the-landing-school"
    },
    {
        "id": "161217",
        "name": "University of Maine at Augusta",
        "hyphenated": "university-of-maine-at-augusta"
    },
    {
        "id": "161226",
        "name": "University of Maine at Farmington",
        "hyphenated": "university-of-maine-at-farmington"
    },
    {
        "id": "161235",
        "name": "University of Maine at Fort Kent",
        "hyphenated": "university-of-maine-at-fort-kent"
    },
    {
        "id": "161253",
        "name": "University of Maine",
        "hyphenated": "university-of-maine"
    },
    {
        "id": "161299",
        "name": "Maine Maritime Academy",
        "hyphenated": "maine-maritime-academy"
    },
    {
        "id": "161341",
        "name": "University of Maine at Presque Isle",
        "hyphenated": "university-of-maine-at-presque-isle"
    },
    {
        "id": "161457",
        "name": "University of New England",
        "hyphenated": "university-of-new-england"
    },
    {
        "id": "161484",
        "name": "Northern Maine Community College",
        "hyphenated": "northern-maine-community-college"
    },
    {
        "id": "161493",
        "name": "Empire Beauty School-Maine",
        "hyphenated": "empire-beauty-school-maine"
    },
    {
        "id": "161509",
        "name": "Maine College of Art & Design",
        "hyphenated": "maine-college-of-art-&-design"
    },
    {
        "id": "161518",
        "name": "Saint Joseph's College of Maine",
        "hyphenated": "saint-joseph's-college-of-maine"
    },
    {
        "id": "161527",
        "name": "Spa Tech Institute-Westbrook",
        "hyphenated": "spa-tech-institute-westbrook"
    },
    {
        "id": "161545",
        "name": "Southern Maine Community College",
        "hyphenated": "southern-maine-community-college"
    },
    {
        "id": "161554",
        "name": "University of Southern Maine",
        "hyphenated": "university-of-southern-maine"
    },
    {
        "id": "161563",
        "name": "Thomas College",
        "hyphenated": "thomas-college"
    },
    {
        "id": "161572",
        "name": "Unity College",
        "hyphenated": "unity-college"
    },
    {
        "id": "161581",
        "name": "Washington County Community College",
        "hyphenated": "washington-county-community-college"
    },
    {
        "id": "161688",
        "name": "Allegany College of Maryland",
        "hyphenated": "allegany-college-of-maryland"
    },
    {
        "id": "161767",
        "name": "Anne Arundel Community College",
        "hyphenated": "anne-arundel-community-college"
    },
    {
        "id": "161800",
        "name": "Award Beauty School",
        "hyphenated": "award-beauty-school"
    },
    {
        "id": "161855",
        "name": "Baltimore Studio of Hair Design",
        "hyphenated": "baltimore-studio-of-hair-design"
    },
    {
        "id": "161864",
        "name": "Baltimore City Community College",
        "hyphenated": "baltimore-city-community-college"
    },
    {
        "id": "161873",
        "name": "University of Baltimore",
        "hyphenated": "university-of-baltimore"
    },
    {
        "id": "162007",
        "name": "Bowie State University",
        "hyphenated": "bowie-state-university"
    },
    {
        "id": "162061",
        "name": "Capitol Technology University",
        "hyphenated": "capitol-technology-university"
    },
    {
        "id": "162104",
        "name": "Cecil College",
        "hyphenated": "cecil-college"
    },
    {
        "id": "162122",
        "name": "College of Southern Maryland",
        "hyphenated": "college-of-southern-maryland"
    },
    {
        "id": "162168",
        "name": "Chesapeake College",
        "hyphenated": "chesapeake-college"
    },
    {
        "id": "162210",
        "name": "Washington Adventist University",
        "hyphenated": "washington-adventist-university"
    },
    {
        "id": "162283",
        "name": "Coppin State University",
        "hyphenated": "coppin-state-university"
    },
    {
        "id": "162335",
        "name": "Del-Mar-Va Beauty Academy",
        "hyphenated": "del-mar-va-beauty-academy"
    },
    {
        "id": "162371",
        "name": "North American Trade Schools",
        "hyphenated": "north-american-trade-schools"
    },
    {
        "id": "162557",
        "name": "Frederick Community College",
        "hyphenated": "frederick-community-college"
    },
    {
        "id": "162584",
        "name": "Frostburg State University",
        "hyphenated": "frostburg-state-university"
    },
    {
        "id": "162609",
        "name": "Garrett College",
        "hyphenated": "garrett-college"
    },
    {
        "id": "162654",
        "name": "Goucher College",
        "hyphenated": "goucher-college"
    },
    {
        "id": "162690",
        "name": "Hagerstown Community College",
        "hyphenated": "hagerstown-community-college"
    },
    {
        "id": "162706",
        "name": "Harford Community College",
        "hyphenated": "harford-community-college"
    },
    {
        "id": "162760",
        "name": "Hood College",
        "hyphenated": "hood-college"
    },
    {
        "id": "162779",
        "name": "Howard Community College",
        "hyphenated": "howard-community-college"
    },
    {
        "id": "162830",
        "name": "Aveda Institute-Maryland",
        "hyphenated": "aveda-institute-maryland"
    },
    {
        "id": "163028",
        "name": "Lincoln College of Technology-Columbia",
        "hyphenated": "lincoln-college-of-technology-columbia"
    },
    {
        "id": "163046",
        "name": "Loyola University Maryland",
        "hyphenated": "loyola-university-maryland"
    },
    {
        "id": "163204",
        "name": "University of Maryland Global Campus",
        "hyphenated": "university-of-maryland-global-campus"
    },
    {
        "id": "163259",
        "name": "University of Maryland Baltimore",
        "hyphenated": "university-of-maryland-baltimore"
    },
    {
        "id": "163295",
        "name": "Maryland Institute College of Art",
        "hyphenated": "maryland-institute-college-of-art"
    },
    {
        "id": "163338",
        "name": "University of Maryland Eastern Shore",
        "hyphenated": "university-of-maryland-eastern-shore"
    },
    {
        "id": "163347",
        "name": "Fortis Institute-Towson",
        "hyphenated": "fortis-institute-towson"
    },
    {
        "id": "163408",
        "name": "Montgomery Beauty School",
        "hyphenated": "montgomery-beauty-school"
    },
    {
        "id": "163426",
        "name": "Montgomery College",
        "hyphenated": "montgomery-college"
    },
    {
        "id": "163453",
        "name": "Morgan State University",
        "hyphenated": "morgan-state-university"
    },
    {
        "id": "163462",
        "name": "Mount St. Mary's University",
        "hyphenated": "mount-st.-mary's-university"
    },
    {
        "id": "163532",
        "name": "Ner Israel Rabbinical College",
        "hyphenated": "ner-israel-rabbinical-college"
    },
    {
        "id": "163541",
        "name": "Hair Academy",
        "hyphenated": "hair-academy"
    },
    {
        "id": "163578",
        "name": "Notre Dame of Maryland University",
        "hyphenated": "notre-dame-of-maryland-university"
    },
    {
        "id": "163657",
        "name": "Prince George's Community College",
        "hyphenated": "prince-george's-community-college"
    },
    {
        "id": "163815",
        "name": "Maryland Beauty Academy of Essex",
        "hyphenated": "maryland-beauty-academy-of-essex"
    },
    {
        "id": "163851",
        "name": "Salisbury University",
        "hyphenated": "salisbury-university"
    },
    {
        "id": "163912",
        "name": "St. Mary's College of Maryland",
        "hyphenated": "st.-mary's-college-of-maryland"
    },
    {
        "id": "163976",
        "name": "St. John's College",
        "hyphenated": "st.-john's-college"
    },
    {
        "id": "164076",
        "name": "Towson University",
        "hyphenated": "towson-university"
    },
    {
        "id": "164085",
        "name": "Maryland University of Integrative Health",
        "hyphenated": "maryland-university-of-integrative-health"
    },
    {
        "id": "164173",
        "name": "Stevenson University",
        "hyphenated": "stevenson-university"
    },
    {
        "id": "164216",
        "name": "Washington College",
        "hyphenated": "washington-college"
    },
    {
        "id": "164270",
        "name": "McDaniel College",
        "hyphenated": "mcdaniel-college"
    },
    {
        "id": "164313",
        "name": "Wor-Wic Community College",
        "hyphenated": "wor-wic-community-college"
    },
    {
        "id": "164368",
        "name": "Hult International Business School",
        "hyphenated": "hult-international-business-school"
    },
    {
        "id": "164447",
        "name": "American International College",
        "hyphenated": "american-international-college"
    },
    {
        "id": "164492",
        "name": "Anna Maria College",
        "hyphenated": "anna-maria-college"
    },
    {
        "id": "164535",
        "name": "Assabet Valley Regional Technical School",
        "hyphenated": "assabet-valley-regional-technical-school"
    },
    {
        "id": "164562",
        "name": "Assumption University",
        "hyphenated": "assumption-university"
    },
    {
        "id": "164580",
        "name": "Babson College",
        "hyphenated": "babson-college"
    },
    {
        "id": "164599",
        "name": "Bancroft School of Massage Therapy",
        "hyphenated": "bancroft-school-of-massage-therapy"
    },
    {
        "id": "164614",
        "name": "Boston Baptist College",
        "hyphenated": "boston-baptist-college"
    },
    {
        "id": "164632",
        "name": "Bay Path University",
        "hyphenated": "bay-path-university"
    },
    {
        "id": "164641",
        "name": "Bay State College",
        "hyphenated": "bay-state-college"
    },
    {
        "id": "164739",
        "name": "Bentley University",
        "hyphenated": "bentley-university"
    },
    {
        "id": "164748",
        "name": "Berklee College of Music",
        "hyphenated": "berklee-college-of-music"
    },
    {
        "id": "164775",
        "name": "Berkshire Community College",
        "hyphenated": "berkshire-community-college"
    },
    {
        "id": "164845",
        "name": "Empire Beauty School-Boston",
        "hyphenated": "empire-beauty-school-boston"
    },
    {
        "id": "164872",
        "name": "Boston Architectural College",
        "hyphenated": "boston-architectural-college"
    },
    {
        "id": "164915",
        "name": "Boston Graduate School of Psychoanalysis Inc",
        "hyphenated": "boston-graduate-school-of-psychoanalysis-inc"
    },
    {
        "id": "165024",
        "name": "Bridgewater State University",
        "hyphenated": "bridgewater-state-university"
    },
    {
        "id": "165033",
        "name": "Bristol Community College",
        "hyphenated": "bristol-community-college"
    },
    {
        "id": "165060",
        "name": "Signature Healthcare Brockton Hospital School of Nursing",
        "hyphenated": "signature-healthcare-brockton-hospital-school-of-nursing"
    },
    {
        "id": "165112",
        "name": "Bunker Hill Community College",
        "hyphenated": "bunker-hill-community-college"
    },
    {
        "id": "165167",
        "name": "Cambridge College",
        "hyphenated": "cambridge-college"
    },
    {
        "id": "165194",
        "name": "Cape Cod Community College",
        "hyphenated": "cape-cod-community-college"
    },
    {
        "id": "165255",
        "name": "Catherine Hinds Institute of Esthetics",
        "hyphenated": "catherine-hinds-institute-of-esthetics"
    },
    {
        "id": "165264",
        "name": "Laboure College of Healthcare",
        "hyphenated": "laboure-college-of-healthcare"
    },
    {
        "id": "165495",
        "name": "Conway School of Landscape Design",
        "hyphenated": "conway-school-of-landscape-design"
    },
    {
        "id": "165529",
        "name": "Curry College",
        "hyphenated": "curry-college"
    },
    {
        "id": "165556",
        "name": "Lincoln Technical Institute-Somerville",
        "hyphenated": "lincoln-technical-institute-somerville"
    },
    {
        "id": "165574",
        "name": "Dean College",
        "hyphenated": "dean-college"
    },
    {
        "id": "165608",
        "name": "Diman Regional Technical Institute",
        "hyphenated": "diman-regional-technical-institute"
    },
    {
        "id": "165635",
        "name": "National Aviation Academy of New England",
        "hyphenated": "national-aviation-academy-of-new-england"
    },
    {
        "id": "165644",
        "name": "Eastern Nazarene College",
        "hyphenated": "eastern-nazarene-college"
    },
    {
        "id": "165662",
        "name": "Emerson College",
        "hyphenated": "emerson-college"
    },
    {
        "id": "165671",
        "name": "Emmanuel College",
        "hyphenated": "emmanuel-college"
    },
    {
        "id": "165699",
        "name": "Endicott College",
        "hyphenated": "endicott-college"
    },
    {
        "id": "165750",
        "name": "Rob Roy Academy-Fall River",
        "hyphenated": "rob-roy-academy-fall-river"
    },
    {
        "id": "165802",
        "name": "Fisher College",
        "hyphenated": "fisher-college"
    },
    {
        "id": "165820",
        "name": "Fitchburg State University",
        "hyphenated": "fitchburg-state-university"
    },
    {
        "id": "165866",
        "name": "Framingham State University",
        "hyphenated": "framingham-state-university"
    },
    {
        "id": "165884",
        "name": "Benjamin Franklin Cummings Institute of Technology",
        "hyphenated": "benjamin-franklin-cummings-institute-of-technology"
    },
    {
        "id": "165936",
        "name": "Gordon College",
        "hyphenated": "gordon-college"
    },
    {
        "id": "165945",
        "name": "Gordon-Conwell Theological Seminary",
        "hyphenated": "gordon-conwell-theological-seminary"
    },
    {
        "id": "165981",
        "name": "Greenfield Community College",
        "hyphenated": "greenfield-community-college"
    },
    {
        "id": "166018",
        "name": "Hampshire College",
        "hyphenated": "hampshire-college"
    },
    {
        "id": "166045",
        "name": "Hebrew College",
        "hyphenated": "hebrew-college"
    },
    {
        "id": "166054",
        "name": "Hellenic College-Holy Cross Greek Orthodox School of Theology",
        "hyphenated": "hellenic-college-holy-cross-greek-orthodox-school-of-theology"
    },
    {
        "id": "166124",
        "name": "College of the Holy Cross",
        "hyphenated": "college-of-the-holy-cross"
    },
    {
        "id": "166133",
        "name": "Holyoke Community College",
        "hyphenated": "holyoke-community-college"
    },
    {
        "id": "166391",
        "name": "Lasell University",
        "hyphenated": "lasell-university"
    },
    {
        "id": "166407",
        "name": "Lawrence Memorial Hospital School of Nursing",
        "hyphenated": "lawrence-memorial-hospital-school-of-nursing"
    },
    {
        "id": "166452",
        "name": "Lesley University",
        "hyphenated": "lesley-university"
    },
    {
        "id": "166489",
        "name": "Longy School of Music of Bard College",
        "hyphenated": "longy-school-of-music-of-bard-college"
    },
    {
        "id": "166498",
        "name": "Lowell Academy Hairstyling Institute",
        "hyphenated": "lowell-academy-hairstyling-institute"
    },
    {
        "id": "166513",
        "name": "University of Massachusetts-Lowell",
        "hyphenated": "university-of-massachusetts-lowell"
    },
    {
        "id": "166638",
        "name": "University of Massachusetts-Boston",
        "hyphenated": "university-of-massachusetts-boston"
    },
    {
        "id": "166647",
        "name": "Massachusetts Bay Community College",
        "hyphenated": "massachusetts-bay-community-college"
    },
    {
        "id": "166656",
        "name": "MCPHS University",
        "hyphenated": "mcphs-university"
    },
    {
        "id": "166674",
        "name": "Massachusetts College of Art and Design",
        "hyphenated": "massachusetts-college-of-art-and-design"
    },
    {
        "id": "166692",
        "name": "Massachusetts Maritime Academy",
        "hyphenated": "massachusetts-maritime-academy"
    },
    {
        "id": "166708",
        "name": "University of Massachusetts Chan Medical School",
        "hyphenated": "university-of-massachusetts-chan-medical-school"
    },
    {
        "id": "166717",
        "name": "William James College",
        "hyphenated": "william-james-college"
    },
    {
        "id": "166805",
        "name": "Massachusetts School of Barbering",
        "hyphenated": "massachusetts-school-of-barbering"
    },
    {
        "id": "166823",
        "name": "Massasoit Community College",
        "hyphenated": "massasoit-community-college"
    },
    {
        "id": "166850",
        "name": "Merrimack College",
        "hyphenated": "merrimack-college"
    },
    {
        "id": "166869",
        "name": "MGH Institute of Health Professions",
        "hyphenated": "mgh-institute-of-health-professions"
    },
    {
        "id": "166887",
        "name": "Middlesex Community College",
        "hyphenated": "middlesex-community-college"
    },
    {
        "id": "166911",
        "name": "Montserrat College of Art",
        "hyphenated": "montserrat-college-of-art"
    },
    {
        "id": "166939",
        "name": "Mount Holyoke College",
        "hyphenated": "mount-holyoke-college"
    },
    {
        "id": "166957",
        "name": "Mount Wachusett Community College",
        "hyphenated": "mount-wachusett-community-college"
    },
    {
        "id": "167039",
        "name": "Rob Roy Academy-New Bedford",
        "hyphenated": "rob-roy-academy-new-bedford"
    },
    {
        "id": "167057",
        "name": "The New England Conservatory of Music",
        "hyphenated": "the-new-england-conservatory-of-music"
    },
    {
        "id": "167093",
        "name": "New England College of Optometry",
        "hyphenated": "new-england-college-of-optometry"
    },
    {
        "id": "167215",
        "name": "New England Law-Boston",
        "hyphenated": "new-england-law-boston"
    },
    {
        "id": "167260",
        "name": "Nichols College",
        "hyphenated": "nichols-college"
    },
    {
        "id": "167288",
        "name": "Massachusetts College of Liberal Arts",
        "hyphenated": "massachusetts-college-of-liberal-arts"
    },
    {
        "id": "167297",
        "name": "North Bennet Street School",
        "hyphenated": "north-bennet-street-school"
    },
    {
        "id": "167312",
        "name": "North Shore Community College",
        "hyphenated": "north-shore-community-college"
    },
    {
        "id": "167376",
        "name": "Northern Essex Community College",
        "hyphenated": "northern-essex-community-college"
    },
    {
        "id": "167394",
        "name": "College of Our Lady of the Elms",
        "hyphenated": "college-of-our-lady-of-the-elms"
    },
    {
        "id": "167455",
        "name": "Pine Manor College",
        "hyphenated": "pine-manor-college"
    },
    {
        "id": "167464",
        "name": "Pope St John XXIII National Seminary",
        "hyphenated": "pope-st-john-xxiii-national-seminary"
    },
    {
        "id": "167525",
        "name": "Quincy College",
        "hyphenated": "quincy-college"
    },
    {
        "id": "167534",
        "name": "Quinsigamond Community College",
        "hyphenated": "quinsigamond-community-college"
    },
    {
        "id": "167598",
        "name": "Regis College",
        "hyphenated": "regis-college"
    },
    {
        "id": "167631",
        "name": "Roxbury Community College",
        "hyphenated": "roxbury-community-college"
    },
    {
        "id": "167677",
        "name": "Saint John's Seminary",
        "hyphenated": "saint-john's-seminary"
    },
    {
        "id": "167729",
        "name": "Salem State University",
        "hyphenated": "salem-state-university"
    },
    {
        "id": "167783",
        "name": "Simmons University",
        "hyphenated": "simmons-university"
    },
    {
        "id": "167792",
        "name": "Bard College at Simon's Rock",
        "hyphenated": "bard-college-at-simon's-rock"
    },
    {
        "id": "167871",
        "name": "Southeastern Technical Institute",
        "hyphenated": "southeastern-technical-institute"
    },
    {
        "id": "167899",
        "name": "Springfield College",
        "hyphenated": "springfield-college"
    },
    {
        "id": "167905",
        "name": "Springfield Technical Community College",
        "hyphenated": "springfield-technical-community-college"
    },
    {
        "id": "167987",
        "name": "University of Massachusetts-Dartmouth",
        "hyphenated": "university-of-massachusetts-dartmouth"
    },
    {
        "id": "167996",
        "name": "Stonehill College",
        "hyphenated": "stonehill-college"
    },
    {
        "id": "168005",
        "name": "Suffolk University",
        "hyphenated": "suffolk-university"
    },
    {
        "id": "168032",
        "name": "Rob Roy Academy-Taunton",
        "hyphenated": "rob-roy-academy-taunton"
    },
    {
        "id": "168227",
        "name": "Wentworth Institute of Technology",
        "hyphenated": "wentworth-institute-of-technology"
    },
    {
        "id": "168254",
        "name": "Western New England University",
        "hyphenated": "western-new-england-university"
    },
    {
        "id": "168263",
        "name": "Westfield State University",
        "hyphenated": "westfield-state-university"
    },
    {
        "id": "168281",
        "name": "Wheaton College (Massachusetts)",
        "hyphenated": "wheaton-college-(massachusetts)"
    },
    {
        "id": "168430",
        "name": "Worcester State University",
        "hyphenated": "worcester-state-university"
    },
    {
        "id": "168528",
        "name": "Adrian College",
        "hyphenated": "adrian-college"
    },
    {
        "id": "168546",
        "name": "Albion College",
        "hyphenated": "albion-college"
    },
    {
        "id": "168555",
        "name": "Hillsdale Beauty College",
        "hyphenated": "hillsdale-beauty-college"
    },
    {
        "id": "168573",
        "name": "Northwestern Technological Institute",
        "hyphenated": "northwestern-technological-institute"
    },
    {
        "id": "168591",
        "name": "Alma College",
        "hyphenated": "alma-college"
    },
    {
        "id": "168607",
        "name": "Alpena Community College",
        "hyphenated": "alpena-community-college"
    },
    {
        "id": "168740",
        "name": "Andrews University",
        "hyphenated": "andrews-university"
    },
    {
        "id": "168786",
        "name": "Aquinas College",
        "hyphenated": "aquinas-college"
    },
    {
        "id": "168847",
        "name": "Baker College",
        "hyphenated": "baker-college"
    },
    {
        "id": "168883",
        "name": "Bay de Noc Community College",
        "hyphenated": "bay-de-noc-community-college"
    },
    {
        "id": "168892",
        "name": "Bayshire Academy of Beauty Craft Inc",
        "hyphenated": "bayshire-academy-of-beauty-craft-inc"
    },
    {
        "id": "169080",
        "name": "Calvin University",
        "hyphenated": "calvin-university"
    },
    {
        "id": "169099",
        "name": "Calvin Theological Seminary",
        "hyphenated": "calvin-theological-seminary"
    },
    {
        "id": "169187",
        "name": "Carnegie Institute",
        "hyphenated": "carnegie-institute"
    },
    {
        "id": "169220",
        "name": "Michigan School of Psychology",
        "hyphenated": "michigan-school-of-psychology"
    },
    {
        "id": "169248",
        "name": "Central Michigan University",
        "hyphenated": "central-michigan-university"
    },
    {
        "id": "169275",
        "name": "Mott Community College",
        "hyphenated": "mott-community-college"
    },
    {
        "id": "169284",
        "name": "Empire Beauty School-Michigan",
        "hyphenated": "empire-beauty-school-michigan"
    },
    {
        "id": "169327",
        "name": "Cleary University",
        "hyphenated": "cleary-university"
    },
    {
        "id": "169363",
        "name": "Concordia University Ann Arbor",
        "hyphenated": "concordia-university-ann-arbor"
    },
    {
        "id": "169424",
        "name": "Cranbrook Academy of Art",
        "hyphenated": "cranbrook-academy-of-art"
    },
    {
        "id": "169442",
        "name": "College for Creative Studies",
        "hyphenated": "college-for-creative-studies"
    },
    {
        "id": "169479",
        "name": "Davenport University",
        "hyphenated": "davenport-university"
    },
    {
        "id": "169503",
        "name": "David Pressley School of Cosmetology",
        "hyphenated": "david-pressley-school-of-cosmetology"
    },
    {
        "id": "169521",
        "name": "Delta College",
        "hyphenated": "delta-college"
    },
    {
        "id": "169576",
        "name": "Detroit Business Institute-Downriver",
        "hyphenated": "detroit-business-institute-downriver"
    },
    {
        "id": "169655",
        "name": "MIAT College of Technology",
        "hyphenated": "miat-college-of-technology"
    },
    {
        "id": "169716",
        "name": "University of Detroit Mercy",
        "hyphenated": "university-of-detroit-mercy"
    },
    {
        "id": "169761",
        "name": "Dorsey College",
        "hyphenated": "dorsey-college"
    },
    {
        "id": "169798",
        "name": "Eastern Michigan University",
        "hyphenated": "eastern-michigan-university"
    },
    {
        "id": "169813",
        "name": "Creative Hair School of Cosmetology",
        "hyphenated": "creative-hair-school-of-cosmetology"
    },
    {
        "id": "169910",
        "name": "Ferris State University",
        "hyphenated": "ferris-state-university"
    },
    {
        "id": "169974",
        "name": "Glen Oaks Community College",
        "hyphenated": "glen-oaks-community-college"
    },
    {
        "id": "169983",
        "name": "Kettering University",
        "hyphenated": "kettering-university"
    },
    {
        "id": "169992",
        "name": "Gogebic Community College",
        "hyphenated": "gogebic-community-college"
    },
    {
        "id": "170000",
        "name": "Grace Christian University",
        "hyphenated": "grace-christian-university"
    },
    {
        "id": "170037",
        "name": "Cornerstone University",
        "hyphenated": "cornerstone-university"
    },
    {
        "id": "170055",
        "name": "Grand Rapids Community College",
        "hyphenated": "grand-rapids-community-college"
    },
    {
        "id": "170082",
        "name": "Grand Valley State University",
        "hyphenated": "grand-valley-state-university"
    },
    {
        "id": "170091",
        "name": "Great Lakes Christian College",
        "hyphenated": "great-lakes-christian-college"
    },
    {
        "id": "170240",
        "name": "Henry Ford College",
        "hyphenated": "henry-ford-college"
    },
    {
        "id": "170286",
        "name": "Hillsdale College",
        "hyphenated": "hillsdale-college"
    },
    {
        "id": "170301",
        "name": "Hope College",
        "hyphenated": "hope-college"
    },
    {
        "id": "170444",
        "name": "Jackson College",
        "hyphenated": "jackson-college"
    },
    {
        "id": "170532",
        "name": "Kalamazoo College",
        "hyphenated": "kalamazoo-college"
    },
    {
        "id": "170541",
        "name": "Kalamazoo Valley Community College",
        "hyphenated": "kalamazoo-valley-community-college"
    },
    {
        "id": "170550",
        "name": "Kellogg Community College",
        "hyphenated": "kellogg-community-college"
    },
    {
        "id": "170587",
        "name": "Kirtland Community College",
        "hyphenated": "kirtland-community-college"
    },
    {
        "id": "170611",
        "name": "Michigan College of Beauty-Monroe",
        "hyphenated": "michigan-college-of-beauty-monroe"
    },
    {
        "id": "170620",
        "name": "Lake Michigan College",
        "hyphenated": "lake-michigan-college"
    },
    {
        "id": "170639",
        "name": "Lake Superior State University",
        "hyphenated": "lake-superior-state-university"
    },
    {
        "id": "170657",
        "name": "Lansing Community College",
        "hyphenated": "lansing-community-college"
    },
    {
        "id": "170675",
        "name": "Lawrence Technological University",
        "hyphenated": "lawrence-technological-university"
    },
    {
        "id": "170736",
        "name": "M J Murphy Beauty College of Mount Pleasant",
        "hyphenated": "m-j-murphy-beauty-college-of-mount-pleasant"
    },
    {
        "id": "170790",
        "name": "Macomb Community College",
        "hyphenated": "macomb-community-college"
    },
    {
        "id": "170806",
        "name": "Madonna University",
        "hyphenated": "madonna-university"
    },
    {
        "id": "170967",
        "name": "Rochester University",
        "hyphenated": "rochester-university"
    },
    {
        "id": "171012",
        "name": "The Michigan Barber School",
        "hyphenated": "the-michigan-barber-school"
    },
    {
        "id": "171128",
        "name": "Michigan Technological University",
        "hyphenated": "michigan-technological-university"
    },
    {
        "id": "171137",
        "name": "University of Michigan-Dearborn",
        "hyphenated": "university-of-michigan-dearborn"
    },
    {
        "id": "171146",
        "name": "University of Michigan-Flint",
        "hyphenated": "university-of-michigan-flint"
    },
    {
        "id": "171155",
        "name": "Mid Michigan College",
        "hyphenated": "mid-michigan-college"
    },
    {
        "id": "171225",
        "name": "Monroe County Community College",
        "hyphenated": "monroe-county-community-college"
    },
    {
        "id": "171234",
        "name": "Montcalm Community College",
        "hyphenated": "montcalm-community-college"
    },
    {
        "id": "171304",
        "name": "Muskegon Community College",
        "hyphenated": "muskegon-community-college"
    },
    {
        "id": "171395",
        "name": "North Central Michigan College",
        "hyphenated": "north-central-michigan-college"
    },
    {
        "id": "171456",
        "name": "Northern Michigan University",
        "hyphenated": "northern-michigan-university"
    },
    {
        "id": "171483",
        "name": "Northwestern Michigan College",
        "hyphenated": "northwestern-michigan-college"
    },
    {
        "id": "171492",
        "name": "Northwood University",
        "hyphenated": "northwood-university"
    },
    {
        "id": "171535",
        "name": "Oakland Community College",
        "hyphenated": "oakland-community-college"
    },
    {
        "id": "171571",
        "name": "Oakland University",
        "hyphenated": "oakland-university"
    },
    {
        "id": "171599",
        "name": "Olivet College",
        "hyphenated": "olivet-college"
    },
    {
        "id": "171775",
        "name": "Port Huron Cosmetology College",
        "hyphenated": "port-huron-cosmetology-college"
    },
    {
        "id": "171881",
        "name": "Kuyper College",
        "hyphenated": "kuyper-college"
    },
    {
        "id": "171988",
        "name": "Ross Medical Education Center-Lansing",
        "hyphenated": "ross-medical-education-center-lansing"
    },
    {
        "id": "171997",
        "name": "Ross Medical Education Center-Warren",
        "hyphenated": "ross-medical-education-center-warren"
    },
    {
        "id": "172015",
        "name": "Ross Medical Education Center-Flint",
        "hyphenated": "ross-medical-education-center-flint"
    },
    {
        "id": "172033",
        "name": "Sacred Heart Major Seminary",
        "hyphenated": "sacred-heart-major-seminary"
    },
    {
        "id": "172051",
        "name": "Saginaw Valley State University",
        "hyphenated": "saginaw-valley-state-university"
    },
    {
        "id": "172200",
        "name": "Schoolcraft Community College District",
        "hyphenated": "schoolcraft-community-college-district"
    },
    {
        "id": "172264",
        "name": "Siena Heights University",
        "hyphenated": "siena-heights-university"
    },
    {
        "id": "172291",
        "name": "St Clair County Community College",
        "hyphenated": "st-clair-county-community-college"
    },
    {
        "id": "172307",
        "name": "Southwestern Michigan College",
        "hyphenated": "southwestern-michigan-college"
    },
    {
        "id": "172334",
        "name": "Spring Arbor University",
        "hyphenated": "spring-arbor-university"
    },
    {
        "id": "172422",
        "name": "Michigan Career and Technical Institute",
        "hyphenated": "michigan-career-and-technical-institute"
    },
    {
        "id": "172440",
        "name": "Finlandia University",
        "hyphenated": "finlandia-university"
    },
    {
        "id": "172477",
        "name": "Western Michigan University-Thomas M. Cooley Law School",
        "hyphenated": "western-michigan-university-thomas-m.-cooley-law-school"
    },
    {
        "id": "172495",
        "name": "Twin City Beauty College",
        "hyphenated": "twin-city-beauty-college"
    },
    {
        "id": "172608",
        "name": "Walsh College",
        "hyphenated": "walsh-college"
    },
    {
        "id": "172617",
        "name": "Washtenaw Community College",
        "hyphenated": "washtenaw-community-college"
    },
    {
        "id": "172635",
        "name": "Wayne County Community College District",
        "hyphenated": "wayne-county-community-college-district"
    },
    {
        "id": "172644",
        "name": "Wayne State University",
        "hyphenated": "wayne-state-university"
    },
    {
        "id": "172671",
        "name": "West Shore Community College",
        "hyphenated": "west-shore-community-college"
    },
    {
        "id": "172680",
        "name": "Paul Mitchell the School-Grand Rapids",
        "hyphenated": "paul-mitchell-the-school-grand-rapids"
    },
    {
        "id": "172699",
        "name": "Western Michigan University",
        "hyphenated": "western-michigan-university"
    },
    {
        "id": "172705",
        "name": "Western Theological Seminary",
        "hyphenated": "western-theological-seminary"
    },
    {
        "id": "172866",
        "name": "Academy College",
        "hyphenated": "academy-college"
    },
    {
        "id": "172918",
        "name": "Alexandria Technical & Community College",
        "hyphenated": "alexandria-technical-&-community-college"
    },
    {
        "id": "172954",
        "name": "Anoka Technical College",
        "hyphenated": "anoka-technical-college"
    },
    {
        "id": "172963",
        "name": "Anoka-Ramsey Community College",
        "hyphenated": "anoka-ramsey-community-college"
    },
    {
        "id": "173045",
        "name": "Augsburg University",
        "hyphenated": "augsburg-university"
    },
    {
        "id": "173063",
        "name": "Riverland Community College",
        "hyphenated": "riverland-community-college"
    },
    {
        "id": "173115",
        "name": "Northwest Technical College",
        "hyphenated": "northwest-technical-college"
    },
    {
        "id": "173124",
        "name": "Bemidji State University",
        "hyphenated": "bemidji-state-university"
    },
    {
        "id": "173142",
        "name": "Bethany Lutheran College",
        "hyphenated": "bethany-lutheran-college"
    },
    {
        "id": "173160",
        "name": "Bethel University",
        "hyphenated": "bethel-university"
    },
    {
        "id": "173203",
        "name": "Central Lakes College-Brainerd",
        "hyphenated": "central-lakes-college-brainerd"
    },
    {
        "id": "173300",
        "name": "Concordia College at Moorhead",
        "hyphenated": "concordia-college-at-moorhead"
    },
    {
        "id": "173328",
        "name": "Concordia University-Saint Paul",
        "hyphenated": "concordia-university-saint-paul"
    },
    {
        "id": "173416",
        "name": "Dakota County Technical College",
        "hyphenated": "dakota-county-technical-college"
    },
    {
        "id": "173452",
        "name": "Martin Luther College",
        "hyphenated": "martin-luther-college"
    },
    {
        "id": "173461",
        "name": "Lake Superior College",
        "hyphenated": "lake-superior-college"
    },
    {
        "id": "173470",
        "name": "Cosmetology Careers Unlimited College of Hair Skin and Nails",
        "hyphenated": "cosmetology-careers-unlimited-college-of-hair-skin-and-nails"
    },
    {
        "id": "173559",
        "name": "Minnesota State Community and Technical College",
        "hyphenated": "minnesota-state-community-and-technical-college"
    },
    {
        "id": "173638",
        "name": "Minnesota West Community and Technical College",
        "hyphenated": "minnesota-west-community-and-technical-college"
    },
    {
        "id": "173647",
        "name": "Gustavus Adolphus College",
        "hyphenated": "gustavus-adolphus-college"
    },
    {
        "id": "173665",
        "name": "Hamline University",
        "hyphenated": "hamline-university"
    },
    {
        "id": "173683",
        "name": "Hazelden Betty Ford Graduate School of Addiction Studies",
        "hyphenated": "hazelden-betty-ford-graduate-school-of-addiction-studies"
    },
    {
        "id": "173708",
        "name": "Hennepin Technical College",
        "hyphenated": "hennepin-technical-college"
    },
    {
        "id": "173735",
        "name": "Minnesota North College",
        "hyphenated": "minnesota-north-college"
    },
    {
        "id": "173744",
        "name": "Aveda Arts & Sciences Institute Minneapolis",
        "hyphenated": "aveda-arts-&-sciences-institute-minneapolis"
    },
    {
        "id": "173799",
        "name": "Inver Hills Community College",
        "hyphenated": "inver-hills-community-college"
    },
    {
        "id": "173805",
        "name": "Minnesota North College - Itasca",
        "hyphenated": "minnesota-north-college---itasca"
    },
    {
        "id": "173896",
        "name": "Luther Seminary",
        "hyphenated": "luther-seminary"
    },
    {
        "id": "173911",
        "name": "South Central College",
        "hyphenated": "south-central-college"
    },
    {
        "id": "173920",
        "name": "Minnesota State University-Mankato",
        "hyphenated": "minnesota-state-university-mankato"
    },
    {
        "id": "173957",
        "name": "Mayo Clinic College of Medicine and Science",
        "hyphenated": "mayo-clinic-college-of-medicine-and-science"
    },
    {
        "id": "173993",
        "name": "Minnesota North College - Mesabi Range Virginia",
        "hyphenated": "minnesota-north-college---mesabi-range-virginia"
    },
    {
        "id": "174020",
        "name": "Metropolitan State University",
        "hyphenated": "metropolitan-state-university"
    },
    {
        "id": "174075",
        "name": "University of Minnesota-Crookston",
        "hyphenated": "university-of-minnesota-crookston"
    },
    {
        "id": "174127",
        "name": "Minneapolis College of Art and Design",
        "hyphenated": "minneapolis-college-of-art-and-design"
    },
    {
        "id": "174136",
        "name": "Minneapolis Community and Technical College",
        "hyphenated": "minneapolis-community-and-technical-college"
    },
    {
        "id": "174154",
        "name": "Herzing University-Minneapolis",
        "hyphenated": "herzing-university-minneapolis"
    },
    {
        "id": "174190",
        "name": "Minnesota School of Cosmetology-Woodbury Campus",
        "hyphenated": "minnesota-school-of-cosmetology-woodbury-campus"
    },
    {
        "id": "174233",
        "name": "University of Minnesota-Duluth",
        "hyphenated": "university-of-minnesota-duluth"
    },
    {
        "id": "174251",
        "name": "University of Minnesota-Morris",
        "hyphenated": "university-of-minnesota-morris"
    },
    {
        "id": "174321",
        "name": "Model College of Hair Design",
        "hyphenated": "model-college-of-hair-design"
    },
    {
        "id": "174358",
        "name": "Minnesota State University Moorhead",
        "hyphenated": "minnesota-state-university-moorhead"
    },
    {
        "id": "174376",
        "name": "North Hennepin Community College",
        "hyphenated": "north-hennepin-community-college"
    },
    {
        "id": "174428",
        "name": "Normandale Community College",
        "hyphenated": "normandale-community-college"
    },
    {
        "id": "174437",
        "name": "North Central University",
        "hyphenated": "north-central-university"
    },
    {
        "id": "174473",
        "name": "Northland Community and Technical College",
        "hyphenated": "northland-community-and-technical-college"
    },
    {
        "id": "174491",
        "name": "University of Northwestern-St Paul",
        "hyphenated": "university-of-northwestern-st-paul"
    },
    {
        "id": "174507",
        "name": "Northwestern Health Sciences University",
        "hyphenated": "northwestern-health-sciences-university"
    },
    {
        "id": "174525",
        "name": "Oak Hills Christian College",
        "hyphenated": "oak-hills-christian-college"
    },
    {
        "id": "174570",
        "name": "Pine Technical & Community College",
        "hyphenated": "pine-technical-&-community-college"
    },
    {
        "id": "174604",
        "name": "Minnesota North College - Rainy River",
        "hyphenated": "minnesota-north-college---rainy-river"
    },
    {
        "id": "174738",
        "name": "Rochester Community and Technical College",
        "hyphenated": "rochester-community-and-technical-college"
    },
    {
        "id": "174747",
        "name": "College of Saint Benedict",
        "hyphenated": "college-of-saint-benedict"
    },
    {
        "id": "174756",
        "name": "St Cloud Technical and Community College",
        "hyphenated": "st-cloud-technical-and-community-college"
    },
    {
        "id": "174783",
        "name": "Saint Cloud State University",
        "hyphenated": "saint-cloud-state-university"
    },
    {
        "id": "174792",
        "name": "Saint Johns University",
        "hyphenated": "saint-johns-university"
    },
    {
        "id": "174817",
        "name": "Saint Mary's University of Minnesota",
        "hyphenated": "saint-mary's-university-of-minnesota"
    },
    {
        "id": "174844",
        "name": "St Olaf College",
        "hyphenated": "st-olaf-college"
    },
    {
        "id": "174862",
        "name": "Crown College",
        "hyphenated": "crown-college"
    },
    {
        "id": "174899",
        "name": "The College of Saint Scholastica",
        "hyphenated": "the-college-of-saint-scholastica"
    },
    {
        "id": "174978",
        "name": "Empire Beauty School-Bloomington",
        "hyphenated": "empire-beauty-school-bloomington"
    },
    {
        "id": "175005",
        "name": "St Catherine University",
        "hyphenated": "st-catherine-university"
    },
    {
        "id": "175014",
        "name": "Rasmussen University-Minnesota",
        "hyphenated": "rasmussen-university-minnesota"
    },
    {
        "id": "175041",
        "name": "Saint Paul College",
        "hyphenated": "saint-paul-college"
    },
    {
        "id": "175078",
        "name": "Southwest Minnesota State University",
        "hyphenated": "southwest-minnesota-state-university"
    },
    {
        "id": "175120",
        "name": "Summit Academy Opportunities Industrialization Center",
        "hyphenated": "summit-academy-opportunities-industrialization-center"
    },
    {
        "id": "175139",
        "name": "United Theological Seminary of the Twin Cities",
        "hyphenated": "united-theological-seminary-of-the-twin-cities"
    },
    {
        "id": "175157",
        "name": "Minnesota North College - Vermilion",
        "hyphenated": "minnesota-north-college---vermilion"
    },
    {
        "id": "175227",
        "name": "Dunwoody College of Technology",
        "hyphenated": "dunwoody-college-of-technology"
    },
    {
        "id": "175236",
        "name": "Ridgewater College",
        "hyphenated": "ridgewater-college"
    },
    {
        "id": "175263",
        "name": "Minnesota State College Southeast",
        "hyphenated": "minnesota-state-college-southeast"
    },
    {
        "id": "175272",
        "name": "Winona State University",
        "hyphenated": "winona-state-university"
    },
    {
        "id": "175281",
        "name": "Mitchell Hamline School of Law",
        "hyphenated": "mitchell-hamline-school-of-law"
    },
    {
        "id": "175315",
        "name": "Century College",
        "hyphenated": "century-college"
    },
    {
        "id": "175342",
        "name": "Alcorn State University",
        "hyphenated": "alcorn-state-university"
    },
    {
        "id": "175421",
        "name": "Belhaven University",
        "hyphenated": "belhaven-university"
    },
    {
        "id": "175430",
        "name": "Blue Mountain Christian University",
        "hyphenated": "blue-mountain-christian-university"
    },
    {
        "id": "175494",
        "name": "Chris Beauty College",
        "hyphenated": "chris-beauty-college"
    },
    {
        "id": "175519",
        "name": "Coahoma Community College",
        "hyphenated": "coahoma-community-college"
    },
    {
        "id": "175573",
        "name": "Copiah-Lincoln Community College",
        "hyphenated": "copiah-lincoln-community-college"
    },
    {
        "id": "175616",
        "name": "Delta State University",
        "hyphenated": "delta-state-university"
    },
    {
        "id": "175643",
        "name": "East Central Community College",
        "hyphenated": "east-central-community-college"
    },
    {
        "id": "175652",
        "name": "East Mississippi Community College",
        "hyphenated": "east-mississippi-community-college"
    },
    {
        "id": "175698",
        "name": "Fosters Cosmetology College",
        "hyphenated": "fosters-cosmetology-college"
    },
    {
        "id": "175722",
        "name": "Academy of Hair Design-Grenada",
        "hyphenated": "academy-of-hair-design-grenada"
    },
    {
        "id": "175786",
        "name": "Hinds Community College",
        "hyphenated": "hinds-community-college"
    },
    {
        "id": "175810",
        "name": "Holmes Community College",
        "hyphenated": "holmes-community-college"
    },
    {
        "id": "175829",
        "name": "Itawamba Community College",
        "hyphenated": "itawamba-community-college"
    },
    {
        "id": "175856",
        "name": "Jackson State University",
        "hyphenated": "jackson-state-university"
    },
    {
        "id": "175883",
        "name": "Jones County Junior College",
        "hyphenated": "jones-county-junior-college"
    },
    {
        "id": "175935",
        "name": "Meridian Community College",
        "hyphenated": "meridian-community-college"
    },
    {
        "id": "175980",
        "name": "Millsaps College",
        "hyphenated": "millsaps-college"
    },
    {
        "id": "176008",
        "name": "Mississippi Delta Community College",
        "hyphenated": "mississippi-delta-community-college"
    },
    {
        "id": "176017",
        "name": "University of Mississippi",
        "hyphenated": "university-of-mississippi"
    },
    {
        "id": "176035",
        "name": "Mississippi University for Women",
        "hyphenated": "mississippi-university-for-women"
    },
    {
        "id": "176044",
        "name": "Mississippi Valley State University",
        "hyphenated": "mississippi-valley-state-university"
    },
    {
        "id": "176053",
        "name": "Mississippi College",
        "hyphenated": "mississippi-college"
    },
    {
        "id": "176062",
        "name": "Hatfield's Mississippi College of Beauty Culture",
        "hyphenated": "hatfield's-mississippi-college-of-beauty-culture"
    },
    {
        "id": "176071",
        "name": "Mississippi Gulf Coast Community College",
        "hyphenated": "mississippi-gulf-coast-community-college"
    },
    {
        "id": "176080",
        "name": "Mississippi State University",
        "hyphenated": "mississippi-state-university"
    },
    {
        "id": "176169",
        "name": "Northeast Mississippi Community College",
        "hyphenated": "northeast-mississippi-community-college"
    },
    {
        "id": "176178",
        "name": "Northwest Mississippi Community College",
        "hyphenated": "northwest-mississippi-community-college"
    },
    {
        "id": "176239",
        "name": "Pearl River Community College",
        "hyphenated": "pearl-river-community-college"
    },
    {
        "id": "176318",
        "name": "Rust College",
        "hyphenated": "rust-college"
    },
    {
        "id": "176336",
        "name": "Southeastern Baptist College",
        "hyphenated": "southeastern-baptist-college"
    },
    {
        "id": "176354",
        "name": "Southwest Mississippi Community College",
        "hyphenated": "southwest-mississippi-community-college"
    },
    {
        "id": "176372",
        "name": "University of Southern Mississippi",
        "hyphenated": "university-of-southern-mississippi"
    },
    {
        "id": "176406",
        "name": "Tougaloo College",
        "hyphenated": "tougaloo-college"
    },
    {
        "id": "176433",
        "name": "Vaughn Beauty College",
        "hyphenated": "vaughn-beauty-college"
    },
    {
        "id": "176451",
        "name": "Wesley Biblical Seminary",
        "hyphenated": "wesley-biblical-seminary"
    },
    {
        "id": "176479",
        "name": "William Carey University",
        "hyphenated": "william-carey-university"
    },
    {
        "id": "176600",
        "name": "Aquinas Institute of Theology",
        "hyphenated": "aquinas-institute-of-theology"
    },
    {
        "id": "176628",
        "name": "Avila University",
        "hyphenated": "avila-university"
    },
    {
        "id": "176664",
        "name": "Baptist Bible College",
        "hyphenated": "baptist-bible-college"
    },
    {
        "id": "176716",
        "name": "House of Heavilin Beauty College-Blue Springs",
        "hyphenated": "house-of-heavilin-beauty-college-blue-springs"
    },
    {
        "id": "176770",
        "name": "Cox College",
        "hyphenated": "cox-college"
    },
    {
        "id": "176789",
        "name": "Calvary University",
        "hyphenated": "calvary-university"
    },
    {
        "id": "176798",
        "name": "Cape Girardeau Career and Technology Center",
        "hyphenated": "cape-girardeau-career-and-technology-center"
    },
    {
        "id": "176910",
        "name": "Central Christian College of the Bible",
        "hyphenated": "central-christian-college-of-the-bible"
    },
    {
        "id": "176947",
        "name": "Central Methodist University-College of Liberal Arts and Sciences",
        "hyphenated": "central-methodist-university-college-of-liberal-arts-and-sciences"
    },
    {
        "id": "176965",
        "name": "University of Central Missouri",
        "hyphenated": "university-of-central-missouri"
    },
    {
        "id": "176983",
        "name": "Grand River Technical School",
        "hyphenated": "grand-river-technical-school"
    },
    {
        "id": "177038",
        "name": "Cleveland University-Kansas City",
        "hyphenated": "cleveland-university-kansas-city"
    },
    {
        "id": "177065",
        "name": "Columbia College",
        "hyphenated": "columbia-college"
    },
    {
        "id": "177083",
        "name": "Conception Seminary College",
        "hyphenated": "conception-seminary-college"
    },
    {
        "id": "177092",
        "name": "Concordia Seminary",
        "hyphenated": "concordia-seminary"
    },
    {
        "id": "177117",
        "name": "Cottey College",
        "hyphenated": "cottey-college"
    },
    {
        "id": "177126",
        "name": "Covenant Theological Seminary",
        "hyphenated": "covenant-theological-seminary"
    },
    {
        "id": "177135",
        "name": "Crowder College",
        "hyphenated": "crowder-college"
    },
    {
        "id": "177144",
        "name": "Culver-Stockton College",
        "hyphenated": "culver-stockton-college"
    },
    {
        "id": "177214",
        "name": "Drury University",
        "hyphenated": "drury-university"
    },
    {
        "id": "177250",
        "name": "East Central College",
        "hyphenated": "east-central-college"
    },
    {
        "id": "177278",
        "name": "Eden Theological Seminary",
        "hyphenated": "eden-theological-seminary"
    },
    {
        "id": "177302",
        "name": "Pinnacle Career Institute",
        "hyphenated": "pinnacle-career-institute"
    },
    {
        "id": "177339",
        "name": "Evangel University",
        "hyphenated": "evangel-university"
    },
    {
        "id": "177418",
        "name": "Fontbonne University",
        "hyphenated": "fontbonne-university"
    },
    {
        "id": "177436",
        "name": "Four Rivers Career Center",
        "hyphenated": "four-rivers-career-center"
    },
    {
        "id": "177472",
        "name": "Ozarks Technical Community College",
        "hyphenated": "ozarks-technical-community-college"
    },
    {
        "id": "177542",
        "name": "Hannibal-LaGrange University",
        "hyphenated": "hannibal-lagrange-university"
    },
    {
        "id": "177551",
        "name": "Harris-Stowe State University",
        "hyphenated": "harris-stowe-state-university"
    },
    {
        "id": "177588",
        "name": "House of Heavilin Beauty College-Kansas City",
        "hyphenated": "house-of-heavilin-beauty-college-kansas-city"
    },
    {
        "id": "177603",
        "name": "Summit Salon Academy Kansas City",
        "hyphenated": "summit-salon-academy-kansas-city"
    },
    {
        "id": "177676",
        "name": "Jefferson College",
        "hyphenated": "jefferson-college"
    },
    {
        "id": "177685",
        "name": "Paul Mitchell the School-Missouri Columbia",
        "hyphenated": "paul-mitchell-the-school-missouri-columbia"
    },
    {
        "id": "177719",
        "name": "Barnes-Jewish College Goldfarb School of Nursing",
        "hyphenated": "barnes-jewish-college-goldfarb-school-of-nursing"
    },
    {
        "id": "177746",
        "name": "Kansas City Art Institute",
        "hyphenated": "kansas-city-art-institute"
    },
    {
        "id": "177816",
        "name": "Kenrick Glennon Seminary",
        "hyphenated": "kenrick-glennon-seminary"
    },
    {
        "id": "177834",
        "name": "A T Still University of Health Sciences",
        "hyphenated": "a-t-still-university-of-health-sciences"
    },
    {
        "id": "177870",
        "name": "Lake Career and Technical Center",
        "hyphenated": "lake-career-and-technical-center"
    },
    {
        "id": "177931",
        "name": "Lex La-Ray Technical Center",
        "hyphenated": "lex-la-ray-technical-center"
    },
    {
        "id": "177940",
        "name": "Lincoln University",
        "hyphenated": "lincoln-university"
    },
    {
        "id": "177968",
        "name": "Lindenwood University",
        "hyphenated": "lindenwood-university"
    },
    {
        "id": "177977",
        "name": "State Technical College of Missouri",
        "hyphenated": "state-technical-college-of-missouri"
    },
    {
        "id": "177986",
        "name": "Logan University",
        "hyphenated": "logan-university"
    },
    {
        "id": "177995",
        "name": "Metropolitan Community College-Kansas City",
        "hyphenated": "metropolitan-community-college-kansas-city"
    },
    {
        "id": "178004",
        "name": "Lutheran School of Nursing",
        "hyphenated": "lutheran-school-of-nursing"
    },
    {
        "id": "178059",
        "name": "Maryville University of Saint Louis",
        "hyphenated": "maryville-university-of-saint-louis"
    },
    {
        "id": "178077",
        "name": "Missouri College of Cosmetology and Esthetics",
        "hyphenated": "missouri-college-of-cosmetology-and-esthetics"
    },
    {
        "id": "178183",
        "name": "Midwest Institute",
        "hyphenated": "midwest-institute"
    },
    {
        "id": "178208",
        "name": "Midwestern Baptist Theological Seminary",
        "hyphenated": "midwestern-baptist-theological-seminary"
    },
    {
        "id": "178217",
        "name": "Mineral Area College",
        "hyphenated": "mineral-area-college"
    },
    {
        "id": "178244",
        "name": "Missouri Baptist University",
        "hyphenated": "missouri-baptist-university"
    },
    {
        "id": "178341",
        "name": "Missouri Southern State University",
        "hyphenated": "missouri-southern-state-university"
    },
    {
        "id": "178369",
        "name": "Missouri Valley College",
        "hyphenated": "missouri-valley-college"
    },
    {
        "id": "178387",
        "name": "Missouri Western State University",
        "hyphenated": "missouri-western-state-university"
    },
    {
        "id": "178402",
        "name": "University of Missouri-Kansas City",
        "hyphenated": "university-of-missouri-kansas-city"
    },
    {
        "id": "178411",
        "name": "Missouri University of Science and Technology",
        "hyphenated": "missouri-university-of-science-and-technology"
    },
    {
        "id": "178420",
        "name": "University of Missouri-St Louis",
        "hyphenated": "university-of-missouri-st-louis"
    },
    {
        "id": "178448",
        "name": "Moberly Area Community College",
        "hyphenated": "moberly-area-community-college"
    },
    {
        "id": "178518",
        "name": "Nazarene Theological Seminary",
        "hyphenated": "nazarene-theological-seminary"
    },
    {
        "id": "178527",
        "name": "Neosho Beauty College",
        "hyphenated": "neosho-beauty-college"
    },
    {
        "id": "178615",
        "name": "Truman State University",
        "hyphenated": "truman-state-university"
    },
    {
        "id": "178624",
        "name": "Northwest Missouri State University",
        "hyphenated": "northwest-missouri-state-university"
    },
    {
        "id": "178679",
        "name": "Ozark Christian College",
        "hyphenated": "ozark-christian-college"
    },
    {
        "id": "178697",
        "name": "College of the Ozarks",
        "hyphenated": "college-of-the-ozarks"
    },
    {
        "id": "178721",
        "name": "Park University",
        "hyphenated": "park-university"
    },
    {
        "id": "178767",
        "name": "Stevens-The Institute of Business & Arts",
        "hyphenated": "stevens-the-institute-of-business-&-arts"
    },
    {
        "id": "178828",
        "name": "Poplar Bluff Technical Career Center",
        "hyphenated": "poplar-bluff-technical-career-center"
    },
    {
        "id": "178891",
        "name": "Ranken Technical College",
        "hyphenated": "ranken-technical-college"
    },
    {
        "id": "178989",
        "name": "Research College of Nursing",
        "hyphenated": "research-college-of-nursing"
    },
    {
        "id": "179043",
        "name": "Rockhurst University",
        "hyphenated": "rockhurst-university"
    },
    {
        "id": "179052",
        "name": "Rolla Technical Institute/Center",
        "hyphenated": "rolla-technical-institute/center"
    },
    {
        "id": "179195",
        "name": "Salem College of Hairstyling",
        "hyphenated": "salem-college-of-hairstyling"
    },
    {
        "id": "179229",
        "name": "American College of Hair Design Inc",
        "hyphenated": "american-college-of-hair-design-inc"
    },
    {
        "id": "179256",
        "name": "Saint Louis Christian College",
        "hyphenated": "saint-louis-christian-college"
    },
    {
        "id": "179265",
        "name": "University of Health Sciences and Pharmacy in St. Louis",
        "hyphenated": "university-of-health-sciences-and-pharmacy-in-st.-louis"
    },
    {
        "id": "179308",
        "name": "Saint Louis Community College",
        "hyphenated": "saint-louis-community-college"
    },
    {
        "id": "179317",
        "name": "Saint Paul School of Theology",
        "hyphenated": "saint-paul-school-of-theology"
    },
    {
        "id": "179326",
        "name": "Southwest Baptist University",
        "hyphenated": "southwest-baptist-university"
    },
    {
        "id": "179344",
        "name": "Missouri State University-West Plains",
        "hyphenated": "missouri-state-university-west-plains"
    },
    {
        "id": "179511",
        "name": "St Louis College of Health Careers-St Louis",
        "hyphenated": "st-louis-college-of-health-careers-st-louis"
    },
    {
        "id": "179539",
        "name": "State Fair Community College",
        "hyphenated": "state-fair-community-college"
    },
    {
        "id": "179548",
        "name": "Stephens College",
        "hyphenated": "stephens-college"
    },
    {
        "id": "179557",
        "name": "Southeast Missouri State University",
        "hyphenated": "southeast-missouri-state-university"
    },
    {
        "id": "179566",
        "name": "Missouri State University-Springfield",
        "hyphenated": "missouri-state-university-springfield"
    },
    {
        "id": "179645",
        "name": "Three Rivers College",
        "hyphenated": "three-rivers-college"
    },
    {
        "id": "179715",
        "name": "North Central Missouri College",
        "hyphenated": "north-central-missouri-college"
    },
    {
        "id": "179812",
        "name": "Kansas City University",
        "hyphenated": "kansas-city-university"
    },
    {
        "id": "179894",
        "name": "Webster University",
        "hyphenated": "webster-university"
    },
    {
        "id": "179946",
        "name": "Westminster College",
        "hyphenated": "westminster-college"
    },
    {
        "id": "179955",
        "name": "William Jewell College",
        "hyphenated": "william-jewell-college"
    },
    {
        "id": "179964",
        "name": "William Woods University",
        "hyphenated": "william-woods-university"
    },
    {
        "id": "179991",
        "name": "Academy of Cosmetology Inc",
        "hyphenated": "academy-of-cosmetology-inc"
    },
    {
        "id": "180054",
        "name": "Blackfeet Community College",
        "hyphenated": "blackfeet-community-college"
    },
    {
        "id": "180063",
        "name": "Butte Academy of Beauty Culture",
        "hyphenated": "butte-academy-of-beauty-culture"
    },
    {
        "id": "180081",
        "name": "Highlands College of Montana Tech",
        "hyphenated": "highlands-college-of-montana-tech"
    },
    {
        "id": "180090",
        "name": "Crevier's Academy of Cosmetology Arts",
        "hyphenated": "crevier's-academy-of-cosmetology-arts"
    },
    {
        "id": "180106",
        "name": "Carroll College",
        "hyphenated": "carroll-college"
    },
    {
        "id": "180151",
        "name": "Dawson Community College",
        "hyphenated": "dawson-community-college"
    },
    {
        "id": "180160",
        "name": "Chief Dull Knife College",
        "hyphenated": "chief-dull-knife-college"
    },
    {
        "id": "180179",
        "name": "Montana State University Billings",
        "hyphenated": "montana-state-university-billings"
    },
    {
        "id": "180197",
        "name": "Flathead Valley Community College",
        "hyphenated": "flathead-valley-community-college"
    },
    {
        "id": "180203",
        "name": "Aaniiih Nakoda College",
        "hyphenated": "aaniiih-nakoda-college"
    },
    {
        "id": "180212",
        "name": "Fort Peck Community College",
        "hyphenated": "fort-peck-community-college"
    },
    {
        "id": "180249",
        "name": "Great Falls College Montana State University",
        "hyphenated": "great-falls-college-montana-state-university"
    },
    {
        "id": "180258",
        "name": "University of Providence",
        "hyphenated": "university-of-providence"
    },
    {
        "id": "180276",
        "name": "Helena College University of Montana",
        "hyphenated": "helena-college-university-of-montana"
    },
    {
        "id": "180328",
        "name": "Little Big Horn College",
        "hyphenated": "little-big-horn-college"
    },
    {
        "id": "180373",
        "name": "Miles Community College",
        "hyphenated": "miles-community-college"
    },
    {
        "id": "180416",
        "name": "Montana Technological University",
        "hyphenated": "montana-technological-university"
    },
    {
        "id": "180461",
        "name": "Montana State University",
        "hyphenated": "montana-state-university"
    },
    {
        "id": "180489",
        "name": "The University of Montana",
        "hyphenated": "the-university-of-montana"
    },
    {
        "id": "180522",
        "name": "Montana State University-Northern",
        "hyphenated": "montana-state-university-northern"
    },
    {
        "id": "180595",
        "name": "Rocky Mountain College",
        "hyphenated": "rocky-mountain-college"
    },
    {
        "id": "180647",
        "name": "Salish Kootenai College",
        "hyphenated": "salish-kootenai-college"
    },
    {
        "id": "180692",
        "name": "The University of Montana-Western",
        "hyphenated": "the-university-of-montana-western"
    },
    {
        "id": "180717",
        "name": "Yellowstone Christian College",
        "hyphenated": "yellowstone-christian-college"
    },
    {
        "id": "180814",
        "name": "Bellevue University",
        "hyphenated": "bellevue-university"
    },
    {
        "id": "180832",
        "name": "Clarkson College",
        "hyphenated": "clarkson-college"
    },
    {
        "id": "180878",
        "name": "Bryan College of Health Sciences",
        "hyphenated": "bryan-college-of-health-sciences"
    },
    {
        "id": "180902",
        "name": "Central Community College",
        "hyphenated": "central-community-college"
    },
    {
        "id": "180948",
        "name": "Chadron State College",
        "hyphenated": "chadron-state-college"
    },
    {
        "id": "180957",
        "name": "College of Hair Design-Downtown",
        "hyphenated": "college-of-hair-design-downtown"
    },
    {
        "id": "180984",
        "name": "Concordia University-Nebraska",
        "hyphenated": "concordia-university-nebraska"
    },
    {
        "id": "181020",
        "name": "Doane University",
        "hyphenated": "doane-university"
    },
    {
        "id": "181127",
        "name": "Hastings College",
        "hyphenated": "hastings-college"
    },
    {
        "id": "181145",
        "name": "CHI Health School of Radiologic Technology",
        "hyphenated": "chi-health-school-of-radiologic-technology"
    },
    {
        "id": "181190",
        "name": "Joseph's College Cosmetology",
        "hyphenated": "joseph's-college-cosmetology"
    },
    {
        "id": "181215",
        "name": "University of Nebraska at Kearney",
        "hyphenated": "university-of-nebraska-at-kearney"
    },
    {
        "id": "181297",
        "name": "Nebraska Methodist College of Nursing & Allied Health",
        "hyphenated": "nebraska-methodist-college-of-nursing-&-allied-health"
    },
    {
        "id": "181303",
        "name": "Metropolitan Community College Area",
        "hyphenated": "metropolitan-community-college-area"
    },
    {
        "id": "181312",
        "name": "Mid-Plains Community College",
        "hyphenated": "mid-plains-community-college"
    },
    {
        "id": "181330",
        "name": "Midland University",
        "hyphenated": "midland-university"
    },
    {
        "id": "181394",
        "name": "University of Nebraska at Omaha",
        "hyphenated": "university-of-nebraska-at-omaha"
    },
    {
        "id": "181419",
        "name": "Nebraska Indian Community College",
        "hyphenated": "nebraska-indian-community-college"
    },
    {
        "id": "181428",
        "name": "University of Nebraska Medical Center",
        "hyphenated": "university-of-nebraska-medical-center"
    },
    {
        "id": "181446",
        "name": "Nebraska Wesleyan University",
        "hyphenated": "nebraska-wesleyan-university"
    },
    {
        "id": "181464",
        "name": "University of Nebraska-Lincoln",
        "hyphenated": "university-of-nebraska-lincoln"
    },
    {
        "id": "181491",
        "name": "Northeast Community College",
        "hyphenated": "northeast-community-college"
    },
    {
        "id": "181534",
        "name": "Peru State College",
        "hyphenated": "peru-state-college"
    },
    {
        "id": "181543",
        "name": "Summit Christian College",
        "hyphenated": "summit-christian-college"
    },
    {
        "id": "181604",
        "name": "College of Saint Mary",
        "hyphenated": "college-of-saint-mary"
    },
    {
        "id": "181640",
        "name": "Southeast Community College Area",
        "hyphenated": "southeast-community-college-area"
    },
    {
        "id": "181738",
        "name": "Union College",
        "hyphenated": "union-college"
    },
    {
        "id": "181765",
        "name": "Nebraska College of Technical Agriculture",
        "hyphenated": "nebraska-college-of-technical-agriculture"
    },
    {
        "id": "181783",
        "name": "Wayne State College",
        "hyphenated": "wayne-state-college"
    },
    {
        "id": "181817",
        "name": "Western Nebraska Community College",
        "hyphenated": "western-nebraska-community-college"
    },
    {
        "id": "181853",
        "name": "York University",
        "hyphenated": "york-university"
    },
    {
        "id": "181880",
        "name": "Academy of Hair Design-Las Vegas",
        "hyphenated": "academy-of-hair-design-las-vegas"
    },
    {
        "id": "181941",
        "name": "Career College of Northern Nevada",
        "hyphenated": "career-college-of-northern-nevada"
    },
    {
        "id": "182005",
        "name": "College of Southern Nevada",
        "hyphenated": "college-of-southern-nevada"
    },
    {
        "id": "182148",
        "name": "Las Vegas College",
        "hyphenated": "las-vegas-college"
    },
    {
        "id": "182281",
        "name": "University of Nevada-Las Vegas",
        "hyphenated": "university-of-nevada-las-vegas"
    },
    {
        "id": "182290",
        "name": "University of Nevada-Reno",
        "hyphenated": "university-of-nevada-reno"
    },
    {
        "id": "182306",
        "name": "Great Basin College",
        "hyphenated": "great-basin-college"
    },
    {
        "id": "182458",
        "name": "Sierra Nevada University",
        "hyphenated": "sierra-nevada-university"
    },
    {
        "id": "182500",
        "name": "Truckee Meadows Community College",
        "hyphenated": "truckee-meadows-community-college"
    },
    {
        "id": "182564",
        "name": "Western Nevada College",
        "hyphenated": "western-nevada-college"
    },
    {
        "id": "182634",
        "name": "Colby-Sawyer College",
        "hyphenated": "colby-sawyer-college"
    },
    {
        "id": "182704",
        "name": "Empire Beauty School-Laconia",
        "hyphenated": "empire-beauty-school-laconia"
    },
    {
        "id": "182722",
        "name": "Empire Beauty School-Somersworth",
        "hyphenated": "empire-beauty-school-somersworth"
    },
    {
        "id": "182795",
        "name": "Franklin Pierce University",
        "hyphenated": "franklin-pierce-university"
    },
    {
        "id": "182829",
        "name": "University of New Hampshire-Franklin Pierce School of Law",
        "hyphenated": "university-of-new-hampshire-franklin-pierce-school-of-law"
    },
    {
        "id": "182892",
        "name": "Keene Beauty Academy",
        "hyphenated": "keene-beauty-academy"
    },
    {
        "id": "182917",
        "name": "Magdalen College",
        "hyphenated": "magdalen-college"
    },
    {
        "id": "182953",
        "name": "Michael's School of Hair Design & Esthetics",
        "hyphenated": "michael's-school-of-hair-design-&-esthetics"
    },
    {
        "id": "182980",
        "name": "New England College",
        "hyphenated": "new-england-college"
    },
    {
        "id": "182999",
        "name": "New England School of Hair Design",
        "hyphenated": "new-england-school-of-hair-design"
    },
    {
        "id": "183026",
        "name": "Southern New Hampshire University",
        "hyphenated": "southern-new-hampshire-university"
    },
    {
        "id": "183035",
        "name": "New Hampshire Institute for Therapeutic Arts",
        "hyphenated": "new-hampshire-institute-for-therapeutic-arts"
    },
    {
        "id": "183062",
        "name": "Keene State College",
        "hyphenated": "keene-state-college"
    },
    {
        "id": "183071",
        "name": "University of New Hampshire at Manchester",
        "hyphenated": "university-of-new-hampshire-at-manchester"
    },
    {
        "id": "183080",
        "name": "Plymouth State University",
        "hyphenated": "plymouth-state-university"
    },
    {
        "id": "183099",
        "name": "NHTI-Concord's Community College",
        "hyphenated": "nhti-concord's-community-college"
    },
    {
        "id": "183105",
        "name": "White Mountains Community College",
        "hyphenated": "white-mountains-community-college"
    },
    {
        "id": "183114",
        "name": "River Valley Community College",
        "hyphenated": "river-valley-community-college"
    },
    {
        "id": "183123",
        "name": "Lakes Region Community College",
        "hyphenated": "lakes-region-community-college"
    },
    {
        "id": "183132",
        "name": "Manchester Community College",
        "hyphenated": "manchester-community-college"
    },
    {
        "id": "183141",
        "name": "Nashua Community College",
        "hyphenated": "nashua-community-college"
    },
    {
        "id": "183150",
        "name": "Great Bay Community College",
        "hyphenated": "great-bay-community-college"
    },
    {
        "id": "183202",
        "name": "Paul Mitchell the School-Portsmouth",
        "hyphenated": "paul-mitchell-the-school-portsmouth"
    },
    {
        "id": "183211",
        "name": "Rivier University",
        "hyphenated": "rivier-university"
    },
    {
        "id": "183239",
        "name": "Saint Anselm College",
        "hyphenated": "saint-anselm-college"
    },
    {
        "id": "183248",
        "name": "St Joseph School of Nursing",
        "hyphenated": "st-joseph-school-of-nursing"
    },
    {
        "id": "183257",
        "name": "Granite State College",
        "hyphenated": "granite-state-college"
    },
    {
        "id": "183275",
        "name": "Thomas More College of Liberal Arts",
        "hyphenated": "thomas-more-college-of-liberal-arts"
    },
    {
        "id": "183488",
        "name": "Eastwick College-Hackensack",
        "hyphenated": "eastwick-college-hackensack"
    },
    {
        "id": "183600",
        "name": "Assumption College for Sisters",
        "hyphenated": "assumption-college-for-sisters"
    },
    {
        "id": "183655",
        "name": "Atlantic Cape Community College",
        "hyphenated": "atlantic-cape-community-college"
    },
    {
        "id": "183725",
        "name": "Fortis Institute-Wayne",
        "hyphenated": "fortis-institute-wayne"
    },
    {
        "id": "183743",
        "name": "Bergen Community College",
        "hyphenated": "bergen-community-college"
    },
    {
        "id": "183789",
        "name": "Berkeley College-Woodland Park",
        "hyphenated": "berkeley-college-woodland-park"
    },
    {
        "id": "183804",
        "name": "Beth Medrash Govoha",
        "hyphenated": "beth-medrash-govoha"
    },
    {
        "id": "183822",
        "name": "Bloomfield College",
        "hyphenated": "bloomfield-college"
    },
    {
        "id": "183859",
        "name": "Brookdale Community College",
        "hyphenated": "brookdale-community-college"
    },
    {
        "id": "183877",
        "name": "Rowan College at Burlington County",
        "hyphenated": "rowan-college-at-burlington-county"
    },
    {
        "id": "183910",
        "name": "Caldwell University",
        "hyphenated": "caldwell-university"
    },
    {
        "id": "183938",
        "name": "Camden County College",
        "hyphenated": "camden-county-college"
    },
    {
        "id": "183974",
        "name": "Centenary University",
        "hyphenated": "centenary-university"
    },
    {
        "id": "183983",
        "name": "Adult and Continuing Education-BCTS",
        "hyphenated": "adult-and-continuing-education-bcts"
    },
    {
        "id": "184056",
        "name": "Lincoln Technical Institute-Iselin",
        "hyphenated": "lincoln-technical-institute-iselin"
    },
    {
        "id": "184092",
        "name": "American Institute-Clifton",
        "hyphenated": "american-institute-clifton"
    },
    {
        "id": "184180",
        "name": "County College of Morris",
        "hyphenated": "county-college-of-morris"
    },
    {
        "id": "184205",
        "name": "Rowan College of South Jersey-Cumberland Campus",
        "hyphenated": "rowan-college-of-south-jersey-cumberland-campus"
    },
    {
        "id": "184348",
        "name": "Drew University",
        "hyphenated": "drew-university"
    },
    {
        "id": "184427",
        "name": "Lincoln Technical Institute-South Plainfield",
        "hyphenated": "lincoln-technical-institute-south-plainfield"
    },
    {
        "id": "184481",
        "name": "Essex County College",
        "hyphenated": "essex-county-college"
    },
    {
        "id": "184506",
        "name": "Empire Beauty School-Union",
        "hyphenated": "empire-beauty-school-union"
    },
    {
        "id": "184603",
        "name": "Fairleigh Dickinson University-Metropolitan Campus",
        "hyphenated": "fairleigh-dickinson-university-metropolitan-campus"
    },
    {
        "id": "184612",
        "name": "Felician University",
        "hyphenated": "felician-university"
    },
    {
        "id": "184694",
        "name": "Fairleigh Dickinson University-Florham Campus",
        "hyphenated": "fairleigh-dickinson-university-florham-campus"
    },
    {
        "id": "184746",
        "name": "Hohokus School of Trade and Technical Sciences",
        "hyphenated": "hohokus-school-of-trade-and-technical-sciences"
    },
    {
        "id": "184773",
        "name": "Georgian Court University",
        "hyphenated": "georgian-court-university"
    },
    {
        "id": "184782",
        "name": "Rowan University",
        "hyphenated": "rowan-university"
    },
    {
        "id": "184791",
        "name": "Rowan College of South Jersey-Gloucester Campus",
        "hyphenated": "rowan-college-of-south-jersey-gloucester-campus"
    },
    {
        "id": "184816",
        "name": "Empire Beauty School-Cherry Hill",
        "hyphenated": "empire-beauty-school-cherry-hill"
    },
    {
        "id": "184959",
        "name": "Eastwick College-Ramsey",
        "hyphenated": "eastwick-college-ramsey"
    },
    {
        "id": "184968",
        "name": "Holy Name Medical Center-Sister Claire Tynan School of Nursing",
        "hyphenated": "holy-name-medical-center-sister-claire-tynan-school-of-nursing"
    },
    {
        "id": "184995",
        "name": "Hudson County Community College",
        "hyphenated": "hudson-county-community-college"
    },
    {
        "id": "185129",
        "name": "New Jersey City University",
        "hyphenated": "new-jersey-city-university"
    },
    {
        "id": "185174",
        "name": "Joe Kubert School of Cartoon and Graphic Art",
        "hyphenated": "joe-kubert-school-of-cartoon-and-graphic-art"
    },
    {
        "id": "185262",
        "name": "Kean University",
        "hyphenated": "kean-university"
    },
    {
        "id": "185396",
        "name": "Lincoln Technical Institute-Union",
        "hyphenated": "lincoln-technical-institute-union"
    },
    {
        "id": "185466",
        "name": "Rizzieri Aveda School for Beauty and Wellness",
        "hyphenated": "rizzieri-aveda-school-for-beauty-and-wellness"
    },
    {
        "id": "185509",
        "name": "Mercer County Community College",
        "hyphenated": "mercer-county-community-college"
    },
    {
        "id": "185536",
        "name": "Middlesex College",
        "hyphenated": "middlesex-college"
    },
    {
        "id": "185572",
        "name": "Monmouth University",
        "hyphenated": "monmouth-university"
    },
    {
        "id": "185590",
        "name": "Montclair State University",
        "hyphenated": "montclair-state-university"
    },
    {
        "id": "185679",
        "name": "JFK Muhlenberg Harold B. and Dorothy A. Snyder Schools",
        "hyphenated": "jfk-muhlenberg-harold-b.-and-dorothy-a.-snyder-schools"
    },
    {
        "id": "185721",
        "name": "Eastwick College-Nutley",
        "hyphenated": "eastwick-college-nutley"
    },
    {
        "id": "185758",
        "name": "New Brunswick Theological Seminary",
        "hyphenated": "new-brunswick-theological-seminary"
    },
    {
        "id": "185767",
        "name": "Robert Fiance Beauty Schools-West New York",
        "hyphenated": "robert-fiance-beauty-schools-west-new-york"
    },
    {
        "id": "185873",
        "name": "Ocean County College",
        "hyphenated": "ocean-county-college"
    },
    {
        "id": "185970",
        "name": "P B Cosmetology Education Center",
        "hyphenated": "p-b-cosmetology-education-center"
    },
    {
        "id": "186016",
        "name": "Parisian Beauty School",
        "hyphenated": "parisian-beauty-school"
    },
    {
        "id": "186034",
        "name": "Passaic County Community College",
        "hyphenated": "passaic-county-community-college"
    },
    {
        "id": "186052",
        "name": "Pennco Tech-Blackwood",
        "hyphenated": "pennco-tech-blackwood"
    },
    {
        "id": "186122",
        "name": "Princeton Theological Seminary",
        "hyphenated": "princeton-theological-seminary"
    },
    {
        "id": "186186",
        "name": "Rabbinical College of America",
        "hyphenated": "rabbinical-college-of-america"
    },
    {
        "id": "186201",
        "name": "Ramapo College of New Jersey",
        "hyphenated": "ramapo-college-of-new-jersey"
    },
    {
        "id": "186274",
        "name": "Robert Fiance Beauty Schools",
        "hyphenated": "robert-fiance-beauty-schools"
    },
    {
        "id": "186283",
        "name": "Rider University",
        "hyphenated": "rider-university"
    },
    {
        "id": "186405",
        "name": "St Francis Medical Center-School of Radiologic Technology",
        "hyphenated": "st-francis-medical-center-school-of-radiologic-technology"
    },
    {
        "id": "186423",
        "name": "Saint Francis Medical Center School of Nursing",
        "hyphenated": "saint-francis-medical-center-school-of-nursing"
    },
    {
        "id": "186432",
        "name": "Saint Peter's University",
        "hyphenated": "saint-peter's-university"
    },
    {
        "id": "186469",
        "name": "Salem Community College",
        "hyphenated": "salem-community-college"
    },
    {
        "id": "186593",
        "name": "Shore Beauty School",
        "hyphenated": "shore-beauty-school"
    },
    {
        "id": "186618",
        "name": "Saint Elizabeth University",
        "hyphenated": "saint-elizabeth-university"
    },
    {
        "id": "186645",
        "name": "Raritan Valley Community College",
        "hyphenated": "raritan-valley-community-college"
    },
    {
        "id": "186876",
        "name": "Stockton University",
        "hyphenated": "stockton-university"
    },
    {
        "id": "186900",
        "name": "Talmudical Academy-New Jersey",
        "hyphenated": "talmudical-academy-new-jersey"
    },
    {
        "id": "187000",
        "name": "Aviation Institute of Maintenance-Teterboro",
        "hyphenated": "aviation-institute-of-maintenance-teterboro"
    },
    {
        "id": "187046",
        "name": "Thomas Edison State University",
        "hyphenated": "thomas-edison-state-university"
    },
    {
        "id": "187134",
        "name": "The College of New Jersey",
        "hyphenated": "the-college-of-new-jersey"
    },
    {
        "id": "187198",
        "name": "Union County College",
        "hyphenated": "union-county-college"
    },
    {
        "id": "187444",
        "name": "William Paterson University of New Jersey",
        "hyphenated": "william-paterson-university-of-new-jersey"
    },
    {
        "id": "187505",
        "name": "Olympian Academy of Cosmetology",
        "hyphenated": "olympian-academy-of-cosmetology"
    },
    {
        "id": "187532",
        "name": "Central New Mexico Community College",
        "hyphenated": "central-new-mexico-community-college"
    },
    {
        "id": "187596",
        "name": "Navajo Technical University",
        "hyphenated": "navajo-technical-university"
    },
    {
        "id": "187620",
        "name": "New Mexico State University-Dona Ana",
        "hyphenated": "new-mexico-state-university-dona-ana"
    },
    {
        "id": "187639",
        "name": "Clovis Community College",
        "hyphenated": "clovis-community-college"
    },
    {
        "id": "187648",
        "name": "Eastern New Mexico University-Main Campus",
        "hyphenated": "eastern-new-mexico-university-main-campus"
    },
    {
        "id": "187666",
        "name": "Eastern New Mexico University-Roswell Campus",
        "hyphenated": "eastern-new-mexico-university-roswell-campus"
    },
    {
        "id": "187745",
        "name": "Institute of American Indian and Alaska Native Culture and Arts Development",
        "hyphenated": "institute-of-american-indian-and-alaska-native-culture-and-arts-development"
    },
    {
        "id": "187897",
        "name": "New Mexico Highlands University",
        "hyphenated": "new-mexico-highlands-university"
    },
    {
        "id": "187903",
        "name": "New Mexico Junior College",
        "hyphenated": "new-mexico-junior-college"
    },
    {
        "id": "187912",
        "name": "New Mexico Military Institute",
        "hyphenated": "new-mexico-military-institute"
    },
    {
        "id": "187958",
        "name": "University of New Mexico-Gallup Campus",
        "hyphenated": "university-of-new-mexico-gallup-campus"
    },
    {
        "id": "187967",
        "name": "New Mexico Institute of Mining and Technology",
        "hyphenated": "new-mexico-institute-of-mining-and-technology"
    },
    {
        "id": "187976",
        "name": "University of New Mexico-Los Alamos Campus",
        "hyphenated": "university-of-new-mexico-los-alamos-campus"
    },
    {
        "id": "187985",
        "name": "University of New Mexico-Main Campus",
        "hyphenated": "university-of-new-mexico-main-campus"
    },
    {
        "id": "187994",
        "name": "New Mexico State University-Alamogordo",
        "hyphenated": "new-mexico-state-university-alamogordo"
    },
    {
        "id": "188003",
        "name": "Southeast New Mexico College",
        "hyphenated": "southeast-new-mexico-college"
    },
    {
        "id": "188021",
        "name": "New Mexico State University-Grants",
        "hyphenated": "new-mexico-state-university-grants"
    },
    {
        "id": "188030",
        "name": "New Mexico State University-Main Campus",
        "hyphenated": "new-mexico-state-university-main-campus"
    },
    {
        "id": "188049",
        "name": "University of New Mexico-Valencia County Campus",
        "hyphenated": "university-of-new-mexico-valencia-county-campus"
    },
    {
        "id": "188058",
        "name": "Northern New Mexico College",
        "hyphenated": "northern-new-mexico-college"
    },
    {
        "id": "188100",
        "name": "San Juan College",
        "hyphenated": "san-juan-college"
    },
    {
        "id": "188137",
        "name": "Santa Fe Community College",
        "hyphenated": "santa-fe-community-college"
    },
    {
        "id": "188182",
        "name": "University of the Southwest",
        "hyphenated": "university-of-the-southwest"
    },
    {
        "id": "188207",
        "name": "Southwestern College",
        "hyphenated": "southwestern-college"
    },
    {
        "id": "188216",
        "name": "Southwestern Indian Polytechnic Institute",
        "hyphenated": "southwestern-indian-polytechnic-institute"
    },
    {
        "id": "188225",
        "name": "University of New Mexico-Taos Campus",
        "hyphenated": "university-of-new-mexico-taos-campus"
    },
    {
        "id": "188261",
        "name": "Mesalands Community College",
        "hyphenated": "mesalands-community-college"
    },
    {
        "id": "188289",
        "name": "Vogue College of Cosmetology-Santa Fe",
        "hyphenated": "vogue-college-of-cosmetology-santa-fe"
    },
    {
        "id": "188304",
        "name": "Western New Mexico University",
        "hyphenated": "western-new-mexico-university"
    },
    {
        "id": "188340",
        "name": "Vaughn College of Aeronautics and Technology",
        "hyphenated": "vaughn-college-of-aeronautics-and-technology"
    },
    {
        "id": "188429",
        "name": "Adelphi University",
        "hyphenated": "adelphi-university"
    },
    {
        "id": "188438",
        "name": "SUNY Adirondack",
        "hyphenated": "suny-adirondack"
    },
    {
        "id": "188517",
        "name": "Bryant & Stratton College-Albany",
        "hyphenated": "bryant-&-stratton-college-albany"
    },
    {
        "id": "188526",
        "name": "Albany College of Pharmacy and Health Sciences",
        "hyphenated": "albany-college-of-pharmacy-and-health-sciences"
    },
    {
        "id": "188535",
        "name": "Albany Law School",
        "hyphenated": "albany-law-school"
    },
    {
        "id": "188580",
        "name": "Albany Medical College",
        "hyphenated": "albany-medical-college"
    },
    {
        "id": "188641",
        "name": "Alfred University",
        "hyphenated": "alfred-university"
    },
    {
        "id": "188650",
        "name": "Allen School-Brooklyn",
        "hyphenated": "allen-school-brooklyn"
    },
    {
        "id": "188669",
        "name": "The Ailey School",
        "hyphenated": "the-ailey-school"
    },
    {
        "id": "188678",
        "name": "American Academy of Dramatic Arts-New York",
        "hyphenated": "american-academy-of-dramatic-arts-new-york"
    },
    {
        "id": "188687",
        "name": "American Academy McAllister Institute of Funeral Service",
        "hyphenated": "american-academy-mcallister-institute-of-funeral-service"
    },
    {
        "id": "188696",
        "name": "Joffrey Ballet School",
        "hyphenated": "joffrey-ballet-school"
    },
    {
        "id": "188854",
        "name": "American Musical and Dramatic Academy",
        "hyphenated": "american-musical-and-dramatic-academy"
    },
    {
        "id": "188890",
        "name": "Apex Technical School",
        "hyphenated": "apex-technical-school"
    },
    {
        "id": "188915",
        "name": "Arnot Ogden Medical Center",
        "hyphenated": "arnot-ogden-medical-center"
    },
    {
        "id": "188942",
        "name": "Associated Beth Rivkah Schools",
        "hyphenated": "associated-beth-rivkah-schools"
    },
    {
        "id": "189015",
        "name": "Bank Street College of Education",
        "hyphenated": "bank-street-college-of-education"
    },
    {
        "id": "189088",
        "name": "Bard College",
        "hyphenated": "bard-college"
    },
    {
        "id": "189219",
        "name": "Berk Trade and Business School",
        "hyphenated": "berk-trade-and-business-school"
    },
    {
        "id": "189228",
        "name": "Berkeley College-New York",
        "hyphenated": "berkeley-college-new-york"
    },
    {
        "id": "189255",
        "name": "Berkowits School of Electrolysis",
        "hyphenated": "berkowits-school-of-electrolysis"
    },
    {
        "id": "189273",
        "name": "Beth Hamedrash Shaarei Yosher Institute",
        "hyphenated": "beth-hamedrash-shaarei-yosher-institute"
    },
    {
        "id": "189282",
        "name": "Phillips School of Nursing at Mount Sinai Beth Israel",
        "hyphenated": "phillips-school-of-nursing-at-mount-sinai-beth-israel"
    },
    {
        "id": "189404",
        "name": "Brittany Beauty Academy",
        "hyphenated": "brittany-beauty-academy"
    },
    {
        "id": "189413",
        "name": "Boricua College",
        "hyphenated": "boricua-college"
    },
    {
        "id": "189468",
        "name": "Brittany Beauty Academy",
        "hyphenated": "brittany-beauty-academy"
    },
    {
        "id": "189477",
        "name": "American Beauty School",
        "hyphenated": "american-beauty-school"
    },
    {
        "id": "189501",
        "name": "Brooklyn Law School",
        "hyphenated": "brooklyn-law-school"
    },
    {
        "id": "189547",
        "name": "SUNY Broome Community College",
        "hyphenated": "suny-broome-community-college"
    },
    {
        "id": "189565",
        "name": "Bryant & Stratton College-Syracuse North",
        "hyphenated": "bryant-&-stratton-college-syracuse-north"
    },
    {
        "id": "189583",
        "name": "Bryant & Stratton College-Buffalo",
        "hyphenated": "bryant-&-stratton-college-buffalo"
    },
    {
        "id": "189592",
        "name": "Bryant & Stratton College-Greece",
        "hyphenated": "bryant-&-stratton-college-greece"
    },
    {
        "id": "189705",
        "name": "Canisius College",
        "hyphenated": "canisius-college"
    },
    {
        "id": "189714",
        "name": "Capri Cosmetology Learning Centers",
        "hyphenated": "capri-cosmetology-learning-centers"
    },
    {
        "id": "189811",
        "name": "St Paul's School of Nursing-Queens",
        "hyphenated": "st-paul's-school-of-nursing-queens"
    },
    {
        "id": "189839",
        "name": "Cayuga County Community College",
        "hyphenated": "cayuga-county-community-college"
    },
    {
        "id": "189848",
        "name": "Cazenovia College",
        "hyphenated": "cazenovia-college"
    },
    {
        "id": "189857",
        "name": "Central Yeshiva Tomchei Tmimim Lubavitz",
        "hyphenated": "central-yeshiva-tomchei-tmimim-lubavitz"
    },
    {
        "id": "189990",
        "name": "Christine Valmy International School for Esthetics Skin Care & Makeup",
        "hyphenated": "christine-valmy-international-school-for-esthetics-skin-care-&-makeup"
    },
    {
        "id": "190008",
        "name": "Circle in the Square Theatre School",
        "hyphenated": "circle-in-the-square-theatre-school"
    },
    {
        "id": "190053",
        "name": "Clinton Community College",
        "hyphenated": "clinton-community-college"
    },
    {
        "id": "190071",
        "name": "Cochran School of Nursing",
        "hyphenated": "cochran-school-of-nursing"
    },
    {
        "id": "190080",
        "name": "Colgate Rochester Crozer Divinity School",
        "hyphenated": "colgate-rochester-crozer-divinity-school"
    },
    {
        "id": "190114",
        "name": "Metropolitan College of New York",
        "hyphenated": "metropolitan-college-of-new-york"
    },
    {
        "id": "190169",
        "name": "Columbia-Greene Community College",
        "hyphenated": "columbia-greene-community-college"
    },
    {
        "id": "190239",
        "name": "Empire Beauty School-Queens",
        "hyphenated": "empire-beauty-school-queens"
    },
    {
        "id": "190318",
        "name": "Continental School of Beauty Culture-Rochester",
        "hyphenated": "continental-school-of-beauty-culture-rochester"
    },
    {
        "id": "190372",
        "name": "The Cooper Union for the Advancement of Science and Art",
        "hyphenated": "the-cooper-union-for-the-advancement-of-science-and-art"
    },
    {
        "id": "190424",
        "name": "Weill Medical College of Cornell University",
        "hyphenated": "weill-medical-college-of-cornell-university"
    },
    {
        "id": "190442",
        "name": "SUNY Corning Community College",
        "hyphenated": "suny-corning-community-college"
    },
    {
        "id": "190451",
        "name": "Pomeroy College of Nursing at Crouse Hospital",
        "hyphenated": "pomeroy-college-of-nursing-at-crouse-hospital"
    },
    {
        "id": "190503",
        "name": "Culinary Institute of America",
        "hyphenated": "culinary-institute-of-america"
    },
    {
        "id": "190512",
        "name": "CUNY Bernard M Baruch College",
        "hyphenated": "cuny-bernard-m-baruch-college"
    },
    {
        "id": "190521",
        "name": "CUNY Borough of Manhattan Community College",
        "hyphenated": "cuny-borough-of-manhattan-community-college"
    },
    {
        "id": "190530",
        "name": "CUNY Bronx Community College",
        "hyphenated": "cuny-bronx-community-college"
    },
    {
        "id": "190549",
        "name": "CUNY Brooklyn College",
        "hyphenated": "cuny-brooklyn-college"
    },
    {
        "id": "190558",
        "name": "College of Staten Island CUNY",
        "hyphenated": "college-of-staten-island-cuny"
    },
    {
        "id": "190567",
        "name": "CUNY City College",
        "hyphenated": "cuny-city-college"
    },
    {
        "id": "190576",
        "name": "CUNY Graduate School and University Center",
        "hyphenated": "cuny-graduate-school-and-university-center"
    },
    {
        "id": "190585",
        "name": "CUNY Hostos Community College",
        "hyphenated": "cuny-hostos-community-college"
    },
    {
        "id": "190594",
        "name": "CUNY Hunter College",
        "hyphenated": "cuny-hunter-college"
    },
    {
        "id": "190600",
        "name": "CUNY John Jay College of Criminal Justice",
        "hyphenated": "cuny-john-jay-college-of-criminal-justice"
    },
    {
        "id": "190619",
        "name": "CUNY Kingsborough Community College",
        "hyphenated": "cuny-kingsborough-community-college"
    },
    {
        "id": "190628",
        "name": "CUNY LaGuardia Community College",
        "hyphenated": "cuny-laguardia-community-college"
    },
    {
        "id": "190637",
        "name": "CUNY Lehman College",
        "hyphenated": "cuny-lehman-college"
    },
    {
        "id": "190646",
        "name": "CUNY Medgar Evers College",
        "hyphenated": "cuny-medgar-evers-college"
    },
    {
        "id": "190655",
        "name": "CUNY New York City College of Technology",
        "hyphenated": "cuny-new-york-city-college-of-technology"
    },
    {
        "id": "190664",
        "name": "CUNY Queens College",
        "hyphenated": "cuny-queens-college"
    },
    {
        "id": "190673",
        "name": "CUNY Queensborough Community College",
        "hyphenated": "cuny-queensborough-community-college"
    },
    {
        "id": "190682",
        "name": "CUNY School of Law",
        "hyphenated": "cuny-school-of-law"
    },
    {
        "id": "190691",
        "name": "CUNY York College",
        "hyphenated": "cuny-york-college"
    },
    {
        "id": "190707",
        "name": "CVPH Medical Center School of Radiologic Technology",
        "hyphenated": "cvph-medical-center-school-of-radiologic-technology"
    },
    {
        "id": "190716",
        "name": "D'Youville University",
        "hyphenated": "d'youville-university"
    },
    {
        "id": "190725",
        "name": "Daemen University",
        "hyphenated": "daemen-university"
    },
    {
        "id": "190752",
        "name": "Yeshiva of Far Rockaway Derech Ayson Rabbinical Seminary",
        "hyphenated": "yeshiva-of-far-rockaway-derech-ayson-rabbinical-seminary"
    },
    {
        "id": "190761",
        "name": "Dominican University New York",
        "hyphenated": "dominican-university-new-york"
    },
    {
        "id": "190840",
        "name": "Dutchess Community College",
        "hyphenated": "dutchess-community-college"
    },
    {
        "id": "190956",
        "name": "Belanger School of Nursing",
        "hyphenated": "belanger-school-of-nursing"
    },
    {
        "id": "190983",
        "name": "Elmira College",
        "hyphenated": "elmira-college"
    },
    {
        "id": "191083",
        "name": "Erie Community College",
        "hyphenated": "erie-community-college"
    },
    {
        "id": "191126",
        "name": "Fashion Institute of Technology",
        "hyphenated": "fashion-institute-of-technology"
    },
    {
        "id": "191199",
        "name": "Finger Lakes Community College",
        "hyphenated": "finger-lakes-community-college"
    },
    {
        "id": "191205",
        "name": "Five Towns College",
        "hyphenated": "five-towns-college"
    },
    {
        "id": "191302",
        "name": "Fulton-Montgomery Community College",
        "hyphenated": "fulton-montgomery-community-college"
    },
    {
        "id": "191311",
        "name": "Gemological Institute of America-New York",
        "hyphenated": "gemological-institute-of-america-new-york"
    },
    {
        "id": "191320",
        "name": "The General Theological Seminary",
        "hyphenated": "the-general-theological-seminary"
    },
    {
        "id": "191339",
        "name": "Genesee Community College",
        "hyphenated": "genesee-community-college"
    },
    {
        "id": "191533",
        "name": "Hartwick College",
        "hyphenated": "hartwick-college"
    },
    {
        "id": "191597",
        "name": "Helene Fuld College of Nursing",
        "hyphenated": "helene-fuld-college-of-nursing"
    },
    {
        "id": "191612",
        "name": "Herkimer County Community College",
        "hyphenated": "herkimer-county-community-college"
    },
    {
        "id": "191621",
        "name": "Hilbert College",
        "hyphenated": "hilbert-college"
    },
    {
        "id": "191630",
        "name": "Hobart William Smith Colleges",
        "hyphenated": "hobart-william-smith-colleges"
    },
    {
        "id": "191649",
        "name": "Hofstra University",
        "hyphenated": "hofstra-university"
    },
    {
        "id": "191676",
        "name": "Houghton University",
        "hyphenated": "houghton-university"
    },
    {
        "id": "191719",
        "name": "Hudson Valley Community College",
        "hyphenated": "hudson-valley-community-college"
    },
    {
        "id": "191728",
        "name": "Hunter Business School",
        "hyphenated": "hunter-business-school"
    },
    {
        "id": "191931",
        "name": "Iona University",
        "hyphenated": "iona-university"
    },
    {
        "id": "191940",
        "name": "Isabella Graham Hart School of Practical Nursing",
        "hyphenated": "isabella-graham-hart-school-of-practical-nursing"
    },
    {
        "id": "191959",
        "name": "Island Drafting and Technical Institute",
        "hyphenated": "island-drafting-and-technical-institute"
    },
    {
        "id": "191968",
        "name": "Ithaca College",
        "hyphenated": "ithaca-college"
    },
    {
        "id": "191986",
        "name": "Jamestown Community College",
        "hyphenated": "jamestown-community-college"
    },
    {
        "id": "192004",
        "name": "Jamestown Business College",
        "hyphenated": "jamestown-business-college"
    },
    {
        "id": "192022",
        "name": "Jefferson Community College",
        "hyphenated": "jefferson-community-college"
    },
    {
        "id": "192040",
        "name": "Jewish Theological Seminary of America",
        "hyphenated": "jewish-theological-seminary-of-america"
    },
    {
        "id": "192110",
        "name": "The Juilliard School",
        "hyphenated": "the-juilliard-school"
    },
    {
        "id": "192165",
        "name": "Kehilath Yakov Rabbinical Seminary",
        "hyphenated": "kehilath-yakov-rabbinical-seminary"
    },
    {
        "id": "192192",
        "name": "Keuka College",
        "hyphenated": "keuka-college"
    },
    {
        "id": "192271",
        "name": "LIM College",
        "hyphenated": "lim-college"
    },
    {
        "id": "192323",
        "name": "Le Moyne College",
        "hyphenated": "le-moyne-college"
    },
    {
        "id": "192448",
        "name": "Long Island University",
        "hyphenated": "long-island-university"
    },
    {
        "id": "192484",
        "name": "Long Island Beauty School-Hempstead",
        "hyphenated": "long-island-beauty-school-hempstead"
    },
    {
        "id": "192509",
        "name": "Long Island Business Institute",
        "hyphenated": "long-island-business-institute"
    },
    {
        "id": "192624",
        "name": "Machzikei Hadath Rabbinical College",
        "hyphenated": "machzikei-hadath-rabbinical-college"
    },
    {
        "id": "192688",
        "name": "Mandl School-The College of Allied Health",
        "hyphenated": "mandl-school-the-college-of-allied-health"
    },
    {
        "id": "192703",
        "name": "Manhattan College",
        "hyphenated": "manhattan-college"
    },
    {
        "id": "192712",
        "name": "Manhattan School of Music",
        "hyphenated": "manhattan-school-of-music"
    },
    {
        "id": "192749",
        "name": "Manhattanville College",
        "hyphenated": "manhattanville-college"
    },
    {
        "id": "192785",
        "name": "Maria College of Albany",
        "hyphenated": "maria-college-of-albany"
    },
    {
        "id": "192800",
        "name": "Marion S Whelan School of Nursing of Geneva General Hospital",
        "hyphenated": "marion-s-whelan-school-of-nursing-of-geneva-general-hospital"
    },
    {
        "id": "192819",
        "name": "Marist College",
        "hyphenated": "marist-college"
    },
    {
        "id": "192864",
        "name": "Marymount Manhattan College",
        "hyphenated": "marymount-manhattan-college"
    },
    {
        "id": "192925",
        "name": "Medaille University",
        "hyphenated": "medaille-university"
    },
    {
        "id": "192961",
        "name": "St. Peter's Hospital College of Nursing",
        "hyphenated": "st.-peter's-hospital-college-of-nursing"
    },
    {
        "id": "192970",
        "name": "Memorial Hospital School of Radiation Therapy Technology",
        "hyphenated": "memorial-hospital-school-of-radiation-therapy-technology"
    },
    {
        "id": "193016",
        "name": "Mercy College",
        "hyphenated": "mercy-college"
    },
    {
        "id": "193052",
        "name": "Mesivta Torah Vodaath Rabbinical Seminary",
        "hyphenated": "mesivta-torah-vodaath-rabbinical-seminary"
    },
    {
        "id": "193061",
        "name": "Mesivta of Eastern Parkway-Yeshiva Zichron Meilech",
        "hyphenated": "mesivta-of-eastern-parkway-yeshiva-zichron-meilech"
    },
    {
        "id": "193070",
        "name": "Mesivtha Tifereth Jerusalem of America",
        "hyphenated": "mesivtha-tifereth-jerusalem-of-america"
    },
    {
        "id": "193186",
        "name": "Midway Paris Beauty School",
        "hyphenated": "midway-paris-beauty-school"
    },
    {
        "id": "193201",
        "name": "Mildred Elley School-Albany Campus",
        "hyphenated": "mildred-elley-school-albany-campus"
    },
    {
        "id": "193247",
        "name": "Mirrer Yeshiva Cent Institute",
        "hyphenated": "mirrer-yeshiva-cent-institute"
    },
    {
        "id": "193265",
        "name": "Modern Welding School",
        "hyphenated": "modern-welding-school"
    },
    {
        "id": "193283",
        "name": "Mohawk Valley Community College",
        "hyphenated": "mohawk-valley-community-college"
    },
    {
        "id": "193292",
        "name": "Molloy College",
        "hyphenated": "molloy-college"
    },
    {
        "id": "193308",
        "name": "Monroe College",
        "hyphenated": "monroe-college"
    },
    {
        "id": "193326",
        "name": "Monroe Community College",
        "hyphenated": "monroe-community-college"
    },
    {
        "id": "193353",
        "name": "Mount Saint Mary College",
        "hyphenated": "mount-saint-mary-college"
    },
    {
        "id": "193380",
        "name": "Montefiore School of Nursing",
        "hyphenated": "montefiore-school-of-nursing"
    },
    {
        "id": "193399",
        "name": "College of Mount Saint Vincent",
        "hyphenated": "college-of-mount-saint-vincent"
    },
    {
        "id": "193405",
        "name": "Icahn School of Medicine at Mount Sinai",
        "hyphenated": "icahn-school-of-medicine-at-mount-sinai"
    },
    {
        "id": "193478",
        "name": "Nassau Community College",
        "hyphenated": "nassau-community-college"
    },
    {
        "id": "193584",
        "name": "Nazareth College",
        "hyphenated": "nazareth-college"
    },
    {
        "id": "193609",
        "name": "Neighborhood Playhouse School of the Theater",
        "hyphenated": "neighborhood-playhouse-school-of-the-theater"
    },
    {
        "id": "193672",
        "name": "The New School Center for Media",
        "hyphenated": "the-new-school-center-for-media"
    },
    {
        "id": "193751",
        "name": "Northeast College of Health Sciences",
        "hyphenated": "northeast-college-of-health-sciences"
    },
    {
        "id": "193821",
        "name": "New York Law School",
        "hyphenated": "new-york-law-school"
    },
    {
        "id": "193830",
        "name": "New York Medical College",
        "hyphenated": "new-york-medical-college"
    },
    {
        "id": "193858",
        "name": "New York School for Medical and Dental Assistants",
        "hyphenated": "new-york-school-for-medical-and-dental-assistants"
    },
    {
        "id": "193894",
        "name": "New York Theological Seminary",
        "hyphenated": "new-york-theological-seminary"
    },
    {
        "id": "193946",
        "name": "Niagara County Community College",
        "hyphenated": "niagara-county-community-college"
    },
    {
        "id": "193973",
        "name": "Niagara University",
        "hyphenated": "niagara-university"
    },
    {
        "id": "193991",
        "name": "Empire Beauty School-Peekskill",
        "hyphenated": "empire-beauty-school-peekskill"
    },
    {
        "id": "194028",
        "name": "North Country Community College",
        "hyphenated": "north-country-community-college"
    },
    {
        "id": "194073",
        "name": "New York College of Podiatric Medicine",
        "hyphenated": "new-york-college-of-podiatric-medicine"
    },
    {
        "id": "194091",
        "name": "New York Institute of Technology",
        "hyphenated": "new-york-institute-of-technology"
    },
    {
        "id": "194116",
        "name": "New York School of Interior Design",
        "hyphenated": "new-york-school-of-interior-design"
    },
    {
        "id": "194161",
        "name": "Alliance University",
        "hyphenated": "alliance-university"
    },
    {
        "id": "194189",
        "name": "Ohr Hameir Theological Seminary",
        "hyphenated": "ohr-hameir-theological-seminary"
    },
    {
        "id": "194222",
        "name": "Onondaga Community College",
        "hyphenated": "onondaga-community-college"
    },
    {
        "id": "194240",
        "name": "Orange County Community College",
        "hyphenated": "orange-county-community-college"
    },
    {
        "id": "194259",
        "name": "Otsego Area BOCES-Practical Nursing Program",
        "hyphenated": "otsego-area-boces-practical-nursing-program"
    },
    {
        "id": "194310",
        "name": "Pace University",
        "hyphenated": "pace-university"
    },
    {
        "id": "194392",
        "name": "Paul Smiths College of Arts and Science",
        "hyphenated": "paul-smiths-college-of-arts-and-science"
    },
    {
        "id": "194435",
        "name": "Continental School of Beauty Culture-West Seneca",
        "hyphenated": "continental-school-of-beauty-culture-west-seneca"
    },
    {
        "id": "194499",
        "name": "Plaza College",
        "hyphenated": "plaza-college"
    },
    {
        "id": "194569",
        "name": "Davis College",
        "hyphenated": "davis-college"
    },
    {
        "id": "194578",
        "name": "Pratt Institute-Main",
        "hyphenated": "pratt-institute-main"
    },
    {
        "id": "194657",
        "name": "Rabbinical Academy Mesivta Rabbi Chaim Berlin",
        "hyphenated": "rabbinical-academy-mesivta-rabbi-chaim-berlin"
    },
    {
        "id": "194666",
        "name": "Rabbinical College Bobover Yeshiva Bnei Zion",
        "hyphenated": "rabbinical-college-bobover-yeshiva-bnei-zion"
    },
    {
        "id": "194693",
        "name": "Rabbinical College Beth Shraga",
        "hyphenated": "rabbinical-college-beth-shraga"
    },
    {
        "id": "194718",
        "name": "Rabbinical Seminary Mkor Chaim",
        "hyphenated": "rabbinical-seminary-mkor-chaim"
    },
    {
        "id": "194736",
        "name": "Rabbinical College of Long Island",
        "hyphenated": "rabbinical-college-of-long-island"
    },
    {
        "id": "194763",
        "name": "Rabbinical Seminary of America",
        "hyphenated": "rabbinical-seminary-of-america"
    },
    {
        "id": "194958",
        "name": "Roberts Wesleyan University",
        "hyphenated": "roberts-wesleyan-university"
    },
    {
        "id": "195049",
        "name": "The Rockefeller University",
        "hyphenated": "the-rockefeller-university"
    },
    {
        "id": "195058",
        "name": "Rockland Community College",
        "hyphenated": "rockland-community-college"
    },
    {
        "id": "195067",
        "name": "Empire Beauty School-Manhattan",
        "hyphenated": "empire-beauty-school-manhattan"
    },
    {
        "id": "195128",
        "name": "Russell Sage College",
        "hyphenated": "russell-sage-college"
    },
    {
        "id": "195155",
        "name": "St Bernard's School of Theology and Ministry",
        "hyphenated": "st-bernard's-school-of-theology-and-ministry"
    },
    {
        "id": "195164",
        "name": "St Bonaventure University",
        "hyphenated": "st-bonaventure-university"
    },
    {
        "id": "195173",
        "name": "St. Francis College",
        "hyphenated": "st.-francis-college"
    },
    {
        "id": "195191",
        "name": "St. Joseph's College of Nursing",
        "hyphenated": "st.-joseph's-college-of-nursing"
    },
    {
        "id": "195216",
        "name": "St Lawrence University",
        "hyphenated": "st-lawrence-university"
    },
    {
        "id": "195234",
        "name": "The College of Saint Rose",
        "hyphenated": "the-college-of-saint-rose"
    },
    {
        "id": "195243",
        "name": "St. Thomas Aquinas College",
        "hyphenated": "st.-thomas-aquinas-college"
    },
    {
        "id": "195289",
        "name": "Samaritan Hospital School of Nursing",
        "hyphenated": "samaritan-hospital-school-of-nursing"
    },
    {
        "id": "195304",
        "name": "Sarah Lawrence College",
        "hyphenated": "sarah-lawrence-college"
    },
    {
        "id": "195322",
        "name": "Schenectady County Community College",
        "hyphenated": "schenectady-county-community-college"
    },
    {
        "id": "195438",
        "name": "Sh'or Yoshuv Rabbinical College",
        "hyphenated": "sh'or-yoshuv-rabbinical-college"
    },
    {
        "id": "195474",
        "name": "Siena College",
        "hyphenated": "siena-college"
    },
    {
        "id": "195526",
        "name": "Skidmore College",
        "hyphenated": "skidmore-college"
    },
    {
        "id": "195544",
        "name": "St. Joseph's University-New York",
        "hyphenated": "st.-joseph's-university-new-york"
    },
    {
        "id": "195580",
        "name": "Saint Vladimirs Orthodox Theological Seminary",
        "hyphenated": "saint-vladimirs-orthodox-theological-seminary"
    },
    {
        "id": "195702",
        "name": "Saint Elizabeth College of Nursing",
        "hyphenated": "saint-elizabeth-college-of-nursing"
    },
    {
        "id": "195720",
        "name": "St. John Fisher University",
        "hyphenated": "st.-john-fisher-university"
    },
    {
        "id": "195784",
        "name": "St Paul's School of Nursing-Staten Island",
        "hyphenated": "st-paul's-school-of-nursing-staten-island"
    },
    {
        "id": "195809",
        "name": "St. John's University-New York",
        "hyphenated": "st.-john's-university-new-york"
    },
    {
        "id": "195988",
        "name": "Sullivan County Community College",
        "hyphenated": "sullivan-county-community-college"
    },
    {
        "id": "196006",
        "name": "SUNY College of Technology at Alfred",
        "hyphenated": "suny-college-of-technology-at-alfred"
    },
    {
        "id": "196015",
        "name": "SUNY College of Technology at Canton",
        "hyphenated": "suny-college-of-technology-at-canton"
    },
    {
        "id": "196024",
        "name": "SUNY College of Technology at Delhi",
        "hyphenated": "suny-college-of-technology-at-delhi"
    },
    {
        "id": "196033",
        "name": "SUNY College of Agriculture and Technology at Cobleskill",
        "hyphenated": "suny-college-of-agriculture-and-technology-at-cobleskill"
    },
    {
        "id": "196042",
        "name": "Farmingdale State College",
        "hyphenated": "farmingdale-state-college"
    },
    {
        "id": "196051",
        "name": "SUNY Morrisville",
        "hyphenated": "suny-morrisville"
    },
    {
        "id": "196060",
        "name": "SUNY at Albany",
        "hyphenated": "suny-at-albany"
    },
    {
        "id": "196112",
        "name": "SUNY Polytechnic Institute",
        "hyphenated": "suny-polytechnic-institute"
    },
    {
        "id": "196121",
        "name": "SUNY Brockport",
        "hyphenated": "suny-brockport"
    },
    {
        "id": "196130",
        "name": "SUNY Buffalo State University",
        "hyphenated": "suny-buffalo-state-university"
    },
    {
        "id": "196149",
        "name": "SUNY Cortland",
        "hyphenated": "suny-cortland"
    },
    {
        "id": "196158",
        "name": "SUNY at Fredonia",
        "hyphenated": "suny-at-fredonia"
    },
    {
        "id": "196167",
        "name": "SUNY College at Geneseo",
        "hyphenated": "suny-college-at-geneseo"
    },
    {
        "id": "196176",
        "name": "State University of New York at New Paltz",
        "hyphenated": "state-university-of-new-york-at-new-paltz"
    },
    {
        "id": "196185",
        "name": "SUNY Oneonta",
        "hyphenated": "suny-oneonta"
    },
    {
        "id": "196194",
        "name": "State University of New York at Oswego",
        "hyphenated": "state-university-of-new-york-at-oswego"
    },
    {
        "id": "196200",
        "name": "SUNY College at Potsdam",
        "hyphenated": "suny-college-at-potsdam"
    },
    {
        "id": "196219",
        "name": "SUNY at Purchase College",
        "hyphenated": "suny-at-purchase-college"
    },
    {
        "id": "196228",
        "name": "SUNY College of Optometry",
        "hyphenated": "suny-college-of-optometry"
    },
    {
        "id": "196237",
        "name": "SUNY College at Old Westbury",
        "hyphenated": "suny-college-at-old-westbury"
    },
    {
        "id": "196246",
        "name": "SUNY College at Plattsburgh",
        "hyphenated": "suny-college-at-plattsburgh"
    },
    {
        "id": "196255",
        "name": "SUNY Downstate Health Sciences University",
        "hyphenated": "suny-downstate-health-sciences-university"
    },
    {
        "id": "196264",
        "name": "SUNY Empire State College",
        "hyphenated": "suny-empire-state-college"
    },
    {
        "id": "196291",
        "name": "SUNY Maritime College",
        "hyphenated": "suny-maritime-college"
    },
    {
        "id": "196307",
        "name": "Upstate Medical University",
        "hyphenated": "upstate-medical-university"
    },
    {
        "id": "196389",
        "name": "Swedish Institute a College of Health Sciences",
        "hyphenated": "swedish-institute-a-college-of-health-sciences"
    },
    {
        "id": "196431",
        "name": "Talmudical Seminary Oholei Torah",
        "hyphenated": "talmudical-seminary-oholei-torah"
    },
    {
        "id": "196440",
        "name": "Talmudical Institute of Upstate New York",
        "hyphenated": "talmudical-institute-of-upstate-new-york"
    },
    {
        "id": "196468",
        "name": "Teachers College at Columbia University",
        "hyphenated": "teachers-college-at-columbia-university"
    },
    {
        "id": "196565",
        "name": "Tompkins Cortland Community College",
        "hyphenated": "tompkins-cortland-community-college"
    },
    {
        "id": "196583",
        "name": "Torah Temimah Talmudical Seminary",
        "hyphenated": "torah-temimah-talmudical-seminary"
    },
    {
        "id": "196592",
        "name": "Touro University",
        "hyphenated": "touro-university"
    },
    {
        "id": "196653",
        "name": "Trocaire College",
        "hyphenated": "trocaire-college"
    },
    {
        "id": "196680",
        "name": "Excelsior University",
        "hyphenated": "excelsior-university"
    },
    {
        "id": "196699",
        "name": "Ulster County Community College",
        "hyphenated": "ulster-county-community-college"
    },
    {
        "id": "196866",
        "name": "Union College",
        "hyphenated": "union-college"
    },
    {
        "id": "196884",
        "name": "Union Theological Seminary in the City of New York",
        "hyphenated": "union-theological-seminary-in-the-city-of-new-york"
    },
    {
        "id": "197018",
        "name": "United Talmudical Seminary",
        "hyphenated": "united-talmudical-seminary"
    },
    {
        "id": "197027",
        "name": "United States Merchant Marine Academy",
        "hyphenated": "united-states-merchant-marine-academy"
    },
    {
        "id": "197045",
        "name": "Utica University",
        "hyphenated": "utica-university"
    },
    {
        "id": "197142",
        "name": "Villa Maria College",
        "hyphenated": "villa-maria-college"
    },
    {
        "id": "197151",
        "name": "School of Visual Arts",
        "hyphenated": "school-of-visual-arts"
    },
    {
        "id": "197197",
        "name": "Wagner College",
        "hyphenated": "wagner-college"
    },
    {
        "id": "197221",
        "name": "Webb Institute",
        "hyphenated": "webb-institute"
    },
    {
        "id": "197230",
        "name": "Wells College",
        "hyphenated": "wells-college"
    },
    {
        "id": "197285",
        "name": "The College of Westchester",
        "hyphenated": "the-college-of-westchester"
    },
    {
        "id": "197294",
        "name": "SUNY Westchester Community College",
        "hyphenated": "suny-westchester-community-college"
    },
    {
        "id": "197337",
        "name": "Westchester School of Beauty Culture",
        "hyphenated": "westchester-school-of-beauty-culture"
    },
    {
        "id": "197531",
        "name": "Word of Life Bible Institute",
        "hyphenated": "word-of-life-bible-institute"
    },
    {
        "id": "197601",
        "name": "Yeshiva Karlin Stolin",
        "hyphenated": "yeshiva-karlin-stolin"
    },
    {
        "id": "197647",
        "name": "Yeshiva Derech Chaim",
        "hyphenated": "yeshiva-derech-chaim"
    },
    {
        "id": "197674",
        "name": "Yeshiva of Nitra Rabbinical College",
        "hyphenated": "yeshiva-of-nitra-rabbinical-college"
    },
    {
        "id": "197692",
        "name": "Yeshivath Shaar Hatorah",
        "hyphenated": "yeshivath-shaar-hatorah"
    },
    {
        "id": "197735",
        "name": "Yeshivath Viznitz",
        "hyphenated": "yeshivath-viznitz"
    },
    {
        "id": "197744",
        "name": "Yeshivath Zichron Moshe",
        "hyphenated": "yeshivath-zichron-moshe"
    },
    {
        "id": "197814",
        "name": "College of the Albemarle",
        "hyphenated": "college-of-the-albemarle"
    },
    {
        "id": "197850",
        "name": "South Piedmont Community College",
        "hyphenated": "south-piedmont-community-college"
    },
    {
        "id": "197869",
        "name": "Appalachian State University",
        "hyphenated": "appalachian-state-university"
    },
    {
        "id": "197887",
        "name": "Asheville-Buncombe Technical Community College",
        "hyphenated": "asheville-buncombe-technical-community-college"
    },
    {
        "id": "197911",
        "name": "Barton College",
        "hyphenated": "barton-college"
    },
    {
        "id": "197966",
        "name": "Beaufort County Community College",
        "hyphenated": "beaufort-county-community-college"
    },
    {
        "id": "197984",
        "name": "Belmont Abbey College",
        "hyphenated": "belmont-abbey-college"
    },
    {
        "id": "197993",
        "name": "Bennett College",
        "hyphenated": "bennett-college"
    },
    {
        "id": "198011",
        "name": "Bladen Community College",
        "hyphenated": "bladen-community-college"
    },
    {
        "id": "198039",
        "name": "Blue Ridge Community College",
        "hyphenated": "blue-ridge-community-college"
    },
    {
        "id": "198066",
        "name": "Brevard College",
        "hyphenated": "brevard-college"
    },
    {
        "id": "198084",
        "name": "Brunswick Community College",
        "hyphenated": "brunswick-community-college"
    },
    {
        "id": "198109",
        "name": "Cabarrus College of Health Sciences",
        "hyphenated": "cabarrus-college-of-health-sciences"
    },
    {
        "id": "198118",
        "name": "Caldwell Community College and Technical Institute",
        "hyphenated": "caldwell-community-college-and-technical-institute"
    },
    {
        "id": "198136",
        "name": "Campbell University",
        "hyphenated": "campbell-university"
    },
    {
        "id": "198154",
        "name": "Cape Fear Community College",
        "hyphenated": "cape-fear-community-college"
    },
    {
        "id": "198206",
        "name": "Carteret Community College",
        "hyphenated": "carteret-community-college"
    },
    {
        "id": "198215",
        "name": "Catawba College",
        "hyphenated": "catawba-college"
    },
    {
        "id": "198233",
        "name": "Catawba Valley Community College",
        "hyphenated": "catawba-valley-community-college"
    },
    {
        "id": "198251",
        "name": "Central Carolina Community College",
        "hyphenated": "central-carolina-community-college"
    },
    {
        "id": "198260",
        "name": "Central Piedmont Community College",
        "hyphenated": "central-piedmont-community-college"
    },
    {
        "id": "198303",
        "name": "Chowan University",
        "hyphenated": "chowan-university"
    },
    {
        "id": "198321",
        "name": "Cleveland Community College",
        "hyphenated": "cleveland-community-college"
    },
    {
        "id": "198330",
        "name": "Coastal Carolina Community College",
        "hyphenated": "coastal-carolina-community-college"
    },
    {
        "id": "198367",
        "name": "Craven Community College",
        "hyphenated": "craven-community-college"
    },
    {
        "id": "198376",
        "name": "Davidson-Davie Community College",
        "hyphenated": "davidson-davie-community-college"
    },
    {
        "id": "198455",
        "name": "Durham Technical Community College",
        "hyphenated": "durham-technical-community-college"
    },
    {
        "id": "198464",
        "name": "East Carolina University",
        "hyphenated": "east-carolina-university"
    },
    {
        "id": "198491",
        "name": "Edgecombe Community College",
        "hyphenated": "edgecombe-community-college"
    },
    {
        "id": "198507",
        "name": "Elizabeth City State University",
        "hyphenated": "elizabeth-city-state-university"
    },
    {
        "id": "198534",
        "name": "Fayetteville Technical Community College",
        "hyphenated": "fayetteville-technical-community-college"
    },
    {
        "id": "198543",
        "name": "Fayetteville State University",
        "hyphenated": "fayetteville-state-university"
    },
    {
        "id": "198552",
        "name": "Forsyth Technical Community College",
        "hyphenated": "forsyth-technical-community-college"
    },
    {
        "id": "198561",
        "name": "Gardner-Webb University",
        "hyphenated": "gardner-webb-university"
    },
    {
        "id": "198570",
        "name": "Gaston College",
        "hyphenated": "gaston-college"
    },
    {
        "id": "198598",
        "name": "Greensboro College",
        "hyphenated": "greensboro-college"
    },
    {
        "id": "198613",
        "name": "Guilford College",
        "hyphenated": "guilford-college"
    },
    {
        "id": "198622",
        "name": "Guilford Technical Community College",
        "hyphenated": "guilford-technical-community-college"
    },
    {
        "id": "198640",
        "name": "Halifax Community College",
        "hyphenated": "halifax-community-college"
    },
    {
        "id": "198668",
        "name": "Haywood Community College",
        "hyphenated": "haywood-community-college"
    },
    {
        "id": "198677",
        "name": "Heritage Bible College",
        "hyphenated": "heritage-bible-college"
    },
    {
        "id": "198695",
        "name": "High Point University",
        "hyphenated": "high-point-university"
    },
    {
        "id": "198710",
        "name": "Isothermal Community College",
        "hyphenated": "isothermal-community-college"
    },
    {
        "id": "198729",
        "name": "James Sprunt Community College",
        "hyphenated": "james-sprunt-community-college"
    },
    {
        "id": "198756",
        "name": "Johnson C Smith University",
        "hyphenated": "johnson-c-smith-university"
    },
    {
        "id": "198774",
        "name": "Johnston Community College",
        "hyphenated": "johnston-community-college"
    },
    {
        "id": "198808",
        "name": "Lees-McRae College",
        "hyphenated": "lees-mcrae-college"
    },
    {
        "id": "198817",
        "name": "Lenoir Community College",
        "hyphenated": "lenoir-community-college"
    },
    {
        "id": "198835",
        "name": "Lenoir-Rhyne University",
        "hyphenated": "lenoir-rhyne-university"
    },
    {
        "id": "198844",
        "name": "Leons Beauty School Inc",
        "hyphenated": "leons-beauty-school-inc"
    },
    {
        "id": "198862",
        "name": "Livingstone College",
        "hyphenated": "livingstone-college"
    },
    {
        "id": "198871",
        "name": "Louisburg College",
        "hyphenated": "louisburg-college"
    },
    {
        "id": "198899",
        "name": "Mars Hill University",
        "hyphenated": "mars-hill-university"
    },
    {
        "id": "198905",
        "name": "Martin Community College",
        "hyphenated": "martin-community-college"
    },
    {
        "id": "198914",
        "name": "Mayland Community College",
        "hyphenated": "mayland-community-college"
    },
    {
        "id": "198923",
        "name": "McDowell Technical Community College",
        "hyphenated": "mcdowell-technical-community-college"
    },
    {
        "id": "198950",
        "name": "Meredith College",
        "hyphenated": "meredith-college"
    },
    {
        "id": "198969",
        "name": "Methodist University",
        "hyphenated": "methodist-university"
    },
    {
        "id": "198978",
        "name": "Miller-Motte College-Wilmington",
        "hyphenated": "miller-motte-college-wilmington"
    },
    {
        "id": "198987",
        "name": "Mitchell Community College",
        "hyphenated": "mitchell-community-college"
    },
    {
        "id": "199005",
        "name": "Mitchells Academy",
        "hyphenated": "mitchells-academy"
    },
    {
        "id": "199023",
        "name": "Montgomery Community College",
        "hyphenated": "montgomery-community-college"
    },
    {
        "id": "199032",
        "name": "Montreat College",
        "hyphenated": "montreat-college"
    },
    {
        "id": "199069",
        "name": "University of Mount Olive",
        "hyphenated": "university-of-mount-olive"
    },
    {
        "id": "199078",
        "name": "College of Wilmington",
        "hyphenated": "college-of-wilmington"
    },
    {
        "id": "199087",
        "name": "Nash Community College",
        "hyphenated": "nash-community-college"
    },
    {
        "id": "199102",
        "name": "North Carolina A & T State University",
        "hyphenated": "north-carolina-a-&-t-state-university"
    },
    {
        "id": "199111",
        "name": "University of North Carolina at Asheville",
        "hyphenated": "university-of-north-carolina-at-asheville"
    },
    {
        "id": "199139",
        "name": "University of North Carolina at Charlotte",
        "hyphenated": "university-of-north-carolina-at-charlotte"
    },
    {
        "id": "199148",
        "name": "University of North Carolina at Greensboro",
        "hyphenated": "university-of-north-carolina-at-greensboro"
    },
    {
        "id": "199157",
        "name": "North Carolina Central University",
        "hyphenated": "north-carolina-central-university"
    },
    {
        "id": "199184",
        "name": "University of North Carolina School of the Arts",
        "hyphenated": "university-of-north-carolina-school-of-the-arts"
    },
    {
        "id": "199209",
        "name": "North Carolina Wesleyan University",
        "hyphenated": "north-carolina-wesleyan-university"
    },
    {
        "id": "199218",
        "name": "University of North Carolina Wilmington",
        "hyphenated": "university-of-north-carolina-wilmington"
    },
    {
        "id": "199263",
        "name": "Pamlico Community College",
        "hyphenated": "pamlico-community-college"
    },
    {
        "id": "199272",
        "name": "William Peace University",
        "hyphenated": "william-peace-university"
    },
    {
        "id": "199281",
        "name": "University of North Carolina at Pembroke",
        "hyphenated": "university-of-north-carolina-at-pembroke"
    },
    {
        "id": "199306",
        "name": "Pfeiffer University",
        "hyphenated": "pfeiffer-university"
    },
    {
        "id": "199324",
        "name": "Piedmont Community College",
        "hyphenated": "piedmont-community-college"
    },
    {
        "id": "199333",
        "name": "Pitt Community College",
        "hyphenated": "pitt-community-college"
    },
    {
        "id": "199412",
        "name": "Queens University of Charlotte",
        "hyphenated": "queens-university-of-charlotte"
    },
    {
        "id": "199421",
        "name": "Randolph Community College",
        "hyphenated": "randolph-community-college"
    },
    {
        "id": "199449",
        "name": "Richmond Community College",
        "hyphenated": "richmond-community-college"
    },
    {
        "id": "199458",
        "name": "Mid-Atlantic Christian University",
        "hyphenated": "mid-atlantic-christian-university"
    },
    {
        "id": "199467",
        "name": "Roanoke-Chowan Community College",
        "hyphenated": "roanoke-chowan-community-college"
    },
    {
        "id": "199476",
        "name": "Robeson Community College",
        "hyphenated": "robeson-community-college"
    },
    {
        "id": "199485",
        "name": "Rockingham Community College",
        "hyphenated": "rockingham-community-college"
    },
    {
        "id": "199494",
        "name": "Rowan-Cabarrus Community College",
        "hyphenated": "rowan-cabarrus-community-college"
    },
    {
        "id": "199582",
        "name": "Saint Augustine's University",
        "hyphenated": "saint-augustine's-university"
    },
    {
        "id": "199607",
        "name": "Salem College",
        "hyphenated": "salem-college"
    },
    {
        "id": "199625",
        "name": "Sampson Community College",
        "hyphenated": "sampson-community-college"
    },
    {
        "id": "199634",
        "name": "Sandhills Community College",
        "hyphenated": "sandhills-community-college"
    },
    {
        "id": "199643",
        "name": "Shaw University",
        "hyphenated": "shaw-university"
    },
    {
        "id": "199689",
        "name": "Paul Mitchell the School-Fayetteville",
        "hyphenated": "paul-mitchell-the-school-fayetteville"
    },
    {
        "id": "199698",
        "name": "St. Andrews University",
        "hyphenated": "st.-andrews-university"
    },
    {
        "id": "199722",
        "name": "Southeastern Community College",
        "hyphenated": "southeastern-community-college"
    },
    {
        "id": "199731",
        "name": "Southwestern Community College",
        "hyphenated": "southwestern-community-college"
    },
    {
        "id": "199740",
        "name": "Stanly Community College",
        "hyphenated": "stanly-community-college"
    },
    {
        "id": "199759",
        "name": "Southeastern Baptist Theological Seminary",
        "hyphenated": "southeastern-baptist-theological-seminary"
    },
    {
        "id": "199768",
        "name": "Surry Community College",
        "hyphenated": "surry-community-college"
    },
    {
        "id": "199786",
        "name": "Alamance Community College",
        "hyphenated": "alamance-community-college"
    },
    {
        "id": "199795",
        "name": "Tri-County Community College",
        "hyphenated": "tri-county-community-college"
    },
    {
        "id": "199838",
        "name": "Vance-Granville Community College",
        "hyphenated": "vance-granville-community-college"
    },
    {
        "id": "199856",
        "name": "Wake Technical Community College",
        "hyphenated": "wake-technical-community-college"
    },
    {
        "id": "199865",
        "name": "Warren Wilson College",
        "hyphenated": "warren-wilson-college"
    },
    {
        "id": "199883",
        "name": "Watts School of Nursing",
        "hyphenated": "watts-school-of-nursing"
    },
    {
        "id": "199892",
        "name": "Wayne Community College",
        "hyphenated": "wayne-community-college"
    },
    {
        "id": "199908",
        "name": "Western Piedmont Community College",
        "hyphenated": "western-piedmont-community-college"
    },
    {
        "id": "199926",
        "name": "Wilkes Community College",
        "hyphenated": "wilkes-community-college"
    },
    {
        "id": "199953",
        "name": "Wilson Community College",
        "hyphenated": "wilson-community-college"
    },
    {
        "id": "199962",
        "name": "Wingate University",
        "hyphenated": "wingate-university"
    },
    {
        "id": "199971",
        "name": "Carolina Christian College",
        "hyphenated": "carolina-christian-college"
    },
    {
        "id": "199980",
        "name": "Winston Salem Barber School",
        "hyphenated": "winston-salem-barber-school"
    },
    {
        "id": "199999",
        "name": "Winston-Salem State University",
        "hyphenated": "winston-salem-state-university"
    },
    {
        "id": "200004",
        "name": "Western Carolina University",
        "hyphenated": "western-carolina-university"
    },
    {
        "id": "200013",
        "name": "Rasmussen University-North Dakota",
        "hyphenated": "rasmussen-university-north-dakota"
    },
    {
        "id": "200022",
        "name": "Bismarck State College",
        "hyphenated": "bismarck-state-college"
    },
    {
        "id": "200059",
        "name": "Dickinson State University",
        "hyphenated": "dickinson-state-university"
    },
    {
        "id": "200086",
        "name": "Nueta Hidatsa Sahnish College",
        "hyphenated": "nueta-hidatsa-sahnish-college"
    },
    {
        "id": "200110",
        "name": "Josef's School of Hair Skin & Body-Grand Forks",
        "hyphenated": "josef's-school-of-hair-skin-&-body-grand-forks"
    },
    {
        "id": "200156",
        "name": "University of Jamestown",
        "hyphenated": "university-of-jamestown"
    },
    {
        "id": "200192",
        "name": "Lake Region State College",
        "hyphenated": "lake-region-state-college"
    },
    {
        "id": "200208",
        "name": "Cankdeska Cikana Community College",
        "hyphenated": "cankdeska-cikana-community-college"
    },
    {
        "id": "200217",
        "name": "University of Mary",
        "hyphenated": "university-of-mary"
    },
    {
        "id": "200226",
        "name": "Mayville State University",
        "hyphenated": "mayville-state-university"
    },
    {
        "id": "200253",
        "name": "Minot State University",
        "hyphenated": "minot-state-university"
    },
    {
        "id": "200280",
        "name": "University of North Dakota",
        "hyphenated": "university-of-north-dakota"
    },
    {
        "id": "200305",
        "name": "North Dakota State College of Science",
        "hyphenated": "north-dakota-state-college-of-science"
    },
    {
        "id": "200314",
        "name": "Dakota College at Bottineau",
        "hyphenated": "dakota-college-at-bottineau"
    },
    {
        "id": "200332",
        "name": "North Dakota State University-Main Campus",
        "hyphenated": "north-dakota-state-university-main-campus"
    },
    {
        "id": "200341",
        "name": "Williston State College",
        "hyphenated": "williston-state-college"
    },
    {
        "id": "200466",
        "name": "Sitting Bull College",
        "hyphenated": "sitting-bull-college"
    },
    {
        "id": "200484",
        "name": "Trinity Bible College and Graduate School",
        "hyphenated": "trinity-bible-college-and-graduate-school"
    },
    {
        "id": "200527",
        "name": "Turtle Mountain Community College",
        "hyphenated": "turtle-mountain-community-college"
    },
    {
        "id": "200554",
        "name": "United Tribes Technical College",
        "hyphenated": "united-tribes-technical-college"
    },
    {
        "id": "200572",
        "name": "Valley City State University",
        "hyphenated": "valley-city-state-university"
    },
    {
        "id": "200590",
        "name": "ETI Technical College",
        "hyphenated": "eti-technical-college"
    },
    {
        "id": "200697",
        "name": "Air Force Institute of Technology-Graduate School of Engineering & Management",
        "hyphenated": "air-force-institute-of-technology-graduate-school-of-engineering-&-management"
    },
    {
        "id": "200785",
        "name": "Herzing University-Akron",
        "hyphenated": "herzing-university-akron"
    },
    {
        "id": "200800",
        "name": "University of Akron Main Campus",
        "hyphenated": "university-of-akron-main-campus"
    },
    {
        "id": "200846",
        "name": "University of Akron Wayne College",
        "hyphenated": "university-of-akron-wayne-college"
    },
    {
        "id": "200873",
        "name": "Allegheny Wesleyan College",
        "hyphenated": "allegheny-wesleyan-college"
    },
    {
        "id": "201034",
        "name": "Apollo Career Center",
        "hyphenated": "apollo-career-center"
    },
    {
        "id": "201061",
        "name": "Art Academy of Cincinnati",
        "hyphenated": "art-academy-of-cincinnati"
    },
    {
        "id": "201098",
        "name": "Ashland County-West Holmes Career Center",
        "hyphenated": "ashland-county-west-holmes-career-center"
    },
    {
        "id": "201104",
        "name": "Ashland University",
        "hyphenated": "ashland-university"
    },
    {
        "id": "201131",
        "name": "Ashtabula County Technical and Career Campus",
        "hyphenated": "ashtabula-county-technical-and-career-campus"
    },
    {
        "id": "201140",
        "name": "Athenaeum of Ohio",
        "hyphenated": "athenaeum-of-ohio"
    },
    {
        "id": "201159",
        "name": "Auburn Career Center",
        "hyphenated": "auburn-career-center"
    },
    {
        "id": "201177",
        "name": "Aultman College of Nursing and Health Sciences",
        "hyphenated": "aultman-college-of-nursing-and-health-sciences"
    },
    {
        "id": "201195",
        "name": "Baldwin Wallace University",
        "hyphenated": "baldwin-wallace-university"
    },
    {
        "id": "201283",
        "name": "Belmont College",
        "hyphenated": "belmont-college"
    },
    {
        "id": "201371",
        "name": "Bluffton University",
        "hyphenated": "bluffton-university"
    },
    {
        "id": "201432",
        "name": "Bowling Green State University-Firelands",
        "hyphenated": "bowling-green-state-university-firelands"
    },
    {
        "id": "201441",
        "name": "Bowling Green State University-Main Campus",
        "hyphenated": "bowling-green-state-university-main-campus"
    },
    {
        "id": "201469",
        "name": "Bryant & Stratton College-Parma",
        "hyphenated": "bryant-&-stratton-college-parma"
    },
    {
        "id": "201478",
        "name": "Buckeye Hills Career Center",
        "hyphenated": "buckeye-hills-career-center"
    },
    {
        "id": "201496",
        "name": "Butler Technology and Career Development Schools",
        "hyphenated": "butler-technology-and-career-development-schools"
    },
    {
        "id": "201539",
        "name": "Canton City Schools Adult Career and Technical Education",
        "hyphenated": "canton-city-schools-adult-career-and-technical-education"
    },
    {
        "id": "201548",
        "name": "Capital University",
        "hyphenated": "capital-university"
    },
    {
        "id": "201654",
        "name": "Cedarville University",
        "hyphenated": "cedarville-university"
    },
    {
        "id": "201672",
        "name": "Central Ohio Technical College",
        "hyphenated": "central-ohio-technical-college"
    },
    {
        "id": "201681",
        "name": "Central School of Practical Nursing",
        "hyphenated": "central-school-of-practical-nursing"
    },
    {
        "id": "201690",
        "name": "Central State University",
        "hyphenated": "central-state-university"
    },
    {
        "id": "201751",
        "name": "Chatfield College",
        "hyphenated": "chatfield-college"
    },
    {
        "id": "201803",
        "name": "Choffin Career and Technical Center",
        "hyphenated": "choffin-career-and-technical-center"
    },
    {
        "id": "201821",
        "name": "The Christ College of Nursing and Health Sciences",
        "hyphenated": "the-christ-college-of-nursing-and-health-sciences"
    },
    {
        "id": "201867",
        "name": "Cincinnati College of Mortuary Science",
        "hyphenated": "cincinnati-college-of-mortuary-science"
    },
    {
        "id": "201885",
        "name": "University of Cincinnati-Main Campus",
        "hyphenated": "university-of-cincinnati-main-campus"
    },
    {
        "id": "201928",
        "name": "Cincinnati State Technical and Community College",
        "hyphenated": "cincinnati-state-technical-and-community-college"
    },
    {
        "id": "201946",
        "name": "University of Cincinnati-Clermont College",
        "hyphenated": "university-of-cincinnati-clermont-college"
    },
    {
        "id": "201955",
        "name": "University of Cincinnati-Blue Ash College",
        "hyphenated": "university-of-cincinnati-blue-ash-college"
    },
    {
        "id": "201964",
        "name": "Ohio Christian University",
        "hyphenated": "ohio-christian-university"
    },
    {
        "id": "201973",
        "name": "Clark State College",
        "hyphenated": "clark-state-college"
    },
    {
        "id": "202046",
        "name": "Cleveland Institute of Art",
        "hyphenated": "cleveland-institute-of-art"
    },
    {
        "id": "202073",
        "name": "Cleveland Institute of Music",
        "hyphenated": "cleveland-institute-of-music"
    },
    {
        "id": "202134",
        "name": "Cleveland State University",
        "hyphenated": "cleveland-state-university"
    },
    {
        "id": "202152",
        "name": "Columbiana County Career and Technical Center",
        "hyphenated": "columbiana-county-career-and-technical-center"
    },
    {
        "id": "202170",
        "name": "Columbus College of Art and Design",
        "hyphenated": "columbus-college-of-art-and-design"
    },
    {
        "id": "202222",
        "name": "Columbus State Community College",
        "hyphenated": "columbus-state-community-college"
    },
    {
        "id": "202356",
        "name": "Cuyahoga Community College District",
        "hyphenated": "cuyahoga-community-college-district"
    },
    {
        "id": "202435",
        "name": "Davis College",
        "hyphenated": "davis-college"
    },
    {
        "id": "202453",
        "name": "Dayton Barber College",
        "hyphenated": "dayton-barber-college"
    },
    {
        "id": "202514",
        "name": "Defiance College",
        "hyphenated": "defiance-college"
    },
    {
        "id": "202523",
        "name": "Denison University",
        "hyphenated": "denison-university"
    },
    {
        "id": "202648",
        "name": "Edison State Community College",
        "hyphenated": "edison-state-community-college"
    },
    {
        "id": "202657",
        "name": "EHOVE Career Center",
        "hyphenated": "ehove-career-center"
    },
    {
        "id": "202763",
        "name": "The University of Findlay",
        "hyphenated": "the-university-of-findlay"
    },
    {
        "id": "202806",
        "name": "Franklin University",
        "hyphenated": "franklin-university"
    },
    {
        "id": "202888",
        "name": "Gerbers Akron Beauty School",
        "hyphenated": "gerbers-akron-beauty-school"
    },
    {
        "id": "202903",
        "name": "Gods Bible School and College",
        "hyphenated": "gods-bible-school-and-college"
    },
    {
        "id": "202912",
        "name": "Good Samaritan College of Nursing and Health Science",
        "hyphenated": "good-samaritan-college-of-nursing-and-health-science"
    },
    {
        "id": "202949",
        "name": "Great Oaks Career Campuses",
        "hyphenated": "great-oaks-career-campuses"
    },
    {
        "id": "202985",
        "name": "Hannah E Mullins School of Practical Nursing",
        "hyphenated": "hannah-e-mullins-school-of-practical-nursing"
    },
    {
        "id": "203030",
        "name": "Adult and Community Education-Hudson",
        "hyphenated": "adult-and-community-education-hudson"
    },
    {
        "id": "203067",
        "name": "Hebrew Union College-Jewish Institute of Religion",
        "hyphenated": "hebrew-union-college-jewish-institute-of-religion"
    },
    {
        "id": "203085",
        "name": "Heidelberg University",
        "hyphenated": "heidelberg-university"
    },
    {
        "id": "203128",
        "name": "Hiram College",
        "hyphenated": "hiram-college"
    },
    {
        "id": "203146",
        "name": "Hobart Institute of Welding Technology",
        "hyphenated": "hobart-institute-of-welding-technology"
    },
    {
        "id": "203155",
        "name": "Hocking College",
        "hyphenated": "hocking-college"
    },
    {
        "id": "203234",
        "name": "Ross Medical Education Center-Cincinnati",
        "hyphenated": "ross-medical-education-center-cincinnati"
    },
    {
        "id": "203289",
        "name": "International College of Broadcasting",
        "hyphenated": "international-college-of-broadcasting"
    },
    {
        "id": "203331",
        "name": "Eastern Gateway Community College",
        "hyphenated": "eastern-gateway-community-college"
    },
    {
        "id": "203368",
        "name": "John Carroll University",
        "hyphenated": "john-carroll-university"
    },
    {
        "id": "203386",
        "name": "Hondros College of Nursing",
        "hyphenated": "hondros-college-of-nursing"
    },
    {
        "id": "203438",
        "name": "Casal Aveda Institute",
        "hyphenated": "casal-aveda-institute"
    },
    {
        "id": "203447",
        "name": "Kent State University at Ashtabula",
        "hyphenated": "kent-state-university-at-ashtabula"
    },
    {
        "id": "203456",
        "name": "Kent State University at East Liverpool",
        "hyphenated": "kent-state-university-at-east-liverpool"
    },
    {
        "id": "203465",
        "name": "Kent State University at Stark",
        "hyphenated": "kent-state-university-at-stark"
    },
    {
        "id": "203474",
        "name": "Kent State University at Trumbull",
        "hyphenated": "kent-state-university-at-trumbull"
    },
    {
        "id": "203483",
        "name": "Kent State University at Tuscarawas",
        "hyphenated": "kent-state-university-at-tuscarawas"
    },
    {
        "id": "203492",
        "name": "Kent State University at Salem",
        "hyphenated": "kent-state-university-at-salem"
    },
    {
        "id": "203517",
        "name": "Kent State University at Kent",
        "hyphenated": "kent-state-university-at-kent"
    },
    {
        "id": "203526",
        "name": "Kent State University at Geauga",
        "hyphenated": "kent-state-university-at-geauga"
    },
    {
        "id": "203535",
        "name": "Kenyon College",
        "hyphenated": "kenyon-college"
    },
    {
        "id": "203544",
        "name": "Kettering College",
        "hyphenated": "kettering-college"
    },
    {
        "id": "203580",
        "name": "Lake Erie College",
        "hyphenated": "lake-erie-college"
    },
    {
        "id": "203599",
        "name": "Lakeland Community College",
        "hyphenated": "lakeland-community-college"
    },
    {
        "id": "203614",
        "name": "O C Collins Career Center",
        "hyphenated": "o-c-collins-career-center"
    },
    {
        "id": "203678",
        "name": "James A. Rhodes State College",
        "hyphenated": "james-a.-rhodes-state-college"
    },
    {
        "id": "203720",
        "name": "Ohio Business College-Sheffield",
        "hyphenated": "ohio-business-college-sheffield"
    },
    {
        "id": "203739",
        "name": "Ohio Business College-Sandusky",
        "hyphenated": "ohio-business-college-sandusky"
    },
    {
        "id": "203748",
        "name": "Lorain County Community College",
        "hyphenated": "lorain-county-community-college"
    },
    {
        "id": "203757",
        "name": "Lourdes University",
        "hyphenated": "lourdes-university"
    },
    {
        "id": "203775",
        "name": "Malone University",
        "hyphenated": "malone-university"
    },
    {
        "id": "203845",
        "name": "Marietta College",
        "hyphenated": "marietta-college"
    },
    {
        "id": "203881",
        "name": "Marion Technical College",
        "hyphenated": "marion-technical-college"
    },
    {
        "id": "203942",
        "name": "Medina County Career Center",
        "hyphenated": "medina-county-career-center"
    },
    {
        "id": "203960",
        "name": "Mercy College of Ohio",
        "hyphenated": "mercy-college-of-ohio"
    },
    {
        "id": "203997",
        "name": "Methodist Theological School in Ohio",
        "hyphenated": "methodist-theological-school-in-ohio"
    },
    {
        "id": "204006",
        "name": "Miami University-Hamilton",
        "hyphenated": "miami-university-hamilton"
    },
    {
        "id": "204015",
        "name": "Miami University-Middletown",
        "hyphenated": "miami-university-middletown"
    },
    {
        "id": "204121",
        "name": "Moler Hollywood Beauty Academy",
        "hyphenated": "moler-hollywood-beauty-academy"
    },
    {
        "id": "204158",
        "name": "Miami Valley Career Technology Center",
        "hyphenated": "miami-valley-career-technology-center"
    },
    {
        "id": "204176",
        "name": "Mount Carmel College of Nursing",
        "hyphenated": "mount-carmel-college-of-nursing"
    },
    {
        "id": "204185",
        "name": "University of Mount Union",
        "hyphenated": "university-of-mount-union"
    },
    {
        "id": "204194",
        "name": "Mount Vernon Nazarene University",
        "hyphenated": "mount-vernon-nazarene-university"
    },
    {
        "id": "204200",
        "name": "Mount Saint Joseph University",
        "hyphenated": "mount-saint-joseph-university"
    },
    {
        "id": "204255",
        "name": "Zane State College",
        "hyphenated": "zane-state-college"
    },
    {
        "id": "204264",
        "name": "Muskingum University",
        "hyphenated": "muskingum-university"
    },
    {
        "id": "204334",
        "name": "National Beauty College",
        "hyphenated": "national-beauty-college"
    },
    {
        "id": "204422",
        "name": "North Central State College",
        "hyphenated": "north-central-state-college"
    },
    {
        "id": "204431",
        "name": "Northern Institute of Cosmetology",
        "hyphenated": "northern-institute-of-cosmetology"
    },
    {
        "id": "204440",
        "name": "Northwest State Community College",
        "hyphenated": "northwest-state-community-college"
    },
    {
        "id": "204459",
        "name": "Toledo Public Schools Adult and Continuing Education",
        "hyphenated": "toledo-public-schools-adult-and-continuing-education"
    },
    {
        "id": "204468",
        "name": "Notre Dame College",
        "hyphenated": "notre-dame-college"
    },
    {
        "id": "204477",
        "name": "Northeast Ohio Medical University",
        "hyphenated": "northeast-ohio-medical-university"
    },
    {
        "id": "204486",
        "name": "University of Northwestern Ohio",
        "hyphenated": "university-of-northwestern-ohio"
    },
    {
        "id": "204501",
        "name": "Oberlin College",
        "hyphenated": "oberlin-college"
    },
    {
        "id": "204608",
        "name": "Ohio Technical College",
        "hyphenated": "ohio-technical-college"
    },
    {
        "id": "204617",
        "name": "Ohio Dominican University",
        "hyphenated": "ohio-dominican-university"
    },
    {
        "id": "204635",
        "name": "Ohio Northern University",
        "hyphenated": "ohio-northern-university"
    },
    {
        "id": "204662",
        "name": "Ohio State University Agricultural Technical Institute",
        "hyphenated": "ohio-state-university-agricultural-technical-institute"
    },
    {
        "id": "204671",
        "name": "Ohio State University-Lima Campus",
        "hyphenated": "ohio-state-university-lima-campus"
    },
    {
        "id": "204680",
        "name": "Ohio State University-Mansfield Campus",
        "hyphenated": "ohio-state-university-mansfield-campus"
    },
    {
        "id": "204699",
        "name": "Ohio State University-Marion Campus",
        "hyphenated": "ohio-state-university-marion-campus"
    },
    {
        "id": "204705",
        "name": "Ohio State University-Newark Campus",
        "hyphenated": "ohio-state-university-newark-campus"
    },
    {
        "id": "204714",
        "name": "Ohio State Beauty Academy",
        "hyphenated": "ohio-state-beauty-academy"
    },
    {
        "id": "204723",
        "name": "Ohio State College of Barber Styling",
        "hyphenated": "ohio-state-college-of-barber-styling"
    },
    {
        "id": "204769",
        "name": "The Spa School",
        "hyphenated": "the-spa-school"
    },
    {
        "id": "204778",
        "name": "Ohio State School of Cosmetology-Canal Winchester",
        "hyphenated": "ohio-state-school-of-cosmetology-canal-winchester"
    },
    {
        "id": "204802",
        "name": "Ohio University-Eastern Campus",
        "hyphenated": "ohio-university-eastern-campus"
    },
    {
        "id": "204820",
        "name": "Ohio University-Chillicothe Campus",
        "hyphenated": "ohio-university-chillicothe-campus"
    },
    {
        "id": "204839",
        "name": "Ohio University-Southern Campus",
        "hyphenated": "ohio-university-southern-campus"
    },
    {
        "id": "204848",
        "name": "Ohio University-Lancaster Campus",
        "hyphenated": "ohio-university-lancaster-campus"
    },
    {
        "id": "204857",
        "name": "Ohio University-Main Campus",
        "hyphenated": "ohio-university-main-campus"
    },
    {
        "id": "204866",
        "name": "Ohio University-Zanesville Campus",
        "hyphenated": "ohio-university-zanesville-campus"
    },
    {
        "id": "204884",
        "name": "East Ohio College",
        "hyphenated": "east-ohio-college"
    },
    {
        "id": "204893",
        "name": "Trinity Health System School of Nursing",
        "hyphenated": "trinity-health-system-school-of-nursing"
    },
    {
        "id": "204909",
        "name": "Ohio Wesleyan University",
        "hyphenated": "ohio-wesleyan-university"
    },
    {
        "id": "204936",
        "name": "Otterbein University",
        "hyphenated": "otterbein-university"
    },
    {
        "id": "204945",
        "name": "Owens Community College",
        "hyphenated": "owens-community-college"
    },
    {
        "id": "204963",
        "name": "Paramount Beauty Academy",
        "hyphenated": "paramount-beauty-academy"
    },
    {
        "id": "204981",
        "name": "Cuyahoga Valley Career Center",
        "hyphenated": "cuyahoga-valley-career-center"
    },
    {
        "id": "204990",
        "name": "Payne Theological Seminary",
        "hyphenated": "payne-theological-seminary"
    },
    {
        "id": "205027",
        "name": "Pontifical College Josephinum",
        "hyphenated": "pontifical-college-josephinum"
    },
    {
        "id": "205054",
        "name": "Professional Skills Institute",
        "hyphenated": "professional-skills-institute"
    },
    {
        "id": "205090",
        "name": "Firelands Regional Medical Center School of Nursing",
        "hyphenated": "firelands-regional-medical-center-school-of-nursing"
    },
    {
        "id": "205124",
        "name": "Rabbinical College Telshe",
        "hyphenated": "rabbinical-college-telshe"
    },
    {
        "id": "205142",
        "name": "Raphael's School of Beauty Culture Inc-Niles",
        "hyphenated": "raphael's-school-of-beauty-culture-inc-niles"
    },
    {
        "id": "205179",
        "name": "Fortis College-Centerville",
        "hyphenated": "fortis-college-centerville"
    },
    {
        "id": "205203",
        "name": "University of Rio Grande",
        "hyphenated": "university-of-rio-grande"
    },
    {
        "id": "205391",
        "name": "The Modern College of Design",
        "hyphenated": "the-modern-college-of-design"
    },
    {
        "id": "205443",
        "name": "Shawnee State University",
        "hyphenated": "shawnee-state-university"
    },
    {
        "id": "205470",
        "name": "Sinclair Community College",
        "hyphenated": "sinclair-community-college"
    },
    {
        "id": "205559",
        "name": "Hussian College-Daymar College Columbus",
        "hyphenated": "hussian-college-daymar-college-columbus"
    },
    {
        "id": "205841",
        "name": "Stark State College",
        "hyphenated": "stark-state-college"
    },
    {
        "id": "205887",
        "name": "Stautzenberger College-Maumee",
        "hyphenated": "stautzenberger-college-maumee"
    },
    {
        "id": "205957",
        "name": "Franciscan University of Steubenville",
        "hyphenated": "franciscan-university-of-steubenville"
    },
    {
        "id": "205966",
        "name": "Southern State Community College",
        "hyphenated": "southern-state-community-college"
    },
    {
        "id": "206011",
        "name": "Terra State Community College",
        "hyphenated": "terra-state-community-college"
    },
    {
        "id": "206039",
        "name": "Tiffin Academy of Hair Design",
        "hyphenated": "tiffin-academy-of-hair-design"
    },
    {
        "id": "206048",
        "name": "Tiffin University",
        "hyphenated": "tiffin-university"
    },
    {
        "id": "206084",
        "name": "University of Toledo",
        "hyphenated": "university-of-toledo"
    },
    {
        "id": "206154",
        "name": "Tri-State Bible College",
        "hyphenated": "tri-state-bible-college"
    },
    {
        "id": "206172",
        "name": "Tri-County Adult Career Center",
        "hyphenated": "tri-county-adult-career-center"
    },
    {
        "id": "206181",
        "name": "Tri-Rivers Career Center",
        "hyphenated": "tri-rivers-career-center"
    },
    {
        "id": "206279",
        "name": "Union Institute & University",
        "hyphenated": "union-institute-&-university"
    },
    {
        "id": "206288",
        "name": "United Theological Seminary",
        "hyphenated": "united-theological-seminary"
    },
    {
        "id": "206321",
        "name": "Upper Valley Career Center",
        "hyphenated": "upper-valley-career-center"
    },
    {
        "id": "206349",
        "name": "Ursuline College",
        "hyphenated": "ursuline-college"
    },
    {
        "id": "206394",
        "name": "The North Coast College",
        "hyphenated": "the-north-coast-college"
    },
    {
        "id": "206437",
        "name": "Walsh University",
        "hyphenated": "walsh-university"
    },
    {
        "id": "206446",
        "name": "Washington State Community College",
        "hyphenated": "washington-state-community-college"
    },
    {
        "id": "206491",
        "name": "Wilberforce University",
        "hyphenated": "wilberforce-university"
    },
    {
        "id": "206507",
        "name": "Wilmington College",
        "hyphenated": "wilmington-college"
    },
    {
        "id": "206516",
        "name": "Winebrenner Theological Seminary",
        "hyphenated": "winebrenner-theological-seminary"
    },
    {
        "id": "206525",
        "name": "Wittenberg University",
        "hyphenated": "wittenberg-university"
    },
    {
        "id": "206589",
        "name": "The College of Wooster",
        "hyphenated": "the-college-of-wooster"
    },
    {
        "id": "206604",
        "name": "Wright State University-Main Campus",
        "hyphenated": "wright-state-university-main-campus"
    },
    {
        "id": "206613",
        "name": "Wright State University-Lake Campus",
        "hyphenated": "wright-state-university-lake-campus"
    },
    {
        "id": "206622",
        "name": "Xavier University",
        "hyphenated": "xavier-university"
    },
    {
        "id": "206695",
        "name": "Youngstown State University",
        "hyphenated": "youngstown-state-university"
    },
    {
        "id": "206817",
        "name": "Bacone College",
        "hyphenated": "bacone-college"
    },
    {
        "id": "206835",
        "name": "Oklahoma Wesleyan University",
        "hyphenated": "oklahoma-wesleyan-university"
    },
    {
        "id": "206862",
        "name": "Southern Nazarene University",
        "hyphenated": "southern-nazarene-university"
    },
    {
        "id": "206905",
        "name": "Pontotoc Technology Center",
        "hyphenated": "pontotoc-technology-center"
    },
    {
        "id": "206914",
        "name": "Cameron University",
        "hyphenated": "cameron-university"
    },
    {
        "id": "206923",
        "name": "Carl Albert State College",
        "hyphenated": "carl-albert-state-college"
    },
    {
        "id": "206932",
        "name": "Central Oklahoma College",
        "hyphenated": "central-oklahoma-college"
    },
    {
        "id": "206941",
        "name": "University of Central Oklahoma",
        "hyphenated": "university-of-central-oklahoma"
    },
    {
        "id": "206996",
        "name": "Connors State College",
        "hyphenated": "connors-state-college"
    },
    {
        "id": "207041",
        "name": "East Central University",
        "hyphenated": "east-central-university"
    },
    {
        "id": "207050",
        "name": "Eastern Oklahoma State College",
        "hyphenated": "eastern-oklahoma-state-college"
    },
    {
        "id": "207069",
        "name": "Redlands Community College",
        "hyphenated": "redlands-community-college"
    },
    {
        "id": "207087",
        "name": "Formations Institute",
        "hyphenated": "formations-institute"
    },
    {
        "id": "207102",
        "name": "Eves College of Hairstyling",
        "hyphenated": "eves-college-of-hairstyling"
    },
    {
        "id": "207157",
        "name": "Randall University",
        "hyphenated": "randall-university"
    },
    {
        "id": "207209",
        "name": "Langston University",
        "hyphenated": "langston-university"
    },
    {
        "id": "207236",
        "name": "Murray State College",
        "hyphenated": "murray-state-college"
    },
    {
        "id": "207254",
        "name": "Spartan College of Aeronautics and Technology",
        "hyphenated": "spartan-college-of-aeronautics-and-technology"
    },
    {
        "id": "207263",
        "name": "Northeastern State University",
        "hyphenated": "northeastern-state-university"
    },
    {
        "id": "207281",
        "name": "Northern Oklahoma College",
        "hyphenated": "northern-oklahoma-college"
    },
    {
        "id": "207290",
        "name": "Northeastern Oklahoma A&M College",
        "hyphenated": "northeastern-oklahoma-a&m-college"
    },
    {
        "id": "207306",
        "name": "Northwestern Oklahoma State University",
        "hyphenated": "northwestern-oklahoma-state-university"
    },
    {
        "id": "207315",
        "name": "Oklahoma State University Center for Health Sciences",
        "hyphenated": "oklahoma-state-university-center-for-health-sciences"
    },
    {
        "id": "207324",
        "name": "Oklahoma Christian University",
        "hyphenated": "oklahoma-christian-university"
    },
    {
        "id": "207342",
        "name": "University of Oklahoma-Health Sciences Center",
        "hyphenated": "university-of-oklahoma-health-sciences-center"
    },
    {
        "id": "207351",
        "name": "Oklahoma Panhandle State University",
        "hyphenated": "oklahoma-panhandle-state-university"
    },
    {
        "id": "207388",
        "name": "Oklahoma State University-Main Campus",
        "hyphenated": "oklahoma-state-university-main-campus"
    },
    {
        "id": "207397",
        "name": "Oklahoma State University-Oklahoma City",
        "hyphenated": "oklahoma-state-university-oklahoma-city"
    },
    {
        "id": "207403",
        "name": "Oklahoma Baptist University",
        "hyphenated": "oklahoma-baptist-university"
    },
    {
        "id": "207449",
        "name": "Oklahoma City Community College",
        "hyphenated": "oklahoma-city-community-college"
    },
    {
        "id": "207458",
        "name": "Oklahoma City University",
        "hyphenated": "oklahoma-city-university"
    },
    {
        "id": "207564",
        "name": "Oklahoma State University Institute of Technology",
        "hyphenated": "oklahoma-state-university-institute-of-technology"
    },
    {
        "id": "207582",
        "name": "Oral Roberts University",
        "hyphenated": "oral-roberts-university"
    },
    {
        "id": "207661",
        "name": "Rogers State University",
        "hyphenated": "rogers-state-university"
    },
    {
        "id": "207670",
        "name": "Rose State College",
        "hyphenated": "rose-state-college"
    },
    {
        "id": "207704",
        "name": "Jenks Beauty College",
        "hyphenated": "jenks-beauty-college"
    },
    {
        "id": "207722",
        "name": "University of Science and Arts of Oklahoma",
        "hyphenated": "university-of-science-and-arts-of-oklahoma"
    },
    {
        "id": "207740",
        "name": "Seminole State College",
        "hyphenated": "seminole-state-college"
    },
    {
        "id": "207786",
        "name": "Southern School of Beauty Inc",
        "hyphenated": "southern-school-of-beauty-inc"
    },
    {
        "id": "207847",
        "name": "Southeastern Oklahoma State University",
        "hyphenated": "southeastern-oklahoma-state-university"
    },
    {
        "id": "207856",
        "name": "Southwestern Christian University",
        "hyphenated": "southwestern-christian-university"
    },
    {
        "id": "207865",
        "name": "Southwestern Oklahoma State University",
        "hyphenated": "southwestern-oklahoma-state-university"
    },
    {
        "id": "207935",
        "name": "Tulsa Community College",
        "hyphenated": "tulsa-community-college"
    },
    {
        "id": "207962",
        "name": "Tulsa Welding School-Tulsa",
        "hyphenated": "tulsa-welding-school-tulsa"
    },
    {
        "id": "208035",
        "name": "Western Oklahoma State College",
        "hyphenated": "western-oklahoma-state-college"
    },
    {
        "id": "208044",
        "name": "Formations Institute of Cosmetology & Barbering",
        "hyphenated": "formations-institute-of-cosmetology-&-barbering"
    },
    {
        "id": "208053",
        "name": "High Plains Technology Center",
        "hyphenated": "high-plains-technology-center"
    },
    {
        "id": "208275",
        "name": "Blue Mountain Community College",
        "hyphenated": "blue-mountain-community-college"
    },
    {
        "id": "208318",
        "name": "Central Oregon Community College",
        "hyphenated": "central-oregon-community-college"
    },
    {
        "id": "208390",
        "name": "Chemeketa Community College",
        "hyphenated": "chemeketa-community-college"
    },
    {
        "id": "208406",
        "name": "Clackamas Community College",
        "hyphenated": "clackamas-community-college"
    },
    {
        "id": "208415",
        "name": "Clatsop Community College",
        "hyphenated": "clatsop-community-college"
    },
    {
        "id": "208433",
        "name": "College of Hair Design Careers",
        "hyphenated": "college-of-hair-design-careers"
    },
    {
        "id": "208479",
        "name": "Concorde Career College-Portland",
        "hyphenated": "concorde-career-college-portland"
    },
    {
        "id": "208512",
        "name": "Sumner College",
        "hyphenated": "sumner-college"
    },
    {
        "id": "208637",
        "name": "East West College of the Healing Arts",
        "hyphenated": "east-west-college-of-the-healing-arts"
    },
    {
        "id": "208646",
        "name": "Eastern Oregon University",
        "hyphenated": "eastern-oregon-university"
    },
    {
        "id": "208725",
        "name": "New Hope Christian College-Eugene",
        "hyphenated": "new-hope-christian-college-eugene"
    },
    {
        "id": "208822",
        "name": "George Fox University",
        "hyphenated": "george-fox-university"
    },
    {
        "id": "208859",
        "name": "Phagans Grants Pass College of Beauty",
        "hyphenated": "phagans-grants-pass-college-of-beauty"
    },
    {
        "id": "209038",
        "name": "Lane Community College",
        "hyphenated": "lane-community-college"
    },
    {
        "id": "209056",
        "name": "Lewis & Clark College",
        "hyphenated": "lewis-&-clark-college"
    },
    {
        "id": "209065",
        "name": "Linfield University",
        "hyphenated": "linfield-university"
    },
    {
        "id": "209074",
        "name": "Linn-Benton Community College",
        "hyphenated": "linn-benton-community-college"
    },
    {
        "id": "209083",
        "name": "Northwest College-Beaverton",
        "hyphenated": "northwest-college-beaverton"
    },
    {
        "id": "209153",
        "name": "Phagans Medford Beauty School",
        "hyphenated": "phagans-medford-beauty-school"
    },
    {
        "id": "209205",
        "name": "Northwest College-Clackamas",
        "hyphenated": "northwest-college-clackamas"
    },
    {
        "id": "209241",
        "name": "Mount Angel Seminary",
        "hyphenated": "mount-angel-seminary"
    },
    {
        "id": "209250",
        "name": "Mt Hood Community College",
        "hyphenated": "mt-hood-community-college"
    },
    {
        "id": "209287",
        "name": "Multnomah University",
        "hyphenated": "multnomah-university"
    },
    {
        "id": "209296",
        "name": "National University of Natural Medicine",
        "hyphenated": "national-university-of-natural-medicine"
    },
    {
        "id": "209409",
        "name": "Bushnell University",
        "hyphenated": "bushnell-university"
    },
    {
        "id": "209490",
        "name": "Oregon Health & Science University",
        "hyphenated": "oregon-health-&-science-university"
    },
    {
        "id": "209506",
        "name": "Oregon Institute of Technology",
        "hyphenated": "oregon-institute-of-technology"
    },
    {
        "id": "209542",
        "name": "Oregon State University",
        "hyphenated": "oregon-state-university"
    },
    {
        "id": "209603",
        "name": "Pacific Northwest College of Art",
        "hyphenated": "pacific-northwest-college-of-art"
    },
    {
        "id": "209612",
        "name": "Pacific University",
        "hyphenated": "pacific-university"
    },
    {
        "id": "209667",
        "name": "Phagans Beauty College",
        "hyphenated": "phagans-beauty-college"
    },
    {
        "id": "209676",
        "name": "Phagans Central Oregon Beauty College",
        "hyphenated": "phagans-central-oregon-beauty-college"
    },
    {
        "id": "209694",
        "name": "Phagans School of Beauty",
        "hyphenated": "phagans-school-of-beauty"
    },
    {
        "id": "209700",
        "name": "Phagans School of Hair Design",
        "hyphenated": "phagans-school-of-hair-design"
    },
    {
        "id": "209719",
        "name": "Summit Salon Academy-Portland",
        "hyphenated": "summit-salon-academy-portland"
    },
    {
        "id": "209746",
        "name": "Portland Community College",
        "hyphenated": "portland-community-college"
    },
    {
        "id": "209807",
        "name": "Portland State University",
        "hyphenated": "portland-state-university"
    },
    {
        "id": "209825",
        "name": "University of Portland",
        "hyphenated": "university-of-portland"
    },
    {
        "id": "209922",
        "name": "Reed College",
        "hyphenated": "reed-college"
    },
    {
        "id": "209940",
        "name": "Rogue Community College",
        "hyphenated": "rogue-community-college"
    },
    {
        "id": "210146",
        "name": "Southern Oregon University",
        "hyphenated": "southern-oregon-university"
    },
    {
        "id": "210155",
        "name": "Southwestern Oregon Community College",
        "hyphenated": "southwestern-oregon-community-college"
    },
    {
        "id": "210234",
        "name": "Treasure Valley Community College",
        "hyphenated": "treasure-valley-community-college"
    },
    {
        "id": "210270",
        "name": "Umpqua Community College",
        "hyphenated": "umpqua-community-college"
    },
    {
        "id": "210304",
        "name": "Warner Pacific University",
        "hyphenated": "warner-pacific-university"
    },
    {
        "id": "210331",
        "name": "Corban University",
        "hyphenated": "corban-university"
    },
    {
        "id": "210340",
        "name": "College of Cosmetology",
        "hyphenated": "college-of-cosmetology"
    },
    {
        "id": "210368",
        "name": "Western Seminary",
        "hyphenated": "western-seminary"
    },
    {
        "id": "210401",
        "name": "Willamette University",
        "hyphenated": "willamette-university"
    },
    {
        "id": "210429",
        "name": "Western Oregon University",
        "hyphenated": "western-oregon-university"
    },
    {
        "id": "210438",
        "name": "University of Western States",
        "hyphenated": "university-of-western-states"
    },
    {
        "id": "210492",
        "name": "Bryn Athyn College of the New Church",
        "hyphenated": "bryn-athyn-college-of-the-new-church"
    },
    {
        "id": "210508",
        "name": "Academy of Vocal Arts",
        "hyphenated": "academy-of-vocal-arts"
    },
    {
        "id": "210571",
        "name": "Albright College",
        "hyphenated": "albright-college"
    },
    {
        "id": "210599",
        "name": "All-State Career School",
        "hyphenated": "all-state-career-school"
    },
    {
        "id": "210605",
        "name": "Community College of Allegheny County",
        "hyphenated": "community-college-of-allegheny-county"
    },
    {
        "id": "210669",
        "name": "Allegheny College",
        "hyphenated": "allegheny-college"
    },
    {
        "id": "210711",
        "name": "The Beauty Institute",
        "hyphenated": "the-beauty-institute"
    },
    {
        "id": "210739",
        "name": "DeSales University",
        "hyphenated": "desales-university"
    },
    {
        "id": "210748",
        "name": "Altoona Beauty School Inc",
        "hyphenated": "altoona-beauty-school-inc"
    },
    {
        "id": "210775",
        "name": "Alvernia University",
        "hyphenated": "alvernia-university"
    },
    {
        "id": "210784",
        "name": "The Beauty Institute-Ambler",
        "hyphenated": "the-beauty-institute-ambler"
    },
    {
        "id": "210809",
        "name": "American College of Financial Services",
        "hyphenated": "american-college-of-financial-services"
    },
    {
        "id": "211006",
        "name": "Automotive Training Center-Exton",
        "hyphenated": "automotive-training-center-exton"
    },
    {
        "id": "211024",
        "name": "Clarks Summit University",
        "hyphenated": "clarks-summit-university"
    },
    {
        "id": "211079",
        "name": "Community College of Beaver County",
        "hyphenated": "community-college-of-beaver-county"
    },
    {
        "id": "211088",
        "name": "Arcadia University",
        "hyphenated": "arcadia-university"
    },
    {
        "id": "211130",
        "name": "Missio Theological Seminary",
        "hyphenated": "missio-theological-seminary"
    },
    {
        "id": "211158",
        "name": "Bloomsburg University of Pennsylvania",
        "hyphenated": "bloomsburg-university-of-pennsylvania"
    },
    {
        "id": "211273",
        "name": "Bryn Mawr College",
        "hyphenated": "bryn-mawr-college"
    },
    {
        "id": "211291",
        "name": "Bucknell University",
        "hyphenated": "bucknell-university"
    },
    {
        "id": "211307",
        "name": "Bucks County Community College",
        "hyphenated": "bucks-county-community-college"
    },
    {
        "id": "211316",
        "name": "Bucks County School of Beauty Culture Inc",
        "hyphenated": "bucks-county-school-of-beauty-culture-inc"
    },
    {
        "id": "211334",
        "name": "Butler Beauty Academy",
        "hyphenated": "butler-beauty-academy"
    },
    {
        "id": "211343",
        "name": "Butler County Community College",
        "hyphenated": "butler-county-community-college"
    },
    {
        "id": "211352",
        "name": "Cabrini University",
        "hyphenated": "cabrini-university"
    },
    {
        "id": "211361",
        "name": "California University of Pennsylvania",
        "hyphenated": "california-university-of-pennsylvania"
    },
    {
        "id": "211431",
        "name": "Carlow University",
        "hyphenated": "carlow-university"
    },
    {
        "id": "211468",
        "name": "Cedar Crest College",
        "hyphenated": "cedar-crest-college"
    },
    {
        "id": "211477",
        "name": "Central Penn College",
        "hyphenated": "central-penn-college"
    },
    {
        "id": "211556",
        "name": "Chatham University",
        "hyphenated": "chatham-university"
    },
    {
        "id": "211583",
        "name": "Chestnut Hill College",
        "hyphenated": "chestnut-hill-college"
    },
    {
        "id": "211608",
        "name": "Cheyney University of Pennsylvania",
        "hyphenated": "cheyney-university-of-pennsylvania"
    },
    {
        "id": "211635",
        "name": "Citizens School of Nursing",
        "hyphenated": "citizens-school-of-nursing"
    },
    {
        "id": "211644",
        "name": "Clarion University of Pennsylvania",
        "hyphenated": "clarion-university-of-pennsylvania"
    },
    {
        "id": "211802",
        "name": "DLP Conemaugh Memorial Medical Center",
        "hyphenated": "dlp-conemaugh-memorial-medical-center"
    },
    {
        "id": "211893",
        "name": "Curtis Institute of Music",
        "hyphenated": "curtis-institute-of-music"
    },
    {
        "id": "211927",
        "name": "Delaware County Community College",
        "hyphenated": "delaware-county-community-college"
    },
    {
        "id": "211981",
        "name": "Delaware Valley University",
        "hyphenated": "delaware-valley-university"
    },
    {
        "id": "212009",
        "name": "Dickinson College",
        "hyphenated": "dickinson-college"
    },
    {
        "id": "212045",
        "name": "Douglas Education Center",
        "hyphenated": "douglas-education-center"
    },
    {
        "id": "212106",
        "name": "Duquesne University",
        "hyphenated": "duquesne-university"
    },
    {
        "id": "212115",
        "name": "East Stroudsburg University of Pennsylvania",
        "hyphenated": "east-stroudsburg-university-of-pennsylvania"
    },
    {
        "id": "212133",
        "name": "Eastern University",
        "hyphenated": "eastern-university"
    },
    {
        "id": "212160",
        "name": "Edinboro University of Pennsylvania",
        "hyphenated": "edinboro-university-of-pennsylvania"
    },
    {
        "id": "212197",
        "name": "Elizabethtown College",
        "hyphenated": "elizabethtown-college"
    },
    {
        "id": "212230",
        "name": "Empire Beauty School-Lebanon",
        "hyphenated": "empire-beauty-school-lebanon"
    },
    {
        "id": "212267",
        "name": "Empire Beauty School-York",
        "hyphenated": "empire-beauty-school-york"
    },
    {
        "id": "212276",
        "name": "Empire Beauty School-Lancaster",
        "hyphenated": "empire-beauty-school-lancaster"
    },
    {
        "id": "212294",
        "name": "Empire Beauty School-Shamokin Dam",
        "hyphenated": "empire-beauty-school-shamokin-dam"
    },
    {
        "id": "212300",
        "name": "Empire Beauty School-Pottsville",
        "hyphenated": "empire-beauty-school-pottsville"
    },
    {
        "id": "212328",
        "name": "Empire Beauty School-Lehigh Valley",
        "hyphenated": "empire-beauty-school-lehigh-valley"
    },
    {
        "id": "212337",
        "name": "Empire Beauty School-Gwinnett",
        "hyphenated": "empire-beauty-school-gwinnett"
    },
    {
        "id": "212355",
        "name": "Empire Beauty School-Wyoming Valley",
        "hyphenated": "empire-beauty-school-wyoming-valley"
    },
    {
        "id": "212382",
        "name": "Empire Beauty School-Harrisburg",
        "hyphenated": "empire-beauty-school-harrisburg"
    },
    {
        "id": "212391",
        "name": "Empire Beauty School-Reading",
        "hyphenated": "empire-beauty-school-reading"
    },
    {
        "id": "212434",
        "name": "Erie Institute of Technology Inc",
        "hyphenated": "erie-institute-of-technology-inc"
    },
    {
        "id": "212443",
        "name": "Evangelical Theological Seminary",
        "hyphenated": "evangelical-theological-seminary"
    },
    {
        "id": "212577",
        "name": "Franklin and Marshall College",
        "hyphenated": "franklin-and-marshall-college"
    },
    {
        "id": "212601",
        "name": "Gannon University",
        "hyphenated": "gannon-university"
    },
    {
        "id": "212656",
        "name": "Geneva College",
        "hyphenated": "geneva-college"
    },
    {
        "id": "212674",
        "name": "Gettysburg College",
        "hyphenated": "gettysburg-college"
    },
    {
        "id": "212753",
        "name": "Empire Beauty School-NE Philadelphia",
        "hyphenated": "empire-beauty-school-ne-philadelphia"
    },
    {
        "id": "212771",
        "name": "Gratz College",
        "hyphenated": "gratz-college"
    },
    {
        "id": "212805",
        "name": "Grove City College",
        "hyphenated": "grove-city-college"
    },
    {
        "id": "212832",
        "name": "Gwynedd Mercy University",
        "hyphenated": "gwynedd-mercy-university"
    },
    {
        "id": "212869",
        "name": "Harcum College",
        "hyphenated": "harcum-college"
    },
    {
        "id": "212878",
        "name": "Harrisburg Area Community College",
        "hyphenated": "harrisburg-area-community-college"
    },
    {
        "id": "212975",
        "name": "Commonwealth Technical Institute",
        "hyphenated": "commonwealth-technical-institute"
    },
    {
        "id": "212984",
        "name": "Holy Family University",
        "hyphenated": "holy-family-university"
    },
    {
        "id": "212993",
        "name": "Hussian College-Philadelphia",
        "hyphenated": "hussian-college-philadelphia"
    },
    {
        "id": "213011",
        "name": "Immaculata University",
        "hyphenated": "immaculata-university"
    },
    {
        "id": "213020",
        "name": "Indiana University of Pennsylvania-Main Campus",
        "hyphenated": "indiana-university-of-pennsylvania-main-campus"
    },
    {
        "id": "213163",
        "name": "UPMC Jameson School of Nursing",
        "hyphenated": "upmc-jameson-school-of-nursing"
    },
    {
        "id": "213181",
        "name": "Great Lakes Institute of Technology",
        "hyphenated": "great-lakes-institute-of-technology"
    },
    {
        "id": "213233",
        "name": "Johnson College",
        "hyphenated": "johnson-college"
    },
    {
        "id": "213251",
        "name": "Juniata College",
        "hyphenated": "juniata-college"
    },
    {
        "id": "213303",
        "name": "Keystone College",
        "hyphenated": "keystone-college"
    },
    {
        "id": "213321",
        "name": "King's College",
        "hyphenated": "king's-college"
    },
    {
        "id": "213330",
        "name": "Butler Beauty Academy-Kittanning Beauty Academy",
        "hyphenated": "butler-beauty-academy-kittanning-beauty-academy"
    },
    {
        "id": "213349",
        "name": "Kutztown University of Pennsylvania",
        "hyphenated": "kutztown-university-of-pennsylvania"
    },
    {
        "id": "213358",
        "name": "La Roche University",
        "hyphenated": "la-roche-university"
    },
    {
        "id": "213367",
        "name": "La Salle University",
        "hyphenated": "la-salle-university"
    },
    {
        "id": "213376",
        "name": "Lackawanna College",
        "hyphenated": "lackawanna-college"
    },
    {
        "id": "213385",
        "name": "Lafayette College",
        "hyphenated": "lafayette-college"
    },
    {
        "id": "213400",
        "name": "Lancaster Bible College",
        "hyphenated": "lancaster-bible-college"
    },
    {
        "id": "213446",
        "name": "Lancaster Theological Seminary",
        "hyphenated": "lancaster-theological-seminary"
    },
    {
        "id": "213455",
        "name": "Lancaster School of Cosmetology & Therapeutic Bodywork",
        "hyphenated": "lancaster-school-of-cosmetology-&-therapeutic-bodywork"
    },
    {
        "id": "213473",
        "name": "Lansdale School of Business",
        "hyphenated": "lansdale-school-of-business"
    },
    {
        "id": "213491",
        "name": "Lansdale School of Cosmetology Inc",
        "hyphenated": "lansdale-school-of-cosmetology-inc"
    },
    {
        "id": "213507",
        "name": "Lebanon Valley College",
        "hyphenated": "lebanon-valley-college"
    },
    {
        "id": "213525",
        "name": "Lehigh Carbon Community College",
        "hyphenated": "lehigh-carbon-community-college"
    },
    {
        "id": "213534",
        "name": "Miller-Motte College-Berks Technical Institute",
        "hyphenated": "miller-motte-college-berks-technical-institute"
    },
    {
        "id": "213570",
        "name": "Lincoln Technical Institute-Allentown",
        "hyphenated": "lincoln-technical-institute-allentown"
    },
    {
        "id": "213589",
        "name": "Lincoln Technical Institute-Philadelphia",
        "hyphenated": "lincoln-technical-institute-philadelphia"
    },
    {
        "id": "213598",
        "name": "Lincoln University",
        "hyphenated": "lincoln-university"
    },
    {
        "id": "213613",
        "name": "Lock Haven University",
        "hyphenated": "lock-haven-university"
    },
    {
        "id": "213622",
        "name": "UPMC St. Margaret School of Nursing",
        "hyphenated": "upmc-st.-margaret-school-of-nursing"
    },
    {
        "id": "213631",
        "name": "United Lutheran Seminary",
        "hyphenated": "united-lutheran-seminary"
    },
    {
        "id": "213659",
        "name": "Luzerne County Community College",
        "hyphenated": "luzerne-county-community-college"
    },
    {
        "id": "213668",
        "name": "Lycoming College",
        "hyphenated": "lycoming-college"
    },
    {
        "id": "213774",
        "name": "Manor College",
        "hyphenated": "manor-college"
    },
    {
        "id": "213783",
        "name": "Mansfield University of Pennsylvania",
        "hyphenated": "mansfield-university-of-pennsylvania"
    },
    {
        "id": "213826",
        "name": "Marywood University",
        "hyphenated": "marywood-university"
    },
    {
        "id": "213914",
        "name": "Vet Tech Institute",
        "hyphenated": "vet-tech-institute"
    },
    {
        "id": "213932",
        "name": "Roxborough Memorial Hospital School of Nursing",
        "hyphenated": "roxborough-memorial-hospital-school-of-nursing"
    },
    {
        "id": "213987",
        "name": "Mercyhurst University",
        "hyphenated": "mercyhurst-university"
    },
    {
        "id": "213996",
        "name": "Messiah University",
        "hyphenated": "messiah-university"
    },
    {
        "id": "214023",
        "name": "ASPIRA City College",
        "hyphenated": "aspira-city-college"
    },
    {
        "id": "214041",
        "name": "Millersville University of Pennsylvania",
        "hyphenated": "millersville-university-of-pennsylvania"
    },
    {
        "id": "214069",
        "name": "Misericordia University",
        "hyphenated": "misericordia-university"
    },
    {
        "id": "214111",
        "name": "Montgomery County Community College",
        "hyphenated": "montgomery-county-community-college"
    },
    {
        "id": "214148",
        "name": "Moore College of Art and Design",
        "hyphenated": "moore-college-of-art-and-design"
    },
    {
        "id": "214157",
        "name": "Moravian University",
        "hyphenated": "moravian-university"
    },
    {
        "id": "214166",
        "name": "Mount Aloysius College",
        "hyphenated": "mount-aloysius-college"
    },
    {
        "id": "214175",
        "name": "Muhlenberg College",
        "hyphenated": "muhlenberg-college"
    },
    {
        "id": "214272",
        "name": "Neumann University",
        "hyphenated": "neumann-university"
    },
    {
        "id": "214290",
        "name": "New Castle School of Trades",
        "hyphenated": "new-castle-school-of-trades"
    },
    {
        "id": "214379",
        "name": "Northampton County Area Community College",
        "hyphenated": "northampton-county-area-community-college"
    },
    {
        "id": "214476",
        "name": "Heritage Valley Kennedy School of Nursing",
        "hyphenated": "heritage-valley-kennedy-school-of-nursing"
    },
    {
        "id": "214528",
        "name": "Orleans Technical College",
        "hyphenated": "orleans-technical-college"
    },
    {
        "id": "214564",
        "name": "Salus University",
        "hyphenated": "salus-university"
    },
    {
        "id": "214582",
        "name": "Pennsylvania Institute of Technology",
        "hyphenated": "pennsylvania-institute-of-technology"
    },
    {
        "id": "214883",
        "name": "Peirce College",
        "hyphenated": "peirce-college"
    },
    {
        "id": "214892",
        "name": "Penn Commercial Business/Technical School",
        "hyphenated": "penn-commercial-business/technical-school"
    },
    {
        "id": "214944",
        "name": "Pennco Tech-Bristol",
        "hyphenated": "pennco-tech-bristol"
    },
    {
        "id": "214971",
        "name": "Pennsylvania Academy of the Fine Arts",
        "hyphenated": "pennsylvania-academy-of-the-fine-arts"
    },
    {
        "id": "215008",
        "name": "Pennsylvania Gunsmith School",
        "hyphenated": "pennsylvania-gunsmith-school"
    },
    {
        "id": "215044",
        "name": "Cortiva Institute",
        "hyphenated": "cortiva-institute"
    },
    {
        "id": "215053",
        "name": "Pennsylvania College of Art and Design",
        "hyphenated": "pennsylvania-college-of-art-and-design"
    },
    {
        "id": "215105",
        "name": "The University of the Arts",
        "hyphenated": "the-university-of-the-arts"
    },
    {
        "id": "215114",
        "name": "Cairn University-Langhorne",
        "hyphenated": "cairn-university-langhorne"
    },
    {
        "id": "215123",
        "name": "Philadelphia College of Osteopathic Medicine",
        "hyphenated": "philadelphia-college-of-osteopathic-medicine"
    },
    {
        "id": "215132",
        "name": "University of the Sciences",
        "hyphenated": "university-of-the-sciences"
    },
    {
        "id": "215239",
        "name": "Community College of Philadelphia",
        "hyphenated": "community-college-of-philadelphia"
    },
    {
        "id": "215266",
        "name": "University of Pittsburgh-Bradford",
        "hyphenated": "university-of-pittsburgh-bradford"
    },
    {
        "id": "215275",
        "name": "University of Pittsburgh-Greensburg",
        "hyphenated": "university-of-pittsburgh-greensburg"
    },
    {
        "id": "215284",
        "name": "University of Pittsburgh-Johnstown",
        "hyphenated": "university-of-pittsburgh-johnstown"
    },
    {
        "id": "215309",
        "name": "University of Pittsburgh-Titusville",
        "hyphenated": "university-of-pittsburgh-titusville"
    },
    {
        "id": "215336",
        "name": "Beaver Falls Beauty Academy",
        "hyphenated": "beaver-falls-beauty-academy"
    },
    {
        "id": "215381",
        "name": "Pittsburgh Institute of Aeronautics",
        "hyphenated": "pittsburgh-institute-of-aeronautics"
    },
    {
        "id": "215390",
        "name": "Pittsburgh Institute of Mortuary Science Inc",
        "hyphenated": "pittsburgh-institute-of-mortuary-science-inc"
    },
    {
        "id": "215415",
        "name": "Pittsburgh Technical College",
        "hyphenated": "pittsburgh-technical-college"
    },
    {
        "id": "215424",
        "name": "Pittsburgh Theological Seminary",
        "hyphenated": "pittsburgh-theological-seminary"
    },
    {
        "id": "215442",
        "name": "Point Park University",
        "hyphenated": "point-park-university"
    },
    {
        "id": "215479",
        "name": "Joseph F McCloskey School of Nursing",
        "hyphenated": "joseph-f-mccloskey-school-of-nursing"
    },
    {
        "id": "215530",
        "name": "The Salon Professional Academy-Altoona",
        "hyphenated": "the-salon-professional-academy-altoona"
    },
    {
        "id": "215585",
        "name": "Reading Area Community College",
        "hyphenated": "reading-area-community-college"
    },
    {
        "id": "215594",
        "name": "Reading Hospital School of Health Sciences",
        "hyphenated": "reading-hospital-school-of-health-sciences"
    },
    {
        "id": "215619",
        "name": "Reconstructionist Rabbinical College",
        "hyphenated": "reconstructionist-rabbinical-college"
    },
    {
        "id": "215637",
        "name": "Walnut Hill College",
        "hyphenated": "walnut-hill-college"
    },
    {
        "id": "215655",
        "name": "Robert Morris University",
        "hyphenated": "robert-morris-university"
    },
    {
        "id": "215682",
        "name": "Rosedale Technical College",
        "hyphenated": "rosedale-technical-college"
    },
    {
        "id": "215691",
        "name": "Rosemont College",
        "hyphenated": "rosemont-college"
    },
    {
        "id": "215743",
        "name": "Saint Francis University",
        "hyphenated": "saint-francis-university"
    },
    {
        "id": "215770",
        "name": "Saint Joseph's University",
        "hyphenated": "saint-joseph's-university"
    },
    {
        "id": "215798",
        "name": "Saint Vincent College",
        "hyphenated": "saint-vincent-college"
    },
    {
        "id": "215813",
        "name": "Saint Vincent Seminary",
        "hyphenated": "saint-vincent-seminary"
    },
    {
        "id": "215886",
        "name": "Schuylkill Technology Center",
        "hyphenated": "schuylkill-technology-center"
    },
    {
        "id": "215929",
        "name": "University of Scranton",
        "hyphenated": "university-of-scranton"
    },
    {
        "id": "215947",
        "name": "Seton Hill University",
        "hyphenated": "seton-hill-university"
    },
    {
        "id": "215974",
        "name": "UPMC Shadyside School of Nursing",
        "hyphenated": "upmc-shadyside-school-of-nursing"
    },
    {
        "id": "215983",
        "name": "Sharon Regional School of Nursing",
        "hyphenated": "sharon-regional-school-of-nursing"
    },
    {
        "id": "215992",
        "name": "Laurel Technical Institute",
        "hyphenated": "laurel-technical-institute"
    },
    {
        "id": "216010",
        "name": "Shippensburg University of Pennsylvania",
        "hyphenated": "shippensburg-university-of-pennsylvania"
    },
    {
        "id": "216038",
        "name": "Slippery Rock University of Pennsylvania",
        "hyphenated": "slippery-rock-university-of-pennsylvania"
    },
    {
        "id": "216047",
        "name": "Saint Charles Borromeo Seminary-Overbrook",
        "hyphenated": "saint-charles-borromeo-seminary-overbrook"
    },
    {
        "id": "216074",
        "name": "South Hills Beauty Academy",
        "hyphenated": "south-hills-beauty-academy"
    },
    {
        "id": "216083",
        "name": "South Hills School of Business & Technology",
        "hyphenated": "south-hills-school-of-business-&-technology"
    },
    {
        "id": "216162",
        "name": "St Lukes Hospital School of Nursing",
        "hyphenated": "st-lukes-hospital-school-of-nursing"
    },
    {
        "id": "216223",
        "name": "The Beauty Institute",
        "hyphenated": "the-beauty-institute"
    },
    {
        "id": "216278",
        "name": "Susquehanna University",
        "hyphenated": "susquehanna-university"
    },
    {
        "id": "216296",
        "name": "Thaddeus Stevens College of Technology",
        "hyphenated": "thaddeus-stevens-college-of-technology"
    },
    {
        "id": "216311",
        "name": "Talmudical Yeshiva of Philadelphia",
        "hyphenated": "talmudical-yeshiva-of-philadelphia"
    },
    {
        "id": "216348",
        "name": "Theological Seminary of the Reformed Episcopal Church",
        "hyphenated": "theological-seminary-of-the-reformed-episcopal-church"
    },
    {
        "id": "216357",
        "name": "Thiel College",
        "hyphenated": "thiel-college"
    },
    {
        "id": "216436",
        "name": "Triangle Tech Inc-Pittsburgh",
        "hyphenated": "triangle-tech-inc-pittsburgh"
    },
    {
        "id": "216445",
        "name": "Triangle Tech Inc-Greensburg",
        "hyphenated": "triangle-tech-inc-greensburg"
    },
    {
        "id": "216454",
        "name": "Triangle Tech Inc-Dubois",
        "hyphenated": "triangle-tech-inc-dubois"
    },
    {
        "id": "216463",
        "name": "Trinity Episcopal School for Ministry",
        "hyphenated": "trinity-episcopal-school-for-ministry"
    },
    {
        "id": "216524",
        "name": "Ursinus College",
        "hyphenated": "ursinus-college"
    },
    {
        "id": "216542",
        "name": "University of Valley Forge",
        "hyphenated": "university-of-valley-forge"
    },
    {
        "id": "216551",
        "name": "Valley Forge Military College",
        "hyphenated": "valley-forge-military-college"
    },
    {
        "id": "216579",
        "name": "Venus Beauty Academy",
        "hyphenated": "venus-beauty-academy"
    },
    {
        "id": "216630",
        "name": "Washington Health System School of Nursing",
        "hyphenated": "washington-health-system-school-of-nursing"
    },
    {
        "id": "216667",
        "name": "Washington & Jefferson College",
        "hyphenated": "washington-&-jefferson-college"
    },
    {
        "id": "216694",
        "name": "Waynesburg University",
        "hyphenated": "waynesburg-university"
    },
    {
        "id": "216746",
        "name": "Welder Training and Testing Institute",
        "hyphenated": "welder-training-and-testing-institute"
    },
    {
        "id": "216764",
        "name": "West Chester University of Pennsylvania",
        "hyphenated": "west-chester-university-of-pennsylvania"
    },
    {
        "id": "216773",
        "name": "Western Pennsylvania Hospital School of Nursing",
        "hyphenated": "western-pennsylvania-hospital-school-of-nursing"
    },
    {
        "id": "216782",
        "name": "Pittsburgh Career Institute",
        "hyphenated": "pittsburgh-career-institute"
    },
    {
        "id": "216807",
        "name": "Westminster College",
        "hyphenated": "westminster-college"
    },
    {
        "id": "216816",
        "name": "Westminster Theological Seminary",
        "hyphenated": "westminster-theological-seminary"
    },
    {
        "id": "216825",
        "name": "Westmoreland County Community College",
        "hyphenated": "westmoreland-county-community-college"
    },
    {
        "id": "216852",
        "name": "Widener University",
        "hyphenated": "widener-university"
    },
    {
        "id": "216931",
        "name": "Wilkes University",
        "hyphenated": "wilkes-university"
    },
    {
        "id": "216940",
        "name": "Williamson College of the Trades",
        "hyphenated": "williamson-college-of-the-trades"
    },
    {
        "id": "217013",
        "name": "Wilson College",
        "hyphenated": "wilson-college"
    },
    {
        "id": "217040",
        "name": "Yeshivath Beth Moshe",
        "hyphenated": "yeshivath-beth-moshe"
    },
    {
        "id": "217059",
        "name": "York College of Pennsylvania",
        "hyphenated": "york-college-of-pennsylvania"
    },
    {
        "id": "217077",
        "name": "YTI Career Institute-York",
        "hyphenated": "yti-career-institute-york"
    },
    {
        "id": "217165",
        "name": "Bryant University",
        "hyphenated": "bryant-university"
    },
    {
        "id": "217235",
        "name": "Johnson & Wales University-Providence",
        "hyphenated": "johnson-&-wales-university-providence"
    },
    {
        "id": "217305",
        "name": "New England Institute of Technology",
        "hyphenated": "new-england-institute-of-technology"
    },
    {
        "id": "217323",
        "name": "New England Tractor Trailer Training School of Rhode Island",
        "hyphenated": "new-england-tractor-trailer-training-school-of-rhode-island"
    },
    {
        "id": "217402",
        "name": "Providence College",
        "hyphenated": "providence-college"
    },
    {
        "id": "217420",
        "name": "Rhode Island College",
        "hyphenated": "rhode-island-college"
    },
    {
        "id": "217475",
        "name": "Community College of Rhode Island",
        "hyphenated": "community-college-of-rhode-island"
    },
    {
        "id": "217484",
        "name": "University of Rhode Island",
        "hyphenated": "university-of-rhode-island"
    },
    {
        "id": "217493",
        "name": "Rhode Island School of Design",
        "hyphenated": "rhode-island-school-of-design"
    },
    {
        "id": "217518",
        "name": "Roger Williams University",
        "hyphenated": "roger-williams-university"
    },
    {
        "id": "217536",
        "name": "Salve Regina University",
        "hyphenated": "salve-regina-university"
    },
    {
        "id": "217581",
        "name": "Empire Beauty School-Warwick",
        "hyphenated": "empire-beauty-school-warwick"
    },
    {
        "id": "217606",
        "name": "Northpoint Bible College",
        "hyphenated": "northpoint-bible-college"
    },
    {
        "id": "217615",
        "name": "Aiken Technical College",
        "hyphenated": "aiken-technical-college"
    },
    {
        "id": "217624",
        "name": "Allen University",
        "hyphenated": "allen-university"
    },
    {
        "id": "217633",
        "name": "Anderson University",
        "hyphenated": "anderson-university"
    },
    {
        "id": "217688",
        "name": "Charleston Southern University",
        "hyphenated": "charleston-southern-university"
    },
    {
        "id": "217712",
        "name": "Technical College of the Lowcountry",
        "hyphenated": "technical-college-of-the-lowcountry"
    },
    {
        "id": "217721",
        "name": "Benedict College",
        "hyphenated": "benedict-college"
    },
    {
        "id": "217749",
        "name": "Bob Jones University",
        "hyphenated": "bob-jones-university"
    },
    {
        "id": "217776",
        "name": "Southern Wesleyan University",
        "hyphenated": "southern-wesleyan-university"
    },
    {
        "id": "217794",
        "name": "Charleston Cosmetology Institute",
        "hyphenated": "charleston-cosmetology-institute"
    },
    {
        "id": "217819",
        "name": "College of Charleston",
        "hyphenated": "college-of-charleston"
    },
    {
        "id": "217828",
        "name": "Charzanne Beauty College",
        "hyphenated": "charzanne-beauty-college"
    },
    {
        "id": "217837",
        "name": "Northeastern Technical College",
        "hyphenated": "northeastern-technical-college"
    },
    {
        "id": "217864",
        "name": "Citadel Military College of South Carolina",
        "hyphenated": "citadel-military-college-of-south-carolina"
    },
    {
        "id": "217873",
        "name": "Claflin University",
        "hyphenated": "claflin-university"
    },
    {
        "id": "217891",
        "name": "Clinton College",
        "hyphenated": "clinton-college"
    },
    {
        "id": "217907",
        "name": "Coker University",
        "hyphenated": "coker-university"
    },
    {
        "id": "217925",
        "name": "Columbia International University",
        "hyphenated": "columbia-international-university"
    },
    {
        "id": "217934",
        "name": "Columbia College",
        "hyphenated": "columbia-college"
    },
    {
        "id": "217961",
        "name": "Converse University",
        "hyphenated": "converse-university"
    },
    {
        "id": "217989",
        "name": "Denmark Technical College",
        "hyphenated": "denmark-technical-college"
    },
    {
        "id": "217998",
        "name": "Erskine College",
        "hyphenated": "erskine-college"
    },
    {
        "id": "218025",
        "name": "Florence-Darlington Technical College",
        "hyphenated": "florence-darlington-technical-college"
    },
    {
        "id": "218061",
        "name": "Francis Marion University",
        "hyphenated": "francis-marion-university"
    },
    {
        "id": "218070",
        "name": "Furman University",
        "hyphenated": "furman-university"
    },
    {
        "id": "218113",
        "name": "Greenville Technical College",
        "hyphenated": "greenville-technical-college"
    },
    {
        "id": "218140",
        "name": "Horry-Georgetown Technical College",
        "hyphenated": "horry-georgetown-technical-college"
    },
    {
        "id": "218159",
        "name": "Kenneth Shuler School of Cosmetology-North Augusta",
        "hyphenated": "kenneth-shuler-school-of-cosmetology-north-augusta"
    },
    {
        "id": "218195",
        "name": "Kenneth Shuler School of Cosmetology and Nails-Columbia",
        "hyphenated": "kenneth-shuler-school-of-cosmetology-and-nails-columbia"
    },
    {
        "id": "218229",
        "name": "Lander University",
        "hyphenated": "lander-university"
    },
    {
        "id": "218238",
        "name": "Limestone University",
        "hyphenated": "limestone-university"
    },
    {
        "id": "218335",
        "name": "Medical University of South Carolina",
        "hyphenated": "medical-university-of-south-carolina"
    },
    {
        "id": "218353",
        "name": "Midlands Technical College",
        "hyphenated": "midlands-technical-college"
    },
    {
        "id": "218399",
        "name": "Morris College",
        "hyphenated": "morris-college"
    },
    {
        "id": "218414",
        "name": "Newberry College",
        "hyphenated": "newberry-college"
    },
    {
        "id": "218441",
        "name": "North Greenville University",
        "hyphenated": "north-greenville-university"
    },
    {
        "id": "218487",
        "name": "Orangeburg Calhoun Technical College",
        "hyphenated": "orangeburg-calhoun-technical-college"
    },
    {
        "id": "218520",
        "name": "Piedmont Technical College",
        "hyphenated": "piedmont-technical-college"
    },
    {
        "id": "218539",
        "name": "Presbyterian College",
        "hyphenated": "presbyterian-college"
    },
    {
        "id": "218645",
        "name": "University of South Carolina Aiken",
        "hyphenated": "university-of-south-carolina-aiken"
    },
    {
        "id": "218654",
        "name": "University of South Carolina Beaufort",
        "hyphenated": "university-of-south-carolina-beaufort"
    },
    {
        "id": "218672",
        "name": "University of South Carolina-Lancaster",
        "hyphenated": "university-of-south-carolina-lancaster"
    },
    {
        "id": "218681",
        "name": "University of South Carolina-Salkehatchie",
        "hyphenated": "university-of-south-carolina-salkehatchie"
    },
    {
        "id": "218690",
        "name": "University of South Carolina-Sumter",
        "hyphenated": "university-of-south-carolina-sumter"
    },
    {
        "id": "218706",
        "name": "University of South Carolina-Union",
        "hyphenated": "university-of-south-carolina-union"
    },
    {
        "id": "218724",
        "name": "Coastal Carolina University",
        "hyphenated": "coastal-carolina-university"
    },
    {
        "id": "218733",
        "name": "South Carolina State University",
        "hyphenated": "south-carolina-state-university"
    },
    {
        "id": "218742",
        "name": "University of South Carolina-Upstate",
        "hyphenated": "university-of-south-carolina-upstate"
    },
    {
        "id": "218751",
        "name": "Sherman College of Chiropractic",
        "hyphenated": "sherman-college-of-chiropractic"
    },
    {
        "id": "218821",
        "name": "Spartanburg Methodist College",
        "hyphenated": "spartanburg-methodist-college"
    },
    {
        "id": "218830",
        "name": "Spartanburg Community College",
        "hyphenated": "spartanburg-community-college"
    },
    {
        "id": "218858",
        "name": "Central Carolina Technical College",
        "hyphenated": "central-carolina-technical-college"
    },
    {
        "id": "218885",
        "name": "Tri-County Technical College",
        "hyphenated": "tri-county-technical-college"
    },
    {
        "id": "218894",
        "name": "Trident Technical College",
        "hyphenated": "trident-technical-college"
    },
    {
        "id": "218919",
        "name": "Voorhees University",
        "hyphenated": "voorhees-university"
    },
    {
        "id": "218955",
        "name": "Williamsburg Technical College",
        "hyphenated": "williamsburg-technical-college"
    },
    {
        "id": "218964",
        "name": "Winthrop University",
        "hyphenated": "winthrop-university"
    },
    {
        "id": "218973",
        "name": "Wofford College",
        "hyphenated": "wofford-college"
    },
    {
        "id": "218991",
        "name": "York Technical College",
        "hyphenated": "york-technical-college"
    },
    {
        "id": "219000",
        "name": "Augustana University",
        "hyphenated": "augustana-university"
    },
    {
        "id": "219037",
        "name": "The Salon Professional Academy",
        "hyphenated": "the-salon-professional-academy"
    },
    {
        "id": "219046",
        "name": "Black Hills State University",
        "hyphenated": "black-hills-state-university"
    },
    {
        "id": "219082",
        "name": "Dakota State University",
        "hyphenated": "dakota-state-university"
    },
    {
        "id": "219091",
        "name": "Dakota Wesleyan University",
        "hyphenated": "dakota-wesleyan-university"
    },
    {
        "id": "219125",
        "name": "Paul Mitchell the School-Rapid City",
        "hyphenated": "paul-mitchell-the-school-rapid-city"
    },
    {
        "id": "219143",
        "name": "Lake Area Technical College",
        "hyphenated": "lake-area-technical-college"
    },
    {
        "id": "219170",
        "name": "Avera McKennan Hospital School of Radiologic Technology",
        "hyphenated": "avera-mckennan-hospital-school-of-radiologic-technology"
    },
    {
        "id": "219189",
        "name": "Mitchell Technical College",
        "hyphenated": "mitchell-technical-college"
    },
    {
        "id": "219198",
        "name": "Mount Marty University",
        "hyphenated": "mount-marty-university"
    },
    {
        "id": "219204",
        "name": "National American University-Rapid City",
        "hyphenated": "national-american-university-rapid-city"
    },
    {
        "id": "219240",
        "name": "Kairos University",
        "hyphenated": "kairos-university"
    },
    {
        "id": "219259",
        "name": "Northern State University",
        "hyphenated": "northern-state-university"
    },
    {
        "id": "219277",
        "name": "Oglala Lakota College",
        "hyphenated": "oglala-lakota-college"
    },
    {
        "id": "219295",
        "name": "Presentation College",
        "hyphenated": "presentation-college"
    },
    {
        "id": "219338",
        "name": "Avera Sacred Heart Hospital",
        "hyphenated": "avera-sacred-heart-hospital"
    },
    {
        "id": "219347",
        "name": "South Dakota School of Mines and Technology",
        "hyphenated": "south-dakota-school-of-mines-and-technology"
    },
    {
        "id": "219356",
        "name": "South Dakota State University",
        "hyphenated": "south-dakota-state-university"
    },
    {
        "id": "219374",
        "name": "Sinte Gleska University",
        "hyphenated": "sinte-gleska-university"
    },
    {
        "id": "219383",
        "name": "University of Sioux Falls",
        "hyphenated": "university-of-sioux-falls"
    },
    {
        "id": "219392",
        "name": "Sanford Medical Center",
        "hyphenated": "sanford-medical-center"
    },
    {
        "id": "219408",
        "name": "Sisseton Wahpeton College",
        "hyphenated": "sisseton-wahpeton-college"
    },
    {
        "id": "219426",
        "name": "Southeast Technical College",
        "hyphenated": "southeast-technical-college"
    },
    {
        "id": "219453",
        "name": "Stewart School",
        "hyphenated": "stewart-school"
    },
    {
        "id": "219471",
        "name": "University of South Dakota",
        "hyphenated": "university-of-south-dakota"
    },
    {
        "id": "219480",
        "name": "Western Dakota Technical College",
        "hyphenated": "western-dakota-technical-college"
    },
    {
        "id": "219505",
        "name": "American Baptist College",
        "hyphenated": "american-baptist-college"
    },
    {
        "id": "219587",
        "name": "Arnolds Beauty School",
        "hyphenated": "arnolds-beauty-school"
    },
    {
        "id": "219596",
        "name": "Tennessee College of Applied Technology-Athens",
        "hyphenated": "tennessee-college-of-applied-technology-athens"
    },
    {
        "id": "219602",
        "name": "Austin Peay State University",
        "hyphenated": "austin-peay-state-university"
    },
    {
        "id": "219639",
        "name": "Baptist Health Sciences University",
        "hyphenated": "baptist-health-sciences-university"
    },
    {
        "id": "219709",
        "name": "Belmont University",
        "hyphenated": "belmont-university"
    },
    {
        "id": "219718",
        "name": "Bethel University",
        "hyphenated": "bethel-university"
    },
    {
        "id": "219790",
        "name": "Bryan College-Dayton",
        "hyphenated": "bryan-college-dayton"
    },
    {
        "id": "219806",
        "name": "Carson-Newman University",
        "hyphenated": "carson-newman-university"
    },
    {
        "id": "219824",
        "name": "Chattanooga State Community College",
        "hyphenated": "chattanooga-state-community-college"
    },
    {
        "id": "219833",
        "name": "Christian Brothers University",
        "hyphenated": "christian-brothers-university"
    },
    {
        "id": "219842",
        "name": "Pentecostal Theological Seminary",
        "hyphenated": "pentecostal-theological-seminary"
    },
    {
        "id": "219851",
        "name": "Austin's Beauty College Inc",
        "hyphenated": "austin's-beauty-college-inc"
    },
    {
        "id": "219879",
        "name": "Cleveland State Community College",
        "hyphenated": "cleveland-state-community-college"
    },
    {
        "id": "219888",
        "name": "Columbia State Community College",
        "hyphenated": "columbia-state-community-college"
    },
    {
        "id": "219903",
        "name": "Concorde Career College-Memphis",
        "hyphenated": "concorde-career-college-memphis"
    },
    {
        "id": "219921",
        "name": "Tennessee College of Applied Technology-Covington",
        "hyphenated": "tennessee-college-of-applied-technology-covington"
    },
    {
        "id": "219949",
        "name": "Cumberland University",
        "hyphenated": "cumberland-university"
    },
    {
        "id": "219976",
        "name": "Lipscomb University",
        "hyphenated": "lipscomb-university"
    },
    {
        "id": "219994",
        "name": "Tennessee College of Applied Technology-Dickson",
        "hyphenated": "tennessee-college-of-applied-technology-dickson"
    },
    {
        "id": "220002",
        "name": "Hussian College-Daymar College Nashville",
        "hyphenated": "hussian-college-daymar-college-nashville"
    },
    {
        "id": "220057",
        "name": "Dyersburg State Community College",
        "hyphenated": "dyersburg-state-community-college"
    },
    {
        "id": "220075",
        "name": "East Tennessee State University",
        "hyphenated": "east-tennessee-state-university"
    },
    {
        "id": "220118",
        "name": "Chattanooga College Medical Dental and Technical Careers",
        "hyphenated": "chattanooga-college-medical-dental-and-technical-careers"
    },
    {
        "id": "220127",
        "name": "Tennessee College of Applied Technology-Elizabethton",
        "hyphenated": "tennessee-college-of-applied-technology-elizabethton"
    },
    {
        "id": "220163",
        "name": "The Hair Academy LLC",
        "hyphenated": "the-hair-academy-llc"
    },
    {
        "id": "220181",
        "name": "Fisk University",
        "hyphenated": "fisk-university"
    },
    {
        "id": "220206",
        "name": "Welch College",
        "hyphenated": "welch-college"
    },
    {
        "id": "220215",
        "name": "Freed-Hardeman University",
        "hyphenated": "freed-hardeman-university"
    },
    {
        "id": "220251",
        "name": "Tennessee College of Applied Technology-Harriman",
        "hyphenated": "tennessee-college-of-applied-technology-harriman"
    },
    {
        "id": "220279",
        "name": "Tennessee College of Applied Technology-Hartsville",
        "hyphenated": "tennessee-college-of-applied-technology-hartsville"
    },
    {
        "id": "220321",
        "name": "Tennessee College of Applied Technology-Hohenwald",
        "hyphenated": "tennessee-college-of-applied-technology-hohenwald"
    },
    {
        "id": "220394",
        "name": "Tennessee College of Applied Technology-Jacksboro",
        "hyphenated": "tennessee-college-of-applied-technology-jacksboro"
    },
    {
        "id": "220400",
        "name": "Jackson State Community College",
        "hyphenated": "jackson-state-community-college"
    },
    {
        "id": "220464",
        "name": "John A Gupton College",
        "hyphenated": "john-a-gupton-college"
    },
    {
        "id": "220473",
        "name": "Johnson University",
        "hyphenated": "johnson-university"
    },
    {
        "id": "220491",
        "name": "Paul Mitchell the School-Murfreesboro",
        "hyphenated": "paul-mitchell-the-school-murfreesboro"
    },
    {
        "id": "220516",
        "name": "King University",
        "hyphenated": "king-university"
    },
    {
        "id": "220552",
        "name": "South College",
        "hyphenated": "south-college"
    },
    {
        "id": "220598",
        "name": "Lane College",
        "hyphenated": "lane-college"
    },
    {
        "id": "220604",
        "name": "Le Moyne-Owen College",
        "hyphenated": "le-moyne-owen-college"
    },
    {
        "id": "220613",
        "name": "Lee University",
        "hyphenated": "lee-university"
    },
    {
        "id": "220631",
        "name": "Lincoln Memorial University",
        "hyphenated": "lincoln-memorial-university"
    },
    {
        "id": "220640",
        "name": "Tennessee College of Applied Technology-Livingston",
        "hyphenated": "tennessee-college-of-applied-technology-livingston"
    },
    {
        "id": "220701",
        "name": "The University of Tennessee Southern",
        "hyphenated": "the-university-of-tennessee-southern"
    },
    {
        "id": "220710",
        "name": "Maryville College",
        "hyphenated": "maryville-college"
    },
    {
        "id": "220756",
        "name": "Tennessee College of Applied Technology-McKenzie",
        "hyphenated": "tennessee-college-of-applied-technology-mckenzie"
    },
    {
        "id": "220765",
        "name": "Genesis Career College-Cookeville",
        "hyphenated": "genesis-career-college-cookeville"
    },
    {
        "id": "220792",
        "name": "Meharry Medical College",
        "hyphenated": "meharry-medical-college"
    },
    {
        "id": "220853",
        "name": "Tennessee College of Applied Technology-Memphis",
        "hyphenated": "tennessee-college-of-applied-technology-memphis"
    },
    {
        "id": "220862",
        "name": "University of Memphis",
        "hyphenated": "university-of-memphis"
    },
    {
        "id": "220871",
        "name": "Memphis Theological Seminary",
        "hyphenated": "memphis-theological-seminary"
    },
    {
        "id": "220978",
        "name": "Middle Tennessee State University",
        "hyphenated": "middle-tennessee-state-university"
    },
    {
        "id": "220996",
        "name": "Middle Tennessee School of Anesthesia Inc",
        "hyphenated": "middle-tennessee-school-of-anesthesia-inc"
    },
    {
        "id": "221050",
        "name": "Tennessee College of Applied Technology-Morristown",
        "hyphenated": "tennessee-college-of-applied-technology-morristown"
    },
    {
        "id": "221096",
        "name": "Motlow State Community College",
        "hyphenated": "motlow-state-community-college"
    },
    {
        "id": "221102",
        "name": "Tennessee College of Applied Technology-Murfreesboro",
        "hyphenated": "tennessee-college-of-applied-technology-murfreesboro"
    },
    {
        "id": "221148",
        "name": "Lincoln College of Technology-Nashville",
        "hyphenated": "lincoln-college-of-technology-nashville"
    },
    {
        "id": "221184",
        "name": "Nashville State Community College",
        "hyphenated": "nashville-state-community-college"
    },
    {
        "id": "221236",
        "name": "Tennessee College of Applied Technology Northwest",
        "hyphenated": "tennessee-college-of-applied-technology-northwest"
    },
    {
        "id": "221281",
        "name": "Tennessee College of Applied Technology-Paris",
        "hyphenated": "tennessee-college-of-applied-technology-paris"
    },
    {
        "id": "221333",
        "name": "Tennessee College of Applied Technology-Pulaski",
        "hyphenated": "tennessee-college-of-applied-technology-pulaski"
    },
    {
        "id": "221351",
        "name": "Rhodes College",
        "hyphenated": "rhodes-college"
    },
    {
        "id": "221388",
        "name": "Tennessee College of Applied Technology-Ripley",
        "hyphenated": "tennessee-college-of-applied-technology-ripley"
    },
    {
        "id": "221397",
        "name": "Roane State Community College",
        "hyphenated": "roane-state-community-college"
    },
    {
        "id": "221430",
        "name": "Tennessee College of Applied Technology-Crump",
        "hyphenated": "tennessee-college-of-applied-technology-crump"
    },
    {
        "id": "221485",
        "name": "Southwest Tennessee Community College",
        "hyphenated": "southwest-tennessee-community-college"
    },
    {
        "id": "221494",
        "name": "Tennessee College of Applied Technology-Shelbyville",
        "hyphenated": "tennessee-college-of-applied-technology-shelbyville"
    },
    {
        "id": "221519",
        "name": "The University of the South",
        "hyphenated": "the-university-of-the-south"
    },
    {
        "id": "221582",
        "name": "Tennessee College of Applied Technology-Oneida-Huntsville",
        "hyphenated": "tennessee-college-of-applied-technology-oneida-huntsville"
    },
    {
        "id": "221591",
        "name": "Tennessee College of Applied Technology-Crossville",
        "hyphenated": "tennessee-college-of-applied-technology-crossville"
    },
    {
        "id": "221607",
        "name": "Tennessee College of Applied Technology-McMinnville",
        "hyphenated": "tennessee-college-of-applied-technology-mcminnville"
    },
    {
        "id": "221616",
        "name": "Tennessee College of Applied Technology-Jackson",
        "hyphenated": "tennessee-college-of-applied-technology-jackson"
    },
    {
        "id": "221625",
        "name": "Tennessee College of Applied Technology-Knoxville",
        "hyphenated": "tennessee-college-of-applied-technology-knoxville"
    },
    {
        "id": "221643",
        "name": "Pellissippi State Community College",
        "hyphenated": "pellissippi-state-community-college"
    },
    {
        "id": "221661",
        "name": "Southern Adventist University",
        "hyphenated": "southern-adventist-university"
    },
    {
        "id": "221670",
        "name": "Southern College of Optometry",
        "hyphenated": "southern-college-of-optometry"
    },
    {
        "id": "221731",
        "name": "Tennessee Wesleyan University",
        "hyphenated": "tennessee-wesleyan-university"
    },
    {
        "id": "221740",
        "name": "The University of Tennessee-Chattanooga",
        "hyphenated": "the-university-of-tennessee-chattanooga"
    },
    {
        "id": "221768",
        "name": "The University of Tennessee-Martin",
        "hyphenated": "the-university-of-tennessee-martin"
    },
    {
        "id": "221829",
        "name": "Tennessee School of Beauty of Knoxville Inc",
        "hyphenated": "tennessee-school-of-beauty-of-knoxville-inc"
    },
    {
        "id": "221838",
        "name": "Tennessee State University",
        "hyphenated": "tennessee-state-university"
    },
    {
        "id": "221847",
        "name": "Tennessee Technological University",
        "hyphenated": "tennessee-technological-university"
    },
    {
        "id": "221892",
        "name": "Trevecca Nazarene University",
        "hyphenated": "trevecca-nazarene-university"
    },
    {
        "id": "221908",
        "name": "Northeast State Community College",
        "hyphenated": "northeast-state-community-college"
    },
    {
        "id": "221953",
        "name": "Tusculum University",
        "hyphenated": "tusculum-university"
    },
    {
        "id": "221971",
        "name": "Union University",
        "hyphenated": "union-university"
    },
    {
        "id": "222053",
        "name": "Volunteer State Community College",
        "hyphenated": "volunteer-state-community-college"
    },
    {
        "id": "222062",
        "name": "Walters State Community College",
        "hyphenated": "walters-state-community-college"
    },
    {
        "id": "222105",
        "name": "William R Moore College of Technology",
        "hyphenated": "william-r-moore-college-of-technology"
    },
    {
        "id": "222178",
        "name": "Abilene Christian University",
        "hyphenated": "abilene-christian-university"
    },
    {
        "id": "222567",
        "name": "Alvin Community College",
        "hyphenated": "alvin-community-college"
    },
    {
        "id": "222576",
        "name": "Amarillo College",
        "hyphenated": "amarillo-college"
    },
    {
        "id": "222628",
        "name": "Amberton University",
        "hyphenated": "amberton-university"
    },
    {
        "id": "222822",
        "name": "Angelina College",
        "hyphenated": "angelina-college"
    },
    {
        "id": "222831",
        "name": "Angelo State University",
        "hyphenated": "angelo-state-university"
    },
    {
        "id": "222877",
        "name": "Arlington Baptist University",
        "hyphenated": "arlington-baptist-university"
    },
    {
        "id": "222886",
        "name": "Arlington Career Institute",
        "hyphenated": "arlington-career-institute"
    },
    {
        "id": "222938",
        "name": "The Art Institute of Houston",
        "hyphenated": "the-art-institute-of-houston"
    },
    {
        "id": "222983",
        "name": "Austin College",
        "hyphenated": "austin-college"
    },
    {
        "id": "222992",
        "name": "Austin Community College District",
        "hyphenated": "austin-community-college-district"
    },
    {
        "id": "223001",
        "name": "Austin Presbyterian Theological Seminary",
        "hyphenated": "austin-presbyterian-theological-seminary"
    },
    {
        "id": "223056",
        "name": "Baldwin Beauty School-South Austin",
        "hyphenated": "baldwin-beauty-school-south-austin"
    },
    {
        "id": "223083",
        "name": "Baptist Health System School of Health Professions",
        "hyphenated": "baptist-health-system-school-of-health-professions"
    },
    {
        "id": "223117",
        "name": "Baptist Missionary Association Theological Seminary",
        "hyphenated": "baptist-missionary-association-theological-seminary"
    },
    {
        "id": "223223",
        "name": "Baylor College of Medicine",
        "hyphenated": "baylor-college-of-medicine"
    },
    {
        "id": "223320",
        "name": "Coastal Bend College",
        "hyphenated": "coastal-bend-college"
    },
    {
        "id": "223427",
        "name": "Blinn College District",
        "hyphenated": "blinn-college-district"
    },
    {
        "id": "223463",
        "name": "Remington College-Dallas Campus",
        "hyphenated": "remington-college-dallas-campus"
    },
    {
        "id": "223472",
        "name": "Vet Tech Institute of Houston",
        "hyphenated": "vet-tech-institute-of-houston"
    },
    {
        "id": "223506",
        "name": "Brazosport College",
        "hyphenated": "brazosport-college"
    },
    {
        "id": "223816",
        "name": "Central Texas College",
        "hyphenated": "central-texas-college"
    },
    {
        "id": "223852",
        "name": "Charles and Sues School of Hair Design",
        "hyphenated": "charles-and-sues-school-of-hair-design"
    },
    {
        "id": "223898",
        "name": "Cisco College",
        "hyphenated": "cisco-college"
    },
    {
        "id": "223922",
        "name": "Clarendon College",
        "hyphenated": "clarendon-college"
    },
    {
        "id": "224004",
        "name": "Concordia University Texas",
        "hyphenated": "concordia-university-texas"
    },
    {
        "id": "224013",
        "name": "BK Cosmo College of Cosmetology",
        "hyphenated": "bk-cosmo-college-of-cosmetology"
    },
    {
        "id": "224110",
        "name": "North Central Texas College",
        "hyphenated": "north-central-texas-college"
    },
    {
        "id": "224147",
        "name": "Texas A & M University-Corpus Christi",
        "hyphenated": "texas-a-&-m-university-corpus-christi"
    },
    {
        "id": "224156",
        "name": "Paul Mitchell the School-Dallas",
        "hyphenated": "paul-mitchell-the-school-dallas"
    },
    {
        "id": "224226",
        "name": "Dallas Baptist University",
        "hyphenated": "dallas-baptist-university"
    },
    {
        "id": "224244",
        "name": "Dallas Christian College",
        "hyphenated": "dallas-christian-college"
    },
    {
        "id": "224271",
        "name": "Dallas Institute of Funeral Service",
        "hyphenated": "dallas-institute-of-funeral-service"
    },
    {
        "id": "224305",
        "name": "Dallas Theological Seminary",
        "hyphenated": "dallas-theological-seminary"
    },
    {
        "id": "224323",
        "name": "University of Dallas",
        "hyphenated": "university-of-dallas"
    },
    {
        "id": "224350",
        "name": "Del Mar College",
        "hyphenated": "del-mar-college"
    },
    {
        "id": "224527",
        "name": "East Texas Baptist University",
        "hyphenated": "east-texas-baptist-university"
    },
    {
        "id": "224545",
        "name": "Texas A&M University-Texarkana",
        "hyphenated": "texas-a&m-university-texarkana"
    },
    {
        "id": "224554",
        "name": "Texas A & M University-Commerce",
        "hyphenated": "texas-a-&-m-university-commerce"
    },
    {
        "id": "224615",
        "name": "Dallas College",
        "hyphenated": "dallas-college"
    },
    {
        "id": "224642",
        "name": "El Paso Community College",
        "hyphenated": "el-paso-community-college"
    },
    {
        "id": "224660",
        "name": "Western Technical College",
        "hyphenated": "western-technical-college"
    },
    {
        "id": "224679",
        "name": "Western Technical College",
        "hyphenated": "western-technical-college"
    },
    {
        "id": "224712",
        "name": "Episcopal Theological Seminary of the Southwest",
        "hyphenated": "episcopal-theological-seminary-of-the-southwest"
    },
    {
        "id": "224758",
        "name": "Exposito School of Hair Design",
        "hyphenated": "exposito-school-of-hair-design"
    },
    {
        "id": "224776",
        "name": "Miami International University of Art & Design-Art Institute Dallas",
        "hyphenated": "miami-international-university-of-art-&-design-art-institute-dallas"
    },
    {
        "id": "224828",
        "name": "Fort Worth Beauty School",
        "hyphenated": "fort-worth-beauty-school"
    },
    {
        "id": "224891",
        "name": "Frank Phillips College",
        "hyphenated": "frank-phillips-college"
    },
    {
        "id": "224961",
        "name": "Galveston College",
        "hyphenated": "galveston-college"
    },
    {
        "id": "225070",
        "name": "Grayson College",
        "hyphenated": "grayson-college"
    },
    {
        "id": "225201",
        "name": "Hallmark University",
        "hyphenated": "hallmark-university"
    },
    {
        "id": "225247",
        "name": "Hardin-Simmons University",
        "hyphenated": "hardin-simmons-university"
    },
    {
        "id": "225308",
        "name": "Trinity Valley Community College",
        "hyphenated": "trinity-valley-community-college"
    },
    {
        "id": "225371",
        "name": "Hill College",
        "hyphenated": "hill-college"
    },
    {
        "id": "225399",
        "name": "Houston Baptist University",
        "hyphenated": "houston-baptist-university"
    },
    {
        "id": "225414",
        "name": "University of Houston-Clear Lake",
        "hyphenated": "university-of-houston-clear-lake"
    },
    {
        "id": "225423",
        "name": "Houston Community College",
        "hyphenated": "houston-community-college"
    },
    {
        "id": "225432",
        "name": "University of Houston-Downtown",
        "hyphenated": "university-of-houston-downtown"
    },
    {
        "id": "225502",
        "name": "University of Houston-Victoria",
        "hyphenated": "university-of-houston-victoria"
    },
    {
        "id": "225511",
        "name": "University of Houston",
        "hyphenated": "university-of-houston"
    },
    {
        "id": "225520",
        "name": "Howard College",
        "hyphenated": "howard-college"
    },
    {
        "id": "225548",
        "name": "Howard Payne University",
        "hyphenated": "howard-payne-university"
    },
    {
        "id": "225575",
        "name": "Huston-Tillotson University",
        "hyphenated": "huston-tillotson-university"
    },
    {
        "id": "225627",
        "name": "University of the Incarnate Word",
        "hyphenated": "university-of-the-incarnate-word"
    },
    {
        "id": "225876",
        "name": "Jacksonville College-Main Campus",
        "hyphenated": "jacksonville-college-main-campus"
    },
    {
        "id": "225885",
        "name": "Jarvis Christian University",
        "hyphenated": "jarvis-christian-university"
    },
    {
        "id": "225991",
        "name": "KD Conservatory College of Film and Dramatic Arts",
        "hyphenated": "kd-conservatory-college-of-film-and-dramatic-arts"
    },
    {
        "id": "226019",
        "name": "Kilgore College",
        "hyphenated": "kilgore-college"
    },
    {
        "id": "226091",
        "name": "Lamar University",
        "hyphenated": "lamar-university"
    },
    {
        "id": "226107",
        "name": "Lamar State College-Orange",
        "hyphenated": "lamar-state-college-orange"
    },
    {
        "id": "226116",
        "name": "Lamar State College-Port Arthur",
        "hyphenated": "lamar-state-college-port-arthur"
    },
    {
        "id": "226125",
        "name": "Laredo Beauty College Inc",
        "hyphenated": "laredo-beauty-college-inc"
    },
    {
        "id": "226134",
        "name": "Laredo College",
        "hyphenated": "laredo-college"
    },
    {
        "id": "226152",
        "name": "Texas A & M International University",
        "hyphenated": "texas-a-&-m-international-university"
    },
    {
        "id": "226204",
        "name": "Lee College",
        "hyphenated": "lee-college"
    },
    {
        "id": "226231",
        "name": "LeTourneau University",
        "hyphenated": "letourneau-university"
    },
    {
        "id": "226277",
        "name": "Lincoln College of Technology-Grand Prairie",
        "hyphenated": "lincoln-college-of-technology-grand-prairie"
    },
    {
        "id": "226383",
        "name": "Lubbock Christian University",
        "hyphenated": "lubbock-christian-university"
    },
    {
        "id": "226408",
        "name": "College of the Mainland",
        "hyphenated": "college-of-the-mainland"
    },
    {
        "id": "226471",
        "name": "University of Mary Hardin-Baylor",
        "hyphenated": "university-of-mary-hardin-baylor"
    },
    {
        "id": "226578",
        "name": "McLennan Community College",
        "hyphenated": "mclennan-community-college"
    },
    {
        "id": "226587",
        "name": "McMurry University",
        "hyphenated": "mcmurry-university"
    },
    {
        "id": "226675",
        "name": "Covenant School of Nursing and Allied Health",
        "hyphenated": "covenant-school-of-nursing-and-allied-health"
    },
    {
        "id": "226806",
        "name": "Midland College",
        "hyphenated": "midland-college"
    },
    {
        "id": "226833",
        "name": "Midwestern State University",
        "hyphenated": "midwestern-state-university"
    },
    {
        "id": "226860",
        "name": "William Edge Institute",
        "hyphenated": "william-edge-institute"
    },
    {
        "id": "226879",
        "name": "Wade College",
        "hyphenated": "wade-college"
    },
    {
        "id": "226903",
        "name": "Southern Careers Institute-Austin",
        "hyphenated": "southern-careers-institute-austin"
    },
    {
        "id": "227058",
        "name": "Tint School of Makeup & Cosmetology",
        "hyphenated": "tint-school-of-makeup-&-cosmetology"
    },
    {
        "id": "227146",
        "name": "Navarro College",
        "hyphenated": "navarro-college"
    },
    {
        "id": "227182",
        "name": "Lone Star College System",
        "hyphenated": "lone-star-college-system"
    },
    {
        "id": "227216",
        "name": "University of North Texas",
        "hyphenated": "university-of-north-texas"
    },
    {
        "id": "227225",
        "name": "Northeast Texas Community College",
        "hyphenated": "northeast-texas-community-college"
    },
    {
        "id": "227289",
        "name": "Oblate School of Theology",
        "hyphenated": "oblate-school-of-theology"
    },
    {
        "id": "227298",
        "name": "Ocean Corporation",
        "hyphenated": "ocean-corporation"
    },
    {
        "id": "227304",
        "name": "Odessa College",
        "hyphenated": "odessa-college"
    },
    {
        "id": "227313",
        "name": "Ogle School Hair Skin Nails-Arlington",
        "hyphenated": "ogle-school-hair-skin-nails-arlington"
    },
    {
        "id": "227322",
        "name": "Ogle School Hair Skin Nails-Ft Worth",
        "hyphenated": "ogle-school-hair-skin-nails-ft-worth"
    },
    {
        "id": "227331",
        "name": "Our Lady of the Lake University",
        "hyphenated": "our-lady-of-the-lake-university"
    },
    {
        "id": "227368",
        "name": "The University of Texas Rio Grande Valley",
        "hyphenated": "the-university-of-texas-rio-grande-valley"
    },
    {
        "id": "227377",
        "name": "Texas Southmost College",
        "hyphenated": "texas-southmost-college"
    },
    {
        "id": "227386",
        "name": "Panola College",
        "hyphenated": "panola-college"
    },
    {
        "id": "227401",
        "name": "Paris Junior College",
        "hyphenated": "paris-junior-college"
    },
    {
        "id": "227429",
        "name": "Paul Quinn College",
        "hyphenated": "paul-quinn-college"
    },
    {
        "id": "227526",
        "name": "Prairie View A & M University",
        "hyphenated": "prairie-view-a-&-m-university"
    },
    {
        "id": "227687",
        "name": "Ranger College",
        "hyphenated": "ranger-college"
    },
    {
        "id": "227748",
        "name": "Aviation Institute of Maintenance-Houston",
        "hyphenated": "aviation-institute-of-maintenance-houston"
    },
    {
        "id": "227845",
        "name": "Saint Edward's University",
        "hyphenated": "saint-edward's-university"
    },
    {
        "id": "227854",
        "name": "St Philip's College",
        "hyphenated": "st-philip's-college"
    },
    {
        "id": "227863",
        "name": "University of St Thomas",
        "hyphenated": "university-of-st-thomas"
    },
    {
        "id": "227881",
        "name": "Sam Houston State University",
        "hyphenated": "sam-houston-state-university"
    },
    {
        "id": "227924",
        "name": "San Antonio College",
        "hyphenated": "san-antonio-college"
    },
    {
        "id": "227979",
        "name": "San Jacinto Community College",
        "hyphenated": "san-jacinto-community-college"
    },
    {
        "id": "228042",
        "name": "Schreiner University",
        "hyphenated": "schreiner-university"
    },
    {
        "id": "228088",
        "name": "Seguin Beauty School-Seguin",
        "hyphenated": "seguin-beauty-school-seguin"
    },
    {
        "id": "228149",
        "name": "St. Mary's University",
        "hyphenated": "st.-mary's-university"
    },
    {
        "id": "228158",
        "name": "South Plains College",
        "hyphenated": "south-plains-college"
    },
    {
        "id": "228185",
        "name": "South Texas Barber College Inc",
        "hyphenated": "south-texas-barber-college-inc"
    },
    {
        "id": "228194",
        "name": "South Texas College of Law Houston",
        "hyphenated": "south-texas-college-of-law-houston"
    },
    {
        "id": "228200",
        "name": "South Texas Vocational Technical Institute-Weslaco",
        "hyphenated": "south-texas-vocational-technical-institute-weslaco"
    },
    {
        "id": "228219",
        "name": "Miller-Motte College-STVT-McAllen",
        "hyphenated": "miller-motte-college-stvt-mcallen"
    },
    {
        "id": "228282",
        "name": "Southwest School of Business and Technical Careers-San Antonio",
        "hyphenated": "southwest-school-of-business-and-technical-careers-san-antonio"
    },
    {
        "id": "228316",
        "name": "Southwest Texas Junior College",
        "hyphenated": "southwest-texas-junior-college"
    },
    {
        "id": "228325",
        "name": "Southwestern Assemblies of God University",
        "hyphenated": "southwestern-assemblies-of-god-university"
    },
    {
        "id": "228343",
        "name": "Southwestern University",
        "hyphenated": "southwestern-university"
    },
    {
        "id": "228431",
        "name": "Stephen F Austin State University",
        "hyphenated": "stephen-f-austin-state-university"
    },
    {
        "id": "228459",
        "name": "Texas State University",
        "hyphenated": "texas-state-university"
    },
    {
        "id": "228468",
        "name": "Southwestern Adventist University",
        "hyphenated": "southwestern-adventist-university"
    },
    {
        "id": "228486",
        "name": "Southwestern Christian College",
        "hyphenated": "southwestern-christian-college"
    },
    {
        "id": "228501",
        "name": "Sul Ross State University",
        "hyphenated": "sul-ross-state-university"
    },
    {
        "id": "228529",
        "name": "Tarleton State University",
        "hyphenated": "tarleton-state-university"
    },
    {
        "id": "228547",
        "name": "Tarrant County College District",
        "hyphenated": "tarrant-county-college-district"
    },
    {
        "id": "228608",
        "name": "Temple College",
        "hyphenated": "temple-college"
    },
    {
        "id": "228635",
        "name": "University of Texas Southwestern Medical Center",
        "hyphenated": "university-of-texas-southwestern-medical-center"
    },
    {
        "id": "228644",
        "name": "The University of Texas Health Science Center at San Antonio",
        "hyphenated": "the-university-of-texas-health-science-center-at-san-antonio"
    },
    {
        "id": "228653",
        "name": "The University of Texas Medical Branch at Galveston",
        "hyphenated": "the-university-of-texas-medical-branch-at-galveston"
    },
    {
        "id": "228699",
        "name": "Texarkana College",
        "hyphenated": "texarkana-college"
    },
    {
        "id": "228705",
        "name": "Texas A & M University-Kingsville",
        "hyphenated": "texas-a-&-m-university-kingsville"
    },
    {
        "id": "228769",
        "name": "The University of Texas at Arlington",
        "hyphenated": "the-university-of-texas-at-arlington"
    },
    {
        "id": "228787",
        "name": "The University of Texas at Dallas",
        "hyphenated": "the-university-of-texas-at-dallas"
    },
    {
        "id": "228796",
        "name": "The University of Texas at El Paso",
        "hyphenated": "the-university-of-texas-at-el-paso"
    },
    {
        "id": "228802",
        "name": "The University of Texas at Tyler",
        "hyphenated": "the-university-of-texas-at-tyler"
    },
    {
        "id": "228866",
        "name": "Texas Chiropractic College Foundation Inc",
        "hyphenated": "texas-chiropractic-college-foundation-inc"
    },
    {
        "id": "228884",
        "name": "Texas College",
        "hyphenated": "texas-college"
    },
    {
        "id": "228909",
        "name": "University of North Texas Health Science Center",
        "hyphenated": "university-of-north-texas-health-science-center"
    },
    {
        "id": "228981",
        "name": "Texas Lutheran University",
        "hyphenated": "texas-lutheran-university"
    },
    {
        "id": "229018",
        "name": "The University of Texas Permian Basin",
        "hyphenated": "the-university-of-texas-permian-basin"
    },
    {
        "id": "229027",
        "name": "The University of Texas at San Antonio",
        "hyphenated": "the-university-of-texas-at-san-antonio"
    },
    {
        "id": "229063",
        "name": "Texas Southern University",
        "hyphenated": "texas-southern-university"
    },
    {
        "id": "229115",
        "name": "Texas Tech University",
        "hyphenated": "texas-tech-university"
    },
    {
        "id": "229160",
        "name": "Texas Wesleyan University",
        "hyphenated": "texas-wesleyan-university"
    },
    {
        "id": "229179",
        "name": "Texas Woman's University",
        "hyphenated": "texas-woman's-university"
    },
    {
        "id": "229267",
        "name": "Trinity University",
        "hyphenated": "trinity-university"
    },
    {
        "id": "229300",
        "name": "The University of Texas Health Science Center at Houston",
        "hyphenated": "the-university-of-texas-health-science-center-at-houston"
    },
    {
        "id": "229337",
        "name": "Texas Tech University Health Sciences Center",
        "hyphenated": "texas-tech-university-health-sciences-center"
    },
    {
        "id": "229355",
        "name": "Tyler Junior College",
        "hyphenated": "tyler-junior-college"
    },
    {
        "id": "229416",
        "name": "Universal Technical Institute of Texas Inc.",
        "hyphenated": "universal-technical-institute-of-texas-inc."
    },
    {
        "id": "229425",
        "name": "UCAS University of Cosmetology Arts & Sciences-McAllen",
        "hyphenated": "ucas-university-of-cosmetology-arts-&-sciences-mcallen"
    },
    {
        "id": "229504",
        "name": "Vernon College",
        "hyphenated": "vernon-college"
    },
    {
        "id": "229522",
        "name": "Victoria Beauty & Barber College",
        "hyphenated": "victoria-beauty-&-barber-college"
    },
    {
        "id": "229540",
        "name": "Victoria College",
        "hyphenated": "victoria-college"
    },
    {
        "id": "229656",
        "name": "Vogue College of Cosmetology-McAllen",
        "hyphenated": "vogue-college-of-cosmetology-mcallen"
    },
    {
        "id": "229780",
        "name": "Wayland Baptist University",
        "hyphenated": "wayland-baptist-university"
    },
    {
        "id": "229799",
        "name": "Weatherford College",
        "hyphenated": "weatherford-college"
    },
    {
        "id": "229814",
        "name": "West Texas A & M University",
        "hyphenated": "west-texas-a-&-m-university"
    },
    {
        "id": "229832",
        "name": "Western Texas College",
        "hyphenated": "western-texas-college"
    },
    {
        "id": "229841",
        "name": "Wharton County Junior College",
        "hyphenated": "wharton-county-junior-college"
    },
    {
        "id": "229887",
        "name": "Wiley College",
        "hyphenated": "wiley-college"
    },
    {
        "id": "230010",
        "name": "Bridgerland Technical College",
        "hyphenated": "bridgerland-technical-college"
    },
    {
        "id": "230047",
        "name": "Brigham Young University-Hawaii",
        "hyphenated": "brigham-young-university-hawaii"
    },
    {
        "id": "230056",
        "name": "Broadview College",
        "hyphenated": "broadview-college"
    },
    {
        "id": "230065",
        "name": "Cameo College of Essential Beauty",
        "hyphenated": "cameo-college-of-essential-beauty"
    },
    {
        "id": "230144",
        "name": "Collectiv Academy",
        "hyphenated": "collectiv-academy"
    },
    {
        "id": "230162",
        "name": "Davis Technical College",
        "hyphenated": "davis-technical-college"
    },
    {
        "id": "230171",
        "name": "Utah Tech University",
        "hyphenated": "utah-tech-university"
    },
    {
        "id": "230199",
        "name": "Evans Hairstyling College-Cedar City",
        "hyphenated": "evans-hairstyling-college-cedar-city"
    },
    {
        "id": "230205",
        "name": "Evans Hairstyling College-St George",
        "hyphenated": "evans-hairstyling-college-st-george"
    },
    {
        "id": "230214",
        "name": "Paul Mitchell the School-Salt Lake City",
        "hyphenated": "paul-mitchell-the-school-salt-lake-city"
    },
    {
        "id": "230366",
        "name": "Eagle Gate College-Murray",
        "hyphenated": "eagle-gate-college-murray"
    },
    {
        "id": "230418",
        "name": "Ensign College",
        "hyphenated": "ensign-college"
    },
    {
        "id": "230490",
        "name": "Ogden-Weber Technical College",
        "hyphenated": "ogden-weber-technical-college"
    },
    {
        "id": "230597",
        "name": "Snow College",
        "hyphenated": "snow-college"
    },
    {
        "id": "230603",
        "name": "Southern Utah University",
        "hyphenated": "southern-utah-university"
    },
    {
        "id": "230676",
        "name": "Uintah Basin Technical College",
        "hyphenated": "uintah-basin-technical-college"
    },
    {
        "id": "230728",
        "name": "Utah State University",
        "hyphenated": "utah-state-university"
    },
    {
        "id": "230737",
        "name": "Utah Valley University",
        "hyphenated": "utah-valley-university"
    },
    {
        "id": "230746",
        "name": "Salt Lake Community College",
        "hyphenated": "salt-lake-community-college"
    },
    {
        "id": "230782",
        "name": "Weber State University",
        "hyphenated": "weber-state-university"
    },
    {
        "id": "230807",
        "name": "Westminster College",
        "hyphenated": "westminster-college"
    },
    {
        "id": "230816",
        "name": "Bennington College",
        "hyphenated": "bennington-college"
    },
    {
        "id": "230834",
        "name": "Castleton University",
        "hyphenated": "castleton-university"
    },
    {
        "id": "230852",
        "name": "Champlain College",
        "hyphenated": "champlain-college"
    },
    {
        "id": "230861",
        "name": "Community College of Vermont",
        "hyphenated": "community-college-of-vermont"
    },
    {
        "id": "230889",
        "name": "Goddard College",
        "hyphenated": "goddard-college"
    },
    {
        "id": "230913",
        "name": "Northern Vermont University",
        "hyphenated": "northern-vermont-university"
    },
    {
        "id": "230995",
        "name": "Norwich University",
        "hyphenated": "norwich-university"
    },
    {
        "id": "231004",
        "name": "O'Briens Aveda Institute",
        "hyphenated": "o'briens-aveda-institute"
    },
    {
        "id": "231059",
        "name": "Saint Michael's College",
        "hyphenated": "saint-michael's-college"
    },
    {
        "id": "231068",
        "name": "SIT Graduate Institute",
        "hyphenated": "sit-graduate-institute"
    },
    {
        "id": "231095",
        "name": "Sterling College",
        "hyphenated": "sterling-college"
    },
    {
        "id": "231147",
        "name": "Vermont Law and Graduate School",
        "hyphenated": "vermont-law-and-graduate-school"
    },
    {
        "id": "231165",
        "name": "Vermont Technical College",
        "hyphenated": "vermont-technical-college"
    },
    {
        "id": "231280",
        "name": "Paul Mitchell the School-Roanoke",
        "hyphenated": "paul-mitchell-the-school-roanoke"
    },
    {
        "id": "231411",
        "name": "Advanced Technology Institute",
        "hyphenated": "advanced-technology-institute"
    },
    {
        "id": "231420",
        "name": "Averett University",
        "hyphenated": "averett-university"
    },
    {
        "id": "231536",
        "name": "Blue Ridge Community College",
        "hyphenated": "blue-ridge-community-college"
    },
    {
        "id": "231554",
        "name": "Bluefield University",
        "hyphenated": "bluefield-university"
    },
    {
        "id": "231581",
        "name": "Bridgewater College",
        "hyphenated": "bridgewater-college"
    },
    {
        "id": "231651",
        "name": "Regent University",
        "hyphenated": "regent-university"
    },
    {
        "id": "231688",
        "name": "Central School of Practical Nursing",
        "hyphenated": "central-school-of-practical-nursing"
    },
    {
        "id": "231697",
        "name": "Central Virginia Community College",
        "hyphenated": "central-virginia-community-college"
    },
    {
        "id": "231712",
        "name": "Christopher Newport University",
        "hyphenated": "christopher-newport-university"
    },
    {
        "id": "231721",
        "name": "Allegiance Beauty School",
        "hyphenated": "allegiance-beauty-school"
    },
    {
        "id": "231785",
        "name": "Bryant & Stratton College-Virginia Beach",
        "hyphenated": "bryant-&-stratton-college-virginia-beach"
    },
    {
        "id": "231873",
        "name": "Mountain Gateway Community College",
        "hyphenated": "mountain-gateway-community-college"
    },
    {
        "id": "231882",
        "name": "Danville Community College",
        "hyphenated": "danville-community-college"
    },
    {
        "id": "231970",
        "name": "Eastern Virginia Medical School",
        "hyphenated": "eastern-virginia-medical-school"
    },
    {
        "id": "232016",
        "name": "Centura College-Virginia Beach",
        "hyphenated": "centura-college-virginia-beach"
    },
    {
        "id": "232025",
        "name": "Emory & Henry College",
        "hyphenated": "emory-&-henry-college"
    },
    {
        "id": "232043",
        "name": "Eastern Mennonite University",
        "hyphenated": "eastern-mennonite-university"
    },
    {
        "id": "232052",
        "name": "Eastern Shore Community College",
        "hyphenated": "eastern-shore-community-college"
    },
    {
        "id": "232089",
        "name": "Ferrum College",
        "hyphenated": "ferrum-college"
    },
    {
        "id": "232195",
        "name": "Germanna Community College",
        "hyphenated": "germanna-community-college"
    },
    {
        "id": "232256",
        "name": "Hampden-Sydney College",
        "hyphenated": "hampden-sydney-college"
    },
    {
        "id": "232265",
        "name": "Hampton University",
        "hyphenated": "hampton-university"
    },
    {
        "id": "232308",
        "name": "Hollins University",
        "hyphenated": "hollins-university"
    },
    {
        "id": "232414",
        "name": "J Sargeant Reynolds Community College",
        "hyphenated": "j-sargeant-reynolds-community-college"
    },
    {
        "id": "232423",
        "name": "James Madison University",
        "hyphenated": "james-madison-university"
    },
    {
        "id": "232450",
        "name": "Brightpoint Community College",
        "hyphenated": "brightpoint-community-college"
    },
    {
        "id": "232557",
        "name": "Liberty University",
        "hyphenated": "liberty-university"
    },
    {
        "id": "232566",
        "name": "Longwood University",
        "hyphenated": "longwood-university"
    },
    {
        "id": "232575",
        "name": "Laurel Ridge Community College",
        "hyphenated": "laurel-ridge-community-college"
    },
    {
        "id": "232609",
        "name": "University of Lynchburg",
        "hyphenated": "university-of-lynchburg"
    },
    {
        "id": "232618",
        "name": "Centra College",
        "hyphenated": "centra-college"
    },
    {
        "id": "232672",
        "name": "Mary Baldwin University",
        "hyphenated": "mary-baldwin-university"
    },
    {
        "id": "232681",
        "name": "University of Mary Washington",
        "hyphenated": "university-of-mary-washington"
    },
    {
        "id": "232706",
        "name": "Marymount University",
        "hyphenated": "marymount-university"
    },
    {
        "id": "232724",
        "name": "Sovah School of Health Professions",
        "hyphenated": "sovah-school-of-health-professions"
    },
    {
        "id": "232788",
        "name": "Mountain Empire Community College",
        "hyphenated": "mountain-empire-community-college"
    },
    {
        "id": "232797",
        "name": "American National University",
        "hyphenated": "american-national-university"
    },
    {
        "id": "232867",
        "name": "New River Community College",
        "hyphenated": "new-river-community-college"
    },
    {
        "id": "232885",
        "name": "Sentara College of Health Sciences",
        "hyphenated": "sentara-college-of-health-sciences"
    },
    {
        "id": "232919",
        "name": "Tidewater Tech-Trades",
        "hyphenated": "tidewater-tech-trades"
    },
    {
        "id": "232937",
        "name": "Norfolk State University",
        "hyphenated": "norfolk-state-university"
    },
    {
        "id": "232946",
        "name": "Northern Virginia Community College",
        "hyphenated": "northern-virginia-community-college"
    },
    {
        "id": "232982",
        "name": "Old Dominion University",
        "hyphenated": "old-dominion-university"
    },
    {
        "id": "233019",
        "name": "Patrick & Henry Community College",
        "hyphenated": "patrick-&-henry-community-college"
    },
    {
        "id": "233037",
        "name": "Paul D Camp Community College",
        "hyphenated": "paul-d-camp-community-college"
    },
    {
        "id": "233082",
        "name": "Southside College of Health Sciences",
        "hyphenated": "southside-college-of-health-sciences"
    },
    {
        "id": "233116",
        "name": "Piedmont Virginia Community College",
        "hyphenated": "piedmont-virginia-community-college"
    },
    {
        "id": "233277",
        "name": "Radford University",
        "hyphenated": "radford-university"
    },
    {
        "id": "233286",
        "name": "Sylvain Melloul International Hair Academy",
        "hyphenated": "sylvain-melloul-international-hair-academy"
    },
    {
        "id": "233295",
        "name": "Randolph-Macon College",
        "hyphenated": "randolph-macon-college"
    },
    {
        "id": "233301",
        "name": "Randolph College",
        "hyphenated": "randolph-college"
    },
    {
        "id": "233310",
        "name": "Rappahannock Community College",
        "hyphenated": "rappahannock-community-college"
    },
    {
        "id": "233329",
        "name": "Fortis College-Norfolk",
        "hyphenated": "fortis-college-norfolk"
    },
    {
        "id": "233338",
        "name": "Richard Bland College",
        "hyphenated": "richard-bland-college"
    },
    {
        "id": "233356",
        "name": "Bon Secours Memorial College of Nursing",
        "hyphenated": "bon-secours-memorial-college-of-nursing"
    },
    {
        "id": "233408",
        "name": "Riverside College of Health Careers",
        "hyphenated": "riverside-college-of-health-careers"
    },
    {
        "id": "233426",
        "name": "Roanoke College",
        "hyphenated": "roanoke-college"
    },
    {
        "id": "233480",
        "name": "Bon Secours St Mary's Hospital School of Medical Imaging",
        "hyphenated": "bon-secours-st-mary's-hospital-school-of-medical-imaging"
    },
    {
        "id": "233541",
        "name": "Shenandoah University",
        "hyphenated": "shenandoah-university"
    },
    {
        "id": "233602",
        "name": "Southeastern Free Will Baptist Bible College",
        "hyphenated": "southeastern-free-will-baptist-bible-college"
    },
    {
        "id": "233611",
        "name": "Southern Virginia University",
        "hyphenated": "southern-virginia-university"
    },
    {
        "id": "233639",
        "name": "Southside Virginia Community College",
        "hyphenated": "southside-virginia-community-college"
    },
    {
        "id": "233648",
        "name": "Southwest Virginia Community College",
        "hyphenated": "southwest-virginia-community-college"
    },
    {
        "id": "233666",
        "name": "Staunton School of Cosmetology",
        "hyphenated": "staunton-school-of-cosmetology"
    },
    {
        "id": "233684",
        "name": "Strayer University-Virginia",
        "hyphenated": "strayer-university-virginia"
    },
    {
        "id": "233718",
        "name": "Sweet Briar College",
        "hyphenated": "sweet-briar-college"
    },
    {
        "id": "233754",
        "name": "Virginia Peninsula Community College",
        "hyphenated": "virginia-peninsula-community-college"
    },
    {
        "id": "233772",
        "name": "Tidewater Community College",
        "hyphenated": "tidewater-community-college"
    },
    {
        "id": "233842",
        "name": "Union Presbyterian Seminary",
        "hyphenated": "union-presbyterian-seminary"
    },
    {
        "id": "233897",
        "name": "University of Virginia's College at Wise",
        "hyphenated": "university-of-virginia's-college-at-wise"
    },
    {
        "id": "233903",
        "name": "Virginia Highlands Community College",
        "hyphenated": "virginia-highlands-community-college"
    },
    {
        "id": "233949",
        "name": "Virginia Western Community College",
        "hyphenated": "virginia-western-community-college"
    },
    {
        "id": "234030",
        "name": "Virginia Commonwealth University",
        "hyphenated": "virginia-commonwealth-university"
    },
    {
        "id": "234085",
        "name": "Virginia Military Institute",
        "hyphenated": "virginia-military-institute"
    },
    {
        "id": "234119",
        "name": "Asher Institute of Hampton",
        "hyphenated": "asher-institute-of-hampton"
    },
    {
        "id": "234137",
        "name": "Virginia University of Lynchburg",
        "hyphenated": "virginia-university-of-lynchburg"
    },
    {
        "id": "234155",
        "name": "Virginia State University",
        "hyphenated": "virginia-state-university"
    },
    {
        "id": "234164",
        "name": "Virginia Union University",
        "hyphenated": "virginia-union-university"
    },
    {
        "id": "234173",
        "name": "Virginia Wesleyan University",
        "hyphenated": "virginia-wesleyan-university"
    },
    {
        "id": "234377",
        "name": "Wytheville Community College",
        "hyphenated": "wytheville-community-college"
    },
    {
        "id": "234669",
        "name": "Bellevue College",
        "hyphenated": "bellevue-college"
    },
    {
        "id": "234696",
        "name": "Bellingham Technical College",
        "hyphenated": "bellingham-technical-college"
    },
    {
        "id": "234711",
        "name": "Big Bend Community College",
        "hyphenated": "big-bend-community-college"
    },
    {
        "id": "234827",
        "name": "Central Washington University",
        "hyphenated": "central-washington-university"
    },
    {
        "id": "234845",
        "name": "Centralia College",
        "hyphenated": "centralia-college"
    },
    {
        "id": "234915",
        "name": "City University of Seattle",
        "hyphenated": "city-university-of-seattle"
    },
    {
        "id": "234924",
        "name": "Paul Mitchell the School-Richland",
        "hyphenated": "paul-mitchell-the-school-richland"
    },
    {
        "id": "234933",
        "name": "Clark College",
        "hyphenated": "clark-college"
    },
    {
        "id": "234951",
        "name": "Clover Park Technical College",
        "hyphenated": "clover-park-technical-college"
    },
    {
        "id": "234979",
        "name": "Columbia Basin College",
        "hyphenated": "columbia-basin-college"
    },
    {
        "id": "235024",
        "name": "Cornish College of the Arts",
        "hyphenated": "cornish-college-of-the-arts"
    },
    {
        "id": "235088",
        "name": "Divers Institute of Technology",
        "hyphenated": "divers-institute-of-technology"
    },
    {
        "id": "235097",
        "name": "Eastern Washington University",
        "hyphenated": "eastern-washington-university"
    },
    {
        "id": "235103",
        "name": "Edmonds College",
        "hyphenated": "edmonds-college"
    },
    {
        "id": "235149",
        "name": "Everett Community College",
        "hyphenated": "everett-community-college"
    },
    {
        "id": "235158",
        "name": "Paroba College of Cosmetology",
        "hyphenated": "paroba-college-of-cosmetology"
    },
    {
        "id": "235167",
        "name": "The Evergreen State College",
        "hyphenated": "the-evergreen-state-college"
    },
    {
        "id": "235237",
        "name": "Pierce College District",
        "hyphenated": "pierce-college-district"
    },
    {
        "id": "235307",
        "name": "Glen Dow Academy of Hair Design",
        "hyphenated": "glen-dow-academy-of-hair-design"
    },
    {
        "id": "235334",
        "name": "Grays Harbor College",
        "hyphenated": "grays-harbor-college"
    },
    {
        "id": "235343",
        "name": "Green River College",
        "hyphenated": "green-river-college"
    },
    {
        "id": "235352",
        "name": "Toni & Guy Hairdressing Academy-Shoreline",
        "hyphenated": "toni-&-guy-hairdressing-academy-shoreline"
    },
    {
        "id": "235422",
        "name": "Heritage University",
        "hyphenated": "heritage-university"
    },
    {
        "id": "235431",
        "name": "Highline College",
        "hyphenated": "highline-college"
    },
    {
        "id": "235501",
        "name": "International Air and Hospitality Academy",
        "hyphenated": "international-air-and-hospitality-academy"
    },
    {
        "id": "235547",
        "name": "Bastyr University",
        "hyphenated": "bastyr-university"
    },
    {
        "id": "235583",
        "name": "Highlights Beauty Schools",
        "hyphenated": "highlights-beauty-schools"
    },
    {
        "id": "235671",
        "name": "Bates Technical College",
        "hyphenated": "bates-technical-college"
    },
    {
        "id": "235699",
        "name": "Lake Washington Institute of Technology",
        "hyphenated": "lake-washington-institute-of-technology"
    },
    {
        "id": "235750",
        "name": "Lower Columbia College",
        "hyphenated": "lower-columbia-college"
    },
    {
        "id": "236018",
        "name": "BJ's Beauty & Barber College",
        "hyphenated": "bj's-beauty-&-barber-college"
    },
    {
        "id": "236072",
        "name": "North Seattle College",
        "hyphenated": "north-seattle-college"
    },
    {
        "id": "236133",
        "name": "Northwest University",
        "hyphenated": "northwest-university"
    },
    {
        "id": "236188",
        "name": "Olympic College",
        "hyphenated": "olympic-college"
    },
    {
        "id": "236212",
        "name": "Perry Technical Institute",
        "hyphenated": "perry-technical-institute"
    },
    {
        "id": "236230",
        "name": "Pacific Lutheran University",
        "hyphenated": "pacific-lutheran-university"
    },
    {
        "id": "236258",
        "name": "Peninsula College",
        "hyphenated": "peninsula-college"
    },
    {
        "id": "236328",
        "name": "University of Puget Sound",
        "hyphenated": "university-of-puget-sound"
    },
    {
        "id": "236382",
        "name": "Renton Technical College",
        "hyphenated": "renton-technical-college"
    },
    {
        "id": "236452",
        "name": "Saint Martin's University",
        "hyphenated": "saint-martin's-university"
    },
    {
        "id": "236504",
        "name": "South Seattle College",
        "hyphenated": "south-seattle-college"
    },
    {
        "id": "236513",
        "name": "Seattle Central College",
        "hyphenated": "seattle-central-college"
    },
    {
        "id": "236577",
        "name": "Seattle Pacific University",
        "hyphenated": "seattle-pacific-university"
    },
    {
        "id": "236610",
        "name": "Shoreline Community College",
        "hyphenated": "shoreline-community-college"
    },
    {
        "id": "236638",
        "name": "Skagit Valley College",
        "hyphenated": "skagit-valley-college"
    },
    {
        "id": "236656",
        "name": "South Puget Sound Community College",
        "hyphenated": "south-puget-sound-community-college"
    },
    {
        "id": "236692",
        "name": "Spokane Community College",
        "hyphenated": "spokane-community-college"
    },
    {
        "id": "236708",
        "name": "Spokane Falls Community College",
        "hyphenated": "spokane-falls-community-college"
    },
    {
        "id": "236744",
        "name": "Stylemaster College of Hair Design",
        "hyphenated": "stylemaster-college-of-hair-design"
    },
    {
        "id": "236753",
        "name": "Tacoma Community College",
        "hyphenated": "tacoma-community-college"
    },
    {
        "id": "236887",
        "name": "Walla Walla Community College",
        "hyphenated": "walla-walla-community-college"
    },
    {
        "id": "236896",
        "name": "Walla Walla University",
        "hyphenated": "walla-walla-university"
    },
    {
        "id": "236939",
        "name": "Washington State University",
        "hyphenated": "washington-state-university"
    },
    {
        "id": "236975",
        "name": "Wenatchee Valley College",
        "hyphenated": "wenatchee-valley-college"
    },
    {
        "id": "237011",
        "name": "Western Washington University",
        "hyphenated": "western-washington-university"
    },
    {
        "id": "237039",
        "name": "Whatcom Community College",
        "hyphenated": "whatcom-community-college"
    },
    {
        "id": "237057",
        "name": "Whitman College",
        "hyphenated": "whitman-college"
    },
    {
        "id": "237066",
        "name": "Whitworth University",
        "hyphenated": "whitworth-university"
    },
    {
        "id": "237109",
        "name": "Yakima Valley College",
        "hyphenated": "yakima-valley-college"
    },
    {
        "id": "237118",
        "name": "Alderson Broaddus University",
        "hyphenated": "alderson-broaddus-university"
    },
    {
        "id": "237127",
        "name": "Appalachian Beauty School",
        "hyphenated": "appalachian-beauty-school"
    },
    {
        "id": "237136",
        "name": "Appalachian Bible College",
        "hyphenated": "appalachian-bible-college"
    },
    {
        "id": "237145",
        "name": "B M Spurr School of Practical Nursing",
        "hyphenated": "b-m-spurr-school-of-practical-nursing"
    },
    {
        "id": "237172",
        "name": "Ben Franklin Career Center",
        "hyphenated": "ben-franklin-career-center"
    },
    {
        "id": "237181",
        "name": "Bethany College",
        "hyphenated": "bethany-college"
    },
    {
        "id": "237215",
        "name": "Bluefield State University",
        "hyphenated": "bluefield-state-university"
    },
    {
        "id": "237242",
        "name": "Cabell County Career Technology Center",
        "hyphenated": "cabell-county-career-technology-center"
    },
    {
        "id": "237303",
        "name": "Charleston School of Beauty Culture",
        "hyphenated": "charleston-school-of-beauty-culture"
    },
    {
        "id": "237312",
        "name": "University of Charleston",
        "hyphenated": "university-of-charleston"
    },
    {
        "id": "237321",
        "name": "Clarksburg Beauty Academy and School of Massage Therapy",
        "hyphenated": "clarksburg-beauty-academy-and-school-of-massage-therapy"
    },
    {
        "id": "237330",
        "name": "Concord University",
        "hyphenated": "concord-university"
    },
    {
        "id": "237358",
        "name": "Davis & Elkins College",
        "hyphenated": "davis-&-elkins-college"
    },
    {
        "id": "237367",
        "name": "Fairmont State University",
        "hyphenated": "fairmont-state-university"
    },
    {
        "id": "237385",
        "name": "Glenville State University",
        "hyphenated": "glenville-state-university"
    },
    {
        "id": "237437",
        "name": "Huntington Junior College",
        "hyphenated": "huntington-junior-college"
    },
    {
        "id": "237473",
        "name": "James Rumsey Technical Institute",
        "hyphenated": "james-rumsey-technical-institute"
    },
    {
        "id": "237491",
        "name": "Ralph R Willis Career and Technical Center",
        "hyphenated": "ralph-r-willis-career-and-technical-center"
    },
    {
        "id": "237525",
        "name": "Marshall University",
        "hyphenated": "marshall-university"
    },
    {
        "id": "237543",
        "name": "Mercer County Technical Education Center",
        "hyphenated": "mercer-county-technical-education-center"
    },
    {
        "id": "237561",
        "name": "Monongalia County Technical Education Center",
        "hyphenated": "monongalia-county-technical-education-center"
    },
    {
        "id": "237570",
        "name": "Morgantown Beauty College Inc",
        "hyphenated": "morgantown-beauty-college-inc"
    },
    {
        "id": "237598",
        "name": "Mountain State College",
        "hyphenated": "mountain-state-college"
    },
    {
        "id": "237686",
        "name": "West Virginia University at Parkersburg",
        "hyphenated": "west-virginia-university-at-parkersburg"
    },
    {
        "id": "237701",
        "name": "Potomac State College of West Virginia University",
        "hyphenated": "potomac-state-college-of-west-virginia-university"
    },
    {
        "id": "237729",
        "name": "Academy of Careers and Technology",
        "hyphenated": "academy-of-careers-and-technology"
    },
    {
        "id": "237783",
        "name": "Salem University",
        "hyphenated": "salem-university"
    },
    {
        "id": "237792",
        "name": "Shepherd University",
        "hyphenated": "shepherd-university"
    },
    {
        "id": "237817",
        "name": "Southern West Virginia Community and Technical College",
        "hyphenated": "southern-west-virginia-community-and-technical-college"
    },
    {
        "id": "237844",
        "name": "Fred W Eberle Technical Center",
        "hyphenated": "fred-w-eberle-technical-center"
    },
    {
        "id": "237880",
        "name": "West Virginia School of Osteopathic Medicine",
        "hyphenated": "west-virginia-school-of-osteopathic-medicine"
    },
    {
        "id": "237899",
        "name": "West Virginia State University",
        "hyphenated": "west-virginia-state-university"
    },
    {
        "id": "237905",
        "name": "West Virginia University Hospital Departments of Rad Tech and Nutrition",
        "hyphenated": "west-virginia-university-hospital-departments-of-rad-tech-and-nutrition"
    },
    {
        "id": "237932",
        "name": "West Liberty University",
        "hyphenated": "west-liberty-university"
    },
    {
        "id": "237950",
        "name": "West Virginia University Institute of Technology",
        "hyphenated": "west-virginia-university-institute-of-technology"
    },
    {
        "id": "237969",
        "name": "West Virginia Wesleyan College",
        "hyphenated": "west-virginia-wesleyan-college"
    },
    {
        "id": "237987",
        "name": "West Virginia Junior College-Charleston",
        "hyphenated": "west-virginia-junior-college-charleston"
    },
    {
        "id": "237996",
        "name": "West Virginia Junior College-Morgantown",
        "hyphenated": "west-virginia-junior-college-morgantown"
    },
    {
        "id": "238005",
        "name": "International Beauty School 4",
        "hyphenated": "international-beauty-school-4"
    },
    {
        "id": "238014",
        "name": "West Virginia Northern Community College",
        "hyphenated": "west-virginia-northern-community-college"
    },
    {
        "id": "238032",
        "name": "West Virginia University",
        "hyphenated": "west-virginia-university"
    },
    {
        "id": "238078",
        "name": "Wheeling University",
        "hyphenated": "wheeling-university"
    },
    {
        "id": "238096",
        "name": "Wood County School of Practical Nursing",
        "hyphenated": "wood-county-school-of-practical-nursing"
    },
    {
        "id": "238175",
        "name": "VICI Beauty School",
        "hyphenated": "vici-beauty-school"
    },
    {
        "id": "238193",
        "name": "Alverno College",
        "hyphenated": "alverno-college"
    },
    {
        "id": "238263",
        "name": "Madison Area Technical College",
        "hyphenated": "madison-area-technical-college"
    },
    {
        "id": "238324",
        "name": "Bellin College",
        "hyphenated": "bellin-college"
    },
    {
        "id": "238333",
        "name": "Beloit College",
        "hyphenated": "beloit-college"
    },
    {
        "id": "238397",
        "name": "Blackhawk Technical College",
        "hyphenated": "blackhawk-technical-college"
    },
    {
        "id": "238430",
        "name": "Cardinal Stritch University",
        "hyphenated": "cardinal-stritch-university"
    },
    {
        "id": "238458",
        "name": "Carroll University",
        "hyphenated": "carroll-university"
    },
    {
        "id": "238476",
        "name": "Carthage College",
        "hyphenated": "carthage-college"
    },
    {
        "id": "238616",
        "name": "Concordia University-Wisconsin",
        "hyphenated": "concordia-university-wisconsin"
    },
    {
        "id": "238661",
        "name": "Edgewood College",
        "hyphenated": "edgewood-college"
    },
    {
        "id": "238722",
        "name": "Fox Valley Technical College",
        "hyphenated": "fox-valley-technical-college"
    },
    {
        "id": "238759",
        "name": "Gateway Technical College",
        "hyphenated": "gateway-technical-college"
    },
    {
        "id": "238810",
        "name": "The Professional Hair Design Academy",
        "hyphenated": "the-professional-hair-design-academy"
    },
    {
        "id": "238980",
        "name": "Lakeland University",
        "hyphenated": "lakeland-university"
    },
    {
        "id": "239008",
        "name": "Lakeshore Technical College",
        "hyphenated": "lakeshore-technical-college"
    },
    {
        "id": "239017",
        "name": "Lawrence University",
        "hyphenated": "lawrence-university"
    },
    {
        "id": "239071",
        "name": "Maranatha Baptist University",
        "hyphenated": "maranatha-baptist-university"
    },
    {
        "id": "239080",
        "name": "Marian University",
        "hyphenated": "marian-university"
    },
    {
        "id": "239169",
        "name": "Medical College of Wisconsin",
        "hyphenated": "medical-college-of-wisconsin"
    },
    {
        "id": "239220",
        "name": "Mid-State Technical College",
        "hyphenated": "mid-state-technical-college"
    },
    {
        "id": "239248",
        "name": "Milwaukee Area Technical College",
        "hyphenated": "milwaukee-area-technical-college"
    },
    {
        "id": "239309",
        "name": "Milwaukee Institute of Art & Design",
        "hyphenated": "milwaukee-institute-of-art-&-design"
    },
    {
        "id": "239318",
        "name": "Milwaukee School of Engineering",
        "hyphenated": "milwaukee-school-of-engineering"
    },
    {
        "id": "239372",
        "name": "Moraine Park Technical College",
        "hyphenated": "moraine-park-technical-college"
    },
    {
        "id": "239390",
        "name": "Mount Mary University",
        "hyphenated": "mount-mary-university"
    },
    {
        "id": "239424",
        "name": "Nashotah House",
        "hyphenated": "nashotah-house"
    },
    {
        "id": "239442",
        "name": "Nicolet Area Technical College",
        "hyphenated": "nicolet-area-technical-college"
    },
    {
        "id": "239460",
        "name": "Northcentral Technical College",
        "hyphenated": "northcentral-technical-college"
    },
    {
        "id": "239488",
        "name": "Northeast Wisconsin Technical College",
        "hyphenated": "northeast-wisconsin-technical-college"
    },
    {
        "id": "239512",
        "name": "Northland College",
        "hyphenated": "northland-college"
    },
    {
        "id": "239628",
        "name": "Ripon College",
        "hyphenated": "ripon-college"
    },
    {
        "id": "239637",
        "name": "Sacred Heart Seminary and School of Theology",
        "hyphenated": "sacred-heart-seminary-and-school-of-theology"
    },
    {
        "id": "239716",
        "name": "Saint Norbert College",
        "hyphenated": "saint-norbert-college"
    },
    {
        "id": "239877",
        "name": "State College of Beauty Culture Inc",
        "hyphenated": "state-college-of-beauty-culture-inc"
    },
    {
        "id": "239910",
        "name": "Southwest Wisconsin Technical College",
        "hyphenated": "southwest-wisconsin-technical-college"
    },
    {
        "id": "240107",
        "name": "Viterbo University",
        "hyphenated": "viterbo-university"
    },
    {
        "id": "240116",
        "name": "Chippewa Valley Technical College",
        "hyphenated": "chippewa-valley-technical-college"
    },
    {
        "id": "240125",
        "name": "Waukesha County Technical College",
        "hyphenated": "waukesha-county-technical-college"
    },
    {
        "id": "240170",
        "name": "Western Technical College",
        "hyphenated": "western-technical-college"
    },
    {
        "id": "240189",
        "name": "University of Wisconsin-Whitewater",
        "hyphenated": "university-of-wisconsin-whitewater"
    },
    {
        "id": "240198",
        "name": "Northwood Technical College",
        "hyphenated": "northwood-technical-college"
    },
    {
        "id": "240213",
        "name": "Wisconsin School of Professional Psychology",
        "hyphenated": "wisconsin-school-of-professional-psychology"
    },
    {
        "id": "240268",
        "name": "University of Wisconsin-Eau Claire",
        "hyphenated": "university-of-wisconsin-eau-claire"
    },
    {
        "id": "240277",
        "name": "University of Wisconsin-Green Bay",
        "hyphenated": "university-of-wisconsin-green-bay"
    },
    {
        "id": "240329",
        "name": "University of Wisconsin-La Crosse",
        "hyphenated": "university-of-wisconsin-la-crosse"
    },
    {
        "id": "240338",
        "name": "Wisconsin Lutheran College",
        "hyphenated": "wisconsin-lutheran-college"
    },
    {
        "id": "240365",
        "name": "University of Wisconsin-Oshkosh",
        "hyphenated": "university-of-wisconsin-oshkosh"
    },
    {
        "id": "240374",
        "name": "University of Wisconsin-Parkside",
        "hyphenated": "university-of-wisconsin-parkside"
    },
    {
        "id": "240392",
        "name": "Herzing University-Madison",
        "hyphenated": "herzing-university-madison"
    },
    {
        "id": "240417",
        "name": "University of Wisconsin-Stout",
        "hyphenated": "university-of-wisconsin-stout"
    },
    {
        "id": "240426",
        "name": "University of Wisconsin-Superior",
        "hyphenated": "university-of-wisconsin-superior"
    },
    {
        "id": "240453",
        "name": "University of Wisconsin-Milwaukee",
        "hyphenated": "university-of-wisconsin-milwaukee"
    },
    {
        "id": "240462",
        "name": "University of Wisconsin-Platteville",
        "hyphenated": "university-of-wisconsin-platteville"
    },
    {
        "id": "240471",
        "name": "University of Wisconsin-River Falls",
        "hyphenated": "university-of-wisconsin-river-falls"
    },
    {
        "id": "240480",
        "name": "University of Wisconsin-Stevens Point",
        "hyphenated": "university-of-wisconsin-stevens-point"
    },
    {
        "id": "240505",
        "name": "Casper College",
        "hyphenated": "casper-college"
    },
    {
        "id": "240514",
        "name": "Central Wyoming College",
        "hyphenated": "central-wyoming-college"
    },
    {
        "id": "240596",
        "name": "Eastern Wyoming College",
        "hyphenated": "eastern-wyoming-college"
    },
    {
        "id": "240620",
        "name": "Laramie County Community College",
        "hyphenated": "laramie-county-community-college"
    },
    {
        "id": "240657",
        "name": "Northwest College",
        "hyphenated": "northwest-college"
    },
    {
        "id": "240666",
        "name": "Northern Wyoming Community College District",
        "hyphenated": "northern-wyoming-community-college-district"
    },
    {
        "id": "240693",
        "name": "Western Wyoming Community College",
        "hyphenated": "western-wyoming-community-college"
    },
    {
        "id": "240709",
        "name": "Cheeks Beauty Academy",
        "hyphenated": "cheeks-beauty-academy"
    },
    {
        "id": "240718",
        "name": "WyoTech",
        "hyphenated": "wyotech"
    },
    {
        "id": "240727",
        "name": "University of Wyoming",
        "hyphenated": "university-of-wyoming"
    },
    {
        "id": "240736",
        "name": "American Samoa Community College",
        "hyphenated": "american-samoa-community-college"
    },
    {
        "id": "240745",
        "name": "Guam Community College",
        "hyphenated": "guam-community-college"
    },
    {
        "id": "240754",
        "name": "University of Guam",
        "hyphenated": "university-of-guam"
    },
    {
        "id": "240790",
        "name": "Northern Marianas College",
        "hyphenated": "northern-marianas-college"
    },
    {
        "id": "240879",
        "name": "Institute of Beauty Careers",
        "hyphenated": "institute-of-beauty-careers"
    },
    {
        "id": "241100",
        "name": "American University of Puerto Rico",
        "hyphenated": "american-university-of-puerto-rico"
    },
    {
        "id": "241128",
        "name": "American University of Puerto Rico",
        "hyphenated": "american-university-of-puerto-rico"
    },
    {
        "id": "241146",
        "name": "American Educational College",
        "hyphenated": "american-educational-college"
    },
    {
        "id": "241182",
        "name": "Antilles School of Technical Careers",
        "hyphenated": "antilles-school-of-technical-careers"
    },
    {
        "id": "241191",
        "name": "Universidad Adventista de las Antillas",
        "hyphenated": "universidad-adventista-de-las-antillas"
    },
    {
        "id": "241216",
        "name": "Atlantic University College",
        "hyphenated": "atlantic-university-college"
    },
    {
        "id": "241225",
        "name": "Universidad Central de Bayamon",
        "hyphenated": "universidad-central-de-bayamon"
    },
    {
        "id": "241304",
        "name": "Columbia Central University-Caguas",
        "hyphenated": "columbia-central-university-caguas"
    },
    {
        "id": "241331",
        "name": "Albizu University-San Juan",
        "hyphenated": "albizu-university-san-juan"
    },
    {
        "id": "241377",
        "name": "Caribbean University-Bayamon",
        "hyphenated": "caribbean-university-bayamon"
    },
    {
        "id": "241386",
        "name": "Caribbean University-Carolina",
        "hyphenated": "caribbean-university-carolina"
    },
    {
        "id": "241395",
        "name": "Pontifical Catholic University of Puerto Rico-Arecibo",
        "hyphenated": "pontifical-catholic-university-of-puerto-rico-arecibo"
    },
    {
        "id": "241410",
        "name": "Pontifical Catholic University of Puerto Rico-Ponce",
        "hyphenated": "pontifical-catholic-university-of-puerto-rico-ponce"
    },
    {
        "id": "241517",
        "name": "CEM College-San Juan",
        "hyphenated": "cem-college-san-juan"
    },
    {
        "id": "241614",
        "name": "Universidad Teologica del Caribe",
        "hyphenated": "universidad-teologica-del-caribe"
    },
    {
        "id": "241720",
        "name": "Colegio Universitario de San Juan",
        "hyphenated": "colegio-universitario-de-san-juan"
    },
    {
        "id": "241739",
        "name": "Universidad Ana G. Mendez-Cupey Campus",
        "hyphenated": "universidad-ana-g.-mendez-cupey-campus"
    },
    {
        "id": "241766",
        "name": "Puerto Rico Conservatory of Music",
        "hyphenated": "puerto-rico-conservatory-of-music"
    },
    {
        "id": "241793",
        "name": "Center for Advanced Studies On Puerto Rico and the Caribbean",
        "hyphenated": "center-for-advanced-studies-on-puerto-rico-and-the-caribbean"
    },
    {
        "id": "241836",
        "name": "EDP University of Puerto Rico Inc-San Sebastian",
        "hyphenated": "edp-university-of-puerto-rico-inc-san-sebastian"
    },
    {
        "id": "241906",
        "name": "Emma's Beauty Academy-Mayaguez",
        "hyphenated": "emma's-beauty-academy-mayaguez"
    },
    {
        "id": "241951",
        "name": "Escuela de Artes Plasticas y Diseno de Puerto Rico",
        "hyphenated": "escuela-de-artes-plasticas-y-diseno-de-puerto-rico"
    },
    {
        "id": "242042",
        "name": "Instituto Tecnologico de Puerto Rico-Recinto de Manati",
        "hyphenated": "instituto-tecnologico-de-puerto-rico-recinto-de-manati"
    },
    {
        "id": "242112",
        "name": "Huertas College",
        "hyphenated": "huertas-college"
    },
    {
        "id": "242121",
        "name": "Humacao Community College",
        "hyphenated": "humacao-community-college"
    },
    {
        "id": "242130",
        "name": "ICPR Junior College-Arecibo",
        "hyphenated": "icpr-junior-college-arecibo"
    },
    {
        "id": "242149",
        "name": "ICPR Junior College-Mayaguez",
        "hyphenated": "icpr-junior-college-mayaguez"
    },
    {
        "id": "242413",
        "name": "Richport Technical College",
        "hyphenated": "richport-technical-college"
    },
    {
        "id": "242556",
        "name": "Instituto Tecnologico de Puerto Rico-Recinto de Guayama",
        "hyphenated": "instituto-tecnologico-de-puerto-rico-recinto-de-guayama"
    },
    {
        "id": "242565",
        "name": "Instituto Tecnologico de Puerto Rico-Recinto de Ponce",
        "hyphenated": "instituto-tecnologico-de-puerto-rico-recinto-de-ponce"
    },
    {
        "id": "242583",
        "name": "Instituto Tecnologico de Puerto Rico-Recinto de San Juan",
        "hyphenated": "instituto-tecnologico-de-puerto-rico-recinto-de-san-juan"
    },
    {
        "id": "242617",
        "name": "Inter American University of Puerto Rico-San German",
        "hyphenated": "inter-american-university-of-puerto-rico-san-german"
    },
    {
        "id": "242626",
        "name": "Inter American University of Puerto Rico-Aguadilla",
        "hyphenated": "inter-american-university-of-puerto-rico-aguadilla"
    },
    {
        "id": "242635",
        "name": "Inter American University of Puerto Rico-Arecibo",
        "hyphenated": "inter-american-university-of-puerto-rico-arecibo"
    },
    {
        "id": "242644",
        "name": "Inter American University of Puerto Rico-Barranquitas",
        "hyphenated": "inter-american-university-of-puerto-rico-barranquitas"
    },
    {
        "id": "242653",
        "name": "Inter American University of Puerto Rico-Metro",
        "hyphenated": "inter-american-university-of-puerto-rico-metro"
    },
    {
        "id": "242662",
        "name": "Inter American University of Puerto Rico-Ponce",
        "hyphenated": "inter-american-university-of-puerto-rico-ponce"
    },
    {
        "id": "242680",
        "name": "Inter American University of Puerto Rico-Fajardo",
        "hyphenated": "inter-american-university-of-puerto-rico-fajardo"
    },
    {
        "id": "242699",
        "name": "Inter American University of Puerto Rico-Guayama",
        "hyphenated": "inter-american-university-of-puerto-rico-guayama"
    },
    {
        "id": "242705",
        "name": "Inter American University of Puerto Rico-Bayamon",
        "hyphenated": "inter-american-university-of-puerto-rico-bayamon"
    },
    {
        "id": "242723",
        "name": "Inter American University of Puerto Rico-School of Law",
        "hyphenated": "inter-american-university-of-puerto-rico-school-of-law"
    },
    {
        "id": "242750",
        "name": "International Technical College",
        "hyphenated": "international-technical-college"
    },
    {
        "id": "242820",
        "name": "Liceo de Arte y Tecnologia",
        "hyphenated": "liceo-de-arte-y-tecnologia"
    },
    {
        "id": "242945",
        "name": "Modern Hairstyling Institute-Bayamon",
        "hyphenated": "modern-hairstyling-institute-bayamon"
    },
    {
        "id": "242954",
        "name": "Modern Hairstyling Institute-Arecibo",
        "hyphenated": "modern-hairstyling-institute-arecibo"
    },
    {
        "id": "242972",
        "name": "NUC University",
        "hyphenated": "nuc-university"
    },
    {
        "id": "243081",
        "name": "Ponce Health Sciences University",
        "hyphenated": "ponce-health-sciences-university"
    },
    {
        "id": "243106",
        "name": "University of Puerto Rico-Aguadilla",
        "hyphenated": "university-of-puerto-rico-aguadilla"
    },
    {
        "id": "243115",
        "name": "University of Puerto Rico-Arecibo",
        "hyphenated": "university-of-puerto-rico-arecibo"
    },
    {
        "id": "243133",
        "name": "University of Puerto Rico-Bayamon",
        "hyphenated": "university-of-puerto-rico-bayamon"
    },
    {
        "id": "243142",
        "name": "University of Puerto Rico-Carolina",
        "hyphenated": "university-of-puerto-rico-carolina"
    },
    {
        "id": "243151",
        "name": "University of Puerto Rico-Cayey",
        "hyphenated": "university-of-puerto-rico-cayey"
    },
    {
        "id": "243179",
        "name": "University of Puerto Rico-Humacao",
        "hyphenated": "university-of-puerto-rico-humacao"
    },
    {
        "id": "243188",
        "name": "University of Puerto Rico-Utuado",
        "hyphenated": "university-of-puerto-rico-utuado"
    },
    {
        "id": "243197",
        "name": "University of Puerto Rico-Mayaguez",
        "hyphenated": "university-of-puerto-rico-mayaguez"
    },
    {
        "id": "243203",
        "name": "University of Puerto Rico-Medical Sciences",
        "hyphenated": "university-of-puerto-rico-medical-sciences"
    },
    {
        "id": "243212",
        "name": "University of Puerto Rico at Ponce",
        "hyphenated": "university-of-puerto-rico-at-ponce"
    },
    {
        "id": "243221",
        "name": "University of Puerto Rico-Rio Piedras",
        "hyphenated": "university-of-puerto-rico-rio-piedras"
    },
    {
        "id": "243346",
        "name": "Universidad Ana G. Mendez-Carolina Campus",
        "hyphenated": "universidad-ana-g.-mendez-carolina-campus"
    },
    {
        "id": "243443",
        "name": "Universidad del Sagrado Corazon",
        "hyphenated": "universidad-del-sagrado-corazon"
    },
    {
        "id": "243498",
        "name": "Seminario Evangelico de Puerto Rico",
        "hyphenated": "seminario-evangelico-de-puerto-rico"
    },
    {
        "id": "243568",
        "name": "Universidad Central Del Caribe",
        "hyphenated": "universidad-central-del-caribe"
    },
    {
        "id": "243577",
        "name": "Universidad Politecnica de Puerto Rico",
        "hyphenated": "universidad-politecnica-de-puerto-rico"
    },
    {
        "id": "243586",
        "name": "Pontifical Catholic University of Puerto Rico-Mayaguez",
        "hyphenated": "pontifical-catholic-university-of-puerto-rico-mayaguez"
    },
    {
        "id": "243601",
        "name": "Universidad Ana G. Mendez-Gurabo Campus",
        "hyphenated": "universidad-ana-g.-mendez-gurabo-campus"
    },
    {
        "id": "243638",
        "name": "College of Micronesia-FSM",
        "hyphenated": "college-of-micronesia-fsm"
    },
    {
        "id": "243647",
        "name": "Palau Community College",
        "hyphenated": "palau-community-college"
    },
    {
        "id": "243665",
        "name": "University of the Virgin Islands",
        "hyphenated": "university-of-the-virgin-islands"
    },
    {
        "id": "243799",
        "name": "Blue Hills Regional Technical School",
        "hyphenated": "blue-hills-regional-technical-school"
    },
    {
        "id": "243823",
        "name": "Parker University",
        "hyphenated": "parker-university"
    },
    {
        "id": "243832",
        "name": "EDP University of Puerto Rico Inc-San Juan",
        "hyphenated": "edp-university-of-puerto-rico-inc-san-juan"
    },
    {
        "id": "243841",
        "name": "ICPR Junior College",
        "hyphenated": "icpr-junior-college"
    },
    {
        "id": "244233",
        "name": "City College-Fort Lauderdale",
        "hyphenated": "city-college-fort-lauderdale"
    },
    {
        "id": "244279",
        "name": "Meridian College",
        "hyphenated": "meridian-college"
    },
    {
        "id": "244437",
        "name": "Georgia State University-Perimeter College",
        "hyphenated": "georgia-state-university-perimeter-college"
    },
    {
        "id": "244446",
        "name": "Georgia Piedmont Technical College",
        "hyphenated": "georgia-piedmont-technical-college"
    },
    {
        "id": "244613",
        "name": "ETI School of Skilled Trades",
        "hyphenated": "eti-school-of-skilled-trades"
    },
    {
        "id": "245281",
        "name": "Traxlers School of Hair",
        "hyphenated": "traxlers-school-of-hair"
    },
    {
        "id": "245625",
        "name": "Warren County Community College",
        "hyphenated": "warren-county-community-college"
    },
    {
        "id": "245652",
        "name": "St. John's College",
        "hyphenated": "st.-john's-college"
    },
    {
        "id": "245777",
        "name": "Bais Medrash Elyon",
        "hyphenated": "bais-medrash-elyon"
    },
    {
        "id": "245838",
        "name": "Antioch University-Los Angeles",
        "hyphenated": "antioch-university-los-angeles"
    },
    {
        "id": "245847",
        "name": "Antioch University-Santa Barbara",
        "hyphenated": "antioch-university-santa-barbara"
    },
    {
        "id": "245865",
        "name": "Antioch University-New England",
        "hyphenated": "antioch-university-new-england"
    },
    {
        "id": "245883",
        "name": "Antioch University-Seattle",
        "hyphenated": "antioch-university-seattle"
    },
    {
        "id": "245892",
        "name": "Antioch University",
        "hyphenated": "antioch-university"
    },
    {
        "id": "245953",
        "name": "Mid-America Christian University",
        "hyphenated": "mid-america-christian-university"
    },
    {
        "id": "245962",
        "name": "Miller-Motte College-Tulsa",
        "hyphenated": "miller-motte-college-tulsa"
    },
    {
        "id": "245980",
        "name": "Ponca City Beauty College",
        "hyphenated": "ponca-city-beauty-college"
    },
    {
        "id": "245999",
        "name": "Francis Tuttle Technology Center",
        "hyphenated": "francis-tuttle-technology-center"
    },
    {
        "id": "246017",
        "name": "Central Technology Center",
        "hyphenated": "central-technology-center"
    },
    {
        "id": "246035",
        "name": "Carrington College-Portland",
        "hyphenated": "carrington-college-portland"
    },
    {
        "id": "246071",
        "name": "Career Technology Center of Lackawanna County",
        "hyphenated": "career-technology-center-of-lackawanna-county"
    },
    {
        "id": "246345",
        "name": "Houston Graduate School of Theology",
        "hyphenated": "houston-graduate-school-of-theology"
    },
    {
        "id": "246354",
        "name": "Palo Alto College",
        "hyphenated": "palo-alto-college"
    },
    {
        "id": "246406",
        "name": "Sebring Career Schools-Huntsville",
        "hyphenated": "sebring-career-schools-huntsville"
    },
    {
        "id": "246415",
        "name": "UCAS University of Cosmetology Arts & Sciences-San Antonio 410",
        "hyphenated": "ucas-university-of-cosmetology-arts-&-sciences-san-antonio-410"
    },
    {
        "id": "246460",
        "name": "Central Texas Beauty College-Round Rock",
        "hyphenated": "central-texas-beauty-college-round-rock"
    },
    {
        "id": "246789",
        "name": "Unification Theological Seminary",
        "hyphenated": "unification-theological-seminary"
    },
    {
        "id": "246813",
        "name": "Athens Technical College",
        "hyphenated": "athens-technical-college"
    },
    {
        "id": "246895",
        "name": "GateWay Community College-Central City",
        "hyphenated": "gateway-community-college-central-city"
    },
    {
        "id": "246974",
        "name": "Carrington College-San Leandro Campus",
        "hyphenated": "carrington-college-san-leandro-campus"
    },
    {
        "id": "247010",
        "name": "Headmasters School of Hair Design",
        "hyphenated": "headmasters-school-of-hair-design"
    },
    {
        "id": "247047",
        "name": "The Salon Professional Academy-Nampa",
        "hyphenated": "the-salon-professional-academy-nampa"
    },
    {
        "id": "247065",
        "name": "Beckfield College-Florence",
        "hyphenated": "beckfield-college-florence"
    },
    {
        "id": "247153",
        "name": "Dorsey College-Wayne",
        "hyphenated": "dorsey-college-wayne"
    },
    {
        "id": "247162",
        "name": "Ecumenical Theological Seminary",
        "hyphenated": "ecumenical-theological-seminary"
    },
    {
        "id": "247409",
        "name": "Ross Medical Education Center-Saginaw",
        "hyphenated": "ross-medical-education-center-saginaw"
    },
    {
        "id": "247603",
        "name": "Sussex County Community College",
        "hyphenated": "sussex-county-community-college"
    },
    {
        "id": "247649",
        "name": "Landmark College",
        "hyphenated": "landmark-college"
    },
    {
        "id": "247773",
        "name": "Yeshiva Gedolah of Greater Detroit",
        "hyphenated": "yeshiva-gedolah-of-greater-detroit"
    },
    {
        "id": "247825",
        "name": "Austin Graduate School of Theology",
        "hyphenated": "austin-graduate-school-of-theology"
    },
    {
        "id": "247834",
        "name": "Collin County Community College District",
        "hyphenated": "collin-county-community-college-district"
    },
    {
        "id": "247940",
        "name": "Owensboro Community and Technical College",
        "hyphenated": "owensboro-community-and-technical-college"
    },
    {
        "id": "248192",
        "name": "Allure Beauty College",
        "hyphenated": "allure-beauty-college"
    },
    {
        "id": "248527",
        "name": "Cloyd's Beauty School 3 Inc",
        "hyphenated": "cloyd's-beauty-school-3-inc"
    },
    {
        "id": "248606",
        "name": "Moore Norman Technology Center",
        "hyphenated": "moore-norman-technology-center"
    },
    {
        "id": "248624",
        "name": "Fayette County Career & Technical Institute Practical Nursing Program",
        "hyphenated": "fayette-county-career-&-technical-institute-practical-nursing-program"
    },
    {
        "id": "248633",
        "name": "Franklin County Career and Technology Center",
        "hyphenated": "franklin-county-career-and-technology-center"
    },
    {
        "id": "248660",
        "name": "Paul Mitchell the School-Knoxville",
        "hyphenated": "paul-mitchell-the-school-knoxville"
    },
    {
        "id": "248703",
        "name": "Ogle School Hair Skin Nails-Hurst",
        "hyphenated": "ogle-school-hair-skin-nails-hurst"
    },
    {
        "id": "248925",
        "name": "Tennessee College of Applied Technology Nashville",
        "hyphenated": "tennessee-college-of-applied-technology-nashville"
    },
    {
        "id": "248934",
        "name": "ECPI University",
        "hyphenated": "ecpi-university"
    },
    {
        "id": "249061",
        "name": "Ms Roberts Academy of Beauty Culture",
        "hyphenated": "ms-roberts-academy-of-beauty-culture"
    },
    {
        "id": "249238",
        "name": "Paul Mitchell the School-Houston",
        "hyphenated": "paul-mitchell-the-school-houston"
    },
    {
        "id": "249609",
        "name": "Fortis Institute-Forty Fort",
        "hyphenated": "fortis-institute-forty-fort"
    },
    {
        "id": "250027",
        "name": "Laurel Business Institute",
        "hyphenated": "laurel-business-institute"
    },
    {
        "id": "250285",
        "name": "Aviation Institute of Maintenance-Philadelphia",
        "hyphenated": "aviation-institute-of-maintenance-philadelphia"
    },
    {
        "id": "250744",
        "name": "Dorsey College-Roseville",
        "hyphenated": "dorsey-college-roseville"
    },
    {
        "id": "250902",
        "name": "Moler-Pickens Beauty Academy",
        "hyphenated": "moler-pickens-beauty-academy"
    },
    {
        "id": "250993",
        "name": "Red River Technology Center",
        "hyphenated": "red-river-technology-center"
    },
    {
        "id": "251260",
        "name": "Southern Union State Community College",
        "hyphenated": "southern-union-state-community-college"
    },
    {
        "id": "251312",
        "name": "South University-Columbia",
        "hyphenated": "south-university-columbia"
    },
    {
        "id": "260336",
        "name": "Clinton Technical School",
        "hyphenated": "clinton-technical-school"
    },
    {
        "id": "260363",
        "name": "Greene County Career and Technology Center",
        "hyphenated": "greene-county-career-and-technology-center"
    },
    {
        "id": "260372",
        "name": "Lac Courte Oreilles Ojibwe University",
        "hyphenated": "lac-courte-oreilles-ojibwe-university"
    },
    {
        "id": "260664",
        "name": "Diesel Driving Academy-Shreveport",
        "hyphenated": "diesel-driving-academy-shreveport"
    },
    {
        "id": "260691",
        "name": "Pima Medical Institute-Mesa",
        "hyphenated": "pima-medical-institute-mesa"
    },
    {
        "id": "260789",
        "name": "Platt College-Los Angeles",
        "hyphenated": "platt-college-los-angeles"
    },
    {
        "id": "260813",
        "name": "Platt College-Aurora",
        "hyphenated": "platt-college-aurora"
    },
    {
        "id": "260929",
        "name": "Paul Mitchell the School-Boise",
        "hyphenated": "paul-mitchell-the-school-boise"
    },
    {
        "id": "260965",
        "name": "Educators of Beauty College of Cosmetology-Rockford",
        "hyphenated": "educators-of-beauty-college-of-cosmetology-rockford"
    },
    {
        "id": "260992",
        "name": "Northwestern College",
        "hyphenated": "northwestern-college"
    },
    {
        "id": "261436",
        "name": "Douglas J Aveda Institute",
        "hyphenated": "douglas-j-aveda-institute"
    },
    {
        "id": "261676",
        "name": "Ross Medical Education Center-Brighton",
        "hyphenated": "ross-medical-education-center-brighton"
    },
    {
        "id": "261685",
        "name": "Ross Medical Education Center-Ann Arbor",
        "hyphenated": "ross-medical-education-center-ann-arbor"
    },
    {
        "id": "261719",
        "name": "Taylortown School of Beauty Inc",
        "hyphenated": "taylortown-school-of-beauty-inc"
    },
    {
        "id": "261773",
        "name": "Pike-Lincoln Technical Center",
        "hyphenated": "pike-lincoln-technical-center"
    },
    {
        "id": "261861",
        "name": "West Virginia Junior College-United Career Institute",
        "hyphenated": "west-virginia-junior-college-united-career-institute"
    },
    {
        "id": "262022",
        "name": "Bethel Seminary-San Diego",
        "hyphenated": "bethel-seminary-san-diego"
    },
    {
        "id": "262031",
        "name": "St Charles Community College",
        "hyphenated": "st-charles-community-college"
    },
    {
        "id": "262086",
        "name": "University of Massachusetts Global",
        "hyphenated": "university-of-massachusetts-global"
    },
    {
        "id": "262129",
        "name": "New College of Florida",
        "hyphenated": "new-college-of-florida"
    },
    {
        "id": "262165",
        "name": "Montana Bible College",
        "hyphenated": "montana-bible-college"
    },
    {
        "id": "262341",
        "name": "Conservatory of Recording Arts and Sciences",
        "hyphenated": "conservatory-of-recording-arts-and-sciences"
    },
    {
        "id": "262369",
        "name": "Adrian's Beauty College of Turlock",
        "hyphenated": "adrian's-beauty-college-of-turlock"
    },
    {
        "id": "262457",
        "name": "San Joaquin Valley College-Fresno",
        "hyphenated": "san-joaquin-valley-college-fresno"
    },
    {
        "id": "262466",
        "name": "Dr. Ida Rolf Institute",
        "hyphenated": "dr.-ida-rolf-institute"
    },
    {
        "id": "362700",
        "name": "Aveda Institute-Fort Myers",
        "hyphenated": "aveda-institute-fort-myers"
    },
    {
        "id": "362782",
        "name": "Elizabeth Grady School of Esthetics and Massage Therapy",
        "hyphenated": "elizabeth-grady-school-of-esthetics-and-massage-therapy"
    },
    {
        "id": "363013",
        "name": "Ross Medical Education Center-Port Huron",
        "hyphenated": "ross-medical-education-center-port-huron"
    },
    {
        "id": "363077",
        "name": "Capri Institute of Hair Design-Clifton",
        "hyphenated": "capri-institute-of-hair-design-clifton"
    },
    {
        "id": "363147",
        "name": "Raphael's School of Beauty Culture Inc-Alliance",
        "hyphenated": "raphael's-school-of-beauty-culture-inc-alliance"
    },
    {
        "id": "363165",
        "name": "Metro Technology Centers",
        "hyphenated": "metro-technology-centers"
    },
    {
        "id": "363633",
        "name": "Luna Community College",
        "hyphenated": "luna-community-college"
    },
    {
        "id": "363679",
        "name": "Grabber School of Hair Design",
        "hyphenated": "grabber-school-of-hair-design"
    },
    {
        "id": "363712",
        "name": "Yeshivah Gedolah Rabbinical College",
        "hyphenated": "yeshivah-gedolah-rabbinical-college"
    },
    {
        "id": "363882",
        "name": "Raphael's School of Beauty Culture Inc-Brunswick",
        "hyphenated": "raphael's-school-of-beauty-culture-inc-brunswick"
    },
    {
        "id": "363907",
        "name": "Caribbean University-Ponce",
        "hyphenated": "caribbean-university-ponce"
    },
    {
        "id": "363916",
        "name": "Caribbean University-Vega Baja",
        "hyphenated": "caribbean-university-vega-baja"
    },
    {
        "id": "363934",
        "name": "University of Advancing Technology",
        "hyphenated": "university-of-advancing-technology"
    },
    {
        "id": "364016",
        "name": "Paradise Valley Community College",
        "hyphenated": "paradise-valley-community-college"
    },
    {
        "id": "364025",
        "name": "Chandler-Gilbert Community College",
        "hyphenated": "chandler-gilbert-community-college"
    },
    {
        "id": "364548",
        "name": "Great Plains Technology Center",
        "hyphenated": "great-plains-technology-center"
    },
    {
        "id": "364575",
        "name": "Roane-Jackson Technical Center",
        "hyphenated": "roane-jackson-technical-center"
    },
    {
        "id": "364627",
        "name": "Pioneer Technology Center",
        "hyphenated": "pioneer-technology-center"
    },
    {
        "id": "364636",
        "name": "Portage Lakes Career Center",
        "hyphenated": "portage-lakes-career-center"
    },
    {
        "id": "364751",
        "name": "Eastern College of Health Vocations-New Orleans",
        "hyphenated": "eastern-college-of-health-vocations-new-orleans"
    },
    {
        "id": "364760",
        "name": "Bell Mar Beauty College",
        "hyphenated": "bell-mar-beauty-college"
    },
    {
        "id": "364946",
        "name": "Caddo Kiowa Technology Center",
        "hyphenated": "caddo-kiowa-technology-center"
    },
    {
        "id": "364964",
        "name": "Center for Instruction Technology & Innovation",
        "hyphenated": "center-for-instruction-technology-&-innovation"
    },
    {
        "id": "365091",
        "name": "International School of Skin Nailcare & Massage Therapy",
        "hyphenated": "international-school-of-skin-nailcare-&-massage-therapy"
    },
    {
        "id": "365125",
        "name": "Palomar Institute of Cosmetology",
        "hyphenated": "palomar-institute-of-cosmetology"
    },
    {
        "id": "365198",
        "name": "Southern Oklahoma Technology Center",
        "hyphenated": "southern-oklahoma-technology-center"
    },
    {
        "id": "365213",
        "name": "Autry Technology Center",
        "hyphenated": "autry-technology-center"
    },
    {
        "id": "365240",
        "name": "Greene County Career Center",
        "hyphenated": "greene-county-career-center"
    },
    {
        "id": "365374",
        "name": "Canadian Valley Technology Center",
        "hyphenated": "canadian-valley-technology-center"
    },
    {
        "id": "365383",
        "name": "Wilkes-Barre Area Career and Technical Center Practical Nursing",
        "hyphenated": "wilkes-barre-area-career-and-technical-center-practical-nursing"
    },
    {
        "id": "365480",
        "name": "Meridian Technology Center",
        "hyphenated": "meridian-technology-center"
    },
    {
        "id": "365505",
        "name": "Waynesville Career Center",
        "hyphenated": "waynesville-career-center"
    },
    {
        "id": "365693",
        "name": "Capitol Beauty School",
        "hyphenated": "capitol-beauty-school"
    },
    {
        "id": "365763",
        "name": "Morris County Vocational School District",
        "hyphenated": "morris-county-vocational-school-district"
    },
    {
        "id": "365824",
        "name": "Ross Medical Education Center-Roosevelt Park",
        "hyphenated": "ross-medical-education-center-roosevelt-park"
    },
    {
        "id": "365833",
        "name": "Ross Medical Education Center-Kentwood",
        "hyphenated": "ross-medical-education-center-kentwood"
    },
    {
        "id": "366003",
        "name": "South Florida Bible College and Theological Seminary",
        "hyphenated": "south-florida-bible-college-and-theological-seminary"
    },
    {
        "id": "366155",
        "name": "Cloyd's Barber School 2 Inc",
        "hyphenated": "cloyd's-barber-school-2-inc"
    },
    {
        "id": "366191",
        "name": "Universal Technical Institute of Illinois Inc",
        "hyphenated": "universal-technical-institute-of-illinois-inc"
    },
    {
        "id": "366252",
        "name": "Pennsylvania College of Technology",
        "hyphenated": "pennsylvania-college-of-technology"
    },
    {
        "id": "366261",
        "name": "Commonwealth Institute of Funeral Service",
        "hyphenated": "commonwealth-institute-of-funeral-service"
    },
    {
        "id": "366270",
        "name": "Delta College of Arts & Technology",
        "hyphenated": "delta-college-of-arts-&-technology"
    },
    {
        "id": "366289",
        "name": "InterCoast Colleges-Santa Ana",
        "hyphenated": "intercoast-colleges-santa-ana"
    },
    {
        "id": "366340",
        "name": "Stone Child College",
        "hyphenated": "stone-child-college"
    },
    {
        "id": "366368",
        "name": "New York Academy of Art",
        "hyphenated": "new-york-academy-of-art"
    },
    {
        "id": "366395",
        "name": "Suffolk County Community College",
        "hyphenated": "suffolk-county-community-college"
    },
    {
        "id": "366401",
        "name": "Las Positas College",
        "hyphenated": "las-positas-college"
    },
    {
        "id": "366438",
        "name": "Blades School of Hair Design",
        "hyphenated": "blades-school-of-hair-design"
    },
    {
        "id": "366465",
        "name": "Ogeechee Technical College",
        "hyphenated": "ogeechee-technical-college"
    },
    {
        "id": "366535",
        "name": "Remington College-Mobile Campus",
        "hyphenated": "remington-college-mobile-campus"
    },
    {
        "id": "366553",
        "name": "Southern Technical College",
        "hyphenated": "southern-technical-college"
    },
    {
        "id": "366571",
        "name": "Mercy-St Luke's School of Radiologic Technology",
        "hyphenated": "mercy-st-luke's-school-of-radiologic-technology"
    },
    {
        "id": "366605",
        "name": "Southwest Acupuncture College-Santa Fe",
        "hyphenated": "southwest-acupuncture-college-santa-fe"
    },
    {
        "id": "366623",
        "name": "Northwest Technology Center-Alva",
        "hyphenated": "northwest-technology-center-alva"
    },
    {
        "id": "366632",
        "name": "Mercer County Career Center",
        "hyphenated": "mercer-county-career-center"
    },
    {
        "id": "366711",
        "name": "California State University-San Marcos",
        "hyphenated": "california-state-university-san-marcos"
    },
    {
        "id": "367051",
        "name": "Hairmasters Institute of Cosmetology",
        "hyphenated": "hairmasters-institute-of-cosmetology"
    },
    {
        "id": "367088",
        "name": "Tricoci University of Beauty Culture LLC-Bloomington",
        "hyphenated": "tricoci-university-of-beauty-culture-llc-bloomington"
    },
    {
        "id": "367103",
        "name": "Interactive College of Technology-Newport",
        "hyphenated": "interactive-college-of-technology-newport"
    },
    {
        "id": "367112",
        "name": "Miller-Motte College-McCann-Monroe",
        "hyphenated": "miller-motte-college-mccann-monroe"
    },
    {
        "id": "367158",
        "name": "Ross Medical Education Center-Taylor",
        "hyphenated": "ross-medical-education-center-taylor"
    },
    {
        "id": "367334",
        "name": "National Tractor Trailer School Inc-Liverpool",
        "hyphenated": "national-tractor-trailer-school-inc-liverpool"
    },
    {
        "id": "367361",
        "name": "Interactive College of Technology",
        "hyphenated": "interactive-college-of-technology"
    },
    {
        "id": "367431",
        "name": "Butler Beauty Academy-New Castle Beauty Academy",
        "hyphenated": "butler-beauty-academy-new-castle-beauty-academy"
    },
    {
        "id": "367459",
        "name": "NorthWest Arkansas Community College",
        "hyphenated": "northwest-arkansas-community-college"
    },
    {
        "id": "367875",
        "name": "Brewster Technical College",
        "hyphenated": "brewster-technical-college"
    },
    {
        "id": "367884",
        "name": "Hodges University",
        "hyphenated": "hodges-university"
    },
    {
        "id": "367954",
        "name": "University of St. Augustine for Health Sciences",
        "hyphenated": "university-of-st.-augustine-for-health-sciences"
    },
    {
        "id": "367972",
        "name": "Capital Area School of Practical Nursing",
        "hyphenated": "capital-area-school-of-practical-nursing"
    },
    {
        "id": "367981",
        "name": "Hair Professionals Career College",
        "hyphenated": "hair-professionals-career-college"
    },
    {
        "id": "368230",
        "name": "Empire Beauty School-Bloomfield",
        "hyphenated": "empire-beauty-school-bloomfield"
    },
    {
        "id": "368364",
        "name": "Southwest Technology Center",
        "hyphenated": "southwest-technology-center"
    },
    {
        "id": "368407",
        "name": "Western Area Career & Technology Center",
        "hyphenated": "western-area-career-&-technology-center"
    },
    {
        "id": "368443",
        "name": "Hussian College-Daymar College Clarksville",
        "hyphenated": "hussian-college-daymar-college-clarksville"
    },
    {
        "id": "368452",
        "name": "Nossi College of Art",
        "hyphenated": "nossi-college-of-art"
    },
    {
        "id": "368540",
        "name": "Seguin Beauty School-New Braunfels",
        "hyphenated": "seguin-beauty-school-new-braunfels"
    },
    {
        "id": "368629",
        "name": "Pima Medical Institute-Seattle",
        "hyphenated": "pima-medical-institute-seattle"
    },
    {
        "id": "368647",
        "name": "Mineral County Vocational Technical Center",
        "hyphenated": "mineral-county-vocational-technical-center"
    },
    {
        "id": "368771",
        "name": "Hypnosis Motivation Institute",
        "hyphenated": "hypnosis-motivation-institute"
    },
    {
        "id": "368832",
        "name": "Thanh Le College School of Cosmetology",
        "hyphenated": "thanh-le-college-school-of-cosmetology"
    },
    {
        "id": "368911",
        "name": "Southeastern Technical College",
        "hyphenated": "southeastern-technical-college"
    },
    {
        "id": "369002",
        "name": "Massachusetts School of Law",
        "hyphenated": "massachusetts-school-of-law"
    },
    {
        "id": "369109",
        "name": "Alliance Career Center",
        "hyphenated": "alliance-career-center"
    },
    {
        "id": "369400",
        "name": "Universal Technical Institute-Auto Motorcycle & Marine Mechanics Institute Division-Orlando",
        "hyphenated": "universal-technical-institute-auto-motorcycle-&-marine-mechanics-institute-division-orlando"
    },
    {
        "id": "369419",
        "name": "Erwin Technical College",
        "hyphenated": "erwin-technical-college"
    },
    {
        "id": "369446",
        "name": "Rosel School of Cosmetology",
        "hyphenated": "rosel-school-of-cosmetology"
    },
    {
        "id": "369455",
        "name": "Worsham College of Mortuary Science",
        "hyphenated": "worsham-college-of-mortuary-science"
    },
    {
        "id": "369516",
        "name": "Bryan University",
        "hyphenated": "bryan-university"
    },
    {
        "id": "369659",
        "name": "Oregon College of Oriental Medicine",
        "hyphenated": "oregon-college-of-oriental-medicine"
    },
    {
        "id": "369668",
        "name": "Central Pennsylvania Institute of Science and Technology",
        "hyphenated": "central-pennsylvania-institute-of-science-and-technology"
    },
    {
        "id": "369686",
        "name": "Northwest Educational Center",
        "hyphenated": "northwest-educational-center"
    },
    {
        "id": "369695",
        "name": "Sebring Career Schools-Houston",
        "hyphenated": "sebring-career-schools-houston"
    },
    {
        "id": "369783",
        "name": "PCI Health Training Center",
        "hyphenated": "pci-health-training-center"
    },
    {
        "id": "369950",
        "name": "Hamrick School",
        "hyphenated": "hamrick-school"
    },
    {
        "id": "371052",
        "name": "Fortis College",
        "hyphenated": "fortis-college"
    },
    {
        "id": "371690",
        "name": "Downey Adult School",
        "hyphenated": "downey-adult-school"
    },
    {
        "id": "371830",
        "name": "CRU Institute of Cosmetology and Barbering",
        "hyphenated": "cru-institute-of-cosmetology-and-barbering"
    },
    {
        "id": "371928",
        "name": "Modern Technology School",
        "hyphenated": "modern-technology-school"
    },
    {
        "id": "371964",
        "name": "National Career Education",
        "hyphenated": "national-career-education"
    },
    {
        "id": "372028",
        "name": "North-West College-Glendale",
        "hyphenated": "north-west-college-glendale"
    },
    {
        "id": "372073",
        "name": "Fremont University",
        "hyphenated": "fremont-university"
    },
    {
        "id": "372082",
        "name": "Pomona Unified School District Adult and Career Education",
        "hyphenated": "pomona-unified-school-district-adult-and-career-education"
    },
    {
        "id": "372240",
        "name": "Universal College of Beauty Inc-Los Angeles 2",
        "hyphenated": "universal-college-of-beauty-inc-los-angeles-2"
    },
    {
        "id": "372329",
        "name": "IBMC College",
        "hyphenated": "ibmc-college"
    },
    {
        "id": "372356",
        "name": "Avalon Institute-Aurora",
        "hyphenated": "avalon-institute-aurora"
    },
    {
        "id": "372462",
        "name": "Academy of Cosmetology",
        "hyphenated": "academy-of-cosmetology"
    },
    {
        "id": "372718",
        "name": "Sunstate Academy",
        "hyphenated": "sunstate-academy"
    },
    {
        "id": "372921",
        "name": "Fortis College-Smyrna",
        "hyphenated": "fortis-college-smyrna"
    },
    {
        "id": "373085",
        "name": "Ross College-Davenport",
        "hyphenated": "ross-college-davenport"
    },
    {
        "id": "373216",
        "name": "Pivot Point Academy",
        "hyphenated": "pivot-point-academy"
    },
    {
        "id": "373456",
        "name": "Blalock's Professional Beauty College",
        "hyphenated": "blalock's-professional-beauty-college"
    },
    {
        "id": "373526",
        "name": "Diesel Driving Academy-Baton Rouge",
        "hyphenated": "diesel-driving-academy-baton-rouge"
    },
    {
        "id": "373678",
        "name": "New England Hair Academy",
        "hyphenated": "new-england-hair-academy"
    },
    {
        "id": "373696",
        "name": "Rob Roy Academy-Worcester",
        "hyphenated": "rob-roy-academy-worcester"
    },
    {
        "id": "373711",
        "name": "Upper Cape Cod Regional Technical School",
        "hyphenated": "upper-cape-cod-regional-technical-school"
    },
    {
        "id": "373784",
        "name": "All-State Career-Baltimore",
        "hyphenated": "all-state-career-baltimore"
    },
    {
        "id": "374024",
        "name": "Adler Graduate School",
        "hyphenated": "adler-graduate-school"
    },
    {
        "id": "374316",
        "name": "Academy of Hair Design-Jackson",
        "hyphenated": "academy-of-hair-design-jackson"
    },
    {
        "id": "374343",
        "name": "KC's School of Hair Design",
        "hyphenated": "kc's-school-of-hair-design"
    },
    {
        "id": "374574",
        "name": "Lincoln Technical Institute-Moorestown",
        "hyphenated": "lincoln-technical-institute-moorestown"
    },
    {
        "id": "374592",
        "name": "MCVSD",
        "hyphenated": "mcvsd"
    },
    {
        "id": "375106",
        "name": "National Tractor Trailer School Inc-Buffalo",
        "hyphenated": "national-tractor-trailer-school-inc-buffalo"
    },
    {
        "id": "375230",
        "name": "Yeshiva Gedolah Imrei Yosef D'spinka",
        "hyphenated": "yeshiva-gedolah-imrei-yosef-d'spinka"
    },
    {
        "id": "375373",
        "name": "Knox County Career Center",
        "hyphenated": "knox-county-career-center"
    },
    {
        "id": "375407",
        "name": "Madison Adult Career Center",
        "hyphenated": "madison-adult-career-center"
    },
    {
        "id": "375416",
        "name": "Remington College-Cleveland Campus",
        "hyphenated": "remington-college-cleveland-campus"
    },
    {
        "id": "375540",
        "name": "Toledo Academy of Beauty Culture-East",
        "hyphenated": "toledo-academy-of-beauty-culture-east"
    },
    {
        "id": "375568",
        "name": "Brown & Clermont Adult Career Campuses",
        "hyphenated": "brown-&-clermont-adult-career-campuses"
    },
    {
        "id": "375656",
        "name": "Chisholm Trail Technology Center",
        "hyphenated": "chisholm-trail-technology-center"
    },
    {
        "id": "375683",
        "name": "Gordon Cooper Technology Center",
        "hyphenated": "gordon-cooper-technology-center"
    },
    {
        "id": "375726",
        "name": "Kiamichi Technology Center-McAlester",
        "hyphenated": "kiamichi-technology-center-mcalester"
    },
    {
        "id": "375939",
        "name": "YTI Career Institute-Altoona",
        "hyphenated": "yti-career-institute-altoona"
    },
    {
        "id": "375966",
        "name": "Eastern Center for Arts and Technology",
        "hyphenated": "eastern-center-for-arts-and-technology"
    },
    {
        "id": "375984",
        "name": "Empire Beauty School-Center City Philadelphia",
        "hyphenated": "empire-beauty-school-center-city-philadelphia"
    },
    {
        "id": "376224",
        "name": "CEM College-Humacao",
        "hyphenated": "cem-college-humacao"
    },
    {
        "id": "376242",
        "name": "Colegio Mayor de Tecnologia Inc",
        "hyphenated": "colegio-mayor-de-tecnologia-inc"
    },
    {
        "id": "376288",
        "name": "Institucion Chaviano de Mayaguez",
        "hyphenated": "institucion-chaviano-de-mayaguez"
    },
    {
        "id": "376330",
        "name": "Liceo de Arte-Dise-O y Comercio",
        "hyphenated": "liceo-de-arte-dise-o-y-comercio"
    },
    {
        "id": "376385",
        "name": "Universal Technology College of Puerto Rico",
        "hyphenated": "universal-technology-college-of-puerto-rico"
    },
    {
        "id": "376446",
        "name": "Academy of Hair Technology",
        "hyphenated": "academy-of-hair-technology"
    },
    {
        "id": "376631",
        "name": "Mr Wayne's School of Unisex Hair Design",
        "hyphenated": "mr-wayne's-school-of-unisex-hair-design"
    },
    {
        "id": "376677",
        "name": "Queen City College",
        "hyphenated": "queen-city-college"
    },
    {
        "id": "376695",
        "name": "College of the Marshall Islands",
        "hyphenated": "college-of-the-marshall-islands"
    },
    {
        "id": "377111",
        "name": "Remington College-Fort Worth Campus",
        "hyphenated": "remington-college-fort-worth-campus"
    },
    {
        "id": "377193",
        "name": "UCAS University of Cosmetology Arts & Sciences",
        "hyphenated": "ucas-university-of-cosmetology-arts-&-sciences"
    },
    {
        "id": "377218",
        "name": "School of Automotive Machinists & Technology",
        "hyphenated": "school-of-automotive-machinists-&-technology"
    },
    {
        "id": "377272",
        "name": "Nuvani Institute",
        "hyphenated": "nuvani-institute"
    },
    {
        "id": "377281",
        "name": "Nuvani Institute",
        "hyphenated": "nuvani-institute"
    },
    {
        "id": "377315",
        "name": "Texas College of Cosmetology-Abilene",
        "hyphenated": "texas-college-of-cosmetology-abilene"
    },
    {
        "id": "377449",
        "name": "Centura College-Newport News",
        "hyphenated": "centura-college-newport-news"
    },
    {
        "id": "377458",
        "name": "Centura College-Norfolk",
        "hyphenated": "centura-college-norfolk"
    },
    {
        "id": "377485",
        "name": "Virginia Beach City Public Schools School of Practical Nursing",
        "hyphenated": "virginia-beach-city-public-schools-school-of-practical-nursing"
    },
    {
        "id": "377546",
        "name": "Northwest College of Art & Design",
        "hyphenated": "northwest-college-of-art-&-design"
    },
    {
        "id": "377555",
        "name": "University of Washington-Bothell Campus",
        "hyphenated": "university-of-washington-bothell-campus"
    },
    {
        "id": "377564",
        "name": "University of Washington-Tacoma Campus",
        "hyphenated": "university-of-washington-tacoma-campus"
    },
    {
        "id": "377643",
        "name": "Huntington School of Beauty Culture",
        "hyphenated": "huntington-school-of-beauty-culture"
    },
    {
        "id": "377652",
        "name": "Valley College-Beckley",
        "hyphenated": "valley-college-beckley"
    },
    {
        "id": "377661",
        "name": "Valley College-Martinsburg",
        "hyphenated": "valley-college-martinsburg"
    },
    {
        "id": "377722",
        "name": "Bellus Academy-Poway",
        "hyphenated": "bellus-academy-poway"
    },
    {
        "id": "377759",
        "name": "Capstone College",
        "hyphenated": "capstone-college"
    },
    {
        "id": "378105",
        "name": "Central Coast College",
        "hyphenated": "central-coast-college"
    },
    {
        "id": "378576",
        "name": "Pacific College of Health and Science",
        "hyphenated": "pacific-college-of-health-and-science"
    },
    {
        "id": "378886",
        "name": "Artistic Nails and Beauty Academy-Tampa",
        "hyphenated": "artistic-nails-and-beauty-academy-tampa"
    },
    {
        "id": "378956",
        "name": "National Aviation Academy of Tampa Bay",
        "hyphenated": "national-aviation-academy-of-tampa-bay"
    },
    {
        "id": "379621",
        "name": "Madison Oneida BOCES-Practical Nursing Program",
        "hyphenated": "madison-oneida-boces-practical-nursing-program"
    },
    {
        "id": "379746",
        "name": "College of Health Care Professions",
        "hyphenated": "college-of-health-care-professions"
    },
    {
        "id": "379782",
        "name": "Center for Advanced Legal Studies",
        "hyphenated": "center-for-advanced-legal-studies"
    },
    {
        "id": "380067",
        "name": "Houston Training School-Main Campus",
        "hyphenated": "houston-training-school-main-campus"
    },
    {
        "id": "380359",
        "name": "Bay Mills Community College",
        "hyphenated": "bay-mills-community-college"
    },
    {
        "id": "380368",
        "name": "Fond du Lac Tribal and Community College",
        "hyphenated": "fond-du-lac-tribal-and-community-college"
    },
    {
        "id": "380377",
        "name": "Northwest Indian College",
        "hyphenated": "northwest-indian-college"
    },
    {
        "id": "380438",
        "name": "Provo College",
        "hyphenated": "provo-college"
    },
    {
        "id": "381255",
        "name": "CET-Coachella",
        "hyphenated": "cet-coachella"
    },
    {
        "id": "381282",
        "name": "CET-Oxnard",
        "hyphenated": "cet-oxnard"
    },
    {
        "id": "381325",
        "name": "CET-Santa Maria",
        "hyphenated": "cet-santa-maria"
    },
    {
        "id": "381352",
        "name": "Colorado School of Traditional Chinese Medicine",
        "hyphenated": "colorado-school-of-traditional-chinese-medicine"
    },
    {
        "id": "381370",
        "name": "Michigan College of Beauty-Troy",
        "hyphenated": "michigan-college-of-beauty-troy"
    },
    {
        "id": "381404",
        "name": "Ogle School Hair Skin Nails-Dallas",
        "hyphenated": "ogle-school-hair-skin-nails-dallas"
    },
    {
        "id": "381459",
        "name": "Phoenix Seminary",
        "hyphenated": "phoenix-seminary"
    },
    {
        "id": "381486",
        "name": "Faust Institute of Cosmetology-Spirit Lake",
        "hyphenated": "faust-institute-of-cosmetology-spirit-lake"
    },
    {
        "id": "381699",
        "name": "Cheeks Beauty Academy",
        "hyphenated": "cheeks-beauty-academy"
    },
    {
        "id": "381732",
        "name": "Colorado School of Healing Arts",
        "hyphenated": "colorado-school-of-healing-arts"
    },
    {
        "id": "382258",
        "name": "Mt. Diablo Adult Education-Mt. Diablo USD",
        "hyphenated": "mt.-diablo-adult-education-mt.-diablo-usd"
    },
    {
        "id": "382416",
        "name": "Greater Lowell Technical School",
        "hyphenated": "greater-lowell-technical-school"
    },
    {
        "id": "382461",
        "name": "Academy of Hair Design-Pearl",
        "hyphenated": "academy-of-hair-design-pearl"
    },
    {
        "id": "382780",
        "name": "Genesis Career College-Lebanon",
        "hyphenated": "genesis-career-college-lebanon"
    },
    {
        "id": "382911",
        "name": "Southwest College for the Deaf",
        "hyphenated": "southwest-college-for-the-deaf"
    },
    {
        "id": "382957",
        "name": "Fortis College-Richmond",
        "hyphenated": "fortis-college-richmond"
    },
    {
        "id": "383020",
        "name": "Midwest College of Oriental Medicine-Racine",
        "hyphenated": "midwest-college-of-oriental-medicine-racine"
    },
    {
        "id": "383084",
        "name": "Hacienda La Puente Adult Education",
        "hyphenated": "hacienda-la-puente-adult-education"
    },
    {
        "id": "383163",
        "name": "La Belle Beauty Academy",
        "hyphenated": "la-belle-beauty-academy"
    },
    {
        "id": "383190",
        "name": "Hawaii Community College",
        "hyphenated": "hawaii-community-college"
    },
    {
        "id": "383279",
        "name": "Robert Fiance Beauty Schools-North Plainfield",
        "hyphenated": "robert-fiance-beauty-schools-north-plainfield"
    },
    {
        "id": "383297",
        "name": "Long Island Beauty School-Hauppauge",
        "hyphenated": "long-island-beauty-school-hauppauge"
    },
    {
        "id": "383312",
        "name": "Buckeye Joint Vocational School",
        "hyphenated": "buckeye-joint-vocational-school"
    },
    {
        "id": "383330",
        "name": "Sandusky Career Center",
        "hyphenated": "sandusky-career-center"
    },
    {
        "id": "383367",
        "name": "Greater Altoona Career & Technology Center",
        "hyphenated": "greater-altoona-career-&-technology-center"
    },
    {
        "id": "383464",
        "name": "Central Texas Beauty College-Temple",
        "hyphenated": "central-texas-beauty-college-temple"
    },
    {
        "id": "383525",
        "name": "Erie 2 Chautauqua Cattaraugus BOCES-Practical Nursing Program",
        "hyphenated": "erie-2-chautauqua-cattaraugus-boces-practical-nursing-program"
    },
    {
        "id": "383765",
        "name": "Empire Beauty School-Brooklyn",
        "hyphenated": "empire-beauty-school-brooklyn"
    },
    {
        "id": "383996",
        "name": "Eastern New Mexico University Ruidoso Branch Community College",
        "hyphenated": "eastern-new-mexico-university-ruidoso-branch-community-college"
    },
    {
        "id": "384236",
        "name": "UEI College-Ontario",
        "hyphenated": "uei-college-ontario"
    },
    {
        "id": "384245",
        "name": "UEI College-Reseda",
        "hyphenated": "uei-college-reseda"
    },
    {
        "id": "384254",
        "name": "Beacon College",
        "hyphenated": "beacon-college"
    },
    {
        "id": "384306",
        "name": "Acupuncture and Integrative Medicine College-Berkeley",
        "hyphenated": "acupuncture-and-integrative-medicine-college-berkeley"
    },
    {
        "id": "384333",
        "name": "Estrella Mountain Community College",
        "hyphenated": "estrella-mountain-community-college"
    },
    {
        "id": "384342",
        "name": "Heartland Community College",
        "hyphenated": "heartland-community-college"
    },
    {
        "id": "384412",
        "name": "University of the Potomac-Washington DC Campus",
        "hyphenated": "university-of-the-potomac-washington-dc-campus"
    },
    {
        "id": "384421",
        "name": "Rabbi Jacob Joseph School",
        "hyphenated": "rabbi-jacob-joseph-school"
    },
    {
        "id": "385132",
        "name": "The Salon Professional Academy-Melbourne",
        "hyphenated": "the-salon-professional-academy-melbourne"
    },
    {
        "id": "385415",
        "name": "Albert Einstein College of Medicine",
        "hyphenated": "albert-einstein-college-of-medicine"
    },
    {
        "id": "385503",
        "name": "Fortis Institute-Scranton",
        "hyphenated": "fortis-institute-scranton"
    },
    {
        "id": "385619",
        "name": "Everglades University",
        "hyphenated": "everglades-university"
    },
    {
        "id": "386153",
        "name": "Seminar L'moros Bais Yaakov",
        "hyphenated": "seminar-l'moros-bais-yaakov"
    },
    {
        "id": "386472",
        "name": "Herzing University-Orlando",
        "hyphenated": "herzing-university-orlando"
    },
    {
        "id": "387925",
        "name": "Cortiva Institute",
        "hyphenated": "cortiva-institute"
    },
    {
        "id": "388520",
        "name": "College of Biblical Studies-Houston",
        "hyphenated": "college-of-biblical-studies-houston"
    },
    {
        "id": "388663",
        "name": "Inner State Beauty School",
        "hyphenated": "inner-state-beauty-school"
    },
    {
        "id": "389860",
        "name": "Mid-EastCTC-Adult Education",
        "hyphenated": "mid-eastctc-adult-education"
    },
    {
        "id": "390905",
        "name": "Polaris Career Center",
        "hyphenated": "polaris-career-center"
    },
    {
        "id": "391005",
        "name": "P&A Scholars Beauty School",
        "hyphenated": "p&a-scholars-beauty-school"
    },
    {
        "id": "391759",
        "name": "Sharp's Academy of Hairstyling",
        "hyphenated": "sharp's-academy-of-hairstyling"
    },
    {
        "id": "392257",
        "name": "The College of Health Care Professions-Northwest",
        "hyphenated": "the-college-of-health-care-professions-northwest"
    },
    {
        "id": "392354",
        "name": "School of Professional Horticulture New York Botanical Garden",
        "hyphenated": "school-of-professional-horticulture-new-york-botanical-garden"
    },
    {
        "id": "392415",
        "name": "Fortis College",
        "hyphenated": "fortis-college"
    },
    {
        "id": "393524",
        "name": "Alhambra Beauty College",
        "hyphenated": "alhambra-beauty-college"
    },
    {
        "id": "393649",
        "name": "UEI College-Gardena",
        "hyphenated": "uei-college-gardena"
    },
    {
        "id": "393861",
        "name": "Asian-American International Beauty College",
        "hyphenated": "asian-american-international-beauty-college"
    },
    {
        "id": "395362",
        "name": "Copper Mountain Community College",
        "hyphenated": "copper-mountain-community-college"
    },
    {
        "id": "395690",
        "name": "Diversified Vocational College",
        "hyphenated": "diversified-vocational-college"
    },
    {
        "id": "397942",
        "name": "UEI College-Fresno",
        "hyphenated": "uei-college-fresno"
    },
    {
        "id": "399212",
        "name": "Santiago Canyon College",
        "hyphenated": "santiago-canyon-college"
    },
    {
        "id": "399869",
        "name": "Southern California Institute of Technology",
        "hyphenated": "southern-california-institute-of-technology"
    },
    {
        "id": "400080",
        "name": "Summit College",
        "hyphenated": "summit-college"
    },
    {
        "id": "400187",
        "name": "Advance Beauty College",
        "hyphenated": "advance-beauty-college"
    },
    {
        "id": "400558",
        "name": "Los Angeles Pacific College",
        "hyphenated": "los-angeles-pacific-college"
    },
    {
        "id": "401223",
        "name": "World Mission University",
        "hyphenated": "world-mission-university"
    },
    {
        "id": "401250",
        "name": "Yo San University of Traditional Chinese Medicine",
        "hyphenated": "yo-san-university-of-traditional-chinese-medicine"
    },
    {
        "id": "401764",
        "name": "John Amico School of Hair Design",
        "hyphenated": "john-amico-school-of-hair-design"
    },
    {
        "id": "402563",
        "name": "Delta College Inc",
        "hyphenated": "delta-college-inc"
    },
    {
        "id": "402800",
        "name": "Tri-State Cosmetology Institute",
        "hyphenated": "tri-state-cosmetology-institute"
    },
    {
        "id": "403469",
        "name": "Frontier Community College",
        "hyphenated": "frontier-community-college"
    },
    {
        "id": "403478",
        "name": "Lincoln Trail College",
        "hyphenated": "lincoln-trail-college"
    },
    {
        "id": "403487",
        "name": "Wabash Valley College",
        "hyphenated": "wabash-valley-college"
    },
    {
        "id": "404055",
        "name": "Brookline College-Tempe",
        "hyphenated": "brookline-college-tempe"
    },
    {
        "id": "404222",
        "name": "Inter American University of Puerto Rico-School of Optometry",
        "hyphenated": "inter-american-university-of-puerto-rico-school-of-optometry"
    },
    {
        "id": "404338",
        "name": "Schiller International University",
        "hyphenated": "schiller-international-university"
    },
    {
        "id": "404426",
        "name": "Coconino Community College",
        "hyphenated": "coconino-community-college"
    },
    {
        "id": "404684",
        "name": "Emma's Beauty Academy-Juana Diaz",
        "hyphenated": "emma's-beauty-academy-juana-diaz"
    },
    {
        "id": "404718",
        "name": "Brillare Beauty Institute",
        "hyphenated": "brillare-beauty-institute"
    },
    {
        "id": "404912",
        "name": "Pima Medical Institute-Denver",
        "hyphenated": "pima-medical-institute-denver"
    },
    {
        "id": "404994",
        "name": "ASA College",
        "hyphenated": "asa-college"
    },
    {
        "id": "405012",
        "name": "Ohio State School of Cosmetology-Heath",
        "hyphenated": "ohio-state-school-of-cosmetology-heath"
    },
    {
        "id": "405058",
        "name": "Yeshivas Novominsk",
        "hyphenated": "yeshivas-novominsk"
    },
    {
        "id": "405076",
        "name": "Southern Careers Institute-Corpus Christi",
        "hyphenated": "southern-careers-institute-corpus-christi"
    },
    {
        "id": "405854",
        "name": "Rabbinical College of Ohr Shimon Yisroel",
        "hyphenated": "rabbinical-college-of-ohr-shimon-yisroel"
    },
    {
        "id": "405872",
        "name": "Carroll Community College",
        "hyphenated": "carroll-community-college"
    },
    {
        "id": "406024",
        "name": "Galen College of Nursing-Tampa Bay",
        "hyphenated": "galen-college-of-nursing-tampa-bay"
    },
    {
        "id": "406033",
        "name": "Galen College of Nursing-San Antonio",
        "hyphenated": "galen-college-of-nursing-san-antonio"
    },
    {
        "id": "406149",
        "name": "Sarasota School of Massage Therapy",
        "hyphenated": "sarasota-school-of-massage-therapy"
    },
    {
        "id": "406325",
        "name": "Schuyler Steuben Chemung Tioga Allegany BOCES",
        "hyphenated": "schuyler-steuben-chemung-tioga-allegany-boces"
    },
    {
        "id": "406361",
        "name": "Delaware Chenango Madison Otsego BOCES-Practical Nursing Program",
        "hyphenated": "delaware-chenango-madison-otsego-boces-practical-nursing-program"
    },
    {
        "id": "406422",
        "name": "Central Susquehanna Intermediate Unit LPN Career",
        "hyphenated": "central-susquehanna-intermediate-unit-lpn-career"
    },
    {
        "id": "406486",
        "name": "Myotherapy College of Utah",
        "hyphenated": "myotherapy-college-of-utah"
    },
    {
        "id": "406547",
        "name": "City College-Gainesville",
        "hyphenated": "city-college-gainesville"
    },
    {
        "id": "407090",
        "name": "G Skin & Beauty Institute",
        "hyphenated": "g-skin-&-beauty-institute"
    },
    {
        "id": "407142",
        "name": "Cosmetology Training Center",
        "hyphenated": "cosmetology-training-center"
    },
    {
        "id": "407151",
        "name": "Stage One-The Hair School",
        "hyphenated": "stage-one-the-hair-school"
    },
    {
        "id": "407179",
        "name": "Empire Beauty School-Malden",
        "hyphenated": "empire-beauty-school-malden"
    },
    {
        "id": "407355",
        "name": "Xenon International Academy-Omaha",
        "hyphenated": "xenon-international-academy-omaha"
    },
    {
        "id": "407391",
        "name": "Capri Institute of Hair Design-Brick",
        "hyphenated": "capri-institute-of-hair-design-brick"
    },
    {
        "id": "407407",
        "name": "Capri Institute of Hair Design-Kenilworth",
        "hyphenated": "capri-institute-of-hair-design-kenilworth"
    },
    {
        "id": "407425",
        "name": "Capri Institute of Hair Design-Paramus",
        "hyphenated": "capri-institute-of-hair-design-paramus"
    },
    {
        "id": "407434",
        "name": "Orlo School of Hair Design and Cosmetology",
        "hyphenated": "orlo-school-of-hair-design-and-cosmetology"
    },
    {
        "id": "407461",
        "name": "Eastland-Fairfield Career and Technical Schools",
        "hyphenated": "eastland-fairfield-career-and-technical-schools"
    },
    {
        "id": "407470",
        "name": "Empire Beauty School-Cincinnati",
        "hyphenated": "empire-beauty-school-cincinnati"
    },
    {
        "id": "407489",
        "name": "Mahoning County Career and Technical Center",
        "hyphenated": "mahoning-county-career-and-technical-center"
    },
    {
        "id": "407513",
        "name": "Pickaway Ross Joint Vocational School District",
        "hyphenated": "pickaway-ross-joint-vocational-school-district"
    },
    {
        "id": "407522",
        "name": "Pioneer Career and Technology Center",
        "hyphenated": "pioneer-career-and-technology-center"
    },
    {
        "id": "407568",
        "name": "Raphael's School of Beauty Culture Inc-Boardman",
        "hyphenated": "raphael's-school-of-beauty-culture-inc-boardman"
    },
    {
        "id": "407577",
        "name": "Scioto County Career Technical Center",
        "hyphenated": "scioto-county-career-technical-center"
    },
    {
        "id": "407595",
        "name": "Trumbull Career & Technical Center",
        "hyphenated": "trumbull-career-&-technical-center"
    },
    {
        "id": "407610",
        "name": "Pacific Bible College",
        "hyphenated": "pacific-bible-college"
    },
    {
        "id": "407629",
        "name": "Lake Erie College of Osteopathic Medicine",
        "hyphenated": "lake-erie-college-of-osteopathic-medicine"
    },
    {
        "id": "407683",
        "name": "UCAS University of Cosmetology Arts & Sciences-Harlingen",
        "hyphenated": "ucas-university-of-cosmetology-arts-&-sciences-harlingen"
    },
    {
        "id": "407708",
        "name": "Highlights Beauty Schools",
        "hyphenated": "highlights-beauty-schools"
    },
    {
        "id": "407735",
        "name": "Empire Beauty School-Green Bay",
        "hyphenated": "empire-beauty-school-green-bay"
    },
    {
        "id": "407832",
        "name": "Warren County Career Center",
        "hyphenated": "warren-county-career-center"
    },
    {
        "id": "408066",
        "name": "Paul Mitchell the School-Wichita",
        "hyphenated": "paul-mitchell-the-school-wichita"
    },
    {
        "id": "408163",
        "name": "Pinnacle Institute of Cosmetology",
        "hyphenated": "pinnacle-institute-of-cosmetology"
    },
    {
        "id": "408385",
        "name": "Southern Careers Institute-Pharr",
        "hyphenated": "southern-careers-institute-pharr"
    },
    {
        "id": "408729",
        "name": "Laurel Technical Institute",
        "hyphenated": "laurel-technical-institute"
    },
    {
        "id": "408844",
        "name": "Florida National University-Main Campus",
        "hyphenated": "florida-national-university-main-campus"
    },
    {
        "id": "408862",
        "name": "Georgia Institute of Cosmetology",
        "hyphenated": "georgia-institute-of-cosmetology"
    },
    {
        "id": "409032",
        "name": "Nouvelle Institute",
        "hyphenated": "nouvelle-institute"
    },
    {
        "id": "409120",
        "name": "Franklin Technology Center Adult Education",
        "hyphenated": "franklin-technology-center-adult-education"
    },
    {
        "id": "409193",
        "name": "Professional Technical Institution",
        "hyphenated": "professional-technical-institution"
    },
    {
        "id": "409254",
        "name": "Erikson Institute",
        "hyphenated": "erikson-institute"
    },
    {
        "id": "409315",
        "name": "South Texas College",
        "hyphenated": "south-texas-college"
    },
    {
        "id": "409537",
        "name": "Pike County Joint Vocational School District",
        "hyphenated": "pike-county-joint-vocational-school-district"
    },
    {
        "id": "409591",
        "name": "Lorain County Joint Vocational School District",
        "hyphenated": "lorain-county-joint-vocational-school-district"
    },
    {
        "id": "409616",
        "name": "Roger Williams University School of Law",
        "hyphenated": "roger-williams-university-school-of-law"
    },
    {
        "id": "409698",
        "name": "California State University-Monterey Bay",
        "hyphenated": "california-state-university-monterey-bay"
    },
    {
        "id": "410797",
        "name": "Ohio Media School-Valley View",
        "hyphenated": "ohio-media-school-valley-view"
    },
    {
        "id": "410964",
        "name": "EINE Inc",
        "hyphenated": "eine-inc"
    },
    {
        "id": "411684",
        "name": "Marian Health Careers Center-Los Angeles Campus",
        "hyphenated": "marian-health-careers-center-los-angeles-campus"
    },
    {
        "id": "411754",
        "name": "Cleveland Clinic Health System-School of Diagnostic Imaging",
        "hyphenated": "cleveland-clinic-health-system-school-of-diagnostic-imaging"
    },
    {
        "id": "412173",
        "name": "Academy for Nursing and Health Occupations",
        "hyphenated": "academy-for-nursing-and-health-occupations"
    },
    {
        "id": "412535",
        "name": "Shawsheen Valley School of Practical Nursing",
        "hyphenated": "shawsheen-valley-school-of-practical-nursing"
    },
    {
        "id": "412544",
        "name": "Shear Ego International School of Hair Design",
        "hyphenated": "shear-ego-international-school-of-hair-design"
    },
    {
        "id": "412599",
        "name": "Remington College-Memphis Campus",
        "hyphenated": "remington-college-memphis-campus"
    },
    {
        "id": "413176",
        "name": "Fayette Institute of Technology",
        "hyphenated": "fayette-institute-of-technology"
    },
    {
        "id": "413413",
        "name": "Capella University",
        "hyphenated": "capella-university"
    },
    {
        "id": "413617",
        "name": "College of Menominee Nation",
        "hyphenated": "college-of-menominee-nation"
    },
    {
        "id": "413626",
        "name": "Leech Lake Tribal College",
        "hyphenated": "leech-lake-tribal-college"
    },
    {
        "id": "413680",
        "name": "Spartan College of Aeronautics & Technology",
        "hyphenated": "spartan-college-of-aeronautics-&-technology"
    },
    {
        "id": "413778",
        "name": "Stone Academy-Waterbury",
        "hyphenated": "stone-academy-waterbury"
    },
    {
        "id": "413820",
        "name": "Yukon Beauty College Inc",
        "hyphenated": "yukon-beauty-college-inc"
    },
    {
        "id": "413893",
        "name": "United Education Institute-Morrow",
        "hyphenated": "united-education-institute-morrow"
    },
    {
        "id": "413945",
        "name": "Toni & Guy Hairdressing Academy-Atlanta",
        "hyphenated": "toni-&-guy-hairdressing-academy-atlanta"
    },
    {
        "id": "413972",
        "name": "Mitchells Academy",
        "hyphenated": "mitchells-academy"
    },
    {
        "id": "414063",
        "name": "Artistic Academy of Hair Design",
        "hyphenated": "artistic-academy-of-hair-design"
    },
    {
        "id": "414124",
        "name": "Motoring Technical Training Institute",
        "hyphenated": "motoring-technical-training-institute"
    },
    {
        "id": "414461",
        "name": "Mech-Tech College",
        "hyphenated": "mech-tech-college"
    },
    {
        "id": "414489",
        "name": "Baldwin Beauty School-North Austin",
        "hyphenated": "baldwin-beauty-school-north-austin"
    },
    {
        "id": "414540",
        "name": "Jean Madeline Aveda Institute",
        "hyphenated": "jean-madeline-aveda-institute"
    },
    {
        "id": "414595",
        "name": "Pacific College of Health and Science",
        "hyphenated": "pacific-college-of-health-and-science"
    },
    {
        "id": "414869",
        "name": "Tricoci University of Beauty Culture-Indianapolis",
        "hyphenated": "tricoci-university-of-beauty-culture-indianapolis"
    },
    {
        "id": "414878",
        "name": "Trine University-Regional/Non-Traditional Campuses",
        "hyphenated": "trine-university-regional/non-traditional-campuses"
    },
    {
        "id": "414911",
        "name": "Pennsylvania Highlands Community College",
        "hyphenated": "pennsylvania-highlands-community-college"
    },
    {
        "id": "414939",
        "name": "Nevada Career Institute",
        "hyphenated": "nevada-career-institute"
    },
    {
        "id": "414966",
        "name": "Phillips Theological Seminary",
        "hyphenated": "phillips-theological-seminary"
    },
    {
        "id": "415039",
        "name": "Empire Beauty School-Hanover",
        "hyphenated": "empire-beauty-school-hanover"
    },
    {
        "id": "415057",
        "name": "Industrial Management Training Institute",
        "hyphenated": "industrial-management-training-institute"
    },
    {
        "id": "415987",
        "name": "Allen School-Jamaica",
        "hyphenated": "allen-school-jamaica"
    },
    {
        "id": "416458",
        "name": "Premiere Career College",
        "hyphenated": "premiere-career-college"
    },
    {
        "id": "416801",
        "name": "The University of Texas MD Anderson Cancer Center",
        "hyphenated": "the-university-of-texas-md-anderson-cancer-center"
    },
    {
        "id": "417008",
        "name": "Paul Mitchell the School-Huntsville",
        "hyphenated": "paul-mitchell-the-school-huntsville"
    },
    {
        "id": "417053",
        "name": "Cedars Sinai Medical Center",
        "hyphenated": "cedars-sinai-medical-center"
    },
    {
        "id": "417062",
        "name": "Estes Institute of Cosmetology Arts and Science",
        "hyphenated": "estes-institute-of-cosmetology-arts-and-science"
    },
    {
        "id": "417105",
        "name": "Baldwin Park Adult & Community Education",
        "hyphenated": "baldwin-park-adult-&-community-education"
    },
    {
        "id": "417123",
        "name": "Ventura Adult and Continuing Education",
        "hyphenated": "ventura-adult-and-continuing-education"
    },
    {
        "id": "417248",
        "name": "CT Aerotech",
        "hyphenated": "ct-aerotech"
    },
    {
        "id": "417275",
        "name": "Stratford School for Aviation Maintenance Technicians",
        "hyphenated": "stratford-school-for-aviation-maintenance-technicians"
    },
    {
        "id": "417327",
        "name": "City College-Altamonte Springs",
        "hyphenated": "city-college-altamonte-springs"
    },
    {
        "id": "417442",
        "name": "Career Center of Southern Illinois",
        "hyphenated": "career-center-of-southern-illinois"
    },
    {
        "id": "417503",
        "name": "Crave Beauty Academy",
        "hyphenated": "crave-beauty-academy"
    },
    {
        "id": "417600",
        "name": "Hastings Beauty School",
        "hyphenated": "hastings-beauty-school"
    },
    {
        "id": "417628",
        "name": "Kirksville Area Technical Center",
        "hyphenated": "kirksville-area-technical-center"
    },
    {
        "id": "417637",
        "name": "South Central Career Center",
        "hyphenated": "south-central-career-center"
    },
    {
        "id": "417646",
        "name": "Saline County Career Center",
        "hyphenated": "saline-county-career-center"
    },
    {
        "id": "417655",
        "name": "Warrensburg Area Career Center",
        "hyphenated": "warrensburg-area-career-center"
    },
    {
        "id": "417682",
        "name": "Cass Career Center",
        "hyphenated": "cass-career-center"
    },
    {
        "id": "417716",
        "name": "Applied Technology Services",
        "hyphenated": "applied-technology-services"
    },
    {
        "id": "417725",
        "name": "Central College of Cosmetology",
        "hyphenated": "central-college-of-cosmetology"
    },
    {
        "id": "417734",
        "name": "Southeast Missouri Hospital College of Nursing and Health Sciences",
        "hyphenated": "southeast-missouri-hospital-college-of-nursing-and-health-sciences"
    },
    {
        "id": "417752",
        "name": "Messenger College",
        "hyphenated": "messenger-college"
    },
    {
        "id": "417831",
        "name": "Union County Vocational Technical School",
        "hyphenated": "union-county-vocational-technical-school"
    },
    {
        "id": "417840",
        "name": "Prism Career Institute-Philadelphia",
        "hyphenated": "prism-career-institute-philadelphia"
    },
    {
        "id": "417868",
        "name": "Clinton Essex Warren Washington BOCES",
        "hyphenated": "clinton-essex-warren-washington-boces"
    },
    {
        "id": "417886",
        "name": "Jefferson Lewis BOCES-Practical Nursing Program",
        "hyphenated": "jefferson-lewis-boces-practical-nursing-program"
    },
    {
        "id": "417910",
        "name": "Veeb Nassau County School of Practical Nursing",
        "hyphenated": "veeb-nassau-county-school-of-practical-nursing"
    },
    {
        "id": "417983",
        "name": "Cattaraugus Allegany BOCES-Practical Nursing Program",
        "hyphenated": "cattaraugus-allegany-boces-practical-nursing-program"
    },
    {
        "id": "417992",
        "name": "Orange Ulster BOCES-Practical Nursing Program",
        "hyphenated": "orange-ulster-boces-practical-nursing-program"
    },
    {
        "id": "418001",
        "name": "Wayne Finger Lakes BOCES-Practical Nursing Program",
        "hyphenated": "wayne-finger-lakes-boces-practical-nursing-program"
    },
    {
        "id": "418010",
        "name": "Broome Delaware Tioga BOCES-Practical Nursing Program",
        "hyphenated": "broome-delaware-tioga-boces-practical-nursing-program"
    },
    {
        "id": "418029",
        "name": "Washington Saratoga Warren Hamilton Essex BOCES-Practical Nursing Program",
        "hyphenated": "washington-saratoga-warren-hamilton-essex-boces-practical-nursing-program"
    },
    {
        "id": "418038",
        "name": "Albany BOCES-Adult Practical Nursing Program",
        "hyphenated": "albany-boces-adult-practical-nursing-program"
    },
    {
        "id": "418056",
        "name": "Ulster BOCES-School of Practical Nursing",
        "hyphenated": "ulster-boces-school-of-practical-nursing"
    },
    {
        "id": "418065",
        "name": "Eastern Suffolk BOCES",
        "hyphenated": "eastern-suffolk-boces"
    },
    {
        "id": "418083",
        "name": "Rockland County BOCES-Practical Nursing Program",
        "hyphenated": "rockland-county-boces-practical-nursing-program"
    },
    {
        "id": "418126",
        "name": "New York College of Health Professions",
        "hyphenated": "new-york-college-of-health-professions"
    },
    {
        "id": "418171",
        "name": "Charles Stuart School of Diamond Setting",
        "hyphenated": "charles-stuart-school-of-diamond-setting"
    },
    {
        "id": "418199",
        "name": "Penta County Joint Vocational School",
        "hyphenated": "penta-county-joint-vocational-school"
    },
    {
        "id": "418205",
        "name": "Career and Technology Education Centers of Licking County",
        "hyphenated": "career-and-technology-education-centers-of-licking-county"
    },
    {
        "id": "418223",
        "name": "Northern Career Institute",
        "hyphenated": "northern-career-institute"
    },
    {
        "id": "418250",
        "name": "Washington County Career Center-Adult Technical Training",
        "hyphenated": "washington-county-career-center-adult-technical-training"
    },
    {
        "id": "418269",
        "name": "Ohio Media School-Cincinnati",
        "hyphenated": "ohio-media-school-cincinnati"
    },
    {
        "id": "418278",
        "name": "Vantage Career Center",
        "hyphenated": "vantage-career-center"
    },
    {
        "id": "418296",
        "name": "Indian Capital Technology Center-Muskogee",
        "hyphenated": "indian-capital-technology-center-muskogee"
    },
    {
        "id": "418302",
        "name": "Western Technology Center",
        "hyphenated": "western-technology-center"
    },
    {
        "id": "418320",
        "name": "Mid-America Technology Center",
        "hyphenated": "mid-america-technology-center"
    },
    {
        "id": "418348",
        "name": "Eastern Oklahoma County Technology Center",
        "hyphenated": "eastern-oklahoma-county-technology-center"
    },
    {
        "id": "418357",
        "name": "Wes Watkins Technology Center",
        "hyphenated": "wes-watkins-technology-center"
    },
    {
        "id": "418409",
        "name": "York County School of Technology-Adult & Continuing Education",
        "hyphenated": "york-county-school-of-technology-adult-&-continuing-education"
    },
    {
        "id": "418427",
        "name": "Chester County Intermediate Unit",
        "hyphenated": "chester-county-intermediate-unit"
    },
    {
        "id": "418445",
        "name": "Jefferson County Dubois Area Vocational Technical Practical Nursing Program",
        "hyphenated": "jefferson-county-dubois-area-vocational-technical-practical-nursing-program"
    },
    {
        "id": "418454",
        "name": "Greater Johnstown Career and Technology Center",
        "hyphenated": "greater-johnstown-career-and-technology-center"
    },
    {
        "id": "418481",
        "name": "Mifflin County Academy of Science and Technology",
        "hyphenated": "mifflin-county-academy-of-science-and-technology"
    },
    {
        "id": "418515",
        "name": "Lenape Technical School Practical Nursing Program",
        "hyphenated": "lenape-technical-school-practical-nursing-program"
    },
    {
        "id": "418524",
        "name": "Venango County Area Vocational Technical School",
        "hyphenated": "venango-county-area-vocational-technical-school"
    },
    {
        "id": "418533",
        "name": "Lancaster County Career and Technology Center",
        "hyphenated": "lancaster-county-career-and-technology-center"
    },
    {
        "id": "418542",
        "name": "Lebanon County Area Vocational Technical School",
        "hyphenated": "lebanon-county-area-vocational-technical-school"
    },
    {
        "id": "418560",
        "name": "Indiana County Technology Center",
        "hyphenated": "indiana-county-technology-center"
    },
    {
        "id": "418588",
        "name": "Northern Tier Career Center",
        "hyphenated": "northern-tier-career-center"
    },
    {
        "id": "418612",
        "name": "Hazleton Area Career Center",
        "hyphenated": "hazleton-area-career-center"
    },
    {
        "id": "418658",
        "name": "All-State Career School-Pittsburgh",
        "hyphenated": "all-state-career-school-pittsburgh"
    },
    {
        "id": "418764",
        "name": "Quality Technical and Beauty College",
        "hyphenated": "quality-technical-and-beauty-college"
    },
    {
        "id": "418773",
        "name": "D'Mart Institute",
        "hyphenated": "d'mart-institute"
    },
    {
        "id": "418782",
        "name": "Automeca Technical College-Aguadilla",
        "hyphenated": "automeca-technical-college-aguadilla"
    },
    {
        "id": "418889",
        "name": "North Central Institute",
        "hyphenated": "north-central-institute"
    },
    {
        "id": "418913",
        "name": "Pipo Academy of Hair Design",
        "hyphenated": "pipo-academy-of-hair-design"
    },
    {
        "id": "418968",
        "name": "Tint School of Makeup and Cosmetology-Dallas",
        "hyphenated": "tint-school-of-makeup-and-cosmetology-dallas"
    },
    {
        "id": "419031",
        "name": "United Technical Center",
        "hyphenated": "united-technical-center"
    },
    {
        "id": "419095",
        "name": "Charles H McCann Technical School",
        "hyphenated": "charles-h-mccann-technical-school"
    },
    {
        "id": "419183",
        "name": "Central California School of Continuing Education",
        "hyphenated": "central-california-school-of-continuing-education"
    },
    {
        "id": "419217",
        "name": "Mercy Hospital School of Practical Nursing",
        "hyphenated": "mercy-hospital-school-of-practical-nursing"
    },
    {
        "id": "419226",
        "name": "Marion Technical College",
        "hyphenated": "marion-technical-college"
    },
    {
        "id": "419235",
        "name": "Rivertown School of Beauty Barber Skin Care and Nails",
        "hyphenated": "rivertown-school-of-beauty-barber-skin-care-and-nails"
    },
    {
        "id": "419244",
        "name": "Atlanta Institute of Music and Media",
        "hyphenated": "atlanta-institute-of-music-and-media"
    },
    {
        "id": "419253",
        "name": "Illinois Media School",
        "hyphenated": "illinois-media-school"
    },
    {
        "id": "419280",
        "name": "New Dimensions School of Hair Design",
        "hyphenated": "new-dimensions-school-of-hair-design"
    },
    {
        "id": "419332",
        "name": "Forbes Road Career and Technology Center",
        "hyphenated": "forbes-road-career-and-technology-center"
    },
    {
        "id": "419341",
        "name": "Jna Institute of Culinary Arts",
        "hyphenated": "jna-institute-of-culinary-arts"
    },
    {
        "id": "419420",
        "name": "Putnam Career and Technical Center",
        "hyphenated": "putnam-career-and-technical-center"
    },
    {
        "id": "419484",
        "name": "Crawford County Career and Technical Center Practical Nursing Program",
        "hyphenated": "crawford-county-career-and-technical-center-practical-nursing-program"
    },
    {
        "id": "419633",
        "name": "Texas College of Cosmetology-San Angelo",
        "hyphenated": "texas-college-of-cosmetology-san-angelo"
    },
    {
        "id": "419703",
        "name": "Automeca Technical College-Bayamon",
        "hyphenated": "automeca-technical-college-bayamon"
    },
    {
        "id": "419712",
        "name": "Automeca Technical College-Caguas",
        "hyphenated": "automeca-technical-college-caguas"
    },
    {
        "id": "419721",
        "name": "Automeca Technical College-Ponce",
        "hyphenated": "automeca-technical-college-ponce"
    },
    {
        "id": "420024",
        "name": "Centura College-Chesapeake",
        "hyphenated": "centura-college-chesapeake"
    },
    {
        "id": "420042",
        "name": "University of Phoenix-Hawaii",
        "hyphenated": "university-of-phoenix-hawaii"
    },
    {
        "id": "420130",
        "name": "Prism Career Institute-Cherry Hill",
        "hyphenated": "prism-career-institute-cherry-hill"
    },
    {
        "id": "420246",
        "name": "Sonoran University of Health Sciences",
        "hyphenated": "sonoran-university-of-health-sciences"
    },
    {
        "id": "420255",
        "name": "Escuela de Peritos Electricistas de Isabela Inc",
        "hyphenated": "escuela-de-peritos-electricistas-de-isabela-inc"
    },
    {
        "id": "420325",
        "name": "Yeshiva D'monsey Rabbinical College",
        "hyphenated": "yeshiva-d'monsey-rabbinical-college"
    },
    {
        "id": "420343",
        "name": "Lincoln Technical Institute-Mahwah",
        "hyphenated": "lincoln-technical-institute-mahwah"
    },
    {
        "id": "420370",
        "name": "Career Technical Institute",
        "hyphenated": "career-technical-institute"
    },
    {
        "id": "420398",
        "name": "Northwest Vista College",
        "hyphenated": "northwest-vista-college"
    },
    {
        "id": "420431",
        "name": "Oconee Fall Line Technical College",
        "hyphenated": "oconee-fall-line-technical-college"
    },
    {
        "id": "420440",
        "name": "York County Community College",
        "hyphenated": "york-county-community-college"
    },
    {
        "id": "420468",
        "name": "Western Suffolk BOCES",
        "hyphenated": "western-suffolk-boces"
    },
    {
        "id": "420538",
        "name": "Arkansas State University-Mountain Home",
        "hyphenated": "arkansas-state-university-mountain-home"
    },
    {
        "id": "420556",
        "name": "Columbia Gorge Community College",
        "hyphenated": "columbia-gorge-community-college"
    },
    {
        "id": "420705",
        "name": "Bakke Graduate University",
        "hyphenated": "bakke-graduate-university"
    },
    {
        "id": "420723",
        "name": "Tillamook Bay Community College",
        "hyphenated": "tillamook-bay-community-college"
    },
    {
        "id": "420981",
        "name": "Manhattan School of Computer Technology",
        "hyphenated": "manhattan-school-of-computer-technology"
    },
    {
        "id": "421513",
        "name": "Ross College-Hopkinsville",
        "hyphenated": "ross-college-hopkinsville"
    },
    {
        "id": "421610",
        "name": "Paul Mitchell the School-St. George",
        "hyphenated": "paul-mitchell-the-school-st.-george"
    },
    {
        "id": "421665",
        "name": "Cadillac Institute of Cosmetology",
        "hyphenated": "cadillac-institute-of-cosmetology"
    },
    {
        "id": "421708",
        "name": "Arizona College of Nursing-Tempe",
        "hyphenated": "arizona-college-of-nursing-tempe"
    },
    {
        "id": "421717",
        "name": "Vanguard-Sentinel Adult Career and Technology Center",
        "hyphenated": "vanguard-sentinel-adult-career-and-technology-center"
    },
    {
        "id": "421771",
        "name": "Academy of Beauty Professionals",
        "hyphenated": "academy-of-beauty-professionals"
    },
    {
        "id": "421832",
        "name": "Living Arts College",
        "hyphenated": "living-arts-college"
    },
    {
        "id": "421841",
        "name": "New York Conservatory for Dramatic Arts",
        "hyphenated": "new-york-conservatory-for-dramatic-arts"
    },
    {
        "id": "421878",
        "name": "Eastern International College-Jersey City",
        "hyphenated": "eastern-international-college-jersey-city"
    },
    {
        "id": "421896",
        "name": "Academia Serrant Inc",
        "hyphenated": "academia-serrant-inc"
    },
    {
        "id": "422020",
        "name": "San Joaquin Valley College-Trades Education Center",
        "hyphenated": "san-joaquin-valley-college-trades-education-center"
    },
    {
        "id": "422695",
        "name": "Pacific College",
        "hyphenated": "pacific-college"
    },
    {
        "id": "422835",
        "name": "American College of Healthcare and Technology",
        "hyphenated": "american-college-of-healthcare-and-technology"
    },
    {
        "id": "423120",
        "name": "Compu-Med Vocational Careers Corp",
        "hyphenated": "compu-med-vocational-careers-corp"
    },
    {
        "id": "423397",
        "name": "Advantage Technical College",
        "hyphenated": "advantage-technical-college"
    },
    {
        "id": "423412",
        "name": "Mid Cities Barber College",
        "hyphenated": "mid-cities-barber-college"
    },
    {
        "id": "423643",
        "name": "Midwestern University-Glendale",
        "hyphenated": "midwestern-university-glendale"
    },
    {
        "id": "423652",
        "name": "Oregon Coast Community College",
        "hyphenated": "oregon-coast-community-college"
    },
    {
        "id": "425250",
        "name": "Gwinnett College-Sandy Springs",
        "hyphenated": "gwinnett-college-sandy-springs"
    },
    {
        "id": "425986",
        "name": "Mildred Elley-Pittsfield Campus",
        "hyphenated": "mildred-elley-pittsfield-campus"
    },
    {
        "id": "426314",
        "name": "Embry-Riddle Aeronautical University-Worldwide",
        "hyphenated": "embry-riddle-aeronautical-university-worldwide"
    },
    {
        "id": "427308",
        "name": "Merkaz Bnos-Business School",
        "hyphenated": "merkaz-bnos-business-school"
    },
    {
        "id": "427335",
        "name": "Lia Schorr Institute of Cosmetic Skin Care Training",
        "hyphenated": "lia-schorr-institute-of-cosmetic-skin-care-training"
    },
    {
        "id": "427894",
        "name": "CET-Alexandria",
        "hyphenated": "cet-alexandria"
    },
    {
        "id": "427973",
        "name": "Aviation Institute of Maintenance-Norfolk",
        "hyphenated": "aviation-institute-of-maintenance-norfolk"
    },
    {
        "id": "427982",
        "name": "Centura College-Richmond Main",
        "hyphenated": "centura-college-richmond-main"
    },
    {
        "id": "427991",
        "name": "Osceola Technical College",
        "hyphenated": "osceola-technical-college"
    },
    {
        "id": "428000",
        "name": "Miami Ad School",
        "hyphenated": "miami-ad-school"
    },
    {
        "id": "428019",
        "name": "Green Country Technology Center",
        "hyphenated": "green-country-technology-center"
    },
    {
        "id": "428055",
        "name": "Nova College de Puerto Rico",
        "hyphenated": "nova-college-de-puerto-rico"
    },
    {
        "id": "428125",
        "name": "John Wesley International Barber and Beauty College",
        "hyphenated": "john-wesley-international-barber-and-beauty-college"
    },
    {
        "id": "428170",
        "name": "Southeastern College-West Palm Beach",
        "hyphenated": "southeastern-college-west-palm-beach"
    },
    {
        "id": "428259",
        "name": "Ottawa University-Milwaukee",
        "hyphenated": "ottawa-university-milwaukee"
    },
    {
        "id": "428392",
        "name": "Klamath Community College",
        "hyphenated": "klamath-community-college"
    },
    {
        "id": "429012",
        "name": "L3Harris Flight Academy",
        "hyphenated": "l3harris-flight-academy"
    },
    {
        "id": "429085",
        "name": "American College of Acupuncture and Oriental Med",
        "hyphenated": "american-college-of-acupuncture-and-oriental-med"
    },
    {
        "id": "429094",
        "name": "AOMA Graduate School of Integrative Medicine",
        "hyphenated": "aoma-graduate-school-of-integrative-medicine"
    },
    {
        "id": "429128",
        "name": "Urban College of Boston",
        "hyphenated": "urban-college-of-boston"
    },
    {
        "id": "430184",
        "name": "Strayer University-Maryland",
        "hyphenated": "strayer-university-maryland"
    },
    {
        "id": "430227",
        "name": "Empire Beauty School-Monroeville",
        "hyphenated": "empire-beauty-school-monroeville"
    },
    {
        "id": "430245",
        "name": "Southern Careers Institute-San Antonio",
        "hyphenated": "southern-careers-institute-san-antonio"
    },
    {
        "id": "430306",
        "name": "Cayce/Reilly School of Massage",
        "hyphenated": "cayce/reilly-school-of-massage"
    },
    {
        "id": "430403",
        "name": "Prism Career Institute-West Atlantic City",
        "hyphenated": "prism-career-institute-west-atlantic-city"
    },
    {
        "id": "430564",
        "name": "Studio Jewelers",
        "hyphenated": "studio-jewelers"
    },
    {
        "id": "430582",
        "name": "Praxis Institute",
        "hyphenated": "praxis-institute"
    },
    {
        "id": "430670",
        "name": "San Juan Bautista School of Medicine",
        "hyphenated": "san-juan-bautista-school-of-medicine"
    },
    {
        "id": "430704",
        "name": "Texas Health and Science University",
        "hyphenated": "texas-health-and-science-university"
    },
    {
        "id": "430795",
        "name": "Carver Career Center",
        "hyphenated": "carver-career-center"
    },
    {
        "id": "430883",
        "name": "Cayuga Onondaga BOCES-Practical Nursing Program",
        "hyphenated": "cayuga-onondaga-boces-practical-nursing-program"
    },
    {
        "id": "430908",
        "name": "Clearfield County Career and Technology Center",
        "hyphenated": "clearfield-county-career-and-technology-center"
    },
    {
        "id": "430935",
        "name": "Colegio de Cinematografia Artes y Television",
        "hyphenated": "colegio-de-cinematografia-artes-y-television"
    },
    {
        "id": "430971",
        "name": "Delaware County Technical School-Practical Nursing Program",
        "hyphenated": "delaware-county-technical-school-practical-nursing-program"
    },
    {
        "id": "431017",
        "name": "Mid-Del Technology Center",
        "hyphenated": "mid-del-technology-center"
    },
    {
        "id": "431071",
        "name": "New York Institute of Massage Inc",
        "hyphenated": "new-york-institute-of-massage-inc"
    },
    {
        "id": "431105",
        "name": "Dutchess BOCES-Practical Nursing Program",
        "hyphenated": "dutchess-boces-practical-nursing-program"
    },
    {
        "id": "431123",
        "name": "Escuela Tecnica de Electricidad",
        "hyphenated": "escuela-tecnica-de-electricidad"
    },
    {
        "id": "431141",
        "name": "Institute of Technology",
        "hyphenated": "institute-of-technology"
    },
    {
        "id": "431169",
        "name": "Garnet Career Center",
        "hyphenated": "garnet-career-center"
    },
    {
        "id": "431187",
        "name": "Bristol Technical Education Center",
        "hyphenated": "bristol-technical-education-center"
    },
    {
        "id": "431196",
        "name": "Genesee Valley BOCES-Practical Nursing Program",
        "hyphenated": "genesee-valley-boces-practical-nursing-program"
    },
    {
        "id": "431266",
        "name": "Henrico County-Saint Marys Hospital School of Practical Nursing",
        "hyphenated": "henrico-county-saint-marys-hospital-school-of-practical-nursing"
    },
    {
        "id": "431275",
        "name": "Herkimer County BOCES-Practical Nursing Program",
        "hyphenated": "herkimer-county-boces-practical-nursing-program"
    },
    {
        "id": "431284",
        "name": "International Beauty College",
        "hyphenated": "international-beauty-college"
    },
    {
        "id": "431558",
        "name": "Cape Coral Technical College",
        "hyphenated": "cape-coral-technical-college"
    },
    {
        "id": "431600",
        "name": "UPMC Mercy School of Nursing",
        "hyphenated": "upmc-mercy-school-of-nursing"
    },
    {
        "id": "431716",
        "name": "Ocean County Vocational-Technical School",
        "hyphenated": "ocean-county-vocational-technical-school"
    },
    {
        "id": "431983",
        "name": "Yeshiva of the Telshe Alumni",
        "hyphenated": "yeshiva-of-the-telshe-alumni"
    },
    {
        "id": "432117",
        "name": "Kenneth Shuler School of Cosmetology-Rock Hill",
        "hyphenated": "kenneth-shuler-school-of-cosmetology-rock-hill"
    },
    {
        "id": "432269",
        "name": "CET-El Paso",
        "hyphenated": "cet-el-paso"
    },
    {
        "id": "432348",
        "name": "Appalachian School of Law",
        "hyphenated": "appalachian-school-of-law"
    },
    {
        "id": "432384",
        "name": "Platt College-Ontario",
        "hyphenated": "platt-college-ontario"
    },
    {
        "id": "432524",
        "name": "Delaware College of Art and Design",
        "hyphenated": "delaware-college-of-art-and-design"
    },
    {
        "id": "433004",
        "name": "American University of Health Sciences",
        "hyphenated": "american-university-of-health-sciences"
    },
    {
        "id": "433013",
        "name": "Career Networks Institute",
        "hyphenated": "career-networks-institute"
    },
    {
        "id": "433040",
        "name": "Monty Tech",
        "hyphenated": "monty-tech"
    },
    {
        "id": "433068",
        "name": "Okaloosa Technical College",
        "hyphenated": "okaloosa-technical-college"
    },
    {
        "id": "433101",
        "name": "Lincoln Technical Institute-Lincoln",
        "hyphenated": "lincoln-technical-institute-lincoln"
    },
    {
        "id": "433138",
        "name": "Wayne County Schools Career Center",
        "hyphenated": "wayne-county-schools-career-center"
    },
    {
        "id": "433174",
        "name": "Carolinas College of Health Sciences",
        "hyphenated": "carolinas-college-of-health-sciences"
    },
    {
        "id": "433387",
        "name": "Western Governors University",
        "hyphenated": "western-governors-university"
    },
    {
        "id": "433420",
        "name": "California Aeronautical University",
        "hyphenated": "california-aeronautical-university"
    },
    {
        "id": "433536",
        "name": "Herzing University-New Orleans",
        "hyphenated": "herzing-university-new-orleans"
    },
    {
        "id": "433660",
        "name": "Florida Gulf Coast University",
        "hyphenated": "florida-gulf-coast-university"
    },
    {
        "id": "434016",
        "name": "Little Priest Tribal College",
        "hyphenated": "little-priest-tribal-college"
    },
    {
        "id": "434061",
        "name": "South Louisiana Community College",
        "hyphenated": "south-louisiana-community-college"
    },
    {
        "id": "434140",
        "name": "Pima Medical Institute-Chula Vista",
        "hyphenated": "pima-medical-institute-chula-vista"
    },
    {
        "id": "434159",
        "name": "Lincoln College of Technology-Marietta",
        "hyphenated": "lincoln-college-of-technology-marietta"
    },
    {
        "id": "434274",
        "name": "Academy of Hair Design-Lufkin",
        "hyphenated": "academy-of-hair-design-lufkin"
    },
    {
        "id": "434283",
        "name": "Pathway Vocational Academy",
        "hyphenated": "pathway-vocational-academy"
    },
    {
        "id": "434308",
        "name": "Cortiva Institute",
        "hyphenated": "cortiva-institute"
    },
    {
        "id": "434344",
        "name": "Toni & Guy Hairdressing Academy-Worcester",
        "hyphenated": "toni-&-guy-hairdressing-academy-worcester"
    },
    {
        "id": "434362",
        "name": "Northwest HVAC/R Training Center",
        "hyphenated": "northwest-hvac/r-training-center"
    },
    {
        "id": "434432",
        "name": "Myotherapy Institute",
        "hyphenated": "myotherapy-institute"
    },
    {
        "id": "434441",
        "name": "Florida College of Integrative Medicine",
        "hyphenated": "florida-college-of-integrative-medicine"
    },
    {
        "id": "434469",
        "name": "New Professions Technical Institute",
        "hyphenated": "new-professions-technical-institute"
    },
    {
        "id": "434496",
        "name": "Strand College of Hair Design",
        "hyphenated": "strand-college-of-hair-design"
    },
    {
        "id": "434539",
        "name": "City College-Miami",
        "hyphenated": "city-college-miami"
    },
    {
        "id": "434584",
        "name": "Ilisagvik College",
        "hyphenated": "ilisagvik-college"
    },
    {
        "id": "434672",
        "name": "Community College of Baltimore County",
        "hyphenated": "community-college-of-baltimore-county"
    },
    {
        "id": "434751",
        "name": "White Earth Tribal and Community College",
        "hyphenated": "white-earth-tribal-and-community-college"
    },
    {
        "id": "434821",
        "name": "Blue Cliff College-Metairie",
        "hyphenated": "blue-cliff-college-metairie"
    },
    {
        "id": "434830",
        "name": "Creative Images Institute of Cosmetology-North Dayton",
        "hyphenated": "creative-images-institute-of-cosmetology-north-dayton"
    },
    {
        "id": "434900",
        "name": "Dewey University-Carolina",
        "hyphenated": "dewey-university-carolina"
    },
    {
        "id": "434937",
        "name": "Yeshiva College of the Nations Capital",
        "hyphenated": "yeshiva-college-of-the-nations-capital"
    },
    {
        "id": "435000",
        "name": "Louisiana State University Health Sciences Center-Shreveport",
        "hyphenated": "louisiana-state-university-health-sciences-center-shreveport"
    },
    {
        "id": "436003",
        "name": "Central Career Institute LLC",
        "hyphenated": "central-career-institute-llc"
    },
    {
        "id": "436030",
        "name": "Hair Academy II",
        "hyphenated": "hair-academy-ii"
    },
    {
        "id": "436261",
        "name": "Southwest Acupuncture College-Boulder",
        "hyphenated": "southwest-acupuncture-college-boulder"
    },
    {
        "id": "436304",
        "name": "River Parishes Community College",
        "hyphenated": "river-parishes-community-college"
    },
    {
        "id": "436429",
        "name": "Los Angeles Film School",
        "hyphenated": "los-angeles-film-school"
    },
    {
        "id": "436553",
        "name": "Century College",
        "hyphenated": "century-college"
    },
    {
        "id": "436599",
        "name": "FINE Mortuary College",
        "hyphenated": "fine-mortuary-college"
    },
    {
        "id": "436614",
        "name": "International Baptist College and Seminary",
        "hyphenated": "international-baptist-college-and-seminary"
    },
    {
        "id": "436632",
        "name": "Gwinnett College",
        "hyphenated": "gwinnett-college"
    },
    {
        "id": "436641",
        "name": "Fortis College-Foley",
        "hyphenated": "fortis-college-foley"
    },
    {
        "id": "436650",
        "name": "Medical Training College",
        "hyphenated": "medical-training-college"
    },
    {
        "id": "436775",
        "name": "Toni & Guy Hairdressing Academy-Plano",
        "hyphenated": "toni-&-guy-hairdressing-academy-plano"
    },
    {
        "id": "436818",
        "name": "University of Connecticut-Waterbury Campus",
        "hyphenated": "university-of-connecticut-waterbury-campus"
    },
    {
        "id": "436827",
        "name": "University of Connecticut-Avery Point",
        "hyphenated": "university-of-connecticut-avery-point"
    },
    {
        "id": "436836",
        "name": "University of Connecticut-Stamford",
        "hyphenated": "university-of-connecticut-stamford"
    },
    {
        "id": "437097",
        "name": "University of Management and Technology",
        "hyphenated": "university-of-management-and-technology"
    },
    {
        "id": "437103",
        "name": "Baton Rouge Community College",
        "hyphenated": "baton-rouge-community-college"
    },
    {
        "id": "437237",
        "name": "IYRS School of Technology & Trades",
        "hyphenated": "iyrs-school-of-technology-&-trades"
    },
    {
        "id": "437316",
        "name": "Linfield University-School of Nursing",
        "hyphenated": "linfield-university-school-of-nursing"
    },
    {
        "id": "437325",
        "name": "Linfield University-Online and Continuing Education",
        "hyphenated": "linfield-university-online-and-continuing-education"
    },
    {
        "id": "437556",
        "name": "Midwest Technical Institute-Illinois",
        "hyphenated": "midwest-technical-institute-illinois"
    },
    {
        "id": "437608",
        "name": "Advanced Barber College and Hair Design",
        "hyphenated": "advanced-barber-college-and-hair-design"
    },
    {
        "id": "437635",
        "name": "The College of Health Care Professions-Austin",
        "hyphenated": "the-college-of-health-care-professions-austin"
    },
    {
        "id": "437723",
        "name": "New Community Career & Technical Institute",
        "hyphenated": "new-community-career-&-technical-institute"
    },
    {
        "id": "437732",
        "name": "Chicago School of Professional Psychology-College of Nursing",
        "hyphenated": "chicago-school-of-professional-psychology-college-of-nursing"
    },
    {
        "id": "437750",
        "name": "Professional Golfers Career College",
        "hyphenated": "professional-golfers-career-college"
    },
    {
        "id": "437769",
        "name": "Chester Career College",
        "hyphenated": "chester-career-college"
    },
    {
        "id": "437778",
        "name": "Milan Institute-San Antonio Ingram",
        "hyphenated": "milan-institute-san-antonio-ingram"
    },
    {
        "id": "437857",
        "name": "Rudae's School of Beauty Culture-Ft Wayne",
        "hyphenated": "rudae's-school-of-beauty-culture-ft-wayne"
    },
    {
        "id": "437936",
        "name": "Carrington College-San Jose",
        "hyphenated": "carrington-college-san-jose"
    },
    {
        "id": "438179",
        "name": "Brookline College-Tucson",
        "hyphenated": "brookline-college-tucson"
    },
    {
        "id": "438221",
        "name": "Miller-Motte College-McCann-Lewisburg",
        "hyphenated": "miller-motte-college-mccann-lewisburg"
    },
    {
        "id": "438258",
        "name": "Carrington College-Pleasant Hill Campus",
        "hyphenated": "carrington-college-pleasant-hill-campus"
    },
    {
        "id": "438285",
        "name": "Cortiva Institute",
        "hyphenated": "cortiva-institute"
    },
    {
        "id": "438337",
        "name": "Modern Hairstyling Institute-Carolina",
        "hyphenated": "modern-hairstyling-institute-carolina"
    },
    {
        "id": "438498",
        "name": "Stratford University",
        "hyphenated": "stratford-university"
    },
    {
        "id": "438504",
        "name": "Universal Technical Institute of California Inc",
        "hyphenated": "universal-technical-institute-of-california-inc"
    },
    {
        "id": "438586",
        "name": "American InterContinental University-Atlanta",
        "hyphenated": "american-intercontinental-university-atlanta"
    },
    {
        "id": "438674",
        "name": "The Academy of Hair Design Six",
        "hyphenated": "the-academy-of-hair-design-six"
    },
    {
        "id": "438708",
        "name": "Eastern West Virginia Community and Technical College",
        "hyphenated": "eastern-west-virginia-community-and-technical-college"
    },
    {
        "id": "438735",
        "name": "Aviation Institute of Maintenance-Indianapolis",
        "hyphenated": "aviation-institute-of-maintenance-indianapolis"
    },
    {
        "id": "438984",
        "name": "IVAEM College",
        "hyphenated": "ivaem-college"
    },
    {
        "id": "439020",
        "name": "Midwest College of Oriental Medicine-Skokie",
        "hyphenated": "midwest-college-of-oriental-medicine-skokie"
    },
    {
        "id": "439118",
        "name": "Carrington College-Spokane",
        "hyphenated": "carrington-college-spokane"
    },
    {
        "id": "439190",
        "name": "Cascadia College",
        "hyphenated": "cascadia-college"
    },
    {
        "id": "439367",
        "name": "CBD College",
        "hyphenated": "cbd-college"
    },
    {
        "id": "439394",
        "name": "East West College of Natural Medicine",
        "hyphenated": "east-west-college-of-natural-medicine"
    },
    {
        "id": "439446",
        "name": "Atlantic Institute of Oriental Medicine",
        "hyphenated": "atlantic-institute-of-oriental-medicine"
    },
    {
        "id": "439455",
        "name": "MDT College of Health Sciences",
        "hyphenated": "mdt-college-of-health-sciences"
    },
    {
        "id": "439473",
        "name": "Bennett Career Institute",
        "hyphenated": "bennett-career-institute"
    },
    {
        "id": "439491",
        "name": "Blue Cliff College-Lafayette",
        "hyphenated": "blue-cliff-college-lafayette"
    },
    {
        "id": "439507",
        "name": "Quest College",
        "hyphenated": "quest-college"
    },
    {
        "id": "439570",
        "name": "Community Care College",
        "hyphenated": "community-care-college"
    },
    {
        "id": "439622",
        "name": "Erie 1 BOCES",
        "hyphenated": "erie-1-boces"
    },
    {
        "id": "439668",
        "name": "HDS Truck Driving Institute",
        "hyphenated": "hds-truck-driving-institute"
    },
    {
        "id": "439686",
        "name": "International Training Careers",
        "hyphenated": "international-training-careers"
    },
    {
        "id": "439701",
        "name": "The King's University",
        "hyphenated": "the-king's-university"
    },
    {
        "id": "439738",
        "name": "Fortis College-Baton Rouge",
        "hyphenated": "fortis-college-baton-rouge"
    },
    {
        "id": "439747",
        "name": "My Le's Beauty College",
        "hyphenated": "my-le's-beauty-college"
    },
    {
        "id": "439774",
        "name": "New Concept Massage and Beauty School",
        "hyphenated": "new-concept-massage-and-beauty-school"
    },
    {
        "id": "439783",
        "name": "New York College of Traditional Chinese Medicine",
        "hyphenated": "new-york-college-of-traditional-chinese-medicine"
    },
    {
        "id": "439792",
        "name": "Fortis College-Orange Park",
        "hyphenated": "fortis-college-orange-park"
    },
    {
        "id": "439817",
        "name": "Northeastern Seminary",
        "hyphenated": "northeastern-seminary"
    },
    {
        "id": "439844",
        "name": "Omega Institute of Cosmetology",
        "hyphenated": "omega-institute-of-cosmetology"
    },
    {
        "id": "439862",
        "name": "Pacific Islands University",
        "hyphenated": "pacific-islands-university"
    },
    {
        "id": "439871",
        "name": "PCI College",
        "hyphenated": "pci-college"
    },
    {
        "id": "439899",
        "name": "Rosedale Bible College",
        "hyphenated": "rosedale-bible-college"
    },
    {
        "id": "439914",
        "name": "Seattle Institute of East Asian Medicine",
        "hyphenated": "seattle-institute-of-east-asian-medicine"
    },
    {
        "id": "439932",
        "name": "Shawnee Beauty College",
        "hyphenated": "shawnee-beauty-college"
    },
    {
        "id": "439969",
        "name": "Acupuncture and Massage College",
        "hyphenated": "acupuncture-and-massage-college"
    },
    {
        "id": "440004",
        "name": "Upper Valley Educators Institute",
        "hyphenated": "upper-valley-educators-institute"
    },
    {
        "id": "440031",
        "name": "Keck Graduate Institute",
        "hyphenated": "keck-graduate-institute"
    },
    {
        "id": "440059",
        "name": "Aviation Institute of Maintenance-Atlanta",
        "hyphenated": "aviation-institute-of-maintenance-atlanta"
    },
    {
        "id": "440101",
        "name": "Empire Beauty School-Kennesaw",
        "hyphenated": "empire-beauty-school-kennesaw"
    },
    {
        "id": "440226",
        "name": "Missouri College of Cosmetology North",
        "hyphenated": "missouri-college-of-cosmetology-north"
    },
    {
        "id": "440262",
        "name": "New York Automotive and Diesel Institute",
        "hyphenated": "new-york-automotive-and-diesel-institute"
    },
    {
        "id": "440271",
        "name": "Remington College-Baton Rouge Campus",
        "hyphenated": "remington-college-baton-rouge-campus"
    },
    {
        "id": "440396",
        "name": "New Saint Andrews College",
        "hyphenated": "new-saint-andrews-college"
    },
    {
        "id": "440402",
        "name": "Arkansas State University-Newport",
        "hyphenated": "arkansas-state-university-newport"
    },
    {
        "id": "440651",
        "name": "Atenas College",
        "hyphenated": "atenas-college"
    },
    {
        "id": "440730",
        "name": "Onondaga Cortland Madison BOCES",
        "hyphenated": "onondaga-cortland-madison-boces"
    },
    {
        "id": "440749",
        "name": "National American University-Ellsworth",
        "hyphenated": "national-american-university-ellsworth"
    },
    {
        "id": "440776",
        "name": "Interactive College of Technology",
        "hyphenated": "interactive-college-of-technology"
    },
    {
        "id": "440794",
        "name": "Pillar College",
        "hyphenated": "pillar-college"
    },
    {
        "id": "440819",
        "name": "Miami Ad School-San Francisco",
        "hyphenated": "miami-ad-school-san-francisco"
    },
    {
        "id": "440828",
        "name": "Oregon State University-Cascades Campus",
        "hyphenated": "oregon-state-university-cascades-campus"
    },
    {
        "id": "440855",
        "name": "Milan Institute-Amarillo",
        "hyphenated": "milan-institute-amarillo"
    },
    {
        "id": "440916",
        "name": "Southern University Law Center",
        "hyphenated": "southern-university-law-center"
    },
    {
        "id": "440989",
        "name": "Texas Barber College",
        "hyphenated": "texas-barber-college"
    },
    {
        "id": "441025",
        "name": "Miller-Motte College-Charleston",
        "hyphenated": "miller-motte-college-charleston"
    },
    {
        "id": "441052",
        "name": "American Career College-Anaheim",
        "hyphenated": "american-career-college-anaheim"
    },
    {
        "id": "441070",
        "name": "Saginaw Chippewa Tribal College",
        "hyphenated": "saginaw-chippewa-tribal-college"
    },
    {
        "id": "441104",
        "name": "Richmont Graduate University",
        "hyphenated": "richmont-graduate-university"
    },
    {
        "id": "441131",
        "name": "The Seattle School of Theology & Psychology",
        "hyphenated": "the-seattle-school-of-theology-&-psychology"
    },
    {
        "id": "441168",
        "name": "Northwest College-Hillsboro",
        "hyphenated": "northwest-college-hillsboro"
    },
    {
        "id": "441210",
        "name": "East Valley Institute of Technology",
        "hyphenated": "east-valley-institute-of-technology"
    },
    {
        "id": "441229",
        "name": "Smith Chason College",
        "hyphenated": "smith-chason-college"
    },
    {
        "id": "441238",
        "name": "Irell & Manella Graduate School of Biological Sciences at City of Hope",
        "hyphenated": "irell-&-manella-graduate-school-of-biological-sciences-at-city-of-hope"
    },
    {
        "id": "441256",
        "name": "Design's School of Cosmetology",
        "hyphenated": "design's-school-of-cosmetology"
    },
    {
        "id": "441371",
        "name": "Ultimate Medical Academy",
        "hyphenated": "ultimate-medical-academy"
    },
    {
        "id": "441380",
        "name": "Florida Barber Academy",
        "hyphenated": "florida-barber-academy"
    },
    {
        "id": "441414",
        "name": "M-DCPS The English Center",
        "hyphenated": "m-dcps-the-english-center"
    },
    {
        "id": "441423",
        "name": "Florida Education Institute",
        "hyphenated": "florida-education-institute"
    },
    {
        "id": "441487",
        "name": "Texas County Technical College",
        "hyphenated": "texas-county-technical-college"
    },
    {
        "id": "441496",
        "name": "Aviation Institute of Maintenance-Kansas City",
        "hyphenated": "aviation-institute-of-maintenance-kansas-city"
    },
    {
        "id": "441502",
        "name": "Blue Cliff College-Gulfport",
        "hyphenated": "blue-cliff-college-gulfport"
    },
    {
        "id": "441548",
        "name": "Institute for Therapeutic Massage",
        "hyphenated": "institute-for-therapeutic-massage"
    },
    {
        "id": "441575",
        "name": "Metropolitan Learning Institute",
        "hyphenated": "metropolitan-learning-institute"
    },
    {
        "id": "441593",
        "name": "Orleans Niagara BOCES-Practical Nursing Program",
        "hyphenated": "orleans-niagara-boces-practical-nursing-program"
    },
    {
        "id": "441609",
        "name": "Yeshiva Shaarei Torah of Rockland",
        "hyphenated": "yeshiva-shaarei-torah-of-rockland"
    },
    {
        "id": "441618",
        "name": "Leon Studio One School of Beauty Knowledge",
        "hyphenated": "leon-studio-one-school-of-beauty-knowledge"
    },
    {
        "id": "441636",
        "name": "American Institute of Alternative Medicine",
        "hyphenated": "american-institute-of-alternative-medicine"
    },
    {
        "id": "441645",
        "name": "Brown Aveda Institute-Mentor",
        "hyphenated": "brown-aveda-institute-mentor"
    },
    {
        "id": "441663",
        "name": "Institute of Technology",
        "hyphenated": "institute-of-technology"
    },
    {
        "id": "441672",
        "name": "Susquehanna County Career and Technology Center",
        "hyphenated": "susquehanna-county-career-and-technology-center"
    },
    {
        "id": "441690",
        "name": "Universidad Pentecostal Mizpa",
        "hyphenated": "universidad-pentecostal-mizpa"
    },
    {
        "id": "441706",
        "name": "Caribbean Forensic and Technical College",
        "hyphenated": "caribbean-forensic-and-technical-college"
    },
    {
        "id": "441742",
        "name": "Concorde Career College-Grand Prairie",
        "hyphenated": "concorde-career-college-grand-prairie"
    },
    {
        "id": "441760",
        "name": "Lamar Institute of Technology",
        "hyphenated": "lamar-institute-of-technology"
    },
    {
        "id": "441788",
        "name": "Aviation Institute of Maintenance-Dallas",
        "hyphenated": "aviation-institute-of-maintenance-dallas"
    },
    {
        "id": "441830",
        "name": "Healing Mountain Massage School",
        "hyphenated": "healing-mountain-massage-school"
    },
    {
        "id": "441858",
        "name": "Eastern Virginia Career College",
        "hyphenated": "eastern-virginia-career-college"
    },
    {
        "id": "441876",
        "name": "Rudy & Kelly Academy A Paul Mitchell Partner School",
        "hyphenated": "rudy-&-kelly-academy-a-paul-mitchell-partner-school"
    },
    {
        "id": "441900",
        "name": "Nevada State College",
        "hyphenated": "nevada-state-college"
    },
    {
        "id": "441937",
        "name": "California State University-Channel Islands",
        "hyphenated": "california-state-university-channel-islands"
    },
    {
        "id": "441982",
        "name": "Franklin W Olin College of Engineering",
        "hyphenated": "franklin-w-olin-college-of-engineering"
    },
    {
        "id": "442064",
        "name": "Won Institute of Graduate Studies",
        "hyphenated": "won-institute-of-graduate-studies"
    },
    {
        "id": "442073",
        "name": "Lincoln Technical Institute-Paramus",
        "hyphenated": "lincoln-technical-institute-paramus"
    },
    {
        "id": "442134",
        "name": "Pima Medical Institute-Colorado Springs",
        "hyphenated": "pima-medical-institute-colorado-springs"
    },
    {
        "id": "442213",
        "name": "Birthwise Midwifery School",
        "hyphenated": "birthwise-midwifery-school"
    },
    {
        "id": "442240",
        "name": "Paul Mitchell the School-Costa Mesa",
        "hyphenated": "paul-mitchell-the-school-costa-mesa"
    },
    {
        "id": "442295",
        "name": "Ave Maria School of Law",
        "hyphenated": "ave-maria-school-of-law"
    },
    {
        "id": "442329",
        "name": "Tulsa Welding School-Jacksonville",
        "hyphenated": "tulsa-welding-school-jacksonville"
    },
    {
        "id": "442356",
        "name": "Pennsylvania College of Health Sciences",
        "hyphenated": "pennsylvania-college-of-health-sciences"
    },
    {
        "id": "442383",
        "name": "West Virginia Junior College-Bridgeport",
        "hyphenated": "west-virginia-junior-college-bridgeport"
    },
    {
        "id": "442426",
        "name": "St Louis College of Health Careers-Fenton",
        "hyphenated": "st-louis-college-of-health-careers-fenton"
    },
    {
        "id": "442435",
        "name": "Interactive College of Technology-Gainesville",
        "hyphenated": "interactive-college-of-technology-gainesville"
    },
    {
        "id": "442444",
        "name": "San Joaquin Valley College-Ontario",
        "hyphenated": "san-joaquin-valley-college-ontario"
    },
    {
        "id": "442523",
        "name": "Alaska Christian College",
        "hyphenated": "alaska-christian-college"
    },
    {
        "id": "442569",
        "name": "University of Arkansas Grantham",
        "hyphenated": "university-of-arkansas-grantham"
    },
    {
        "id": "442602",
        "name": "Carrington College-Albuquerque",
        "hyphenated": "carrington-college-albuquerque"
    },
    {
        "id": "442611",
        "name": "NASCAR Technical Institute",
        "hyphenated": "nascar-technical-institute"
    },
    {
        "id": "442639",
        "name": "University of the Potomac-VA Campus",
        "hyphenated": "university-of-the-potomac-va-campus"
    },
    {
        "id": "442657",
        "name": "Milan Institute-Palm Desert",
        "hyphenated": "milan-institute-palm-desert"
    },
    {
        "id": "442781",
        "name": "Tohono O'odham Community College",
        "hyphenated": "tohono-o'odham-community-college"
    },
    {
        "id": "442806",
        "name": "Edward Via College of Osteopathic Medicine",
        "hyphenated": "edward-via-college-of-osteopathic-medicine"
    },
    {
        "id": "442842",
        "name": "Pacific College of Health and Science",
        "hyphenated": "pacific-college-of-health-and-science"
    },
    {
        "id": "442879",
        "name": "Southwest Institute of Healing Arts",
        "hyphenated": "southwest-institute-of-healing-arts"
    },
    {
        "id": "442888",
        "name": "InterCoast Colleges-Riverside",
        "hyphenated": "intercoast-colleges-riverside"
    },
    {
        "id": "442930",
        "name": "University of Antelope Valley",
        "hyphenated": "university-of-antelope-valley"
    },
    {
        "id": "442949",
        "name": "Birthingway College of Midwifery",
        "hyphenated": "birthingway-college-of-midwifery"
    },
    {
        "id": "442976",
        "name": "Brighton Center's Center for Employment Training",
        "hyphenated": "brighton-center's-center-for-employment-training"
    },
    {
        "id": "442985",
        "name": "California Healing Arts College",
        "hyphenated": "california-healing-arts-college"
    },
    {
        "id": "443021",
        "name": "Dallas Barber & Stylist College",
        "hyphenated": "dallas-barber-&-stylist-college"
    },
    {
        "id": "443030",
        "name": "NorthShore University HealthSystem School of Nurse Anesthesia",
        "hyphenated": "northshore-university-healthsystem-school-of-nurse-anesthesia"
    },
    {
        "id": "443049",
        "name": "Faith International University",
        "hyphenated": "faith-international-university"
    },
    {
        "id": "443058",
        "name": "Family of Faith Christian University",
        "hyphenated": "family-of-faith-christian-university"
    },
    {
        "id": "443076",
        "name": "Hood Theological Seminary",
        "hyphenated": "hood-theological-seminary"
    },
    {
        "id": "443100",
        "name": "Colegio Educativo Tecnologico Industrial Inc",
        "hyphenated": "colegio-educativo-tecnologico-industrial-inc"
    },
    {
        "id": "443128",
        "name": "International Technological University",
        "hyphenated": "international-technological-university"
    },
    {
        "id": "443137",
        "name": "Irene's Myomassology Institute",
        "hyphenated": "irene's-myomassology-institute"
    },
    {
        "id": "443146",
        "name": "Jay's Technical Institute",
        "hyphenated": "jay's-technical-institute"
    },
    {
        "id": "443207",
        "name": "NTMA Training Centers of Southern California",
        "hyphenated": "ntma-training-centers-of-southern-california"
    },
    {
        "id": "443216",
        "name": "P C Age-Jersey City",
        "hyphenated": "p-c-age-jersey-city"
    },
    {
        "id": "443225",
        "name": "PC AGE-Metropark",
        "hyphenated": "pc-age-metropark"
    },
    {
        "id": "443234",
        "name": "Hollywood Institute",
        "hyphenated": "hollywood-institute"
    },
    {
        "id": "443252",
        "name": "South Florida Institute of Technology",
        "hyphenated": "south-florida-institute-of-technology"
    },
    {
        "id": "443261",
        "name": "Southeastern College-Charleston",
        "hyphenated": "southeastern-college-charleston"
    },
    {
        "id": "443289",
        "name": "Tigi Hairdressing Academy-Colorado Springs",
        "hyphenated": "tigi-hairdressing-academy-colorado-springs"
    },
    {
        "id": "443298",
        "name": "Trend Barber College",
        "hyphenated": "trend-barber-college"
    },
    {
        "id": "443322",
        "name": "Valley Grande Institute for Academic Studies",
        "hyphenated": "valley-grande-institute-for-academic-studies"
    },
    {
        "id": "443331",
        "name": "West Coast University-Los Angeles",
        "hyphenated": "west-coast-university-los-angeles"
    },
    {
        "id": "443340",
        "name": "Williamson Christian College",
        "hyphenated": "williamson-christian-college"
    },
    {
        "id": "443377",
        "name": "Triangle Tech Inc-Sunbury",
        "hyphenated": "triangle-tech-inc-sunbury"
    },
    {
        "id": "443410",
        "name": "DigiPen Institute of Technology",
        "hyphenated": "digipen-institute-of-technology"
    },
    {
        "id": "443492",
        "name": "Pierpont Community and Technical College",
        "hyphenated": "pierpont-community-and-technical-college"
    },
    {
        "id": "443562",
        "name": "Dewey University-Hato Rey",
        "hyphenated": "dewey-university-hato-rey"
    },
    {
        "id": "443571",
        "name": "InterCoast Colleges-West Covina",
        "hyphenated": "intercoast-colleges-west-covina"
    },
    {
        "id": "443599",
        "name": "American College of Healthcare Sciences",
        "hyphenated": "american-college-of-healthcare-sciences"
    },
    {
        "id": "443632",
        "name": "Colorado Media School",
        "hyphenated": "colorado-media-school"
    },
    {
        "id": "443641",
        "name": "Paul Mitchell the School-Rhode Island",
        "hyphenated": "paul-mitchell-the-school-rhode-island"
    },
    {
        "id": "443650",
        "name": "Miller-Motte College-Chattanooga",
        "hyphenated": "miller-motte-college-chattanooga"
    },
    {
        "id": "443669",
        "name": "Milan Institute-Sparks",
        "hyphenated": "milan-institute-sparks"
    },
    {
        "id": "443696",
        "name": "Interactive College of Technology",
        "hyphenated": "interactive-college-of-technology"
    },
    {
        "id": "443702",
        "name": "Bexley Hall Seabury Western Theological Seminary Federation Inc.",
        "hyphenated": "bexley-hall-seabury-western-theological-seminary-federation-inc."
    },
    {
        "id": "443748",
        "name": "Altierus Career College-Norcross",
        "hyphenated": "altierus-career-college-norcross"
    },
    {
        "id": "443766",
        "name": "Strayer University-Tennessee",
        "hyphenated": "strayer-university-tennessee"
    },
    {
        "id": "443784",
        "name": "Strayer University-Pennsylvania",
        "hyphenated": "strayer-university-pennsylvania"
    },
    {
        "id": "443951",
        "name": "Charlie's Guard-Detective Bureau and Academy Inc",
        "hyphenated": "charlie's-guard-detective-bureau-and-academy-inc"
    },
    {
        "id": "443979",
        "name": "PCI Academy-Plymouth",
        "hyphenated": "pci-academy-plymouth"
    },
    {
        "id": "444051",
        "name": "Creative Images Institute of Cosmetology-South Dayton",
        "hyphenated": "creative-images-institute-of-cosmetology-south-dayton"
    },
    {
        "id": "444088",
        "name": "Brookline College-Albuquerque",
        "hyphenated": "brookline-college-albuquerque"
    },
    {
        "id": "444103",
        "name": "Byzantine Catholic Seminary of Saints Cyril and Methodius",
        "hyphenated": "byzantine-catholic-seminary-of-saints-cyril-and-methodius"
    },
    {
        "id": "444130",
        "name": "Northcentral University",
        "hyphenated": "northcentral-university"
    },
    {
        "id": "444194",
        "name": "Bayamon Community College",
        "hyphenated": "bayamon-community-college"
    },
    {
        "id": "444219",
        "name": "Folsom Lake College",
        "hyphenated": "folsom-lake-college"
    },
    {
        "id": "444228",
        "name": "LeGrand Institute of Cosmetology Inc",
        "hyphenated": "legrand-institute-of-cosmetology-inc"
    },
    {
        "id": "444255",
        "name": "Hussian College-Daymar College Murfreesboro",
        "hyphenated": "hussian-college-daymar-college-murfreesboro"
    },
    {
        "id": "444264",
        "name": "Cambridge Technical Institute",
        "hyphenated": "cambridge-technical-institute"
    },
    {
        "id": "444282",
        "name": "Empire Beauty School-Midlothian",
        "hyphenated": "empire-beauty-school-midlothian"
    },
    {
        "id": "444291",
        "name": "Empire Beauty School-Owings Mills",
        "hyphenated": "empire-beauty-school-owings-mills"
    },
    {
        "id": "444307",
        "name": "Empire Beauty School-West Mifflin",
        "hyphenated": "empire-beauty-school-west-mifflin"
    },
    {
        "id": "444325",
        "name": "ATI College-Whittier",
        "hyphenated": "ati-college-whittier"
    },
    {
        "id": "444334",
        "name": "Advance Science International College",
        "hyphenated": "advance-science-international-college"
    },
    {
        "id": "444361",
        "name": "ATA College",
        "hyphenated": "ata-college"
    },
    {
        "id": "444370",
        "name": "American Advanced Technicians Institute",
        "hyphenated": "american-advanced-technicians-institute"
    },
    {
        "id": "444398",
        "name": "Baptist University of the Americas",
        "hyphenated": "baptist-university-of-the-americas"
    },
    {
        "id": "444404",
        "name": "The Beauty Institute",
        "hyphenated": "the-beauty-institute"
    },
    {
        "id": "444413",
        "name": "Beis Medrash Heichal Dovid",
        "hyphenated": "beis-medrash-heichal-dovid"
    },
    {
        "id": "444440",
        "name": "Blue Cliff Career College",
        "hyphenated": "blue-cliff-career-college"
    },
    {
        "id": "444468",
        "name": "Career Beauty College",
        "hyphenated": "career-beauty-college"
    },
    {
        "id": "444486",
        "name": "Paul Mitchell the School-Gastonia",
        "hyphenated": "paul-mitchell-the-school-gastonia"
    },
    {
        "id": "444510",
        "name": "CES College",
        "hyphenated": "ces-college"
    },
    {
        "id": "444529",
        "name": "Stellar Career College",
        "hyphenated": "stellar-career-college"
    },
    {
        "id": "444556",
        "name": "Auguste Escoffier School of Culinary Arts-Austin",
        "hyphenated": "auguste-escoffier-school-of-culinary-arts-austin"
    },
    {
        "id": "444565",
        "name": "Culinary Institute Inc",
        "hyphenated": "culinary-institute-inc"
    },
    {
        "id": "444583",
        "name": "Escuela Hotelera de San Juan",
        "hyphenated": "escuela-hotelera-de-san-juan"
    },
    {
        "id": "444635",
        "name": "Orion Institute",
        "hyphenated": "orion-institute"
    },
    {
        "id": "444662",
        "name": "Moore Career College",
        "hyphenated": "moore-career-college"
    },
    {
        "id": "444671",
        "name": "Industrial Technical College",
        "hyphenated": "industrial-technical-college"
    },
    {
        "id": "444699",
        "name": "Institute of Clinical Acupuncture & Oriental Med",
        "hyphenated": "institute-of-clinical-acupuncture-&-oriental-med"
    },
    {
        "id": "444714",
        "name": "Gwinnett College-Marietta Campus",
        "hyphenated": "gwinnett-college-marietta-campus"
    },
    {
        "id": "444732",
        "name": "Leston College",
        "hyphenated": "leston-college"
    },
    {
        "id": "444778",
        "name": "Charlotte Christian College and Theological Seminary",
        "hyphenated": "charlotte-christian-college-and-theological-seminary"
    },
    {
        "id": "444787",
        "name": "Nightingale College",
        "hyphenated": "nightingale-college"
    },
    {
        "id": "444796",
        "name": "Omnitech Institute",
        "hyphenated": "omnitech-institute"
    },
    {
        "id": "444811",
        "name": "PITC Institute",
        "hyphenated": "pitc-institute"
    },
    {
        "id": "444857",
        "name": "Skinworks School of Advanced Skincare",
        "hyphenated": "skinworks-school-of-advanced-skincare"
    },
    {
        "id": "444866",
        "name": "Southeastern College-Columbia",
        "hyphenated": "southeastern-college-columbia"
    },
    {
        "id": "444893",
        "name": "Technical Learning Centers Inc",
        "hyphenated": "technical-learning-centers-inc"
    },
    {
        "id": "444936",
        "name": "Turning Point Beauty College",
        "hyphenated": "turning-point-beauty-college"
    },
    {
        "id": "444945",
        "name": "Unitech Training Academy-Lafayette",
        "hyphenated": "unitech-training-academy-lafayette"
    },
    {
        "id": "444954",
        "name": "Mountwest Community and Technical College",
        "hyphenated": "mountwest-community-and-technical-college"
    },
    {
        "id": "445027",
        "name": "American InterContinental University",
        "hyphenated": "american-intercontinental-university"
    },
    {
        "id": "445054",
        "name": "Toyota Technological Institute at Chicago",
        "hyphenated": "toyota-technological-institute-at-chicago"
    },
    {
        "id": "445133",
        "name": "American InterContinental University-Houston",
        "hyphenated": "american-intercontinental-university-houston"
    },
    {
        "id": "445203",
        "name": "Remington College-North Houston Campus",
        "hyphenated": "remington-college-north-houston-campus"
    },
    {
        "id": "445212",
        "name": "Paul Mitchell the School-Orlando",
        "hyphenated": "paul-mitchell-the-school-orlando"
    },
    {
        "id": "445230",
        "name": "Pima Medical Institute-Las Vegas",
        "hyphenated": "pima-medical-institute-las-vegas"
    },
    {
        "id": "445249",
        "name": "Remington College-Nashville Campus",
        "hyphenated": "remington-college-nashville-campus"
    },
    {
        "id": "445267",
        "name": "Central Methodist University-College of Graduate and Extended Studies",
        "hyphenated": "central-methodist-university-college-of-graduate-and-extended-studies"
    },
    {
        "id": "445364",
        "name": "North-West College-Riverside",
        "hyphenated": "north-west-college-riverside"
    },
    {
        "id": "445461",
        "name": "Altierus Career College-Bissonnet",
        "hyphenated": "altierus-career-college-bissonnet"
    },
    {
        "id": "445498",
        "name": "Colegio Tecnico de Electricidad Galloza",
        "hyphenated": "colegio-tecnico-de-electricidad-galloza"
    },
    {
        "id": "445540",
        "name": "Arthur's Beauty College",
        "hyphenated": "arthur's-beauty-college"
    },
    {
        "id": "445647",
        "name": "Spa Tech Institute-Westboro",
        "hyphenated": "spa-tech-institute-westboro"
    },
    {
        "id": "445656",
        "name": "Spa Tech Institute-Plymouth",
        "hyphenated": "spa-tech-institute-plymouth"
    },
    {
        "id": "445692",
        "name": "Neumont College of Computer Science",
        "hyphenated": "neumont-college-of-computer-science"
    },
    {
        "id": "445708",
        "name": "Johnson & Wales University-Charlotte",
        "hyphenated": "johnson-&-wales-university-charlotte"
    },
    {
        "id": "445735",
        "name": "Roseman University of Health Sciences",
        "hyphenated": "roseman-university-of-health-sciences"
    },
    {
        "id": "445744",
        "name": "Fortis Institute-Port Saint Lucie",
        "hyphenated": "fortis-institute-port-saint-lucie"
    },
    {
        "id": "445762",
        "name": "Aviation Institute of Maintenance-Manassas",
        "hyphenated": "aviation-institute-of-maintenance-manassas"
    },
    {
        "id": "445780",
        "name": "Cosmetology School of Arts & Sciences",
        "hyphenated": "cosmetology-school-of-arts-&-sciences"
    },
    {
        "id": "445799",
        "name": "Universal Technical Institute of Pennsylvania Inc",
        "hyphenated": "universal-technical-institute-of-pennsylvania-inc"
    },
    {
        "id": "445841",
        "name": "Kenneth Shuler School of Cosmetology-Spartanburg",
        "hyphenated": "kenneth-shuler-school-of-cosmetology-spartanburg"
    },
    {
        "id": "445850",
        "name": "Kenneth Shuler School of Cosmetology-Columbia",
        "hyphenated": "kenneth-shuler-school-of-cosmetology-columbia"
    },
    {
        "id": "445869",
        "name": "Divine Mercy University",
        "hyphenated": "divine-mercy-university"
    },
    {
        "id": "445911",
        "name": "Lincoln Technical Institute-Shelton",
        "hyphenated": "lincoln-technical-institute-shelton"
    },
    {
        "id": "445948",
        "name": "Northwest Career College",
        "hyphenated": "northwest-career-college"
    },
    {
        "id": "445957",
        "name": "Soma Institute-The National School of Clinical Massage Therapy",
        "hyphenated": "soma-institute-the-national-school-of-clinical-massage-therapy"
    },
    {
        "id": "446002",
        "name": "American Academy of Health and Wellness",
        "hyphenated": "american-academy-of-health-and-wellness"
    },
    {
        "id": "446039",
        "name": "Arizona School of Acupuncture and Oriental Medicine",
        "hyphenated": "arizona-school-of-acupuncture-and-oriental-medicine"
    },
    {
        "id": "446048",
        "name": "Ave Maria University",
        "hyphenated": "ave-maria-university"
    },
    {
        "id": "446084",
        "name": "CALC Institute of Technology",
        "hyphenated": "calc-institute-of-technology"
    },
    {
        "id": "446093",
        "name": "Sutter County Career Training Center",
        "hyphenated": "sutter-county-career-training-center"
    },
    {
        "id": "446109",
        "name": "Cambridge College of Healthcare & Technology",
        "hyphenated": "cambridge-college-of-healthcare-&-technology"
    },
    {
        "id": "446118",
        "name": "Career Care Institute",
        "hyphenated": "career-care-institute"
    },
    {
        "id": "446127",
        "name": "ATA Career Education",
        "hyphenated": "ata-career-education"
    },
    {
        "id": "446163",
        "name": "Community Christian College",
        "hyphenated": "community-christian-college"
    },
    {
        "id": "446206",
        "name": "Culpeper Cosmetology Training Center",
        "hyphenated": "culpeper-cosmetology-training-center"
    },
    {
        "id": "446224",
        "name": "Eastern School of Acupuncture and Traditional Medicine",
        "hyphenated": "eastern-school-of-acupuncture-and-traditional-medicine"
    },
    {
        "id": "446233",
        "name": "Ecclesia College",
        "hyphenated": "ecclesia-college"
    },
    {
        "id": "446242",
        "name": "Employment Solutions-College for Technical Education",
        "hyphenated": "employment-solutions-college-for-technical-education"
    },
    {
        "id": "446251",
        "name": "Estelle Medical Academy",
        "hyphenated": "estelle-medical-academy"
    },
    {
        "id": "446260",
        "name": "Evergreen Beauty and Barber College-Everett",
        "hyphenated": "evergreen-beauty-and-barber-college-everett"
    },
    {
        "id": "446288",
        "name": "Paul Mitchell the School-Great Lakes",
        "hyphenated": "paul-mitchell-the-school-great-lakes"
    },
    {
        "id": "446303",
        "name": "Healthcare Training Institute",
        "hyphenated": "healthcare-training-institute"
    },
    {
        "id": "446349",
        "name": "John D Rockefeller IV Career Center",
        "hyphenated": "john-d-rockefeller-iv-career-center"
    },
    {
        "id": "446385",
        "name": "Los Angeles College of Music",
        "hyphenated": "los-angeles-college-of-music"
    },
    {
        "id": "446394",
        "name": "Maple Springs Baptist Bible College and Seminary",
        "hyphenated": "maple-springs-baptist-bible-college-and-seminary"
    },
    {
        "id": "446455",
        "name": "Precision Manufacturing Institute",
        "hyphenated": "precision-manufacturing-institute"
    },
    {
        "id": "446516",
        "name": "Rosslyn Training Academy of Cosmetology",
        "hyphenated": "rosslyn-training-academy-of-cosmetology"
    },
    {
        "id": "446525",
        "name": "SAE Institute of Technology-Nashville",
        "hyphenated": "sae-institute-of-technology-nashville"
    },
    {
        "id": "446552",
        "name": "Southern Technical College",
        "hyphenated": "southern-technical-college"
    },
    {
        "id": "446561",
        "name": "Stanbridge University",
        "hyphenated": "stanbridge-university"
    },
    {
        "id": "446589",
        "name": "Universal Career School",
        "hyphenated": "universal-career-school"
    },
    {
        "id": "446598",
        "name": "Universal College of Healing Arts",
        "hyphenated": "universal-college-of-healing-arts"
    },
    {
        "id": "446604",
        "name": "Uta Mesivta of Kiryas Joel",
        "hyphenated": "uta-mesivta-of-kiryas-joel"
    },
    {
        "id": "446640",
        "name": "Harrisburg University of Science and Technology",
        "hyphenated": "harrisburg-university-of-science-and-technology"
    },
    {
        "id": "446774",
        "name": "Blue Ridge Community and Technical College",
        "hyphenated": "blue-ridge-community-and-technical-college"
    },
    {
        "id": "447014",
        "name": "Daytona College",
        "hyphenated": "daytona-college"
    },
    {
        "id": "447023",
        "name": "Delta Technical College-Mississippi",
        "hyphenated": "delta-technical-college-mississippi"
    },
    {
        "id": "447050",
        "name": "United States University",
        "hyphenated": "united-states-university"
    },
    {
        "id": "447148",
        "name": "Empire Beauty School-Avondale",
        "hyphenated": "empire-beauty-school-avondale"
    },
    {
        "id": "447166",
        "name": "Empire Beauty School-Littleton",
        "hyphenated": "empire-beauty-school-littleton"
    },
    {
        "id": "447175",
        "name": "Empire Beauty School-Aurora",
        "hyphenated": "empire-beauty-school-aurora"
    },
    {
        "id": "447263",
        "name": "Joyce University of Nursing and Health Sciences",
        "hyphenated": "joyce-university-of-nursing-and-health-sciences"
    },
    {
        "id": "447351",
        "name": "San Joaquin Valley College-Modesto",
        "hyphenated": "san-joaquin-valley-college-modesto"
    },
    {
        "id": "447421",
        "name": "Eagle Gate College-Layton",
        "hyphenated": "eagle-gate-college-layton"
    },
    {
        "id": "447449",
        "name": "Academy of Hair Design-Jasper",
        "hyphenated": "academy-of-hair-design-jasper"
    },
    {
        "id": "447458",
        "name": "SAE Expression College",
        "hyphenated": "sae-expression-college"
    },
    {
        "id": "447467",
        "name": "Unitech Training Academy-West Monroe",
        "hyphenated": "unitech-training-academy-west-monroe"
    },
    {
        "id": "447582",
        "name": "New River Community and Technical College",
        "hyphenated": "new-river-community-and-technical-college"
    },
    {
        "id": "447591",
        "name": "The College of Health Care Professions-Southwest Houston",
        "hyphenated": "the-college-of-health-care-professions-southwest-houston"
    },
    {
        "id": "447607",
        "name": "UEI College-Chula Vista",
        "hyphenated": "uei-college-chula-vista"
    },
    {
        "id": "447616",
        "name": "UEI College-West Covina",
        "hyphenated": "uei-college-west-covina"
    },
    {
        "id": "447689",
        "name": "Georgia Gwinnett College",
        "hyphenated": "georgia-gwinnett-college"
    },
    {
        "id": "447698",
        "name": "Phoenix Institute of Herbal Medicine & Acupuncture",
        "hyphenated": "phoenix-institute-of-herbal-medicine-&-acupuncture"
    },
    {
        "id": "447704",
        "name": "Tucson College of Beauty",
        "hyphenated": "tucson-college-of-beauty"
    },
    {
        "id": "447713",
        "name": "California Career College",
        "hyphenated": "california-career-college"
    },
    {
        "id": "447722",
        "name": "Palladium Technical Academy Inc",
        "hyphenated": "palladium-technical-academy-inc"
    },
    {
        "id": "447731",
        "name": "Los Angeles College of Aesthetics",
        "hyphenated": "los-angeles-college-of-aesthetics"
    },
    {
        "id": "447759",
        "name": "National Polytechnic College",
        "hyphenated": "national-polytechnic-college"
    },
    {
        "id": "447768",
        "name": "American Career College-Ontario",
        "hyphenated": "american-career-college-ontario"
    },
    {
        "id": "447777",
        "name": "Asher College",
        "hyphenated": "asher-college"
    },
    {
        "id": "447795",
        "name": "American Institute of Massage Therapy",
        "hyphenated": "american-institute-of-massage-therapy"
    },
    {
        "id": "447801",
        "name": "University of East-West Medicine",
        "hyphenated": "university-of-east-west-medicine"
    },
    {
        "id": "447810",
        "name": "Blake Austin College",
        "hyphenated": "blake-austin-college"
    },
    {
        "id": "447847",
        "name": "Aviator College of Aeronautical Science and Technology",
        "hyphenated": "aviator-college-of-aeronautical-science-and-technology"
    },
    {
        "id": "447865",
        "name": "Trendsetters School of Beauty & Barbering",
        "hyphenated": "trendsetters-school-of-beauty-&-barbering"
    },
    {
        "id": "447874",
        "name": "CDA Technical Institute",
        "hyphenated": "cda-technical-institute"
    },
    {
        "id": "447883",
        "name": "American Institute of Beauty",
        "hyphenated": "american-institute-of-beauty"
    },
    {
        "id": "447892",
        "name": "North Florida Cosmetology Institute",
        "hyphenated": "north-florida-cosmetology-institute"
    },
    {
        "id": "447908",
        "name": "Florida Academy of Health & Beauty",
        "hyphenated": "florida-academy-of-health-&-beauty"
    },
    {
        "id": "447917",
        "name": "Augusta School of Massage",
        "hyphenated": "augusta-school-of-massage"
    },
    {
        "id": "447935",
        "name": "ATA College",
        "hyphenated": "ata-college"
    },
    {
        "id": "447953",
        "name": "SUM Bible College and Theological Seminary",
        "hyphenated": "sum-bible-college-and-theological-seminary"
    },
    {
        "id": "447962",
        "name": "Compass Career College",
        "hyphenated": "compass-career-college"
    },
    {
        "id": "447971",
        "name": "Hair Expressions Academy",
        "hyphenated": "hair-expressions-academy"
    },
    {
        "id": "447980",
        "name": "Nuvo College of Cosmetology",
        "hyphenated": "nuvo-college-of-cosmetology"
    },
    {
        "id": "447999",
        "name": "WellSpring School of Allied Health-Kansas City",
        "hyphenated": "wellspring-school-of-allied-health-kansas-city"
    },
    {
        "id": "448026",
        "name": "The Salon Professional Academy-Fargo",
        "hyphenated": "the-salon-professional-academy-fargo"
    },
    {
        "id": "448044",
        "name": "Institute of Professional Careers",
        "hyphenated": "institute-of-professional-careers"
    },
    {
        "id": "448071",
        "name": "Aveda Arts & Sciences Institute-New York",
        "hyphenated": "aveda-arts-&-sciences-institute-new-york"
    },
    {
        "id": "448123",
        "name": "MyrAngel Beauty Institute",
        "hyphenated": "myrangel-beauty-institute"
    },
    {
        "id": "448196",
        "name": "Texas Health School",
        "hyphenated": "texas-health-school"
    },
    {
        "id": "448220",
        "name": "Paul Mitchell the School-Logan",
        "hyphenated": "paul-mitchell-the-school-logan"
    },
    {
        "id": "448239",
        "name": "Careers Unlimited",
        "hyphenated": "careers-unlimited"
    },
    {
        "id": "448248",
        "name": "Mountainland Technical College",
        "hyphenated": "mountainland-technical-college"
    },
    {
        "id": "448257",
        "name": "Avi Career Training",
        "hyphenated": "avi-career-training"
    },
    {
        "id": "448275",
        "name": "Mountain State School of Massage",
        "hyphenated": "mountain-state-school-of-massage"
    },
    {
        "id": "448354",
        "name": "Eastern International College-Belleville",
        "hyphenated": "eastern-international-college-belleville"
    },
    {
        "id": "448372",
        "name": "San Joaquin Valley College-Rancho Cordova",
        "hyphenated": "san-joaquin-valley-college-rancho-cordova"
    },
    {
        "id": "448424",
        "name": "Broken Arrow Beauty College",
        "hyphenated": "broken-arrow-beauty-college"
    },
    {
        "id": "448433",
        "name": "Universal Technical Institute of Northern California Inc",
        "hyphenated": "universal-technical-institute-of-northern-california-inc"
    },
    {
        "id": "448594",
        "name": "West Hills College-Lemoore",
        "hyphenated": "west-hills-college-lemoore"
    },
    {
        "id": "448600",
        "name": "Stautzenberger College-Brecksville",
        "hyphenated": "stautzenberger-college-brecksville"
    },
    {
        "id": "448619",
        "name": "Stone Academy-East Hartford",
        "hyphenated": "stone-academy-east-hartford"
    },
    {
        "id": "448646",
        "name": "Unitech Training Academy-Houma",
        "hyphenated": "unitech-training-academy-houma"
    },
    {
        "id": "448673",
        "name": "Rasmussen University-Illinois",
        "hyphenated": "rasmussen-university-illinois"
    },
    {
        "id": "448691",
        "name": "International Institute for Restorative Practices",
        "hyphenated": "international-institute-for-restorative-practices"
    },
    {
        "id": "448770",
        "name": "Automotive Training Center-Warminster",
        "hyphenated": "automotive-training-center-warminster"
    },
    {
        "id": "448798",
        "name": "Empire Beauty School-Milwaukee",
        "hyphenated": "empire-beauty-school-milwaukee"
    },
    {
        "id": "448859",
        "name": "Triangle Tech Inc-Bethlehem",
        "hyphenated": "triangle-tech-inc-bethlehem"
    },
    {
        "id": "448895",
        "name": "Milan Institute-Clovis",
        "hyphenated": "milan-institute-clovis"
    },
    {
        "id": "449038",
        "name": "Strayer University-Florida",
        "hyphenated": "strayer-university-florida"
    },
    {
        "id": "449074",
        "name": "Pima Medical Institute-Renton",
        "hyphenated": "pima-medical-institute-renton"
    },
    {
        "id": "449083",
        "name": "CBT Technology Institute-Main Campus",
        "hyphenated": "cbt-technology-institute-main-campus"
    },
    {
        "id": "449092",
        "name": "CBT Technology Institute-Hialeah",
        "hyphenated": "cbt-technology-institute-hialeah"
    },
    {
        "id": "449135",
        "name": "Dewey University-Juana Diaz",
        "hyphenated": "dewey-university-juana-diaz"
    },
    {
        "id": "449250",
        "name": "The College of Health Care Professions-San Antonio",
        "hyphenated": "the-college-of-health-care-professions-san-antonio"
    },
    {
        "id": "449311",
        "name": "Brittany Beauty Academy",
        "hyphenated": "brittany-beauty-academy"
    },
    {
        "id": "449320",
        "name": "Lincoln Technical Institute-Whitestone",
        "hyphenated": "lincoln-technical-institute-whitestone"
    },
    {
        "id": "449339",
        "name": "American Public University System",
        "hyphenated": "american-public-university-system"
    },
    {
        "id": "449348",
        "name": "Huntsville Bible College",
        "hyphenated": "huntsville-bible-college"
    },
    {
        "id": "449357",
        "name": "Beaumont Adult School",
        "hyphenated": "beaumont-adult-school"
    },
    {
        "id": "449366",
        "name": "Make-up Designory",
        "hyphenated": "make-up-designory"
    },
    {
        "id": "449384",
        "name": "Gnomon",
        "hyphenated": "gnomon"
    },
    {
        "id": "449393",
        "name": "Coachella Valley Beauty College",
        "hyphenated": "coachella-valley-beauty-college"
    },
    {
        "id": "449445",
        "name": "Valley College of Medical Careers",
        "hyphenated": "valley-college-of-medical-careers"
    },
    {
        "id": "449454",
        "name": "Academy of Natural Therapy Inc",
        "hyphenated": "academy-of-natural-therapy-inc"
    },
    {
        "id": "449463",
        "name": "Academy Di Capelli-School of Cosmetology",
        "hyphenated": "academy-di-capelli-school-of-cosmetology"
    },
    {
        "id": "449472",
        "name": "Delaware Learning Institute of Cosmetology",
        "hyphenated": "delaware-learning-institute-of-cosmetology"
    },
    {
        "id": "449481",
        "name": "Dragon Rises College of Oriental Medicine",
        "hyphenated": "dragon-rises-college-of-oriental-medicine"
    },
    {
        "id": "449490",
        "name": "Palm Beach Academy of Health & Beauty",
        "hyphenated": "palm-beach-academy-of-health-&-beauty"
    },
    {
        "id": "449506",
        "name": "SABER College",
        "hyphenated": "saber-college"
    },
    {
        "id": "449524",
        "name": "Taylor College",
        "hyphenated": "taylor-college"
    },
    {
        "id": "449533",
        "name": "Pensacola School of Massage Therapy & Health Careers",
        "hyphenated": "pensacola-school-of-massage-therapy-&-health-careers"
    },
    {
        "id": "449597",
        "name": "Ideal Beauty Academy",
        "hyphenated": "ideal-beauty-academy"
    },
    {
        "id": "449603",
        "name": "Denmark College",
        "hyphenated": "denmark-college"
    },
    {
        "id": "449612",
        "name": "Louisiana Culinary Institute",
        "hyphenated": "louisiana-culinary-institute"
    },
    {
        "id": "449658",
        "name": "Bais Medrash Toras Chesed",
        "hyphenated": "bais-medrash-toras-chesed"
    },
    {
        "id": "449676",
        "name": "Ace Institute of Technology",
        "hyphenated": "ace-institute-of-technology"
    },
    {
        "id": "449685",
        "name": "New Age Training",
        "hyphenated": "new-age-training"
    },
    {
        "id": "449700",
        "name": "Dayton School of Medical Massage",
        "hyphenated": "dayton-school-of-medical-massage"
    },
    {
        "id": "449719",
        "name": "Northcoast Medical Training Academy",
        "hyphenated": "northcoast-medical-training-academy"
    },
    {
        "id": "449728",
        "name": "ATA College",
        "hyphenated": "ata-college"
    },
    {
        "id": "449764",
        "name": "Visible Music College",
        "hyphenated": "visible-music-college"
    },
    {
        "id": "449773",
        "name": "CCI Training Center-Arlington",
        "hyphenated": "cci-training-center-arlington"
    },
    {
        "id": "449782",
        "name": "Champion Beauty College",
        "hyphenated": "champion-beauty-college"
    },
    {
        "id": "449807",
        "name": "Southeast Texas Career Institute",
        "hyphenated": "southeast-texas-career-institute"
    },
    {
        "id": "449816",
        "name": "Southwest Technical College",
        "hyphenated": "southwest-technical-college"
    },
    {
        "id": "449834",
        "name": "Virginia Beach Theological Seminary",
        "hyphenated": "virginia-beach-theological-seminary"
    },
    {
        "id": "449861",
        "name": "Milwaukee Career College",
        "hyphenated": "milwaukee-career-college"
    },
    {
        "id": "449870",
        "name": "University of the West",
        "hyphenated": "university-of-the-west"
    },
    {
        "id": "449889",
        "name": "American College of Education",
        "hyphenated": "american-college-of-education"
    },
    {
        "id": "449898",
        "name": "South University-Tampa",
        "hyphenated": "south-university-tampa"
    },
    {
        "id": "449904",
        "name": "Milan Institute of Cosmetology-San Antonio Military",
        "hyphenated": "milan-institute-of-cosmetology-san-antonio-military"
    },
    {
        "id": "449922",
        "name": "Appalachian College of Pharmacy",
        "hyphenated": "appalachian-college-of-pharmacy"
    },
    {
        "id": "449959",
        "name": "Paul Mitchell the School-Tampa",
        "hyphenated": "paul-mitchell-the-school-tampa"
    },
    {
        "id": "449968",
        "name": "Paul Mitchell the School-San Diego",
        "hyphenated": "paul-mitchell-the-school-san-diego"
    },
    {
        "id": "449977",
        "name": "Paul Mitchell the School-Michigan",
        "hyphenated": "paul-mitchell-the-school-michigan"
    },
    {
        "id": "449986",
        "name": "Paul Mitchell the School-Sherman Oaks",
        "hyphenated": "paul-mitchell-the-school-sherman-oaks"
    },
    {
        "id": "449995",
        "name": "Paul Mitchell the School-Tysons Corner",
        "hyphenated": "paul-mitchell-the-school-tysons-corner"
    },
    {
        "id": "450021",
        "name": "Blue Cliff College-Houma",
        "hyphenated": "blue-cliff-college-houma"
    },
    {
        "id": "450058",
        "name": "Fortis College-Columbus",
        "hyphenated": "fortis-college-columbus"
    },
    {
        "id": "450067",
        "name": "Fortis College-Cincinnati",
        "hyphenated": "fortis-college-cincinnati"
    },
    {
        "id": "450119",
        "name": "Blue Cliff College-Fayetteville",
        "hyphenated": "blue-cliff-college-fayetteville"
    },
    {
        "id": "450128",
        "name": "Blue Cliff College-Alexandria",
        "hyphenated": "blue-cliff-college-alexandria"
    },
    {
        "id": "450298",
        "name": "Strayer University-Delaware",
        "hyphenated": "strayer-university-delaware"
    },
    {
        "id": "450304",
        "name": "Brite Divinity School",
        "hyphenated": "brite-divinity-school"
    },
    {
        "id": "450377",
        "name": "Strayer University-Alabama",
        "hyphenated": "strayer-university-alabama"
    },
    {
        "id": "450395",
        "name": "Brown Aveda Institute-Strongsville",
        "hyphenated": "brown-aveda-institute-strongsville"
    },
    {
        "id": "450401",
        "name": "Clary Sage College",
        "hyphenated": "clary-sage-college"
    },
    {
        "id": "450571",
        "name": "Rasmussen University-Wisconsin",
        "hyphenated": "rasmussen-university-wisconsin"
    },
    {
        "id": "450599",
        "name": "Empire Beauty School-Richmond",
        "hyphenated": "empire-beauty-school-richmond"
    },
    {
        "id": "450605",
        "name": "Empire Beauty School-North Hills",
        "hyphenated": "empire-beauty-school-north-hills"
    },
    {
        "id": "450614",
        "name": "Empire Beauty School-Concord",
        "hyphenated": "empire-beauty-school-concord"
    },
    {
        "id": "450632",
        "name": "Lexington Healing Arts Academy",
        "hyphenated": "lexington-healing-arts-academy"
    },
    {
        "id": "450641",
        "name": "Empire Beauty School-Hooksett",
        "hyphenated": "empire-beauty-school-hooksett"
    },
    {
        "id": "450650",
        "name": "The Institute of Beauty and Wellness",
        "hyphenated": "the-institute-of-beauty-and-wellness"
    },
    {
        "id": "450696",
        "name": "Carrington College-Stockton",
        "hyphenated": "carrington-college-stockton"
    },
    {
        "id": "450702",
        "name": "Carrington College-Citrus Heights",
        "hyphenated": "carrington-college-citrus-heights"
    },
    {
        "id": "450711",
        "name": "Trend Barber College",
        "hyphenated": "trend-barber-college"
    },
    {
        "id": "450720",
        "name": "Euphoria Institute of Beauty Arts & Sciences-Summerlin",
        "hyphenated": "euphoria-institute-of-beauty-arts-&-sciences-summerlin"
    },
    {
        "id": "450933",
        "name": "Columbia Southern University",
        "hyphenated": "columbia-southern-university"
    },
    {
        "id": "450951",
        "name": "Arizona Culinary Institute",
        "hyphenated": "arizona-culinary-institute"
    },
    {
        "id": "450960",
        "name": "Healthcare Career College",
        "hyphenated": "healthcare-career-college"
    },
    {
        "id": "450988",
        "name": "Coastline Beauty College",
        "hyphenated": "coastline-beauty-college"
    },
    {
        "id": "451042",
        "name": "Branford Academy of Hair and Cosmetology",
        "hyphenated": "branford-academy-of-hair-and-cosmetology"
    },
    {
        "id": "451051",
        "name": "Academy of Massage and Bodywork",
        "hyphenated": "academy-of-massage-and-bodywork"
    },
    {
        "id": "451079",
        "name": "Academy for Five Element Acupuncture",
        "hyphenated": "academy-for-five-element-acupuncture"
    },
    {
        "id": "451097",
        "name": "Academy of Career Training",
        "hyphenated": "academy-of-career-training"
    },
    {
        "id": "451103",
        "name": "Miami Regional University",
        "hyphenated": "miami-regional-university"
    },
    {
        "id": "451121",
        "name": "Paul Mitchell the School-Miami",
        "hyphenated": "paul-mitchell-the-school-miami"
    },
    {
        "id": "451149",
        "name": "Aveda Institute-South Florida",
        "hyphenated": "aveda-institute-south-florida"
    },
    {
        "id": "451158",
        "name": "Aveda Institute-Tallahassee",
        "hyphenated": "aveda-institute-tallahassee"
    },
    {
        "id": "451167",
        "name": "Shear Excellence Hair Academy",
        "hyphenated": "shear-excellence-hair-academy"
    },
    {
        "id": "451194",
        "name": "Carlson College of Massage Therapy",
        "hyphenated": "carlson-college-of-massage-therapy"
    },
    {
        "id": "451228",
        "name": "MyComputerCareer at Indianapolis",
        "hyphenated": "mycomputercareer-at-indianapolis"
    },
    {
        "id": "451237",
        "name": "Z Hair Academy",
        "hyphenated": "z-hair-academy"
    },
    {
        "id": "451255",
        "name": "DiGrigoli School of Cosmetology",
        "hyphenated": "digrigoli-school-of-cosmetology"
    },
    {
        "id": "451264",
        "name": "Millennium Training Institute",
        "hyphenated": "millennium-training-institute"
    },
    {
        "id": "451282",
        "name": "Elevate Salon Institute",
        "hyphenated": "elevate-salon-institute"
    },
    {
        "id": "451291",
        "name": "Lakewood School of Therapeutic Massage",
        "hyphenated": "lakewood-school-of-therapeutic-massage"
    },
    {
        "id": "451307",
        "name": "The Salon Professional Academy-Battle Creek",
        "hyphenated": "the-salon-professional-academy-battle-creek"
    },
    {
        "id": "451316",
        "name": "Trend Setters School",
        "hyphenated": "trend-setters-school"
    },
    {
        "id": "451334",
        "name": "Academy of Hair Design-Springfield",
        "hyphenated": "academy-of-hair-design-springfield"
    },
    {
        "id": "451343",
        "name": "Corinth Academy of Cosmetology",
        "hyphenated": "corinth-academy-of-cosmetology"
    },
    {
        "id": "451352",
        "name": "Southeastern College-Charlotte",
        "hyphenated": "southeastern-college-charlotte"
    },
    {
        "id": "451370",
        "name": "Yeshivas Be'er Yitzchok",
        "hyphenated": "yeshivas-be'er-yitzchok"
    },
    {
        "id": "451398",
        "name": "Yeshiva Toras Chaim",
        "hyphenated": "yeshiva-toras-chaim"
    },
    {
        "id": "451404",
        "name": "Talmudical Seminary of Bobov",
        "hyphenated": "talmudical-seminary-of-bobov"
    },
    {
        "id": "451413",
        "name": "Center for Allied Health Education",
        "hyphenated": "center-for-allied-health-education"
    },
    {
        "id": "451459",
        "name": "Monroe 2 Orleans BOCES-Center for Workforce Development",
        "hyphenated": "monroe-2-orleans-boces-center-for-workforce-development"
    },
    {
        "id": "451468",
        "name": "Aveda Fredric's Institute-Cincinnati",
        "hyphenated": "aveda-fredric's-institute-cincinnati"
    },
    {
        "id": "451477",
        "name": "MyComputerCareer at Columbus",
        "hyphenated": "mycomputercareer-at-columbus"
    },
    {
        "id": "451495",
        "name": "CDE Career Institute",
        "hyphenated": "cde-career-institute"
    },
    {
        "id": "451501",
        "name": "Instituto Educativo Premier",
        "hyphenated": "instituto-educativo-premier"
    },
    {
        "id": "451510",
        "name": "Charleston School of Law",
        "hyphenated": "charleston-school-of-law"
    },
    {
        "id": "451529",
        "name": "Elite College of Cosmetology",
        "hyphenated": "elite-college-of-cosmetology"
    },
    {
        "id": "451547",
        "name": "Manuel and Theresa's School of Hair Design-Bryan",
        "hyphenated": "manuel-and-theresa's-school-of-hair-design-bryan"
    },
    {
        "id": "451556",
        "name": "Southwest University at El Paso",
        "hyphenated": "southwest-university-at-el-paso"
    },
    {
        "id": "451565",
        "name": "Paul Mitchell the School-Austin",
        "hyphenated": "paul-mitchell-the-school-austin"
    },
    {
        "id": "451574",
        "name": "Aveda Institute-Provo",
        "hyphenated": "aveda-institute-provo"
    },
    {
        "id": "451583",
        "name": "Dixie Technical College",
        "hyphenated": "dixie-technical-college"
    },
    {
        "id": "451626",
        "name": "Victoria's Academy of Cosmetology",
        "hyphenated": "victoria's-academy-of-cosmetology"
    },
    {
        "id": "451714",
        "name": "Paul Mitchell the School-San Antonio",
        "hyphenated": "paul-mitchell-the-school-san-antonio"
    },
    {
        "id": "451741",
        "name": "CEM College-Bayamon",
        "hyphenated": "cem-college-bayamon"
    },
    {
        "id": "451750",
        "name": "Bryant & Stratton College-Wauwatosa",
        "hyphenated": "bryant-&-stratton-college-wauwatosa"
    },
    {
        "id": "451820",
        "name": "The Art Institute of Austin",
        "hyphenated": "the-art-institute-of-austin"
    },
    {
        "id": "451857",
        "name": "Remington College-Houston Southeast Campus",
        "hyphenated": "remington-college-houston-southeast-campus"
    },
    {
        "id": "451866",
        "name": "Remington College-Shreveport Campus",
        "hyphenated": "remington-college-shreveport-campus"
    },
    {
        "id": "451918",
        "name": "Jenny Lea Academy of Cosmetology",
        "hyphenated": "jenny-lea-academy-of-cosmetology"
    },
    {
        "id": "451927",
        "name": "Patrick Henry College",
        "hyphenated": "patrick-henry-college"
    },
    {
        "id": "452009",
        "name": "The Hair Academy",
        "hyphenated": "the-hair-academy"
    },
    {
        "id": "452072",
        "name": "Ross Medical Education Center-New Baltimore",
        "hyphenated": "ross-medical-education-center-new-baltimore"
    },
    {
        "id": "452081",
        "name": "Ross College-Sylvania",
        "hyphenated": "ross-college-sylvania"
    },
    {
        "id": "452106",
        "name": "Escuela De Troqueleria Y Herramentaje",
        "hyphenated": "escuela-de-troqueleria-y-herramentaje"
    },
    {
        "id": "452124",
        "name": "Fortis Institute-Nashville",
        "hyphenated": "fortis-institute-nashville"
    },
    {
        "id": "452373",
        "name": "ATA College-Cincinnati",
        "hyphenated": "ata-college-cincinnati"
    },
    {
        "id": "452771",
        "name": "Dental Assistant Pro LLC-Columbus",
        "hyphenated": "dental-assistant-pro-llc-columbus"
    },
    {
        "id": "452780",
        "name": "Dental Assistant Pro-Lebanon",
        "hyphenated": "dental-assistant-pro-lebanon"
    },
    {
        "id": "452887",
        "name": "Elite Welding Academy LLC",
        "hyphenated": "elite-welding-academy-llc"
    },
    {
        "id": "452948",
        "name": "Galen College of Nursing-Cincinnati",
        "hyphenated": "galen-college-of-nursing-cincinnati"
    },
    {
        "id": "453163",
        "name": "Strayer University-North Carolina",
        "hyphenated": "strayer-university-north-carolina"
    },
    {
        "id": "453215",
        "name": "Strayer University-New Jersey",
        "hyphenated": "strayer-university-new-jersey"
    },
    {
        "id": "453297",
        "name": "International Culinary Arts and Sciences Institute",
        "hyphenated": "international-culinary-arts-and-sciences-institute"
    },
    {
        "id": "453756",
        "name": "Ohio Media School-Columbus",
        "hyphenated": "ohio-media-school-columbus"
    },
    {
        "id": "453792",
        "name": "Ohio Medical Career College",
        "hyphenated": "ohio-medical-career-college"
    },
    {
        "id": "454184",
        "name": "The King's College",
        "hyphenated": "the-king's-college"
    },
    {
        "id": "454227",
        "name": "Chamberlain University-Illinois",
        "hyphenated": "chamberlain-university-illinois"
    },
    {
        "id": "454236",
        "name": "Chamberlain University-Ohio",
        "hyphenated": "chamberlain-university-ohio"
    },
    {
        "id": "454245",
        "name": "Chamberlain University-Arizona",
        "hyphenated": "chamberlain-university-arizona"
    },
    {
        "id": "454458",
        "name": "Bryan University",
        "hyphenated": "bryan-university"
    },
    {
        "id": "454537",
        "name": "The Salon Professional Academy-Appleton",
        "hyphenated": "the-salon-professional-academy-appleton"
    },
    {
        "id": "454546",
        "name": "Milan Institute of Cosmetology-Vacaville",
        "hyphenated": "milan-institute-of-cosmetology-vacaville"
    },
    {
        "id": "454555",
        "name": "Milan Institute of Cosmetology-Reno",
        "hyphenated": "milan-institute-of-cosmetology-reno"
    },
    {
        "id": "454582",
        "name": "Ottawa University-Online",
        "hyphenated": "ottawa-university-online"
    },
    {
        "id": "454607",
        "name": "Paul Mitchell the School-Esani",
        "hyphenated": "paul-mitchell-the-school-esani"
    },
    {
        "id": "454616",
        "name": "Institute of Production and Recording",
        "hyphenated": "institute-of-production-and-recording"
    },
    {
        "id": "454652",
        "name": "Paul Mitchell the School-Birmingham",
        "hyphenated": "paul-mitchell-the-school-birmingham"
    },
    {
        "id": "454689",
        "name": "Taft University System",
        "hyphenated": "taft-university-system"
    },
    {
        "id": "454698",
        "name": "Mayfield College",
        "hyphenated": "mayfield-college"
    },
    {
        "id": "454722",
        "name": "Academy of Esthetics and Cosmetology",
        "hyphenated": "academy-of-esthetics-and-cosmetology"
    },
    {
        "id": "454759",
        "name": "Borner's Barber College",
        "hyphenated": "borner's-barber-college"
    },
    {
        "id": "454768",
        "name": "Paul Mitchell the School-Pasadena",
        "hyphenated": "paul-mitchell-the-school-pasadena"
    },
    {
        "id": "454777",
        "name": "San Francisco Institute of Esthetics & Cosmetology Inc",
        "hyphenated": "san-francisco-institute-of-esthetics-&-cosmetology-inc"
    },
    {
        "id": "454786",
        "name": "Laurus College",
        "hyphenated": "laurus-college"
    },
    {
        "id": "454795",
        "name": "Healing Hands School of Holistic Health",
        "hyphenated": "healing-hands-school-of-holistic-health"
    },
    {
        "id": "454801",
        "name": "Career Development Institute Inc",
        "hyphenated": "career-development-institute-inc"
    },
    {
        "id": "454810",
        "name": "Auguste Escoffier School of Culinary Arts-Boulder",
        "hyphenated": "auguste-escoffier-school-of-culinary-arts-boulder"
    },
    {
        "id": "454829",
        "name": "Aspen University",
        "hyphenated": "aspen-university"
    },
    {
        "id": "454838",
        "name": "Institute of Taoist Education and Acupuncture",
        "hyphenated": "institute-of-taoist-education-and-acupuncture"
    },
    {
        "id": "454856",
        "name": "Denver College of Nursing",
        "hyphenated": "denver-college-of-nursing"
    },
    {
        "id": "454865",
        "name": "Cambridge College of Healthcare & Technology",
        "hyphenated": "cambridge-college-of-healthcare-&-technology"
    },
    {
        "id": "454874",
        "name": "Cozmo Beauty School",
        "hyphenated": "cozmo-beauty-school"
    },
    {
        "id": "454917",
        "name": "Celebrity School of Beauty",
        "hyphenated": "celebrity-school-of-beauty"
    },
    {
        "id": "454926",
        "name": "Paul Mitchell the School-Atlanta",
        "hyphenated": "paul-mitchell-the-school-atlanta"
    },
    {
        "id": "454935",
        "name": "Evans Hairstyling College-Rexburg",
        "hyphenated": "evans-hairstyling-college-rexburg"
    },
    {
        "id": "454944",
        "name": "Paul Mitchell the School-Rexburg",
        "hyphenated": "paul-mitchell-the-school-rexburg"
    },
    {
        "id": "454953",
        "name": "University of Aesthetics & Cosmetology",
        "hyphenated": "university-of-aesthetics-&-cosmetology"
    },
    {
        "id": "454962",
        "name": "The University of Aesthetics & Cosmetology",
        "hyphenated": "the-university-of-aesthetics-&-cosmetology"
    },
    {
        "id": "454971",
        "name": "Tricoci University of Beauty Culture-Chicago NW",
        "hyphenated": "tricoci-university-of-beauty-culture-chicago-nw"
    },
    {
        "id": "454980",
        "name": "Tricoci University of Beauty Culture-Glendale Heights",
        "hyphenated": "tricoci-university-of-beauty-culture-glendale-heights"
    },
    {
        "id": "454999",
        "name": "Tricoci University of Beauty Culture-Peoria",
        "hyphenated": "tricoci-university-of-beauty-culture-peoria"
    },
    {
        "id": "455008",
        "name": "Tricoci University of Beauty Culture-Rockford",
        "hyphenated": "tricoci-university-of-beauty-culture-rockford"
    },
    {
        "id": "455026",
        "name": "Omega Studios' School of Applied Recording Arts & Sciences",
        "hyphenated": "omega-studios'-school-of-applied-recording-arts-&-sciences"
    },
    {
        "id": "455062",
        "name": "French Academy of Cosmetology",
        "hyphenated": "french-academy-of-cosmetology"
    },
    {
        "id": "455071",
        "name": "Healing Arts Center",
        "hyphenated": "healing-arts-center"
    },
    {
        "id": "455080",
        "name": "Paul Mitchell the School-Springfield",
        "hyphenated": "paul-mitchell-the-school-springfield"
    },
    {
        "id": "455099",
        "name": "Urshan Graduate School of Theology",
        "hyphenated": "urshan-graduate-school-of-theology"
    },
    {
        "id": "455105",
        "name": "Mississippi Institute of Aesthetics Nails & Cosmetology",
        "hyphenated": "mississippi-institute-of-aesthetics-nails-&-cosmetology"
    },
    {
        "id": "455114",
        "name": "College of Western Idaho",
        "hyphenated": "college-of-western-idaho"
    },
    {
        "id": "455141",
        "name": "Aveda Institute-Chapel Hill",
        "hyphenated": "aveda-institute-chapel-hill"
    },
    {
        "id": "455178",
        "name": "Daoist Traditions College of Chinese Medical Arts",
        "hyphenated": "daoist-traditions-college-of-chinese-medical-arts"
    },
    {
        "id": "455187",
        "name": "Total Image Beauty Academy",
        "hyphenated": "total-image-beauty-academy"
    },
    {
        "id": "455196",
        "name": "Jersey College",
        "hyphenated": "jersey-college"
    },
    {
        "id": "455202",
        "name": "American Institute of Medical Sciences & Education",
        "hyphenated": "american-institute-of-medical-sciences-&-education"
    },
    {
        "id": "455211",
        "name": "Casal Institute of Nevada",
        "hyphenated": "casal-institute-of-nevada"
    },
    {
        "id": "455220",
        "name": "Career School of NY",
        "hyphenated": "career-school-of-ny"
    },
    {
        "id": "455257",
        "name": "Yeshiva of Machzikai Hadas",
        "hyphenated": "yeshiva-of-machzikai-hadas"
    },
    {
        "id": "455275",
        "name": "Aveda Institute-Columbus",
        "hyphenated": "aveda-institute-columbus"
    },
    {
        "id": "455284",
        "name": "Paul Mitchell the School-Cincinnati",
        "hyphenated": "paul-mitchell-the-school-cincinnati"
    },
    {
        "id": "455327",
        "name": "Hands on Therapy",
        "hyphenated": "hands-on-therapy"
    },
    {
        "id": "455336",
        "name": "MediaTech Institute-Dallas",
        "hyphenated": "mediatech-institute-dallas"
    },
    {
        "id": "455354",
        "name": "Aveda Arts & Sciences Institute-San Antonio",
        "hyphenated": "aveda-arts-&-sciences-institute-san-antonio"
    },
    {
        "id": "455381",
        "name": "Skin Science Institute",
        "hyphenated": "skin-science-institute"
    },
    {
        "id": "455406",
        "name": "Pacific Northwest University of Health Sciences",
        "hyphenated": "pacific-northwest-university-of-health-sciences"
    },
    {
        "id": "455433",
        "name": "The Chicago School of Professional Psychology at Anaheim",
        "hyphenated": "the-chicago-school-of-professional-psychology-at-anaheim"
    },
    {
        "id": "455460",
        "name": "Fortis College-Dothan",
        "hyphenated": "fortis-college-dothan"
    },
    {
        "id": "455479",
        "name": "Fortis Institute-Pensacola",
        "hyphenated": "fortis-institute-pensacola"
    },
    {
        "id": "455488",
        "name": "Fortis College-Montgomery",
        "hyphenated": "fortis-college-montgomery"
    },
    {
        "id": "455512",
        "name": "Woodland Community College",
        "hyphenated": "woodland-community-college"
    },
    {
        "id": "455558",
        "name": "Dorsey College-Roseville",
        "hyphenated": "dorsey-college-roseville"
    },
    {
        "id": "455628",
        "name": "Fortis Institute-Birmingham",
        "hyphenated": "fortis-institute-birmingham"
    },
    {
        "id": "455664",
        "name": "The Chicago School of Professional Psychology at Los Angeles",
        "hyphenated": "the-chicago-school-of-professional-psychology-at-los-angeles"
    },
    {
        "id": "455707",
        "name": "Cardiac and Vascular Institute of Ultrasound",
        "hyphenated": "cardiac-and-vascular-institute-of-ultrasound"
    },
    {
        "id": "455725",
        "name": "Studio Academy of Beauty",
        "hyphenated": "studio-academy-of-beauty"
    },
    {
        "id": "455734",
        "name": "California Nurses Educational Institute",
        "hyphenated": "california-nurses-educational-institute"
    },
    {
        "id": "455761",
        "name": "Paul Mitchell the School-Sacramento",
        "hyphenated": "paul-mitchell-the-school-sacramento"
    },
    {
        "id": "455770",
        "name": "Providence Christian College",
        "hyphenated": "providence-christian-college"
    },
    {
        "id": "455798",
        "name": "Oxford Academy of Hair Design Inc",
        "hyphenated": "oxford-academy-of-hair-design-inc"
    },
    {
        "id": "455804",
        "name": "Institute of World Politics",
        "hyphenated": "institute-of-world-politics"
    },
    {
        "id": "455813",
        "name": "Pontifical John Paul II Institute for Studies on Marriage and Family",
        "hyphenated": "pontifical-john-paul-ii-institute-for-studies-on-marriage-and-family"
    },
    {
        "id": "455831",
        "name": "Medical Institute of Palm Beach",
        "hyphenated": "medical-institute-of-palm-beach"
    },
    {
        "id": "455859",
        "name": "Oliver Finley Academy of Cosmetology",
        "hyphenated": "oliver-finley-academy-of-cosmetology"
    },
    {
        "id": "455868",
        "name": "National Career College",
        "hyphenated": "national-career-college"
    },
    {
        "id": "455877",
        "name": "Aveda Institute-Chicago",
        "hyphenated": "aveda-institute-chicago"
    },
    {
        "id": "455886",
        "name": "Tricoci University of Beauty Culture-Libertyville",
        "hyphenated": "tricoci-university-of-beauty-culture-libertyville"
    },
    {
        "id": "455895",
        "name": "Tricoci University of Beauty Culture-Bridgeview",
        "hyphenated": "tricoci-university-of-beauty-culture-bridgeview"
    },
    {
        "id": "455901",
        "name": "The Temple-A Paul Mitchell Partner School",
        "hyphenated": "the-temple-a-paul-mitchell-partner-school"
    },
    {
        "id": "455910",
        "name": "Center for Massage",
        "hyphenated": "center-for-massage"
    },
    {
        "id": "455929",
        "name": "The Academy of Hair Design LLC",
        "hyphenated": "the-academy-of-hair-design-llc"
    },
    {
        "id": "455965",
        "name": "Toni & Guy Hairdressing Academy-Cranston",
        "hyphenated": "toni-&-guy-hairdressing-academy-cranston"
    },
    {
        "id": "455974",
        "name": "South Texas Training Center",
        "hyphenated": "south-texas-training-center"
    },
    {
        "id": "455983",
        "name": "Columbia College",
        "hyphenated": "columbia-college"
    },
    {
        "id": "455992",
        "name": "Vermont College of Fine Arts",
        "hyphenated": "vermont-college-of-fine-arts"
    },
    {
        "id": "456001",
        "name": "Advanced Welding Institute",
        "hyphenated": "advanced-welding-institute"
    },
    {
        "id": "456029",
        "name": "Paul Mitchell the School-Memphis",
        "hyphenated": "paul-mitchell-the-school-memphis"
    },
    {
        "id": "456038",
        "name": "Pima Medical Institute-East Valley",
        "hyphenated": "pima-medical-institute-east-valley"
    },
    {
        "id": "456065",
        "name": "Paul Mitchell the School-Cleveland",
        "hyphenated": "paul-mitchell-the-school-cleveland"
    },
    {
        "id": "456074",
        "name": "Paul Mitchell the School-Columbus",
        "hyphenated": "paul-mitchell-the-school-columbus"
    },
    {
        "id": "456135",
        "name": "Empire Beauty School-Spring Lake Park",
        "hyphenated": "empire-beauty-school-spring-lake-park"
    },
    {
        "id": "456153",
        "name": "MediaTech Institute-Houston",
        "hyphenated": "mediatech-institute-houston"
    },
    {
        "id": "456205",
        "name": "Miller-Motte College-Raleigh",
        "hyphenated": "miller-motte-college-raleigh"
    },
    {
        "id": "456214",
        "name": "Lamson Institute",
        "hyphenated": "lamson-institute"
    },
    {
        "id": "456287",
        "name": "College of Hair Design-East Campus",
        "hyphenated": "college-of-hair-design-east-campus"
    },
    {
        "id": "456454",
        "name": "Fortis College-Salt Lake City",
        "hyphenated": "fortis-college-salt-lake-city"
    },
    {
        "id": "456481",
        "name": "Polytechnic University of Puerto Rico-Miami",
        "hyphenated": "polytechnic-university-of-puerto-rico-miami"
    },
    {
        "id": "456490",
        "name": "Polytechnic University of Puerto Rico-Orlando",
        "hyphenated": "polytechnic-university-of-puerto-rico-orlando"
    },
    {
        "id": "456506",
        "name": "Ross Medical Education Center-Fort Wayne",
        "hyphenated": "ross-medical-education-center-fort-wayne"
    },
    {
        "id": "456515",
        "name": "Ross Medical Education Center-Battle Creek",
        "hyphenated": "ross-medical-education-center-battle-creek"
    },
    {
        "id": "456542",
        "name": "Geisinger Commonwealth School of Medicine",
        "hyphenated": "geisinger-commonwealth-school-of-medicine"
    },
    {
        "id": "456551",
        "name": "Josef's School of Hair Skin & Body-Fargo",
        "hyphenated": "josef's-school-of-hair-skin-&-body-fargo"
    },
    {
        "id": "456579",
        "name": "Miller-Motte College-McCann-Allentown",
        "hyphenated": "miller-motte-college-mccann-allentown"
    },
    {
        "id": "456588",
        "name": "Salon Success Academy-Fontana",
        "hyphenated": "salon-success-academy-fontana"
    },
    {
        "id": "456597",
        "name": "Salon Success Academy-Redlands",
        "hyphenated": "salon-success-academy-redlands"
    },
    {
        "id": "456621",
        "name": "Strayer University-West Virginia",
        "hyphenated": "strayer-university-west-virginia"
    },
    {
        "id": "456825",
        "name": "Paul Mitchell the School-Baton Rouge",
        "hyphenated": "paul-mitchell-the-school-baton-rouge"
    },
    {
        "id": "456852",
        "name": "San Joaquin Valley College-Hesperia",
        "hyphenated": "san-joaquin-valley-college-hesperia"
    },
    {
        "id": "456922",
        "name": "South Texas Vocational Technical Institute-Brownsville",
        "hyphenated": "south-texas-vocational-technical-institute-brownsville"
    },
    {
        "id": "456931",
        "name": "Miller-Motte College-STVT-Corpus Christi",
        "hyphenated": "miller-motte-college-stvt-corpus-christi"
    },
    {
        "id": "456959",
        "name": "University of Minnesota-Rochester",
        "hyphenated": "university-of-minnesota-rochester"
    },
    {
        "id": "456968",
        "name": "Health And Style Institute",
        "hyphenated": "health-and-style-institute"
    },
    {
        "id": "456977",
        "name": "CBT Technology Institute-Cutler Bay",
        "hyphenated": "cbt-technology-institute-cutler-bay"
    },
    {
        "id": "457031",
        "name": "Capri Beauty College",
        "hyphenated": "capri-beauty-college"
    },
    {
        "id": "457077",
        "name": "Milan Institute-Bakersfield",
        "hyphenated": "milan-institute-bakersfield"
    },
    {
        "id": "457086",
        "name": "Homestead Schools",
        "hyphenated": "homestead-schools"
    },
    {
        "id": "457101",
        "name": "Carrington College-Las Vegas",
        "hyphenated": "carrington-college-las-vegas"
    },
    {
        "id": "457110",
        "name": "Carrington College-Reno",
        "hyphenated": "carrington-college-reno"
    },
    {
        "id": "457129",
        "name": "Chamberlain University-Florida",
        "hyphenated": "chamberlain-university-florida"
    },
    {
        "id": "457192",
        "name": "Washington Barber College Inc",
        "hyphenated": "washington-barber-college-inc"
    },
    {
        "id": "457208",
        "name": "InterCoast Colleges-Rancho Cordova",
        "hyphenated": "intercoast-colleges-rancho-cordova"
    },
    {
        "id": "457226",
        "name": "Horizon University",
        "hyphenated": "horizon-university"
    },
    {
        "id": "457253",
        "name": "Beyond 21st Century Beauty Academy",
        "hyphenated": "beyond-21st-century-beauty-academy"
    },
    {
        "id": "457271",
        "name": "Academy for Jewish Religion-California",
        "hyphenated": "academy-for-jewish-religion-california"
    },
    {
        "id": "457299",
        "name": "Angeles College",
        "hyphenated": "angeles-college"
    },
    {
        "id": "457314",
        "name": "Angeles Institute",
        "hyphenated": "angeles-institute"
    },
    {
        "id": "457323",
        "name": "Paul Mitchell the School-Temecula",
        "hyphenated": "paul-mitchell-the-school-temecula"
    },
    {
        "id": "457332",
        "name": "TIGI Hairdressing Academy Guilford",
        "hyphenated": "tigi-hairdressing-academy-guilford"
    },
    {
        "id": "457341",
        "name": "International Institute of Cosmetology",
        "hyphenated": "international-institute-of-cosmetology"
    },
    {
        "id": "457350",
        "name": "Florida School of Traditional Midwifery",
        "hyphenated": "florida-school-of-traditional-midwifery"
    },
    {
        "id": "457378",
        "name": "Emerald Coast Technical College",
        "hyphenated": "emerald-coast-technical-college"
    },
    {
        "id": "457387",
        "name": "Immokalee Technical College",
        "hyphenated": "immokalee-technical-college"
    },
    {
        "id": "457396",
        "name": "American Academy of Cosmetology",
        "hyphenated": "american-academy-of-cosmetology"
    },
    {
        "id": "457402",
        "name": "University of Fort Lauderdale",
        "hyphenated": "university-of-fort-lauderdale"
    },
    {
        "id": "457411",
        "name": "Aviation Institute of Maintenance-Orlando",
        "hyphenated": "aviation-institute-of-maintenance-orlando"
    },
    {
        "id": "457420",
        "name": "Summit Salon Academy",
        "hyphenated": "summit-salon-academy"
    },
    {
        "id": "457439",
        "name": "Summit Salon Academy-Gainesville",
        "hyphenated": "summit-salon-academy-gainesville"
    },
    {
        "id": "457448",
        "name": "Tenaj Salon Institute",
        "hyphenated": "tenaj-salon-institute"
    },
    {
        "id": "457466",
        "name": "Profile Institute of Barber-Styling",
        "hyphenated": "profile-institute-of-barber-styling"
    },
    {
        "id": "457475",
        "name": "Keune Academy by 124",
        "hyphenated": "keune-academy-by-124"
    },
    {
        "id": "457484",
        "name": "Pacific Rim Christian University",
        "hyphenated": "pacific-rim-christian-university"
    },
    {
        "id": "457493",
        "name": "Paul Mitchell the School-Twin Falls",
        "hyphenated": "paul-mitchell-the-school-twin-falls"
    },
    {
        "id": "457527",
        "name": "Ambria College of Nursing",
        "hyphenated": "ambria-college-of-nursing"
    },
    {
        "id": "457536",
        "name": "Midwestern Career College",
        "hyphenated": "midwestern-career-college"
    },
    {
        "id": "457545",
        "name": "Paul Mitchell the School-Normal",
        "hyphenated": "paul-mitchell-the-school-normal"
    },
    {
        "id": "457554",
        "name": "Innovations Design Academy",
        "hyphenated": "innovations-design-academy"
    },
    {
        "id": "457563",
        "name": "Aveda Fredric's Institute-Indianapolis",
        "hyphenated": "aveda-fredric's-institute-indianapolis"
    },
    {
        "id": "457572",
        "name": "Summit Salon Academy",
        "hyphenated": "summit-salon-academy"
    },
    {
        "id": "457581",
        "name": "Eric Fisher Academy",
        "hyphenated": "eric-fisher-academy"
    },
    {
        "id": "457606",
        "name": "My Le's Beauty College",
        "hyphenated": "my-le's-beauty-college"
    },
    {
        "id": "457615",
        "name": "Blackstone Valley Vocational Regional School District",
        "hyphenated": "blackstone-valley-vocational-regional-school-district"
    },
    {
        "id": "457624",
        "name": "Southern Worcester County Regional Vocational School District",
        "hyphenated": "southern-worcester-county-regional-vocational-school-district"
    },
    {
        "id": "457633",
        "name": "L'esprit Academy",
        "hyphenated": "l'esprit-academy"
    },
    {
        "id": "457642",
        "name": "Marketti Academy of Cosmetology",
        "hyphenated": "marketti-academy-of-cosmetology"
    },
    {
        "id": "457679",
        "name": "Avalon School of Cosmetology",
        "hyphenated": "avalon-school-of-cosmetology"
    },
    {
        "id": "457688",
        "name": "American Business and Technology University",
        "hyphenated": "american-business-and-technology-university"
    },
    {
        "id": "457697",
        "name": "City Vision University",
        "hyphenated": "city-vision-university"
    },
    {
        "id": "457721",
        "name": "Bitterroot School of Cosmetology",
        "hyphenated": "bitterroot-school-of-cosmetology"
    },
    {
        "id": "457749",
        "name": "Montana Academy of Salons",
        "hyphenated": "montana-academy-of-salons"
    },
    {
        "id": "457767",
        "name": "National Career Institute",
        "hyphenated": "national-career-institute"
    },
    {
        "id": "457776",
        "name": "Paul Mitchell the School-Albuquerque",
        "hyphenated": "paul-mitchell-the-school-albuquerque"
    },
    {
        "id": "457785",
        "name": "International Academy of Style",
        "hyphenated": "international-academy-of-style"
    },
    {
        "id": "457794",
        "name": "EDP School",
        "hyphenated": "edp-school"
    },
    {
        "id": "457800",
        "name": "New York Medical Career Training Center",
        "hyphenated": "new-york-medical-career-training-center"
    },
    {
        "id": "457819",
        "name": "Institute of Culinary Education",
        "hyphenated": "institute-of-culinary-education"
    },
    {
        "id": "457837",
        "name": "The Salon Professional Academy-Tonawanda",
        "hyphenated": "the-salon-professional-academy-tonawanda"
    },
    {
        "id": "457855",
        "name": "Paul Mitchell the School-NYC",
        "hyphenated": "paul-mitchell-the-school-nyc"
    },
    {
        "id": "457873",
        "name": "Four County Career Center",
        "hyphenated": "four-county-career-center"
    },
    {
        "id": "457916",
        "name": "Summit Salon Academy-Perrysburg",
        "hyphenated": "summit-salon-academy-perrysburg"
    },
    {
        "id": "457925",
        "name": "Portland Actors Conservatory",
        "hyphenated": "portland-actors-conservatory"
    },
    {
        "id": "457943",
        "name": "Somerset County Technology Center",
        "hyphenated": "somerset-county-technology-center"
    },
    {
        "id": "457952",
        "name": "Metro Beauty Academy",
        "hyphenated": "metro-beauty-academy"
    },
    {
        "id": "457989",
        "name": "Academy for Careers and Technology",
        "hyphenated": "academy-for-careers-and-technology"
    },
    {
        "id": "457998",
        "name": "Aiken School of Cosmetology and Barbering",
        "hyphenated": "aiken-school-of-cosmetology-and-barbering"
    },
    {
        "id": "458007",
        "name": "Love Beauty School Inc",
        "hyphenated": "love-beauty-school-inc"
    },
    {
        "id": "458034",
        "name": "Houston International College Cardiotech Ultrasound School",
        "hyphenated": "houston-international-college-cardiotech-ultrasound-school"
    },
    {
        "id": "458043",
        "name": "DuVall's School of Cosmetology",
        "hyphenated": "duvall's-school-of-cosmetology"
    },
    {
        "id": "458061",
        "name": "Texas Beauty College",
        "hyphenated": "texas-beauty-college"
    },
    {
        "id": "458070",
        "name": "Avenue Five Institute",
        "hyphenated": "avenue-five-institute"
    },
    {
        "id": "458098",
        "name": "The Salon Professional Academy-Lewisville",
        "hyphenated": "the-salon-professional-academy-lewisville"
    },
    {
        "id": "458104",
        "name": "Renaissance Academie",
        "hyphenated": "renaissance-academie"
    },
    {
        "id": "458113",
        "name": "Ascent College",
        "hyphenated": "ascent-college"
    },
    {
        "id": "458122",
        "name": "Institute of Advanced Medical Esthetics",
        "hyphenated": "institute-of-advanced-medical-esthetics"
    },
    {
        "id": "458131",
        "name": "Aveda Arts & Sciences Institute Seattle",
        "hyphenated": "aveda-arts-&-sciences-institute-seattle"
    },
    {
        "id": "458140",
        "name": "Northwest School of Wooden Boat Building",
        "hyphenated": "northwest-school-of-wooden-boat-building"
    },
    {
        "id": "458168",
        "name": "The Salon Professional Academy-Onalaska",
        "hyphenated": "the-salon-professional-academy-onalaska"
    },
    {
        "id": "458210",
        "name": "West Coast University-Orange County",
        "hyphenated": "west-coast-university-orange-county"
    },
    {
        "id": "458229",
        "name": "West Coast University-Ontario",
        "hyphenated": "west-coast-university-ontario"
    },
    {
        "id": "458274",
        "name": "Paul Mitchell the School-Chicago",
        "hyphenated": "paul-mitchell-the-school-chicago"
    },
    {
        "id": "458405",
        "name": "Miller-Motte College-Fayetteville",
        "hyphenated": "miller-motte-college-fayetteville"
    },
    {
        "id": "458423",
        "name": "European Massage Therapy School-Las Vegas",
        "hyphenated": "european-massage-therapy-school-las-vegas"
    },
    {
        "id": "458441",
        "name": "Miller-Motte College-Columbus",
        "hyphenated": "miller-motte-college-columbus"
    },
    {
        "id": "458496",
        "name": "The Art Institute of Virginia Beach",
        "hyphenated": "the-art-institute-of-virginia-beach"
    },
    {
        "id": "458681",
        "name": "Fortis College-Indianapolis",
        "hyphenated": "fortis-college-indianapolis"
    },
    {
        "id": "458803",
        "name": "Empire Beauty School-E Memphis",
        "hyphenated": "empire-beauty-school-e-memphis"
    },
    {
        "id": "458812",
        "name": "Empire Beauty School-Nashville",
        "hyphenated": "empire-beauty-school-nashville"
    },
    {
        "id": "458821",
        "name": "Empire Beauty School-Jackson",
        "hyphenated": "empire-beauty-school-jackson"
    },
    {
        "id": "458830",
        "name": "Empire Beauty School-Springfield",
        "hyphenated": "empire-beauty-school-springfield"
    },
    {
        "id": "458858",
        "name": "Empire Beauty School-Speedway",
        "hyphenated": "empire-beauty-school-speedway"
    },
    {
        "id": "458867",
        "name": "Empire Beauty School-Morrow",
        "hyphenated": "empire-beauty-school-morrow"
    },
    {
        "id": "458885",
        "name": "Strayer University-Arkansas",
        "hyphenated": "strayer-university-arkansas"
    },
    {
        "id": "458919",
        "name": "Strayer University-Georgia",
        "hyphenated": "strayer-university-georgia"
    },
    {
        "id": "458955",
        "name": "Strayer University-Mississippi",
        "hyphenated": "strayer-university-mississippi"
    },
    {
        "id": "458964",
        "name": "Strayer University-South Carolina",
        "hyphenated": "strayer-university-south-carolina"
    },
    {
        "id": "458973",
        "name": "Strayer University-Texas",
        "hyphenated": "strayer-university-texas"
    },
    {
        "id": "458982",
        "name": "The Art Institute of San Antonio",
        "hyphenated": "the-art-institute-of-san-antonio"
    },
    {
        "id": "459019",
        "name": "Empire Beauty School-Charlotte",
        "hyphenated": "empire-beauty-school-charlotte"
    },
    {
        "id": "459046",
        "name": "Empire Beauty School-Winston-Salem",
        "hyphenated": "empire-beauty-school-winston-salem"
    },
    {
        "id": "459055",
        "name": "Empire Beauty School-West Palm",
        "hyphenated": "empire-beauty-school-west-palm"
    },
    {
        "id": "459064",
        "name": "Empire Beauty School-Pineville",
        "hyphenated": "empire-beauty-school-pineville"
    },
    {
        "id": "459116",
        "name": "Paul Mitchell the School-Phoenix",
        "hyphenated": "paul-mitchell-the-school-phoenix"
    },
    {
        "id": "459125",
        "name": "All Beauty College",
        "hyphenated": "all-beauty-college"
    },
    {
        "id": "459170",
        "name": "Paul Mitchell the School-Modesto",
        "hyphenated": "paul-mitchell-the-school-modesto"
    },
    {
        "id": "459189",
        "name": "Cosmo Beauty Academy",
        "hyphenated": "cosmo-beauty-academy"
    },
    {
        "id": "459198",
        "name": "Paul Mitchell the School-Fresno",
        "hyphenated": "paul-mitchell-the-school-fresno"
    },
    {
        "id": "459204",
        "name": "Unitek College",
        "hyphenated": "unitek-college"
    },
    {
        "id": "459213",
        "name": "Gurnick Academy of Medical Arts",
        "hyphenated": "gurnick-academy-of-medical-arts"
    },
    {
        "id": "459231",
        "name": "Paul Mitchell the School-East Bay",
        "hyphenated": "paul-mitchell-the-school-east-bay"
    },
    {
        "id": "459259",
        "name": "South University-Richmond",
        "hyphenated": "south-university-richmond"
    },
    {
        "id": "459268",
        "name": "South University-Virginia Beach",
        "hyphenated": "south-university-virginia-beach"
    },
    {
        "id": "459277",
        "name": "Aveda Institute-Denver",
        "hyphenated": "aveda-institute-denver"
    },
    {
        "id": "459286",
        "name": "Paul Mitchell the School-Colorado Springs",
        "hyphenated": "paul-mitchell-the-school-colorado-springs"
    },
    {
        "id": "459295",
        "name": "The Salon Professional Academy-Colorado Springs",
        "hyphenated": "the-salon-professional-academy-colorado-springs"
    },
    {
        "id": "459301",
        "name": "Paul Mitchell the School-Delaware",
        "hyphenated": "paul-mitchell-the-school-delaware"
    },
    {
        "id": "459310",
        "name": "Future-Tech Institute",
        "hyphenated": "future-tech-institute"
    },
    {
        "id": "459329",
        "name": "Fred K Marchman Technical College",
        "hyphenated": "fred-k-marchman-technical-college"
    },
    {
        "id": "459347",
        "name": "The Salon Professional Academy-Ft Myers",
        "hyphenated": "the-salon-professional-academy-ft-myers"
    },
    {
        "id": "459374",
        "name": "Universal Spa Training Academy",
        "hyphenated": "universal-spa-training-academy"
    },
    {
        "id": "459392",
        "name": "The Salon Professional Academy-Evansville",
        "hyphenated": "the-salon-professional-academy-evansville"
    },
    {
        "id": "459408",
        "name": "Tri County Regional Vocational Technical High School",
        "hyphenated": "tri-county-regional-vocational-technical-high-school"
    },
    {
        "id": "459417",
        "name": "Compass College of Film and Media",
        "hyphenated": "compass-college-of-film-and-media"
    },
    {
        "id": "459426",
        "name": "Nova Academy of Cosmetology",
        "hyphenated": "nova-academy-of-cosmetology"
    },
    {
        "id": "459462",
        "name": "SAE Institute of Technology-New York",
        "hyphenated": "sae-institute-of-technology-new-york"
    },
    {
        "id": "459499",
        "name": "Sage School of Massage & Esthetics",
        "hyphenated": "sage-school-of-massage-&-esthetics"
    },
    {
        "id": "459514",
        "name": "Peloton College",
        "hyphenated": "peloton-college"
    },
    {
        "id": "459523",
        "name": "ABC Beauty Academy",
        "hyphenated": "abc-beauty-academy"
    },
    {
        "id": "459532",
        "name": "Salon & Spa Institute",
        "hyphenated": "salon-&-spa-institute"
    },
    {
        "id": "459541",
        "name": "American Beauty Academy",
        "hyphenated": "american-beauty-academy"
    },
    {
        "id": "459550",
        "name": "Summit Salon Academy",
        "hyphenated": "summit-salon-academy"
    },
    {
        "id": "459578",
        "name": "Paul Mitchell the School-Milwaukee",
        "hyphenated": "paul-mitchell-the-school-milwaukee"
    },
    {
        "id": "459727",
        "name": "Touro University Worldwide",
        "hyphenated": "touro-university-worldwide"
    },
    {
        "id": "459736",
        "name": "Touro University California",
        "hyphenated": "touro-university-california"
    },
    {
        "id": "459745",
        "name": "The Chicago School of Professional Psychology at Washington DC",
        "hyphenated": "the-chicago-school-of-professional-psychology-at-washington-dc"
    },
    {
        "id": "459824",
        "name": "Touro University Nevada",
        "hyphenated": "touro-university-nevada"
    },
    {
        "id": "459842",
        "name": "Herzing University-Kenosha",
        "hyphenated": "herzing-university-kenosha"
    },
    {
        "id": "459851",
        "name": "Herzing University-Brookfield",
        "hyphenated": "herzing-university-brookfield"
    },
    {
        "id": "459949",
        "name": "Texas A&M University-San Antonio",
        "hyphenated": "texas-a&m-university-san-antonio"
    },
    {
        "id": "459958",
        "name": "Fortis Institute",
        "hyphenated": "fortis-institute"
    },
    {
        "id": "459967",
        "name": "Southern Careers Institute-Brownsville",
        "hyphenated": "southern-careers-institute-brownsville"
    },
    {
        "id": "459985",
        "name": "Southern Careers Institute-Harlingen",
        "hyphenated": "southern-careers-institute-harlingen"
    },
    {
        "id": "459994",
        "name": "Strayer University-Global Region",
        "hyphenated": "strayer-university-global-region"
    },
    {
        "id": "460011",
        "name": "Kenneth Shuler School of Cosmetology-Florence",
        "hyphenated": "kenneth-shuler-school-of-cosmetology-florence"
    },
    {
        "id": "460020",
        "name": "Fortis College-Columbia",
        "hyphenated": "fortis-college-columbia"
    },
    {
        "id": "460093",
        "name": "Ross Medical Education Center-Davison",
        "hyphenated": "ross-medical-education-center-davison"
    },
    {
        "id": "460109",
        "name": "Ross Medical Education Center-Granger",
        "hyphenated": "ross-medical-education-center-granger"
    },
    {
        "id": "460118",
        "name": "Ross Medical Education Center-Niles",
        "hyphenated": "ross-medical-education-center-niles"
    },
    {
        "id": "460127",
        "name": "Ross Medical Education Center-Canton",
        "hyphenated": "ross-medical-education-center-canton"
    },
    {
        "id": "460136",
        "name": "Pima Medical Institute-Houston",
        "hyphenated": "pima-medical-institute-houston"
    },
    {
        "id": "460145",
        "name": "Illinois Media School-Chicago Campus",
        "hyphenated": "illinois-media-school-chicago-campus"
    },
    {
        "id": "460172",
        "name": "Miller-Motte College-STVT-San Antonio",
        "hyphenated": "miller-motte-college-stvt-san-antonio"
    },
    {
        "id": "460181",
        "name": "Concorde Career College-Dallas",
        "hyphenated": "concorde-career-college-dallas"
    },
    {
        "id": "460190",
        "name": "Concorde Career Institute-Orlando",
        "hyphenated": "concorde-career-institute-orlando"
    },
    {
        "id": "460206",
        "name": "Concorde Career College-San Antonio",
        "hyphenated": "concorde-career-college-san-antonio"
    },
    {
        "id": "460349",
        "name": "Johnson & Wales University-Online",
        "hyphenated": "johnson-&-wales-university-online"
    },
    {
        "id": "460376",
        "name": "Fairfax University of America",
        "hyphenated": "fairfax-university-of-america"
    },
    {
        "id": "460385",
        "name": "Geisinger-Lewistown Hospital School of Nursing",
        "hyphenated": "geisinger-lewistown-hospital-school-of-nursing"
    },
    {
        "id": "460394",
        "name": "Moreno Valley College",
        "hyphenated": "moreno-valley-college"
    },
    {
        "id": "460464",
        "name": "Norco College",
        "hyphenated": "norco-college"
    },
    {
        "id": "460482",
        "name": "Dorsey College-Saginaw",
        "hyphenated": "dorsey-college-saginaw"
    },
    {
        "id": "460516",
        "name": "Milan Institute of Cosmetology-La Quinta",
        "hyphenated": "milan-institute-of-cosmetology-la-quinta"
    },
    {
        "id": "460525",
        "name": "Milan Institute-Boise",
        "hyphenated": "milan-institute-boise"
    },
    {
        "id": "460534",
        "name": "Milan Institute of Cosmetology-El Paso",
        "hyphenated": "milan-institute-of-cosmetology-el-paso"
    },
    {
        "id": "460543",
        "name": "Universal Technical Institute-Dallas Fort Worth",
        "hyphenated": "universal-technical-institute-dallas-fort-worth"
    },
    {
        "id": "460677",
        "name": "ICPR Junior College",
        "hyphenated": "icpr-junior-college"
    },
    {
        "id": "460701",
        "name": "Artistic Nails and Beauty Academy-Lakeland",
        "hyphenated": "artistic-nails-and-beauty-academy-lakeland"
    },
    {
        "id": "460738",
        "name": "American Sentinel College of Nursing and Health Sciences",
        "hyphenated": "american-sentinel-college-of-nursing-and-health-sciences"
    },
    {
        "id": "460756",
        "name": "Hawaii Medical College",
        "hyphenated": "hawaii-medical-college"
    },
    {
        "id": "460765",
        "name": "Carolina College of Hair Design",
        "hyphenated": "carolina-college-of-hair-design"
    },
    {
        "id": "460808",
        "name": "College of Massage Therapy",
        "hyphenated": "college-of-massage-therapy"
    },
    {
        "id": "460817",
        "name": "Miller-Motte College-Jacksonville",
        "hyphenated": "miller-motte-college-jacksonville"
    },
    {
        "id": "460826",
        "name": "Miller-Motte College-Augusta",
        "hyphenated": "miller-motte-college-augusta"
    },
    {
        "id": "460835",
        "name": "Miller-Motte College-Conway",
        "hyphenated": "miller-motte-college-conway"
    },
    {
        "id": "460862",
        "name": "Aveda Institute-Portland",
        "hyphenated": "aveda-institute-portland"
    },
    {
        "id": "460871",
        "name": "Chamberlain University-Virginia",
        "hyphenated": "chamberlain-university-virginia"
    },
    {
        "id": "460914",
        "name": "Penrose Academy",
        "hyphenated": "penrose-academy"
    },
    {
        "id": "460932",
        "name": "Skin Institute",
        "hyphenated": "skin-institute"
    },
    {
        "id": "460969",
        "name": "Beauty Academy of South Florida",
        "hyphenated": "beauty-academy-of-south-florida"
    },
    {
        "id": "460978",
        "name": "Ogle School Hair Skin Nails-North Dallas",
        "hyphenated": "ogle-school-hair-skin-nails-north-dallas"
    },
    {
        "id": "460987",
        "name": "The Salon Professional Academy",
        "hyphenated": "the-salon-professional-academy"
    },
    {
        "id": "460996",
        "name": "The Salon Professional Academy-St Charles",
        "hyphenated": "the-salon-professional-academy-st-charles"
    },
    {
        "id": "461014",
        "name": "Mildred Elley-New York Campus",
        "hyphenated": "mildred-elley-new-york-campus"
    },
    {
        "id": "461023",
        "name": "National Paralegal College",
        "hyphenated": "national-paralegal-college"
    },
    {
        "id": "461032",
        "name": "Carolina College of Biblical Studies",
        "hyphenated": "carolina-college-of-biblical-studies"
    },
    {
        "id": "461087",
        "name": "Northeast Technology Center",
        "hyphenated": "northeast-technology-center"
    },
    {
        "id": "461111",
        "name": "Allstate Hairstyling & Barber College",
        "hyphenated": "allstate-hairstyling-&-barber-college"
    },
    {
        "id": "461120",
        "name": "Omega Graduate School",
        "hyphenated": "omega-graduate-school"
    },
    {
        "id": "461139",
        "name": "Jung Tao School of Classical Chinese Medicine",
        "hyphenated": "jung-tao-school-of-classical-chinese-medicine"
    },
    {
        "id": "461148",
        "name": "New York Film Academy",
        "hyphenated": "new-york-film-academy"
    },
    {
        "id": "461175",
        "name": "International College of Cosmetology",
        "hyphenated": "international-college-of-cosmetology"
    },
    {
        "id": "461193",
        "name": "Bella Capelli Academy",
        "hyphenated": "bella-capelli-academy"
    },
    {
        "id": "461218",
        "name": "Institute of Medical Careers",
        "hyphenated": "institute-of-medical-careers"
    },
    {
        "id": "461245",
        "name": "Flagler Technical College",
        "hyphenated": "flagler-technical-college"
    },
    {
        "id": "461254",
        "name": "Galaxy Medical College",
        "hyphenated": "galaxy-medical-college"
    },
    {
        "id": "461263",
        "name": "American Medical Sciences Center",
        "hyphenated": "american-medical-sciences-center"
    },
    {
        "id": "461272",
        "name": "Mandalyn Academy",
        "hyphenated": "mandalyn-academy"
    },
    {
        "id": "461281",
        "name": "Jose Maria Vargas University",
        "hyphenated": "jose-maria-vargas-university"
    },
    {
        "id": "461306",
        "name": "D A Dorsey Technical College",
        "hyphenated": "d-a-dorsey-technical-college"
    },
    {
        "id": "461315",
        "name": "Keweenaw Bay Ojibwa Community College",
        "hyphenated": "keweenaw-bay-ojibwa-community-college"
    },
    {
        "id": "461324",
        "name": "Meridian Institute of Surgical Assisting",
        "hyphenated": "meridian-institute-of-surgical-assisting"
    },
    {
        "id": "461333",
        "name": "Mauna Loa Helicopters",
        "hyphenated": "mauna-loa-helicopters"
    },
    {
        "id": "461342",
        "name": "Acaydia School of Aesthetics",
        "hyphenated": "acaydia-school-of-aesthetics"
    },
    {
        "id": "461379",
        "name": "Holistic Massage Training Institute",
        "hyphenated": "holistic-massage-training-institute"
    },
    {
        "id": "461388",
        "name": "Diamonds College",
        "hyphenated": "diamonds-college"
    },
    {
        "id": "461412",
        "name": "Northern Virginia School of Therapeutic Massage",
        "hyphenated": "northern-virginia-school-of-therapeutic-massage"
    },
    {
        "id": "461421",
        "name": "Paul Mitchell the School-St Louis",
        "hyphenated": "paul-mitchell-the-school-st-louis"
    },
    {
        "id": "461430",
        "name": "Advanced Training Institute",
        "hyphenated": "advanced-training-institute"
    },
    {
        "id": "461485",
        "name": "Shepherds Theological Seminary",
        "hyphenated": "shepherds-theological-seminary"
    },
    {
        "id": "461494",
        "name": "Taylor Andrews Academy of Hair Design-West Jordan",
        "hyphenated": "taylor-andrews-academy-of-hair-design-west-jordan"
    },
    {
        "id": "461500",
        "name": "Santa Ana Beauty Academy",
        "hyphenated": "santa-ana-beauty-academy"
    },
    {
        "id": "461528",
        "name": "Manna University",
        "hyphenated": "manna-university"
    },
    {
        "id": "461537",
        "name": "Evolve Beauty Academy",
        "hyphenated": "evolve-beauty-academy"
    },
    {
        "id": "461555",
        "name": "Aveda Institute-New Mexico",
        "hyphenated": "aveda-institute-new-mexico"
    },
    {
        "id": "461573",
        "name": "American Trade School",
        "hyphenated": "american-trade-school"
    },
    {
        "id": "461582",
        "name": "Estelle Skin Care and Spa Institute",
        "hyphenated": "estelle-skin-care-and-spa-institute"
    },
    {
        "id": "461591",
        "name": "Paul Mitchell the School-Honolulu",
        "hyphenated": "paul-mitchell-the-school-honolulu"
    },
    {
        "id": "461607",
        "name": "Elite Cosmetology School",
        "hyphenated": "elite-cosmetology-school"
    },
    {
        "id": "461625",
        "name": "American Technical Institute",
        "hyphenated": "american-technical-institute"
    },
    {
        "id": "461643",
        "name": "Bergin University of Canine Studies",
        "hyphenated": "bergin-university-of-canine-studies"
    },
    {
        "id": "461652",
        "name": "Aveda Institute-Boise",
        "hyphenated": "aveda-institute-boise"
    },
    {
        "id": "461689",
        "name": "Pima Medical Institute-Aurora",
        "hyphenated": "pima-medical-institute-aurora"
    },
    {
        "id": "461704",
        "name": "Capilo School of Hair Design",
        "hyphenated": "capilo-school-of-hair-design"
    },
    {
        "id": "461713",
        "name": "Pure Aesthetics Natural Skincare School",
        "hyphenated": "pure-aesthetics-natural-skincare-school"
    },
    {
        "id": "461722",
        "name": "Lindsey Institute of Cosmetology",
        "hyphenated": "lindsey-institute-of-cosmetology"
    },
    {
        "id": "461740",
        "name": "Buckner Barber School",
        "hyphenated": "buckner-barber-school"
    },
    {
        "id": "461759",
        "name": "Simmons College of Kentucky",
        "hyphenated": "simmons-college-of-kentucky"
    },
    {
        "id": "461768",
        "name": "Atelier Esthetique Institute of Esthetics",
        "hyphenated": "atelier-esthetique-institute-of-esthetics"
    },
    {
        "id": "461786",
        "name": "Advanced College of Cosmetology",
        "hyphenated": "advanced-college-of-cosmetology"
    },
    {
        "id": "461795",
        "name": "North American University",
        "hyphenated": "north-american-university"
    },
    {
        "id": "461810",
        "name": "United Beauty College",
        "hyphenated": "united-beauty-college"
    },
    {
        "id": "461829",
        "name": "Cinta Aveda Institute",
        "hyphenated": "cinta-aveda-institute"
    },
    {
        "id": "461838",
        "name": "Long Island Nail Skin & Hair Institute",
        "hyphenated": "long-island-nail-skin-&-hair-institute"
    },
    {
        "id": "461847",
        "name": "Keser Torah-Mayan Hatalmud",
        "hyphenated": "keser-torah-mayan-hatalmud"
    },
    {
        "id": "461856",
        "name": "Southern California Health Institute",
        "hyphenated": "southern-california-health-institute"
    },
    {
        "id": "461865",
        "name": "Best Care College",
        "hyphenated": "best-care-college"
    },
    {
        "id": "461874",
        "name": "First Class Cosmetology School",
        "hyphenated": "first-class-cosmetology-school"
    },
    {
        "id": "461883",
        "name": "Millennia Atlantic University",
        "hyphenated": "millennia-atlantic-university"
    },
    {
        "id": "461892",
        "name": "Abcott Institute",
        "hyphenated": "abcott-institute"
    },
    {
        "id": "461908",
        "name": "The Salon Professional Academy-Kenosha",
        "hyphenated": "the-salon-professional-academy-kenosha"
    },
    {
        "id": "461917",
        "name": "Paul Mitchell the School-Indianapolis",
        "hyphenated": "paul-mitchell-the-school-indianapolis"
    },
    {
        "id": "461926",
        "name": "Taylor Andrews Academy-St George",
        "hyphenated": "taylor-andrews-academy-st-george"
    },
    {
        "id": "461944",
        "name": "Protege Academy",
        "hyphenated": "protege-academy"
    },
    {
        "id": "461953",
        "name": "Colorado Academy of Veterinary Technology",
        "hyphenated": "colorado-academy-of-veterinary-technology"
    },
    {
        "id": "461962",
        "name": "Northeast Technical Institute",
        "hyphenated": "northeast-technical-institute"
    },
    {
        "id": "461999",
        "name": "Elite School of Cosmetology",
        "hyphenated": "elite-school-of-cosmetology"
    },
    {
        "id": "462008",
        "name": "Paul Mitchell the School-Las Vegas",
        "hyphenated": "paul-mitchell-the-school-las-vegas"
    },
    {
        "id": "462017",
        "name": "Summit Salon Academy-Kokomo",
        "hyphenated": "summit-salon-academy-kokomo"
    },
    {
        "id": "462035",
        "name": "Florida Academy",
        "hyphenated": "florida-academy"
    },
    {
        "id": "462044",
        "name": "Institute for Doctoral Studies in the Visual Arts",
        "hyphenated": "institute-for-doctoral-studies-in-the-visual-arts"
    },
    {
        "id": "462053",
        "name": "State Career College",
        "hyphenated": "state-career-college"
    },
    {
        "id": "462062",
        "name": "Academy for Salon Professionals",
        "hyphenated": "academy-for-salon-professionals"
    },
    {
        "id": "462071",
        "name": "Paul Mitchell the School-Spokane",
        "hyphenated": "paul-mitchell-the-school-spokane"
    },
    {
        "id": "462336",
        "name": "International College of Beauty Arts & Sciences",
        "hyphenated": "international-college-of-beauty-arts-&-sciences"
    },
    {
        "id": "462345",
        "name": "The Salon Professional Academy",
        "hyphenated": "the-salon-professional-academy"
    },
    {
        "id": "462354",
        "name": "John Paul the Great Catholic University",
        "hyphenated": "john-paul-the-great-catholic-university"
    },
    {
        "id": "463056",
        "name": "University of Connecticut-Hartford Campus",
        "hyphenated": "university-of-connecticut-hartford-campus"
    },
    {
        "id": "464226",
        "name": "Ottawa University-Surprise",
        "hyphenated": "ottawa-university-surprise"
    },
    {
        "id": "466921",
        "name": "Chamberlain University-Missouri",
        "hyphenated": "chamberlain-university-missouri"
    },
    {
        "id": "466930",
        "name": "Chamberlain University-Texas",
        "hyphenated": "chamberlain-university-texas"
    },
    {
        "id": "467094",
        "name": "Continental School of Beauty Culture-Mattydale",
        "hyphenated": "continental-school-of-beauty-culture-mattydale"
    },
    {
        "id": "467368",
        "name": "Carrington College-Ontario",
        "hyphenated": "carrington-college-ontario"
    },
    {
        "id": "467863",
        "name": "Paul Mitchell the School-Columbia",
        "hyphenated": "paul-mitchell-the-school-columbia"
    },
    {
        "id": "467872",
        "name": "Paul Mitchell the School-Charleston",
        "hyphenated": "paul-mitchell-the-school-charleston"
    },
    {
        "id": "467906",
        "name": "Kenneth Shuler School of Cosmetology-Greenville",
        "hyphenated": "kenneth-shuler-school-of-cosmetology-greenville"
    },
    {
        "id": "468246",
        "name": "Northwest College-Eugene",
        "hyphenated": "northwest-college-eugene"
    },
    {
        "id": "468255",
        "name": "Northwest College-Tualatin",
        "hyphenated": "northwest-college-tualatin"
    },
    {
        "id": "468291",
        "name": "San Joaquin Valley College-Hanford Classroom",
        "hyphenated": "san-joaquin-valley-college-hanford-classroom"
    },
    {
        "id": "468769",
        "name": "CET-Soledad",
        "hyphenated": "cet-soledad"
    },
    {
        "id": "468848",
        "name": "UEI College-Oceanside",
        "hyphenated": "uei-college-oceanside"
    },
    {
        "id": "468893",
        "name": "Paul Mitchell the School-New Orleans",
        "hyphenated": "paul-mitchell-the-school-new-orleans"
    },
    {
        "id": "468909",
        "name": "UEI College-Garden Grove",
        "hyphenated": "uei-college-garden-grove"
    },
    {
        "id": "468963",
        "name": "New England Tractor Trailer Training School of Massachusetts",
        "hyphenated": "new-england-tractor-trailer-training-school-of-massachusetts"
    },
    {
        "id": "469416",
        "name": "Dewey University-Manati",
        "hyphenated": "dewey-university-manati"
    },
    {
        "id": "469610",
        "name": "Allen School-Phoenix",
        "hyphenated": "allen-school-phoenix"
    },
    {
        "id": "469629",
        "name": "Oklahoma Technical College",
        "hyphenated": "oklahoma-technical-college"
    },
    {
        "id": "469911",
        "name": "Unitech Training Academy-Alexandria",
        "hyphenated": "unitech-training-academy-alexandria"
    },
    {
        "id": "469957",
        "name": "Aveda Institute-Los Angeles",
        "hyphenated": "aveda-institute-los-angeles"
    },
    {
        "id": "470038",
        "name": "UEI College-Riverside",
        "hyphenated": "uei-college-riverside"
    },
    {
        "id": "470047",
        "name": "WellSpring School of Allied Health-Lawrence",
        "hyphenated": "wellspring-school-of-allied-health-lawrence"
    },
    {
        "id": "470296",
        "name": "Manuel and Theresa's School of Hair Design-Victoria",
        "hyphenated": "manuel-and-theresa's-school-of-hair-design-victoria"
    },
    {
        "id": "470393",
        "name": "Tricoci University of Beauty Culture-Chicago NE",
        "hyphenated": "tricoci-university-of-beauty-culture-chicago-ne"
    },
    {
        "id": "474863",
        "name": "Los Angeles Pacific University",
        "hyphenated": "los-angeles-pacific-university"
    },
    {
        "id": "474915",
        "name": "Avalon Institute-Phoenix",
        "hyphenated": "avalon-institute-phoenix"
    },
    {
        "id": "474924",
        "name": "Avalon Institute-Layton",
        "hyphenated": "avalon-institute-layton"
    },
    {
        "id": "475015",
        "name": "Aveda Institute-Des Moines",
        "hyphenated": "aveda-institute-des-moines"
    },
    {
        "id": "475024",
        "name": "Fortis Institute-Lawrenceville",
        "hyphenated": "fortis-institute-lawrenceville"
    },
    {
        "id": "475033",
        "name": "Relay Graduate School of Education",
        "hyphenated": "relay-graduate-school-of-education"
    },
    {
        "id": "475060",
        "name": "Miller-Motte College-Macon",
        "hyphenated": "miller-motte-college-macon"
    },
    {
        "id": "475121",
        "name": "South University-Savannah Online",
        "hyphenated": "south-university-savannah-online"
    },
    {
        "id": "475176",
        "name": "San Joaquin Valley College-Temecula",
        "hyphenated": "san-joaquin-valley-college-temecula"
    },
    {
        "id": "475200",
        "name": "Whitworth University-Adult Degree Programs",
        "hyphenated": "whitworth-university-adult-degree-programs"
    },
    {
        "id": "475228",
        "name": "The Santa Barbara and Ventura Colleges of Law at Santa Barbara",
        "hyphenated": "the-santa-barbara-and-ventura-colleges-of-law-at-santa-barbara"
    },
    {
        "id": "475273",
        "name": "Springfield College-Regional Online and Continuing Education",
        "hyphenated": "springfield-college-regional-online-and-continuing-education"
    },
    {
        "id": "475282",
        "name": "Marian Health Careers Center-Van Nuys Campus",
        "hyphenated": "marian-health-careers-center-van-nuys-campus"
    },
    {
        "id": "475325",
        "name": "Milan Institute-Las Vegas",
        "hyphenated": "milan-institute-las-vegas"
    },
    {
        "id": "475398",
        "name": "Catholic Distance University",
        "hyphenated": "catholic-distance-university"
    },
    {
        "id": "475404",
        "name": "New York School of Esthetics & Day Spa",
        "hyphenated": "new-york-school-of-esthetics-&-day-spa"
    },
    {
        "id": "475413",
        "name": "Panache Academy of Beauty",
        "hyphenated": "panache-academy-of-beauty"
    },
    {
        "id": "475422",
        "name": "Finger Lakes Health College of Nursing & Health Sciences",
        "hyphenated": "finger-lakes-health-college-of-nursing-&-health-sciences"
    },
    {
        "id": "475431",
        "name": "ASI Career Institute",
        "hyphenated": "asi-career-institute"
    },
    {
        "id": "475468",
        "name": "Christine Valmy International School of Esthetics & Cosmetology",
        "hyphenated": "christine-valmy-international-school-of-esthetics-&-cosmetology"
    },
    {
        "id": "475477",
        "name": "City College-Hollywood",
        "hyphenated": "city-college-hollywood"
    },
    {
        "id": "475486",
        "name": "Paul Mitchell the School-Arlington",
        "hyphenated": "paul-mitchell-the-school-arlington"
    },
    {
        "id": "475495",
        "name": "Rocky Mountain University of Health Professions",
        "hyphenated": "rocky-mountain-university-of-health-professions"
    },
    {
        "id": "475547",
        "name": "Paul Mitchell the School-Fort Myers",
        "hyphenated": "paul-mitchell-the-school-fort-myers"
    },
    {
        "id": "475556",
        "name": "Paul Mitchell the School-Raleigh",
        "hyphenated": "paul-mitchell-the-school-raleigh"
    },
    {
        "id": "475565",
        "name": "CUNY Stella and Charles Guttman Community College",
        "hyphenated": "cuny-stella-and-charles-guttman-community-college"
    },
    {
        "id": "475574",
        "name": "Lil Lou's Beauty and Barber College",
        "hyphenated": "lil-lou's-beauty-and-barber-college"
    },
    {
        "id": "475608",
        "name": "Criswell College",
        "hyphenated": "criswell-college"
    },
    {
        "id": "475635",
        "name": "Beverly Hills Design Institute",
        "hyphenated": "beverly-hills-design-institute"
    },
    {
        "id": "475653",
        "name": "Reflections Academy of Beauty",
        "hyphenated": "reflections-academy-of-beauty"
    },
    {
        "id": "475662",
        "name": "UCAS University of Cosmetology Arts & Sciences-La Joya",
        "hyphenated": "ucas-university-of-cosmetology-arts-&-sciences-la-joya"
    },
    {
        "id": "475705",
        "name": "Annenberg School of Nursing",
        "hyphenated": "annenberg-school-of-nursing"
    },
    {
        "id": "475714",
        "name": "American Medical Academy",
        "hyphenated": "american-medical-academy"
    },
    {
        "id": "475723",
        "name": "Tooele Technical College",
        "hyphenated": "tooele-technical-college"
    },
    {
        "id": "475732",
        "name": "Chamberlain University-Georgia",
        "hyphenated": "chamberlain-university-georgia"
    },
    {
        "id": "475741",
        "name": "Chamberlain University-Indiana",
        "hyphenated": "chamberlain-university-indiana"
    },
    {
        "id": "475839",
        "name": "Sessions College for Professional Design",
        "hyphenated": "sessions-college-for-professional-design"
    },
    {
        "id": "476063",
        "name": "Tulsa Welding School-Jacksonville",
        "hyphenated": "tulsa-welding-school-jacksonville"
    },
    {
        "id": "476179",
        "name": "Empire Beauty School-Virginia Beach",
        "hyphenated": "empire-beauty-school-virginia-beach"
    },
    {
        "id": "476230",
        "name": "Ross Medical Education Center-Morgantown",
        "hyphenated": "ross-medical-education-center-morgantown"
    },
    {
        "id": "476294",
        "name": "Paul Mitchell the School-Ogden",
        "hyphenated": "paul-mitchell-the-school-ogden"
    },
    {
        "id": "476355",
        "name": "Miami Ad School-New York",
        "hyphenated": "miami-ad-school-new-york"
    },
    {
        "id": "476489",
        "name": "Larry's Barber College",
        "hyphenated": "larry's-barber-college"
    },
    {
        "id": "476498",
        "name": "Futura Career Institute",
        "hyphenated": "futura-career-institute"
    },
    {
        "id": "476513",
        "name": "Flair Beauty College",
        "hyphenated": "flair-beauty-college"
    },
    {
        "id": "476540",
        "name": "Vogue College of Cosmetology-San Antonio Fredericksburg",
        "hyphenated": "vogue-college-of-cosmetology-san-antonio-fredericksburg"
    },
    {
        "id": "476559",
        "name": "Vogue College of Cosmetology",
        "hyphenated": "vogue-college-of-cosmetology"
    },
    {
        "id": "476568",
        "name": "Tomorrow's Image Barber And Beauty Academy of Virginia",
        "hyphenated": "tomorrow's-image-barber-and-beauty-academy-of-virginia"
    },
    {
        "id": "476595",
        "name": "Medical Allied Career Center",
        "hyphenated": "medical-allied-career-center"
    },
    {
        "id": "476601",
        "name": "Bais HaMedrash and Mesivta of Baltimore",
        "hyphenated": "bais-hamedrash-and-mesivta-of-baltimore"
    },
    {
        "id": "476610",
        "name": "Barber & Beauty Institute of New York",
        "hyphenated": "barber-&-beauty-institute-of-new-york"
    },
    {
        "id": "476629",
        "name": "Salon Institute-Toledo Campus",
        "hyphenated": "salon-institute-toledo-campus"
    },
    {
        "id": "476683",
        "name": "Athena Career Academy",
        "hyphenated": "athena-career-academy"
    },
    {
        "id": "476692",
        "name": "Yeshiva Gedolah Zichron Leyma",
        "hyphenated": "yeshiva-gedolah-zichron-leyma"
    },
    {
        "id": "476708",
        "name": "The Barber School",
        "hyphenated": "the-barber-school"
    },
    {
        "id": "476717",
        "name": "Be'er Yaakov Talmudic Seminary",
        "hyphenated": "be'er-yaakov-talmudic-seminary"
    },
    {
        "id": "476726",
        "name": "Rio Grande Valley College",
        "hyphenated": "rio-grande-valley-college"
    },
    {
        "id": "476735",
        "name": "Alexander Academy",
        "hyphenated": "alexander-academy"
    },
    {
        "id": "476753",
        "name": "Aveda Institute-Tucson",
        "hyphenated": "aveda-institute-tucson"
    },
    {
        "id": "476780",
        "name": "Diamond Beauty College",
        "hyphenated": "diamond-beauty-college"
    },
    {
        "id": "476799",
        "name": "Unitek College",
        "hyphenated": "unitek-college"
    },
    {
        "id": "476805",
        "name": "University Academy of Hair Design",
        "hyphenated": "university-academy-of-hair-design"
    },
    {
        "id": "476814",
        "name": "Florida Institute of Recording Sound and Technology",
        "hyphenated": "florida-institute-of-recording-sound-and-technology"
    },
    {
        "id": "476841",
        "name": "Paul Mitchell the School-Reno",
        "hyphenated": "paul-mitchell-the-school-reno"
    },
    {
        "id": "476850",
        "name": "Boise Barber College",
        "hyphenated": "boise-barber-college"
    },
    {
        "id": "476869",
        "name": "Austin Kade Academy",
        "hyphenated": "austin-kade-academy"
    },
    {
        "id": "476878",
        "name": "Aesthetic Science Institute",
        "hyphenated": "aesthetic-science-institute"
    },
    {
        "id": "476887",
        "name": "Elaine Sterling Institute",
        "hyphenated": "elaine-sterling-institute"
    },
    {
        "id": "476896",
        "name": "Wade Gordon Hairdressing Academy",
        "hyphenated": "wade-gordon-hairdressing-academy"
    },
    {
        "id": "476902",
        "name": "Mitsu Sato Hair Academy",
        "hyphenated": "mitsu-sato-hair-academy"
    },
    {
        "id": "476939",
        "name": "FVI School of Nursing and Technology",
        "hyphenated": "fvi-school-of-nursing-and-technology"
    },
    {
        "id": "476948",
        "name": "SAE Institute of Technology-Atlanta",
        "hyphenated": "sae-institute-of-technology-atlanta"
    },
    {
        "id": "476957",
        "name": "Academy di Firenze",
        "hyphenated": "academy-di-firenze"
    },
    {
        "id": "476966",
        "name": "SAE Institute of Technology-Miami",
        "hyphenated": "sae-institute-of-technology-miami"
    },
    {
        "id": "476975",
        "name": "Colorado State University Global",
        "hyphenated": "colorado-state-university-global"
    },
    {
        "id": "476984",
        "name": "Paul Mitchell the School-Overland Park",
        "hyphenated": "paul-mitchell-the-school-overland-park"
    },
    {
        "id": "476993",
        "name": "Medspa Academies",
        "hyphenated": "medspa-academies"
    },
    {
        "id": "477002",
        "name": "Brighton Institute of Cosmetology",
        "hyphenated": "brighton-institute-of-cosmetology"
    },
    {
        "id": "477039",
        "name": "West Coast University-Dallas",
        "hyphenated": "west-coast-university-dallas"
    },
    {
        "id": "478582",
        "name": "Empire Beauty School-Savannah",
        "hyphenated": "empire-beauty-school-savannah"
    },
    {
        "id": "478591",
        "name": "Ogle School Hair Skin Nails-Denton",
        "hyphenated": "ogle-school-hair-skin-nails-denton"
    },
    {
        "id": "478616",
        "name": "Empire Beauty School-West Greensboro",
        "hyphenated": "empire-beauty-school-west-greensboro"
    },
    {
        "id": "478917",
        "name": "Hays Academy of Hair Design",
        "hyphenated": "hays-academy-of-hair-design"
    },
    {
        "id": "478953",
        "name": "Bellus Academy",
        "hyphenated": "bellus-academy"
    },
    {
        "id": "479062",
        "name": "MotoRing Technical Training Institute",
        "hyphenated": "motoring-technical-training-institute"
    },
    {
        "id": "479424",
        "name": "Unitek College",
        "hyphenated": "unitek-college"
    },
    {
        "id": "479965",
        "name": "Medical Career Institute",
        "hyphenated": "medical-career-institute"
    },
    {
        "id": "479974",
        "name": "Advance Beauty Techs Academy",
        "hyphenated": "advance-beauty-techs-academy"
    },
    {
        "id": "479983",
        "name": "Elevate Salon Institute-Westminster",
        "hyphenated": "elevate-salon-institute-westminster"
    },
    {
        "id": "479992",
        "name": "Brand College",
        "hyphenated": "brand-college"
    },
    {
        "id": "480000",
        "name": "Hinton Barber and Beauty College",
        "hyphenated": "hinton-barber-and-beauty-college"
    },
    {
        "id": "480019",
        "name": "Advanced Career Institute",
        "hyphenated": "advanced-career-institute"
    },
    {
        "id": "480028",
        "name": "Digital Film Academy",
        "hyphenated": "digital-film-academy"
    },
    {
        "id": "480037",
        "name": "MyComputerCareer at Raleigh",
        "hyphenated": "mycomputercareer-at-raleigh"
    },
    {
        "id": "480073",
        "name": "South University-Austin",
        "hyphenated": "south-university-austin"
    },
    {
        "id": "480091",
        "name": "Bryant & Stratton College-Online",
        "hyphenated": "bryant-&-stratton-college-online"
    },
    {
        "id": "480125",
        "name": "Ogle School Hair Skin Nails-San Antonio",
        "hyphenated": "ogle-school-hair-skin-nails-san-antonio"
    },
    {
        "id": "480161",
        "name": "Ideal Beauty Academy",
        "hyphenated": "ideal-beauty-academy"
    },
    {
        "id": "480198",
        "name": "Warner Pacific University Professional and Graduate Studies",
        "hyphenated": "warner-pacific-university-professional-and-graduate-studies"
    },
    {
        "id": "480204",
        "name": "Platt College-Riverside",
        "hyphenated": "platt-college-riverside"
    },
    {
        "id": "480310",
        "name": "Empire Beauty School-Glen Burnie",
        "hyphenated": "empire-beauty-school-glen-burnie"
    },
    {
        "id": "480347",
        "name": "International School of Cosmetology",
        "hyphenated": "international-school-of-cosmetology"
    },
    {
        "id": "480471",
        "name": "UEI College-Stockton",
        "hyphenated": "uei-college-stockton"
    },
    {
        "id": "480523",
        "name": "Ross Medical Education Center-Dayton",
        "hyphenated": "ross-medical-education-center-dayton"
    },
    {
        "id": "480532",
        "name": "Ross Medical Education Center-Ontario",
        "hyphenated": "ross-medical-education-center-ontario"
    },
    {
        "id": "480550",
        "name": "Ross Medical Education Center-Bowling Green",
        "hyphenated": "ross-medical-education-center-bowling-green"
    },
    {
        "id": "480569",
        "name": "Florida Institute of Technology-Online",
        "hyphenated": "florida-institute-of-technology-online"
    },
    {
        "id": "480657",
        "name": "Rasmussen University-Kansas",
        "hyphenated": "rasmussen-university-kansas"
    },
    {
        "id": "480693",
        "name": "Columbia Institute",
        "hyphenated": "columbia-institute"
    },
    {
        "id": "480727",
        "name": "School of Missionary Aviation Technology",
        "hyphenated": "school-of-missionary-aviation-technology"
    },
    {
        "id": "480781",
        "name": "California Miramar University",
        "hyphenated": "california-miramar-university"
    },
    {
        "id": "480790",
        "name": "Rocky Vista University",
        "hyphenated": "rocky-vista-university"
    },
    {
        "id": "480824",
        "name": "The College of Health Care Professions-Dallas",
        "hyphenated": "the-college-of-health-care-professions-dallas"
    },
    {
        "id": "480833",
        "name": "The College of Health Care Professions-Fort Worth",
        "hyphenated": "the-college-of-health-care-professions-fort-worth"
    },
    {
        "id": "480842",
        "name": "Shear Finesse Beauty Academy",
        "hyphenated": "shear-finesse-beauty-academy"
    },
    {
        "id": "480851",
        "name": "Miami Media School",
        "hyphenated": "miami-media-school"
    },
    {
        "id": "480879",
        "name": "Academy of Salon Professionals",
        "hyphenated": "academy-of-salon-professionals"
    },
    {
        "id": "480888",
        "name": "CyberTex Institute of Technology",
        "hyphenated": "cybertex-institute-of-technology"
    },
    {
        "id": "480903",
        "name": "New England Tractor Trailer Training School of CT-Bridgeport",
        "hyphenated": "new-england-tractor-trailer-training-school-of-ct-bridgeport"
    },
    {
        "id": "480912",
        "name": "Gemini School of Visual Arts & Communication",
        "hyphenated": "gemini-school-of-visual-arts-&-communication"
    },
    {
        "id": "480921",
        "name": "Designer Barber & Stylist School",
        "hyphenated": "designer-barber-&-stylist-school"
    },
    {
        "id": "480930",
        "name": "Paul Mitchell the School-Woodbridge",
        "hyphenated": "paul-mitchell-the-school-woodbridge"
    },
    {
        "id": "480967",
        "name": "College of the Muscogee Nation",
        "hyphenated": "college-of-the-muscogee-nation"
    },
    {
        "id": "480976",
        "name": "The Salon Professional Academy-Huntsville",
        "hyphenated": "the-salon-professional-academy-huntsville"
    },
    {
        "id": "480985",
        "name": "Midwives College of Utah",
        "hyphenated": "midwives-college-of-utah"
    },
    {
        "id": "480994",
        "name": "Aspen Beauty Academy of Laurel",
        "hyphenated": "aspen-beauty-academy-of-laurel"
    },
    {
        "id": "481003",
        "name": "M T Training Center",
        "hyphenated": "m-t-training-center"
    },
    {
        "id": "481021",
        "name": "Berks Career & Technology Center",
        "hyphenated": "berks-career-&-technology-center"
    },
    {
        "id": "481030",
        "name": "Future Generations University",
        "hyphenated": "future-generations-university"
    },
    {
        "id": "481049",
        "name": "Carthage R9 School District-Carthage Technical Center",
        "hyphenated": "carthage-r9-school-district-carthage-technical-center"
    },
    {
        "id": "481058",
        "name": "Grace Mission University",
        "hyphenated": "grace-mission-university"
    },
    {
        "id": "481085",
        "name": "Verve College",
        "hyphenated": "verve-college"
    },
    {
        "id": "481094",
        "name": "Sotheby's Institute of Art-NY",
        "hyphenated": "sotheby's-institute-of-art-ny"
    },
    {
        "id": "481100",
        "name": "Top Nails & Hair Beauty School",
        "hyphenated": "top-nails-&-hair-beauty-school"
    },
    {
        "id": "481128",
        "name": "Santa Ana Beauty College",
        "hyphenated": "santa-ana-beauty-college"
    },
    {
        "id": "481137",
        "name": "Shepherds College",
        "hyphenated": "shepherds-college"
    },
    {
        "id": "481146",
        "name": "Woodruff Medical and Wellness Training",
        "hyphenated": "woodruff-medical-and-wellness-training"
    },
    {
        "id": "481155",
        "name": "Helms College",
        "hyphenated": "helms-college"
    },
    {
        "id": "481182",
        "name": "Ukiah Adult School",
        "hyphenated": "ukiah-adult-school"
    },
    {
        "id": "481191",
        "name": "Riverside County Office of Education-School of Career Education",
        "hyphenated": "riverside-county-office-of-education-school-of-career-education"
    },
    {
        "id": "481207",
        "name": "Institute of Health Sciences",
        "hyphenated": "institute-of-health-sciences"
    },
    {
        "id": "481225",
        "name": "Mid-South Christian College",
        "hyphenated": "mid-south-christian-college"
    },
    {
        "id": "481243",
        "name": "New York Institute of Beauty",
        "hyphenated": "new-york-institute-of-beauty"
    },
    {
        "id": "481252",
        "name": "Ultrasound Medical Institute",
        "hyphenated": "ultrasound-medical-institute"
    },
    {
        "id": "481289",
        "name": "Xavier College School of Nursing",
        "hyphenated": "xavier-college-school-of-nursing"
    },
    {
        "id": "481298",
        "name": "Lawrence & Company College of Cosmetology",
        "hyphenated": "lawrence-&-company-college-of-cosmetology"
    },
    {
        "id": "481313",
        "name": "Kaizen Beauty Academy",
        "hyphenated": "kaizen-beauty-academy"
    },
    {
        "id": "481322",
        "name": "Southern Texas Careers Academy",
        "hyphenated": "southern-texas-careers-academy"
    },
    {
        "id": "481331",
        "name": "Sharp Edgez Barber Institute",
        "hyphenated": "sharp-edgez-barber-institute"
    },
    {
        "id": "481340",
        "name": "The Salon Professional Academy-Nashville",
        "hyphenated": "the-salon-professional-academy-nashville"
    },
    {
        "id": "481368",
        "name": "Prestige Health & Beauty Sciences Academy",
        "hyphenated": "prestige-health-&-beauty-sciences-academy"
    },
    {
        "id": "481386",
        "name": "California Career Institute",
        "hyphenated": "california-career-institute"
    },
    {
        "id": "481401",
        "name": "Grace School of Theology",
        "hyphenated": "grace-school-of-theology"
    },
    {
        "id": "481410",
        "name": "Yeshiva Gedolah Kesser Torah",
        "hyphenated": "yeshiva-gedolah-kesser-torah"
    },
    {
        "id": "481429",
        "name": "Universal Training Institute",
        "hyphenated": "universal-training-institute"
    },
    {
        "id": "481438",
        "name": "Yeshiva Yesodei Hatorah",
        "hyphenated": "yeshiva-yesodei-hatorah"
    },
    {
        "id": "481447",
        "name": "Center for the Healing Arts",
        "hyphenated": "center-for-the-healing-arts"
    },
    {
        "id": "481456",
        "name": "Bonnie Joseph Academy of Cosmetology & Barbering",
        "hyphenated": "bonnie-joseph-academy-of-cosmetology-&-barbering"
    },
    {
        "id": "481465",
        "name": "Healthcare Training Institute",
        "hyphenated": "healthcare-training-institute"
    },
    {
        "id": "481474",
        "name": "J D Academy of Salon and Spa",
        "hyphenated": "j-d-academy-of-salon-and-spa"
    },
    {
        "id": "481483",
        "name": "Boca Beauty Academy",
        "hyphenated": "boca-beauty-academy"
    },
    {
        "id": "481508",
        "name": "Paul Mitchell the School-Jersey Shore",
        "hyphenated": "paul-mitchell-the-school-jersey-shore"
    },
    {
        "id": "481517",
        "name": "City Pointe Beauty Academy",
        "hyphenated": "city-pointe-beauty-academy"
    },
    {
        "id": "481526",
        "name": "The Chrysm Institute of Esthetics",
        "hyphenated": "the-chrysm-institute-of-esthetics"
    },
    {
        "id": "481535",
        "name": "Sanford Burnham Prebys Medical Discovery Institute",
        "hyphenated": "sanford-burnham-prebys-medical-discovery-institute"
    },
    {
        "id": "481571",
        "name": "Belle Academy of Cosmetology LLC",
        "hyphenated": "belle-academy-of-cosmetology-llc"
    },
    {
        "id": "481678",
        "name": "Empire Beauty School-Augusta",
        "hyphenated": "empire-beauty-school-augusta"
    },
    {
        "id": "481720",
        "name": "South University-High Point",
        "hyphenated": "south-university-high-point"
    },
    {
        "id": "481739",
        "name": "Empire Beauty School-Vernon Hills",
        "hyphenated": "empire-beauty-school-vernon-hills"
    },
    {
        "id": "481748",
        "name": "Empire Beauty School-Stone Park",
        "hyphenated": "empire-beauty-school-stone-park"
    },
    {
        "id": "481775",
        "name": "San Joaquin Valley College-Lancaster",
        "hyphenated": "san-joaquin-valley-college-lancaster"
    },
    {
        "id": "481845",
        "name": "Empire Beauty School-Rochester",
        "hyphenated": "empire-beauty-school-rochester"
    },
    {
        "id": "481863",
        "name": "Ross Medical Education Center-Kokomo",
        "hyphenated": "ross-medical-education-center-kokomo"
    },
    {
        "id": "481890",
        "name": "Ross Medical Education Center-Erlanger",
        "hyphenated": "ross-medical-education-center-erlanger"
    },
    {
        "id": "481906",
        "name": "Ross Medical Education Center-Charleston",
        "hyphenated": "ross-medical-education-center-charleston"
    },
    {
        "id": "481960",
        "name": "InterCoast Colleges-Fairfield",
        "hyphenated": "intercoast-colleges-fairfield"
    },
    {
        "id": "482015",
        "name": "Southern Careers Institute-San Antonio",
        "hyphenated": "southern-careers-institute-san-antonio"
    },
    {
        "id": "482060",
        "name": "Milan Institute-Merced",
        "hyphenated": "milan-institute-merced"
    },
    {
        "id": "482097",
        "name": "Midwest Technical Institute-Missouri",
        "hyphenated": "midwest-technical-institute-missouri"
    },
    {
        "id": "482149",
        "name": "Augusta University",
        "hyphenated": "augusta-university"
    },
    {
        "id": "482158",
        "name": "Middle Georgia State University",
        "hyphenated": "middle-georgia-state-university"
    },
    {
        "id": "482167",
        "name": "Trenz Beauty Academy",
        "hyphenated": "trenz-beauty-academy"
    },
    {
        "id": "482176",
        "name": "Paul Mitchell the School-Denver",
        "hyphenated": "paul-mitchell-the-school-denver"
    },
    {
        "id": "482185",
        "name": "Academy of Interactive Entertainment",
        "hyphenated": "academy-of-interactive-entertainment"
    },
    {
        "id": "482194",
        "name": "Arrojo Cosmetology School",
        "hyphenated": "arrojo-cosmetology-school"
    },
    {
        "id": "482200",
        "name": "Cosmetology Academy of Texarkana",
        "hyphenated": "cosmetology-academy-of-texarkana"
    },
    {
        "id": "482228",
        "name": "Veritas Baptist College",
        "hyphenated": "veritas-baptist-college"
    },
    {
        "id": "482246",
        "name": "Lynnes Welding Training",
        "hyphenated": "lynnes-welding-training"
    },
    {
        "id": "482255",
        "name": "Aviation Institute of Maintenance-Las Vegas",
        "hyphenated": "aviation-institute-of-maintenance-las-vegas"
    },
    {
        "id": "482291",
        "name": "UEI College-Bakersfield",
        "hyphenated": "uei-college-bakersfield"
    },
    {
        "id": "482413",
        "name": "DeVry College of New York",
        "hyphenated": "devry-college-of-new-york"
    },
    {
        "id": "482422",
        "name": "DeVry University-Arizona",
        "hyphenated": "devry-university-arizona"
    },
    {
        "id": "482431",
        "name": "DeVry University-California",
        "hyphenated": "devry-university-california"
    },
    {
        "id": "482440",
        "name": "DeVry University-Colorado",
        "hyphenated": "devry-university-colorado"
    },
    {
        "id": "482459",
        "name": "DeVry University-Florida",
        "hyphenated": "devry-university-florida"
    },
    {
        "id": "482468",
        "name": "DeVry University-Georgia",
        "hyphenated": "devry-university-georgia"
    },
    {
        "id": "482477",
        "name": "DeVry University-Illinois",
        "hyphenated": "devry-university-illinois"
    },
    {
        "id": "482538",
        "name": "DeVry University-Missouri",
        "hyphenated": "devry-university-missouri"
    },
    {
        "id": "482547",
        "name": "DeVry University-Nevada",
        "hyphenated": "devry-university-nevada"
    },
    {
        "id": "482556",
        "name": "DeVry University-New Jersey",
        "hyphenated": "devry-university-new-jersey"
    },
    {
        "id": "482565",
        "name": "DeVry University-North Carolina",
        "hyphenated": "devry-university-north-carolina"
    },
    {
        "id": "482574",
        "name": "DeVry University-Ohio",
        "hyphenated": "devry-university-ohio"
    },
    {
        "id": "482608",
        "name": "DeVry University-Pennsylvania",
        "hyphenated": "devry-university-pennsylvania"
    },
    {
        "id": "482617",
        "name": "DeVry University-Tennessee",
        "hyphenated": "devry-university-tennessee"
    },
    {
        "id": "482635",
        "name": "DeVry University-Texas",
        "hyphenated": "devry-university-texas"
    },
    {
        "id": "482653",
        "name": "DeVry University-Virginia",
        "hyphenated": "devry-university-virginia"
    },
    {
        "id": "482680",
        "name": "University of North Georgia",
        "hyphenated": "university-of-north-georgia"
    },
    {
        "id": "482699",
        "name": "South Georgia State College",
        "hyphenated": "south-georgia-state-college"
    },
    {
        "id": "482705",
        "name": "Northeastern University Professional Programs",
        "hyphenated": "northeastern-university-professional-programs"
    },
    {
        "id": "482936",
        "name": "Florida Polytechnic University",
        "hyphenated": "florida-polytechnic-university"
    },
    {
        "id": "482963",
        "name": "American Institute-Toms River",
        "hyphenated": "american-institute-toms-river"
    },
    {
        "id": "482981",
        "name": "Alamo City Barber College",
        "hyphenated": "alamo-city-barber-college"
    },
    {
        "id": "482990",
        "name": "Arizona College-Mesa",
        "hyphenated": "arizona-college-mesa"
    },
    {
        "id": "483009",
        "name": "American College of Healthcare and Technology",
        "hyphenated": "american-college-of-healthcare-and-technology"
    },
    {
        "id": "483018",
        "name": "Antioch College",
        "hyphenated": "antioch-college"
    },
    {
        "id": "483036",
        "name": "Texas A&M University-Central Texas",
        "hyphenated": "texas-a&m-university-central-texas"
    },
    {
        "id": "483045",
        "name": "Central Georgia Technical College",
        "hyphenated": "central-georgia-technical-college"
    },
    {
        "id": "483054",
        "name": "Barber School of Pittsburgh",
        "hyphenated": "barber-school-of-pittsburgh"
    },
    {
        "id": "483106",
        "name": "Innovate Salon Academy",
        "hyphenated": "innovate-salon-academy"
    },
    {
        "id": "483124",
        "name": "Arizona State University Digital Immersion",
        "hyphenated": "arizona-state-university-digital-immersion"
    },
    {
        "id": "483212",
        "name": "Louisiana Delta Community College",
        "hyphenated": "louisiana-delta-community-college"
    },
    {
        "id": "483230",
        "name": "The Fab School",
        "hyphenated": "the-fab-school"
    },
    {
        "id": "483258",
        "name": "Networks Barber College",
        "hyphenated": "networks-barber-college"
    },
    {
        "id": "483276",
        "name": "JB's Hair Design and Barber College",
        "hyphenated": "jb's-hair-design-and-barber-college"
    },
    {
        "id": "483328",
        "name": "Paul Mitchell the School-Lombard",
        "hyphenated": "paul-mitchell-the-school-lombard"
    },
    {
        "id": "483337",
        "name": "Paul Mitchell the School-Greenville",
        "hyphenated": "paul-mitchell-the-school-greenville"
    },
    {
        "id": "483346",
        "name": "Barber and Beauty Academy of Pennsylvania",
        "hyphenated": "barber-and-beauty-academy-of-pennsylvania"
    },
    {
        "id": "483355",
        "name": "Delta College of Arts & Technology-Lafayette Campus",
        "hyphenated": "delta-college-of-arts-&-technology-lafayette-campus"
    },
    {
        "id": "483364",
        "name": "Delta College-Slidell Campus",
        "hyphenated": "delta-college-slidell-campus"
    },
    {
        "id": "483373",
        "name": "Integrity College of Health",
        "hyphenated": "integrity-college-of-health"
    },
    {
        "id": "483382",
        "name": "Electrical and HVAC/R Training Center",
        "hyphenated": "electrical-and-hvac/r-training-center"
    },
    {
        "id": "483425",
        "name": "Alexander Paul Institute of Hair Design",
        "hyphenated": "alexander-paul-institute-of-hair-design"
    },
    {
        "id": "483443",
        "name": "California Technical Academy",
        "hyphenated": "california-technical-academy"
    },
    {
        "id": "483470",
        "name": "More Tech Institute",
        "hyphenated": "more-tech-institute"
    },
    {
        "id": "483504",
        "name": "Dorsey College-Dearborn",
        "hyphenated": "dorsey-college-dearborn"
    },
    {
        "id": "483513",
        "name": "Neecee's Barber College",
        "hyphenated": "neecee's-barber-college"
    },
    {
        "id": "483559",
        "name": "Bella Cosmetology and Barber College",
        "hyphenated": "bella-cosmetology-and-barber-college"
    },
    {
        "id": "483577",
        "name": "Franklin Hair Academy School of Cosmetology",
        "hyphenated": "franklin-hair-academy-school-of-cosmetology"
    },
    {
        "id": "483595",
        "name": "Ana G. Mendez University",
        "hyphenated": "ana-g.-mendez-university"
    },
    {
        "id": "483647",
        "name": "Ohio Institute of Allied Health",
        "hyphenated": "ohio-institute-of-allied-health"
    },
    {
        "id": "483708",
        "name": "Access Careers",
        "hyphenated": "access-careers"
    },
    {
        "id": "483726",
        "name": "Luckes Beauty Academy LLC",
        "hyphenated": "luckes-beauty-academy-llc"
    },
    {
        "id": "483735",
        "name": "Total Transformation Institute of Cosmetology",
        "hyphenated": "total-transformation-institute-of-cosmetology"
    },
    {
        "id": "483744",
        "name": "Vibe Barber College",
        "hyphenated": "vibe-barber-college"
    },
    {
        "id": "483753",
        "name": "Champ's Barber School",
        "hyphenated": "champ's-barber-school"
    },
    {
        "id": "483780",
        "name": "IGlobal University",
        "hyphenated": "iglobal-university"
    },
    {
        "id": "483799",
        "name": "East-West Healing Arts Institute",
        "hyphenated": "east-west-healing-arts-institute"
    },
    {
        "id": "483814",
        "name": "Standard Healthcare Services-College of Nursing",
        "hyphenated": "standard-healthcare-services-college-of-nursing"
    },
    {
        "id": "483823",
        "name": "Philadelphia Technician Training",
        "hyphenated": "philadelphia-technician-training"
    },
    {
        "id": "483841",
        "name": "Grace International Beauty School",
        "hyphenated": "grace-international-beauty-school"
    },
    {
        "id": "483850",
        "name": "Alliance Computing Solutions",
        "hyphenated": "alliance-computing-solutions"
    },
    {
        "id": "483869",
        "name": "InterAmerican Technical Institute",
        "hyphenated": "interamerican-technical-institute"
    },
    {
        "id": "483878",
        "name": "Bay Area Medical Academy",
        "hyphenated": "bay-area-medical-academy"
    },
    {
        "id": "483887",
        "name": "Mind Body Institute",
        "hyphenated": "mind-body-institute"
    },
    {
        "id": "483896",
        "name": "Parisian Spa Institute",
        "hyphenated": "parisian-spa-institute"
    },
    {
        "id": "483911",
        "name": "Creative Touch Cosmetology School",
        "hyphenated": "creative-touch-cosmetology-school"
    },
    {
        "id": "483920",
        "name": "LaBarberia Institute of Hair",
        "hyphenated": "labarberia-institute-of-hair"
    },
    {
        "id": "483948",
        "name": "Bos-Man's Barber College",
        "hyphenated": "bos-man's-barber-college"
    },
    {
        "id": "483957",
        "name": "Lawrence & Company College of Cosmetology",
        "hyphenated": "lawrence-&-company-college-of-cosmetology"
    },
    {
        "id": "483975",
        "name": "Alabama College of Osteopathic Medicine",
        "hyphenated": "alabama-college-of-osteopathic-medicine"
    },
    {
        "id": "484002",
        "name": "High Desert Medical College",
        "hyphenated": "high-desert-medical-college"
    },
    {
        "id": "484011",
        "name": "Paul Mitchell the School-Schenectady",
        "hyphenated": "paul-mitchell-the-school-schenectady"
    },
    {
        "id": "484020",
        "name": "Beyond Measure Barbering Institute",
        "hyphenated": "beyond-measure-barbering-institute"
    },
    {
        "id": "484039",
        "name": "McDougle Technical Institute",
        "hyphenated": "mcdougle-technical-institute"
    },
    {
        "id": "484048",
        "name": "Barber Institute of Texas",
        "hyphenated": "barber-institute-of-texas"
    },
    {
        "id": "484057",
        "name": "Jupiter Beauty Academy",
        "hyphenated": "jupiter-beauty-academy"
    },
    {
        "id": "484066",
        "name": "MedQuest College",
        "hyphenated": "medquest-college"
    },
    {
        "id": "484075",
        "name": "First Coast Barber Academy",
        "hyphenated": "first-coast-barber-academy"
    },
    {
        "id": "484084",
        "name": "Debutantes School of Cosmetology and Nail Technology",
        "hyphenated": "debutantes-school-of-cosmetology-and-nail-technology"
    },
    {
        "id": "484093",
        "name": "Paul Mitchell the School-Farmington Hills",
        "hyphenated": "paul-mitchell-the-school-farmington-hills"
    },
    {
        "id": "484109",
        "name": "Paul Mitchell the School-Merrillville",
        "hyphenated": "paul-mitchell-the-school-merrillville"
    },
    {
        "id": "484127",
        "name": "Ricci's Toni & Guy Hairdressing Academy/TIGI Creative School",
        "hyphenated": "ricci's-toni-&-guy-hairdressing-academy/tigi-creative-school"
    },
    {
        "id": "484136",
        "name": "Yahweh Beauty Academy",
        "hyphenated": "yahweh-beauty-academy"
    },
    {
        "id": "484163",
        "name": "Arizona School of Integrative Studies",
        "hyphenated": "arizona-school-of-integrative-studies"
    },
    {
        "id": "484172",
        "name": "Lee Professional Institute",
        "hyphenated": "lee-professional-institute"
    },
    {
        "id": "484190",
        "name": "Park Place Premier Barber School",
        "hyphenated": "park-place-premier-barber-school"
    },
    {
        "id": "484206",
        "name": "The Salon Professional Academy-San Jose",
        "hyphenated": "the-salon-professional-academy-san-jose"
    },
    {
        "id": "484233",
        "name": "Tri-Community Adult Education",
        "hyphenated": "tri-community-adult-education"
    },
    {
        "id": "484330",
        "name": "Ross Medical Education Center-Owensboro",
        "hyphenated": "ross-medical-education-center-owensboro"
    },
    {
        "id": "484349",
        "name": "Ross Medical Education Center-Evansville",
        "hyphenated": "ross-medical-education-center-evansville"
    },
    {
        "id": "484358",
        "name": "Ross Medical Education Center-Johnson City",
        "hyphenated": "ross-medical-education-center-johnson-city"
    },
    {
        "id": "484367",
        "name": "North-West College-Anaheim",
        "hyphenated": "north-west-college-anaheim"
    },
    {
        "id": "484376",
        "name": "North-West College-Long Beach",
        "hyphenated": "north-west-college-long-beach"
    },
    {
        "id": "484394",
        "name": "Taylor Andrews Academy of Hair Design-Provo",
        "hyphenated": "taylor-andrews-academy-of-hair-design-provo"
    },
    {
        "id": "484473",
        "name": "University of Florida-Online",
        "hyphenated": "university-of-florida-online"
    },
    {
        "id": "484604",
        "name": "Ogle School Hair Skin Nails-Stafford",
        "hyphenated": "ogle-school-hair-skin-nails-stafford"
    },
    {
        "id": "484613",
        "name": "University of Phoenix-Arizona",
        "hyphenated": "university-of-phoenix-arizona"
    },
    {
        "id": "484631",
        "name": "University of Phoenix-California",
        "hyphenated": "university-of-phoenix-california"
    },
    {
        "id": "484710",
        "name": "University of Phoenix-Nevada",
        "hyphenated": "university-of-phoenix-nevada"
    },
    {
        "id": "484756",
        "name": "University of Phoenix-Texas",
        "hyphenated": "university-of-phoenix-texas"
    },
    {
        "id": "484835",
        "name": "CEM College-Mayaguez",
        "hyphenated": "cem-college-mayaguez"
    },
    {
        "id": "484844",
        "name": "Minerva University",
        "hyphenated": "minerva-university"
    },
    {
        "id": "484862",
        "name": "University of West Los Angeles",
        "hyphenated": "university-of-west-los-angeles"
    },
    {
        "id": "484871",
        "name": "Rabbinical College Ohr Yisroel",
        "hyphenated": "rabbinical-college-ohr-yisroel"
    },
    {
        "id": "484899",
        "name": "Aveda Institute-Phoenix",
        "hyphenated": "aveda-institute-phoenix"
    },
    {
        "id": "484905",
        "name": "University of North Texas at Dallas",
        "hyphenated": "university-of-north-texas-at-dallas"
    },
    {
        "id": "484923",
        "name": "Dermal Science International Aesthetics and Nail Academy",
        "hyphenated": "dermal-science-international-aesthetics-and-nail-academy"
    },
    {
        "id": "484932",
        "name": "BridgeValley Community & Technical College",
        "hyphenated": "bridgevalley-community-&-technical-college"
    },
    {
        "id": "484950",
        "name": "Northwest College-Medford",
        "hyphenated": "northwest-college-medford"
    },
    {
        "id": "484996",
        "name": "Houston Training Schools-Gessner",
        "hyphenated": "houston-training-schools-gessner"
    },
    {
        "id": "485014",
        "name": "Empire Beauty School-Buffalo",
        "hyphenated": "empire-beauty-school-buffalo"
    },
    {
        "id": "485032",
        "name": "Paul Mitchell the School-Toledo",
        "hyphenated": "paul-mitchell-the-school-toledo"
    },
    {
        "id": "485111",
        "name": "Georgia Military College",
        "hyphenated": "georgia-military-college"
    },
    {
        "id": "485139",
        "name": "SAE Institute of Technology-Chicago",
        "hyphenated": "sae-institute-of-technology-chicago"
    },
    {
        "id": "485166",
        "name": "Beau Monde Academy of Barbering and Cosmetology",
        "hyphenated": "beau-monde-academy-of-barbering-and-cosmetology"
    },
    {
        "id": "485236",
        "name": "Fountain of Youth Academy of Cosmetology",
        "hyphenated": "fountain-of-youth-academy-of-cosmetology"
    },
    {
        "id": "485272",
        "name": "West Coast University-Miami",
        "hyphenated": "west-coast-university-miami"
    },
    {
        "id": "485306",
        "name": "All Beauty College",
        "hyphenated": "all-beauty-college"
    },
    {
        "id": "485342",
        "name": "Aparicio-Levy Technical College",
        "hyphenated": "aparicio-levy-technical-college"
    },
    {
        "id": "485360",
        "name": "The Vocational Nursing Institute Inc",
        "hyphenated": "the-vocational-nursing-institute-inc"
    },
    {
        "id": "485379",
        "name": "Hollywood Institute of Beauty Careers-West Palm Beach",
        "hyphenated": "hollywood-institute-of-beauty-careers-west-palm-beach"
    },
    {
        "id": "485388",
        "name": "Hollywood Institute of Beauty Careers-Casselberry",
        "hyphenated": "hollywood-institute-of-beauty-careers-casselberry"
    },
    {
        "id": "485397",
        "name": "Empire Beauty School-Cheltenham",
        "hyphenated": "empire-beauty-school-cheltenham"
    },
    {
        "id": "485403",
        "name": "High Tech High Graduate School of Education",
        "hyphenated": "high-tech-high-graduate-school-of-education"
    },
    {
        "id": "485412",
        "name": "Coachella Valley Beauty College-Hemet",
        "hyphenated": "coachella-valley-beauty-college-hemet"
    },
    {
        "id": "485458",
        "name": "Coastal Pines Technical College",
        "hyphenated": "coastal-pines-technical-college"
    },
    {
        "id": "485476",
        "name": "Studio Beauty School",
        "hyphenated": "studio-beauty-school"
    },
    {
        "id": "485485",
        "name": "Latin Beauty Academy",
        "hyphenated": "latin-beauty-academy"
    },
    {
        "id": "485494",
        "name": "Associated Barber College of San Diego",
        "hyphenated": "associated-barber-college-of-san-diego"
    },
    {
        "id": "485500",
        "name": "ABCO Technology",
        "hyphenated": "abco-technology"
    },
    {
        "id": "485519",
        "name": "Lionel University",
        "hyphenated": "lionel-university"
    },
    {
        "id": "485546",
        "name": "California Intercontinental University",
        "hyphenated": "california-intercontinental-university"
    },
    {
        "id": "485564",
        "name": "Infinity College",
        "hyphenated": "infinity-college"
    },
    {
        "id": "485573",
        "name": "Universal Healthcare Careers College",
        "hyphenated": "universal-healthcare-careers-college"
    },
    {
        "id": "485591",
        "name": "New Beginnings Beauty Academy",
        "hyphenated": "new-beginnings-beauty-academy"
    },
    {
        "id": "485607",
        "name": "Dolce The Academy",
        "hyphenated": "dolce-the-academy"
    },
    {
        "id": "485698",
        "name": "American College of the Building Arts",
        "hyphenated": "american-college-of-the-building-arts"
    },
    {
        "id": "485704",
        "name": "River Valley School of Massage",
        "hyphenated": "river-valley-school-of-massage"
    },
    {
        "id": "485768",
        "name": "Atlantis University",
        "hyphenated": "atlantis-university"
    },
    {
        "id": "485801",
        "name": "Westchester School for Medical & Dental Assistants",
        "hyphenated": "westchester-school-for-medical-&-dental-assistants"
    },
    {
        "id": "485856",
        "name": "Studio Incamminati",
        "hyphenated": "studio-incamminati"
    },
    {
        "id": "485874",
        "name": "Salon Boutique Academy",
        "hyphenated": "salon-boutique-academy"
    },
    {
        "id": "485926",
        "name": "GA Beauty & Barber School",
        "hyphenated": "ga-beauty-&-barber-school"
    },
    {
        "id": "485953",
        "name": "The Recording Conservatory of Austin",
        "hyphenated": "the-recording-conservatory-of-austin"
    },
    {
        "id": "485962",
        "name": "VH Barber & Styling Academy",
        "hyphenated": "vh-barber-&-styling-academy"
    },
    {
        "id": "485999",
        "name": "Bet Medrash Gadol Ateret Torah",
        "hyphenated": "bet-medrash-gadol-ateret-torah"
    },
    {
        "id": "486017",
        "name": "Yeshiva Ohr Yisrael",
        "hyphenated": "yeshiva-ohr-yisrael"
    },
    {
        "id": "486026",
        "name": "Yeshiva Sholom Shachna",
        "hyphenated": "yeshiva-sholom-shachna"
    },
    {
        "id": "486053",
        "name": "Bethlehem College & Seminary",
        "hyphenated": "bethlehem-college-&-seminary"
    },
    {
        "id": "486080",
        "name": "Elmezzi Graduate School of Molecular Medicine",
        "hyphenated": "elmezzi-graduate-school-of-molecular-medicine"
    },
    {
        "id": "486105",
        "name": "Professional Academy of Cosmetology",
        "hyphenated": "professional-academy-of-cosmetology"
    },
    {
        "id": "486123",
        "name": "Theatre of Arts",
        "hyphenated": "theatre-of-arts"
    },
    {
        "id": "486141",
        "name": "Westchester College of Nursing & Allied Health",
        "hyphenated": "westchester-college-of-nursing-&-allied-health"
    },
    {
        "id": "486150",
        "name": "Treasure Coast Technical College",
        "hyphenated": "treasure-coast-technical-college"
    },
    {
        "id": "486169",
        "name": "American College of Barbering",
        "hyphenated": "american-college-of-barbering"
    },
    {
        "id": "486196",
        "name": "Beth Medrash Meor Yitzchok",
        "hyphenated": "beth-medrash-meor-yitzchok"
    },
    {
        "id": "486202",
        "name": "Mingo Extended Learning Center",
        "hyphenated": "mingo-extended-learning-center"
    },
    {
        "id": "486239",
        "name": "San Ignacio University",
        "hyphenated": "san-ignacio-university"
    },
    {
        "id": "486248",
        "name": "ZMS The Academy",
        "hyphenated": "zms-the-academy"
    },
    {
        "id": "486257",
        "name": "Valor Christian College",
        "hyphenated": "valor-christian-college"
    },
    {
        "id": "486284",
        "name": "Bethany Global University",
        "hyphenated": "bethany-global-university"
    },
    {
        "id": "486309",
        "name": "Bella Academy of Cosmetology",
        "hyphenated": "bella-academy-of-cosmetology"
    },
    {
        "id": "486345",
        "name": "HVAC Technical Institute",
        "hyphenated": "hvac-technical-institute"
    },
    {
        "id": "486354",
        "name": "United International College",
        "hyphenated": "united-international-college"
    },
    {
        "id": "486372",
        "name": "San Francisco Film School",
        "hyphenated": "san-francisco-film-school"
    },
    {
        "id": "486381",
        "name": "Center for Ultrasound Research & Education",
        "hyphenated": "center-for-ultrasound-research-&-education"
    },
    {
        "id": "486390",
        "name": "Culinary Tech Center",
        "hyphenated": "culinary-tech-center"
    },
    {
        "id": "486406",
        "name": "Randolph Technical Center",
        "hyphenated": "randolph-technical-center"
    },
    {
        "id": "486415",
        "name": "AMG School of Nursing",
        "hyphenated": "amg-school-of-nursing"
    },
    {
        "id": "486424",
        "name": "Saint Michael College of Allied Health",
        "hyphenated": "saint-michael-college-of-allied-health"
    },
    {
        "id": "486433",
        "name": "Presidio Graduate School",
        "hyphenated": "presidio-graduate-school"
    },
    {
        "id": "486442",
        "name": "Sandra Academy of Salon Services",
        "hyphenated": "sandra-academy-of-salon-services"
    },
    {
        "id": "486460",
        "name": "Wright Graduate University for the Realization of Human Potential",
        "hyphenated": "wright-graduate-university-for-the-realization-of-human-potential"
    },
    {
        "id": "486488",
        "name": "California Jazz Conservatory",
        "hyphenated": "california-jazz-conservatory"
    },
    {
        "id": "486497",
        "name": "California Barber and Beauty College",
        "hyphenated": "california-barber-and-beauty-college"
    },
    {
        "id": "486503",
        "name": "Laredo CHI Academy Beauty School",
        "hyphenated": "laredo-chi-academy-beauty-school"
    },
    {
        "id": "486512",
        "name": "Florida International Training Institute",
        "hyphenated": "florida-international-training-institute"
    },
    {
        "id": "486530",
        "name": "Gould's Academy",
        "hyphenated": "gould's-academy"
    },
    {
        "id": "486558",
        "name": "Aviation Institute of Maintenance-Fremont",
        "hyphenated": "aviation-institute-of-maintenance-fremont"
    },
    {
        "id": "486567",
        "name": "Paul Mitchell the School-Tulsa",
        "hyphenated": "paul-mitchell-the-school-tulsa"
    },
    {
        "id": "486576",
        "name": "PPG Technical College",
        "hyphenated": "ppg-technical-college"
    },
    {
        "id": "486594",
        "name": "Wave Leadership College",
        "hyphenated": "wave-leadership-college"
    },
    {
        "id": "486619",
        "name": "Academy of Professional Cosmetology",
        "hyphenated": "academy-of-professional-cosmetology"
    },
    {
        "id": "486691",
        "name": "Empire Beauty School-Northlake",
        "hyphenated": "empire-beauty-school-northlake"
    },
    {
        "id": "486707",
        "name": "Ross Medical Education Center-Huntsville",
        "hyphenated": "ross-medical-education-center-huntsville"
    },
    {
        "id": "486716",
        "name": "Ross Medical Education Center-Knoxville",
        "hyphenated": "ross-medical-education-center-knoxville"
    },
    {
        "id": "486752",
        "name": "Ross Medical Education Center-Muncie",
        "hyphenated": "ross-medical-education-center-muncie"
    },
    {
        "id": "486770",
        "name": "Cortiva Institute-Arlington",
        "hyphenated": "cortiva-institute-arlington"
    },
    {
        "id": "486798",
        "name": "Tulsa Welding School-Houston",
        "hyphenated": "tulsa-welding-school-houston"
    },
    {
        "id": "486813",
        "name": "Pima Medical Institute-El Paso",
        "hyphenated": "pima-medical-institute-el-paso"
    },
    {
        "id": "486822",
        "name": "Pima Medical Institute-Phoenix",
        "hyphenated": "pima-medical-institute-phoenix"
    },
    {
        "id": "486840",
        "name": "Kennesaw State University",
        "hyphenated": "kennesaw-state-university"
    },
    {
        "id": "486859",
        "name": "Davines Professional Academy of Beauty and Business",
        "hyphenated": "davines-professional-academy-of-beauty-and-business"
    },
    {
        "id": "486868",
        "name": "All Beauty College",
        "hyphenated": "all-beauty-college"
    },
    {
        "id": "486877",
        "name": "Eastern Suffolk BOCES",
        "hyphenated": "eastern-suffolk-boces"
    },
    {
        "id": "486886",
        "name": "Eastern Suffolk BOCES",
        "hyphenated": "eastern-suffolk-boces"
    },
    {
        "id": "486901",
        "name": "Milligan University",
        "hyphenated": "milligan-university"
    },
    {
        "id": "486938",
        "name": "Chamberlain University-Nevada",
        "hyphenated": "chamberlain-university-nevada"
    },
    {
        "id": "486947",
        "name": "Chamberlain University-Michigan",
        "hyphenated": "chamberlain-university-michigan"
    },
    {
        "id": "486956",
        "name": "Chamberlain University-New Jersey",
        "hyphenated": "chamberlain-university-new-jersey"
    },
    {
        "id": "486965",
        "name": "Hussian College-Los Angeles",
        "hyphenated": "hussian-college-los-angeles"
    },
    {
        "id": "486983",
        "name": "Paul Mitchell the School-Madison",
        "hyphenated": "paul-mitchell-the-school-madison"
    },
    {
        "id": "487010",
        "name": "The University of Tennessee Health Science Center",
        "hyphenated": "the-university-of-tennessee-health-science-center"
    },
    {
        "id": "487047",
        "name": "Unitech Training Academy-New Orleans",
        "hyphenated": "unitech-training-academy-new-orleans"
    },
    {
        "id": "487065",
        "name": "Wade Gordon Hairdressing Academy-Lubbock",
        "hyphenated": "wade-gordon-hairdressing-academy-lubbock"
    },
    {
        "id": "487083",
        "name": "Protege Academy",
        "hyphenated": "protege-academy"
    },
    {
        "id": "487092",
        "name": "Emory University-Oxford College",
        "hyphenated": "emory-university-oxford-college"
    },
    {
        "id": "487153",
        "name": "The Chicago School of Professional Psychology at Xavier University of Louisiana",
        "hyphenated": "the-chicago-school-of-professional-psychology-at-xavier-university-of-louisiana"
    },
    {
        "id": "487162",
        "name": "Southern Regional Technical College",
        "hyphenated": "southern-regional-technical-college"
    },
    {
        "id": "487232",
        "name": "Texas College of Cosmetology-Lubbock",
        "hyphenated": "texas-college-of-cosmetology-lubbock"
    },
    {
        "id": "487302",
        "name": "Fortis College-Cuyahoga Falls",
        "hyphenated": "fortis-college-cuyahoga-falls"
    },
    {
        "id": "487311",
        "name": "Jones Technical Institute",
        "hyphenated": "jones-technical-institute"
    },
    {
        "id": "487320",
        "name": "Texas State Technical College",
        "hyphenated": "texas-state-technical-college"
    },
    {
        "id": "487348",
        "name": "Aveda Institute Portland-Vancouver Campus",
        "hyphenated": "aveda-institute-portland-vancouver-campus"
    },
    {
        "id": "487375",
        "name": "Arizona College of Nursing-Las Vegas",
        "hyphenated": "arizona-college-of-nursing-las-vegas"
    },
    {
        "id": "487384",
        "name": "Florida Career College-Pembroke Pines",
        "hyphenated": "florida-career-college-pembroke-pines"
    },
    {
        "id": "487393",
        "name": "Florida Career College-West Palm Beach",
        "hyphenated": "florida-career-college-west-palm-beach"
    },
    {
        "id": "487409",
        "name": "Florida Career College-Hialeah",
        "hyphenated": "florida-career-college-hialeah"
    },
    {
        "id": "487418",
        "name": "Florida Career College-Lauderdale Lakes",
        "hyphenated": "florida-career-college-lauderdale-lakes"
    },
    {
        "id": "487427",
        "name": "Florida Career College-Tampa",
        "hyphenated": "florida-career-college-tampa"
    },
    {
        "id": "487436",
        "name": "Florida Career College-Jacksonville",
        "hyphenated": "florida-career-college-jacksonville"
    },
    {
        "id": "487445",
        "name": "Florida Career College-Boynton Beach",
        "hyphenated": "florida-career-college-boynton-beach"
    },
    {
        "id": "487454",
        "name": "Florida Career College-Margate",
        "hyphenated": "florida-career-college-margate"
    },
    {
        "id": "487472",
        "name": "Florida Career College-Orlando",
        "hyphenated": "florida-career-college-orlando"
    },
    {
        "id": "487481",
        "name": "UEI College-Phoenix",
        "hyphenated": "uei-college-phoenix"
    },
    {
        "id": "487490",
        "name": "Florida Career College-Houston",
        "hyphenated": "florida-career-college-houston"
    },
    {
        "id": "487506",
        "name": "Aveda Institute-Madison",
        "hyphenated": "aveda-institute-madison"
    },
    {
        "id": "487524",
        "name": "Husson University",
        "hyphenated": "husson-university"
    },
    {
        "id": "487533",
        "name": "Tricoci University of Beauty Culture-Elgin",
        "hyphenated": "tricoci-university-of-beauty-culture-elgin"
    },
    {
        "id": "487588",
        "name": "Concorde Career College-Southaven",
        "hyphenated": "concorde-career-college-southaven"
    },
    {
        "id": "487597",
        "name": "Universal Technical Institute-Southern California",
        "hyphenated": "universal-technical-institute-southern-california"
    },
    {
        "id": "487603",
        "name": "Northwest University-Center for Online and Extended Education",
        "hyphenated": "northwest-university-center-for-online-and-extended-education"
    },
    {
        "id": "487621",
        "name": "Celebrity Barber School",
        "hyphenated": "celebrity-barber-school"
    },
    {
        "id": "487649",
        "name": "California Institute of Advanced Management",
        "hyphenated": "california-institute-of-advanced-management"
    },
    {
        "id": "487658",
        "name": "Academy of Interactive Entertainment",
        "hyphenated": "academy-of-interactive-entertainment"
    },
    {
        "id": "487676",
        "name": "The Temple Annapolis-A Paul Mitchell Partner School",
        "hyphenated": "the-temple-annapolis-a-paul-mitchell-partner-school"
    },
    {
        "id": "487746",
        "name": "Yeshiva Zichron Aryeh",
        "hyphenated": "yeshiva-zichron-aryeh"
    },
    {
        "id": "487773",
        "name": "Butte County Regional Occupational Program",
        "hyphenated": "butte-county-regional-occupational-program"
    },
    {
        "id": "487791",
        "name": "Arclabs",
        "hyphenated": "arclabs"
    },
    {
        "id": "487807",
        "name": "Top of the Line Barber College",
        "hyphenated": "top-of-the-line-barber-college"
    },
    {
        "id": "487816",
        "name": "Midfield Institute of Cosmetology",
        "hyphenated": "midfield-institute-of-cosmetology"
    },
    {
        "id": "487852",
        "name": "American Institute of Alternative Medicine",
        "hyphenated": "american-institute-of-alternative-medicine"
    },
    {
        "id": "487861",
        "name": "Felbry College",
        "hyphenated": "felbry-college"
    },
    {
        "id": "487889",
        "name": "Regional Center for Border Health",
        "hyphenated": "regional-center-for-border-health"
    },
    {
        "id": "487898",
        "name": "B-Unique Beauty and Barber Academy",
        "hyphenated": "b-unique-beauty-and-barber-academy"
    },
    {
        "id": "487904",
        "name": "Kentucky Horseshoeing School",
        "hyphenated": "kentucky-horseshoeing-school"
    },
    {
        "id": "487922",
        "name": "Image Maker Beauty Institute",
        "hyphenated": "image-maker-beauty-institute"
    },
    {
        "id": "487959",
        "name": "Dalton Institute of Esthetics and Cosmetology",
        "hyphenated": "dalton-institute-of-esthetics-and-cosmetology"
    },
    {
        "id": "487968",
        "name": "CAAN Academy of Nursing",
        "hyphenated": "caan-academy-of-nursing"
    },
    {
        "id": "487977",
        "name": "Martinsburg College",
        "hyphenated": "martinsburg-college"
    },
    {
        "id": "487995",
        "name": "Alhambra Medical University",
        "hyphenated": "alhambra-medical-university"
    },
    {
        "id": "488004",
        "name": "Central Yeshiva Beth Joseph",
        "hyphenated": "central-yeshiva-beth-joseph"
    },
    {
        "id": "488013",
        "name": "Garden State Science and Technology Institute",
        "hyphenated": "garden-state-science-and-technology-institute"
    },
    {
        "id": "488022",
        "name": "Barber Tech Academy",
        "hyphenated": "barber-tech-academy"
    },
    {
        "id": "488031",
        "name": "Abraham Lincoln University",
        "hyphenated": "abraham-lincoln-university"
    },
    {
        "id": "488059",
        "name": "Med Academy",
        "hyphenated": "med-academy"
    },
    {
        "id": "488068",
        "name": "Huntington University of Health Sciences",
        "hyphenated": "huntington-university-of-health-sciences"
    },
    {
        "id": "488077",
        "name": "Sonoran Desert Institute",
        "hyphenated": "sonoran-desert-institute"
    },
    {
        "id": "488101",
        "name": "Yeshivas Maharit D'Satmar",
        "hyphenated": "yeshivas-maharit-d'satmar"
    },
    {
        "id": "488110",
        "name": "Natural Images Beauty College",
        "hyphenated": "natural-images-beauty-college"
    },
    {
        "id": "488129",
        "name": "Textures Institute of Cosmetology",
        "hyphenated": "textures-institute-of-cosmetology"
    },
    {
        "id": "488138",
        "name": "Learning Bridge Career Institute",
        "hyphenated": "learning-bridge-career-institute"
    },
    {
        "id": "488147",
        "name": "PiBerry Institute",
        "hyphenated": "piberry-institute"
    },
    {
        "id": "488174",
        "name": "Ea La Mar's Cosmetology & Barber College",
        "hyphenated": "ea-la-mar's-cosmetology-&-barber-college"
    },
    {
        "id": "488183",
        "name": "Regina Webb Academy",
        "hyphenated": "regina-webb-academy"
    },
    {
        "id": "488192",
        "name": "DeHart Technical School",
        "hyphenated": "dehart-technical-school"
    },
    {
        "id": "488217",
        "name": "Medical Career & Technical College",
        "hyphenated": "medical-career-&-technical-college"
    },
    {
        "id": "488226",
        "name": "Center for Neurosomatic Studies",
        "hyphenated": "center-for-neurosomatic-studies"
    },
    {
        "id": "488253",
        "name": "Focus Personal Training Institute",
        "hyphenated": "focus-personal-training-institute"
    },
    {
        "id": "488262",
        "name": "Hair Academy School of Barbering & Beauty",
        "hyphenated": "hair-academy-school-of-barbering-&-beauty"
    },
    {
        "id": "488271",
        "name": "Fosbre Academy of Hair Design",
        "hyphenated": "fosbre-academy-of-hair-design"
    },
    {
        "id": "488280",
        "name": "National Personal Training Institute",
        "hyphenated": "national-personal-training-institute"
    },
    {
        "id": "488299",
        "name": "Mission Beauty Institute",
        "hyphenated": "mission-beauty-institute"
    },
    {
        "id": "488305",
        "name": "Elim Bible Institute and College",
        "hyphenated": "elim-bible-institute-and-college"
    },
    {
        "id": "488314",
        "name": "Beth Medrash of Asbury Park",
        "hyphenated": "beth-medrash-of-asbury-park"
    },
    {
        "id": "488332",
        "name": "Hope College of Arts and Sciences",
        "hyphenated": "hope-college-of-arts-and-sciences"
    },
    {
        "id": "488350",
        "name": "Yeshiva Gedolah Shaarei Shmuel",
        "hyphenated": "yeshiva-gedolah-shaarei-shmuel"
    },
    {
        "id": "488369",
        "name": "Premier Barber Institute",
        "hyphenated": "premier-barber-institute"
    },
    {
        "id": "488378",
        "name": "International Beauty Education Center",
        "hyphenated": "international-beauty-education-center"
    },
    {
        "id": "488387",
        "name": "Claremont Lincoln University",
        "hyphenated": "claremont-lincoln-university"
    },
    {
        "id": "488396",
        "name": "Phipps Academy of Barbering",
        "hyphenated": "phipps-academy-of-barbering"
    },
    {
        "id": "488411",
        "name": "Hoss Lee Academy",
        "hyphenated": "hoss-lee-academy"
    },
    {
        "id": "488420",
        "name": "Indiana Wellness College",
        "hyphenated": "indiana-wellness-college"
    },
    {
        "id": "488439",
        "name": "Kenny's Academy of Barbering",
        "hyphenated": "kenny's-academy-of-barbering"
    },
    {
        "id": "488448",
        "name": "Seattle Film Institute",
        "hyphenated": "seattle-film-institute"
    },
    {
        "id": "488527",
        "name": "Arkansas Colleges of Health Education",
        "hyphenated": "arkansas-colleges-of-health-education"
    },
    {
        "id": "488554",
        "name": "Burrell College of Osteopathic Medicine",
        "hyphenated": "burrell-college-of-osteopathic-medicine"
    },
    {
        "id": "488563",
        "name": "California College of Music",
        "hyphenated": "california-college-of-music"
    },
    {
        "id": "488572",
        "name": "California Health Sciences University",
        "hyphenated": "california-health-sciences-university"
    },
    {
        "id": "488590",
        "name": "Christian Culinary Academy",
        "hyphenated": "christian-culinary-academy"
    },
    {
        "id": "488651",
        "name": "Glitz School of Cosmetology",
        "hyphenated": "glitz-school-of-cosmetology"
    },
    {
        "id": "488679",
        "name": "Indiana Wesleyan University-National & Global",
        "hyphenated": "indiana-wesleyan-university-national-&-global"
    },
    {
        "id": "488730",
        "name": "Northeast Lakeview College",
        "hyphenated": "northeast-lakeview-college"
    },
    {
        "id": "488749",
        "name": "Paul Mitchell the School-Jessup",
        "hyphenated": "paul-mitchell-the-school-jessup"
    },
    {
        "id": "488776",
        "name": "John Patrick University of Health and Applied Sciences",
        "hyphenated": "john-patrick-university-of-health-and-applied-sciences"
    },
    {
        "id": "488785",
        "name": "University of Saint Katherine",
        "hyphenated": "university-of-saint-katherine"
    },
    {
        "id": "488800",
        "name": "Teachers College of San Joaquin",
        "hyphenated": "teachers-college-of-san-joaquin"
    },
    {
        "id": "488828",
        "name": "Totally Cosmo School of Modern Cosmetology",
        "hyphenated": "totally-cosmo-school-of-modern-cosmetology"
    },
    {
        "id": "488837",
        "name": "U.S. Truck Driver Training School",
        "hyphenated": "u.s.-truck-driver-training-school"
    },
    {
        "id": "488846",
        "name": "University of the People",
        "hyphenated": "university-of-the-people"
    },
    {
        "id": "488891",
        "name": "South Dade Technical College-South Dade Skills Center Campus",
        "hyphenated": "south-dade-technical-college-south-dade-skills-center-campus"
    },
    {
        "id": "488907",
        "name": "Wongu University of Oriental Medicine",
        "hyphenated": "wongu-university-of-oriental-medicine"
    },
    {
        "id": "488916",
        "name": "Alabama School of Nail Technology & Cosmetology",
        "hyphenated": "alabama-school-of-nail-technology-&-cosmetology"
    },
    {
        "id": "488934",
        "name": "Tulsa Technology Center",
        "hyphenated": "tulsa-technology-center"
    },
    {
        "id": "488952",
        "name": "Ross Medical Education Center-Elyria",
        "hyphenated": "ross-medical-education-center-elyria"
    },
    {
        "id": "488961",
        "name": "Ross Medical Education Center-Lafayette",
        "hyphenated": "ross-medical-education-center-lafayette"
    },
    {
        "id": "488970",
        "name": "Ross Medical Education Center-Midland",
        "hyphenated": "ross-medical-education-center-midland"
    },
    {
        "id": "488989",
        "name": "Paul Mitchell the School-Clear Lake",
        "hyphenated": "paul-mitchell-the-school-clear-lake"
    },
    {
        "id": "488998",
        "name": "Paul Mitchell the School-Nampa",
        "hyphenated": "paul-mitchell-the-school-nampa"
    },
    {
        "id": "489122",
        "name": "Academy of Beauty Professionals",
        "hyphenated": "academy-of-beauty-professionals"
    },
    {
        "id": "489131",
        "name": "Academy of Beauty Professionals",
        "hyphenated": "academy-of-beauty-professionals"
    },
    {
        "id": "489140",
        "name": "Academy of Beauty Professionals",
        "hyphenated": "academy-of-beauty-professionals"
    },
    {
        "id": "489159",
        "name": "Kenneth Shuler School of Cosmetology-Goose Creek",
        "hyphenated": "kenneth-shuler-school-of-cosmetology-goose-creek"
    },
    {
        "id": "489201",
        "name": "Clovis Community College",
        "hyphenated": "clovis-community-college"
    },
    {
        "id": "489238",
        "name": "Boca Beauty Academy-Parkland",
        "hyphenated": "boca-beauty-academy-parkland"
    },
    {
        "id": "489247",
        "name": "American Beauty Academy-West Valley Campus",
        "hyphenated": "american-beauty-academy-west-valley-campus"
    },
    {
        "id": "489256",
        "name": "Caribbean Aviation Training Institute Inc",
        "hyphenated": "caribbean-aviation-training-institute-inc"
    },
    {
        "id": "489283",
        "name": "The College of Health Care Professions-McAllen Campus",
        "hyphenated": "the-college-of-health-care-professions-mcallen-campus"
    },
    {
        "id": "489335",
        "name": "Institute of Buddhist Studies",
        "hyphenated": "institute-of-buddhist-studies"
    },
    {
        "id": "489344",
        "name": "Chamberlain University-North Carolina",
        "hyphenated": "chamberlain-university-north-carolina"
    },
    {
        "id": "489353",
        "name": "Chamberlain University-California",
        "hyphenated": "chamberlain-university-california"
    },
    {
        "id": "489371",
        "name": "Spartan College of Aeronautics and Technology",
        "hyphenated": "spartan-college-of-aeronautics-and-technology"
    },
    {
        "id": "489779",
        "name": "Purdue University Global",
        "hyphenated": "purdue-university-global"
    },
    {
        "id": "489812",
        "name": "Pima Medical Institute-Dillon",
        "hyphenated": "pima-medical-institute-dillon"
    },
    {
        "id": "489830",
        "name": "Arthur's Beauty College",
        "hyphenated": "arthur's-beauty-college"
    },
    {
        "id": "489858",
        "name": "West Coast University-Center for Graduate Studies",
        "hyphenated": "west-coast-university-center-for-graduate-studies"
    },
    {
        "id": "489937",
        "name": "Carolina University",
        "hyphenated": "carolina-university"
    },
    {
        "id": "490018",
        "name": "National Personal Training Institute-Tampa",
        "hyphenated": "national-personal-training-institute-tampa"
    },
    {
        "id": "490045",
        "name": "Presbyterian Theological Seminary in America",
        "hyphenated": "presbyterian-theological-seminary-in-america"
    },
    {
        "id": "490054",
        "name": "HCI College",
        "hyphenated": "hci-college"
    },
    {
        "id": "490063",
        "name": "Southern States University",
        "hyphenated": "southern-states-university"
    },
    {
        "id": "490081",
        "name": "America Evangelical University",
        "hyphenated": "america-evangelical-university"
    },
    {
        "id": "490090",
        "name": "Gadsden Technical College",
        "hyphenated": "gadsden-technical-college"
    },
    {
        "id": "490106",
        "name": "Virginia University of Integrative Medicine",
        "hyphenated": "virginia-university-of-integrative-medicine"
    },
    {
        "id": "490115",
        "name": "Nashville Film Institute",
        "hyphenated": "nashville-film-institute"
    },
    {
        "id": "490124",
        "name": "Los Angeles Academy of Figurative Art",
        "hyphenated": "los-angeles-academy-of-figurative-art"
    },
    {
        "id": "490133",
        "name": "Westcliff University",
        "hyphenated": "westcliff-university"
    },
    {
        "id": "490142",
        "name": "World Class Academy of Beauty Careers",
        "hyphenated": "world-class-academy-of-beauty-careers"
    },
    {
        "id": "490151",
        "name": "Allied Health Careers Institute",
        "hyphenated": "allied-health-careers-institute"
    },
    {
        "id": "490160",
        "name": "Sacramento Ultrasound Institute",
        "hyphenated": "sacramento-ultrasound-institute"
    },
    {
        "id": "490197",
        "name": "Regan Career Institute",
        "hyphenated": "regan-career-institute"
    },
    {
        "id": "490203",
        "name": "Bolivar Technical College",
        "hyphenated": "bolivar-technical-college"
    },
    {
        "id": "490212",
        "name": "Baldy View Regional Occupational Program",
        "hyphenated": "baldy-view-regional-occupational-program"
    },
    {
        "id": "490221",
        "name": "MAK Beauty Institute",
        "hyphenated": "mak-beauty-institute"
    },
    {
        "id": "490230",
        "name": "Reformed University",
        "hyphenated": "reformed-university"
    },
    {
        "id": "490249",
        "name": "Mobile Technical Training",
        "hyphenated": "mobile-technical-training"
    },
    {
        "id": "490258",
        "name": "American Barber and Beauty Academy",
        "hyphenated": "american-barber-and-beauty-academy"
    },
    {
        "id": "490267",
        "name": "Master's Barber & Styling College",
        "hyphenated": "master's-barber-&-styling-college"
    },
    {
        "id": "490276",
        "name": "Yeshiva Shaar Ephraim",
        "hyphenated": "yeshiva-shaar-ephraim"
    },
    {
        "id": "490285",
        "name": "California Institute of Arts & Technology",
        "hyphenated": "california-institute-of-arts-&-technology"
    },
    {
        "id": "490294",
        "name": "Elite Cosmetology Barber & Spa Academy",
        "hyphenated": "elite-cosmetology-barber-&-spa-academy"
    },
    {
        "id": "490300",
        "name": "Cutting Edge Academy",
        "hyphenated": "cutting-edge-academy"
    },
    {
        "id": "490319",
        "name": "Yeshiva Bais Aharon",
        "hyphenated": "yeshiva-bais-aharon"
    },
    {
        "id": "490328",
        "name": "Mechon L'hoyroa",
        "hyphenated": "mechon-l'hoyroa"
    },
    {
        "id": "490337",
        "name": "My Beauty & Barber College",
        "hyphenated": "my-beauty-&-barber-college"
    },
    {
        "id": "490346",
        "name": "Elyon College",
        "hyphenated": "elyon-college"
    },
    {
        "id": "490373",
        "name": "Western Michigan University Homer Stryker M.D. School of Medicine",
        "hyphenated": "western-michigan-university-homer-stryker-m.d.-school-of-medicine"
    },
    {
        "id": "490382",
        "name": "Harmony Health Care Institute",
        "hyphenated": "harmony-health-care-institute"
    },
    {
        "id": "490391",
        "name": "American Beauty Schools",
        "hyphenated": "american-beauty-schools"
    },
    {
        "id": "490407",
        "name": "Hogan Institute of Cosmetology and Esthetics",
        "hyphenated": "hogan-institute-of-cosmetology-and-esthetics"
    },
    {
        "id": "490416",
        "name": "J's Barber College",
        "hyphenated": "j's-barber-college"
    },
    {
        "id": "490425",
        "name": "Ann Webb Skin Institute",
        "hyphenated": "ann-webb-skin-institute"
    },
    {
        "id": "490434",
        "name": "Apex Academy Hair Skin Nails School of Cosmetology",
        "hyphenated": "apex-academy-hair-skin-nails-school-of-cosmetology"
    },
    {
        "id": "490443",
        "name": "The Esthetic Institute",
        "hyphenated": "the-esthetic-institute"
    },
    {
        "id": "490452",
        "name": "Kor Beauty Academy",
        "hyphenated": "kor-beauty-academy"
    },
    {
        "id": "490461",
        "name": "EduMed Partners",
        "hyphenated": "edumed-partners"
    },
    {
        "id": "490470",
        "name": "Massage Institute of Memphis",
        "hyphenated": "massage-institute-of-memphis"
    },
    {
        "id": "490489",
        "name": "Metro Detroit Barber College",
        "hyphenated": "metro-detroit-barber-college"
    },
    {
        "id": "490498",
        "name": "Digital Media Institute",
        "hyphenated": "digital-media-institute"
    },
    {
        "id": "490504",
        "name": "Yeshiva Ohr Naftoli",
        "hyphenated": "yeshiva-ohr-naftoli"
    },
    {
        "id": "490513",
        "name": "Bais Medrash Mayan Hatorah",
        "hyphenated": "bais-medrash-mayan-hatorah"
    },
    {
        "id": "490522",
        "name": "Allgood Beauty Institute",
        "hyphenated": "allgood-beauty-institute"
    },
    {
        "id": "490531",
        "name": "Ray J's College of Hair",
        "hyphenated": "ray-j's-college-of-hair"
    },
    {
        "id": "490540",
        "name": "Delta Designs Cosmetology School",
        "hyphenated": "delta-designs-cosmetology-school"
    },
    {
        "id": "490559",
        "name": "Gwinnett Institute",
        "hyphenated": "gwinnett-institute"
    },
    {
        "id": "490601",
        "name": "Kansas City University",
        "hyphenated": "kansas-city-university"
    },
    {
        "id": "490674",
        "name": "Manuel and Theresa's School of Hair Design",
        "hyphenated": "manuel-and-theresa's-school-of-hair-design"
    },
    {
        "id": "490692",
        "name": "Cincinnati School of Barbering & Hair Design",
        "hyphenated": "cincinnati-school-of-barbering-&-hair-design"
    },
    {
        "id": "490717",
        "name": "L Makeup Institute",
        "hyphenated": "l-makeup-institute"
    },
    {
        "id": "490735",
        "name": "Peloton College",
        "hyphenated": "peloton-college"
    },
    {
        "id": "490744",
        "name": "Northern Technical College",
        "hyphenated": "northern-technical-college"
    },
    {
        "id": "490753",
        "name": "Maine Media College",
        "hyphenated": "maine-media-college"
    },
    {
        "id": "490805",
        "name": "Purdue University Northwest",
        "hyphenated": "purdue-university-northwest"
    },
    {
        "id": "490814",
        "name": "Ross Medical Education Center - Kalamazoo",
        "hyphenated": "ross-medical-education-center---kalamazoo"
    },
    {
        "id": "490832",
        "name": "Ross College-Canton",
        "hyphenated": "ross-college-canton"
    },
    {
        "id": "490841",
        "name": "Ross College-Grand Rapids North",
        "hyphenated": "ross-college-grand-rapids-north"
    },
    {
        "id": "490869",
        "name": "American Institute-Somerset",
        "hyphenated": "american-institute-somerset"
    },
    {
        "id": "490878",
        "name": "Platt College-Anaheim",
        "hyphenated": "platt-college-anaheim"
    },
    {
        "id": "490896",
        "name": "Advantage Career Institute",
        "hyphenated": "advantage-career-institute"
    },
    {
        "id": "490948",
        "name": "Bull City Durham Beauty and Barber College",
        "hyphenated": "bull-city-durham-beauty-and-barber-college"
    },
    {
        "id": "490975",
        "name": "Fortis College-Cutler Bay",
        "hyphenated": "fortis-college-cutler-bay"
    },
    {
        "id": "491057",
        "name": "Yeshiva Kollel Tifereth Elizer",
        "hyphenated": "yeshiva-kollel-tifereth-elizer"
    },
    {
        "id": "491066",
        "name": "Strand Institute of Beauty & Esthetics",
        "hyphenated": "strand-institute-of-beauty-&-esthetics"
    },
    {
        "id": "491075",
        "name": "Arkansas Welding Academy",
        "hyphenated": "arkansas-welding-academy"
    },
    {
        "id": "491084",
        "name": "Unitech Training Academy-Baton Rouge",
        "hyphenated": "unitech-training-academy-baton-rouge"
    },
    {
        "id": "491136",
        "name": "Empire Beauty School-Tampa",
        "hyphenated": "empire-beauty-school-tampa"
    },
    {
        "id": "491145",
        "name": "Empire Beauty School-Lakeland",
        "hyphenated": "empire-beauty-school-lakeland"
    },
    {
        "id": "491181",
        "name": "Galen College of Nursing-ARH",
        "hyphenated": "galen-college-of-nursing-arh"
    },
    {
        "id": "491190",
        "name": "The Chicago School of Professional Psychology at San Diego",
        "hyphenated": "the-chicago-school-of-professional-psychology-at-san-diego"
    },
    {
        "id": "491215",
        "name": "Commercial Divers International",
        "hyphenated": "commercial-divers-international"
    },
    {
        "id": "491224",
        "name": "Tricoci University of Beauty Culture-Janesville",
        "hyphenated": "tricoci-university-of-beauty-culture-janesville"
    },
    {
        "id": "491233",
        "name": "Unlimited Cosmetology School",
        "hyphenated": "unlimited-cosmetology-school"
    },
    {
        "id": "491242",
        "name": "Johnny Matthew's Hairdressing Training School",
        "hyphenated": "johnny-matthew's-hairdressing-training-school"
    },
    {
        "id": "491251",
        "name": "The Culinary School of Fort Worth",
        "hyphenated": "the-culinary-school-of-fort-worth"
    },
    {
        "id": "491260",
        "name": "Centralia Beauty College",
        "hyphenated": "centralia-beauty-college"
    },
    {
        "id": "491288",
        "name": "University of Wisconsin-Milwaukee Flex",
        "hyphenated": "university-of-wisconsin-milwaukee-flex"
    },
    {
        "id": "491297",
        "name": "University of Wisconsin-Parkside Flex",
        "hyphenated": "university-of-wisconsin-parkside-flex"
    },
    {
        "id": "491394",
        "name": "Miller-Motte College-Edge Tech Academy",
        "hyphenated": "miller-motte-college-edge-tech-academy"
    },
    {
        "id": "491446",
        "name": "Bnos Zion Of Bobov Seminary",
        "hyphenated": "bnos-zion-of-bobov-seminary"
    },
    {
        "id": "491464",
        "name": "ABC Adult School",
        "hyphenated": "abc-adult-school"
    },
    {
        "id": "491473",
        "name": "Polytech Adult Education",
        "hyphenated": "polytech-adult-education"
    },
    {
        "id": "491507",
        "name": "California Institute of Medical Science",
        "hyphenated": "california-institute-of-medical-science"
    },
    {
        "id": "491525",
        "name": "Union Bible College",
        "hyphenated": "union-bible-college"
    },
    {
        "id": "491534",
        "name": "Pets Playground Grooming School",
        "hyphenated": "pets-playground-grooming-school"
    },
    {
        "id": "491543",
        "name": "San Bernardino Beauty College",
        "hyphenated": "san-bernardino-beauty-college"
    },
    {
        "id": "491552",
        "name": "Christina and Company Education Center",
        "hyphenated": "christina-and-company-education-center"
    },
    {
        "id": "491570",
        "name": "Institute of Medical Ultrasound",
        "hyphenated": "institute-of-medical-ultrasound"
    },
    {
        "id": "491589",
        "name": "Mystros Barber Academy",
        "hyphenated": "mystros-barber-academy"
    },
    {
        "id": "491598",
        "name": "European Medical School of Massage",
        "hyphenated": "european-medical-school-of-massage"
    },
    {
        "id": "491613",
        "name": "Yeshiva Gedolah Tiferes Boruch",
        "hyphenated": "yeshiva-gedolah-tiferes-boruch"
    },
    {
        "id": "491622",
        "name": "Yeshiva Chemdas Hatorah",
        "hyphenated": "yeshiva-chemdas-hatorah"
    },
    {
        "id": "491631",
        "name": "Women's Institute of Torah Seminary and College",
        "hyphenated": "women's-institute-of-torah-seminary-and-college"
    },
    {
        "id": "491640",
        "name": "Yeshiva Gedolah Keren Hatorah",
        "hyphenated": "yeshiva-gedolah-keren-hatorah"
    },
    {
        "id": "491659",
        "name": "ICOHS College",
        "hyphenated": "icohs-college"
    },
    {
        "id": "491668",
        "name": "Neo-Esthetique European Institute",
        "hyphenated": "neo-esthetique-european-institute"
    },
    {
        "id": "491677",
        "name": "Lehigh Valley Barber School",
        "hyphenated": "lehigh-valley-barber-school"
    },
    {
        "id": "491686",
        "name": "International Barber College",
        "hyphenated": "international-barber-college"
    },
    {
        "id": "491695",
        "name": "Brown Beauty Barber School",
        "hyphenated": "brown-beauty-barber-school"
    },
    {
        "id": "491701",
        "name": "Rexburg College of Massage Therapy",
        "hyphenated": "rexburg-college-of-massage-therapy"
    },
    {
        "id": "491710",
        "name": "Yeshiva Gedolah of Cliffwood",
        "hyphenated": "yeshiva-gedolah-of-cliffwood"
    },
    {
        "id": "491729",
        "name": "Goshen School of Cosmetology",
        "hyphenated": "goshen-school-of-cosmetology"
    },
    {
        "id": "491738",
        "name": "IBS School of Cosmetology and Massage",
        "hyphenated": "ibs-school-of-cosmetology-and-massage"
    },
    {
        "id": "491747",
        "name": "UR Beauty & Barber Academy",
        "hyphenated": "ur-beauty-&-barber-academy"
    },
    {
        "id": "491756",
        "name": "Urban Barber College",
        "hyphenated": "urban-barber-college"
    },
    {
        "id": "491765",
        "name": "Yeshivas Emek Hatorah",
        "hyphenated": "yeshivas-emek-hatorah"
    },
    {
        "id": "491774",
        "name": "Transitions Career Institute School of Nursing",
        "hyphenated": "transitions-career-institute-school-of-nursing"
    },
    {
        "id": "491783",
        "name": "Wilton Simpson Technical College",
        "hyphenated": "wilton-simpson-technical-college"
    },
    {
        "id": "491792",
        "name": "Stacey James Institute",
        "hyphenated": "stacey-james-institute"
    },
    {
        "id": "491808",
        "name": "Meridian University",
        "hyphenated": "meridian-university"
    },
    {
        "id": "491817",
        "name": "Seminary Bnos Chaim",
        "hyphenated": "seminary-bnos-chaim"
    },
    {
        "id": "491826",
        "name": "Avenue Academy A Cosmetology Institute",
        "hyphenated": "avenue-academy-a-cosmetology-institute"
    },
    {
        "id": "491835",
        "name": "Salon Professional Academy of San Antonio",
        "hyphenated": "salon-professional-academy-of-san-antonio"
    },
    {
        "id": "491844",
        "name": "Red Lake Nation College",
        "hyphenated": "red-lake-nation-college"
    },
    {
        "id": "491853",
        "name": "Princess Institute of Beauty",
        "hyphenated": "princess-institute-of-beauty"
    },
    {
        "id": "491871",
        "name": "Vogue International Academy",
        "hyphenated": "vogue-international-academy"
    },
    {
        "id": "491880",
        "name": "Independent Training & Apprenticeship Program",
        "hyphenated": "independent-training-&-apprenticeship-program"
    },
    {
        "id": "491899",
        "name": "Victory Career College",
        "hyphenated": "victory-career-college"
    },
    {
        "id": "491905",
        "name": "Winonah's International School of Cosmetology",
        "hyphenated": "winonah's-international-school-of-cosmetology"
    },
    {
        "id": "491914",
        "name": "Yeshiva Gedola Tiferes Yerachmiel",
        "hyphenated": "yeshiva-gedola-tiferes-yerachmiel"
    },
    {
        "id": "491923",
        "name": "Southeastern Esthetics Institute",
        "hyphenated": "southeastern-esthetics-institute"
    },
    {
        "id": "491932",
        "name": "Installer Institute",
        "hyphenated": "installer-institute"
    },
    {
        "id": "491941",
        "name": "Caris College",
        "hyphenated": "caris-college"
    },
    {
        "id": "491950",
        "name": "Contra Costa Medical Career College",
        "hyphenated": "contra-costa-medical-career-college"
    },
    {
        "id": "491969",
        "name": "Anousheh School of Hair",
        "hyphenated": "anousheh-school-of-hair"
    },
    {
        "id": "491978",
        "name": "Another Level Barbering and Cosmetology School",
        "hyphenated": "another-level-barbering-and-cosmetology-school"
    },
    {
        "id": "491996",
        "name": "The Salon Professional Academy-Georgetown",
        "hyphenated": "the-salon-professional-academy-georgetown"
    },
    {
        "id": "492005",
        "name": "Elite Academy of Hair Design",
        "hyphenated": "elite-academy-of-hair-design"
    },
    {
        "id": "492032",
        "name": "Amslee Institute",
        "hyphenated": "amslee-institute"
    },
    {
        "id": "492069",
        "name": "Champion Christian College",
        "hyphenated": "champion-christian-college"
    },
    {
        "id": "492078",
        "name": "Chicago Professional Center",
        "hyphenated": "chicago-professional-center"
    },
    {
        "id": "492096",
        "name": "D&S School of Cosmetology",
        "hyphenated": "d&s-school-of-cosmetology"
    },
    {
        "id": "492139",
        "name": "DSDT",
        "hyphenated": "dsdt"
    },
    {
        "id": "492157",
        "name": "Houston School of Carpentry",
        "hyphenated": "houston-school-of-carpentry"
    },
    {
        "id": "492209",
        "name": "Reiss-Davis Graduate School",
        "hyphenated": "reiss-davis-graduate-school"
    },
    {
        "id": "492281",
        "name": "GoodFellas Barber College",
        "hyphenated": "goodfellas-barber-college"
    },
    {
        "id": "492306",
        "name": "Escondido Adult School",
        "hyphenated": "escondido-adult-school"
    },
    {
        "id": "492315",
        "name": "Poway Adult School",
        "hyphenated": "poway-adult-school"
    },
    {
        "id": "492324",
        "name": "Vista Adult School",
        "hyphenated": "vista-adult-school"
    },
    {
        "id": "492360",
        "name": "The Salon Professional Academy-Delray Beach",
        "hyphenated": "the-salon-professional-academy-delray-beach"
    },
    {
        "id": "492379",
        "name": "Lynnes Welding Training-Bismarck",
        "hyphenated": "lynnes-welding-training-bismarck"
    },
    {
        "id": "492421",
        "name": "No Grease Barber School",
        "hyphenated": "no-grease-barber-school"
    },
    {
        "id": "492449",
        "name": "Pima Medical Institute-San Marcos",
        "hyphenated": "pima-medical-institute-san-marcos"
    },
    {
        "id": "492476",
        "name": "EDP University of Puerto Rico-Villalba",
        "hyphenated": "edp-university-of-puerto-rico-villalba"
    },
    {
        "id": "492607",
        "name": "Chicago School of Professional Psychology at Dallas",
        "hyphenated": "chicago-school-of-professional-psychology-at-dallas"
    },
    {
        "id": "492643",
        "name": "American Massage & Bodywork Institute",
        "hyphenated": "american-massage-&-bodywork-institute"
    },
    {
        "id": "492652",
        "name": "KCK Beauty & Barber Academy",
        "hyphenated": "kck-beauty-&-barber-academy"
    },
    {
        "id": "492661",
        "name": "California College of Barbering and Cosmetology",
        "hyphenated": "california-college-of-barbering-and-cosmetology"
    },
    {
        "id": "492689",
        "name": "Texas Tech University Health Sciences Center-El Paso",
        "hyphenated": "texas-tech-university-health-sciences-center-el-paso"
    },
    {
        "id": "492704",
        "name": "Ponce Health Sciences University-Centro Universitario de San Juan",
        "hyphenated": "ponce-health-sciences-university-centro-universitario-de-san-juan"
    },
    {
        "id": "492722",
        "name": "The College of Health Care Professions-South San Antonio",
        "hyphenated": "the-college-of-health-care-professions-south-san-antonio"
    },
    {
        "id": "492731",
        "name": "Innovate Salon Academy",
        "hyphenated": "innovate-salon-academy"
    },
    {
        "id": "492759",
        "name": "Chamberlain University-Louisiana",
        "hyphenated": "chamberlain-university-louisiana"
    },
    {
        "id": "492801",
        "name": "Drury University-College of Continuing Professional Studies",
        "hyphenated": "drury-university-college-of-continuing-professional-studies"
    },
    {
        "id": "492935",
        "name": "Global Medical & Technical Training Institute",
        "hyphenated": "global-medical-&-technical-training-institute"
    },
    {
        "id": "492953",
        "name": "Salon Success Academy-West Covina",
        "hyphenated": "salon-success-academy-west-covina"
    },
    {
        "id": "492962",
        "name": "Indiana Institute of Technology-College of Professional Studies",
        "hyphenated": "indiana-institute-of-technology-college-of-professional-studies"
    },
    {
        "id": "493071",
        "name": "Advantage Technical College",
        "hyphenated": "advantage-technical-college"
    },
    {
        "id": "493080",
        "name": "Institute of Culinary Education",
        "hyphenated": "institute-of-culinary-education"
    },
    {
        "id": "493132",
        "name": "Universal Technical Institute-Bloomfield",
        "hyphenated": "universal-technical-institute-bloomfield"
    },
    {
        "id": "493150",
        "name": "Gould's Academy",
        "hyphenated": "gould's-academy"
    },
    {
        "id": "493372",
        "name": "Urban Academy of Beauty",
        "hyphenated": "urban-academy-of-beauty"
    },
    {
        "id": "493451",
        "name": "WellSpring School of Allied Health-Springfield",
        "hyphenated": "wellspring-school-of-allied-health-springfield"
    },
    {
        "id": "493479",
        "name": "Medical Career College of Northern California",
        "hyphenated": "medical-career-college-of-northern-california"
    },
    {
        "id": "493488",
        "name": "Premiere International College",
        "hyphenated": "premiere-international-college"
    },
    {
        "id": "493503",
        "name": "American Fitness and Nutrition Academy",
        "hyphenated": "american-fitness-and-nutrition-academy"
    },
    {
        "id": "493512",
        "name": "San Diego Global Knowledge University",
        "hyphenated": "san-diego-global-knowledge-university"
    },
    {
        "id": "493521",
        "name": "Global Tech College",
        "hyphenated": "global-tech-college"
    },
    {
        "id": "493530",
        "name": "Crown Cutz Academy Bristol",
        "hyphenated": "crown-cutz-academy-bristol"
    },
    {
        "id": "493549",
        "name": "MCI",
        "hyphenated": "mci"
    },
    {
        "id": "493558",
        "name": "Central Pennsylvania Diesel Institute",
        "hyphenated": "central-pennsylvania-diesel-institute"
    },
    {
        "id": "493567",
        "name": "Bellasa Professional Institute",
        "hyphenated": "bellasa-professional-institute"
    },
    {
        "id": "493576",
        "name": "PMCA Pittsburgh Multicultural Cosmetology Academy",
        "hyphenated": "pmca-pittsburgh-multicultural-cosmetology-academy"
    },
    {
        "id": "493585",
        "name": "Health and Technology Training Institute",
        "hyphenated": "health-and-technology-training-institute"
    },
    {
        "id": "493594",
        "name": "Yeshiva of Ocean",
        "hyphenated": "yeshiva-of-ocean"
    },
    {
        "id": "493600",
        "name": "Congregation Talmidei Mesivta Tiferes Shmiel Aleksander",
        "hyphenated": "congregation-talmidei-mesivta-tiferes-shmiel-aleksander"
    },
    {
        "id": "493619",
        "name": "Young Americans College of the Performing Arts",
        "hyphenated": "young-americans-college-of-the-performing-arts"
    },
    {
        "id": "493628",
        "name": "Pro Beauty Academy",
        "hyphenated": "pro-beauty-academy"
    },
    {
        "id": "493637",
        "name": "South Eastern Beauty Academy",
        "hyphenated": "south-eastern-beauty-academy"
    },
    {
        "id": "493646",
        "name": "Ohel Margulia Seminary",
        "hyphenated": "ohel-margulia-seminary"
    },
    {
        "id": "493655",
        "name": "Montana Barber Institute",
        "hyphenated": "montana-barber-institute"
    },
    {
        "id": "493664",
        "name": "Yeshivat Hechal Shemuel",
        "hyphenated": "yeshivat-hechal-shemuel"
    },
    {
        "id": "493673",
        "name": "Professional University Dr. Carlos J. Borrero Rios",
        "hyphenated": "professional-university-dr.-carlos-j.-borrero-rios"
    },
    {
        "id": "493682",
        "name": "American Institute of Healthcare & Technology",
        "hyphenated": "american-institute-of-healthcare-&-technology"
    },
    {
        "id": "493691",
        "name": "Premier Academy of Cosmetology",
        "hyphenated": "premier-academy-of-cosmetology"
    },
    {
        "id": "493707",
        "name": "Yeshiva Gedolah of Woodlake Village",
        "hyphenated": "yeshiva-gedolah-of-woodlake-village"
    },
    {
        "id": "493716",
        "name": "Yeshiva Gedola Tiferes Yaakov Yitzchok",
        "hyphenated": "yeshiva-gedola-tiferes-yaakov-yitzchok"
    },
    {
        "id": "493725",
        "name": "University of Arkansas System eVersity",
        "hyphenated": "university-of-arkansas-system-eversity"
    },
    {
        "id": "493734",
        "name": "Careers Institute of America",
        "hyphenated": "careers-institute-of-america"
    },
    {
        "id": "493743",
        "name": "Houston Barber School",
        "hyphenated": "houston-barber-school"
    },
    {
        "id": "493752",
        "name": "Health-Tech Institute of Memphis",
        "hyphenated": "health-tech-institute-of-memphis"
    },
    {
        "id": "493761",
        "name": "MIXED Institute of Cosmetology & Barber",
        "hyphenated": "mixed-institute-of-cosmetology-&-barber"
    },
    {
        "id": "493770",
        "name": "Vski Cosmetology School",
        "hyphenated": "vski-cosmetology-school"
    },
    {
        "id": "493789",
        "name": "Shear Perfection Academy of Cosmetology",
        "hyphenated": "shear-perfection-academy-of-cosmetology"
    },
    {
        "id": "493798",
        "name": "New York Seminary",
        "hyphenated": "new-york-seminary"
    },
    {
        "id": "493804",
        "name": "Deluxe Barber College",
        "hyphenated": "deluxe-barber-college"
    },
    {
        "id": "493813",
        "name": "Falcon Institute of Health and Science",
        "hyphenated": "falcon-institute-of-health-and-science"
    },
    {
        "id": "493822",
        "name": "College Unbound",
        "hyphenated": "college-unbound"
    },
    {
        "id": "493831",
        "name": "Midwest Barber College",
        "hyphenated": "midwest-barber-college"
    },
    {
        "id": "493868",
        "name": "A Better U Beauty Barber Academy",
        "hyphenated": "a-better-u-beauty-barber-academy"
    },
    {
        "id": "493895",
        "name": "SANS Technology Institute",
        "hyphenated": "sans-technology-institute"
    },
    {
        "id": "493910",
        "name": "Tuana European Beauty Academy",
        "hyphenated": "tuana-european-beauty-academy"
    },
    {
        "id": "493947",
        "name": "Idaho College of Osteopathic Medicine",
        "hyphenated": "idaho-college-of-osteopathic-medicine"
    },
    {
        "id": "493992",
        "name": "Southern Careers Institute-Waco",
        "hyphenated": "southern-careers-institute-waco"
    },
    {
        "id": "494001",
        "name": "Elevate Salon Institute",
        "hyphenated": "elevate-salon-institute"
    },
    {
        "id": "494010",
        "name": "Ponce Health Sciences University-St Louis",
        "hyphenated": "ponce-health-sciences-university-st-louis"
    },
    {
        "id": "494029",
        "name": "Lil Lou's Beauty and Barber College-Hammond",
        "hyphenated": "lil-lou's-beauty-and-barber-college-hammond"
    },
    {
        "id": "494162",
        "name": "Arizona College of Nursing-Dallas",
        "hyphenated": "arizona-college-of-nursing-dallas"
    },
    {
        "id": "494232",
        "name": "The Salon Professional Academy-Washington DC",
        "hyphenated": "the-salon-professional-academy-washington-dc"
    },
    {
        "id": "494250",
        "name": "Valley College-Cleveland",
        "hyphenated": "valley-college-cleveland"
    },
    {
        "id": "494269",
        "name": "National American University-Kings Bay",
        "hyphenated": "national-american-university-kings-bay"
    },
    {
        "id": "494278",
        "name": "EDP University of Puerto Rico-Humacao",
        "hyphenated": "edp-university-of-puerto-rico-humacao"
    },
    {
        "id": "494287",
        "name": "EDP University of Puerto Rico-Manati",
        "hyphenated": "edp-university-of-puerto-rico-manati"
    },
    {
        "id": "494357",
        "name": "UEI College-Sacramento",
        "hyphenated": "uei-college-sacramento"
    },
    {
        "id": "494436",
        "name": "Fortis Institute-Cookeville",
        "hyphenated": "fortis-institute-cookeville"
    },
    {
        "id": "494463",
        "name": "Derech Hachaim Seminary",
        "hyphenated": "derech-hachaim-seminary"
    },
    {
        "id": "494472",
        "name": "Evvaylois Academy School of Beauty",
        "hyphenated": "evvaylois-academy-school-of-beauty"
    },
    {
        "id": "494524",
        "name": "Avenue Five Institute-South Austin Campus",
        "hyphenated": "avenue-five-institute-south-austin-campus"
    },
    {
        "id": "494551",
        "name": "Glasgow Caledonian New York College",
        "hyphenated": "glasgow-caledonian-new-york-college"
    },
    {
        "id": "494588",
        "name": "Pima Medical Institute-San Antonio",
        "hyphenated": "pima-medical-institute-san-antonio"
    },
    {
        "id": "494597",
        "name": "Faith Theological Seminary and Christian College",
        "hyphenated": "faith-theological-seminary-and-christian-college"
    },
    {
        "id": "494603",
        "name": "The Southwestern Baptist Theological Seminary",
        "hyphenated": "the-southwestern-baptist-theological-seminary"
    },
    {
        "id": "494621",
        "name": "Lotus Professional College",
        "hyphenated": "lotus-professional-college"
    },
    {
        "id": "494630",
        "name": "Christ Mission College",
        "hyphenated": "christ-mission-college"
    },
    {
        "id": "494649",
        "name": "Esteem Academy of Beauty",
        "hyphenated": "esteem-academy-of-beauty"
    },
    {
        "id": "494658",
        "name": "KC Beauty Academy",
        "hyphenated": "kc-beauty-academy"
    },
    {
        "id": "494685",
        "name": "Urshan College",
        "hyphenated": "urshan-college"
    },
    {
        "id": "494694",
        "name": "Texas Healthtech Institute",
        "hyphenated": "texas-healthtech-institute"
    },
    {
        "id": "494700",
        "name": "Xtylo Beauty College",
        "hyphenated": "xtylo-beauty-college"
    },
    {
        "id": "494719",
        "name": "Tonsorial Arts Barber College",
        "hyphenated": "tonsorial-arts-barber-college"
    },
    {
        "id": "494728",
        "name": "Michael's Barber & Hair Stylist Academy",
        "hyphenated": "michael's-barber-&-hair-stylist-academy"
    },
    {
        "id": "494737",
        "name": "Yeshiva Yesoda Hatorah Vetz Chaim",
        "hyphenated": "yeshiva-yesoda-hatorah-vetz-chaim"
    },
    {
        "id": "494746",
        "name": "North-West College-San Diego",
        "hyphenated": "north-west-college-san-diego"
    },
    {
        "id": "494755",
        "name": "West Virginia Junior College-United Career Institute",
        "hyphenated": "west-virginia-junior-college-united-career-institute"
    },
    {
        "id": "494764",
        "name": "Aviation Institute of Maintenance-Charlotte",
        "hyphenated": "aviation-institute-of-maintenance-charlotte"
    },
    {
        "id": "494807",
        "name": "Ponce Health Sciences University-East",
        "hyphenated": "ponce-health-sciences-university-east"
    },
    {
        "id": "494843",
        "name": "Fortis College-Landover",
        "hyphenated": "fortis-college-landover"
    },
    {
        "id": "494852",
        "name": "Stautzenberger College-Rockford Career College",
        "hyphenated": "stautzenberger-college-rockford-career-college"
    },
    {
        "id": "494898",
        "name": "WellSpring School of Allied Health-Wichita",
        "hyphenated": "wellspring-school-of-allied-health-wichita"
    },
    {
        "id": "494904",
        "name": "Access Careers-Islandia",
        "hyphenated": "access-careers-islandia"
    },
    {
        "id": "494940",
        "name": "California Arts University",
        "hyphenated": "california-arts-university"
    },
    {
        "id": "494959",
        "name": "Northern Pennsylvania Regional College",
        "hyphenated": "northern-pennsylvania-regional-college"
    },
    {
        "id": "494977",
        "name": "American Medical Institute Inc.",
        "hyphenated": "american-medical-institute-inc."
    },
    {
        "id": "494986",
        "name": "Apex College of Veterinary Technology",
        "hyphenated": "apex-college-of-veterinary-technology"
    },
    {
        "id": "494995",
        "name": "Meryma'at Barber College",
        "hyphenated": "meryma'at-barber-college"
    },
    {
        "id": "495013",
        "name": "Western Maricopa Education Center",
        "hyphenated": "western-maricopa-education-center"
    },
    {
        "id": "495031",
        "name": "Bais Medrash of Dexter Park",
        "hyphenated": "bais-medrash-of-dexter-park"
    },
    {
        "id": "495040",
        "name": "Makana Esthetics Wellness Academy",
        "hyphenated": "makana-esthetics-wellness-academy"
    },
    {
        "id": "495059",
        "name": "Oak Valley College",
        "hyphenated": "oak-valley-college"
    },
    {
        "id": "495077",
        "name": "Paul Mitchell the School-San Jose",
        "hyphenated": "paul-mitchell-the-school-san-jose"
    },
    {
        "id": "495101",
        "name": "Zion Massage College",
        "hyphenated": "zion-massage-college"
    },
    {
        "id": "495110",
        "name": "Great Lakes Boat Building School",
        "hyphenated": "great-lakes-boat-building-school"
    },
    {
        "id": "495129",
        "name": "Austin Career Institute",
        "hyphenated": "austin-career-institute"
    },
    {
        "id": "495138",
        "name": "Professional Culinary Academy",
        "hyphenated": "professional-culinary-academy"
    },
    {
        "id": "495147",
        "name": "Pacific Northwest Christian College",
        "hyphenated": "pacific-northwest-christian-college"
    },
    {
        "id": "495165",
        "name": "Royal Learning Institute",
        "hyphenated": "royal-learning-institute"
    },
    {
        "id": "495174",
        "name": "Hollywood Cultural College",
        "hyphenated": "hollywood-cultural-college"
    },
    {
        "id": "495183",
        "name": "Mariano Moreno Culinary Institute",
        "hyphenated": "mariano-moreno-culinary-institute"
    },
    {
        "id": "495192",
        "name": "Alder Graduate School of Education",
        "hyphenated": "alder-graduate-school-of-education"
    },
    {
        "id": "495208",
        "name": "Webb's Barber School of Arts",
        "hyphenated": "webb's-barber-school-of-arts"
    },
    {
        "id": "495217",
        "name": "Adrian H. Wallace Barber Academy",
        "hyphenated": "adrian-h.-wallace-barber-academy"
    },
    {
        "id": "495226",
        "name": "St. Louis Med Tech",
        "hyphenated": "st.-louis-med-tech"
    },
    {
        "id": "495235",
        "name": "Total Beauty Institute",
        "hyphenated": "total-beauty-institute"
    },
    {
        "id": "495244",
        "name": "Pearlands Innovative School of Beauty",
        "hyphenated": "pearlands-innovative-school-of-beauty"
    },
    {
        "id": "495253",
        "name": "Durant Institute of Hair Design",
        "hyphenated": "durant-institute-of-hair-design"
    },
    {
        "id": "495262",
        "name": "Premiere Aesthetics Institute",
        "hyphenated": "premiere-aesthetics-institute"
    },
    {
        "id": "495271",
        "name": "California Indian Nations College",
        "hyphenated": "california-indian-nations-college"
    },
    {
        "id": "495280",
        "name": "Indian Bible College",
        "hyphenated": "indian-bible-college"
    },
    {
        "id": "495299",
        "name": "Florida Professional Institute",
        "hyphenated": "florida-professional-institute"
    },
    {
        "id": "495305",
        "name": "The Professional Cosmetology Academy",
        "hyphenated": "the-professional-cosmetology-academy"
    },
    {
        "id": "495314",
        "name": "Hackensack Meridian School of Medicine",
        "hyphenated": "hackensack-meridian-school-of-medicine"
    },
    {
        "id": "495341",
        "name": "Taylor Andrews Academy of Hair Design-Hair Lab Detroit Barber School",
        "hyphenated": "taylor-andrews-academy-of-hair-design-hair-lab-detroit-barber-school"
    },
    {
        "id": "495350",
        "name": "Paul Mitchell the School-North Tahoe",
        "hyphenated": "paul-mitchell-the-school-north-tahoe"
    },
    {
        "id": "495369",
        "name": "Paul Mitchell the School-Fort Lauderdale",
        "hyphenated": "paul-mitchell-the-school-fort-lauderdale"
    },
    {
        "id": "495378",
        "name": "PCI Academy-Iowa City",
        "hyphenated": "pci-academy-iowa-city"
    },
    {
        "id": "495387",
        "name": "Larry's Barber College",
        "hyphenated": "larry's-barber-college"
    },
    {
        "id": "495396",
        "name": "Royal Learning Institute",
        "hyphenated": "royal-learning-institute"
    },
    {
        "id": "495411",
        "name": "Paul Mitchell the School-Provo",
        "hyphenated": "paul-mitchell-the-school-provo"
    },
    {
        "id": "495420",
        "name": "Arizona College of Nursing-Tampa",
        "hyphenated": "arizona-college-of-nursing-tampa"
    },
    {
        "id": "495439",
        "name": "Arizona College of Nursing-Fort Lauderdale",
        "hyphenated": "arizona-college-of-nursing-fort-lauderdale"
    },
    {
        "id": "495448",
        "name": "Arizona College of Nursing-Tucson",
        "hyphenated": "arizona-college-of-nursing-tucson"
    },
    {
        "id": "495457",
        "name": "Arizona College of Nursing-Phoenix",
        "hyphenated": "arizona-college-of-nursing-phoenix"
    },
    {
        "id": "495518",
        "name": "Empire Beauty School-Newport News",
        "hyphenated": "empire-beauty-school-newport-news"
    },
    {
        "id": "495730",
        "name": "Tricoci University of Beauty Culture-Normal",
        "hyphenated": "tricoci-university-of-beauty-culture-normal"
    },
    {
        "id": "495776",
        "name": "Elite Welding Academy",
        "hyphenated": "elite-welding-academy"
    },
    {
        "id": "495794",
        "name": "Paul Mitchell the School-Murfreesboro-Nashville",
        "hyphenated": "paul-mitchell-the-school-murfreesboro-nashville"
    },
    {
        "id": "495837",
        "name": "Dorsey College-Woodhaven",
        "hyphenated": "dorsey-college-woodhaven"
    },
    {
        "id": "495916",
        "name": "Pathways College",
        "hyphenated": "pathways-college"
    },
    {
        "id": "495925",
        "name": "San Joaquin Valley College-Madera",
        "hyphenated": "san-joaquin-valley-college-madera"
    },
    {
        "id": "495934",
        "name": "San Joaquin Valley College-Delano",
        "hyphenated": "san-joaquin-valley-college-delano"
    },
    {
        "id": "495943",
        "name": "San Joaquin Valley College-Atascadero",
        "hyphenated": "san-joaquin-valley-college-atascadero"
    },
    {
        "id": "495952",
        "name": "San Joaquin Valley College-Rancho Mirage",
        "hyphenated": "san-joaquin-valley-college-rancho-mirage"
    },
    {
        "id": "495961",
        "name": "San Joaquin Valley College-Santa Maria",
        "hyphenated": "san-joaquin-valley-college-santa-maria"
    },
    {
        "id": "495970",
        "name": "Advantage Technical College-Aguadilla",
        "hyphenated": "advantage-technical-college-aguadilla"
    },
    {
        "id": "495998",
        "name": "San Joaquin Valley College-Porterville",
        "hyphenated": "san-joaquin-valley-college-porterville"
    },
    {
        "id": "496043",
        "name": "California University of Science and Medicine",
        "hyphenated": "california-university-of-science-and-medicine"
    },
    {
        "id": "496052",
        "name": "Ruben's Five Star Academy",
        "hyphenated": "ruben's-five-star-academy"
    },
    {
        "id": "496061",
        "name": "Mason Anthony School of Cosmetology Arts & Sciences",
        "hyphenated": "mason-anthony-school-of-cosmetology-arts-&-sciences"
    },
    {
        "id": "496186",
        "name": "California Institute of Arts & Technology-National City",
        "hyphenated": "california-institute-of-arts-&-technology-national-city"
    },
    {
        "id": "496265",
        "name": "Triangle Tech-Chambersburg",
        "hyphenated": "triangle-tech-chambersburg"
    },
    {
        "id": "496283",
        "name": "Provo College-Idaho Falls Campus",
        "hyphenated": "provo-college-idaho-falls-campus"
    },
    {
        "id": "496292",
        "name": "Miller-Motte College-Chattanooga 2",
        "hyphenated": "miller-motte-college-chattanooga-2"
    },
    {
        "id": "496326",
        "name": "Eagle Gate College-Boise Campus",
        "hyphenated": "eagle-gate-college-boise-campus"
    },
    {
        "id": "496371",
        "name": "Elite Welding Academy South Point",
        "hyphenated": "elite-welding-academy-south-point"
    },
    {
        "id": "496399",
        "name": "Agape College of Business and Science",
        "hyphenated": "agape-college-of-business-and-science"
    },
    {
        "id": "496469",
        "name": "Daybreak University",
        "hyphenated": "daybreak-university"
    },
    {
        "id": "496478",
        "name": "Motion Picture Institute",
        "hyphenated": "motion-picture-institute"
    },
    {
        "id": "496511",
        "name": "United College of Health and Beauty",
        "hyphenated": "united-college-of-health-and-beauty"
    },
    {
        "id": "496520",
        "name": "Zen Shiatsu Chicago",
        "hyphenated": "zen-shiatsu-chicago"
    },
    {
        "id": "496539",
        "name": "The Salon Professional Academy of Holland",
        "hyphenated": "the-salon-professional-academy-of-holland"
    },
    {
        "id": "496593",
        "name": "Turing School of Software & Design",
        "hyphenated": "turing-school-of-software-&-design"
    },
    {
        "id": "496618",
        "name": "National Institute for Medical Assistant Advancement (NIMAA)",
        "hyphenated": "national-institute-for-medical-assistant-advancement-(nimaa)"
    },
    {
        "id": "496627",
        "name": "California Northstate University",
        "hyphenated": "california-northstate-university"
    },
    {
        "id": "496636",
        "name": "Antigua College International",
        "hyphenated": "antigua-college-international"
    },
    {
        "id": "496645",
        "name": "Burlington County Institute of Technology-Adult Education",
        "hyphenated": "burlington-county-institute-of-technology-adult-education"
    },
    {
        "id": "496654",
        "name": "Sri Sai Krish Institute",
        "hyphenated": "sri-sai-krish-institute"
    },
    {
        "id": "496663",
        "name": "Riggins Urban Barber College",
        "hyphenated": "riggins-urban-barber-college"
    },
    {
        "id": "496672",
        "name": "Hair Arts Institute",
        "hyphenated": "hair-arts-institute"
    },
    {
        "id": "496681",
        "name": "Florida Academy of Nursing",
        "hyphenated": "florida-academy-of-nursing"
    },
    {
        "id": "496690",
        "name": "Body Wisdom Massage Therapy School",
        "hyphenated": "body-wisdom-massage-therapy-school"
    },
    {
        "id": "496706",
        "name": "Lakewood University",
        "hyphenated": "lakewood-university"
    },
    {
        "id": "496715",
        "name": "CTK Healthcare & Career Institute",
        "hyphenated": "ctk-healthcare-&-career-institute"
    },
    {
        "id": "496724",
        "name": "Aesthetics Institute",
        "hyphenated": "aesthetics-institute"
    },
    {
        "id": "496733",
        "name": "Sierra Academy of Style",
        "hyphenated": "sierra-academy-of-style"
    },
    {
        "id": "496742",
        "name": "Jolie Hair Academy",
        "hyphenated": "jolie-hair-academy"
    },
    {
        "id": "496751",
        "name": "Institute of Allied Healthcare",
        "hyphenated": "institute-of-allied-healthcare"
    },
    {
        "id": "496894",
        "name": "Branford Academy of Hair & Cosmetology-Bridgeport",
        "hyphenated": "branford-academy-of-hair-&-cosmetology-bridgeport"
    },
    {
        "id": "496919",
        "name": "United Education Institute-Las Vegas",
        "hyphenated": "united-education-institute-las-vegas"
    },
    {
        "id": "496955",
        "name": "UEI College-Mesa",
        "hyphenated": "uei-college-mesa"
    },
    {
        "id": "496964",
        "name": "Larry's Barber College-Joliet",
        "hyphenated": "larry's-barber-college-joliet"
    },
    {
        "id": "496973",
        "name": "Herzing University-Tampa",
        "hyphenated": "herzing-university-tampa"
    },
    {
        "id": "496991",
        "name": "Galen Health Institutes-Miami Campus",
        "hyphenated": "galen-health-institutes-miami-campus"
    },
    {
        "id": "497000",
        "name": "Remington College-Knoxville",
        "hyphenated": "remington-college-knoxville"
    },
    {
        "id": "497037",
        "name": "Abilene Christian University-Undergraduate Online",
        "hyphenated": "abilene-christian-university-undergraduate-online"
    },
    {
        "id": "497046",
        "name": "Madera Community College",
        "hyphenated": "madera-community-college"
    },
    {
        "id": "497073",
        "name": "Ogle School Hair Skin Nails-Willowbrook",
        "hyphenated": "ogle-school-hair-skin-nails-willowbrook"
    },
    {
        "id": "497116",
        "name": "Galen Health Institutes-Austin Campus",
        "hyphenated": "galen-health-institutes-austin-campus"
    },
    {
        "id": "497125",
        "name": "Galen Health Institutes-Nashville Campus",
        "hyphenated": "galen-health-institutes-nashville-campus"
    },
    {
        "id": "497161",
        "name": "Ohio Business College-Dayton-Driving Academy",
        "hyphenated": "ohio-business-college-dayton-driving-academy"
    },
    {
        "id": "497189",
        "name": "The Rapha School",
        "hyphenated": "the-rapha-school"
    },
    {
        "id": "497198",
        "name": "Academy of Allied Health Careers",
        "hyphenated": "academy-of-allied-health-careers"
    },
    {
        "id": "497204",
        "name": "Mei Barber School",
        "hyphenated": "mei-barber-school"
    },
    {
        "id": "497213",
        "name": "Great Northern University",
        "hyphenated": "great-northern-university"
    },
    {
        "id": "497222",
        "name": "Salon Professional Academy-Elevate Salon Institute",
        "hyphenated": "salon-professional-academy-elevate-salon-institute"
    },
    {
        "id": "497268",
        "name": "Arizona College of Nursing-Salt Lake City",
        "hyphenated": "arizona-college-of-nursing-salt-lake-city"
    },
    {
        "id": "497277",
        "name": "Arizona College-Glendale",
        "hyphenated": "arizona-college-glendale"
    },
    {
        "id": "497286",
        "name": "Universal Technical Institute-West Texas",
        "hyphenated": "universal-technical-institute-west-texas"
    },
    {
        "id": "497301",
        "name": "Avalon Institute-Las Vegas",
        "hyphenated": "avalon-institute-las-vegas"
    },
    {
        "id": "497310",
        "name": "Medspa Academies-National Institute of Modern Aesthetics",
        "hyphenated": "medspa-academies-national-institute-of-modern-aesthetics"
    },
    {
        "id": "497329",
        "name": "American Institute-Cherry Hill",
        "hyphenated": "american-institute-cherry-hill"
    },
    {
        "id": "497338",
        "name": "Glendale Career College-North-West College-Bakersfield",
        "hyphenated": "glendale-career-college-north-west-college-bakersfield"
    },
    {
        "id": "10236801",
        "name": "Troy University-Phenix City Campus",
        "hyphenated": "troy-university-phenix-city-campus"
    },
    {
        "id": "10236802",
        "name": "Troy University-Montgomery Campus",
        "hyphenated": "troy-university-montgomery-campus"
    },
    {
        "id": "10236803",
        "name": "Troy University-Dothan Campus",
        "hyphenated": "troy-university-dothan-campus"
    },
    {
        "id": "10236808",
        "name": "Troy University-Online",
        "hyphenated": "troy-university-online"
    },
    {
        "id": "10236809",
        "name": "Troy University-Support Sites",
        "hyphenated": "troy-university-support-sites"
    },
    {
        "id": "10415101",
        "name": "Arizona State University-West",
        "hyphenated": "arizona-state-university-west"
    },
    {
        "id": "10415102",
        "name": "Arizona State University-Polytechnic",
        "hyphenated": "arizona-state-university-polytechnic"
    },
    {
        "id": "10415103",
        "name": "Arizona State University-Downtown Phoenix",
        "hyphenated": "arizona-state-university-downtown-phoenix"
    },
    {
        "id": "10415104",
        "name": "Arizona State University - Tucson",
        "hyphenated": "arizona-state-university---tucson"
    },
    {
        "id": "10415105",
        "name": "Arizona State University - Lake Havasu",
        "hyphenated": "arizona-state-university---lake-havasu"
    },
    {
        "id": "10415106",
        "name": "Arizona State University - The Gila Valley",
        "hyphenated": "arizona-state-university---the-gila-valley"
    },
    {
        "id": "10415107",
        "name": "Arizona State University - Yuma",
        "hyphenated": "arizona-state-university---yuma"
    },
    {
        "id": "10415108",
        "name": "Arizona State University - Pinal",
        "hyphenated": "arizona-state-university---pinal"
    },
    {
        "id": "10415109",
        "name": "Arizona State University - Cochise",
        "hyphenated": "arizona-state-university---cochise"
    },
    {
        "id": "10415110",
        "name": "Arizona State University - Yavapai",
        "hyphenated": "arizona-state-university---yavapai"
    },
    {
        "id": "10415111",
        "name": "Arizona State University - Pima",
        "hyphenated": "arizona-state-university---pima"
    },
    {
        "id": "10415112",
        "name": "Arizona State University - Washington D.C.",
        "hyphenated": "arizona-state-university---washington-d.c."
    },
    {
        "id": "10635101",
        "name": "Arkansas College of Barbering and Hair Design",
        "hyphenated": "arkansas-college-of-barbering-and-hair-design"
    },
    {
        "id": "10704401",
        "name": "Harding School of Theology",
        "hyphenated": "harding-school-of-theology"
    },
    {
        "id": "10722001",
        "name": "Career Academy of Hair Design-Siloam Springs",
        "hyphenated": "career-academy-of-hair-design-siloam-springs"
    },
    {
        "id": "10722002",
        "name": "Career Academy of Hair Design-Rogers",
        "hyphenated": "career-academy-of-hair-design-rogers"
    },
    {
        "id": "10722003",
        "name": "Career Academy of Hair Design-Fayetteville",
        "hyphenated": "career-academy-of-hair-design-fayetteville"
    },
    {
        "id": "10722004",
        "name": "Career Academy of Hair Design - Fort Smith",
        "hyphenated": "career-academy-of-hair-design---fort-smith"
    },
    {
        "id": "10772501",
        "name": "University of Arkansas Community College at Hope - Texarkana",
        "hyphenated": "university-of-arkansas-community-college-at-hope---texarkana"
    },
    {
        "id": "11046801",
        "name": "Alliant International University-Fresno",
        "hyphenated": "alliant-international-university-fresno"
    },
    {
        "id": "11046802",
        "name": "Alliant International University-Los Angeles",
        "hyphenated": "alliant-international-university-los-angeles"
    },
    {
        "id": "11046803",
        "name": "Alliant International University-San Francisco",
        "hyphenated": "alliant-international-university-san-francisco"
    },
    {
        "id": "11046804",
        "name": "Alliant International University-Irvine",
        "hyphenated": "alliant-international-university-irvine"
    },
    {
        "id": "11046805",
        "name": "Alliant International University-Sacramento",
        "hyphenated": "alliant-international-university-sacramento"
    },
    {
        "id": "11257001",
        "name": "Flashpoint Chicago A Campus of Columbia College Hollywood",
        "hyphenated": "flashpoint-chicago-a-campus-of-columbia-college-hollywood"
    },
    {
        "id": "11458501",
        "name": "San Jose Campus",
        "hyphenated": "san-jose-campus"
    },
    {
        "id": "11508305",
        "name": "Golden Gate University-Seattle",
        "hyphenated": "golden-gate-university-seattle"
    },
    {
        "id": "11508306",
        "name": "Golden Gate University-Silicon Valley",
        "hyphenated": "golden-gate-university-silicon-valley"
    },
    {
        "id": "11577301",
        "name": "Humphreys College-Modesto",
        "hyphenated": "humphreys-college-modesto"
    },
    {
        "id": "11635001",
        "name": "TONI&GUY Hairdressing Academy - Costa Mesa",
        "hyphenated": "toni&guy-hairdressing-academy---costa-mesa"
    },
    {
        "id": "11643901",
        "name": "IDEA at ATEP",
        "hyphenated": "idea-at-atep"
    },
    {
        "id": "11710401",
        "name": "Life Pacific University-Virginia",
        "hyphenated": "life-pacific-university-virginia"
    },
    {
        "id": "11891201",
        "name": "MiraCosta College - San Elijo Campus",
        "hyphenated": "miracosta-college---san-elijo-campus"
    },
    {
        "id": "11917301",
        "name": "Mount Saint Mary's University - Doheny Campus",
        "hyphenated": "mount-saint-mary's-university---doheny-campus"
    },
    {
        "id": "12240901",
        "name": "San Diego State University-Imperial Valley Campus",
        "hyphenated": "san-diego-state-university-imperial-valley-campus"
    },
    {
        "id": "12265801",
        "name": "San Joaquin General Hospital School of Radiation Technology",
        "hyphenated": "san-joaquin-general-hospital-school-of-radiation-technology"
    },
    {
        "id": "12293101",
        "name": "Jesuit School of Theology of Santa Clara University",
        "hyphenated": "jesuit-school-of-theology-of-santa-clara-university"
    },
    {
        "id": "12429201",
        "name": "Thomas Aquinas College - New England",
        "hyphenated": "thomas-aquinas-college---new-england"
    },
    {
        "id": "12509101",
        "name": "Victor Valley Community College - Aviation Technology",
        "hyphenated": "victor-valley-community-college---aviation-technology"
    },
    {
        "id": "12601201",
        "name": "Wright Institute (The) -",
        "hyphenated": "wright-institute-(the)--"
    },
    {
        "id": "12682703",
        "name": "Colorado Technical University-Denver South",
        "hyphenated": "colorado-technical-university-denver-south"
    },
    {
        "id": "12697901",
        "name": "Denver Seminary - Washington DC",
        "hyphenated": "denver-seminary---washington-dc"
    },
    {
        "id": "12777801",
        "name": "Otero College - La Junta High School",
        "hyphenated": "otero-college---la-junta-high-school"
    },
    {
        "id": "12980801",
        "name": "Three Rivers Community College - Corrigan-Radgowski Correctional Center",
        "hyphenated": "three-rivers-community-college---corrigan-radgowski-correctional-center"
    },
    {
        "id": "12980802",
        "name": "Three Rivers Community College - York Correctional Institution",
        "hyphenated": "three-rivers-community-college---york-correctional-institution"
    },
    {
        "id": "13017401",
        "name": "Porter and Chester Institute of Rocky Hill",
        "hyphenated": "porter-and-chester-institute-of-rocky-hill"
    },
    {
        "id": "13017402",
        "name": "Porter and Chester Institute of Enfield",
        "hyphenated": "porter-and-chester-institute-of-enfield"
    },
    {
        "id": "13017403",
        "name": "Porter and Chester Institute of Waterbury",
        "hyphenated": "porter-and-chester-institute-of-waterbury"
    },
    {
        "id": "13017405",
        "name": "Porter and Chester Institute of Worcester",
        "hyphenated": "porter-and-chester-institute-of-worcester"
    },
    {
        "id": "13017407",
        "name": "Porter and Chester Institute - New London",
        "hyphenated": "porter-and-chester-institute---new-london"
    },
    {
        "id": "13017408",
        "name": "Porter and Chester Institute - Brockton",
        "hyphenated": "porter-and-chester-institute---brockton"
    },
    {
        "id": "13017409",
        "name": "Porter and Chester Institute - Chicopee",
        "hyphenated": "porter-and-chester-institute---chicopee"
    },
    {
        "id": "13090701",
        "name": "Delaware Technical Community College-Owens",
        "hyphenated": "delaware-technical-community-college-owens"
    },
    {
        "id": "13090702",
        "name": "Delaware Technical Community College-Stanton/Wilmington",
        "hyphenated": "delaware-technical-community-college-stanton/wilmington"
    },
    {
        "id": "13247101",
        "name": "Barry University Law School",
        "hyphenated": "barry-university-law-school"
    },
    {
        "id": "13508101",
        "name": "Keiser University-Melbourne",
        "hyphenated": "keiser-university-melbourne"
    },
    {
        "id": "13508102",
        "name": "Keiser University-Tallahassee",
        "hyphenated": "keiser-university-tallahassee"
    },
    {
        "id": "13508103",
        "name": "Keiser University-Daytona",
        "hyphenated": "keiser-university-daytona"
    },
    {
        "id": "13508104",
        "name": "Keiser University-Lakeland",
        "hyphenated": "keiser-university-lakeland"
    },
    {
        "id": "13508105",
        "name": "Keiser University- Miami",
        "hyphenated": "keiser-university--miami"
    },
    {
        "id": "13508106",
        "name": "Keiser University-Orlando",
        "hyphenated": "keiser-university-orlando"
    },
    {
        "id": "13508107",
        "name": "Keiser University-Sarasota",
        "hyphenated": "keiser-university-sarasota"
    },
    {
        "id": "13508108",
        "name": "Keiser University-Jacksonville",
        "hyphenated": "keiser-university-jacksonville"
    },
    {
        "id": "13508109",
        "name": "Keiser University-Tampa",
        "hyphenated": "keiser-university-tampa"
    },
    {
        "id": "13508110",
        "name": "Keiser University-Pembroke Pines",
        "hyphenated": "keiser-university-pembroke-pines"
    },
    {
        "id": "13508111",
        "name": "Keiser University-Port St Lucie",
        "hyphenated": "keiser-university-port-st-lucie"
    },
    {
        "id": "13508112",
        "name": "Keiser University-West Palm Beach",
        "hyphenated": "keiser-university-west-palm-beach"
    },
    {
        "id": "13508113",
        "name": "Keiser University-Ft Myers",
        "hyphenated": "keiser-university-ft-myers"
    },
    {
        "id": "13508114",
        "name": "Keiser University - Clearwater",
        "hyphenated": "keiser-university---clearwater"
    },
    {
        "id": "13508115",
        "name": "Keiser University - New Port Richey",
        "hyphenated": "keiser-university---new-port-richey"
    },
    {
        "id": "13508116",
        "name": "Keiser University - Residential (Flagship)",
        "hyphenated": "keiser-university---residential-(flagship)"
    },
    {
        "id": "13552201",
        "name": "Maynard A. Traviss Technical College - Central Florida Aerospace Academy",
        "hyphenated": "maynard-a.-traviss-technical-college---central-florida-aerospace-academy"
    },
    {
        "id": "13682601",
        "name": "Miami Lakes Educational Center and Technical College -",
        "hyphenated": "miami-lakes-educational-center-and-technical-college--"
    },
    {
        "id": "13830901",
        "name": "Rasmussen University-Central Pasco",
        "hyphenated": "rasmussen-university-central-pasco"
    },
    {
        "id": "13830902",
        "name": "Rasmussen University-Fort Myers",
        "hyphenated": "rasmussen-university-fort-myers"
    },
    {
        "id": "13830903",
        "name": "Rasmussen University-Tampa/Brandon",
        "hyphenated": "rasmussen-university-tampa/brandon"
    },
    {
        "id": "13830905",
        "name": "Rasmussen University - Orlando",
        "hyphenated": "rasmussen-university---orlando"
    },
    {
        "id": "13962101",
        "name": "East Georgia State College - Statesboro",
        "hyphenated": "east-georgia-state-college---statesboro"
    },
    {
        "id": "13962102",
        "name": "East Georgia State College - Augusta",
        "hyphenated": "east-georgia-state-college---augusta"
    },
    {
        "id": "14736901",
        "name": "Moody Theological Seminary and Graduate School--Michigan",
        "hyphenated": "moody-theological-seminary-and-graduate-school--michigan"
    },
    {
        "id": "14736903",
        "name": "Moody Bible Institute - Spokane",
        "hyphenated": "moody-bible-institute---spokane"
    },
    {
        "id": "14953201",
        "name": "Triton College - Intl Union of Operating Engr Local 399 Trning Fac.",
        "hyphenated": "triton-college---intl-union-of-operating-engr-local-399-trning-fac."
    },
    {
        "id": "15085301",
        "name": "PJ's College of Cosmetology- Brownsburg",
        "hyphenated": "pj's-college-of-cosmetology--brownsburg"
    },
    {
        "id": "15085303",
        "name": "PJ's College of Cosmetology- Jeffersonville",
        "hyphenated": "pj's-college-of-cosmetology--jeffersonville"
    },
    {
        "id": "15098701",
        "name": "Ivy Tech Community College-South Bend/Elkhart",
        "hyphenated": "ivy-tech-community-college-south-bend/elkhart"
    },
    {
        "id": "15098702",
        "name": "Ivy Tech Community College-Columbus",
        "hyphenated": "ivy-tech-community-college-columbus"
    },
    {
        "id": "15098703",
        "name": "Ivy Tech Community College-Muncie",
        "hyphenated": "ivy-tech-community-college-muncie"
    },
    {
        "id": "15098704",
        "name": "Ivy Tech Community College-Kokomo",
        "hyphenated": "ivy-tech-community-college-kokomo"
    },
    {
        "id": "15098705",
        "name": "Ivy Tech Community College-Lafayette",
        "hyphenated": "ivy-tech-community-college-lafayette"
    },
    {
        "id": "15098706",
        "name": "Ivy Tech Community College-Fort Wayne",
        "hyphenated": "ivy-tech-community-college-fort-wayne"
    },
    {
        "id": "15098707",
        "name": "Ivy Tech Community College-Sellersburg",
        "hyphenated": "ivy-tech-community-college-sellersburg"
    },
    {
        "id": "15098708",
        "name": "Ivy Tech Community College-Evansville",
        "hyphenated": "ivy-tech-community-college-evansville"
    },
    {
        "id": "15098709",
        "name": "Ivy Tech Community College-Terre Haute",
        "hyphenated": "ivy-tech-community-college-terre-haute"
    },
    {
        "id": "15098710",
        "name": "Ivy Tech Community College-Richmond",
        "hyphenated": "ivy-tech-community-college-richmond"
    },
    {
        "id": "15098711",
        "name": "Ivy Tech Community College-Lake County",
        "hyphenated": "ivy-tech-community-college-lake-county"
    },
    {
        "id": "15098712",
        "name": "Ivy Tech Community College-Madison",
        "hyphenated": "ivy-tech-community-college-madison"
    },
    {
        "id": "15098713",
        "name": "Ivy Tech Community College-Bloomington",
        "hyphenated": "ivy-tech-community-college-bloomington"
    },
    {
        "id": "15098714",
        "name": "Ivy Tech Community College-Indianapolis",
        "hyphenated": "ivy-tech-community-college-indianapolis"
    },
    {
        "id": "15208001",
        "name": "University of Notre Dame -",
        "hyphenated": "university-of-notre-dame--"
    },
    {
        "id": "15336601",
        "name": "Graceland University - Independence",
        "hyphenated": "graceland-university---independence"
    },
    {
        "id": "15468801",
        "name": "Baker University School of Nursing",
        "hyphenated": "baker-university-school-of-nursing"
    },
    {
        "id": "15492501",
        "name": "Coffeyville Technical Campus",
        "hyphenated": "coffeyville-technical-campus"
    },
    {
        "id": "15492502",
        "name": "Columbus Technical Campus",
        "hyphenated": "columbus-technical-campus"
    },
    {
        "id": "15535301",
        "name": "Old Town Barber College - KC",
        "hyphenated": "old-town-barber-college---kc"
    },
    {
        "id": "15631001",
        "name": "PJ's College of Cosmetology- Greenfield",
        "hyphenated": "pj's-college-of-cosmetology--greenfield"
    },
    {
        "id": "15631002",
        "name": "PJ's College of Cosmetology- Muncie",
        "hyphenated": "pj's-college-of-cosmetology--muncie"
    },
    {
        "id": "15631003",
        "name": "PJ's College of Cosmetology- Indianapolis",
        "hyphenated": "pj's-college-of-cosmetology--indianapolis"
    },
    {
        "id": "15631004",
        "name": "PJ's College of Cosmetology- Plainfield",
        "hyphenated": "pj's-college-of-cosmetology--plainfield"
    },
    {
        "id": "15631005",
        "name": "PJ's College of Cosmetology- Louisville",
        "hyphenated": "pj's-college-of-cosmetology--louisville"
    },
    {
        "id": "16061201",
        "name": "Southeastern Louisiana University - EBR Parish School System Prof Dev",
        "hyphenated": "southeastern-louisiana-university---ebr-parish-school-system-prof-dev"
    },
    {
        "id": "16061202",
        "name": "Southeastern Louisiana University - St Amant High School",
        "hyphenated": "southeastern-louisiana-university---st-amant-high-school"
    },
    {
        "id": "16061203",
        "name": "Southeastern Louisiana University - Destrahan High School",
        "hyphenated": "southeastern-louisiana-university---destrahan-high-school"
    },
    {
        "id": "16061204",
        "name": "Southeastern Louisiana University - Albert Cammon Middle School",
        "hyphenated": "southeastern-louisiana-university---albert-cammon-middle-school"
    },
    {
        "id": "16125301",
        "name": "University of Maine - Machias",
        "hyphenated": "university-of-maine---machias"
    },
    {
        "id": "16149303",
        "name": "Empire Beauty School-Bangor",
        "hyphenated": "empire-beauty-school-bangor"
    },
    {
        "id": "16210401",
        "name": "Cecil College - Elkton Station",
        "hyphenated": "cecil-college---elkton-station"
    },
    {
        "id": "16855501",
        "name": "Hillsdale Beauty College",
        "hyphenated": "hillsdale-beauty-college"
    },
    {
        "id": "16884703",
        "name": "Baker College of Auburn Hills",
        "hyphenated": "baker-college-of-auburn-hills"
    },
    {
        "id": "16884704",
        "name": "Baker College of Cadillac",
        "hyphenated": "baker-college-of-cadillac"
    },
    {
        "id": "16884706",
        "name": "Baker College of Jackson",
        "hyphenated": "baker-college-of-jackson"
    },
    {
        "id": "16884707",
        "name": "Baker College of Muskegon",
        "hyphenated": "baker-college-of-muskegon"
    },
    {
        "id": "16908001",
        "name": "Calvin University - Handlon Campus",
        "hyphenated": "calvin-university---handlon-campus"
    },
    {
        "id": "16947903",
        "name": "Davenport University-Warren Location",
        "hyphenated": "davenport-university-warren-location"
    },
    {
        "id": "16947904",
        "name": "Davenport University-Kalamazoo Location",
        "hyphenated": "davenport-university-kalamazoo-location"
    },
    {
        "id": "16947905",
        "name": "Davenport University-Lansing Location",
        "hyphenated": "davenport-university-lansing-location"
    },
    {
        "id": "16947907",
        "name": "Davenport University-Midland Location",
        "hyphenated": "davenport-university-midland-location"
    },
    {
        "id": "16947909",
        "name": "Davenport University-Holland Location",
        "hyphenated": "davenport-university-holland-location"
    },
    {
        "id": "16947912",
        "name": "Davenport University-Traverse City Location",
        "hyphenated": "davenport-university-traverse-city-location"
    },
    {
        "id": "16947922",
        "name": "Davenport University - Wayne CCCD",
        "hyphenated": "davenport-university---wayne-cccd"
    },
    {
        "id": "16947925",
        "name": "Davenport University - Detroit",
        "hyphenated": "davenport-university---detroit"
    },
    {
        "id": "17296301",
        "name": "Anoka-Ramsey Community College-Cambridge Campus",
        "hyphenated": "anoka-ramsey-community-college-cambridge-campus"
    },
    {
        "id": "17320301",
        "name": "Central Lakes College-Staples Campus",
        "hyphenated": "central-lakes-college-staples-campus"
    },
    {
        "id": "17355901",
        "name": "M State - Detroit Lakes Campus",
        "hyphenated": "m-state---detroit-lakes-campus"
    },
    {
        "id": "17355902",
        "name": "M State - Moorhead Campus",
        "hyphenated": "m-state---moorhead-campus"
    },
    {
        "id": "17355903",
        "name": "M State - Wadena Campus",
        "hyphenated": "m-state---wadena-campus"
    },
    {
        "id": "17391101",
        "name": "South Central College-Faribault",
        "hyphenated": "south-central-college-faribault"
    },
    {
        "id": "17395701",
        "name": "Mayo Clinic College of Medicine and Science - Florida",
        "hyphenated": "mayo-clinic-college-of-medicine-and-science---florida"
    },
    {
        "id": "17395702",
        "name": "Mayo Clinic College of Medicine and Science - Arizona",
        "hyphenated": "mayo-clinic-college-of-medicine-and-science---arizona"
    },
    {
        "id": "17399301",
        "name": "Mesabi Range College",
        "hyphenated": "mesabi-range-college"
    },
    {
        "id": "17447301",
        "name": "Northland Community and Technical College - East Grand Forks",
        "hyphenated": "northland-community-and-technical-college---east-grand-forks"
    },
    {
        "id": "17501401",
        "name": "Rasmussen University-Bloomington",
        "hyphenated": "rasmussen-university-bloomington"
    },
    {
        "id": "17501402",
        "name": "Rasmussen University-Eagan",
        "hyphenated": "rasmussen-university-eagan"
    },
    {
        "id": "17501403",
        "name": "Rasmussen University-Mankato",
        "hyphenated": "rasmussen-university-mankato"
    },
    {
        "id": "17501404",
        "name": "Rasmussen University-Brooklyn Park",
        "hyphenated": "rasmussen-university-brooklyn-park"
    },
    {
        "id": "17501405",
        "name": "Rasmussen University-Lake Elmo/Woodbury",
        "hyphenated": "rasmussen-university-lake-elmo/woodbury"
    },
    {
        "id": "17501406",
        "name": "Rasmussen University-Blaine",
        "hyphenated": "rasmussen-university-blaine"
    },
    {
        "id": "17526301",
        "name": "Minnesota State CollegeSoutheast-Red Wing Campus",
        "hyphenated": "minnesota-state-collegesoutheast-red-wing-campus"
    },
    {
        "id": "17557301",
        "name": "Copiah-Lincoln Community College Simpson County Center",
        "hyphenated": "copiah-lincoln-community-college-simpson-county-center"
    },
    {
        "id": "17557302",
        "name": "Copiah-Lincoln Community College-Natchez Campus",
        "hyphenated": "copiah-lincoln-community-college-natchez-campus"
    },
    {
        "id": "17601701",
        "name": "University of Mississippi Medical Center",
        "hyphenated": "university-of-mississippi-medical-center"
    },
    {
        "id": "17623901",
        "name": "Pearl River Community College-Forrest County Campus",
        "hyphenated": "pearl-river-community-college-forrest-county-campus"
    },
    {
        "id": "17706501",
        "name": "Columbia College - Redstone Arsenal",
        "hyphenated": "columbia-college---redstone-arsenal"
    },
    {
        "id": "17706503",
        "name": "Columbia College - Denver",
        "hyphenated": "columbia-college---denver"
    },
    {
        "id": "17706504",
        "name": "Columbia College - NAS Jacksonville",
        "hyphenated": "columbia-college---nas-jacksonville"
    },
    {
        "id": "17706505",
        "name": "Columbia College - Orlando",
        "hyphenated": "columbia-college---orlando"
    },
    {
        "id": "17706506",
        "name": "Columbia College - Crystal Lake Campus",
        "hyphenated": "columbia-college---crystal-lake-campus"
    },
    {
        "id": "17706507",
        "name": "Columbia College - Kansas City",
        "hyphenated": "columbia-college---kansas-city"
    },
    {
        "id": "17706508",
        "name": "Columbia College - Fort Leonard Wood",
        "hyphenated": "columbia-college---fort-leonard-wood"
    },
    {
        "id": "17706509",
        "name": "Columbia College - Jefferson City",
        "hyphenated": "columbia-college---jefferson-city"
    },
    {
        "id": "17706510",
        "name": "Columbia College - Lake Ozark",
        "hyphenated": "columbia-college---lake-ozark"
    },
    {
        "id": "17706511",
        "name": "Columbia College - Saint Louis",
        "hyphenated": "columbia-college---saint-louis"
    },
    {
        "id": "17706512",
        "name": "Columbia College - Fort Worth",
        "hyphenated": "columbia-college---fort-worth"
    },
    {
        "id": "17706513",
        "name": "Columbia College - Salt Lake",
        "hyphenated": "columbia-college---salt-lake"
    },
    {
        "id": "17706514",
        "name": "Columbia College - Lake County",
        "hyphenated": "columbia-college---lake-county"
    },
    {
        "id": "17706515",
        "name": "Columbia College - Naval Station Everett/Marysville",
        "hyphenated": "columbia-college---naval-station-everett/marysville"
    },
    {
        "id": "17706516",
        "name": "Columbia College - Freeport",
        "hyphenated": "columbia-college---freeport"
    },
    {
        "id": "17706517",
        "name": "Columbia College - Springfield",
        "hyphenated": "columbia-college---springfield"
    },
    {
        "id": "17706518",
        "name": "Columbia College - Rolla",
        "hyphenated": "columbia-college---rolla"
    },
    {
        "id": "17706519",
        "name": "Columbia College - Los Alamitos",
        "hyphenated": "columbia-college---los-alamitos"
    },
    {
        "id": "17706521",
        "name": "Columbia College - Lemoore",
        "hyphenated": "columbia-college---lemoore"
    },
    {
        "id": "17706522",
        "name": "Columbia College - San Luis Obispo",
        "hyphenated": "columbia-college---san-luis-obispo"
    },
    {
        "id": "17706523",
        "name": "Columbia College - Fort Stewart",
        "hyphenated": "columbia-college---fort-stewart"
    },
    {
        "id": "17706524",
        "name": "Columbia College - Hunter Army Airfield",
        "hyphenated": "columbia-college---hunter-army-airfield"
    },
    {
        "id": "17706525",
        "name": "Columbia College - Elgin",
        "hyphenated": "columbia-college---elgin"
    },
    {
        "id": "17706527",
        "name": "Columbia College - Whidbey Island",
        "hyphenated": "columbia-college---whidbey-island"
    },
    {
        "id": "17706529",
        "name": "Columbia College - Jacksonville",
        "hyphenated": "columbia-college---jacksonville"
    },
    {
        "id": "17706530",
        "name": "Columbia College - Fort Sill",
        "hyphenated": "columbia-college---fort-sill"
    },
    {
        "id": "17706532",
        "name": "Columbia College - San Diego",
        "hyphenated": "columbia-college---san-diego"
    },
    {
        "id": "17706533",
        "name": "Columbia College - Mesquite",
        "hyphenated": "columbia-college---mesquite"
    },
    {
        "id": "17706534",
        "name": "Columbia College - Waynesville",
        "hyphenated": "columbia-college---waynesville"
    },
    {
        "id": "17706535",
        "name": "Columbia College - Naval Base San Diego",
        "hyphenated": "columbia-college---naval-base-san-diego"
    },
    {
        "id": "17706536",
        "name": "Columbia College - Imperial",
        "hyphenated": "columbia-college---imperial"
    },
    {
        "id": "17706537",
        "name": "Columbia College - NSB Kings Bay",
        "hyphenated": "columbia-college---nsb-kings-bay"
    },
    {
        "id": "17706539",
        "name": "Columbia College - Minot AFB",
        "hyphenated": "columbia-college---minot-afb"
    },
    {
        "id": "17706540",
        "name": "Columbia College - Springfield TMobile",
        "hyphenated": "columbia-college---springfield-tmobile"
    },
    {
        "id": "17706541",
        "name": "Columbia College - Eastfield Pleasant Grove",
        "hyphenated": "columbia-college---eastfield-pleasant-grove"
    },
    {
        "id": "17706542",
        "name": "Columbia College - Fort Leonard Wood 58th Trans BN",
        "hyphenated": "columbia-college---fort-leonard-wood-58th-trans-bn"
    },
    {
        "id": "17706543",
        "name": "Columbia College - USCG Base Honolulu",
        "hyphenated": "columbia-college---uscg-base-honolulu"
    },
    {
        "id": "17706544",
        "name": "Columbia College - Tavares PD",
        "hyphenated": "columbia-college---tavares-pd"
    },
    {
        "id": "17733901",
        "name": "Evangel University - Assemblies of God Theological Seminary",
        "hyphenated": "evangel-university---assemblies-of-god-theological-seminary"
    },
    {
        "id": "18017901",
        "name": "City College Montana State University Billings",
        "hyphenated": "city-college-montana-state-university-billings"
    },
    {
        "id": "18303501",
        "name": "New Hampshire Institute for Therapeutic Arts",
        "hyphenated": "new-hampshire-institute-for-therapeutic-arts"
    },
    {
        "id": "18409201",
        "name": "American Institute",
        "hyphenated": "american-institute"
    },
    {
        "id": "18557201",
        "name": "Monmouth University - Monmouth Park Corporate Center",
        "hyphenated": "monmouth-university---monmouth-park-corporate-center"
    },
    {
        "id": "18908801",
        "name": "Bard College - Holyoke Microcollege",
        "hyphenated": "bard-college---holyoke-microcollege"
    },
    {
        "id": "18908802",
        "name": "Bard College - Coxsackie Correctional Facility",
        "hyphenated": "bard-college---coxsackie-correctional-facility"
    },
    {
        "id": "18908803",
        "name": "Bard College - Eastern NY Correctional Facility",
        "hyphenated": "bard-college---eastern-ny-correctional-facility"
    },
    {
        "id": "18908804",
        "name": "Bard College - Fishkill Correctional Facility",
        "hyphenated": "bard-college---fishkill-correctional-facility"
    },
    {
        "id": "18908805",
        "name": "Bard College - Green Haven Correctional Facility",
        "hyphenated": "bard-college---green-haven-correctional-facility"
    },
    {
        "id": "18908806",
        "name": "Bard College - Taconic Correctional Facility",
        "hyphenated": "bard-college---taconic-correctional-facility"
    },
    {
        "id": "18908807",
        "name": "Bard College - Woodbourne Correctional Facility",
        "hyphenated": "bard-college---woodbourne-correctional-facility"
    },
    {
        "id": "18908808",
        "name": "Bard College - BHSEC Newark",
        "hyphenated": "bard-college---bhsec-newark"
    },
    {
        "id": "18908809",
        "name": "Bard College - Brooklyn Public Library",
        "hyphenated": "bard-college---brooklyn-public-library"
    },
    {
        "id": "18956501",
        "name": "Bryant & Stratton College-Syracuse",
        "hyphenated": "bryant-&-stratton-college-syracuse"
    },
    {
        "id": "18958301",
        "name": "Bryant & Stratton College-Amherst",
        "hyphenated": "bryant-&-stratton-college-amherst"
    },
    {
        "id": "18958302",
        "name": "Bryant & Stratton College-Southtowns",
        "hyphenated": "bryant-&-stratton-college-southtowns"
    },
    {
        "id": "18959201",
        "name": "Bryant & Stratton College-Henrietta",
        "hyphenated": "bryant-&-stratton-college-henrietta"
    },
    {
        "id": "19004401",
        "name": "Clarkson University Capital Region Campus",
        "hyphenated": "clarkson-university-capital-region-campus"
    },
    {
        "id": "19050301",
        "name": "Culinary Institute of America at Greystone",
        "hyphenated": "culinary-institute-of-america-at-greystone"
    },
    {
        "id": "19050302",
        "name": "Culinary Institute of America San Antonio",
        "hyphenated": "culinary-institute-of-america-san-antonio"
    },
    {
        "id": "19054901",
        "name": "CUNY Brooklyn College - Feirstein Graduate School of Cinema",
        "hyphenated": "cuny-brooklyn-college---feirstein-graduate-school-of-cinema"
    },
    {
        "id": "19200401",
        "name": "Jamestown Business College -",
        "hyphenated": "jamestown-business-college--"
    },
    {
        "id": "19385801",
        "name": "NYSMedical&Dental Assistants - American Institute of Clinical Massage",
        "hyphenated": "nysmedical&dental-assistants---american-institute-of-clinical-massage"
    },
    {
        "id": "19457801",
        "name": "Pratt Manhattan-A Division of Pratt Institute",
        "hyphenated": "pratt-manhattan-a-division-of-pratt-institute"
    },
    {
        "id": "19476301",
        "name": "Rabbinical Seminary of America-Ma'yan HaTorah",
        "hyphenated": "rabbinical-seminary-of-america-ma'yan-hatorah"
    },
    {
        "id": "19580901",
        "name": "Saint John's University - Staten Island Campus",
        "hyphenated": "saint-john's-university---staten-island-campus"
    },
    {
        "id": "19857001",
        "name": "Gaston College",
        "hyphenated": "gaston-college"
    },
    {
        "id": "19998001",
        "name": "Winston - Salem Barber School - Satellite",
        "hyphenated": "winston---salem-barber-school---satellite"
    },
    {
        "id": "20146901",
        "name": "Bryant & Stratton College-Akron",
        "hyphenated": "bryant-&-stratton-college-akron"
    },
    {
        "id": "20146902",
        "name": "Bryant & Stratton College-Solon",
        "hyphenated": "bryant-&-stratton-college-solon"
    },
    {
        "id": "20146903",
        "name": "Bryant & Stratton College-Cleveland",
        "hyphenated": "bryant-&-stratton-college-cleveland"
    },
    {
        "id": "20306701",
        "name": "Hebrew Union College-Jewish Institute of Religion-Cincinnati",
        "hyphenated": "hebrew-union-college-jewish-institute-of-religion-cincinnati"
    },
    {
        "id": "20306702",
        "name": "Hebrew Union College-Jewish Institute of Religion-Los Angeles",
        "hyphenated": "hebrew-union-college-jewish-institute-of-religion-los-angeles"
    },
    {
        "id": "20354401",
        "name": "Kettering College - Ollie Davis Medical Arts and Education Center",
        "hyphenated": "kettering-college---ollie-davis-medical-arts-and-education-center"
    },
    {
        "id": "20417601",
        "name": "Mount Carmel College of Nursing - Fairfield",
        "hyphenated": "mount-carmel-college-of-nursing---fairfield"
    },
    {
        "id": "20627901",
        "name": "Los Angeles Center",
        "hyphenated": "los-angeles-center"
    },
    {
        "id": "20627902",
        "name": "Florida Center",
        "hyphenated": "florida-center"
    },
    {
        "id": "20627903",
        "name": "Sacramento Center",
        "hyphenated": "sacramento-center"
    },
    {
        "id": "21036801",
        "name": "Western Seminary-San Jose",
        "hyphenated": "western-seminary-san-jose"
    },
    {
        "id": "21036802",
        "name": "Western Seminary-Sacramento",
        "hyphenated": "western-seminary-sacramento"
    },
    {
        "id": "21078401",
        "name": "The Beauty Institute",
        "hyphenated": "the-beauty-institute"
    },
    {
        "id": "21130701",
        "name": "Bucks County Community College-Upper Bucks Campus",
        "hyphenated": "bucks-county-community-college-upper-bucks-campus"
    },
    {
        "id": "21130702",
        "name": "Bucks County Community College-Gene and Marlene Epstein Campus at Lower Bucks",
        "hyphenated": "bucks-county-community-college-gene-and-marlene-epstein-campus-at-lower-bucks"
    },
    {
        "id": "21287801",
        "name": "Harrisburg Area Community College-Lancaster",
        "hyphenated": "harrisburg-area-community-college-lancaster"
    },
    {
        "id": "21287802",
        "name": "Harrisburg Area Community College-Gettysburg",
        "hyphenated": "harrisburg-area-community-college-gettysburg"
    },
    {
        "id": "21287803",
        "name": "Harrisburg Area Community College-Lebanon",
        "hyphenated": "harrisburg-area-community-college-lebanon"
    },
    {
        "id": "21287804",
        "name": "Harrisburg Area Community College-York",
        "hyphenated": "harrisburg-area-community-college-york"
    },
    {
        "id": "21316301",
        "name": "UPMC Jameson School of Nursing - UPMC Hamot",
        "hyphenated": "upmc-jameson-school-of-nursing---upmc-hamot"
    },
    {
        "id": "21398701",
        "name": "Mercyhurst Municipal Police Training Academy",
        "hyphenated": "mercyhurst-municipal-police-training-academy"
    },
    {
        "id": "21429001",
        "name": "New Castle School of Trades",
        "hyphenated": "new-castle-school-of-trades"
    },
    {
        "id": "21429002",
        "name": "New Castle School of Trades - East Liverpool Campus",
        "hyphenated": "new-castle-school-of-trades---east-liverpool-campus"
    },
    {
        "id": "21437901",
        "name": "Northampton County Area Community College-Monroe",
        "hyphenated": "northampton-county-area-community-college-monroe"
    },
    {
        "id": "21608301",
        "name": "South Hills School of Business and Technology-Altoona",
        "hyphenated": "south-hills-school-of-business-and-technology-altoona"
    },
    {
        "id": "22055202",
        "name": "South College-Asheville",
        "hyphenated": "south-college-asheville"
    },
    {
        "id": "22055203",
        "name": "South College-Nashville",
        "hyphenated": "south-college-nashville"
    },
    {
        "id": "22055204",
        "name": "South College-Atlanta",
        "hyphenated": "south-college-atlanta"
    },
    {
        "id": "22300101",
        "name": "Austin Presbyterian Theological Seminary- YMCA Outdoor Center",
        "hyphenated": "austin-presbyterian-theological-seminary--ymca-outdoor-center"
    },
    {
        "id": "22797901",
        "name": "San Jacinto College-North Campus",
        "hyphenated": "san-jacinto-college-north-campus"
    },
    {
        "id": "22797902",
        "name": "San Jacinto College-South Campus",
        "hyphenated": "san-jacinto-college-south-campus"
    },
    {
        "id": "22797903",
        "name": "San Jacinto Community College District - Generation Park Campus",
        "hyphenated": "san-jacinto-community-college-district---generation-park-campus"
    },
    {
        "id": "22872301",
        "name": "Texas A & M University Health Science Center",
        "hyphenated": "texas-a-&-m-university-health-science-center"
    },
    {
        "id": "23059701",
        "name": "Snow College-Richfield Campus",
        "hyphenated": "snow-college-richfield-campus"
    },
    {
        "id": "23091302",
        "name": "Northern Vermont University - Lyndon",
        "hyphenated": "northern-vermont-university---lyndon"
    },
    {
        "id": "23155401",
        "name": "Bluefield College - Edward Via College of Osteopathic Medicine",
        "hyphenated": "bluefield-college---edward-via-college-of-osteopathic-medicine"
    },
    {
        "id": "23178501",
        "name": "Bryant & Stratton College-Richmond",
        "hyphenated": "bryant-&-stratton-college-richmond"
    },
    {
        "id": "23178502",
        "name": "Bryant & Stratton College-Hampton",
        "hyphenated": "bryant-&-stratton-college-hampton"
    },
    {
        "id": "23368401",
        "name": "Strayer University-Alexandria Campus",
        "hyphenated": "strayer-university-alexandria-campus"
    },
    {
        "id": "23368402",
        "name": "Strayer University-Woodbridge Campus",
        "hyphenated": "strayer-university-woodbridge-campus"
    },
    {
        "id": "23368404",
        "name": "Strayer University-Loudoun Campus",
        "hyphenated": "strayer-university-loudoun-campus"
    },
    {
        "id": "23368405",
        "name": "Strayer University-Fredericksburg Campus",
        "hyphenated": "strayer-university-fredericksburg-campus"
    },
    {
        "id": "23368408",
        "name": "Strayer University-Chesterfield Campus",
        "hyphenated": "strayer-university-chesterfield-campus"
    },
    {
        "id": "23368410",
        "name": "Strayer University-Newport News Campus",
        "hyphenated": "strayer-university-newport-news-campus"
    },
    {
        "id": "23368411",
        "name": "Strayer University-Virginia Beach Campus",
        "hyphenated": "strayer-university-virginia-beach-campus"
    },
    {
        "id": "23384201",
        "name": "Union Presbyterian Seminary-Charlotte",
        "hyphenated": "union-presbyterian-seminary-charlotte"
    },
    {
        "id": "23661001",
        "name": "Shoreline Community College - CNC Machinists Program Georgetown SSC",
        "hyphenated": "shoreline-community-college---cnc-machinists-program-georgetown-ssc"
    },
    {
        "id": "23661002",
        "name": "Shoreline Community College - Dental Hygiene",
        "hyphenated": "shoreline-community-college---dental-hygiene"
    },
    {
        "id": "23693901",
        "name": "Washington State University-Vancouver",
        "hyphenated": "washington-state-university-vancouver"
    },
    {
        "id": "23693902",
        "name": "Washington State University-Spokane",
        "hyphenated": "washington-state-university-spokane"
    },
    {
        "id": "23693903",
        "name": "Washington State University-Tri Cities",
        "hyphenated": "washington-state-university-tri-cities"
    },
    {
        "id": "23693904",
        "name": "Washington State University - Everett Campus",
        "hyphenated": "washington-state-university---everett-campus"
    },
    {
        "id": "24297201",
        "name": "NUC University - Arecibo",
        "hyphenated": "nuc-university---arecibo"
    },
    {
        "id": "24297202",
        "name": "NUC University - Rio Grande",
        "hyphenated": "nuc-university---rio-grande"
    },
    {
        "id": "24297203",
        "name": "NUC University - Ponce",
        "hyphenated": "nuc-university---ponce"
    },
    {
        "id": "24297204",
        "name": "NUC University - Caguas",
        "hyphenated": "nuc-university---caguas"
    },
    {
        "id": "24297205",
        "name": "NUC University - Mayaguez",
        "hyphenated": "nuc-university---mayaguez"
    },
    {
        "id": "24297206",
        "name": "NUC University - South Florida",
        "hyphenated": "nuc-university---south-florida"
    },
    {
        "id": "24297207",
        "name": "NUC University - Florida Technical College - Lakeland",
        "hyphenated": "nuc-university---florida-technical-college---lakeland"
    },
    {
        "id": "24297208",
        "name": "NUC University - Florida Technical College - Deland",
        "hyphenated": "nuc-university---florida-technical-college---deland"
    },
    {
        "id": "24297209",
        "name": "NUC University - Florida Technical College - Kissimmee",
        "hyphenated": "nuc-university---florida-technical-college---kissimmee"
    },
    {
        "id": "24297210",
        "name": "NUC University - Florida Technical College - Pembroke Pines",
        "hyphenated": "nuc-university---florida-technical-college---pembroke-pines"
    },
    {
        "id": "24297211",
        "name": "NUC University - Florida Technical College - Cutler Bay",
        "hyphenated": "nuc-university---florida-technical-college---cutler-bay"
    },
    {
        "id": "24297212",
        "name": "NUC University - IBC Technical Division - Ponce",
        "hyphenated": "nuc-university---ibc-technical-division---ponce"
    },
    {
        "id": "24297213",
        "name": "NUC University - IBC Technical Division - Mayaguez",
        "hyphenated": "nuc-university---ibc-technical-division---mayaguez"
    },
    {
        "id": "24297214",
        "name": "NUC University - IBC Technical Division - Guayama",
        "hyphenated": "nuc-university---ibc-technical-division---guayama"
    },
    {
        "id": "24297215",
        "name": "NUC University - IBC Technical Division - Fajardo",
        "hyphenated": "nuc-university---ibc-technical-division---fajardo"
    },
    {
        "id": "24297216",
        "name": "NUC University - IBC Technical Division - Manati",
        "hyphenated": "nuc-university---ibc-technical-division---manati"
    },
    {
        "id": "24297217",
        "name": "NUC University - IBC Technical Division - Los Colobos",
        "hyphenated": "nuc-university---ibc-technical-division---los-colobos"
    },
    {
        "id": "24297218",
        "name": "NUC University - IBC Technical Division - Moca",
        "hyphenated": "nuc-university---ibc-technical-division---moca"
    },
    {
        "id": "24297219",
        "name": "NUC University - IBC Technical Division - Aguadilla",
        "hyphenated": "nuc-university---ibc-technical-division---aguadilla"
    },
    {
        "id": "24297220",
        "name": "NUC University - IBC Technical Division - Caguas",
        "hyphenated": "nuc-university---ibc-technical-division---caguas"
    },
    {
        "id": "24297221",
        "name": "NUC University - IBC Technical Division - Arecibo",
        "hyphenated": "nuc-university---ibc-technical-division---arecibo"
    },
    {
        "id": "24297222",
        "name": "NUC University - IBC Technical Division - Escorial",
        "hyphenated": "nuc-university---ibc-technical-division---escorial"
    },
    {
        "id": "24297223",
        "name": "NUC University - IBC Technical Division - Bayamon",
        "hyphenated": "nuc-university---ibc-technical-division---bayamon"
    },
    {
        "id": "24297224",
        "name": "NUC University - IBC Technical Division - Yauco",
        "hyphenated": "nuc-university---ibc-technical-division---yauco"
    },
    {
        "id": "24297225",
        "name": "NUC University - Florida Technical College - Orlando",
        "hyphenated": "nuc-university---florida-technical-college---orlando"
    },
    {
        "id": "24297226",
        "name": "NUC University - Florida Technical College - Tampa",
        "hyphenated": "nuc-university---florida-technical-college---tampa"
    },
    {
        "id": "24366501",
        "name": "University of the Virgin Islands-Albert A. Sheen",
        "hyphenated": "university-of-the-virgin-islands-albert-a.-sheen"
    },
    {
        "id": "24378001",
        "name": "Purdue University - Purdue Polytechnic Anderson",
        "hyphenated": "purdue-university---purdue-polytechnic-anderson"
    },
    {
        "id": "24378002",
        "name": "Purdue University - Purdue Polytechnic Indianapolis",
        "hyphenated": "purdue-university---purdue-polytechnic-indianapolis"
    },
    {
        "id": "24378003",
        "name": "Purdue University - Purdue Polytechnic South Bend",
        "hyphenated": "purdue-university---purdue-polytechnic-south-bend"
    },
    {
        "id": "24378004",
        "name": "Purdue University - Purdue Polytechnic New Albany",
        "hyphenated": "purdue-university---purdue-polytechnic-new-albany"
    },
    {
        "id": "24378006",
        "name": "Purdue University - Purdue Polytechnic Columbus",
        "hyphenated": "purdue-university---purdue-polytechnic-columbus"
    },
    {
        "id": "24378007",
        "name": "Purdue University - Purdue Polytechnic Kokomo",
        "hyphenated": "purdue-university---purdue-polytechnic-kokomo"
    },
    {
        "id": "24378008",
        "name": "Purdue University - Purdue Polytechnic Richmond",
        "hyphenated": "purdue-university---purdue-polytechnic-richmond"
    },
    {
        "id": "24378009",
        "name": "Purdue University - Purdue Polytechnic Lafayette",
        "hyphenated": "purdue-university---purdue-polytechnic-lafayette"
    },
    {
        "id": "24378010",
        "name": "Purdue University - Purdue Polytechnic Vincennes",
        "hyphenated": "purdue-university---purdue-polytechnic-vincennes"
    },
    {
        "id": "24862401",
        "name": "Fayette County Career & Technical Institute -",
        "hyphenated": "fayette-county-career-&-technical-institute--"
    },
    {
        "id": "24893401",
        "name": "ECPI University-Newport News",
        "hyphenated": "ecpi-university-newport-news"
    },
    {
        "id": "24893402",
        "name": "ECPI University-Greensboro",
        "hyphenated": "ecpi-university-greensboro"
    },
    {
        "id": "24893403",
        "name": "ECPI University-Charlotte",
        "hyphenated": "ecpi-university-charlotte"
    },
    {
        "id": "24893404",
        "name": "ECPI University-Greenville",
        "hyphenated": "ecpi-university-greenville"
    },
    {
        "id": "24893405",
        "name": "ECPI University-Manassas",
        "hyphenated": "ecpi-university-manassas"
    },
    {
        "id": "24893406",
        "name": "ECPI University-Charleston",
        "hyphenated": "ecpi-university-charleston"
    },
    {
        "id": "24893407",
        "name": "ECPI University-Raleigh",
        "hyphenated": "ecpi-university-raleigh"
    },
    {
        "id": "24893408",
        "name": "ECPI University-Columbia",
        "hyphenated": "ecpi-university-columbia"
    },
    {
        "id": "24893410",
        "name": "ECPI University-Culinary Institute of Virginia",
        "hyphenated": "ecpi-university-culinary-institute-of-virginia"
    },
    {
        "id": "24893411",
        "name": "ECPI University-Virginia Beach Health Sciences",
        "hyphenated": "ecpi-university-virginia-beach-health-sciences"
    },
    {
        "id": "24893412",
        "name": "ECPI University-Richmond South",
        "hyphenated": "ecpi-university-richmond-south"
    },
    {
        "id": "24893413",
        "name": "ECPI University-Innsbrook",
        "hyphenated": "ecpi-university-innsbrook"
    },
    {
        "id": "24893414",
        "name": "ECPI University-Richmond West",
        "hyphenated": "ecpi-university-richmond-west"
    },
    {
        "id": "24893415",
        "name": "ECPI University - Roanoke",
        "hyphenated": "ecpi-university---roanoke"
    },
    {
        "id": "24893416",
        "name": "ECPI University - Orlando",
        "hyphenated": "ecpi-university---orlando"
    },
    {
        "id": "24893417",
        "name": "ECPI University - Culinary Institute of Virginia - Newport News",
        "hyphenated": "ecpi-university---culinary-institute-of-virginia---newport-news"
    },
    {
        "id": "24893418",
        "name": "ECPI University - San Antonio",
        "hyphenated": "ecpi-university---san-antonio"
    },
    {
        "id": "36662301",
        "name": "Northwest Technology Center-Fairview",
        "hyphenated": "northwest-technology-center-fairview"
    },
    {
        "id": "37369601",
        "name": "Rob Roy Academy - Woonsocket",
        "hyphenated": "rob-roy-academy---woonsocket"
    },
    {
        "id": "37727201",
        "name": "Nuvani Institute-Uvalde",
        "hyphenated": "nuvani-institute-uvalde"
    },
    {
        "id": "37728101",
        "name": "Nuvani Institute-Del Rio",
        "hyphenated": "nuvani-institute-del-rio"
    },
    {
        "id": "37728103",
        "name": "Nuvani Institute-South Campus",
        "hyphenated": "nuvani-institute-south-campus"
    },
    {
        "id": "38561901",
        "name": "Everglades University-Orlando",
        "hyphenated": "everglades-university-orlando"
    },
    {
        "id": "38561902",
        "name": "Everglades University-Sarasota",
        "hyphenated": "everglades-university-sarasota"
    },
    {
        "id": "38561903",
        "name": "Everglades University - Tampa",
        "hyphenated": "everglades-university---tampa"
    },
    {
        "id": "38561904",
        "name": "Everglades University - Miami",
        "hyphenated": "everglades-university---miami"
    },
    {
        "id": "40884401",
        "name": "Florida National University-South Campus",
        "hyphenated": "florida-national-university-south-campus"
    },
    {
        "id": "40884402",
        "name": "Florida National University Training Center",
        "hyphenated": "florida-national-university-training-center"
    },
    {
        "id": "41724801",
        "name": "Connecticut Aero Tech School",
        "hyphenated": "connecticut-aero-tech-school"
    },
    {
        "id": "41829601",
        "name": "Indian Capital Technology Center-Sallisaw",
        "hyphenated": "indian-capital-technology-center-sallisaw"
    },
    {
        "id": "41829602",
        "name": "Indian Capital Technology Center-Stilwell",
        "hyphenated": "indian-capital-technology-center-stilwell"
    },
    {
        "id": "41829603",
        "name": "Indian Capital Technology Center-Tahlequah",
        "hyphenated": "indian-capital-technology-center-tahlequah"
    },
    {
        "id": "41918301",
        "name": "Central California School of Continuing Education -",
        "hyphenated": "central-california-school-of-continuing-education--"
    },
    {
        "id": "42312001",
        "name": "Compu-Med Vocational Careers Corp",
        "hyphenated": "compu-med-vocational-careers-corp"
    },
    {
        "id": "42799101",
        "name": "Osceola Technical College - St. Cloud Campus",
        "hyphenated": "osceola-technical-college---st.-cloud-campus"
    },
    {
        "id": "42799102",
        "name": "Osceola Technical College - Poinciana Campus",
        "hyphenated": "osceola-technical-college---poinciana-campus"
    },
    {
        "id": "42817001",
        "name": "Southeastern College-Miami Lakes",
        "hyphenated": "southeastern-college-miami-lakes"
    },
    {
        "id": "43018401",
        "name": "Strayer University-Rockville Campus",
        "hyphenated": "strayer-university-rockville-campus"
    },
    {
        "id": "43018402",
        "name": "Strayer University-Anne Arundel Campus",
        "hyphenated": "strayer-university-anne-arundel-campus"
    },
    {
        "id": "43018403",
        "name": "Strayer University-White Marsh Campus",
        "hyphenated": "strayer-university-white-marsh-campus"
    },
    {
        "id": "43018404",
        "name": "Strayer University-Owings Mills Campus",
        "hyphenated": "strayer-university-owings-mills-campus"
    },
    {
        "id": "43342002",
        "name": "Santa Barbara Business College - Ventura",
        "hyphenated": "santa-barbara-business-college---ventura"
    },
    {
        "id": "43482101",
        "name": "Blue Cliff College",
        "hyphenated": "blue-cliff-college"
    },
    {
        "id": "44098901",
        "name": "Texas Barber College - Branch Campus #1",
        "hyphenated": "texas-barber-college---branch-campus-#1"
    },
    {
        "id": "44098902",
        "name": "Texas Barber College - Branch Campus #2",
        "hyphenated": "texas-barber-college---branch-campus-#2"
    },
    {
        "id": "44098904",
        "name": "Texas Barber College - Branch Campus #5",
        "hyphenated": "texas-barber-college---branch-campus-#5"
    },
    {
        "id": "44376601",
        "name": "Strayer University-Nashville Campus",
        "hyphenated": "strayer-university-nashville-campus"
    },
    {
        "id": "44376602",
        "name": "Strayer University-Shelby Oaks Campus",
        "hyphenated": "strayer-university-shelby-oaks-campus"
    },
    {
        "id": "44376603",
        "name": "Strayer University-Knoxville Campus",
        "hyphenated": "strayer-university-knoxville-campus"
    },
    {
        "id": "44378401",
        "name": "Strayer University-Delaware County Campus",
        "hyphenated": "strayer-university-delaware-county-campus"
    },
    {
        "id": "44378403",
        "name": "Strayer University-Center City Campus",
        "hyphenated": "strayer-university-center-city-campus"
    },
    {
        "id": "44378406",
        "name": "Strayer University-Allentown Campus",
        "hyphenated": "strayer-university-allentown-campus"
    },
    {
        "id": "44452901",
        "name": "Stellar Career College - Chicago IL",
        "hyphenated": "stellar-career-college---chicago-il"
    },
    {
        "id": "44473201",
        "name": "Leston College -",
        "hyphenated": "leston-college--"
    },
    {
        "id": "44784701",
        "name": "Aviator College of Aeronautical Science & Technology - Kissimmee",
        "hyphenated": "aviator-college-of-aeronautical-science-&-technology---kissimmee"
    },
    {
        "id": "44867301",
        "name": "Rasmussen University-Aurora",
        "hyphenated": "rasmussen-university-aurora"
    },
    {
        "id": "44867302",
        "name": "Rasmussen University-Romeoville/Joliet",
        "hyphenated": "rasmussen-university-romeoville/joliet"
    },
    {
        "id": "44867303",
        "name": "Rasmussen University-Mokena/Tinley Park",
        "hyphenated": "rasmussen-university-mokena/tinley-park"
    },
    {
        "id": "44903803",
        "name": "Strayer University-Orlando East Campus",
        "hyphenated": "strayer-university-orlando-east-campus"
    },
    {
        "id": "44903804",
        "name": "Strayer University-Sand Lake Campus",
        "hyphenated": "strayer-university-sand-lake-campus"
    },
    {
        "id": "44903805",
        "name": "Strayer University-Baymeadows Campus",
        "hyphenated": "strayer-university-baymeadows-campus"
    },
    {
        "id": "44903809",
        "name": "Strayer University-Miramar Campus",
        "hyphenated": "strayer-university-miramar-campus"
    },
    {
        "id": "44946301",
        "name": "Academy Di Capelli -",
        "hyphenated": "academy-di-capelli--"
    },
    {
        "id": "44970001",
        "name": "Dayton School of Medical Massage-Lima",
        "hyphenated": "dayton-school-of-medical-massage-lima"
    },
    {
        "id": "44970002",
        "name": "Cincinnati School of Medical Massage",
        "hyphenated": "cincinnati-school-of-medical-massage"
    },
    {
        "id": "44970003",
        "name": "Cleveland Institute of Medical Massage",
        "hyphenated": "cleveland-institute-of-medical-massage"
    },
    {
        "id": "44970004",
        "name": "Columbus School of Medical Massage",
        "hyphenated": "columbus-school-of-medical-massage"
    },
    {
        "id": "44977302",
        "name": "CCI Training Center",
        "hyphenated": "cci-training-center"
    },
    {
        "id": "45037701",
        "name": "Strayer University-Huntsville Campus",
        "hyphenated": "strayer-university-huntsville-campus"
    },
    {
        "id": "45037702",
        "name": "Strayer University - Montgomery Campus",
        "hyphenated": "strayer-university---montgomery-campus"
    },
    {
        "id": "45057102",
        "name": "Rasmussen University-Wausau",
        "hyphenated": "rasmussen-university-wausau"
    },
    {
        "id": "45098801",
        "name": "Coastline Beauty College - Hemet",
        "hyphenated": "coastline-beauty-college---hemet"
    },
    {
        "id": "45133401",
        "name": "Academy of Hair Design - Austin",
        "hyphenated": "academy-of-hair-design---austin"
    },
    {
        "id": "45175001",
        "name": "Bryant & Stratton College-Bayshore",
        "hyphenated": "bryant-&-stratton-college-bayshore"
    },
    {
        "id": "45175002",
        "name": "Bryant & Stratton College-Racine",
        "hyphenated": "bryant-&-stratton-college-racine"
    },
    {
        "id": "45316301",
        "name": "Strayer University-North Charlotte",
        "hyphenated": "strayer-university-north-charlotte"
    },
    {
        "id": "45316302",
        "name": "Strayer University-South Charlotte",
        "hyphenated": "strayer-university-south-charlotte"
    },
    {
        "id": "45316303",
        "name": "Strayer University-North Raleigh Campus",
        "hyphenated": "strayer-university-north-raleigh-campus"
    },
    {
        "id": "45316306",
        "name": "Strayer University-South Raleigh Campus",
        "hyphenated": "strayer-university-south-raleigh-campus"
    },
    {
        "id": "45486501",
        "name": "Cambridge College of Healthcare & Technology",
        "hyphenated": "cambridge-college-of-healthcare-&-technology"
    },
    {
        "id": "45491701",
        "name": "Celebrity School of Beauty - Hialeah",
        "hyphenated": "celebrity-school-of-beauty---hialeah"
    },
    {
        "id": "45519601",
        "name": "Jersey College",
        "hyphenated": "jersey-college"
    },
    {
        "id": "45519602",
        "name": "Jersey College",
        "hyphenated": "jersey-college"
    },
    {
        "id": "45519603",
        "name": "Jersey College",
        "hyphenated": "jersey-college"
    },
    {
        "id": "45519604",
        "name": "Jersey College",
        "hyphenated": "jersey-college"
    },
    {
        "id": "45519605",
        "name": "Jersey College",
        "hyphenated": "jersey-college"
    },
    {
        "id": "45519606",
        "name": "Jersey College",
        "hyphenated": "jersey-college"
    },
    {
        "id": "45519607",
        "name": "Jersey College - Fort Wayne",
        "hyphenated": "jersey-college---fort-wayne"
    },
    {
        "id": "45538101",
        "name": "Utah County Campus",
        "hyphenated": "utah-county-campus"
    },
    {
        "id": "45763301",
        "name": "L'esprit Academy - Royal Oak",
        "hyphenated": "l'esprit-academy---royal-oak"
    },
    {
        "id": "45891901",
        "name": "Strayer University-Cobb Campus",
        "hyphenated": "strayer-university-cobb-campus"
    },
    {
        "id": "45891902",
        "name": "Strayer University-Morrow Campus",
        "hyphenated": "strayer-university-morrow-campus"
    },
    {
        "id": "45891905",
        "name": "Strayer University-Lithonia Campus",
        "hyphenated": "strayer-university-lithonia-campus"
    },
    {
        "id": "45891906",
        "name": "Strayer University-Savannah Campus",
        "hyphenated": "strayer-university-savannah-campus"
    },
    {
        "id": "45891907",
        "name": "Strayer University-Augusta Campus",
        "hyphenated": "strayer-university-augusta-campus"
    },
    {
        "id": "45891908",
        "name": "Strayer University-Columbus",
        "hyphenated": "strayer-university-columbus"
    },
    {
        "id": "45891909",
        "name": "Strayer University - Macon Campus",
        "hyphenated": "strayer-university---macon-campus"
    },
    {
        "id": "45896401",
        "name": "Strayer University-Columbia Campus",
        "hyphenated": "strayer-university-columbia-campus"
    },
    {
        "id": "45896402",
        "name": "Strayer University-Charleston Campus",
        "hyphenated": "strayer-university-charleston-campus"
    },
    {
        "id": "45897305",
        "name": "Strayer University-Cedar Hill",
        "hyphenated": "strayer-university-cedar-hill"
    },
    {
        "id": "45897307",
        "name": "Strayer University-San Antonio",
        "hyphenated": "strayer-university-san-antonio"
    },
    {
        "id": "45897308",
        "name": "Strayer University-Stafford",
        "hyphenated": "strayer-university-stafford"
    },
    {
        "id": "45897309",
        "name": "Strayer University - Northwest Houston Campus",
        "hyphenated": "strayer-university---northwest-houston-campus"
    },
    {
        "id": "45920401",
        "name": "Unitek College",
        "hyphenated": "unitek-college"
    },
    {
        "id": "46131501",
        "name": "Keweenaw Bay Ojibwa Community College - Wabanung Campus",
        "hyphenated": "keweenaw-bay-ojibwa-community-college---wabanung-campus"
    },
    {
        "id": "46182901",
        "name": "Cinta Aveda Institute - San Jose",
        "hyphenated": "cinta-aveda-institute---san-jose"
    },
    {
        "id": "46185601",
        "name": "Southern California Health Institute (SOCHI) - North Hollywood",
        "hyphenated": "southern-california-health-institute-(sochi)---north-hollywood"
    },
    {
        "id": "47501501",
        "name": "Aveda Institute Rochester",
        "hyphenated": "aveda-institute-rochester"
    },
    {
        "id": "47503301",
        "name": "Relay Graduate School of Education - Newark",
        "hyphenated": "relay-graduate-school-of-education---newark"
    },
    {
        "id": "47503302",
        "name": "Relay Graduate School of Education - New Orleans",
        "hyphenated": "relay-graduate-school-of-education---new-orleans"
    },
    {
        "id": "47503303",
        "name": "Relay Graduate School of Education - Delaware",
        "hyphenated": "relay-graduate-school-of-education---delaware"
    },
    {
        "id": "47503304",
        "name": "Relay Graduate School of Education - Memphis",
        "hyphenated": "relay-graduate-school-of-education---memphis"
    },
    {
        "id": "47503305",
        "name": "Relay Graduate School of Education - Houston",
        "hyphenated": "relay-graduate-school-of-education---houston"
    },
    {
        "id": "47503306",
        "name": "Relay Graduate School of Education - Chicago",
        "hyphenated": "relay-graduate-school-of-education---chicago"
    },
    {
        "id": "47503307",
        "name": "Relay Graduate School of Education - Baton Rouge",
        "hyphenated": "relay-graduate-school-of-education---baton-rouge"
    },
    {
        "id": "47503308",
        "name": "Relay Graduate School of Education - Philadelphia & Camden",
        "hyphenated": "relay-graduate-school-of-education---philadelphia-&-camden"
    },
    {
        "id": "47503309",
        "name": "Relay Graduate School of Education - Denver",
        "hyphenated": "relay-graduate-school-of-education---denver"
    },
    {
        "id": "47503310",
        "name": "Relay Graduate School of Education - Nashville",
        "hyphenated": "relay-graduate-school-of-education---nashville"
    },
    {
        "id": "47503311",
        "name": "Relay Graduate School of Education - San Antonio",
        "hyphenated": "relay-graduate-school-of-education---san-antonio"
    },
    {
        "id": "47503312",
        "name": "Relay Graduate School of Education - Dallas-Fort Worth",
        "hyphenated": "relay-graduate-school-of-education---dallas-fort-worth"
    },
    {
        "id": "47503313",
        "name": "Relay Graduate School of Education - Washington D.C",
        "hyphenated": "relay-graduate-school-of-education---washington-d.c"
    },
    {
        "id": "47503314",
        "name": "Relay Graduate School of Education - Atlanta",
        "hyphenated": "relay-graduate-school-of-education---atlanta"
    },
    {
        "id": "47503315",
        "name": "Relay Graduate School of Education - California",
        "hyphenated": "relay-graduate-school-of-education---california"
    },
    {
        "id": "47503316",
        "name": "Relay Graduate School of Education - Indiana",
        "hyphenated": "relay-graduate-school-of-education---indiana"
    },
    {
        "id": "47503317",
        "name": "Relay Graduate School of Education - Connecticut",
        "hyphenated": "relay-graduate-school-of-education---connecticut"
    },
    {
        "id": "47662901",
        "name": "Salon Institute - Columbus Campus",
        "hyphenated": "salon-institute---columbus-campus"
    },
    {
        "id": "48002801",
        "name": "Digital Film Academy - Atlanta",
        "hyphenated": "digital-film-academy---atlanta"
    },
    {
        "id": "48065701",
        "name": "Rasmussen University - Overland Park",
        "hyphenated": "rasmussen-university---overland-park"
    },
    {
        "id": "48079001",
        "name": "Rocky Vista University - Southern Utah",
        "hyphenated": "rocky-vista-university---southern-utah"
    },
    {
        "id": "48092101",
        "name": "Designer Barber & Stylist School -",
        "hyphenated": "designer-barber-&-stylist-school--"
    },
    {
        "id": "48133101",
        "name": "Sharp Edgez Barber Institute - Buffalo",
        "hyphenated": "sharp-edgez-barber-institute---buffalo"
    },
    {
        "id": "48325801",
        "name": "Networks Barber College",
        "hyphenated": "networks-barber-college"
    },
    {
        "id": "48325802",
        "name": "Networks Barber College",
        "hyphenated": "networks-barber-college"
    },
    {
        "id": "48379901",
        "name": "East-West Healing Arts Institute - Milwaukee",
        "hyphenated": "east-west-healing-arts-institute---milwaukee"
    },
    {
        "id": "48385001",
        "name": "Alliance Computing Solutions - NYC",
        "hyphenated": "alliance-computing-solutions---nyc"
    },
    {
        "id": "48387801",
        "name": "Bay Area Medical Academy - San Jose Satellite Location",
        "hyphenated": "bay-area-medical-academy---san-jose-satellite-location"
    },
    {
        "id": "48400201",
        "name": "High Desert Medical College - Bakerfield",
        "hyphenated": "high-desert-medical-college---bakerfield"
    },
    {
        "id": "48400202",
        "name": "High Desert Medical College - Temecula",
        "hyphenated": "high-desert-medical-college---temecula"
    },
    {
        "id": "48486201",
        "name": "University of West Los Angeles - Chatsworth",
        "hyphenated": "university-of-west-los-angeles---chatsworth"
    },
    {
        "id": "48511101",
        "name": "Georgia Military College - Zebulon",
        "hyphenated": "georgia-military-college---zebulon"
    },
    {
        "id": "48511103",
        "name": "Georgia Military College - Valdosta",
        "hyphenated": "georgia-military-college---valdosta"
    },
    {
        "id": "48511104",
        "name": "Georgia Military College - Augusta",
        "hyphenated": "georgia-military-college---augusta"
    },
    {
        "id": "48511105",
        "name": "Georgia Military College - Fairburn",
        "hyphenated": "georgia-military-college---fairburn"
    },
    {
        "id": "48511106",
        "name": "Georgia Military College - Robins",
        "hyphenated": "georgia-military-college---robins"
    },
    {
        "id": "48511107",
        "name": "Georgia Military College - Columbus",
        "hyphenated": "georgia-military-college---columbus"
    },
    {
        "id": "48511108",
        "name": "Georgia Military College - Sandersville",
        "hyphenated": "georgia-military-college---sandersville"
    },
    {
        "id": "48511109",
        "name": "Georgia Military College - Madison",
        "hyphenated": "georgia-military-college---madison"
    },
    {
        "id": "48511110",
        "name": "Georgia Military College - Stone Mountain",
        "hyphenated": "georgia-military-college---stone-mountain"
    },
    {
        "id": "48511111",
        "name": "Georgia Military College - Fayetteville",
        "hyphenated": "georgia-military-college---fayetteville"
    },
    {
        "id": "48511112",
        "name": "Georgia Military College - Dublin",
        "hyphenated": "georgia-military-college---dublin"
    },
    {
        "id": "48511113",
        "name": "Georgia Military College - Eastman",
        "hyphenated": "georgia-military-college---eastman"
    },
    {
        "id": "48616901",
        "name": "American College of Barbering - Florence",
        "hyphenated": "american-college-of-barbering---florence"
    },
    {
        "id": "49005401",
        "name": "HCI College - Fort Lauderdale Campus",
        "hyphenated": "hci-college---fort-lauderdale-campus"
    },
    {
        "id": "49010601",
        "name": "Virginia University of Integrative Medicine - New Jersey",
        "hyphenated": "virginia-university-of-integrative-medicine---new-jersey"
    },
    {
        "id": "49022101",
        "name": "MAK Beauty Institute - Duluth",
        "hyphenated": "mak-beauty-institute---duluth"
    },
    {
        "id": "49146401",
        "name": "ABC Adult School - Cabrillo Lane",
        "hyphenated": "abc-adult-school---cabrillo-lane"
    },
    {
        "id": "49175601",
        "name": "Urban Barber College - San Jose",
        "hyphenated": "urban-barber-college---san-jose"
    },
    {
        "id": "49178301",
        "name": "Wilton Simpson Technical College",
        "hyphenated": "wilton-simpson-technical-college"
    },
    {
        "id": "49425001",
        "name": "Valley College - Fairlawn - School of Nursing",
        "hyphenated": "valley-college---fairlawn---school-of-nursing"
    },
    {
        "id": "49501301",
        "name": "Western Maricopa Education Center - Southwest Campus",
        "hyphenated": "western-maricopa-education-center---southwest-campus"
    },
    {
        "id": "49501302",
        "name": "Western Maricopa Education Center - Northeast Campus",
        "hyphenated": "western-maricopa-education-center---northeast-campus"
    },
    {
        "id": "49576701",
        "name": "Pennsylvania State University-Dickinson Law",
        "hyphenated": "pennsylvania-state-university-dickinson-law"
    },
    {
        "id": "49576702",
        "name": "Pennsylvania State University-World Campus",
        "hyphenated": "pennsylvania-state-university-world-campus"
    },
    {
        "id": "49576703",
        "name": "Pennsylvania State University-College of Medicine",
        "hyphenated": "pennsylvania-state-university-college-of-medicine"
    },
    {
        "id": "49576704",
        "name": "Pennsylvania State University-Penn State Lehigh Valley",
        "hyphenated": "pennsylvania-state-university-penn-state-lehigh-valley"
    },
    {
        "id": "49576705",
        "name": "Pennsylvania State University-Penn State Altoona",
        "hyphenated": "pennsylvania-state-university-penn-state-altoona"
    },
    {
        "id": "49576706",
        "name": "Pennsylvania State University-Penn State Beaver",
        "hyphenated": "pennsylvania-state-university-penn-state-beaver"
    },
    {
        "id": "49576707",
        "name": "Pennsylvania State University-Penn State Erie-Behrend College",
        "hyphenated": "pennsylvania-state-university-penn-state-erie-behrend-college"
    },
    {
        "id": "49576708",
        "name": "Pennsylvania State University-Penn State Berks",
        "hyphenated": "pennsylvania-state-university-penn-state-berks"
    },
    {
        "id": "49576709",
        "name": "Pennsylvania State University-Penn State DuBois",
        "hyphenated": "pennsylvania-state-university-penn-state-dubois"
    },
    {
        "id": "49576710",
        "name": "Pennsylvania State University-Penn State Fayette- Eberly",
        "hyphenated": "pennsylvania-state-university-penn-state-fayette--eberly"
    },
    {
        "id": "49576711",
        "name": "Pennsylvania State University-Penn State Hazleton",
        "hyphenated": "pennsylvania-state-university-penn-state-hazleton"
    },
    {
        "id": "49576712",
        "name": "Pennsylvania State University-Penn State Greater Allegheny",
        "hyphenated": "pennsylvania-state-university-penn-state-greater-allegheny"
    },
    {
        "id": "49576713",
        "name": "Pennsylvania State University-Penn State Mont Alto",
        "hyphenated": "pennsylvania-state-university-penn-state-mont-alto"
    },
    {
        "id": "49576714",
        "name": "Pennsylvania State University-Penn State New Kensington",
        "hyphenated": "pennsylvania-state-university-penn-state-new-kensington"
    },
    {
        "id": "49576715",
        "name": "Pennsylvania State University-Penn State Abington",
        "hyphenated": "pennsylvania-state-university-penn-state-abington"
    },
    {
        "id": "49576716",
        "name": "Pennsylvania State University-Penn State Schuylkill",
        "hyphenated": "pennsylvania-state-university-penn-state-schuylkill"
    },
    {
        "id": "49576717",
        "name": "Pennsylvania State University-Penn State Scranton",
        "hyphenated": "pennsylvania-state-university-penn-state-scranton"
    },
    {
        "id": "49576718",
        "name": "Pennsylvania State University-Penn State Shenango",
        "hyphenated": "pennsylvania-state-university-penn-state-shenango"
    },
    {
        "id": "49576719",
        "name": "Pennsylvania State University-Penn State Wilkes-Barre",
        "hyphenated": "pennsylvania-state-university-penn-state-wilkes-barre"
    },
    {
        "id": "49576720",
        "name": "Pennsylvania State University-Penn State York",
        "hyphenated": "pennsylvania-state-university-penn-state-york"
    },
    {
        "id": "49576721",
        "name": "Pennsylvania State University-Penn State Great Valley",
        "hyphenated": "pennsylvania-state-university-penn-state-great-valley"
    },
    {
        "id": "49576722",
        "name": "Pennsylvania State University-Penn State Harrisburg",
        "hyphenated": "pennsylvania-state-university-penn-state-harrisburg"
    },
    {
        "id": "49576723",
        "name": "Pennsylvania State University-Penn State Brandywine",
        "hyphenated": "pennsylvania-state-university-penn-state-brandywine"
    },
    {
        "id": "49664501",
        "name": "Burlington County Institute of Technology - Adult Education -",
        "hyphenated": "burlington-county-institute-of-technology---adult-education--"
    }
]