import { useEffect, useState } from 'react'
import { ECNameSearch } from './components/ECNameSearch'
import { ECTypeSearch } from './components/ECTypeSearch'
import { ECCollegeSearch } from './components/ECCollegeSearch'
import { ECInterestAreaSearch } from './components/ECInterestAreaSearch'
import { ECFormatSearch } from './components/ECFormatSearch'
import { ECDatesSearch } from './components/ECDatesSearch'
import { ECGradeSearch } from './components/ECGradeSearch'
import { ECAgeSearch } from './components/ECAgeSearch'
import { ECCostSearch } from './components/ECCostSearch'
import { ECImpactSearch } from './components/ECImpactSearch'
import { ECLocationSearch } from './components/ECLocationSearch'
import { ECSchoolLevelSearch } from './components/ECSchoolLevelSearch'
import { ECMajorSearch } from './components/ECMajorSearch'
import { ECCareerSearch } from './components/ECCareerSearch'
import { ECOnlyInterests } from './components/ECOnlyInterests'
import { ECOnlyPremiums } from './components/ECOnlyPremiums'
import { pageRoutes } from '../dictionary'
import { returnParameterString, concatLocation } from '../searchPageURLManager'
import { useLocation } from 'react-router'
import { useAuth } from '../../modules/auth'

const ECSearchAside = ({ filteredEC, setFilteredEC, nameOptions, locationOptions, collegeOptions, setTypeFilter, setNameFilter, setCollegeFilter, setInterestAreaFilter, setMajorFilter, setCareerFilter, setFormatFilter, setDateFilter, setGradeFilter, setAgeFilter, setCostFilter, setLocationFilter, setImpactFilter, setSchoolLevelFilter, setECSearchLocation, ecSearchLocation, setSortKey, setSortOrder, sortKey, sortOrder, setShowOnlyMyInterestsFilter, onlyInterestsSelect, setOnlyInterestsSelect, setShowOnlyPremiumsFilter, onlyPremiumsSelect, setOnlyPremiumsSelect, allECPremiumInsights }) => {

  const location = useLocation()
  const { currentUser } = useAuth()

  //Filter select values
  const [nameSelect, setNameSelect] = useState<any>([])
  const [typeSelect, setTypeSelect] = useState<any>([])
  const [collegeSelect, setCollegeSelect] = useState<any>([])
  const [interestAreaSelect, setInterestAreaSelect] = useState<any>([])
  const [majorSelect, setMajorSelect] = useState<any>([])
  const [careerSelect, setCareerSelect] = useState<any>([])
  const [activityFormatSelect, setActivityFormatSelect] = useState<any>([])
  const [inPersonFormatSelect, setInPersonFormatSelect] = useState<any>([])
  const [dateSelectMin, setDateSelectMin] = useState<any>(null)
  const [dateSelectMax, setDateSelectMax] = useState<any>(null)
  const [gradeSelect, setGradeSelect] = useState<any>(0)
  const [ageSelect, setAgeSelect] = useState<any>(0)
  const [costSelect, setCostSelect] = useState<any>(20000)
  const [locationSelect, setLocationSelect] = useState<any>([])
  const [impactSelect, setImpactSelect] = useState<any>(0)
  const [schoolLevelSelect, setSchoolLevelSelect] = useState<any>(0)

  // URL Params
  const [sortParams, setSortParams] = useState('')
  const [activityNameParams, setActivityNameParams] = useState('')
  const [typeParams, setTypeParams] = useState('')
  const [interestAreasParams, setInterestAreasParams] = useState('')
  const [impactParams, setImpactParams] = useState('')
  const [collegeParams, setCollegeParams] = useState('')
  const [datesParams, setDatesParams] = useState('')
  const [formatParams, setFormatParams] = useState('')
  const [schoolLevelParams, setSchoolLevelParams] = useState('')
  const [gradeParams, setGradeParams] = useState('')
  const [ageParams, setAgeParams] = useState('')
  const [locationParams, setLocationParams] = useState('')
  const [maxCostParams, setMaxCostParams] = useState('')
  const [onlyInterestsParams, setOnlyInterestsParams] = useState('')
  const [onlyPremiumsParams, setOnlyPremiumsParams] = useState('')

  // //Update filters when selected filter options are changed
  useEffect(() => {
    const returnNameFilter = (nameSelect) => {
      if (nameSelect.length !== 0) {
        setNameFilter((item) => {
          return item => nameSelect.includes(item.name)
        })
      }
      else {
        setNameFilter((item) => {
          return item => item
        })
      }
    };
    returnNameFilter(nameSelect)

    // Set URL params
    let params = new URLSearchParams(location.search);

    if (nameSelect.length > 0) {
      const names = nameSelect.map((name) => name).join(',')
      params.set('activityName', names)
    } else {
      params.delete('activityName')
    }

    setActivityNameParams(`${params.toString()}`)
  }, [nameSelect])

  useEffect(() => {

    // Set URL params
    let params = new URLSearchParams(location.search);

    if (typeSelect.length > 0) {
      const types = typeSelect.map((res) => res).join(',')
      params.set('type', types)
    } else {
      params.delete('type')
    }

    setTypeParams(`${params.toString()}`)

    const returnTypeFilter = (typeSelect) => {
      if (typeSelect.length > 0) {
        setTypeFilter((item) => {
          return item => (item.activityType !== null && typeSelect.includes(item.activityType))
        })
      }

      else {
        setTypeFilter((item) => {
          return item => item
        })
      }
    }

    returnTypeFilter(typeSelect)
  }, [typeSelect])

  useEffect(() => {
    const returnCollegeFilter = (collegeSelect) => {
      if (collegeSelect.length !== 0) {
        setCollegeFilter((item) => {
          return item => (item.sponsorCollegeID !== null && collegeSelect.includes(item.sponsorCollegeID))
        })
      }
      else {
        setCollegeFilter((item) => {
          return item => item
        })
      }
    };
    returnCollegeFilter(collegeSelect)

    // Set URL params
    let params = new URLSearchParams(location.search);

    if (collegeSelect.length > 0) {
      const colleges = collegeSelect.map((col) => col).join(',')
      params.set('college', colleges)
    } else {
      params.delete('college')
    }

    setCollegeParams(`${params.toString()}`)

  }, [collegeSelect])

  useEffect(() => {
    const returnInterestAreaFilter = (interestAreaSelect) => {
      if (interestAreaSelect.length !== 0) {
        setInterestAreaFilter((item) => {
          return item => (item.relatedInterestAreas !== null && item.relatedInterestAreas.some((interest) => interestAreaSelect.includes(interest)))
        });
      }
      else {
        setInterestAreaFilter((item) => {
          return item => item
        })
      }
    };
    returnInterestAreaFilter(interestAreaSelect)

    // Set URL params
    let params = new URLSearchParams(location.search);

    if (interestAreaSelect.length > 0) {
      const interests = interestAreaSelect.map((interest) => interest).join(',')
      params.set('interestAreas', interests)
    } else {
      params.delete('interestAreas')
    }

    setInterestAreasParams(`${params.toString()}`)

  }, [interestAreaSelect])

  useEffect(() => {
    const returnMajorFilter = (majorSelect) => {
      if (majorSelect.length !== 0) {
        setMajorFilter((item) => {
          return item => (item.relatedMajors !== null && majorSelect.includes(item.relatedMajors))
        })
      }
      else {
        setMajorFilter((item) => {
          return item => item
        })
      }
    };
    returnMajorFilter(majorSelect)
  }, [majorSelect])

  useEffect(() => {
    const returnCareerFilter = (careerSelect) => {
      if (careerSelect.length !== 0) {
        setCareerFilter((item) => {
          return item => (item.relatedCareers !== null && careerSelect.includes(item.relatedCareers))
        })
      }
      else {
        setCareerFilter((item) => {
          return item => item
        })
      }
    };
    returnCareerFilter(careerSelect)
  }, [careerSelect])

  useEffect(() => {
    const returnFormatFilter = (activityFormatSelect, inPersonFormatSelect) => {
      if (activityFormatSelect.length !== 0 && inPersonFormatSelect.length == 0) {
        setFormatFilter((item) => {
          return item => (item.activityFormat !== null && item.activityFormat.some((activity) => activityFormatSelect.includes(activity)))
        })
      }

      else if (activityFormatSelect.length !== 0 && inPersonFormatSelect.length !== 0) {
        setFormatFilter((item) => {
          return item => (item.activityFormat !== null && item.inPersonFormat !== null && item.activityFormat.some((activity) => activityFormatSelect.includes(activity)) && item.inPersonFormat.some((res) => inPersonFormatSelect.includes(res)))
        })
      }

      else {
        setFormatFilter((item) => {
          return item => item
        })
      }
    }

    returnFormatFilter(activityFormatSelect, inPersonFormatSelect)

    // online --> activityFormat[2] inPerson[]
    // in-person (residential) --> activityFormat[1] inPerson[1]
    // in-person (commuting) --> activityFormat[1] inPerson[2]
    // hybrid --> activityFormat[3] inPerson[]

    // Set URL params
    let params = new URLSearchParams(location.search);

    if (activityFormatSelect.length > 0) {
      let formats: any = []
      if (activityFormatSelect.includes(2)) {
        formats.push('online')
      }

      if (activityFormatSelect.includes(3)) {
        formats.push('hybrid')
      }

      if (activityFormatSelect.includes(1) && inPersonFormatSelect.includes(1)) {
        formats.push('residential')
      }

      if (activityFormatSelect.includes(1) && inPersonFormatSelect.includes(2)) {
        formats.push('commuting')
      }
      formats = formats.map((form) => form).join(',')
      params.set('format', formats)
    } else {
      params.delete('format')
    }

    setFormatParams(`${params.toString()}`)

  }, [activityFormatSelect, inPersonFormatSelect])

  useEffect(() => {

    const returnDateFilter = (dateSelectMin, dateSelectMax) => {


      if (dateSelectMin !== null && dateSelectMax !== null) {
        // Could also use the getDateFromHyphenFormat here instead of explicitly using new Date(date.replace...)
        setDateFilter((item) => {
          return item => (
            item.ec_activity_session_dates &&
            item.ec_activity_session_dates.length > 0 &&
            item.ec_activity_session_dates.some((date) => (new Date(date.startDate.replace(/-/g, '\/')) >= dateSelectMin) && (new Date(date.endDate.replace(/-/g, '\/')) <= dateSelectMax))
          )
        })

        // setDateFilter(() => {
        //   return (item) => (
        //     item.ec_activity_session_dates &&
        //     item.ec_activity_session_dates.length > 0 &&
        //     item.ec_activity_session_dates.some((date) => {
        //       const startDate = new Date(date.startDate.replace(/-/g, '\/'));
        //       const endDate = new Date(date.endDate.replace(/-/g, '\/'));
        //       return startDate >= dateSelectMin && endDate <= dateSelectMax;
        //     })
        //   );
        // });
      }

      else {
        setDateFilter((item) => {
          return item => item
        })
      }
    }
    returnDateFilter(dateSelectMin, dateSelectMax)

    let params = new URLSearchParams(location.search);

    if (dateSelectMin != null || dateSelectMax != null) {
      let dateRange: any[] = [new Date(dateSelectMin), new Date(dateSelectMax)]
      dateRange.map((date, index) => {
        const month = date.toLocaleString('en-us', { month: 'short' });
        const day = date.getDate();
        const year = date.getFullYear();

        dateRange[index] = `${month}-${day}-${year}`
      })
      const dateSelect = dateRange.map((date) => date).join(',')
      params.set('dates', dateSelect)
    } else {
      params.delete('dates')
    }

    setDatesParams(`${params.toString()}`)

  }, [dateSelectMin, dateSelectMax])

  useEffect(() => {
    const returnGradeFilter = (gradeSelect) => {

      switch (true) {
        case gradeSelect === 0:
          return setGradeFilter((item) => {
            return item => item
          })
        case gradeSelect === 1:
        case gradeSelect === 2:
        case gradeSelect === 3:
        case gradeSelect === 4:
        case gradeSelect === 5:
          return setGradeFilter((item) => {

            if (item.eligibleGrades !== null) {
              return item => item.eligibleGrades !== null && item.eligibleGrades.includes(gradeSelect)
            }

            else {
              return item => item.schoolLevel !== null && item.schoolLevel.includes(3)
            }
          })
        case gradeSelect === 6:
        case gradeSelect === 7:
        case gradeSelect === 8:
          return setGradeFilter((item) => {

            if (item.eligibleGrades !== null) {
              return item => item.eligibleGrades !== null && item.eligibleGrades.includes(gradeSelect)
            }

            else {
              return item => item.schoolLevel !== null && item.schoolLevel.includes(2)
            }
          })
        case gradeSelect === 9:
        case gradeSelect === 10:
        case gradeSelect === 11:
        case gradeSelect === 12:
          return setGradeFilter((item) => {

            if (item.eligibleGrades !== null) {
              return item => item.eligibleGrades !== null && item.eligibleGrades.includes(gradeSelect)
            }

            else {
              return item => item.schoolLevel !== null && item.schoolLevel.includes(1)
            }
          })
        default:
          return setGradeFilter((item) => {
            return item => item
          })
      }
    }
    returnGradeFilter(gradeSelect)

    let params = new URLSearchParams(location.search);

    if (gradeSelect != '') {
      params.set('grade', gradeSelect)
    } else {
      params.delete('grade')
    }

    setGradeParams(`${params.toString()}`)
  }, [gradeSelect])

  useEffect(() => {
    const returnAgeFilter = (ageSelect) => {
      if (ageSelect !== 0) {
        setAgeFilter((item) => {
          return item => (item.eligibleAges === null || item.eligibleAges.some((age) => ageSelect == age))
        })
      }
      else {
        setAgeFilter((item) => {
          return item => item
        })
      }
    }
    returnAgeFilter(ageSelect)

    let params = new URLSearchParams(location.search);

    if (ageSelect != '') {
      params.set('age', ageSelect)
    } else {
      params.delete('age')
    }

    setAgeParams(`${params.toString()}`)
  }, [ageSelect])

  useEffect(() => {
    const returnCostFilter = (costSelect) => {
      setCostFilter((item) => {
        return item => (
          (item.specificCost !== null && item.specificCost <= costSelect) || (item.specificCost === null && item.maxCost !== null && item.maxCost <= costSelect) || (item.specificCost === null && item.maxCost === null)
        )
      })
    }
    returnCostFilter(costSelect)

    let params = new URLSearchParams(location.search);
    if (costSelect < 20000) {
      params.set('maxCost', `${costSelect}`)
    }
    else {
      params.delete('maxCost')
    }

    setMaxCostParams(`${params.toString()}`)

  }, [costSelect])

  useEffect(() => {
    const returnLocationFilter = (locationSelect) => {
      if (locationSelect.length !== 0) {
        setLocationFilter((item) => {
          return item => (item.locations !== null && item.locations.some((location) => locationSelect.includes(location)))
        })
      }
      else {
        setLocationFilter((item) => {
          return item => item
        })
      }
    };
    returnLocationFilter(locationSelect)

    // Set URL params
    let params = new URLSearchParams(location.search);

    if (locationSelect.length > 0) {
      const locations = locationSelect.map((loc) => loc).join(',')
      params.set('location', locations)
    } else {
      params.delete('location')
    }

    setLocationParams(`${params.toString()}`)
  }, [locationSelect])

  useEffect(() => {

    const returnImpactFilter = (impactSelect) => {
      if (impactSelect !== 0) {
        setImpactFilter((item) => {
          return item => (item.impactIndex !== null && item.impactIndex === impactSelect)
        })
      }
      else {
        setImpactFilter((item) => {
          return item => item
        })
      }
    };
    returnImpactFilter(impactSelect)

    let params = new URLSearchParams(location.search);

    if (impactSelect != '') {
      params.set('impact', impactSelect)
    } else {
      params.delete('impact')
    }

    setImpactParams(`${params.toString()}`)
  }, [impactSelect])

  useEffect(() => {
    const returnSchoolLevelFilter = (schoolLevelSelect) => {
      switch (schoolLevelSelect) {
        case 0:
          return setSchoolLevelFilter((item) => {
            return item => item
          })
        case 1:
          return setSchoolLevelFilter((item) => {
            return item => (item.schoolLevel !== null && item.schoolLevel.includes(1))
          })
        case 2:
          return setSchoolLevelFilter((item) => {
            return item => (item.schoolLevel !== null && item.schoolLevel.includes(2))
          })
        case 3:
          return setSchoolLevelFilter((item) => {
            return item => (item.schoolLevel !== null && item.schoolLevel.includes(3))
          })
        default:
          return setSchoolLevelFilter((item) => {
            return item => item
          })
      }
    }
    returnSchoolLevelFilter(schoolLevelSelect)

    let params = new URLSearchParams(location.search);

    if (schoolLevelSelect != '') {
      params.set('schoolLevel', schoolLevelSelect)
    } else {
      params.delete('schoolLevel')
    }

    setSchoolLevelParams(`${params.toString()}`)
  }, [schoolLevelSelect])

  useEffect(() => {

    const returnShowOnlyInterestFilter = (onlyInterestsSelect) => {

      if (onlyInterestsSelect === true && currentUser?.activitiesInterest && currentUser?.activitiesInterest.length > 0) {
        setShowOnlyMyInterestsFilter((item) => {
          return item => currentUser?.activitiesInterest.some((res) => res == item.id
          )
        }
        )
      }

      else if (onlyInterestsSelect === true && (currentUser?.activitiesInterest == null || currentUser?.activitiesInterest?.length == 0)) {
        setShowOnlyMyInterestsFilter((item) => {
          return item => false
        })
      }

      else {
        setShowOnlyMyInterestsFilter((item) => {
          return item => item
        })
      }

    }
    returnShowOnlyInterestFilter(onlyInterestsSelect)

    let params = new URLSearchParams(location.search);

    if (onlyInterestsSelect === true) {
      params.set('onlyInterests', 'true')
    }

    else {
      params.delete('onlyInterests')
    }

    setOnlyInterestsParams(`${params.toString()}`)

  }, [onlyInterestsSelect])

  useEffect(() => {

    const returnShowOnlyPremiumsFilter = (onlyPremiumsSelect) => {

      if (onlyPremiumsSelect === true) {
        setShowOnlyPremiumsFilter((item) => {
          return item => allECPremiumInsights.some(insightsObj => insightsObj.id == item.id)
        }
        )
      }

      else {
        setShowOnlyPremiumsFilter((item) => {
          return item => item
        })
      }

    }
    returnShowOnlyPremiumsFilter(onlyPremiumsSelect)

    let params = new URLSearchParams(location.search);

    if (onlyPremiumsSelect === true) {
      params.set('onlyPremiums', 'true')
    }

    else {
      params.delete('onlyPremiums')
    }

    setOnlyPremiumsParams(`${params.toString()}`)

  }, [onlyPremiumsSelect, allECPremiumInsights])

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let sortParam = params.get('sort');

    if (sortParam) {
      const sorts = sortParam.split(',').map(res => res);
      setSortKey(sorts[0])
      setSortOrder(sorts[1])
    }

  }, [location.search]);

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let sortDetails: any = [sortKey, sortOrder]

    sortDetails = sortDetails.map((res) => res).join(',')
    params.set('sort', sortDetails)

    setSortParams(`${params.toString()}`)

  }, [sortKey, sortOrder])

  useEffect(() => {

    //  Create an array of the parameter strings
    let params: string[] = []

    // Use the returnParameterString function to fix the string if needed
    params = [
      returnParameterString(onlyPremiumsParams, 'onlyPremiums'),
      returnParameterString(onlyInterestsParams, 'onlyInterests'),
      returnParameterString(activityNameParams, 'activityName'),
      returnParameterString(typeParams, 'type'),
      returnParameterString(interestAreasParams, 'interestAreas'),
      returnParameterString(impactParams, 'impact'),
      returnParameterString(collegeParams, 'college'),
      returnParameterString(datesParams, 'dates'),
      returnParameterString(formatParams, 'format'),
      returnParameterString(schoolLevelParams, 'schoolLevel'),
      returnParameterString(gradeParams, 'grade'),
      returnParameterString(ageParams, 'age'),
      returnParameterString(locationParams, 'location'),
      returnParameterString(maxCostParams, 'maxCost'),
      returnParameterString(sortParams, 'sort')
    ]

    // Set the location string using the base pageRoute and the return from the concat location function
    setECSearchLocation(`${pageRoutes.ecSearch}${concatLocation(params)}`)
  }, [onlyPremiumsParams, onlyInterestsParams, activityNameParams, typeParams, interestAreasParams, impactParams, collegeParams, datesParams, formatParams, schoolLevelParams, gradeParams, ageParams, locationParams, maxCostParams, sortParams])

  useEffect(() => {

    window.history.replaceState({}, '', ecSearchLocation)

  }, [ecSearchLocation])


  return (
    <div style={{ width: '260px', maxWidth: '260px', whiteSpace: 'nowrap' }}>

      <ECNameSearch
        nameOptions={nameOptions}
        setNameSelect={setNameSelect}
      />
      <ECTypeSearch
        filteredEC={filteredEC}
        setTypeSelect={setTypeSelect}
      />
      <ECInterestAreaSearch
        filteredEC={filteredEC}
        setInterestAreaSelect={setInterestAreaSelect}
      />
      <ECImpactSearch
        impactSelect={impactSelect}
        setImpactSelect={setImpactSelect}
      />
      <ECOnlyPremiums
        onlyPremiumsSelect={onlyPremiumsSelect}
        setOnlyPremiumsSelect={setOnlyPremiumsSelect}
      />
      <ECCollegeSearch
        collegeOptions={collegeOptions}
        setCollegeSelect={setCollegeSelect}
      />
      <ECDatesSearch
        dateSelectMin={dateSelectMin}
        setDateSelectMin={setDateSelectMin}
        dateSelectMax={dateSelectMax}
        setDateSelectMax={setDateSelectMax}
      />
      <ECFormatSearch
        filteredEC={filteredEC}
        setActivityFormatSelect={setActivityFormatSelect}
        setInPersonFormatSelect={setInPersonFormatSelect}
      />
      <ECSchoolLevelSearch
        schoolLevelSelect={schoolLevelSelect}
        setSchoolLevelSelect={setSchoolLevelSelect} />
      <ECGradeSearch
        gradeSelect={gradeSelect}
        setGradeSelect={setGradeSelect}
      />
      <ECAgeSearch
        ageSelect={ageSelect}
        setAgeSelect={setAgeSelect}
      />
      <div style={{ zIndex: 2 }}>
        <ECLocationSearch
          locationOptions={locationOptions}
          setLocationSelect={setLocationSelect}
        />
      </div>

      <div style={{ zIndex: 1 }}>
        <ECCostSearch
          setCostSelect={setCostSelect}
        />
      </div>

      <div style={{ zIndex: 1 }}>
        <ECOnlyInterests
          onlyInterestsSelect={onlyInterestsSelect}
          setOnlyInterestsSelect={setOnlyInterestsSelect}
        />
      </div>

      {/* <ECMajorSearch
        setMajorSelect={setMajorSelect}
      />
      <ECCareerSearch
        setCareerSelect={setCareerSelect}
      /> */}
    </div>
  )
}

export { ECSearchAside }