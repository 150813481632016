import { useState, useEffect } from 'react'
import { numberConverter, URL } from '../../dictionary'
import Chart from 'react-apexcharts'
import { RankIndicator } from '../../RankIndicator'
import { RankColorRange } from '../../RankColorRange'
import { TooltipComponent } from '../../Tooltip'
import { OverlayTrigger, Tooltip } from "react-bootstrap"
import { RnDSpendModal } from './Modals/R&DSpendModal'
import axios from 'axios'
import { getAuth } from "../../../modules/auth";
import { SpecializedRankingModal } from './Modals/SpecializedRankingModal'

const RnDSpend = ({ college, rndAnnual, collegeListLength, setSelectedTab }) => {

  let apiToken: any = undefined
  let permissionGroups: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
    permissionGroups = getAuth()?.groups
  }

  if (apiToken === undefined) {
    setSelectedTab('Admission Info')
  }

  const latestYear = new Date().getFullYear()

  const [toggleCollapse, setToggleCollapse] = useState({
    'baseToggle': true,
    'scienceToggle': false,
    'geoToggle': true,
    'lifeToggle': true,
    'physToggle': true,
    'socialToggle': true,
    'engineeringToggle': false,
    'nonSEToggle': false
  })
  const [dataLoaded, setDataLoaded] = useState(false)
  const [collegeData, setCollegeData] = useState<any>([])
  const [modalTitle, setModalTitle] = useState('Science')

  const numberConverterForSpecializedModal = (num) => {
    if (num) {
      return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }

    else {
      return '-'
    }
  }

  const handleToggle = (toggle) => {
    setToggleCollapse((prevState) => ({
      ...prevState,
      [toggle]: !prevState[toggle],
    }))
  }

  const returnSpendFigure = (val) => {
    if (val != '-') {
      return `$${val}`
    }

    else if (val === null) {
      return '-'
    }

    else {
      return val
    }
  }

  const returnRankModalTrigger = (modalTitle, rank) => {
    return (<div
      className="me-2 align-self-end"
      style={{ cursor: 'pointer' }}
      data-bs-toggle="modal"
      data-bs-target="#view_rd_spend_rankings_modal"
      onClick={() => setModalTitle(modalTitle)}
    >
      <OverlayTrigger
        delay={{ hide: 450, show: 300 }}
        overlay={(props) => (
          <Tooltip {...props}>
            {`Click to view all college rankings for this specific R&D field`}
          </Tooltip>
        )}
        placement="top"
      ><div><RankIndicator rank={rank} /></div>
      </OverlayTrigger>
    </div>)
  }

  useEffect(() => {

    // console.log('college', college)
    // console.log('rndAnnual', rndAnnual)

    // console.log('rndAverage', college.rnd_average)
    // console.log('rndRank', college.rnd_rank)

    const fetchData = async () => {
      try {

        if (dataLoaded === true) {
          setDataLoaded(false)
        }

        const collegeAxios = axios.get(`${URL}/colleges/0-to-${collegeListLength}`)

        const [collegeRes] = await Promise.all([
          collegeAxios
        ]);

        await Promise.all([
          setCollegeData(collegeRes.data)
        ]);
        console.log('College data for modal:', collegeRes.data)
        setDataLoaded(true);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [])

  let rndAverage: any = {}
  let rndRank: any = {}

  let averageRDspendValue: any = '-'
  let averageRDspendRank: any = '-'

  // Donut Chart Values
  let scienceSpend: any = 0
  let engineeringSpend: any = 0
  let nonSESpend: any = 0

  //////// Table Values ////////
  let placeholder = ['-', '-', '-', '-', '-']
  // Science
  // // Geosciences
  let geosciencesSubArray: any = [
    { name: 'Atmospheric science and meteorology', value: placeholder },
    { name: 'Geological and earth sciences', value: placeholder },
    { name: 'Ocean sciences and marine sciences', value: placeholder },
    { name: 'Geosciences, atmospheric sciences, and ocean sciences, not elsewhere classified', value: placeholder },
  ]

  // // Life sciences
  let lifeSciencesSubArray: any = [
    { name: 'Agricultural sciences', value: placeholder },
    { name: 'Biological and biomedical sciences', value: placeholder },
    { name: 'Health sciences', value: placeholder },
    { name: 'Natural resources and conservation', value: placeholder },
    { name: 'Life sciences, not elsewhere classified', value: placeholder }
  ]

  // // Physical sciences
  let physicalSciencesSubArray: any = [
    { name: 'Astronomy and astrophysics', value: placeholder },
    { name: 'Chemistry', value: placeholder },
    { name: 'Materials Science', value: placeholder },
    { name: 'Physics', value: placeholder },
    { name: 'Physical sciences, not elsewhere classified', value: placeholder }
  ]

  // // Social sciences
  let socialSciencesSubArray: any = [
    { name: 'Anthropology', value: placeholder },
    { name: 'Economics', value: placeholder },
    { name: 'Political science and government', value: placeholder },
    { name: 'Sociology, demography, and population studies', value: placeholder },
    { name: 'Social sciences, not elsewhere classified', value: placeholder }
  ]

  // Engineering
  let engineeringSubArray: any = [
    { name: 'Aerospace, aeronautical, and astronautical engineering', value: placeholder },
    { name: 'Bioengineering and biomedical engineering', value: placeholder },
    { name: 'Chemical engineering', value: placeholder },
    { name: 'Civil engineering', value: placeholder },
    { name: 'Electrical, electronic, and communications engineering', value: placeholder },
    { name: 'Industrial and manufacturing engineering', value: placeholder },
    { name: 'Mechanical engineering', value: placeholder },
    { name: 'Metallurgical and materials engineering', value: placeholder },
    { name: 'Engineering not elsewhere classified', value: placeholder }
  ]

  // Non-S&E
  let nonSESubArray: any = [
    { name: 'Business management and business administration', value: placeholder },
    { name: 'Communication and communications technologies', value: placeholder },
    { name: 'Education', value: placeholder },
    { name: 'Humanities', value: placeholder },
    { name: 'Law', value: placeholder },
    { name: 'Social work', value: placeholder },
    { name: 'Visual and performing arts', value: placeholder },
    { name: 'Non-S&E, not elsewhere classified', value: placeholder }
  ]

  if (college && rndAnnual) {

    rndAverage = college.rnd_average
    rndRank = college.rnd_rank

    //Values for Donut Chart
    scienceSpend = rndAverage?.science
    engineeringSpend = rndAverage?.engineering
    nonSESpend = rndAverage?.nonScienceEng

    const returnData = (field) => {
      return [rndRank ? rndRank[field] : null, rndAverage ? rndAverage[field] : null, rndAnnual[0] ? rndAnnual[0][field] : null, rndAnnual[1] ? rndAnnual[1][field] : null, rndAnnual[2] ? rndAnnual[2][field] : null]
    }

    geosciencesSubArray = [
      { name: 'Atmospheric science and meteorology', value: returnData('atmospheric') },
      { name: 'Geological and earth sciences', value: returnData('geologicalEarth') },
      { name: 'Ocean sciences and marine sciences', value: returnData('oceanMarine') },
      { name: 'Geosciences, atmospheric sciences, and ocean sciences, not elsewhere classified', value: returnData('geoAtmoOceanNotClassfied') },
    ]

    lifeSciencesSubArray = [
      { name: 'Agricultural sciences', value: returnData('agriculturalSciences') },
      { name: 'Biological and biomedical sciences', value: returnData('biologicalBiomedical') },
      { name: 'Health sciences', value: returnData('healthSciences') },
      { name: 'Natural resources and conservation', value: returnData('naturalResources') },
      { name: 'Life sciences, not elsewhere classified', value: returnData('lifeSciencesNotClassified') }
    ]

    physicalSciencesSubArray = [
      { name: 'Astronomy and astrophysics', value: returnData('astronomyAstro') },
      { name: 'Chemistry', value: returnData('chemistry') },
      { name: 'Materials Science', value: returnData('materialsScience') },
      { name: 'Physics', value: returnData('physics') },
      { name: 'Physical sciences, not elsewhere classified', value: returnData('physicalSciencesNotClassified') }
    ]

    socialSciencesSubArray = [
      { name: 'Anthropology', value: returnData('anthropology') },
      { name: 'Economics', value: returnData('economics') },
      { name: 'Political science and government', value: returnData('politicalSciGovernment') },
      { name: 'Sociology, demography, and population studies', value: returnData('sociology') },
      { name: 'Social sciences, not elsewhere classified', value: returnData('socialSciencesNotClassified') }
    ]

    engineeringSubArray = [
      { name: 'Aerospace, aeronautical, and astronautical engineering', value: returnData('aerospace') },
      { name: 'Bioengineering and biomedical engineering', value: returnData('bioengineeringBiomed') },
      { name: 'Chemical engineering', value: returnData('chemicalEng') },
      { name: 'Civil engineering', value: returnData('civilEng') },
      { name: 'Electrical, electronic, and communications engineering', value: returnData('electricalEng') },
      { name: 'Industrial and manufacturing engineering', value: returnData('industrialManufEng') },
      { name: 'Mechanical engineering', value: returnData('mechanicalEng') },
      { name: 'Metallurgical and materials engineering', value: returnData('metallurgicalEng') },
      { name: 'Engineering not elsewhere classified', value: returnData('engineeringNotClassified') }
    ]

    nonSESubArray = [
      { name: 'Business management and business administration', value: returnData('business') },
      { name: 'Communication and communications technologies', value: returnData('communication') },
      { name: 'Education', value: returnData('education') },
      { name: 'Humanities', value: returnData('humanities') },
      { name: 'Law', value: returnData('law') },
      { name: 'Social work', value: returnData('socialWork') },
      { name: 'Visual and performing arts', value: returnData('visualPerformingArts') },
      { name: 'Non-S&E, not elsewhere classified', value: returnData('nonScienceEngNotClassified') }
    ]


  }

  if (college.averageRDspendValue != null && college.averageRDspendRank) {
    averageRDspendValue = (college.averageRDspendValue / 1000).toFixed(1)
    averageRDspendRank = college.averageRDspendRank
  }

  const rdDonutChart = (
    <Chart
      series={[scienceSpend, engineeringSpend, nonSESpend]}
      options={{
        /*responsive: [{
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'right',
              offsetX: 0,
              offsetY: 0
            },
          }
        }],*/
        yaxis: {
          labels: {
            formatter: function (val, opts) {
              return val.toLocaleString("en-US");
            },
          },
        },
        legend: {
          position: 'bottom',
          offsetX: 0,
          offsetY: 0,
          onItemClick: {
            toggleDataSeries: false
          },
          onItemHover: {
            highlightDataSeries: false
          },
        },
        colors: ["#90F892", "#FFEF68", "#97E6FF"],
        tooltip: {
          enabled: true,
          fillSeriesColor: false,
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#000000'],
          },
          background: {
            borderWidth: 0,
            foreColor: '#000',
          },
          // formatter: function (val, opts) {
          //   return val.toLocaleString("en-US");
          // },
        },
        plotOptions: {
          pie: {
            customScale: 1.0,
            donut: {
              size: '60%'
            }
          }
        },
        labels: ['Science', 'Engineering', 'Non-Science/Engineering']
      }}
      type='donut'
      width={"400"}
    />
  )

  const regularRow = (toggle, readableField, field) => {
    return <tr className={`collapse ${toggleCollapse[toggle] ? '' : 'show'}`}>
      <th className='fs-4 text-start'>{readableField}</th>
      <td className='text-center'>
        {returnRankModalTrigger(readableField, rndRank ? rndRank[field] : '-')}
      </td>
      <td className='text-center'>{returnSpendFigure(numberConverter(rndAverage ? rndAverage[field] : null))}</td>
      {rndAnnual.map((annual, index) => (
        <td key={index} className='text-center'>{returnSpendFigure(numberConverter(annual[field]))}</td>
      ))}
    </tr>
  }

  const parentRow = (field, toggleA, readableField) => {
    return <tr className='text-center'>
      <th
        className='fw-bold fs-4 text-start'
        style={{ cursor: 'pointer' }}
        onClick={() => {
          handleToggle(toggleA)
        }}
      >{readableField}<i className={`ms-2 bi ${toggleCollapse[toggleA] ? 'bi-chevron-right' : 'bi-chevron-up'}`} style={{ color: 'black' }}></i></th>
      <td>{returnRankModalTrigger(readableField, rndRank ? rndRank[field] : '-')}</td>
      <td>{returnSpendFigure(numberConverter(rndAverage ? rndAverage[field] : null))}</td>
      <td>{returnSpendFigure(numberConverter(rndAnnual[0] ? rndAnnual[0][field] : null))}</td>
      <td>{returnSpendFigure(numberConverter(rndAnnual[1] ? rndAnnual[1][field] : null))}</td>
      <td>{returnSpendFigure(numberConverter(rndAnnual[2] ? rndAnnual[2][field] : null))}</td>
    </tr>
  }

  const subparentRow = (field, toggleA, toggleB, readableField) => {
    return <tr className={`text-center collapse ${toggleCollapse[toggleA] ? '' : 'show'}`}>
      <th
        className='fs-4 text-start'
        style={{ cursor: 'pointer' }}
        onClick={() => {
          handleToggle(toggleB)
        }}
      >{readableField}<i className={`ms-2 bi ${toggleCollapse[toggleB] ? 'bi-chevron-right' : 'bi-chevron-up'}`} style={{ color: 'black' }}></i></th>
      <td>{returnRankModalTrigger(readableField, rndRank ? rndRank[field] : '-')}</td>
      <td>{returnSpendFigure(numberConverter(rndAverage ? rndAverage[field] : null))}</td>
      <td>{returnSpendFigure(numberConverter(rndAnnual[0] ? rndAnnual[0][field] : null))}</td>
      <td>{returnSpendFigure(numberConverter(rndAnnual[1] ? rndAnnual[1][field] : null))}</td>
      <td>{returnSpendFigure(numberConverter(rndAnnual[2] ? rndAnnual[2][field] : null))}</td>
    </tr>
  }

  const childRow = (array, toggle, toggleB?) => {

    let res = array.map((field, index) => {

      if (field && field.value) {
        return <tr key={index} className={`collapse ${toggleCollapse[toggle] || toggleCollapse[toggleB] ? '' : 'show'}`}>
          <th style={{ fontStyle: 'italic' }}>{field.name}</th>
          <td className='text-center'>{returnRankModalTrigger(field.name, field.value[0] ? field.value[0] : null)}</td>
          <td className='text-center'>{returnSpendFigure(numberConverter(field.value[1]))}</td>
          <td className='text-center'>{returnSpendFigure(numberConverter(field.value[2]))}</td>
          <td className='text-center'>{returnSpendFigure(numberConverter(field.value[3]))}</td>
          <td className='text-center'>{returnSpendFigure(numberConverter(field.value[4]))}</td>
        </tr>
      }

      else {
        return <tr key={index} className={`collapse ${toggleCollapse[toggle] ? '' : 'show'}`}>
          <th>{field.name}</th>
          <td className='text-center'>{returnRankModalTrigger(field.name, '-')}</td>
          <td className='text-center'>-</td>
          <td className='text-center'>-</td>
          <td className='text-center'>-</td>
          <td className='text-center'>-</td>
        </tr>
      }
    })

    return res
  }

  const childRowSubParentFont = (array, toggle) => {

    let res = array.map((field, index) => {

      if (field && field.value) {
        return <tr key={index} className={`collapse ${toggleCollapse[toggle] ? '' : 'show'}`}>
          <th className='fs-4 text-start'>{field.name}</th>
          <td className='text-center'>{returnRankModalTrigger(field.name, field.value[0])}</td>
          <td className='text-center'>{returnSpendFigure(numberConverter(field.value[1]))}</td>
          <td className='text-center'>{returnSpendFigure(numberConverter(field.value[2]))}</td>
          <td className='text-center'>{returnSpendFigure(numberConverter(field.value[3]))}</td>
          <td className='text-center'>{returnSpendFigure(numberConverter(field.value[4]))}</td>
        </tr>
      }

      else {
        return <tr key={index} className={`collapse ${toggleCollapse[toggle] ? '' : 'show'}`}>
          <th className='fs-4 text-start'>{field.name}</th>
          <td className='text-center'>{returnRankModalTrigger(field.name, '-')}</td>
          <td className='text-center'>-</td>
          <td className='text-center'>-</td>
          <td className='text-center'>-</td>
          <td className='text-center'>-</td>
        </tr>
      }
    })

    return res
  }

  const RDTable = (
    <table className="table table-borderless">
      <thead>
        <tr className='fw-bold text-center'>
          <th scope="col"></th>
          <th scope="col" className='d-flex flex-row'>RANK <div style={{ marginLeft: '5px' }}><TooltipComponent content={`This column displays, for each R&D spend category, the relative rank for ${college.name} compared against all 100 colleges featured on this site, based on annual average spend. Click on any rank indicator to see how all colleges rank for that specific R&D spend category.`} /></div></th>
          <th scope="col">3-YEAR AVG SPEND ($000s)</th>
          <th scope="col">{rndAnnual[0]?.year ?? '-'} SPEND ($000s)</th>
          <th scope="col">{rndAnnual[1]?.year ?? '-'} SPEND ($000s)</th>
          <th scope="col">{rndAnnual[2]?.year ?? '-'} SPEND ($000s)</th>
        </tr>
      </thead>

      <tbody>

        {parentRow('science', 'scienceToggle', 'Science')}
        {regularRow('scienceToggle', 'Computer and information sciences', 'computerInfo')}
        {subparentRow('geoscienceAtmosphericOcean', 'scienceToggle', 'geoToggle', 'Geosciences, atmospheric sciences, and ocean sciences')}
        {childRow(geosciencesSubArray, 'geoToggle', 'scienceToggle')}
        {subparentRow('lifeSciences', 'scienceToggle', 'lifeToggle', 'Life Sciences')}
        {childRow(lifeSciencesSubArray, 'lifeToggle', 'scienceToggle')}
        {regularRow('scienceToggle', 'Math and statistics', 'mathStatistics')}
        {subparentRow('physicalSciences', 'scienceToggle', 'physToggle', 'Physical sciences')}
        {childRow(physicalSciencesSubArray, 'physToggle', 'scienceToggle')}
        {regularRow('scienceToggle', 'Psychology', 'psychology')}
        {subparentRow('socialSciences', 'scienceToggle', 'socialToggle', 'Social sciences')}
        {childRow(socialSciencesSubArray, 'socialToggle', 'scienceToggle')}
        {regularRow('scienceToggle', 'Sciences, not elsewhere classified', 'sciencesNotClassified')}

        {parentRow('engineering', 'engineeringToggle', 'Engineering')}
        {childRowSubParentFont(engineeringSubArray, 'engineeringToggle')}

        {parentRow('nonScienceEng', 'nonSEToggle', 'Non-S&E')}
        {childRowSubParentFont(nonSESubArray, 'nonSEToggle')}

      </tbody>
    </table>)

  const averageRDSpendHeader = (
    <div
      className="d-flex flex-row justify-content-between"
      style={{ marginTop: '20px' }}
    >

      <div className='d-flex flex-row'>
        <h4 className="me-2 align-self-end">Average Annual Total R&D Spend: </h4>
        <div className="me-2 fw-bold align-self-center" style={{ fontSize: '16px' }}>{averageRDspendValue != '-' ? `$${numberConverter(parseInt(averageRDspendValue))}M` : '-'}</div>

        <div
          className="me-2 align-self-end"
          style={{ cursor: 'pointer' }}
          data-bs-toggle="modal"
          data-bs-target="#view_specialized_rankings_modal"
          onClick={() => setModalTitle('Average Annual Total R&D Spend')}
        ><RankIndicator rank={averageRDspendRank} />
        </div>

        <div className="d-flex align-self-center"><TooltipComponent content={`This is the average annual overall R&D spend for ${college.name} (based on the last three years of available data) and the corresponding relative rank among the 100 leading colleges featured on this site. Click on the rank indicator to view comparative ranks for total R&D spend at all colleges.`} /></div>

      </div>

      <RankColorRange />


    </div>
  )

  return (
    <div className="card shadow" style={{ marginTop: '40px' }}>
      <div id="rdSpend_inner_card_content_margin" style={{ marginLeft: '20px', marginRight: '20px' }}>
        {averageRDSpendHeader}

        <div className='d-flex flex-column justify-content-center align-items-center' style={{ marginTop: '50px' }}>
          <h3>R&D Spend by Field</h3>
          {rdDonutChart}
        </div>

        <div style={{ marginTop: '20px', width: '90vw' }}>
          {RDTable}
        </div>

        <div style={{ margin: '50px 0px 15px 0px' }}>Source: National Center for Science and Engineering Statistics.</div>

        <RnDSpendModal
          collegeData={collegeData}
          dataLoaded={dataLoaded}
          modalTitle={modalTitle}
          year0={rndAnnual[0]?.year ?? '-'}
          year1={rndAnnual[1]?.year ?? '-'}
          year2={rndAnnual[2]?.year ?? '-'} />

        {/* Show ranking of average annual R&D Spend available if needed: */}
        <SpecializedRankingModal
          collegeData={collegeData}
          dataLoaded={dataLoaded}
          modalTitle={'Average Annual Total R&D Spend'}
          latestYear={latestYear}
          numberConverter={numberConverterForSpecializedModal} />

      </div>
    </div>
  )

}

export { RnDSpend }
