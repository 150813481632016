import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import { AuthModel, UserModel, DjangoUserModel } from './_models'
import * as authHelper from './AuthHelpers'
import { getUserByToken, getUserProfile } from './_requests'
import { WithChildren } from '../../../../_metronic/helpers'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  djangoUser: DjangoUserModel | undefined
  setDjangoUser: Dispatch<SetStateAction<DjangoUserModel | undefined>>
  logout: () => void
  requestUser: (apiToken: string) => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  djangoUser: undefined,
  setDjangoUser: () => { },
  logout: () => { },
  requestUser: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()
  const [djangoUser, setDjangoUser] = useState<DjangoUserModel | undefined>()
  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = () => {
    saveAuth(undefined)
    setCurrentUser(undefined)
    setDjangoUser(undefined)
  }

  const requestUser = async (apiToken: string) => {

    try {
      const { data: user } = await getUserByToken(apiToken)
      const { data: userProfileData } = await getUserProfile(user.pk, apiToken)

      if (user && userProfileData) {
        // console.log('Auth init Django user set:', user)
        setDjangoUser(user)
        // console.log('Auth init userprofile data:', userProfileData)

        const groupIds = user.groups.map(group => group.name)

        const updatedAuth: AuthModel = {
          api_token: apiToken,
          groups: groupIds
        }

        authHelper.setAuth(updatedAuth)
        // console.log('Groups from authHelper getAuth function', authHelper.getAuth()?.groups)

        setCurrentUser(userProfileData)
      }
    } catch (error) {
      console.error(error)
      logout()
    }
  }

  useEffect(() => {
    if (auth && auth?.api_token) {
      console.log('Auth init triggered, auth and auth.apitoken found')
      requestUser(auth.api_token)
    } else {
      logout()
    }
  }, [])

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, djangoUser, setDjangoUser, logout, requestUser }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {

  return <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
