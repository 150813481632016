import { ProfileInfo } from "./ProfileInfo"
import { CollegesOfInterest } from "./CollegesOfInterest"
import { MajorsOfInterest } from "./MajorsOfInterest"
import { ECActivitiesOfInterest } from "./ECActivitiesOfInterest"
import { AccountManagement } from "./AccountManagement"

const TabContent = ({ selectedTab, tabOptions, user, setUser, userCollegeInterest, setUserCollegeInterest, collegesId, ecActivities, majors, apiToken, userData, setUserData, setCollegeDetailSelectedTab }) => {

  switch (selectedTab) {
    case tabOptions[0]:
      return <ProfileInfo user={user} setUser={setUser} apiToken={apiToken} userData={userData} setUserData={setUserData} />
    case tabOptions[1]:
      return <CollegesOfInterest
        user={user}
        userData={userData}
        userCollegeInterest={userCollegeInterest}
        setUserCollegeInterest={setUserCollegeInterest}
        collegesId={collegesId}
        setUser={setUser} />
    case tabOptions[2]:
      return <MajorsOfInterest
        user={user}
        userData={userData}
        majors={majors}
        setUser={setUser}
        setUserData={setUserData} />
    case tabOptions[3]:
      return <ECActivitiesOfInterest
        user={user}
        userData={userData}
        setUser={setUser}
        ecActivities={ecActivities}
        setUserData={setUserData} />
    case tabOptions[4]:
      return <AccountManagement user={user} userData={userData} setCollegeDetailSelectedTab={setCollegeDetailSelectedTab} />
    default:
      return <ProfileInfo
        user={user}
        setUser={setUser}
        apiToken={apiToken}
        userData={userData}
        setUserData={setUserData} />
  }

}

export { TabContent }