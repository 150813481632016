import { useState, useEffect } from 'react'
import Select from 'react-select'
import { TooltipComponent } from '../Tooltip'
import { MajorReactGAHandlers } from '../ReactGAHandlers'

const Aside = ({ collegeSet, colleges, collegesOptions, setShowRelatedMajors, results, setCollegeSelect, setCollegeSetSelect, setPublicSelect, setPrivateSelect, majorName }) => {

  //////// College ////////
  const [selectedColleges, setSelectedColleges] = useState<any>([])

  const handleCollegeSelectChange = (selectedColleges) => {
    setSelectedColleges(selectedColleges);
    let collegeArr: any = []
    selectedColleges.map((college) =>
      collegeArr.push(college.value))
    setCollegeSelect(collegeArr)
  }

  const collegeSelect = (
    <div className='mt-4 d-flex flex-column'>
      <div className='dca-aside-header'>COLLEGE</div>
      <Select
        options={collegesOptions}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 'transparent',
            backgroundColor: '#F9F9F9'
          })
        }}
        isMulti
        value={selectedColleges}
        onChange={handleCollegeSelectChange}
      // onInputChange={(inputValue) => {
      //   handleInputNameChange(inputValue)
      // }}
      />
    </div>
  )


  //////// School Type ////////
  let publicCount: any = '-'
  let privateCount: any = '-'

  const [schoolTypeSelected, setSchoolTypeSelected] = useState<any>(0)

  const handleCheckChange = (event) => {
    let checkStatus = event.target.checked
    return checkStatus
  }

  if (results.length > 0 && colleges.length > 0) {
    let collegeIds: any = []
    results.map((res) => {
      collegeIds.push(res.college_id)
    })

    let collegeArr: any = []
    collegeIds.map((college) => {
      collegeArr.push(colleges.filter((item) => item.id == college))
    })

    publicCount = collegeArr.filter((item) => item[0].type === 1).length

    privateCount = collegeArr.filter((item) => ((item[0].type === 2) || (item[0].type === 3))).length
  }

  useEffect(() => {

    switch (true) {
      case schoolTypeSelected === 0:
        setPrivateSelect(false);
        setPublicSelect(false);
        break;
      case schoolTypeSelected === 1:
        setPrivateSelect(false);
        setPublicSelect(true);
        break;
      case schoolTypeSelected === 2:
        setPrivateSelect(true);
        setPublicSelect(false);
        break;
      default:
        setPrivateSelect(false);
        setPublicSelect(false);
        break;
    }
  }, [schoolTypeSelected])

  const schoolTypeSelect = (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
      <div className='dca-aside-header'>INSTITUTION TYPE</div>
      <div className='d-flex flex-row justify-content-center align-items-center'>
        <button
          type="button"
          onClick={() => setSchoolTypeSelected(0)}
          className={`btn btn-sm ${schoolTypeSelected === 0 ? 'btn-success' : 'btn-light'}`}
          aria-pressed="true"
          style={{ width: '100%' }}
        >
          All
        </button>
        <button
          type="button"
          onClick={() => setSchoolTypeSelected(1)}
          className={`btn btn-sm ${schoolTypeSelected === 1 ? 'btn-success' : 'btn-light'}`}
          aria-pressed="true"
          style={{ width: '100%' }}
        >
          Public
        </button>
        <button
          type="button"
          onClick={() => setSchoolTypeSelected(2)}
          className={`btn btn-sm ${schoolTypeSelected === 2 ? 'btn-success' : 'btn-light'}`}
          aria-pressed="true"
          style={{ width: '100%' }}
        >
          Private
        </button>
      </div>
    </div>
  )

  //////// College Set ////////
  const [selectedSet, setSelectedSet] = useState<any>([])

  const handleCollegeSetSelectChange = (selectedSet) => {
    setSelectedSet(selectedSet);
    if (selectedSet.length > 0) {
      setCollegeSetSelect(selectedSet[0].value)
    }
    else {
      setCollegeSetSelect(selectedSet)
    }
  }

  const collegeSetSelect = (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
      <div className='dca-aside-header'>COLLEGE SET</div>
      <Select
        options={collegeSet}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 'transparent',
            backgroundColor: '#F9F9F9'
          })
        }}
        isMulti
        value={selectedSet}
        onChange={handleCollegeSetSelectChange}
      />
    </div>
  )


  //////// Show Related Majors ////////

  const { handleGAEventShowRelated } = MajorReactGAHandlers()

  const handleClickActionsShowRelatedMajors = (event) => {
    try {
      setShowRelatedMajors(handleCheckChange(event))
    } catch (error) {
      throw error
    } finally {
      // Trigger handleGAEventShowRelated whenever it is checked true/active
      handleCheckChange(event) == true && handleGAEventShowRelated()
    }
  }

  const showRelatedMajorsToggle = (
    <div style={{ marginTop: '15px' }}>
      <label className="checkbox d-flex justify-content-between" style={{ cursor: 'pointer' }}>
        <div>
          <input
            type="checkbox"
            className='dca-checkbox'
            name="Show Related Majors"
            onChange={(event) => {
              handleClickActionsShowRelatedMajors(event)
            }}
            style={{ cursor: 'pointer' }}
          />
          <span className='ms-3 me-3' style={{ display: 'inline-block' }}>Show Related Majors <span className='ms-1'><TooltipComponent content={`Select the checkbox to also display majors that are similar to ${majorName}.`} /></span></span>
        </div>
      </label>
    </div>
  )


  return (
    <div style={{ width: '260px', maxWidth: '260px', whiteSpace: 'nowrap' }}>
      {collegeSelect}
      {/* Hide schoolTypeSelect while type field is not accessible */}
      {/* {schoolTypeSelect} */}
      {collegeSetSelect}
      {showRelatedMajorsToggle}
    </div>
  )
}

export { Aside }
