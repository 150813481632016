import { useEffect } from "react"
import { useLocation } from "react-router"

const ECSchoolLevelSearch = ({ schoolLevelSelect, setSchoolLevelSelect }) => {

  const location = useLocation()

  const schoolLevelOptions = [
    { value: 0, label: 'Any' },
    { value: 1, label: 'High School' },
    { value: 2, label: 'Middle School' },
    { value: 3, label: 'Elementary School' }
  ]

  const handleSchoolLevelSelectChange = (event) => {
    let res = event.target.value
    setSchoolLevelSelect(parseInt(res))
  }

  useEffect(() => {

    const params = new URLSearchParams(location.search);
    const schoolLevelParam = params.get('schoolLevel');

    if (schoolLevelParam) {
      setSchoolLevelSelect(parseInt(schoolLevelParam));
    }

    else {
      setSchoolLevelSelect(0)
    }
  }, [location.search]);

  return (
    <div className='mt-4 d-flex flex-column'>
      <div className='dca-aside-header'>SCHOOL LEVEL</div>
      <select
        className='form-select form-select-solid'
        data-control="select2"
        value={schoolLevelSelect}
        onChange={handleSchoolLevelSelectChange}
        style={{ cursor: 'pointer' }}
      >
        {schoolLevelOptions.map((res, index) => (
          <option
            key={index}
            value={res.value}>
            {res.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export { ECSchoolLevelSearch }