import { useState, useEffect } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { getUserByToken, registerUser, createNewUserProfile, getUserProfile, login } from '../../modules/auth/core/_requests'
import { Link } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { PasswordMeterComponent } from '../../../_metronic/assets/ts/components'
import { useAuth } from '../../modules/auth/core/Auth'
import { setAuth } from '../../modules/auth'
import { URL } from '../../components/dictionary'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'

const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  password1: '',
  password2: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .required('First name is required'),
  lastname: Yup.string()
    .required('Last name is required'),
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password1: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .matches(/(?=.*\d)/, 'Password must contain at least one number')
    .required('Password is required'),
  password2: Yup.string()
    .min(8, 'Minimum 8 symbols')
    .max(50, 'Maximum 50 symbols')
    .matches(/(?=.*\d)/, 'Password must contain at least one number')
    .required('Password is required')
    .oneOf([Yup.ref('password1')], "Password and Confirm Password didn't match"),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})

export function SignUpPage({ setUser }) {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  let navigate = useNavigate()

  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        await registerUser(
          values.email,
          values.password1,
          values.password2
        );

        const { data: authToken } = await login(values.email, values.password1)
        console.log('Login successful')

        const { data: user } = await getUserByToken(authToken.key)
        localStorage.setItem('apiToken', authToken.key)
        console.log('User retrieved: ', user)

        await createNewUserProfile(
          user.pk,
          values.firstname,
          values.lastname,
          values.email
        );

        const { data: userprofile } = await getUserProfile(user.pk, authToken.key)
        console.log('UserProfile: ', userprofile)
        setCurrentUser(userprofile)
        const groupIds = user.groups.map((group => group.id))

        saveAuth({ api_token: authToken.key, groups: groupIds })
        // setAuth(authToken.key)
        navigate(`/home`)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The registration details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  const signUpForm = (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>

        <div className='text-gray-500 fw-semibold fs-6'>Your Social Campaigns</div>
      </div>

      <div className='row g-3 mb-9'>
        <div className='col-md-6'>
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/google-icon.svg')}
              className='h-15px me-3'
            />
            Sign in with Google
          </a>
        </div>
        <div className='col-md-6'>
          <a
            href='#'
            className='btn btn-flex btn-outline btn-text-gray-700 btn-active-color-primary bg-state-light flex-center text-nowrap w-100'
          >
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black.svg')}
              className='theme-light-show h-15px me-3'
            />
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/svg/brand-logos/apple-black-dark.svg')}
              className='theme-dark-show h-15px me-3'
            />
            Sign in with Apple
          </a>
        </div>
      </div>

      <div className='separator separator-content my-14'>
        <span className='w-125px text-gray-500 fw-semibold fs-7'>Or with email</span>
      </div>

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      <div className='fv-row mb-8'>
        <input
          placeholder='First name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('firstname')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.firstname && formik.errors.firstname,
            },
            {
              'is-valid': formik.touched.firstname && !formik.errors.firstname,
            }
          )}
        />
        {formik.touched.firstname && formik.errors.firstname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.firstname}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <input
          placeholder='Last name'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('lastname')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.lastname && formik.errors.lastname,
            },
            {
              'is-valid': formik.touched.lastname && !formik.errors.lastname,
            }
          )}
        />
        {formik.touched.lastname && formik.errors.lastname && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.lastname}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-8'>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            { 'is-invalid': formik.touched.email && formik.errors.email },
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Password'
              autoComplete='off'
              {...formik.getFieldProps('password1')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password1 && formik.errors.password1,
                },
                {
                  'is-valid': formik.touched.password1 && !formik.errors.password1,
                }
              )}
            />
            {formik.touched.password1 && formik.errors.password1 && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password1}</span>
                </div>
              </div>
            )}
          </div>

          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>

        </div>
        <div className='text-muted'>
          Use 8 or more characters with a mix of letters and at least one number.
        </div>
      </div>

      <div className='fv-row mb-5'>

        <input
          type='password'
          placeholder='Repeat Password'
          autoComplete='off'
          {...formik.getFieldProps('password2')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.password2 && formik.errors.password2,
            },
            {
              'is-valid': formik.touched.password2 && !formik.errors.password2,
            }
          )}
        />
        {formik.touched.password2 && formik.errors.password2 && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password2}</span>
            </div>
          </div>
        )}
      </div>

      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            I Accept the{' '}
            <a
              href='https://keenthemes.com/metronic/?page=faq'
              target='_blank'
              className='ms-1 link-primary'
            >
              Terms
            </a>
            .
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>

      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Sign Up</span>}
          {loading && (
            <span className='indicator-progress' style={{ display: 'block' }}>
              Please wait...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          {/* <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancel
          </button> */}
        </Link>
      </div>
      <div className='text-gray-500 text-center fw-semibold fs-6'>
        Already have an Account? {' '}
        <Link to='/sign-in' className='link-primary'>
          Sign in
        </Link>
      </div>
    </form>
  )


  const handleSignup = async () => {
    const email = 'testcollege@email.com';
    const password = 'testpassword123';

    try {
      const response = await axios.post(`${URL}/dj-rest-auth/registration/`, {
        "email": email,
        "password1": password,
        "password2": password
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log(response.data, response.status)

    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        console.error('Error during signup:', error.response.data);
      } else {
        console.error('Unknown error during signup:', error);
      }
    }
  };

  const obtainAuthToken = async () => {
    const email = 'testcollege@email.com';
    const password = 'testpassword123';

    try {
      const response = await axios.post(`${URL}/login/`, {
        "email": email,
        "password": password
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      console.log(response.data, response.status)

    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        // Check if the error has a response property
        console.error('Error during signup:', error.response.data);
      } else {
        console.error('Unknown error during signup:', error);
      }
    }

  }

  const seeUserDetails = async () => {

    try {
      const res = await axios.get(`${URL}/user/`)

      console.log(res.data)
    } catch (error) {
      console.log(error)
      throw (error)
    }
  }


  return (
    <div className='d-flex flex-column'>
      <Link
        className="fw-bold align-self-center"
        to={'/home'}
        style={{ color: 'black', margin: '50px' }}>
        <i className="me-3 fa-solid fa-arrow-left" style={{ color: 'black', fontWeight: 'bold', marginTop: '30px' }} ></i>
        Back to Home</Link>
      <div className='card align-self-center' style={{ padding: '40px', margin: '50px', width: '60%' }}>
        {/* <button
          type='button'
          onClick={handleSignup}>Try Adding User</button>

        <button
          type='button'
          style={{ marginTop: '20px' }}
          onClick={obtainAuthToken}>Obtain Auth Token from user/ Login</button>

        <button
          type='button'
          style={{ marginTop: '20px' }}
          onClick={seeUserDetails}>See user details</button> */}
        {signUpForm}
      </div>
    </div>
  )
}