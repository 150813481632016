import { OverlayTrigger, Tooltip } from "react-bootstrap"

export const TooltipComponent = ({ content }) => {

  return (
    <OverlayTrigger
      delay={{ hide: 450, show: 300 }}
      overlay={(props) => (
        <Tooltip {...props} className='dca-tooltip'>
          {content}
        </Tooltip>
      )}
      placement="top"
    ><i
      className="bi bi-info-circle-fill"
      style={{ color: 'black' }}
    ></i>
    </OverlayTrigger>
  )
}

export const TooltipComponentDown = ({ content }) => {

  return (
    <OverlayTrigger
      delay={{ hide: 450, show: 300 }}
      overlay={(props) => (
        <Tooltip {...props} className='dca-tooltip'>
          {content}
        </Tooltip>
      )}
      placement="bottom"
    ><i
      className="bi bi-info-circle-fill"
      style={{ color: 'black' }}
    ></i>
    </OverlayTrigger>
  )
}


