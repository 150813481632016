import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { URL, pageRoutes, returnHyphenatedString } from '../components/dictionary';
import axios from 'axios';
import { collegeMap } from '../components/collegeMap';
import { CollegeDetailPage } from './CollegeDetailPage';
import { MajorDetailPage } from './MajorDetailPage';
import { ECDetailPage } from './ECDetailPage';
import { LoadingIndicator } from '../components/LoadingIndicator';
import { getAuth } from '../modules/auth';

const ConditionalRouter = (props) => {
  const location = useLocation();
  const path = location.pathname;
  const [routed, setRouted] = useState<any>(false)
  const [detailsGroup, setDetailsGroup] = useState('college')
  const [idToSend, setIDToSend] = useState<any>(0)

  let navigate = useNavigate()
  let apiToken: any = undefined

  if (getAuth()?.api_token) {
    apiToken = getAuth()?.api_token
  }

  useEffect(() => {

    if (path == pageRoutes.testScoreStrategy && !apiToken) {
      navigate(pageRoutes.home);
      return
    }

    const getData = async () => {

      routed && setRouted(false)

      let pathname = ''
      if (path) {
        pathname = path.split('/')[1]
      }

      let major = await axios.get(`${URL}/majors_name_id/`);

      let ec = await axios.get(`${URL}/ec_activity_name_id/`)

      const findCollege = (arr) => {
        return arr.some(res => {
          if (res.hyphenated == pathname) {
            setIDToSend(res.id);
            setDetailsGroup('college');
            setRouted(true);
            return true;
          }
          return false;
        });
      }

      const findGroup = (arr, detailsGroup) => {
        return arr.some(res => {
          if (returnHyphenatedString(res.name) === pathname) {
            setIDToSend(`${res.id}`);
            setDetailsGroup(detailsGroup);
            setRouted(true);
            return true;
          }
          return false;
        });
      }

      const collegeFound = findCollege(collegeMap);
      if (!collegeFound) {
        const majorFound = findGroup(major.data, 'major');
        if (!majorFound) {
          const ecFound = findGroup(ec.data, 'ec');
          if (!ecFound) {
            navigate(pageRoutes.home);
            return
          }
        }
      }
    }

    getData();

  }, [])

  switch (true) {
    case routed && detailsGroup === 'college':
      return <CollegeDetailPage
        user={props.user}
        setUser={props.setUser}
        userCollegeInterest={props.userCollegeInterest}
        setUserCollegeInterest={props.setUserCollegeInterest}
        collegesId={props.collegesId}
        collegeListLength={props.collegeListLength}
        prompt={props.prompt}
        setPrompt={props.setPrompt}
        navigateTo={props.navigateTo}
        setNavigateTo={props.setNavigateTo}
        setPurchaseID={props.setPurchaseID}
        setPurchaseModalCollegeName={props.setPurchaseModalCollegeName}
        selectedTab={props.selectedTab}
        setSelectedTab={props.setSelectedTab}
        collegeSearchLocation={props.collegeSearchLocation}
        idToSend={idToSend}
      />

    case routed && detailsGroup === 'major':
      return <MajorDetailPage
        user={props.user}
        setUser={props.setUser}
        collegeListLength={props.collegeListLength}
        prompt={props.prompt}
        setPrompt={props.setPrompt}
        navigateTo={props.navigateTo}
        setNavigateTo={props.setNavigateTo}
        majorSearchLocation={props.majorSearchLocation}
        idToSend={idToSend}
      />

    case routed && detailsGroup === 'ec':
      return <ECDetailPage
        user={props.user}
        setUser={props.setUser}
        setPrompt={props.setPrompt}
        setNavigateTo={props.setNavigateTo}
        setECName={props.setECName}
        setECNumAlumni={props.setECNumAlumni}
        setECID={props.setECID}
        setPurchaseID={props.setPurchaseID}
        clientSecret={props.clientSecret}
        setClientSecret={props.setClientSecret}
        ecName={props.ecName}
        ecSearchLocation={props.ecSearchLocation}
        idToSend={idToSend}
      />

    default:
      return <div
        className='d-flex justify-content-center align-items-center'
        style={{ margin: '25vh 0 25vh 0' }}><LoadingIndicator text={'Loading Details Page...'} /></div>
  }

};

export { ConditionalRouter };
