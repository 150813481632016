import { useEffect, useRef } from "react";
import noUiSlider from 'nouislider'
import { useLocation } from 'react-router-dom';

const Selectivity = ({ selectivityMin, selectivityMax, setSelectivityMin, setSelectivityMax, setAdmitParams }) => {

  const location = useLocation()
  const selectivitySliderRef = useRef(null);

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let admitParam = params.get('admitRate');

    if (admitParam) {
      const admitRateVals = admitParam.split(',').map(admit => (parseInt(admit)))
      setSelectivityMin(admitRateVals[0])
      setSelectivityMax(admitRateVals[1])
    }

    else {
      setSelectivityMin(0)
      setSelectivityMax(100)
    }

  }, [location.search]);

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let minMaxArr: any[] = []

    if (selectivityMin != 0 || selectivityMax != 100) {
      minMaxArr = [selectivityMin, selectivityMax]
      const minMax = minMaxArr.map((val) => val).join(',')
      params.set('admitRate', minMax)
    } else {
      params.delete('admitRate')
    }

    setAdmitParams(`${params.toString()}`)

  }, [selectivityMin, selectivityMax])

  useEffect(() => {

    if (!selectivitySliderRef.current) {

      const slider: any = document.querySelector("#kt_slider_selectivity");
      let params = new URLSearchParams(location.search);
      let admitParam = params.get('admitRate');

      if (admitParam) {
        const admitRateVals = admitParam.split(',').map(admit => (parseInt(admit)))

        noUiSlider.create(slider, {
          start: [admitRateVals[0], admitRateVals[1]],
          step: 1,
          range: {
            "min": 0,
            "max": 100
          },
          tooltips: {
            to: function (value) {
              return `${value.toFixed(0)}%`;
            }
          },
        });
      }

      else {
        noUiSlider.create(slider, {
          start: [0, 100],
          step: 1,
          range: {
            "min": 0,
            "max": 100
          },
          tooltips: {
            to: function (value) {
              return `${value.toFixed(0)}%`;
            }
          },
        })
      };

      slider.noUiSlider.on("set", function (values, handle) {
        if (handle === 0) {
          setSelectivityMin(parseInt(values[0]))
        }

        if (handle === 1) {
          setSelectivityMax(parseInt(values[1]))
        }
      });

      selectivitySliderRef.current = slider;
    }


  }, [])

  return <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
    <div className='dca-aside-header'>ADMIT RATE</div>
    <div className="mb-0" style={{ marginTop: '50px', zIndex: '1' }}>
      <div id="kt_slider_selectivity"></div>
    </div>
  </div>

}

export { Selectivity }