import Chart from 'react-apexcharts'

const GroupStackedColumnChart = ({ chartId, xaxis, earlyApplicantsArray, earlyAdmitArray, regularApplicantsArray, regularAdmitArray }) => {

  const yMax = Math.max(
    ...earlyApplicantsArray,
    ...earlyAdmitArray,
    ...regularApplicantsArray,
    ...regularAdmitArray
  ) + 5000

  // const allData = [...earlyApplicantsArray, ...earlyAdmitArray, ...regularApplicantsArray, ...regularAdmitArray]

  // let valuesNull = true

  // if (xaxis.some((x) => x !== null) && allData.some((data) => data != null)) {
  //   valuesNull = false
  // }

  return (
    <Chart
      options={{
        chart: {
          id: chartId,
          stacked: true,
        },
        xaxis: {
          categories: xaxis,
          labels: {
            show: true,
            rotate: -45,
            style: {
              colors: '#9AA2B6',
            },
          }
        },
        yaxis: {
          min: 0,
          //max: yMax,
          forceNiceScale: true,
          labels: {
            style: {
              colors: ['#9AA2B6'],
            },
            formatter: function (val, opts) {
              return val.toLocaleString("en-US");
            },
          }
        },
        //colors: ['#80c7fd', '#008FFB', '#80f1cb', '#00E396'],
        colors: ["#90F892", "#FFEF68", "#97E6FF", "#C5DEF9"],
        tooltip: {
          enabled: true,
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#000000'],
          },
          background: {
            enabled: false,
            borderWidth: 0,
            foreColor: '#000',
          },
          formatter: function (val, opts) {
            return val.toLocaleString("en-US");
          },
        },
        legend: {
          show: true,
          onItemClick: {
            toggleDataSeries: false
          },
          onItemHover: {
            highlightDataSeries: true
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '75%',
            dataLabels: {
              position: 'top',
              total: {
                enabled: false,
              }
            },
          }
        }
      }}
      series={[
        {
          name: 'Early Round Applicants',
          group: 'Applicants',
          data: earlyApplicantsArray
        },
        {
          name: 'Early Round Admits',
          group: 'Admits',
          data: earlyAdmitArray,
        },
        {
          name: 'Regular Round Applicants',
          group: 'Applicants',
          data: regularApplicantsArray
        },
        {
          name: 'Regular Round Admits',
          group: 'Admits',
          data: regularAdmitArray
        }
      ]}
      type='bar'
      height={'300'}
    />
  )
}

const GroupStackedColumnChartGender = ({ chartId, xaxis, menAppliedArray, menAdmittedArray, womenAppliedArray, womenAdmittedArray, otherAppliedArray, otherAdmittedArray }) => {

  const yMax = Math.max(
    ...menAppliedArray,
    ...menAdmittedArray,
    ...womenAppliedArray,
    ...womenAdmittedArray,
    ...otherAppliedArray,
    ...otherAdmittedArray
  ) + 5000

  return (
    <Chart
      options={{
        chart: {
          id: chartId,
          stacked: true,
        },
        xaxis: {
          categories: xaxis,
          labels: {
            show: true,
            rotate: -45,
            style: {
              colors: '#9AA2B6',
            },
          }
        },
        yaxis: {
          min: 0,
          //max: yMax,
          forceNiceScale: true,
          labels: {
            style: {
              colors: ['#9AA2B6'],
            },
            formatter: function (val, opts) {
              return val.toLocaleString("en-US");
            },
          }
        },
        colors: ["#90F892", "#FFEF68", "#C5DEF9", "#00E986", "#97E6FF", "767676"],
        tooltip: {
          enabled: true,
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#000000'],
          },
          background: {
            enabled: false,
            borderWidth: 0,
            foreColor: '#000',
          },
          formatter: function (val, opts) {
            return val.toLocaleString("en-US");
          },
        },
        legend: {
          show: true,
          onItemClick: {
            toggleDataSeries: false
          },
          onItemHover: {
            highlightDataSeries: true
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '75%',
            dataLabels: {
              position: 'top',
              total: {
                enabled: false,
              }
            },
          }
        }
      }}
      series={otherAppliedArray.some(item => item != 0) && otherAdmittedArray.some(item => item != 0) ? [
        {
          name: 'Men Applied',
          group: 'Applicants',
          data: menAppliedArray
        },
        {
          name: 'Men Admits',
          group: 'Admits',
          data: menAdmittedArray,
        },
        {
          name: 'Women Applied',
          group: 'Applicants',
          data: womenAppliedArray
        },
        {
          name: 'Women Admits',
          group: 'Admits',
          data: womenAdmittedArray
        },
        {
          name: 'Other Applied',
          group: 'Applicants',
          data: otherAppliedArray
        },
        {
          name: 'Other Admits',
          group: 'Admits',
          data: otherAdmittedArray
        }
      ] : [
        {
          name: 'Men Applied',
          group: 'Applicants',
          data: menAppliedArray
        },
        {
          name: 'Men Admits',
          group: 'Admits',
          data: menAdmittedArray,
        },
        {
          name: 'Women Applied',
          group: 'Applicants',
          data: womenAppliedArray
        },
        {
          name: 'Women Admits',
          group: 'Admits',
          data: womenAdmittedArray
        }
      ]}
      type='bar'
      height={'300'}
    />
  )
}

export {
  GroupStackedColumnChart,
  GroupStackedColumnChartGender
}
