import { LoadingSpinner } from "../../LoadingSpinner";
import Chart from 'react-apexcharts'
import { titleSpace } from "../../dictionary";

const FieldOfStudySelection = ({ result, cip, returnMajorAreaByID }) => {

  //When result is not set, inform that it is loading
  if (!result || !result.insights || !result.insights['Major Group Data'] || !result.insights['Individual Major Data'] || !cip) {
    return <LoadingSpinner title={'Field of Study Selection'} />;
  }

  let majorGroupData: any = result.insights['Major Group Data']
  let individualMajorData: any = result.insights['Individual Major Data']

  //Sort Individual Major Data
  individualMajorData.sort((a, b) => b.number - a.number)

  //Return functions for table
  const returnCIPName = (code, cip) => {
    if (!code || !cip) {
      return '-'
    }

    return cip.filter((item) => (item.code) == code)[0].name
  }
  const returnNumber = (num) => {

    if (!num) {
      return '-'
    }

    return num

  }
  const returnPercent = (res) => {

    if (!res) {
      return '-'
    }

    return `${((res) * 100).toFixed(1)}%`
  }

  // Set treemap data
  let treemapData: any = []
  majorGroupData.map((res) => {

    if (res.number !== null && res.number > 0) {
      treemapData.push({ x: returnMajorAreaByID(res.majorAreaID), y: res.number })
    }

  })

  return (

    <div style={{ marginTop: '40px' }}>
      <div className='card shadow'>
        <div className='fw-bold' style={{ fontSize: '26px', paddingTop: '40px', paddingLeft: '40px' }}>Declared Major(s) Of Activity Alumni - Summary By Major Group</div>
        <div style={{ marginLeft: '40px' }}>
          <Chart
            options={{
              chart: {
                id: 'Major Group Data Treemap'
              },
              colors: ["#FFEF68"],
              plotOptions: {
                treemap: {
                  enableShades: true,
                  shadeIntensity: 0.25,
                  dataLabels: {
                    format: "truncate"
                  },
                }
              },
              dataLabels: {
                enabled: true,
                style: {
                  colors: ['#000000'],
                },
                background: {
                  borderWidth: 0,
                  foreColor: '#000',
                },
              },

            }}
            series={[
              {
                name: 'Test',
                data: treemapData
              }
            ]}
            type='treemap'
            height={600}
            width={1000}
          />
        </div>
      </div>

      <div className='card shadow' style={{ marginTop: '40px' }}>
        <div className='fw-bold' style={{ fontSize: '26px', paddingTop: '40px', paddingLeft: '40px' }}>Declared Major(s) Of Activity Alumni - Breakdown By Major Area</div>
        <div>
          <table className="table table-borderless table-sm table-responsive" style={{ width: '65%', marginLeft: '40px', marginBottom: '20px', marginTop: '30px', borderSpacing: '0 15px', borderCollapse: 'separate' }}>
            <thead>
              <tr className="fw-bold">
                <th scope="col" style={{ letterSpacing: titleSpace }}>MAJOR AREA</th>
                <th scope="col" style={{ letterSpacing: titleSpace }}># STUDENTS</th>
                <th scope="col" style={{ letterSpacing: titleSpace }}>% MAJORS</th>
              </tr>
            </thead>
            <tbody>

              {individualMajorData.map((res, index) => (
                <tr key={index} style={{ borderBottom: '1px solid rgba(207,207,207,1)' }}>
                  <th scope='row' className="text-start fw-bold">{returnCIPName(res.cipCode, cip)}</th>
                  <td className="fw-bold">{returnNumber(res.number)}</td>
                  <td className="fw-bold">{returnPercent(res.percent)}</td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
      </div>
    </div>
  )

}

export { FieldOfStudySelection }
