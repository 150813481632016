import { useNavigate } from "react-router-dom"
import { KTIcon } from '../../../_metronic/helpers'
import academic1 from "../../../custom_assets/Overview/CollegeAppWalkthrough/image1v2.png"
import academic2 from "../../../custom_assets/Overview/CollegeAppWalkthrough/image2v2.png"
import academic3 from "../../../custom_assets/Overview/CollegeAppWalkthrough/image3v2.png"
import test1 from "../../../custom_assets/Overview/CollegeAppWalkthrough/image4v2.png"
import ec1 from "../../../custom_assets/Overview/CollegeAppWalkthrough/image5v2.png"
import awards1 from "../../../custom_assets/Overview/CollegeAppWalkthrough/image6v2.png"
import letters1 from "../../../custom_assets/Overview/CollegeAppWalkthrough/image7v2.png"
import letters2 from "../../../custom_assets/Overview/CollegeAppWalkthrough/image8v2.png"
import letters3 from "../../../custom_assets/Overview/CollegeAppWalkthrough/image9v2.png"
import study1 from "../../../custom_assets/Overview/CollegeAppWalkthrough/image10v2.png"


const CollegeAppWalkthrough = ({ hashPath, verticalTabs, overviewSidebarActive, sidebar, handleClickOffcanvas, pagination }) => {

  const navigate = useNavigate();

  const returnPathname = (hashPath) => {

    switch (true) {
      case hashPath === 'academics':
        return 'Academics'
      case hashPath === 'standardized-test-scores':
        return 'Standardized Test Scores'
      case hashPath === 'extracurricular-activities':
        return 'Extracurricular Activities'
      case hashPath === 'honors-and-awards':
        return 'Honors and Awards'
      case hashPath === 'letters-of-recommendation':
        return 'Letters of Recommendation'
      case hashPath === 'personal-statement-and-supplemental-essays':
        return 'Personal Statement and Supplemental Essays'
      case hashPath === 'intended-field-of-study':
        return 'Intended Field of Study'
      default:
        return ''
    }
  }

  let breadcrumbs = [
    { title: 'Overview', path: `/introduction` },
    { title: 'College Application Walkthrough', path: `/college-application-walkthrough` }
  ]

  if (hashPath !== '') {
    breadcrumbs = [
      { title: 'Overview', path: `/introduction` },
      { title: 'College Application Walkthrough', path: `/college-application-walkthrough` },
      { title: returnPathname(hashPath), path: `/college-application-walkthrough/${hashPath}` }
    ]
  }

  const breadcrumbComponent = (
    <ol className="breadcrumb text-muted fs-6 fw-bold align-self-start">
      {breadcrumbs.map((item, index) => (
        <li key={index} className="breadcrumb-item pe-3">
          {index < breadcrumbs.length - 1 ? (
            <a href="#" className="pe-3 dca-breadcrumb-link" onClick={() => navigate(item.path)}>
              {item.title}
            </a>
          ) : (
            <span className="px-3 text-muted">{item.title}</span>
          )}
        </li>
      ))}
    </ol>
  );

  const base = (
    <>
      <span className="dca-overview-title">College Application Walkthrough</span>

      <span className="dca-overview-font-standard" style={{ marginTop: '5vh', lineHeight: '1.75em' }}>In the following sections, we will further explore the core college admissions criteria and illustrate how they surface within the context of an actual application. For better or worse, the reality of the process is that the student's entire body of work - including all their academic and extracurricular achievements throughout high school - ultimately has to be packaged into a handful of pages, typically with pre-defined structures and constraints. And with the admissions committees receiving upwards of tens of thousands of these applications, it is critical to maximize the impact you can convey in your application. As such, we believe it is important to understand what you are working towards so you can be optimally prepared when it comes time to create your application senior year.</span>

      <span className="dca-overview-paragraph">Tactically speaking, there are a variety of application formats. Some schools have their own proprietary, online application process. Most schools partner with third-party application platforms (such as Common Application and Coalition Application) that allow students to use a common application interface to apply to multiple schools. Regardless of the specific application platform, the core focus areas of the college application are consistent and aligned with the key criteria outlined earlier.</span>

      <span className="dca-overview-paragraph">For purposes of this walkthrough, we are displaying the Common Application as it is the most widely-used application platform, supporting over 1,000 colleges in the United States.</span>

      <span className="dca-overview-font-standard" style={{ marginTop: '20px', lineHeight: '1.75em', fontStyle: 'italic' }}>(Note, this site is not affiliated with, nor endorsed by, the Common App in any way.)</span>
    </>
  )

  const academics = (
    <>
      <span className="dca-overview-title">Academics</span>

      <span className="dca-overview-font-standard" style={{ marginTop: '5vh', lineHeight: '1.75em' }}>The student's high school academic record is typically the most important consideration for admission to selective US colleges. There are three different aspects of academics that may surface in the college application:</span>

      <span className="dca-overview-font-standard" style={{ marginTop: '20px', lineHeight: '1.75em', textIndent: '50px', alignSelf: 'start' }}> 1. Grade Point Average (GPA)</span>

      <span className="dca-overview-paragraph">You will report your current GPA, as provided by your high school, which will reflect the cumulative performance in your classwork through the time the application is submitted (typically will include all of freshman, sophomore and junior year grades, plus potentially part of senior year depending on the timing of submission.)  The application may ask for your unweighted and/or weighted GPA, as illustrated in the Common App example below.</span>

      <img
        src={academic1}
        alt="academic1"
        className="d-flex align-self-center"
        style={{
          //width: '60%',
          //maxHeight: '60%',
          objectFit: 'fill',
          objectPosition: '50% 50%',
          borderRadius: '10px',
          margin: '4vh 0vh'
        }}
      ></img>

      <span className="dca-overview-font-standard" style={{ marginTop: '20px', lineHeight: '1.75em', textIndent: '50px', alignSelf: 'start' }}> 2. Class Rank</span>

      <span className="dca-overview-paragraph">Colleges will consider the student's relative placement within the graduating class, if class rank information is made available. The Common App allows for different formats of class rank (i.e. exact rank vs by segment):
      </span>

      <img
        src={academic2}
        alt="overview_stock_image"
        className="d-flex align-self-center"
        style={{
          //width: '65%',
          //maxHeight: '40%',
          objectFit: 'fill',
          objectPosition: '50% 50%',
          borderRadius: '10px',
          margin: '4vh 0vh'
        }}
      ></img>

      <span className="dca-overview-font-standard" style={{ marginTop: '20px', lineHeight: '1.75em', textIndent: '50px', alignSelf: 'start' }}> 3. Course Rigor</span>

      <span className="dca-overview-paragraph">Colleges will consider the difficulty of the applicant's courseload through high school. Schedule rigor is reflected in the aforementioned GPA and class rank metrics, as most schools will reward advanced (i.e. honors, AP, etc.) classes with higher GPA weights. In addition, colleges may view the individual courses taken by the student, and may compare against the course options available at the school (which are typically provided to the college as part of the school report provided by the high school counselor.)
      </span>

      <span className="dca-overview-paragraph">Course rigor will be evaluated via an official transcript and/or by courses (and grades) entered directly into the online application as illustrated in the Common App example below.
      </span>

      <img
        src={academic3}
        alt="overview_stock_image"
        className="d-flex align-self-center"
        style={{
          width: '100%',
          objectFit: 'fill',
          objectPosition: '50% 50%',
          borderRadius: '10px',
          margin: '4vh 0vh'
        }}
      ></img>

    </>
  )

  const testScores = (
    <>
      <span className="dca-overview-title">Standardized Test Scores</span>

      <span className="dca-overview-font-standard" style={{ marginTop: '5vh', lineHeight: '1.75em' }}>Among the key admission considerations, the standardized test scores criteria has had the most flux in the last few years. The Covid-19 pandemic catalyzed changes in colleges' test policy, with many moving away from the traditional test-required stance, and many schools still maintaining test-optional, or even test-blind, policies. While this shift does provide a measure of flexibility, test scores are still an important element of consideration for many colleges, as evidenced by most of the selective test-optional schools still having a majority of enrollees submitting a test score.</span>

      <span className="dca-overview-paragraph">Nearly all colleges will accept either the SAT or ACT (or both) for admission consideration. You will typically self-report your score(s) as part of the application (as shown in the Common App example below), and some schools will also require an official score report from the testing agency.</span>

      <img
        src={test1}
        alt="overview_stock_image"
        className="d-flex align-self-center"
        style={{
          width: '65%',
          objectFit: 'fill',
          objectPosition: '50% 50%',
          borderRadius: '10px',
          margin: '4vh 0vh'
        }}
      ></img>

      <span className="dca-overview-paragraph">This section also provides an opportunity to report other standardized scores. Any Advanced Placement (AP) exam scores you received through your junior year can be listed here, along with indication of AP tests planned for the spring of senior year. In addition, you may indicate scores for the following tests, if applicable: International Baccalaureate (IB), Cambridge, Test of English as a Foreign Language (TOEFL), PTE Academic, International English Language Testing System (IELTS), and Duolingo English Test.</span>


    </>
  )

  const ec = (
    <>
      <span className="dca-overview-title">Extracurricular Activities</span>

      <span className="dca-overview-font-standard" style={{ marginTop: '5vh', lineHeight: '1.75em' }}>We believe the activities section is an increasingly important aspect of the application. This section provides an opportunity for the student to indicate their interests and accomplishments beyond their school coursework. Examples of activities might include sports, clubs, community service, student government, arts, work experience, etc.
      </span>

      <span className="dca-overview-paragraph">The Common App allows for the student to list up to 10 activities. And for each activity, you may provide a concise description (limited to a 150 character description), an indication of time spent on the activity, and a few other attributes:
      </span>

      <img
        src={ec1}
        alt="overview_stock_image"
        className="d-flex align-self-center"
        style={{
          width: '70%',
          objectFit: 'cover',
          objectPosition: '50% 50%',
          borderRadius: '10px',
          margin: '4vh 0vh'
        }}
      ></img>

      <span className="dca-overview-paragraph">Note that other application platforms, including proprietary school-specific applications, may vary in terms of the number of activities and the level of detail permitted. For instance, the University of California application allows for 20 activities (combined with honors) and longer (350 word) descriptions each, while  the Georgetown University application allows for only 6 core activities and 90 characters across activity name and position title/role.
      </span>
    </>
  )

  const awards = (
    <>
      <span className="dca-overview-title">Honors and Awards</span>

      <span className="dca-overview-font-standard" style={{ marginTop: '5vh', lineHeight: '1.75em' }}>In addition to activities, the application includes a section for honors and awards which provides the applicant an opportunity to highlight academic accomplishments and distinctions. The Common App provides space for up to 5 honors, and provides an opportunity to indicate the corresponding grade level and recognition level:
      </span>

      <img
        src={awards1}
        alt="overview_stock_image"
        className="d-flex align-self-center"
        style={{
          //width: '70%',
          objectFit: 'cover',
          objectPosition: '50% 50%',
          borderRadius: '10px',
          margin: '4vh 0vh'
        }}
      ></img>

    </>
  )

  const letters = (
    <>
      <span className="dca-overview-title">Letters of Recommendation</span>

      <span className="dca-overview-font-standard" style={{ marginTop: '5vh', lineHeight: '1.75em' }}>Most colleges will request letters of recommendation as part of the application. These letters provide an opportunity for the student to select recommenders to support their application. There are typically three different types of recommenders:
      </span>

      <span className="dca-overview-ordered-item" style={{ marginTop: '40px' }}>1. Counselor recommendation. Typically the school counselor will be required to provide a letter of recommendation and complete a School Report.
      </span>

      <img
        src={letters1}
        alt="overview_stock_image"
        className="d-flex align-self-center"
        style={{
          //width: '70%',
          objectFit: 'cover',
          objectPosition: '50% 50%',
          borderRadius: '10px',
          margin: '4vh 0vh'
        }}
      ></img>

      <span className="dca-overview-ordered-item">2. Teacher recommendation(s).  Typically schools will request 1-2 recommendations from teachers.
      </span>

      <img
        src={letters2}
        alt="overview_stock_image"
        className="d-flex align-self-center"
        style={{
          //width: '70%',
          objectFit: 'cover',
          objectPosition: '50% 50%',
          borderRadius: '10px',
          margin: '4vh 0vh'
        }}
      ></img>

      <span className="dca-overview-ordered-item">3. A subset of colleges will give students the option to submit an additional recommendation from a non-teacher. When available, this option provides the student with a chance to request application support from someone who knows them well and could provide a supplemental perspective on the student. Common examples of non-teacher recommenders are coaches, employers, peers, clergy, family members, etc.
      </span>

      <img
        src={letters3}
        alt="overview_stock_image"
        className="d-flex align-self-center"
        style={{
          //width: '70%',
          objectFit: 'cover',
          objectPosition: '50% 50%',
          borderRadius: '10px',
          margin: '4vh 0vh'
        }}
      ></img>
    </>
  )

  const personalEssayTextList = [
    'Some students have a background, identity, interest, or talent that is so meaningful they believe their application would be incomplete without it. If this sounds like you, then please share your story.',
    'The lessons we take from obstacles we encounter can be fundamental to later success. Recount a time when you faced a challenge, setback, or failure. How did it affect you, and what did you learn from the experience?',
    'Reflect on a time when you questioned or challenged a belief or idea. What prompted your thinking? What was the outcome?',
    'Reflect on something that someone has done for you that has made you happy or thankful in a surprising way. How has this gratitude affected or motivated you?',
    'Discuss an accomplishment, event, or realization that sparked a period of personal growth and a new understanding of yourself or others.',
    'Describe a topic, idea, or concept you find so engaging that it makes you lose all track of time. Why does it captivate you? What or who do you turn to when you want to learn more?',
    `Share an essay on any topic of your choice. It can be one you've already written, one that responds to a different prompt, or one of your own design.`,
  ]

  const personalStatement = (
    <>
      <span className="dca-overview-title">Personal Statement and Supplemental Essays</span>

      <span className="dca-overview-font-standard" style={{ marginTop: '5vh', lineHeight: '1.75em' }}>The essay(s) are an integral part of the college application and provides students an opportunity to share more about themselves to the admissions committees. The number and nature of writing requirements will vary by college. Most commonly, colleges will require a personal essay (typically as part of the Common Application) and also some school-specific supplemental questions.
      </span>

      <span className="dca-overview-paragraph">The specific topics/prompts will vary by college, and may vary over time, but some examples are provided below to give a representative sense of essay topics. It can be helpful to preview these essay prompts (and especially the themes which are likely to be consistent) to develop a general sense of the nature of college application essays and help inform how your high school experiences and personal character will be showcased.</span>

      <span className="dca-overview-paragraph fw-bold">Personal Essay choices from the Common App 2023-2024 application cycle (650-word limit):</span>

      <ul className="dca-overview-paragraph">
        {personalEssayTextList.map((res, index) =>
          <li key={index}>{res}</li>)}
      </ul>

      <span className="dca-overview-paragraph fw-bold">Examples of supplemental school-specific essays from 2023-2024 application cycle, grouped by some common themes that appear across multiple colleges' prompts:</span>

      <ul className="dca-overview-paragraph">
        <li>Theme: Why this college?
          <ul>
            <li>Why are you interested in attending Columbia University? We encourage you to consider the aspect(s) that you find unique and compelling about Columbia. (Columbia University)</li>
            <li>What is your sense of Duke as a university and a community, and why do you consider it a good match for you? If there's something in particular about our offerings that attracts you, feel free to share that as well. (Duke University)</li>
            <li>Describe the unique qualities that attract you to the specific undergraduate College or School (including preferred admission and dual degree programs) to which you are applying at the University of Michigan. How would that curriculum support your interests? (University of Michigan)
            </li>
          </ul>
        </li>

        <li>Theme: Why this major?
          <ul>
            <li>Please explain why you wish to study in the academic areas you selected. (Rice University)</li>
            <li>What academic areas are you interested in exploring at Emory University and why? (Emory University)</li>
            <li>Most students choose their intended major or area of study based on a passion or inspiration that's developed over time - what passion or inspiration led you to choose this area of study? (Carnegie Mellon)</li>
          </ul>
        </li>

        <li>Theme: Extracurricular activities
          <ul>
            <li>What is your most meaningful extracurricular commitment, and what would you like us to know about it? (Brown University)</li>
            <li>Briefly discuss the significance to you of the school or summer activity in which you have been most involved. (Georgetown University)</li>
            <li>Vanderbilt offers a community where students find balance between their academic and social experiences. Please briefly elaborate on how one of your extracurricular activities or work experiences has influenced you. (Vanderbilt University)</li>
          </ul>
        </li>

        <li>Theme: Diversity, perspectives, and contributions to college
          <ul>
            <li>Harvard has long recognized the importance of enrolling a diverse student body. How will the life experiences that shape who you are today enable you to contribute to Harvard? (Harvard University)</li>
            <li>We believe a wide range of personal perspectives, beliefs, and lived experiences are essential to making Duke a vibrant and meaningful living and learning community. Feel free to share with us anything in this context that might help us better understand you and what you might bring to our community. (Duke University)</li>
            <li>Northwestern is a place where people with diverse backgrounds from all over the world can study, live, and talk with one another. This range of experiences and viewpoints immeasurably enriches learning. How might your individual background contribute to this diversity of perspectives in Northwestern's classrooms and around our campus? (Northwestern University)
            </li>
            <li>A hallmark of the Columbia experience is being able to learn and thrive in an equitable and inclusive community with a wide range of perspectives. Tell us about an aspect of your own perspective, viewpoint or lived experience that is important to you, and describe how it has shaped the way you would learn from and contribute to Columbia's diverse and collaborative community. (Columbia University)</li>
          </ul>
        </li>

        <li>Theme: Intellectual Experience/Idea
          <ul>
            <li>Briefly describe an intellectual experience that was important to you. (Harvard University)</li>
            <li>Tell us about an intellectual experience in the past two years that you found absolutely fascinating (Duke University)</li>
          </ul>
        </li>

        <li>Theme: Community
          <ul>
            <li>Emory University has a strong commitment to building community. Tell us about a community you have been part of where your participation helped to change or shape the community for the better. (Emory University)</li>
            <li>Reflect on your membership in a community to which you feel connected. Why is this community meaningful to you? You may define community however you like. (Yale University)</li>
            <li>Notre Dame fosters an undergraduate experience dedicated to the intellectual, moral, and spiritual development of each individual, characterized by a collective sense of care for every person. How do you foster service to others in your community?  (Notre Dame)</li>
          </ul>
        </li>

        <li>Theme: Media
          <ul>
            <li>List a selection of texts, resources and outlets that have contributed to your intellectual development outside of academic courses, including but not limited to books, journals, websites, podcasts, essays, plays, presentations, videos, museums and other content that you enjoy. (Columbia University)</li>
            <li>What song represents the soundtrack of your life at this moment? (Princeton University)</li>
          </ul>
        </li>

        <li>Assorted
          <ul>
            <li>Describe an example of your leadership experience in which you have positively influenced others, helped resolve disputes or contributed to group efforts over time. (University of California)</li>
            <li>In college/university, students are often challenged in ways that they could not predict or anticipate. It is important to us, therefore, to understand an applicant's ability to navigate through adversity. Please describe a barrier or obstacle you have faced and discuss the personal qualities, skills or insights you have developed as a result. (Columbia University)
            </li>
            <li>Write a short thank-you note to someone you have not yet thanked and would like to acknowledge. (We encourage you to share this note with that person, if possible, and reflect on the experience!)  (University of Pennsylvania)</li>
            <li>Tell us about an aspect of your identity (e.g. race, gender, sexuality, religion, community, etc.) or a life experience that has shaped you as an individual and how that influenced what you'd like to pursue in college at Hopkins. (This can be a future goal or experience that is either academic, extracurricular, or social). (John Hopkins University)</li>
            <li>Virtually all of Stanford's undergraduates live on campus. Write a note to your future roommate that reveals something about you or that will help your roommate - and us - get to know you better. (Stanford University)</li>
            <li>“Be yourself,” Oscar Wilde advised. “Everyone else is taken.” Introduce yourself. (Dartmouth University)</li>
            <li>There are unwritten rules that everyone follows or has heard at least once in their life. But of course, some rules should be broken or updated. What is an unwritten rule that you wish didn't exist? (University of Chicago)</li>
          </ul>
        </li>
      </ul>



      {/* <ul className="dca-overview-paragraph">
        {supplementalTextList.map((res, index) =>
          <li key={index}>{res}</li>)}
      </ul> */}

    </>
  )

  const study = (
    <>
      <span className="dca-overview-title">Intended Field of Study</span>

      <span className="dca-overview-font-standard" style={{ marginTop: '5vh', lineHeight: '1.75em' }}>Colleges will typically ask for the student to indicate their intended areas of study in college, which is effectively asking what the student plans to pursue for their college major(s). The number of requested preferences will vary by college, but it is typically two or three, and the student should enter these in order of interest level. The choices available for selection in the application will be aligned with the majors available at that institution. In some cases, where a student is required to select a specific school to apply to within the university (i.e. School of Engineering vs School of Arts & Sciences), the options for areas of study will be specific to that school.</span>

      <span className="dca-overview-paragraph">Importantly, the most common case is that these intended areas of study are exactly that: intentions but not commitments. Typically students will enter their college officially as undeclared majors and will proceed to select their actual major(s) during their first two years. The exception is some schools may have unique constraints for over-subscribed majors (i.e. computer science) or specialized majors (i.e. nursing, music), requiring application to those specific programs at the time of application and/or specific policies relating to transferring across majors or schools within a college, so we recommend confirming any applicable restrictions for your target colleges.
      </span>

      <img
        src={study1}
        alt="overview_stock_image"
        className="d-flex align-self-center"
        style={{
          //width: '65%',
          objectFit: 'cover',
          objectPosition: '50% 50%',
          borderRadius: '10px',
          margin: '4vh 0vh'
        }}
      ></img>
    </>
  )

  const returnContent = (hashPath) => {
    switch (true) {
      case hashPath === '':
        return base
      case hashPath === 'academics':
        return academics
      case hashPath === 'standardized-test-scores':
        return testScores
      case hashPath === 'extracurricular-activities':
        return ec
      case hashPath === 'honors-and-awards':
        return awards
      case hashPath === 'letters-of-recommendation':
        return letters
      case hashPath === 'personal-statement-and-supplemental-essays':
        return personalStatement
      case hashPath === 'intended-field-of-study':
        return study
      default:
        return base
    }
  }

  return (
    <>
      <div className='dca-header-menu-toggle d-flex d-lg-none align-items-center me-n2' title='Show header menu' onClick={() => { handleClickOffcanvas() }}>
        <button
          className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
          id='kt_header_menu_mobile_toggle'
        >
          <KTIcon iconName='text-align-left' className='fs-1' />
        </button>
      </div>

      <div className='d-flex flex-row'>

        <div className='dca-overview-vertical-tabs'>{verticalTabs}</div>

        <div className='dca-overview-sidebar'>{sidebar}</div>

        <div className='d-flex flex-column align-items-center dca-overview-main-content'>

          <div className="dca-overview-breadcrumb align-self-start">{breadcrumbComponent}</div>

          {returnContent(hashPath)}

          {pagination}
        </div>
      </div>
    </>
  )
}

export { CollegeAppWalkthrough }
