import LoadingIndicatorGIF from '../../custom_assets/dca-loading-indicator.gif'

export const LoadingIndicator = ({ text }) => {
  return <div className='d-flex flex-column justify-content-center'>
    <img src={LoadingIndicatorGIF} alt='LoadingIndicator' className="dca-loading-indicator-image" />
    <div
      style={{
        textAlign: 'center',
        marginTop: '40px'
      }}>{text}</div>
  </div>
}