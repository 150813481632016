import * as dictionary from '../../dictionary'
import { zipcodeMap } from '../../zipcodeMap'

const Logistics = ({ ec }) => {

  const formatFieldReturn = (val) => {
    if (val && val.length > 1) {
      let ec: any = []
      val.map((act, index) => {
        if (index === val.length - 1) {
          ec.push(dictionary.activityFormat[act])
        }
        else {
          ec.push(`${dictionary.activityFormat[act]}, `)
        }
      })
      return ec
    }
    else if (val) {
      return dictionary.activityFormat[val[0]]
    }
    else {
      return '-'
    }
  }

  const costReturn = (ec) => {
    if (ec && ec.specificCost !== null) {
      return `$${dictionary.numberConverter(ec.specificCost)}`
    }

    else if (ec && ec.minCost && ec.maxCost) {
      return `$${dictionary.numberConverter(ec.minCost)} - $${dictionary.numberConverter(ec.maxCost)}`
    }

    else {
      return '-'
    }
  }

  const locationReturn = (zipcodes) => {

    if (Array.isArray(zipcodes) && zipcodes.length > 0) {

      let res: any = []

      zipcodes.forEach((zipcode) => {

        const zipcodeFound = zipcodeMap.find((item) => item.zipcode == zipcode)

        if (zipcodeFound) {
          res.push(`${zipcodeFound.cityState}`)
        }

        else {
          res.push(zipcode)
        }
      })
      return res
    }

    else {
      return '-'
    }
  }

  const dateReturn = (dates) => {
    if (Array.isArray(dates) && dates.length > 0) {
      return dates.map((res, index) => <li key={index}>{dictionary.convertDateFormat(res.startDate)} - {dictionary.convertDateFormat(res.endDate)}</li>)
    }

    else {
      return '-'
    }
  }

  const inPersonFormatReturn = (inPersonFormat) => {

    if (inPersonFormat && inPersonFormat.length > 1) {
      let res: any = []

      inPersonFormat.map((format, index) => {
        if (index === 0) {
          if (format === 1) {
            res.push(`Residential`)
          }

          else if (format === 2) {
            res.push(`Commuting`)
          }
        }

        else {
          if (format === 1) {
            res.push(` Residential`)
          }

          else if (format === 2) {
            res.push(` Commuting`)
          }
        }
      }
      )

      return `${res}`
    }

    else if (inPersonFormat && inPersonFormat.length == 1) {

      if (inPersonFormat[0] === 1) {
        return `Residential`
      }

      else if (inPersonFormat[0] === 2) {
        return `Commuting`
      }
    }

    else {
      return '-'
    }
  }

  return (
    <div className='card shadow fw-bold justify-content-between' style={{ marginTop: '40px' }}>

      <div className='d-flex flex-row' style={{ marginTop: '30px', padding: '20px 40px 40px 40px' }}>

        <div style={{ width: '45%' }}>
          <div>
            <div>ACTIVITY FORMAT(S)</div>
            <div>{formatFieldReturn(ec.activityFormat)}</div>
          </div>

          <div style={{ marginTop: '25px' }}>
            <div>LOCATION(S)</div>
            <div>{dictionary.returnSemiColonSeparatedFromArray(locationReturn(ec.locations))}</div>
          </div>

          <div style={{ marginTop: '25px' }}>
            <div>DATES</div>
            <ul>{dateReturn(ec.ec_activity_session_dates)}</ul>
          </div>

          <div style={{ marginTop: '25px' }}>
            <div>COST</div>
            <div>{costReturn(ec)}</div>
          </div>
        </div>

        <div style={{ width: '45%', marginLeft: '20px' }}>
          <div>
            <div>IN-PERSON FORMAT</div>
            <div>{inPersonFormatReturn(ec.inPersonFormat)}</div>
          </div>

          <div style={{ marginTop: '25px' }}>
            <div>DURATION</div>
            <div>{ec.duration ? `${ec.duration} Weeks` : '-'}</div>
          </div>

          <div style={{ marginTop: '25px' }}>
            <div>STIPEND</div>
            <div>{ec.stipendAmt ? ec.stipendAmt : '-'}</div>
          </div>
        </div>


      </div>
    </div>
  )
}

export { Logistics }