const SubmitACT = ({ userSATScoreOutput, userACTScoreOutput, collegeName, satSuperScore, actComposite, testPolicy, collegeCDS }) => {

   ////////////////
  // EDIT TEXT //
  //////////////

  //// TEST POLICY 1 ////
  // satScoreOutput = -1
  const caseA1 = <div>{collegeName} requires a test score from all applicants to be considered for admission. You have indicated you have an ACT score ({actComposite}), but not an SAT score, so therefore our recommendation is to submit your ACT score.</div>

  const noteA1 = <div>Note that your current ACT score is <span className='fw-bold'>below the median score</span> for {collegeName} and because a score is required, we recommend trying to improve your ACT score and/or explore the SAT.</div>

  //satScoreOutput = -2
  const caseB1 = <div>{collegeName} requires a test score from all applicants to be considered for admission. You have indicated you have an ACT score ({actComposite}), and there is insufficient data published by {collegeName} on their SAT score benchmarks, so therefore our recommendation is to submit your ACT score.</div>

  // satScoreOutput > 0
  const caseC1 = <div>{collegeName} requires a test score from all applicants to be considered for admission. Your ACT score ({actComposite}) is stronger than your SAT score ({satSuperScore}), as compared to the respective benchmarks published by {collegeName}, therefore our recommendation is to submit your ACT score.</div>

  const noteC1 = <div>Note that your current SAT score is <span className='fw-bold'>below the median score</span> for {collegeName} and because a score is required, we recommend <span className='fw-bold'>trying to improve your SAT and/or ACT scores.</span></div>

  //// TEST POLICY 2 ////
  // satScoreOutput = -1
  const caseA2 = <div>{collegeName} is test-optional, but we recommend to <span className='fw-bold'>submit your ACT score ({actComposite})</span> with above-average confidence that it could strengthen your application. This recommendation is based on the strength of your ACT score as compared to the benchmark values for {collegeName}.</div>

  // satScoreOutput = -2
  const caseB2 = <div>{collegeName} is test-optional, but we recommend to <span className='fw-bold'>submit your ACT score ({actComposite})</span> with above-average confidence that it could strengthen your application. This recommendation is based on the strength of your ACT score, as compared to the benchmark values for {collegeName}. Note there is insufficient data published by {collegeName} on their SAT score benchmarks to evaluate the relative strength of your SAT score and so that has not been considered for this recommendation.</div>

  // satScoreOutput > 0
  const caseC2 = <div>{collegeName} is test-optional, but we recommend to <span className='fw-bold'>submit your ACT score ({actComposite})</span> with above-average confidence that it could strengthen your application. This recommendation is based on the strength of your ACT score, as compared to the benchmark values for {collegeName}. Our recommendation is further based on assessing the relative strength of your ACT score vs your SAT score ({satSuperScore}).</div>

  //// Case 14, Submit ACT score for test optional school when SAT and ACT are equally strong
  const case3 = <div>{collegeName} is test-optional, but we recommend to <span className='fw-bold'>submit your ACT score ({actComposite})</span> with above-average confidence that it could strengthen your application. This recommendation is based on the strength of your ACT score as compared to the benchmark values for {collegeName}. Note that we estimate your SAT score ({satSuperScore}) is equally strong as your ACT score and therefore you may wish to <span className='fw-bold'>consider submitting both scores</span>. Our primary recommendation gives slight preference to the ACT score because the ACT is more commonly submitted by recent enrollees at {collegeName}.</div>

  //////////////////////
  //  END EDIT TEXT  //
  ////////////////////

  let subcase = 'a1'
  let append = false
  let case3Check = false


  const determineSubCase1 = (userSATScoreOutput, userACTScoreOutput) => {
    switch (true) {
      case userSATScoreOutput == -1:
        subcase = 'a1'
        break;
      case userSATScoreOutput == -2:
        subcase = 'b1'
        break;
      case userSATScoreOutput > 0:
        subcase = 'c1'
        break;
      default:
        subcase = subcase
    }

    userACTScoreOutput < 40 ? append = true : append = false
  }

  const determineSubCase2 = (userSATScoreOutput) => {

    switch (true) {
      case userSATScoreOutput == -1:
        subcase = 'a2'
        break;
      case userSATScoreOutput == -2:
        subcase = 'b2'
        break;
      case userSATScoreOutput > 0:
        subcase = 'c2'
        break;
      default:
        subcase = subcase
    }
  }

  const checkCase = (userSATScoreOutput, userACTScoreOutput, testPolicy) => {
    if ((userSATScoreOutput == userACTScoreOutput) && (userSATScoreOutput >= 60)) {

      let submitSATPercent = collegeCDS[0].submitSATPercent
      let submitACTPercent = collegeCDS[0].submitACTPercent

      submitSATPercent < submitACTPercent ? case3Check = true : case3Check = false
    }

    else {
      testPolicy == 1 ? determineSubCase1(userSATScoreOutput, userACTScoreOutput) : determineSubCase2(userSATScoreOutput)
    }
  }

  checkCase(userSATScoreOutput, userACTScoreOutput, testPolicy)

  const a1False = (
    <div>{caseA1}</div>
  )

  const a1True = (
    <div className="d-flex flex-column">
      <div>{caseA1}</div>
      <div>{noteA1}</div>
    </div>
  )

  const b1 = (
    <div>{caseB1}</div>
  )

  const c1False = (
    <div>{caseC1}</div>
  )

  const c1True = (
    <div className="d-flex flex-column">
      <div>{caseC1}</div>
      <div>{noteC1}</div>
    </div>
  )

  const a2 = (
    <div>{caseA2}</div>
  )

  const b2 = (
    <div>{caseB2}</div>
  )

  const c2 = (
    <div>{caseC2}</div>
  )

  // Test Policy 2, satScoreOutput == actScoreOutput, satScoreOutput >= 60, submitSATPercent < submitACTPercent
  const c3 = (
    <div>{case3}</div>
  )

  const returnExplanationTestPolicy1 = (subcase) => {
    switch (subcase) {
      case 'a1':
        return append ? a1True : a1False
      case 'b1':
        return b1
      case 'c1':
        return append ? c1True : c1False
      default:
        return <div> - </div>
    }
  }

  const returnExplanationTestPolicy2 = (subcase) => {
    switch (subcase) {
      case 'a2':
        return a2
      case 'b2':
        return b2
      case 'c2':
        return c2
      default:
        return <div> - </div>
    }
  }

  return testPolicy == 1 ? (
    <div>
      {returnExplanationTestPolicy1(subcase)}
    </div>
  ) : (
    <div>
      {case3Check ? c3 : returnExplanationTestPolicy2(subcase)}
    </div>
  )
}


export { SubmitACT }
