import { useState, useEffect } from "react";
import Select from 'react-select'
import { useLocation } from "react-router";

const ECNameSearch = ({ setNameSelect, nameOptions }) => {

  const location = useLocation()
  const [selectedNames, setSelectedNames] = useState<any>([]);

  const handleNameSelectChange = (selectedNames) => {
    console.log(selectedNames)
    setSelectedNames(selectedNames);
    let nameArr: any = []
    selectedNames.map((name) =>
      nameArr.push(name.value)
    )
    setNameSelect(nameArr)
  };

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let namesParam = params.get('activityName');

    if (namesParam) {
      const names = namesParam.split(',').map(name => ({ value: name, label: name }));
      handleNameSelectChange(names)
    }

    else {
      setSelectedNames([])
    }

  }, [location.search, nameOptions]);


  return (
    <div className='d-flex flex-column'>
      <div className='dca-aside-header'>ACTIVITY NAME</div>
      <Select
        options={nameOptions}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 'transparent',
            backgroundColor: '#F9F9F9'
          })
        }}
        isMulti
        value={selectedNames}
        onChange={handleNameSelectChange}
      />
    </div>
  )
}

export { ECNameSearch }