import { useState, useEffect } from "react";
import Select from 'react-select'
import { useLocation } from "react-router";

const MajorNameSearch = ({ setNameSelect, nameOptions }) => {

  const location = useLocation()
  const [selectedNames, setSelectedNames] = useState<any>([]);

  const handleNameSelectChange = (selectedNames) => {
    setSelectedNames(selectedNames);
    // let nameArr: any = []
    // selectedNames.map((name) =>
    //   nameArr.push(name.label)
    // )

    setNameSelect(selectedNames)
  };

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let namesParam = params.get('majorName');
    let res: any[] = []

    // if (namesParam) {
    //   const names = namesParam.split(',').map(name => ({ value: name, label: name }));
    //   handleNameSelectChange(names)
    // }

    if (namesParam && nameOptions) {
      const names = namesParam.split(',')
      names.map((val) => {
        if (nameOptions.find((obj => obj.value === parseInt(val)))) {
          res.push(nameOptions.find((obj => obj.value === parseInt(val))))
        }
      })
      handleNameSelectChange(res)
    }

    else {
      setSelectedNames([])
    }

  }, [location.search, nameOptions]);

  return (
    <div className='d-flex flex-column'>
      <div className='dca-aside-header'>MAJOR NAME (EXACT)</div>
      <Select
        options={nameOptions}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 'transparent',
            backgroundColor: '#F9F9F9'
          })
        }}
        isMulti
        value={selectedNames}
        onChange={handleNameSelectChange}
      />
    </div>
  )
}

export { MajorNameSearch }
