import Select from 'react-select'
import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

const Name = ({ selectorNameOptions, setNameFilter, setNameParams }) => {

  const location = useLocation()
  const [selectedNames, setSelectedNames] = useState<any>([]);

  const handleNameSelectChange = (selectedNames) => {
    setSelectedNames(selectedNames);
    let nameArr: any[] = []
    selectedNames.map((college) =>
      nameArr.push(college.value)
    )
    setNameFilter(nameArr)
  };

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let namesParam = params.get('name');

    if (namesParam) {
      const names = namesParam.split(',').map(name => ({ value: name, label: name }));
      setSelectedNames(names);
      let nameArr: any[] = []
      names.map((college) =>
        nameArr.push(college.value)
      )
      setNameFilter(nameArr)
    }

    else {
      setSelectedNames([])
      setNameFilter([])
    }

  }, [location.search]);

  useEffect(() => {

    let params = new URLSearchParams(location.search);

    if (selectedNames.length > 0) {
      const names = selectedNames.map((name) => name.value).join(',')
      params.set('name', names)
    } else {
      params.delete('name')
    }

    setNameParams(`${params.toString()}`)
  }, [selectedNames])

  return (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
      <div className='dca-aside-header'>COLLEGE NAME</div>

      <Select
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 'transparent',
            backgroundColor: '#F9F9F9'
          })
        }}
        options={selectorNameOptions}
        isMulti
        value={selectedNames}
        onChange={handleNameSelectChange}
      />

    </div>
  )
}


export { Name }