const Contact = () => {

  return (
    <div className="d-flex flex-column" style={{ margin: '6vh 4vw 2vh 4vw' }}>
      <div className="dca-overview-title">Contact</div>

	<div className="dca-overview-paragraph">The team at NextFour can be best reached via email and will typically respond within 1-2 business days:</div>
   
      {/* Paragraph Content */}
      <div className="dca-overview-paragraph">- For help with your user account or accessing/using our services: <a href="mailto:support@nextfour.ai">support@nextfour.ai</a></div>
	  {/* Paragraph Content */}
      <div className="dca-overview-paragraph">- For sharing feedback on our services (all compliments, complaints, and desired features are welcome!): <a href="mailto:feedback@nextfour.ai">feedback@nextfour.ai</a></div>
	  {/* Paragraph Content */}
      <div className="dca-overview-paragraph">- For partnership and business development opportunities: <a href="mailto:partners@nextfour.ai">partners@nextfour.ai</a></div>	  
	  {/* Paragraph Content */}
      <div className="dca-overview-paragraph">- For press inquiries: <a href="mailto:press@nextfour.ai">press@nextfour.ai</a></div>	  
	  {/* Paragraph Content */}
	  <div className="dca-overview-paragraph">- For general inquiries and all other questions: <a href="mailto:nextfour.ai">info@nextfour.ai</a></div>	  
	{/* Paragraph Content */}
	  <div className="dca-overview-paragraph">We can also be reached by mail at 2120 Avy Avenue #7121, Menlo Park, CA 94025.</div>
	    {/* Paragraph Content */}
	  <div className="dca-overview-paragraph">We look forward to hearing from you!</div>
      {/*  */}
    </div>
  )
}

export { Contact }
