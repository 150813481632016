import { closeModalIcon } from "../components/dictionary"

const ScreenWidthAlertModal = () => {

  return (
    <div className="modal" id="screen_width_alert_modal">
      <div className="overlay" id="screen_width_alert_overlay"></div>
      <div className="modal-dialog modal-dialog-centered">
        <div
          className="modal-content"
          style={{
            minWidth: 'fit-content',
            maxWidth: '75vw',
            position: 'fixed', top: '28%', left: '50%', transform: 'translate(-50%, -50%)', overflowY: 'auto', maxHeight: '90vh'
          }}>
          <div style={{ margin: '8px' }}>
            <i
              className="bi bi-x-lg align-self-end"
              data-bs-dismiss="modal"
              onClick={() => {
                const modalElement = document.getElementById("screen_width_alert_modal");
                if (modalElement) {
                  modalElement.classList.remove('show');
                }
              }}
              style={{
                cursor: 'pointer',
                position: 'absolute',
                top: '20px',
                right: '20px',
                color: 'black',
                fontSize: '20px'
              }}></i>
          </div>

          <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
            This application is optimized for a wider screen; please view on a computer or tilt your mobile device for the best experience.
          </div>
        </div>
      </div>
    </div>
  )
}

export { ScreenWidthAlertModal }