import { LoadingSpinner } from "../../LoadingSpinner";
import Chart from 'react-apexcharts'
import { titleSpace, stateArray, returnValueInArrayOfObjects } from "../../dictionary";

const LocationOfStudents = ({ result }) => {

  //When result is not set, inform that it is loading
  if (!result || !result.insights || !result.insights['Location Region Data']) {
    return <LoadingSpinner title={'Location of Students'} />;
  }

  //Return functions for table
  const returnNumber = (num) => {

    if (!num) {
      return '-'
    }

    return num

  }
  const returnPercent = (res) => {

    if (!res) {
      return '-'
    }

    return `${((res) * 100).toFixed(1)}%`
  }

  const returnLocation = (id) => {
    if (!id) {
      return '-'
    }

    else if (id == '99' || id === 99) {
      return 'International'
    }

    return `${returnValueInArrayOfObjects(parseInt(id), stateArray)}`
  }

  const returnRegion = (regionID) => {
    if (!regionID) {
      return '-'
    }

    else {
      switch (true) {
        case regionID == "1":
          return 'New England'
        case regionID == '2':
          return 'Middle Atlantic'
        case regionID == '3':
          return 'Midwest (East)'
        case regionID == '4':
          return 'Midwest (West)'
        case regionID == '5':
          return 'South Atlantic'
        case regionID == '6':
          return 'Southeast'
        case regionID == '7':
          return 'Southwest'
        case regionID == '8':
          return 'Mountain West'
        case regionID == '9':
          return 'Pacific'
        case regionID == '10':
          return 'International'
      }
    }
  }

  let locationRegionData: any = result.insights['Location Region Data']
  let locationDetailsData: any = result.insights['Location Details Data']

  // Set treemap data
  let treemapData: any = []
  locationRegionData.map((res) => {

    // if (res.number !== null && res.number > 0) {
    //   treemapData.push({ x: returnLocation(res.regionID), y: res.number })
    // }

    if (res.number !== null && res.number > 0) {
      treemapData.push({ x: returnRegion(res.regionID), y: res.number })
    }

  })

  //If regionID does not have a match, remove from treemapData
  treemapData = treemapData.filter((item) => item.x !== 'null')

  //Sort Individual Major Data
  locationRegionData.sort((a, b) => b.number - a.number)

  return (

    <div style={{ marginTop: '40px' }}>
      <div className='card shadow'>
        <div className='fw-bold' style={{ fontSize: '26px', paddingTop: '40px', paddingLeft: '40px' }}>High School Location Of Activity Alumni - Summary By Region</div>
        <div style={{ marginLeft: '40px' }}>
          <Chart
            options={{
              chart: {
                id: 'Location Region Data Treemap'
              },
              colors: ["#97E6FF"],
              plotOptions: {
                treemap: {
                  enableShades: true,
                  shadeIntensity: 0.25,
                  dataLabels: {
                    format: "truncate"
                  },
                }
              },
              dataLabels: {
                enabled: true,
                style: {
                  colors: ['#000000'],
                },
                background: {
                  borderWidth: 0,
                  foreColor: '#000',
                },
              },


            }}
            series={[
              {
                name: 'Test',
                data: treemapData
              }
            ]}
            type='treemap'
            height={600}
            width={1000}
          />
        </div>
      </div>

      <div className='card shadow' style={{ marginTop: '40px' }}>
        <div className='fw-bold' style={{ fontSize: '26px', paddingTop: '40px', paddingLeft: '40px' }}>High School Location Of Activity Alumni - Breakdown By State</div>
        <div>
          <table className="table table-borderless table-sm table-responsive" style={{ width: '65%', marginLeft: '40px', marginBottom: '20px', marginTop: '30px', borderSpacing: '0 15px', borderCollapse: 'separate' }}>
            <thead>
              <tr className="fw-bold">
                <th scope="col" style={{ letterSpacing: titleSpace }}>STATE</th>
                <th scope="col" style={{ letterSpacing: titleSpace }}># STUDENTS</th>
                <th scope="col" style={{ letterSpacing: titleSpace }}>% STUDENTS</th>
              </tr>
            </thead>
            <tbody>

              {locationDetailsData.map((res, index) => (
                <tr key={index} style={{ borderBottom: '1px solid rgba(207,207,207,1)' }}>
                  <th scope='row' className="text-start fw-bold">{returnLocation(res.locationID)}</th>
                  <td className="fw-bold">{returnNumber(res.number)}</td>
                  <td className="fw-bold">{returnPercent(res.percent)}</td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>
      </div>
    </div>
  )

}

export { LocationOfStudents }
