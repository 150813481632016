import axios from 'axios'
import { getAuth } from './AuthHelpers'
import { FRONTEND_URL } from '../../../components/dictionary'

const API_URL = process.env.REACT_APP_COLLEGE_API

export const REGISTER_URL = `${API_URL}/dj-rest-auth/registration/`
export const CREATE_USER_PROFILE = `${API_URL}/user_profiles/create/`
export const LOGIN_URL = `${API_URL}/login/`
export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/user/`
export const REQUEST_PASSWORD_URL = `${API_URL}/dj-rest-auth/password/reset/`
export const PASSWORD_CHANGE = `${API_URL}/dj-rest-auth/password/change/`
export const LOGOUT_URL = `${API_URL}/logout/`
export const API_TOKEN = getAuth()

export const login = async (email: string, password: string) => {
  return await axios.post(LOGIN_URL, {
    "email": email,
    "password": password,
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const registerUser = async (email: string,
  password1: string,
  password2: string) => {
  await axios.post(REGISTER_URL, {
    "email": email,
    "password1": password1,
    "password2": password2
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  console.log(`User registered to Django Users`)
}

export const getUserByToken = async (token: string) => {
  return await axios.get(GET_USER_BY_ACCESSTOKEN_URL, {
    headers: {
      'Authorization': `Token ${token}`,
      'Content-Type': 'application/json',
    },
  })
}

export const createNewUserProfile = async (user_id: number, firstName: string, lastName: string, email: string) => {
  await axios.post(CREATE_USER_PROFILE, {
    "user_id": user_id,
    "firstName": firstName,
    "lastName": lastName,
    "emailAddress": email,
    "homeLink": `${FRONTEND_URL}/home`
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  console.log(`User Profile for ${firstName} ${lastName} created`)
}

export const getUserProfile = async (user_id: number, token: string) => {
  return await axios.get(`${API_URL}/user_profiles/${user_id}`, {
    headers: {
      'Authorization': `Token ${token}`,
      'Content-Type': 'application/json',
    },
  })
}

export function register(
  firstName: string,
  lastName: string,
  email: string,
  password1: string,
  password2: string
) {
  try {
    // createNewUserProfile(firstName, lastName, email)

    return registerUser(email, password1, password2)
  }

  catch (error) {
    console.log(error, 'Error with registration, please try again')
    throw (error)
  }
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean }>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export const updatePassword = async (newPass1: string, newPass2: string, oldPass: string) => {
  return await axios.post(PASSWORD_CHANGE, {
    "new_password1": newPass1,
    "new_password2": newPass2,
    "old_password": oldPass
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
}

export const logout = async () => {
  return await axios.post(LOGOUT_URL), {
    headers: {
      'Authorization': `Token ${API_TOKEN}`,
      'Content-Type': 'application/json',
    },
  }
}