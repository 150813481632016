import { useEffect } from "react"
import { useLocation } from "react-router"

const ECGradeSearch = ({ gradeSelect, setGradeSelect }) => {

  const location = useLocation()

  const gradeOptions = [
    { value: 0, label: 'Any' },
    { value: 1, label: '1st' },
    { value: 2, label: '2nd' },
    { value: 3, label: '3rd' },
    { value: 4, label: '4th' },
    { value: 5, label: '5th' },
    { value: 6, label: '6th' },
    { value: 7, label: '7th' },
    { value: 8, label: '8th' },
    { value: 9, label: '9th/Rising Sophmore' },
    { value: 10, label: '10th/Rising Junior' },
    { value: 11, label: '11th/Rising Senior' },
    { value: 12, label: '12th' },
  ]

  const handleGradeSelectChange = (event) => {
    let res = event.target.value
    setGradeSelect(parseInt(res))
  }

  useEffect(() => {

    const params = new URLSearchParams(location.search);
    const gradeParam = params.get('grade');

    if (gradeParam) {
      setGradeSelect(parseInt(gradeParam));
    }

    else {
      setGradeSelect(0)
    }
  }, [location.search]);

  return (
    <div className='mt-4 d-flex flex-column'>
      <div className='dca-aside-header'>CURRENT GRADE</div>
      <select
        className='form-select form-select-solid'
        data-control="select2"
        value={gradeSelect}
        onChange={handleGradeSelectChange}
        style={{ cursor: 'pointer' }}
      >
        {gradeOptions.map((res, index) => (
          <option
            key={index}
            value={res.value}>
            {res.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export { ECGradeSearch }