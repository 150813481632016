import { ActivityInformation } from './TabComponents/ActivityInformation'
import { Logistics } from './TabComponents/Logistics'
import { ApplicationParameters } from './TabComponents/ApplicationParameters'
// import { ApplicationInfo } from './TabComponents/ApplicationInfo'

const ECDetailTabContent = ({ selectedTab, tabOptions, ec }) => {

  switch (selectedTab) {
    case tabOptions[0]:
      return <ActivityInformation ec={ec} />
    case tabOptions[1]:
      return <Logistics ec={ec} />
    case tabOptions[2]:
      return <ApplicationParameters ec={ec} />
    default:
      return <ActivityInformation ec={ec} />
  }
}

export { ECDetailTabContent }