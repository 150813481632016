import { useState, useEffect } from "react";
import { LoadingSpinner } from "../LoadingSpinner"
import { titleSpace, selectedTabGreen, pageRoutes, URL, separatorReturn } from "../dictionary"
import { useNavigate } from "react-router-dom";
import axios from "axios";

const ECActivitiesOfInterest = ({ user, ecActivities, setUser, userData, setUserData }) => {

  let navigate = useNavigate();
  const [saveError, setSaveError] = useState('')

  const cardHeaderStyle = { padding: '20px', marginLeft: '10px' }

  const returnECActivityName = (id) => {
    return ecActivities && id ? ecActivities.find((item) => item.id == id).name : '-'
  }

  const removeECActivityFromUserList = async (id) => {

    try {

      let activitiesList = [...userData.activitiesInterest]

      const index = activitiesList.indexOf(id)

      if (index > -1) {
        activitiesList.splice(index, 1)
      }

      let patchData = { activitiesInterest: activitiesList }

      const patch = await axios.patch(`${URL}/user_profiles/${userData.user_id}`, patchData)

      console.log('Patch successful', patch.data)

      const res = await axios.get(`${URL}/user_profiles/${userData.user_id}`)
      setUser(res.data)
      setUserData(res.data)
      console.log('Updated user:', res.data);

    } catch (error) {
      // console.log(error)
      // alert(`Cannot remove ${returnECActivityName(id)} at this time. Try again later.`)
      setSaveError(`Cannot remove ${returnECActivityName(id)} at this time. Try again later.`)
      // throw (error);
    }


  }

  return userData ? (

    <div className="dca-userProfile-form-card">
      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="dca-userProfile-form-title">EC Activities of Interest</div>
        <button
          type="button"
          style={{ marginRight: '25px' }}
          className='dca-userProfile-save-changes-button'
          onClick={() => navigate(pageRoutes.ecSearch)}
        >
          Add Activities
        </button>
      </div>
      <div className="separator"></div>

      <div className="d-flex justify-content-center">
        {saveError != '' && <span style={{ margin: '15px 15px', color: 'red' }}>{saveError}</span>}
      </div>

      <div className="d-flex flex-wrap">
        {userData.activitiesInterest && userData.activitiesInterest.length > 0 ? userData.activitiesInterest.map((res, index) =>
          <div
            key={index}
            style={{ width: '50%' }}>
            <div className="dca-userProfile-interest-field">
              <div>{returnECActivityName(res)}</div>

              <i className="bi bi-x align-self-center" style={{ marginLeft: '10px', cursor: 'pointer' }}
                onClick={() => {
                  removeECActivityFromUserList(res)
                }}></i>
            </div>
            <div className={separatorReturn(index, userData.activitiesInterest)} style={{ width: '95%' }}></div>
          </div>
        ) : <div></div>}
      </div>

    </div>
  ) : <LoadingSpinner title={'EC Activities of Interest'} />
}

export { ECActivitiesOfInterest }