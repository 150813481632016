import { closeModalIcon, closeModalFunction, pageRoutes, selectedTabGreen, specializedRankingsPriceID, rdSpendPriceID, safetyPriceID, returnECPremiumPriceID, reactGACheckoutModalController, advisorConnectAccessCode, returnAdvisorPriceID, stripePromiseKey } from "../dictionary"
import { getAuth, useAuth } from '../../modules/auth'
import { handleCreateOrAssignGroups } from "../../pages/Auth/UpgradeTierPage"
import { handleUpdateUserTransactions } from "../../pages/Auth/UpdateUserTransaction"
import { specializedRankingsAccessCode, safetyAccessCode, rdSpendAccessCode, ecPremiumAccessCode } from "../dictionary"
import { useNavigate } from "react-router"

import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { AdvisorsReactGAHandlers, CollegeReactGAHandlers, ECReactGAHandlers } from "../ReactGAHandlers"
import { LoadingIndicator } from "../LoadingIndicator"

// const stripePromise = loadStripe("pk_test_51OaR0tF7MQ6RjhDC0nWRzQPB3GvQzNDyAEu4Y9Vx2S33Xr9nlfwUZfUkMoYkFA1awRmoxWPBIvtPqWhXTtAObVuh00wMn17srK");

const stripePromise = loadStripe(stripePromiseKey)

let apiToken: any = undefined

if (getAuth()?.api_token && getAuth()?.groups) {
  apiToken = getAuth()?.api_token
}

const AddCardModal = ({ upgradeUserGroupAccessCode, setSelectedTab, clientSecret, setClientSecret, ecID, ecName, showNavAfterPayment, setShowNavAfterPayment, advisorID }) => {

  const { handleGAEventPurchaseComplete } = CollegeReactGAHandlers()
  const { handleECGAEventPurchaseComplete } = ECReactGAHandlers()
  const { handleAdvisorGAEventPurchaseComplete } = AdvisorsReactGAHandlers()

  const { requestUser } = useAuth()
  let navigate = useNavigate();
  const routeChange = (id) => {
    navigate(pageRoutes.ecPremiumInsightsWithId(id))
  }

  const submitButton = (
    <button
      type="submit" id="kt_modal_new_card_submit" className="btn btn-primary"
      data-bs-dismiss="modal"
      onClick={async () => {
        try {

          if (getAuth()?.api_token && getAuth()?.groups) {
            apiToken = getAuth()?.api_token
          }

          await handleCreateOrAssignGroups(apiToken, upgradeUserGroupAccessCode)

          await requestUser(apiToken)

          closeModalFunction("add_payment_method")

          switch (true) {
            case upgradeUserGroupAccessCode === specializedRankingsAccessCode:
              setSelectedTab('Specialized Rankings')
              break;
            case upgradeUserGroupAccessCode === rdSpendAccessCode:
              setSelectedTab('R&D Spend')
              break;
            case upgradeUserGroupAccessCode === safetyAccessCode:
              setSelectedTab('Crime & Safety');
              break
          }


        } catch (error) {

          console.log(error)
          throw (error)

        }
      }}>
      <span className="indicator-label">
        Submit
      </span>
      <span className="indicator-progress">
        Please wait... <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
      </span>
    </button>
  )

  return (
    <div className="modal fade" id="add_payment_method_modal">
      <div className="modal-dialog modal-dialog-centered">
        <div
          className="modal-content"
          style={{ minWidth: 'fit-content', maxWidth: '75vw', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', overflowY: 'auto', maxHeight: '90vh' }}>
          <div style={{ margin: '8px' }}>
            {closeModalIcon('add_payment_method', reactGACheckoutModalController(upgradeUserGroupAccessCode))}
          </div>

          <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">

            {/* <CheckoutForm /> */}
            <div id="checkout">
              {clientSecret ? (
                <EmbeddedCheckoutProvider
                  stripe={stripePromise}
                  options={{
                    clientSecret, onComplete() {

                      const runSubmit = async () => {
                        try {

                          if (getAuth()?.api_token && getAuth()?.groups) {
                            apiToken = getAuth()?.api_token
                          }

                          await handleCreateOrAssignGroups(apiToken, upgradeUserGroupAccessCode)

                          // Update UserTransactions table, pass product/priceID and expiry
                          switch (true) {
                            case upgradeUserGroupAccessCode === specializedRankingsAccessCode:
                              await handleUpdateUserTransactions(apiToken, specializedRankingsPriceID)
                              break;
                            case upgradeUserGroupAccessCode === rdSpendAccessCode:
                              await handleUpdateUserTransactions(apiToken, rdSpendPriceID)
                              break;
                            case upgradeUserGroupAccessCode === safetyAccessCode:
                              await handleUpdateUserTransactions(apiToken, safetyPriceID)
                              break
                            case upgradeUserGroupAccessCode.includes(ecPremiumAccessCode):
                              let ecPremiumPriceID = returnECPremiumPriceID(ecID)

                              if (typeof ecPremiumPriceID == 'string') {
                                await handleUpdateUserTransactions(apiToken, ecPremiumPriceID)
                              }

                              else {
                                console.log('Error updating user transaction')
                              }
                              break;
                            case upgradeUserGroupAccessCode.includes(advisorConnectAccessCode):
                              let advisorConnectPriceID = returnAdvisorPriceID(advisorID)

                              if (typeof advisorConnectPriceID == 'string') {
                                await handleUpdateUserTransactions(apiToken, advisorConnectPriceID)
                              }

                              else {
                                console.log('Error updating user transaction')
                              }
                          }

                          await requestUser(apiToken)

                          // await setClientSecret('')

                          // closeModalFunction("add_payment_method")

                          switch (true) {
                            case upgradeUserGroupAccessCode === specializedRankingsAccessCode:
                              setSelectedTab('Specialized Rankings');
                              handleGAEventPurchaseComplete('Specialized Rankings')
                              break;
                            case upgradeUserGroupAccessCode === rdSpendAccessCode:
                              setSelectedTab('R&D Spend');
                              handleGAEventPurchaseComplete('R&D Spend')
                              break;
                            case upgradeUserGroupAccessCode === safetyAccessCode:
                              setSelectedTab('Crime & Safety');
                              handleGAEventPurchaseComplete('Crime & Safety')
                              break
                            case upgradeUserGroupAccessCode.includes(ecPremiumAccessCode):
                              setShowNavAfterPayment(true);
                              handleGAEventPurchaseComplete(ecID)
                              break;
                            case upgradeUserGroupAccessCode.includes(advisorConnectAccessCode):
                              setShowNavAfterPayment(true);
                              handleAdvisorGAEventPurchaseComplete(advisorID)
                          }

                        } catch (error) {

                          console.log(error)
                          throw (error)

                        }
                      }

                      setTimeout(() => {
                        runSubmit()
                      }, 2000)

                    },
                  }}
                >
                  <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
              ) : <div className="d-flex flex-column justify-content-center align-items-center" >
                <LoadingIndicator text={''} />
              </div>}

              {showNavAfterPayment === true && upgradeUserGroupAccessCode.includes(ecPremiumAccessCode) ? <div className="d-flex justify-content-center align-items-center" style={{ marginTop: '30px' }}><a
                role="button"
                className="btn btn-sm fw-bold align-self-center"
                style={{ background: selectedTabGreen }}
                onClick={async () => {
                  await closeModalFunction("add_payment_method")
                  routeChange(ecID)
                }}
              >View Premium Data</a></div> : <div></div>}

              {showNavAfterPayment === true && upgradeUserGroupAccessCode.includes(advisorConnectAccessCode) ? <div className="d-flex justify-content-center align-items-center" style={{ marginTop: '30px' }}>Your purchase transaction for this Advisors consultation is completed. The next step is you will receive an email from NextFour with details for scheduling your meeting. If you have any questions, please contact us at advisors@nextfour.ai.</div> : <div></div>}

            </div>
          </div>
        </div>
      </div>
    </div>


  )
}

export { AddCardModal }






