import { AdmissionCorrelation } from './TabContent/AdmissionCorrelation'
import { CollegeMatriculation } from './TabContent/CollegeMatriculation'
import { DistributionByGrade } from './TabContent/DistributionByGrade'
import { FieldOfStudySelection } from './TabContent/FieldOfStudySelection'
import { InsideScoop } from './TabContent/InsideScoop'
import { LocationOfStudents } from './TabContent/LocationOfStudents'

const TabContent = ({ selectedTab, tabOptions, result, colleges, cip, advisorECReports }) => {


  const returnCollegeGroupID = (id) => {
    switch (id) {
      case "1":
        return 'HYPSM'
      case "2":
        return 'Ivy League'
      case "3":
        return 'Top 10 (USNWR)'
      case "4":
        return 'Top 20 (USNWR)'
      case "5":
        return 'Top 35 (USNWR)'
      case "6":
        return 'Top 50 (USNWR)'
      case "7":
        return 'Top 100 (USNWR)'
      case "8":
        return 'Top 10 (Metarank)'
      case "9":
        return 'Top 20 (Metarank)'
      case "10":
        return 'Top 35 (Metarank)'
      case "11":
        return 'Top 50 (Metarank)'
      case "12":
        return 'Top 100 (Metarank)'
      case "13":
        return 'Other'
      // case "14": 
      //   return 'TBD'
      // case "15": 
      //   return 'TBD'
      default:
        return '-'
    }
  }

  const returnMajorAreaByID = (id) => {
    switch (id) {
      case "1":
        return "Computer & Information Sciences"
      case "2":
        return "Physical & Natural Sciences"
      case "3":
        return "Arts"
      case "4":
        return "Humanities"
      case "5":
        return "Engineering"
      case "6":
        return "Mathematics & Statistics"
      case "7":
        return "Social Sciences"
      case "8":
        return "Health & Medicine"
      case "9":
        return "Business"
      case "10":
        return "Education"
      case "11":
        return "Other"
      default:
        return '-'
    }
  }

  const returnAgeGroupByID = (id) => {
    switch (id) {
      case "1":
        return "Before High School"
      case "2":
        return "Freshman/Rising Sophomore"
      case "3":
        return "Sophomore/Rising Junior"
      case "4":
        return "Junior/Rising Senior"
      case "5":
        return "Senior"
      case "6":
        return "Other"
      default:
        return '-'
    }
  }

  switch (selectedTab) {
    case tabOptions[0]:
      return <AdmissionCorrelation
        result={result}
        returnCollegeGroupID={returnCollegeGroupID} />
    case tabOptions[1]:
      return <CollegeMatriculation
        result={result}
        colleges={colleges} />
    case tabOptions[2]:
      return <FieldOfStudySelection
        result={result}
        cip={cip}
        returnMajorAreaByID={returnMajorAreaByID} />
    case tabOptions[3]:
      return <LocationOfStudents
        result={result} />
    case tabOptions[4]:
      return <DistributionByGrade
        result={result}
        returnAgeGroupByID={returnAgeGroupByID} />
    case tabOptions[5]:
      return <InsideScoop advisorECReports={advisorECReports} />
    default:
      return <div>content</div>
  }

}

export { TabContent }