import React from "react";

export { };

export const returnParameterString = (fullParamString, paramKey) => {
  let fullParamText = fullParamString.split('&')
  let res = ''

  for (let subString of fullParamText) {
    if (subString.startsWith(`${paramKey}=`)) {
      res = subString
      break;
    }
  }

  return res
}

export const concatLocation = (params: string[]) => {
  let res = ''

  const appendToRes = (res: string, paramToAdd: string) => {
    if (res !== '') {
      res = res + `&` + paramToAdd
    }
    else {
      res = paramToAdd
    }

    return res
  }

  params.map((param) => {
    if (param !== '') {
      res = appendToRes(res, param)
    }
  })

  return `?${res}`
}

