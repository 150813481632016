import { useEffect } from "react"
import { useLocation } from "react-router"

const ECOnlyPremiums = ({ onlyPremiumsSelect, setOnlyPremiumsSelect }) => {

  const location = useLocation()

  const handleCheckChange = (event) => {
    let checkStatus = event.target.checked
    return checkStatus
  }

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let onlyPremiumsParam = params.get('onlyPremiums');

    if (onlyPremiumsParam) {
      onlyPremiumsParam == 'true' ? setOnlyPremiumsSelect(true) : setOnlyPremiumsSelect(false)
    }

  }, [location.search]);

  return (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
      <div className='dca-aside-header'>PREMIUM INSIGHTS</div>
      <div className='d-flex flex-column'>
        <div className="checkbox-list d-flex flex-column" style={{ marginTop: '5px' }}>

          <label className="d-flex justify-content-between" style={{ cursor: 'pointer', width: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <input
                type="checkbox"
                className='dca-checkbox'
                name="Show Premiums"
                checked={onlyPremiumsSelect}
                onChange={(event) => (setOnlyPremiumsSelect(handleCheckChange(event)))}
                style={{ cursor: 'pointer' }}
              />
              <span
                className='ms-2 me-2'
                style={{
                  display: 'block',
                  maxWidth: '100%',
                  wordWrap: 'break-word',
                  whiteSpace: 'normal'
                }}
              >
                Show Only Activities With Premium Data Available
              </span>
            </div>
          </label>

        </div>
      </div>
    </div>

  )
}

export { ECOnlyPremiums }