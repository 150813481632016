import { useState, useEffect } from 'react'
import { OverlayTabs } from './OverlayTabs'
import { AggregateRecommendation } from './AggregateRecommendation'
import { MissingUserData } from './ExplanationCases/MissingUserData'
import { InsufficientCollegeData } from './ExplanationCases/InsufficientCollegeData'
import { DoNotSubmit } from './ExplanationCases/DoNotSubmit'
import { SubmitSAT } from './ExplanationCases/SubmitSAT'
import { SubmitACT } from './ExplanationCases/SubmitACT'
import { Borderline } from './ExplanationCases/Borderline'
import { SubmitBoth } from './ExplanationCases/SubmitBoth'
import { closeModalIcon, collegeLogos } from '../dictionary'

const DetailsOverlay = ({ modalData, returnUserSATScoreOutput, returnUserACTScoreOutput, userSATVerbal, userSATMath, userSATSuperScore, userActEnglish, userActMath, userActWriting, userActScience, userActReading, userACTBestScore, setUserSATVerbal, setUserSATMath, setUserSATSuperScore, setUserACTBestScore, setUserActEnglish, setUserActMath, setUserActWriting, setUserActScience, setUserActReading, setUserprofile }) => {

  let userSATScoreOutput = returnUserSATScoreOutput(userSATMath, userSATVerbal, modalData)

  let userACTScoreOutput = returnUserACTScoreOutput(userACTBestScore, modalData)

  let caseB = false

  const returnOptionBSATComposite25 = (cds) => {
    if (cds.satEvidence25 && cds.satMath25) {
      return cds.satEvidence25 + cds.satMath25
    }

    else {
      return null
    }
  }

  const returnOptionBSATComposite75 = (cds) => {
    if (cds.Evidence75 + cds.satMath75) {
      return cds.Evidence75 + cds.satMath75
    }

    else {
      return null
    }
  }

  const returnOptionBSATComposite50 = (cds) => {

    if (cds.satEvidence50 && cds.satMath50) {
      return cds.satEvidence50 + cds.satMath50
    }

    else if (cds.satEvidence25 && cds.satEvidence75 && cds.satMath25 && cds.satMath75) {
      return ((cds.satEvidence25 + cds.satEvidence75) / 2) + ((cds.satMath25 + cds.satMath75) / 2)
    }

    else {
      return null
    }
  }

  if (modalData && typeof modalData === 'object' && modalData.college_cds) {

    const cds = modalData.college_cds[0]

    let satComposite50: any = null
    let satComposite25: any = null
    let satComposite75: any = null

    switch (true) {
      case cds.satComposite25 && cds.satComposite75:
        satComposite25 = cds.satComposite25
        satComposite75 = cds.satComposite75
        cds.satComposite50 ? satComposite50 = cds.satComposite50 : satComposite50 = ((satComposite25 + satComposite75) / 2)
        break;

      case !cds.satComposite25 && !cds.satComposite75:
        satComposite25 = returnOptionBSATComposite25(cds);
        satComposite75 = returnOptionBSATComposite75(cds);
        satComposite50 = returnOptionBSATComposite50(cds);
        caseB = true
        break;
    }

  }

  const returnRecommendationValue = (userSATScoreOutput, userACTScoreOutput, res) => {

    if (res.testPolicy === 3) {
      return 5
    }

    if (userSATScoreOutput === -1 && userACTScoreOutput === -1) {
      return 7
    }

    if (userSATScoreOutput === -2 && userACTScoreOutput === -2) {
      return 6
    }

    if (userSATScoreOutput === -1 && userACTScoreOutput === -2) {
      return 6
    }

    if (userSATScoreOutput === -2 && userACTScoreOutput === -1) {
      return 6
    }


    switch (res.testPolicy) {
      case 1:
        if (userSATScoreOutput > userACTScoreOutput) {
          return 2
        }
        else if (userSATScoreOutput < userACTScoreOutput) {
          return 3
        }
        else {
          return 1
        }
      case 2:
        if (userSATScoreOutput > userACTScoreOutput) {
          if (userSATScoreOutput >= 60) {
            return 2
          }
          else if ((40 <= userSATScoreOutput) && (userSATScoreOutput < 60)) {
            return 4
          }
          else if (userSATScoreOutput < 40) {
            return 5
          }
        }
        if (userSATScoreOutput < userACTScoreOutput) {
          if (userACTScoreOutput >= 60) {
            return 3
          }
          else if ((40 <= userACTScoreOutput) && (userACTScoreOutput < 60)) {
            return 4
          }
          else if (userACTScoreOutput < 40) {
            return 5
          }
        }
        if (userSATScoreOutput === userACTScoreOutput) {
          if (userSATScoreOutput >= 60) {
            if (res.college_cds[0].submitSATPercent >= res.college_cds[0].submitACTPercent) {
              return 2
            }
            else if (res.college_cds[0].submitSATPercent < res.college_cds[0].submitACTPercent) {
              return 3
            }
          }
          if (40 <= userSATScoreOutput && userSATScoreOutput < 60) {
            return 4
          }
          if (userSATScoreOutput < 40) {
            return 5
          }
        }
    }
  }

  let recommendation = returnRecommendationValue(userSATScoreOutput, userACTScoreOutput, modalData)

  const returnExplanation = (recommendation) => {
    switch (recommendation) {
      case 1:
        return <SubmitBoth
          modalData={modalData}
          userSATScoreOutput={userSATScoreOutput}
          userACTScoreOutput={userACTScoreOutput}
          collegeName={modalData.name}
          actComposite={userACTBestScore}
          satSuperScore={userSATSuperScore} />
      case 2:
        return <SubmitSAT
          userSATScoreOutput={userSATScoreOutput}
          userACTScoreOutput={userACTScoreOutput}
          collegeName={modalData.name}
          actComposite={userACTBestScore}
          satSuperScore={userSATSuperScore}
          testPolicy={modalData.testPolicy}
          collegeCDS={modalData.college_cds} />
      case 3:
        return <SubmitACT
          userSATScoreOutput={userSATScoreOutput}
          userACTScoreOutput={userACTScoreOutput}
          collegeName={modalData.name}
          actComposite={userACTBestScore}
          satSuperScore={userSATSuperScore}
          testPolicy={modalData.testPolicy}
          collegeCDS={modalData.college_cds} />
      case 4:
        return <Borderline
          userSATScoreOutput={userSATScoreOutput}
          userACTScoreOutput={userACTScoreOutput}
          collegeName={modalData.name}
          actComposite={userACTBestScore}
          satSuperScore={userSATSuperScore}
          testPolicy={modalData.testPolicy} />
      case 5:
        return <DoNotSubmit
          collegeName={modalData.name}
          userSATScoreOutput={userSATScoreOutput}
          userACTScoreOutput={userACTScoreOutput}
          actComposite={userACTBestScore}
          satSuperScore={userSATSuperScore}
          testPolicy={modalData.testPolicy} />
      case 6:
        return <InsufficientCollegeData
          userSATScoreOutput={userSATScoreOutput}
          userACTScoreOutput={userACTScoreOutput} collegeName={modalData.name} />
      default:
        return <MissingUserData />
    }
  }

  return (
    <div className="modal fade" id="overlay">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content" style={{ minWidth: '75vw', maxWidth: '75vw', minHeight: '85vh', maxHeight: '85vh', padding: '60px', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', overflowY: 'auto' }}>

          <div className="d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: '15px' }}>
            <img style={{ width: '94px', height: '94px' }} alt="college_logo" src={collegeLogos[modalData.id]} />
            <h3 className="mt-5 d-flex">{modalData.name}</h3>
            {closeModalIcon('overlay')}
            <AggregateRecommendation
              userSATVerbal={userSATVerbal}
              userSATMath={userSATMath}
              userSATSuperScore={userSATSuperScore}
              userActEnglish={userActEnglish}
              userActMath={userActMath}
              userActWriting={userActWriting}
              userActScience={userActScience}
              userActReading={userActReading}
              userACTBestScore={userACTBestScore}
              setUserSATVerbal={setUserSATVerbal}
              setUserSATMath={setUserSATMath}
              setUserACTBestScore={setUserACTBestScore}
              setUserActEnglish={setUserActEnglish}
              setUserActMath={setUserActMath}
              setUserActWriting={setUserActWriting}
              setUserActScience={setUserActScience}
              setUserActReading={setUserActReading}
              userSATScoreOutput={returnUserSATScoreOutput(userSATMath, userSATVerbal, modalData)}
              userACTScoreOutput={returnUserACTScoreOutput(userACTBestScore, modalData)}
              res={modalData}
              setUserprofile={setUserprofile}
              setUserSATSuperScore={setUserSATSuperScore} />
          </div>

          <div className='dca-testscore-overlay-explanation-title'>EXPLANATION OF THE RECOMMENDATION</div>

          {returnExplanation(recommendation)}

          {caseB && recommendation !== 6 && <span style={{ marginTop: '10px' }}>Note that because {modalData.name} does not publish SAT composite score benchmarks, the SAT score analysis/recommendation was based on the sum of the benchmarks published for the SAT Evidence/Reading and Math sections.</span>}

          <OverlayTabs modalData={modalData} userSATScoreOutput={userSATScoreOutput} userACTScoreOutput={userACTScoreOutput} />

        </div>
      </div>
    </div>
  )
}

export { DetailsOverlay }