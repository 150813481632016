import OverviewStockImage from '../../../custom_assets/helena-lopes-PGnqT0rXWLs-unsplash.jpg'
import { HeaderWrapper } from '../../../_metronic/layout/components/header/HeaderWrapper'
import { useParams, useLocation, useNavigate, Link } from "react-router-dom"
import IntroPhoto1 from '../../../custom_assets/Overview/Introduction/dca_intro_photo1v4.png'
import IntroPhoto2 from '../../../custom_assets/Overview/Introduction/dca_intro_photo2v4.png'
import { KTIcon } from '../../../_metronic/helpers'

const Introduction = ({ verticalTabs, overviewSidebarActive, sidebar, handleClickOffcanvas, pagination }) => {

  const navigate = useNavigate();

  const admissionsCriteriaList = [
    'Academics (GPA, course rigor, class rank)',
    'Standardized test scores',
    'Extracurricular activities, awards and honors',
    'Personal statement and supplemental essays',
    'Letters of recommendation',
    'Interview',
    'Intended field(s) of study',
  ]

  const breadcrumbs = [
    { title: 'Overview', path: `/introduction` }
  ]

  const breadcrumbComponent = (
    <ol className="breadcrumb text-muted fs-6 fw-bold align-self-start">
      {breadcrumbs.map((item, index) => (
        <li key={index} className="breadcrumb-item pe-3">
          {index < breadcrumbs.length - 1 ? (
            <a href="#" className="pe-3 dca-breadcrumb-link" onClick={() => navigate(item.path)}>
              {item.title}
            </a>
          ) : (
            <span className="px-3 text-muted">{item.title}</span>
          )}
        </li>
      ))}
    </ol>
  );

  return (
    <>
      <div
        className='dca-header-menu-toggle d-flex d-lg-none align-items-center me-n2'
        title='Show header menu'
        onClick={() => { handleClickOffcanvas() }}>
        <button
          className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'
          id='kt_header_menu_mobile_toggle'
        >
          <KTIcon iconName='text-align-left' className='fs-1' />
        </button>
      </div>

      <div className='d-flex flex-row'>

        <div className='dca-overview-vertical-tabs'>{verticalTabs}</div>

        <div className='dca-overview-sidebar'>{sidebar}</div>

        <div
          className='d-flex flex-column align-items-center dca-overview-main-content'
        >
          <div className="dca-overview-breadcrumb align-self-start">{breadcrumbComponent}</div>

          <span className="dca-overview-title">Introduction</span>

          <span className="dca-overview-paragraph">The college admissions process is confusing and stressful. This is especially true for students pursuing the higher-ranked and more selective colleges, where admission rates have steadily declined while the bar for “what it takes to get in” seemingly keeps getting higher.</span>

          <img
            src={IntroPhoto1}
            alt="overview_stock_image"
            className='align-self-center'
            style={{
              width: '100%',
              objectFit: 'cover',
              objectPosition: '50% 50%',
              borderRadius: '10px',
              margin: '4vh 0vh'
            }}
          ></img>

          <span className="dca-overview-paragraph">We recognize these trends can be daunting, but we are here to help with guidance, tools and data-driven insights to help support your journey and maximize the probability of getting into your dream schools.</span>

          <span className="dca-overview-paragraph">Our recommended starting point is to ensure a baseline understanding of the holistic college admissions process and how the key admissions considerations will come together in the actual application that will be submitted senior year. This perspective should help anchor your decision-making and preparation throughout your high school years.</span>

          <span className="dca-overview-paragraph">The cornerstone admissions criteria for selective US colleges are the following:</span>

          <ul className="dca-overview-paragraph">
            {admissionsCriteriaList.map((res, index) =>
              <li key={index}>{res}</li>
            )}
          </ul>

          <span className="dca-overview-paragraph">The specific criteria may vary by individual school; for instance some colleges don't interview and other colleges may not consider (or require) SAT/ACT test scores. But generally speaking these are the core college admissions considerations that you should be preparing for through the process. The percentage weighting of these criteria will vary by college and are not transparently published; most schools will simply indicate a “holistic evaluation.”  But to provide some baseline calibration, we propose the following framework as general guidance on the relative importance of these criteria:</span>

          <img
            src={IntroPhoto2}
            alt="overview_stock_image"
            className='align-self-center'
            style={{
              width: '100%',
              objectFit: 'cover',
              objectPosition: '50% 50%',
              borderRadius: '10px',
              margin: '4vh 0vh'
            }}
          ></img>

          {pagination}
        </div>

      </div>
    </>
  )
}

export { Introduction }
