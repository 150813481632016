import { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

const Type = ({ institutionTypeFilter, setInstitutionTypeFilter, setTypeParams }) => {

  const location = useLocation()

  useEffect(() => {

    const params = new URLSearchParams(location.search);
    const typeParam = params.get('type');

    if (typeParam) {
      setInstitutionTypeFilter(parseInt(typeParam));
    }

    else {
      setInstitutionTypeFilter(0)
    }
  }, [location.search]);

  useEffect(() => {

    let params = new URLSearchParams(location.search);

    if (institutionTypeFilter != 0) {
      params.set('type', institutionTypeFilter)
    } else {
      params.delete('type')
    }

    setTypeParams(`${params.toString()}`)
  }, [institutionTypeFilter])

  return (

    <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
      <div className='dca-aside-header'>INSTITUTION TYPE</div>
      <div className='d-flex flex-row justify-content-center align-items-center'>
        <button
          type="button"
          onClick={() => setInstitutionTypeFilter(0)}
          className={`btn btn-sm ${institutionTypeFilter === 0 ? 'btn-success' : 'btn-light'}`}
          aria-pressed="true"
          style={{ width: '100%' }}
        >
          All
        </button>
        <button
          type="button"
          onClick={() => setInstitutionTypeFilter(1)}
          className={`btn btn-sm ${institutionTypeFilter === 1 ? 'btn-success' : 'btn-light'}`}
          aria-pressed="true"
          style={{ width: '100%' }}
        >
          Public
        </button>
        <button
          type="button"
          onClick={() => setInstitutionTypeFilter(2)}
          className={`btn btn-sm ${institutionTypeFilter === 2 ? 'btn-success' : 'btn-light'}`}
          aria-pressed="true"
          style={{ width: '100%' }}
        >
          Private
        </button>
      </div>
    </div>
  )
}

export { Type }