import { useNavigate } from "react-router-dom";
import { Link } from 'react-router-dom'
import { useEffect, useState } from "react";
// import ecLogo from "../../../custom_assets/ec_logo.png"
import * as dictionary from '../dictionary'
import { SaveButtonECActivityCard, SaveButtonCardNoAuth } from "../SaveButton";
import { getAuth } from "../../modules/auth";
import { SignInModal } from "../Auth/SignInModal";
import { zipcodeMap } from "../zipcodeMap";
import { ECReactGAHandlers } from "../ReactGAHandlers";
import { AuthReactGAHandlers } from "../ReactGAHandlers";

const ECCard = (props) => {

  const { handleGAEventSignInModalOpen } = AuthReactGAHandlers()

  const {
    handleGAEventExtLink,
    handleGAEventViewPremium,
    handleGAEventPurchaseModalOpen
  } = ECReactGAHandlers()

  let apiToken: any = undefined
  let permissionGroups: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
    permissionGroups = getAuth()?.groups
  }

  const sortKeys = [{ 'Alphabetical': 'name' }, { 'Cost': 'specificCost' }, { 'Impact': 'impactIndex' }]
  const handleSortKeyChange = (event) => {
    const selectedKey = event.target.value
    props.setSortKey(selectedKey)
  }
  const sortKeyReturn = (sortKey) => {
    switch (sortKey) {
      case 'name':
        return 'Alphabetical'
      case 'specificCost':
        return 'Cost'
      case 'impactIndex':
        return 'Impact'
      default:
        return 'Name'
    }
  }
  const handleSortOrderChange = () => {
    props.sortOrder === 'Ascending' ? props.setSortOrder('Descending') : props.setSortOrder('Ascending')
  }
  let navigate = useNavigate();
  const routeChange = (name) => {
    navigate(dictionary.pageRoutes.ecDetailWithId(dictionary.returnHyphenatedString(name)))
  }

  const dateReturn = (sessions) => {
    if (sessions && sessions.length > 1) {
      return `${dictionary.convertDateFormat(sessions[0].startDate)} - ${dictionary.convertDateFormat(sessions[0].endDate)} + ${sessions.length - 1} more`
    }

    else if (sessions && sessions.length === 1) {
      return `${dictionary.convertDateFormat(sessions[0].startDate)} - ${dictionary.convertDateFormat(sessions[0].endDate)}`
    }

    else {
      return '-'
    }
  }

  const locationReturn = (zipcodes) => {

    if (zipcodes && zipcodes.length > 0) {

      if (zipcodes.length > 1) {

        const zipcodeFound = zipcodeMap.find((item) => item.zipcode == zipcodes[0])

        if (zipcodeFound) {
          return `${zipcodeFound.cityState} + ${zipcodes.length - 1} more`
        }
      }

      else {
        const zipcodeFound = zipcodeMap.find((item) => item.zipcode == zipcodes[0])

        if (zipcodeFound) {
          return `${zipcodeFound.cityState}`
        }
      }

    }

    else {
      return '-'
    }
  }

  const formatFieldReturn = (val) => {
    if (val && val.length > 1) {
      let res: any = []
      val.map((act, index) => {
        if (index === val.length - 1) {
          res.push(dictionary.activityFormat[act])
        }
        else {
          res.push(`${dictionary.activityFormat[act]}, `)
        }
      })
      return res
    }
    else if (val) {
      return dictionary.activityFormat[val[0]]
    }
    else {
      return '-'
    }
  }

  const costReturn = (res) => {
    if (res && res.specificCost !== null) {
      return `$${dictionary.numberConverter(res.specificCost)}`
    }

    if (res && res.minCost && res.maxCost) {
      return `$${dictionary.numberConverter(res.minCost)} - $${dictionary.numberConverter(res.maxCost)}`
    }

    else {
      return '-'
    }
  }

  const durationReturn = (duration) => {
    if (duration) {
      return `${duration} Weeks`
    }

    else {
      return '-'
    }
  }

  const schoolLevelReturn = (schoolLevel) => {
    if (schoolLevel) {
      let res: any = []

      schoolLevel.map((lvl, index) => {
        if (index === 0) {
          if (lvl === 1) {
            res.push(`High school`)
          }

          else if (lvl === 2) {
            res.push(`Middle school`)
          }

          else if (lvl === 3) {
            res.push(`Elementary school`)
          }
        }

        else {
          if (lvl === 1) {
            res.push(` High school`)
          }

          else if (lvl === 2) {
            res.push(` Middle school`)
          }

          else if (lvl === 3) {
            res.push(` Elementary school`)
          }
        }
      })

      return `${res}`
    }

    else {
      return '-'
    }
  }

  const inPersonFormatReturn = (inPersonFormat) => {

    if (inPersonFormat && inPersonFormat.length > 1) {
      let res: any = []

      inPersonFormat.map((format, index) => {
        if (index === 0) {
          if (format === 1) {
            res.push(`Residential`)
          }

          else if (format === 2) {
            res.push(`Commuting`)
          }
        }

        else {
          if (format === 1) {
            res.push(` Residential`)
          }

          else if (format === 2) {
            res.push(` Commuting`)
          }
        }
      }
      )

      return `${res}`
    }

    else if (inPersonFormat && inPersonFormat.length == 1) {

      if (inPersonFormat[0] === 1) {
        return `Residential`
      }

      else if (inPersonFormat[0] === 2) {
        return `Commuting`
      }
    }

    else {
      return '-'
    }
  }

  const impactReturn = (impact) => {
    if (impact) {

      switch (true) {
        case impact === 1:
          return 'Good'
        case impact === 2:
          return 'Better'
        case impact === 3:
          return 'Best'
      }

    }

    else {
      return '-'
    }
  }

  const returnAccessPremiumData = (ec, allECPremiumInsights) => {

    const tokenTrue = (apiToken != undefined)

    let insightsTrue = false

    let numAlumni: any = undefined

    if (allECPremiumInsights.filter((item) => (item.id == ec.id)).length > 0) {
      insightsTrue = true

      if (insightsTrue === true && allECPremiumInsights.filter((item) => (item.id == ec.id))[0].insights['Core Data'][0].sampleSize) {
        numAlumni = allECPremiumInsights.filter((item) => (item.id == ec.id))[0].insights['Core Data'][0].sampleSize
      }
    }

    const groupsTrue = (permissionGroups != undefined)
    const hasGroup2 = (permissionGroups?.includes(`EC${ec.id}`))

    const caseA = !insightsTrue

    const caseB = tokenTrue && (groupsTrue && hasGroup2) && insightsTrue

    const caseC = (tokenTrue && !groupsTrue) || (tokenTrue && (groupsTrue && !hasGroup2))

    const caseD = !tokenTrue && !groupsTrue

    switch (true) {
      // On card, only show when a program has premium data available
      // Premium Data not available for this EC Activity
      case caseA:
        return <div></div>

      // Premium data available and authenticated user has access
      case caseB:
        return <div style={{ position: 'absolute', top: '15px', right: '85px' }} onClick={(e) => e.stopPropagation()}><a
          role="button"
          className="me-5 btn btn-sm fw-bold dca-premium-data-button-color"
          onClick={() => {
            navigate(dictionary.pageRoutes.ecPremiumInsightsWithId(ec.id));

            handleGAEventViewPremium('Search', ec.id)
          }}
        >View Premium Data</a></div>

      // Premium insights available but authenticated user does not have access
      case caseC:
        return <div style={{ position: 'absolute', top: '15px', right: '85px' }} onClick={(e) => e.stopPropagation()}><a
          role="button"
          className="me-5 btn btn-sm fw-bold dca-premium-data-button-color"
          data-bs-toggle="modal"
          data-bs-target="#purchase_modal"
          onClick={(e) => {
            e.preventDefault();
            props.setPurchaseID(dictionary.ecPremiumAccessCode);
            props.setNavigateTo(dictionary.pageRoutes.ecPremiumInsightsWithId(ec.id));
            props.setECID(ec.id);
            props.setShowNavAfterPayment(false);

            handleGAEventPurchaseModalOpen(ec.id)

            if (ec.name) {
              props.setECName(ec.name);
            }
            if (numAlumni != undefined)
              props.setECNumAlumni(numAlumni)
          }}
        >
          <span className='m-3 text-nowrap align-self-end'><i className="bi bi-lock align-self-center" style={{ color: 'black', marginRight: '4px' }}></i>View Premium Data</span>
        </a></div>

      // Guest user, not authenticated but insights true
      case insightsTrue && caseD:
        return <div style={{ position: 'absolute', top: '15px', right: '85px' }} onClick={(e) => e.stopPropagation()}><a
          role="button"
          className="me-5 btn btn-sm fw-bold dca-premium-data-button-color"
          data-bs-toggle="modal"
          href="#login_modal"
          onClick={(e) => {
            e.preventDefault();
            props.setPrompt('Access to this premium content is reserved for qualifying user accounts. To proceed, please first sign-in to your account (or sign-up for an account) below:');
            props.setNavigateTo('');
            handleGAEventSignInModalOpen('forced')
          }}

        ><span className='m-3 text-nowrap align-self-end'><i className="bi bi-lock align-self-center" style={{ color: 'black', marginRight: '4px' }}></i>View Premium Data</span></a></div>

      default:
        return <div></div>

    }


  }

  const returnAccessPremiumDataCheck = (ec, allECPremiumInsights) => {

    const tokenTrue = (apiToken != undefined)

    let insightsTrue = false

    let numAlumni: any = undefined

    if (allECPremiumInsights.filter((item) => (item.id == ec.id)).length > 0) {
      insightsTrue = true

      if (insightsTrue === true && allECPremiumInsights.filter((item) => (item.id == ec.id))[0].insights['Core Data'][0].sampleSize) {
        numAlumni = allECPremiumInsights.filter((item) => (item.id == ec.id))[0].insights['Core Data'][0].sampleSize
      }
    }

    const groupsTrue = (permissionGroups != undefined)
    const hasGroup2 = (permissionGroups?.includes(`EC${ec.id}`))

    const caseA = !insightsTrue

    const caseB = tokenTrue && (groupsTrue && hasGroup2) && insightsTrue

    const caseC = (tokenTrue && !groupsTrue) || (tokenTrue && (groupsTrue && !hasGroup2))

    const caseD = !tokenTrue && !groupsTrue

    switch (true) {
      // Returns true or false to indicate whether ec name field needs a marginTop to account for the ec premium and save buttons
      case caseA:
        return false

      // Premium data available and authenticated user has access
      case caseB:
        return true

      // Premium insights available but authenticated user does not have access
      case caseC:
        return true

      // Guest user, not authenticated but insights true 
      case insightsTrue && caseD:
        return true

      default:
        return <div></div>

    }


  }

  return (
    <div className='dca-card-container'>

      {/* EC CARDS HEADER */}
      <div className="d-flex flex-row justify-content-between align-items-end">

        <div className="d-flex flex-row">
          <div className="me-3 align-bottom dca-card-items-found-text">Displaying {props.filteredEC.length} activities</div>
          <div
            onClick={handleSortOrderChange}
            style={{ cursor: 'pointer', fontSize: '15px', lineHeight: '18px', color: '#585555', marginBottom: '2px' }}
            className='text-muted fs-7 fw-bolder align-self-end'
          >by {props.sortOrder} {sortKeyReturn(props.sortKey)} {props.sortOrder === 'Ascending' && (<i className="bi bi-arrow-up"></i>)}
            {props.sortOrder === 'Descending' && (<i className="bi bi-arrow-down"></i>)}
          </div>
        </div>

        <select
          className='form-select w-25 align-bottom'
          data-control="select2"
          value={props.sortKey}
          onChange={handleSortKeyChange}
          style={{ cursor: 'pointer' }}
        >
          {sortKeys.map((res, id) => (
            <option key={id} value={Object.values(res)}>{Object.keys(res)}</option>
          ))}
        </select>
      </div>
      {/* END EC CARD HEADER */}


      <div>{props.filteredEC.map((res) => (

        <div
          key={res.id}
          className="mt-5 hover-elevate-up dca-card-item"
          onClick={() => routeChange(res.name)}
        >

          <div className="d-flex flex-row justify-content-end">

            {returnAccessPremiumData(res, props.allECPremiumInsights)}

            {apiToken != undefined ? <SaveButtonECActivityCard
              user={props.user}
              ecId={res.id}
              setUser={props.setUser} /> : <SaveButtonCardNoAuth
              setPrompt={props.setPrompt}
              setNavigateTo={props.setNavigateTo} />}
          </div>
          {/* <div className="d-flex align-self-center justify-content-center" style={{ width: '33%', paddingTop: '15px' }}>
            <img className='mt-5' alt="ecImage" src={res.imageURL ? res.imageURL : ecLogo} style={{ width: '30%', backgroundColor: 'transparent', paddingTop: '15px' }} />
          </div> */}

          {/* Padding div block while image is removed */}
          <div className="d-flex align-self-center justify-content-center" style={{ width: '33%', padding: '15px' }}>
          </div>

          <div className="d-flex justify-content-center">
            <span
              className="dca-card-item-nameField"
              // style={ returnAccessPremiumDataCheck(res, props.allECPremiumInsights) ? { marginTop: '35px'} : {}}
              style={{ marginTop: '35px', textAlign: 'center', maxWidth: '80%' }}
            >{res.name}</span>
          </div>

          <div className="d-flex justify-content-center">
            <span
              className="align-self-center"
              style={{ width: '90%', marginTop: '20px', marginRight: '20px', marginLeft: '20px' }}>{res.description}</span>
          </div>


          {/* INNER CARD CONTENT */}
          <div className="d-flex flex-column justify-content-center" style={{ marginTop: '17px' }}>
            <div className="d-flex flex-row align-self-center justify-content-between" style={{ width: '90%', margin: '20px' }}>
              <div className="d-flex flex-column" style={{ width: '18%' }}>
                <div className="dca-card-item-header">ACTIVITY TYPE</div>
                <div className="dca-card-item-text">{dictionary.returnValueInArrayOfObjects(res.activityType, dictionary.activityType)}</div>
              </div>

              <div className="d-flex flex-column" style={{ width: '18%' }}>
                <div className="dca-card-item-header">SCHOOL LEVEL</div>
                <div className="dca-card-item-text">{schoolLevelReturn(res.schoolLevel)}</div>
              </div>

              <div className="d-flex flex-column" style={{ width: '18%' }}>
                <div className="dca-card-item-header">IMPACT</div>
                <div className="dca-card-item-text">{impactReturn(res.impactIndex)}</div>
              </div>

              <div className="d-flex flex-column" style={{ width: '18%' }}>
                <div className="dca-card-item-header">DATE(S)</div>
                <div className="dca-card-item-text">{dateReturn(res.ec_activity_session_dates)}</div>
              </div>

              <div className="d-flex flex-column" style={{ width: '18%' }}>
                <div className="dca-card-item-header">DURATION</div>
                <div className="dca-card-item-text">{durationReturn(res.duration)}</div>
              </div>

            </div>

            <div className="d-flex flex-row align-self-center justify-content-between" style={{ width: '90%', margin: '20px' }}>
              <div className="d-flex flex-column" style={{ width: '18%' }}>
                <div className="dca-card-item-header">ACTIVITY FORMAT(S)</div>
                <div className="dca-card-item-text">{formatFieldReturn(res.activityFormat)}</div>
              </div>

              <div className="d-flex flex-column" style={{ width: '18%' }}>
                <div className="dca-card-item-header">IN-PERSON FORMAT(S)</div>
                <div className="dca-card-item-text">{inPersonFormatReturn(res.inPersonFormat)}</div>
              </div>

              <div className="d-flex flex-column" style={{ width: '18%' }}>
                <div className="dca-card-item-header">LOCATION(S)</div>
                <div className="dca-card-item-text">{locationReturn(res.locations)}</div>
              </div>

              <div className="d-flex flex-column" style={{ width: '18%' }}>
                <div className="dca-card-item-header">COST</div>
                <div className="dca-card-item-text">{costReturn(res)}</div>
              </div>

              <div className="d-flex flex-column" style={{ width: '18%' }}>
                <div className="dca-card-item-header">WEBSITE</div>
                {res.homepageURL ? <a
                  href={res.homepageURL}
                  target="_blank"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleGAEventExtLink(res.id)
                  }}
                  className="dca-card-item-text">Link</a> : <span className="dca-card-item-text">-</span>}
              </div>

            </div>
          </div>
          {/* END INNER CARD CONTENT */}

        </div>
      ))}

      </div>
    </div>
  )
}

export { ECCard }
