import { useState, useEffect, useRef } from 'react'
import { EditScoreProfile } from './EditScoreProfile'
import noUiSlider from 'nouislider'
import Select from 'react-select'
import { type } from 'os'

const Aside = ({ results, collegeOptions, collegeSetOptions, setCollegeSelect, setCollegeSetSelect, setRequiredSelect, setOptionalSelect, setBlindSelect, setSATMin, setSATMax, setACTMin, setACTMax, userSATVerbal, userSATMath, userSATSuperScore, userActEnglish, userActMath, userActWriting, userActScience, userActReading, userACTBestScore, setUserSATVerbal, setUserSATMath, setUserACTBestScore, setUserActEnglish, setUserActMath, setUserActWriting, setUserActScience, setUserActReading, setUserprofile, setUserSATSuperScore, setOnlyInterestsSelect, requiredCount, optionalCount, blindCount }) => {

  //////// College ////////
  const [selectedColleges, setSelectedColleges] = useState<any>([])

  const handleCollegeSelectChange = (selectedColleges) => {
    setSelectedColleges(selectedColleges);
    let collegeArr: any = []
    selectedColleges.map((college) =>
      collegeArr.push(college.value))
    setCollegeSelect(collegeArr)
  }

  const collegeSelect = (
    <div className='mt-4 d-flex flex-column'>
      <div className='dca-aside-header'>COLLEGE</div>
      <Select
        options={collegeOptions}
        isMulti
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 'transparent',
            backgroundColor: '#F9F9F9'
          })
        }}
        value={selectedColleges}
        onChange={handleCollegeSelectChange}
      // onInputChange={(inputValue) => {
      //   handleInputNameChange(inputValue)
      // }}
      />
    </div>
  )

  //////// College Set ////////

  const [selectedSet, setSelectedSet] = useState<any>('')

  const handleCollegeSetSelectChange = (event) => {
    const selectedSet = event.target.value
    setSelectedSet(selectedSet)
    setCollegeSetSelect(selectedSet)
  }

  const collegeSetSelect = (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
      <div className='dca-aside-header'>COLLEGE SET</div>
      <select
        className='form-select form-select-solid'
        data-control="select2"
        value={selectedSet}
        onChange={handleCollegeSetSelectChange}
        style={{ cursor: 'pointer' }}
      >
        <option value={''}>All</option>
        {collegeSetOptions.map((res) =>
          <option value={res.value}>{res.label}</option>
        )}
      </select>
    </div>
  )

  //////// Test Policy ////////
  // let requiredCount: any = '-'
  // let optionalCount: any = '-'
  // let blindCount: any = '-'

  // if (results.length > 0) {
  //   requiredCount = results.filter((item) => item.testPolicy === 1).length
  //   optionalCount = results.filter((item) => item.testPolicy === 2).length
  //   blindCount = results.filter((item) => item.testPolicy === 3).length
  // }

  const handleCheckChange = (event) => {
    let checkStatus = event.target.checked
    return checkStatus
  }

  const testPolicySelect = (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
      <div className='dca-aside-header'>TEST POLICY</div>
      <div className="checkbox-list d-flex flex-column" style={{ marginTop: '5px' }}>

        <label className="d-flex justify-content-between" style={{ cursor: 'pointer' }}>
          <div>
            <input
              type="checkbox"
              className='dca-checkbox'
              name="Required"
              onChange={(event) => (setRequiredSelect(handleCheckChange(event)))}
              style={{ cursor: 'pointer' }}
            />
            <span className='m-3'>Required</span>
          </div>
          <span className='text-muted fs-7 fw-bolder'>{requiredCount}</span>
        </label>

        <label className="d-flex justify-content-between" style={{ cursor: 'pointer', marginTop: '5px' }}>
          <div>
            <input
              type="checkbox"
              className='dca-checkbox'
              name="Optional"
              onChange={(event) => (setOptionalSelect(handleCheckChange(event)))}
              style={{ cursor: 'pointer' }}
            />
            <span className='m-3'>Optional</span>
          </div>
          <span className='text-muted fs-7 fw-bolder'>{optionalCount}</span>
        </label>

        <label className="d-flex justify-content-between" style={{ cursor: 'pointer', marginTop: '5px' }}>
          <div>
            <input
              type="checkbox"
              className='dca-checkbox'
              name="Blind"
              onChange={(event) => (setBlindSelect(handleCheckChange(event)))}
              style={{ cursor: 'pointer' }}
            />
            <span className='m-3'>Blind</span>
          </div>
          <span className='text-muted fs-7 fw-bolder'>{blindCount}</span>
        </label>

      </div>
    </div>
  )

  //////// Median SAT ////////

  const satSliderRef = useRef(null);

  useEffect(() => {

    if (!satSliderRef.current) {

      const slider: any = document.querySelector("#kt_slider_sat");
      noUiSlider.create(slider, {
        start: [0, 1600],
        // connect: true,
        range: {
          "min": 0,
          "max": 1600
        },
        step: 10,
        tooltips: [
          {
            to: function (value) {
              return value.toFixed(0)
            }
          },
          {
            to: function (value) {
              return value.toFixed(0)
            }
          },
        ]
      });

      slider.noUiSlider.on("set", function (values, handle) {
        if (handle === 0) {
          setSATMin(parseInt(values[0]))
        }
        if (handle === 1) {
          setSATMax(parseInt(values[1]))
        }

      });

      satSliderRef.current = slider;

    }


  }, [])

  const medianSATSelect = (
    <div className='mt-4 d-flex flex-column'>
      <div className='dca-aside-header'>MEDIAN SAT SCORE</div>
      <div className="mb-0" style={{ marginTop: '50px', zIndex: '1' }}>
        <div id="kt_slider_sat"></div>
      </div>
    </div>
  )

  //////// Median ACT ////////

  const actSliderRef = useRef(null);

  useEffect(() => {

    if (!actSliderRef.current) {

      const slider: any = document.querySelector("#kt_slider_act");
      noUiSlider.create(slider, {
        start: [0, 36],
        // connect: true,
        range: {
          "min": 0,
          "max": 36
        },
        step: 1,
        tooltips: [
          {
            to: function (value) {
              return value.toFixed(0)
            }
          },
          {
            to: function (value) {
              return value.toFixed(0)
            }
          },
        ]
      });

      slider.noUiSlider.on("set", function (values, handle) {
        if (handle === 0) {
          setACTMin(parseInt(values[0]))
        }
        if (handle === 1) {
          setACTMax(parseInt(values[1]))
        }

      });

      actSliderRef.current = slider;

    }


  }, [])

  const medianACTSelect = (
    <div className='mt-4 d-flex flex-column'>
      <div className='dca-aside-header'>MEDIAN ACT SCORE</div>
      <div className="mb-0" style={{ marginTop: '50px', zIndex: '1' }}>
        <div id="kt_slider_act"></div>
      </div>
    </div>
  )

  //////// Show My Interests ////////
  const showMyInterests = (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
      <div className="checkbox-list d-flex flex-column" style={{ marginTop: '5px' }}>

        <label className="d-flex justify-content-between" style={{ cursor: 'pointer' }}>
          <div>
            <input
              type="checkbox"
              className='dca-checkbox'
              name="Show My Interests"
              onChange={(event) => (setOnlyInterestsSelect(handleCheckChange(event)))}
              style={{ cursor: 'pointer' }}
            />
            <span className='ms-3 dca-test-score-show-colleges-of-interest' style={{ marginLeft: '0.1rem' }}>Show My Colleges Of Interest</span>
          </div>
        </label>

      </div>
    </div>
  )

  return (
    <div style={{ width: '260px', maxWidth: '260px', whiteSpace: 'nowrap' }}>
      {collegeSelect}
      {collegeSetSelect}
      {testPolicySelect}
      {medianSATSelect}
      {medianACTSelect}
      <EditScoreProfile
        userSATVerbal={userSATVerbal}
        userSATMath={userSATMath}
        userSATSuperScore={userSATSuperScore}
        userActEnglish={userActEnglish}
        userActMath={userActMath}
        userActWriting={userActWriting}
        userActScience={userActScience}
        userActReading={userActReading}
        userACTBestScore={userACTBestScore}
        setUserSATVerbal={setUserSATVerbal}
        setUserSATMath={setUserSATMath}
        setUserACTBestScore={setUserACTBestScore}
        setUserActEnglish={setUserActEnglish}
        setUserActMath={setUserActMath}
        setUserActWriting={setUserActWriting}
        setUserActScience={setUserActScience}
        setUserActReading={setUserActReading}
        setUserprofile={setUserprofile}
        setUserSATSuperScore={setUserSATSuperScore}
      />
      {showMyInterests}
    </div>
  )
}

export { Aside }