import Select from 'react-select'
import { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';

const Location = ({ setLocationFilter, selectorLocationOptions, setLocationParams }) => {

  const location = useLocation()
  const [selectedLocations, setSelectedLocations] = useState<any>([]);

  const handleLocationSelectChange = (selectedLocations) => {

    setSelectedLocations(selectedLocations);
    let locArr: any[] = [];
    selectedLocations.map((college) =>
      locArr.push(parseInt(college.value[0]))
    )
    setLocationFilter(locArr)
  }

  const setChosenOptions = (locs, options) => {
    let selected: any[] = [];

    locs.forEach((intStr) => {
      let optionObj: any = options.find((opt) => opt.value.toString() === intStr);
      if (optionObj) {
        selected.push(optionObj);
      }
    });

    return selected;
  };


  // On load, check url. If location exists in url params, split the values and use setChosenOptions to find the objects in selectorLocationOptions to set the selectedLocations state. Doing so then updates the Select component to update the ui and preps the location filter accordingly
  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let locationParam = params.get('location');
    let res: any[] = []

    if (locationParam) {
      const locationStrs = locationParam.split(',').map(loc => loc);
      res = setChosenOptions(locationStrs, selectorLocationOptions)
      handleLocationSelectChange(res)
    }

    else {
      setSelectedLocations([])
    }

  }, [location.search]);

  // When loaded, selecting options from the Select component runs the handleLocationSelectChange. When selectedLocations is updated, update the URL here:
  useEffect(() => {

    let params = new URLSearchParams(location.search);

    if (selectedLocations.length > 0) {
      const locations = selectedLocations.map((loc) => loc.value).join(',')
      params.set('location', locations)
    } else {
      params.delete('location')
    }

    setLocationParams(`${params.toString()}`)

  }, [selectedLocations])

  return (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px', zIndex: 4 }}>
      <div className='dca-aside-header'>LOCATION</div>
      <Select
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 'transparent',
            backgroundColor: '#F9F9F9'
          }),

        }}
        options={selectorLocationOptions}
        isMulti
        value={selectedLocations}
        onChange={handleLocationSelectChange}
      />
    </div>
  )
}

export { Location }