import { FC, useState, useEffect } from 'react'
import axios from 'axios'
import * as dictionary from '../components/dictionary'
import { Link, useParams } from 'react-router-dom'
import { CollegeDetailCardData } from '../components/CollegeDetail/CollegeDetailCardData'
import { CollegeDetailTabs } from '../components/CollegeDetail/CollegeDetailTabs'
import { SaveButtonCollegeInline, SaveButtonInlineNoAuth } from '../components/SaveButton'
import { getAuth } from '../modules/auth'
import { LoadingIndicator } from '../components/LoadingIndicator'
import { collegeMap } from '../components/collegeMap'

const CollegeDetailPage = ({ user, setUser, userCollegeInterest, setUserCollegeInterest, collegeListLength, prompt, setPrompt, navigateTo, setNavigateTo, setPurchaseID, setPurchaseModalCollegeName, collegesId, selectedTab, setSelectedTab, collegeSearchLocation, idToSend }) => {

  let apiToken: any = undefined
  let permissionGroups: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
    permissionGroups = getAuth()?.groups
  }

  useEffect(() => {
    if (getAuth()?.api_token && getAuth()?.groups) {
      apiToken = getAuth()?.api_token
      permissionGroups = getAuth()?.groups
    }

  }, [user])

  // const { collegeId } = useParams()
  const { collegeNameHyphenated } = useParams()
  const [college, setCollege] = useState<any>([])

  useEffect(() => {

    const getCollege = async () => {
      // let res = await axios.get(`${dictionary.URL}/colleges/${collegeId}`)

      try {
        // let collegeObj = collegeMap.find(col => col.hyphenated == collegeNameHyphenated)

        // let res = await axios.get(`${dictionary.URL}/colleges/${collegeObj?.id}`)

        let res = await axios.get(`${dictionary.URL}/colleges/${idToSend}`)
        setCollege(res.data)
      } catch (error) {
        console.log('Could not find college info:', error)
        throw error
      }
    }
    getCollege();

  }, [])

  return college ? (
    <div className='me-5' style={{ marginLeft: '35px' }}>

      <Link
        className="fw-bold"
        to={`${collegeSearchLocation}`}
        style={{ color: 'black', marginTop: '30px' }}>
        <i className="me-3 fa-solid fa-arrow-left" style={{ color: 'black', fontWeight: 'bold', marginTop: '30px' }} ></i>
        Back to Exploring All Colleges</Link>

      <div className='mt-5 d-flex flex-row align-items-center justify-content-between'>
        <div className='d-flex flex-row justify-content-between align-items-center'>
          <img className="m-3" style={{ width: '75px', height: '75px' }} alt="campus_photo" src={dictionary.collegeLogos[college.id]} />

          <div className='mt-3 d-flex flex-row align-items-baseline'>
            <p className='fw-bold' style={{ fontSize: '30px' }}>{college.name}</p>

            <p className='ms-4 fw-bold'>{college.locationCity ? `${college.locationCity}, ${college.locationState ? dictionary.stateMap[college.locationState] : 'N/A'}` : 'N/A'}</p>
          </div>
        </div>

        <div className='d-flex flex-row justify-content-between align-items-center'>
          {/* <button type="button" className="me-5 btn btn-sm fw-bold" style={{ background: dictionary.gray }}><i className="bi bi-link-45deg"></i> Connect with {college.name} students</button> */}

          {apiToken != undefined ? <SaveButtonCollegeInline
            list={userCollegeInterest}
            user={user}
            collegeId={college.id}
            setUserCollegeInterest={setUserCollegeInterest}
          /> : <SaveButtonInlineNoAuth setPrompt={setPrompt} setNavigateTo={setNavigateTo} />}
        </div>
      </div>

      <div className='mt-5'>
        <CollegeDetailCardData
          college={college}
        />
      </div>

      <div style={{ marginTop: '50px', maxWidth: '95vw' }}>
        <CollegeDetailTabs
          college={college}
          setUser={setUser}
          collegeListLength={collegeListLength}
          setPrompt={setPrompt}
          setNavigateTo={setNavigateTo}
          setPurchaseID={setPurchaseID}
          setPurchaseModalCollegeName={setPurchaseModalCollegeName}
          collegesId={collegesId}
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
        />
      </div>

    </div>

  ) : (<div> <LoadingIndicator text={"Loading College Data ... "} /></div>)
}

export { CollegeDetailPage }
