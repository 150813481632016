import { linearGradient } from "polished";

const RankColorRange = () => {

  const green = 'rgba(135, 233, 138, 1)';
  const yellow = 'rgba(255, 241, 111, 1)';
  const red = 'rgba(243, 72, 115, 1)';

  return (
    <div className="d-flex flex-row align-items-end" style={{ color: 'gray' }}>
      Ranking Scale
      <div className="ms-5 d-flex flex-column">
        <div className="d-flex flex-row justify-content-between">
          <div>1</div>
          <div>100</div>
        </div>
        <div className='mb-1 rounded' style={{ background: "linear-gradient(98deg, rgba(135,233,138,1) 0%, rgba(255,241,111,1) 33%, rgba(218,160,51,1) 66%, rgba(243,72,115,1) 100%)", width: '175px', height: '10px' }}>
        </div>
      </div>
    </div>
  )
}

export { RankColorRange }