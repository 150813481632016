import Chart from 'react-apexcharts'

const BarChart = ({ chartId, xaxis, seriesName, seriesData }) => {

  let valuesNull = true

  if (xaxis.some((x) => x !== null) && seriesData.some((data) => data != null)) {
    valuesNull = false
  }

  return valuesNull ? (
    <Chart
      options={{
        chart: {
          id: chartId
        },
        xaxis: {
          categories: xaxis,
          labels: {
            show: true,
            rotate: -45,
          }
        },
        //   title: {
        //     text: chartTitle,
        //     align: 'left',
        //     margin: 10,
        //     offsetX: 0,
        //     offsetY: 0,
        //     floating: false,
        //     style: {
        //       fontSize:  '14px',
        //       fontWeight:  'bold',
        //       fontFamily:  undefined,
        //       color:  '#263238'
        //     },
        // },
        yaxis: {
          min: 0,
          max: 100,
          labels: {
            style: {
              colors: ['#9AA2B6'],
            },
            formatter: function (val) {
              return val + "%";
            }
          }
        },
        colors: ["#90F892"],
        tooltip: {
          enabled: false,
        },
        dataLabels: {
          enabled: false,
          style: {
            colors: ['#000000'],
          },
          background: {
            enabled: false,
            borderWidth: 0,
            foreColor: '#000',
          },
          formatter: function (val, opts) {
            return val + "%";
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 5,
            borderRadiusApplication: 'end',
            columnWidth: '55%',
            dataLabels: {
              position: 'top',
            },
          }
        }
      }}
      series={[
        {
          name: seriesName,
          data: seriesData
        }
      ]}
      type='bar'
      // width={'450'}
      height={'300'}
    />
  ) : (
    <Chart
      options={{
        chart: {
          id: chartId
        },
        xaxis: {
          categories: xaxis,
          labels: {
            show: true,
            rotate: -45,
          }
        },
        yaxis: {
          min: 0,
          max: 100,
          labels: {
            style: {
              colors: ['#9AA2B6'],
            },
            formatter: function (val) {
              return val + "%"
            }
          }
        },
        colors: ["#90F892"],
        tooltip: {
          enabled: false,
        },
        dataLabels: {
          enabled: true,
          style: {
            colors: ['#000000'],
          },
          background: {
            enabled: false,
            borderWidth: 0,
            foreColor: '#000',
          },
          formatter: function (val: any) {
            if (val != null) {
              return val.toFixed(0) + "%";
            }

            else {
              return ""
            }
          },
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            borderRadius: 5,
            borderRadiusApplication: 'end',
            dataLabels: {
              position: 'top',
            },
          }
        }
      }}
      series={[
        {
          name: seriesName,
          data: seriesData
        }
      ]}
      type='bar'
      // width={'450'}
      height={'300'}
    />
  )
}


export { BarChart } 
