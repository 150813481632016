import { booleanReturn, convertDateFormat } from '../../dictionary'

const ApplicationParameters = ({ ec }) => {

  const returnAcceptanceRate = (admitRate) => {
    if (admitRate) {
      return `${admitRate * 100}%`
    }

    else {
      return '-'
    }
  }

  return (
    <div className='d-flex flex-row card shadow fw-bold' style={{ marginTop: '40px', padding: '40px' }}>
      <div className="d-flex flex-column" style={{ width: '45%' }}>

        <div>
          <div>APPLICATION REQUIRED</div>
          <div>{booleanReturn(ec.appRequired)}</div>
        </div>

        <div style={{ marginTop: '20px' }}>
          <div>APPLICATION AVAILABILITY</div>
          <div>{convertDateFormat(ec.appAvailability)}</div>
        </div>

        <div style={{ marginTop: '20px' }}>
          <div>APPLICATION DUE DATE</div>
          <div>{convertDateFormat(ec.appDueDate)}</div>
        </div>

        <div style={{ marginTop: '20px' }}>
          <div>APPLICATION RESULT DATE</div>
          <div>{ec.appResultDate ? ec.appResultDate : '-'}</div>
        </div>

        <div style={{ marginTop: '20px' }}>
          <div>ROLLING ADMISSION</div>
          <div>{booleanReturn(ec.rollingAdmission)}</div>
        </div>

        <div style={{ marginTop: '20px' }}>
          <div>APPLICATION PROCESS</div>
          <div>{ec.appProcess ? ec.appProcess : '-'}</div>
        </div>

        <div style={{ marginTop: '20px' }}>
          <div>APPLICATION FEE</div>
          <div>{ec.appFee ? `$${ec.appFee}` : '-'}</div>
        </div>

      </div>

      <div className="d-flex flex-column" style={{ marginLeft: '20px', width: '45%' }}>

        <div>
          <div>PRE-REQUISITES/REQUIREMENTS</div>
          <div>{ec.requirements ? ec.requirements : '-'}</div>
        </div>

        <div style={{ marginTop: '20px' }}>
          <div>ESSAY REQUIRED</div>
          <div>{booleanReturn(ec.essaysRequired)}</div>
        </div>

        <div style={{ marginTop: '20px' }}>
          <div>RECOMMENDATION(S) REQUIRED</div>
          <div>{booleanReturn(ec.recsRequired)}</div>
        </div>

        <div style={{ marginTop: '20px' }}>
          <div>INTERVIEW REQUIRED</div>
          <div>{booleanReturn(ec.interviewRequired)}</div>
        </div>

        <div style={{ marginTop: '20px' }}>
          <div>ACCEPTANCE RATE</div>
          <div>{returnAcceptanceRate(ec.admitRate)}</div>
        </div>

        <div style={{ marginTop: '20px' }}>
          <div>APPLICATION LINK</div>
          <a href={ec.applicationURL} target="_blank">{ec.applicationURL ? 'Link' : '-'}</a>
        </div>

      </div>
    </div>
  )
}

export { ApplicationParameters }