import React, { Suspense, useEffect } from 'react'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { AuthInit } from './modules/auth'
import { ThemeModeProvider } from '../_metronic/partials'
import { Outlet } from 'react-router-dom'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import ReactGA from "react-ga4"
import { useLocation } from 'react-router-dom'
import { pageRoutes } from './components/dictionary'

const App = () => {

  const GA_measurement_id = process.env.REACT_APP_GA_ID ?? ''

  if (GA_measurement_id != '') {
    ReactGA.initialize(GA_measurement_id)
  }

  //// Screen Alert useEffect ////
  const location = useLocation()

  useEffect(() => {

    const screenWidthAlert = () => {
      const screenWidth = window.innerWidth;
      if (screenWidth < 768 && !localStorage.getItem('screenWidthAlert')) {
        const modalElement = document.getElementById("screen_width_alert_modal")
        if (modalElement) {
          modalElement.classList.add('show')
        }
        localStorage.setItem('screenWidthAlert', 'true');
      }
    };

    let overviewPages = [pageRoutes.overviewIntroduction, pageRoutes.overviewCollegeAppWalkthrough, pageRoutes.overviewAdmissionsAdvice]

    if (!(location.pathname === pageRoutes.home || overviewPages.includes(location.pathname))) {
      screenWidthAlert();
      window.addEventListener('resize', screenWidthAlert);
    }

    return () => {
      window.removeEventListener('resize', screenWidthAlert);
    };

  }, [location.pathname]);
  //// //// //// ////

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <ThemeModeProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <AuthInit>
                <Outlet />
                <MasterInit />
              </AuthInit>
            </LocalizationProvider>
          </ThemeModeProvider>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  )
}

export { App }
