const SubmitSAT = ({ userSATScoreOutput, userACTScoreOutput, collegeName, satSuperScore, actComposite, testPolicy, collegeCDS }) => {

  ////////////////
  // EDIT TEXT //
  //////////////

  //// TEST POLICY 1 ////
  // actScoreOutput = -1
  const caseA1 = <div>{collegeName} requires a test score from all applicants to be considered for admission. You have indicated you have an SAT score ({satSuperScore}), but not an ACT score, so therefore our <span className='fw-bold'>recommendation is to submit your SAT score</span>.</div>

  const noteA1 = <div>Please note that your current SAT score is <span className='fw-bold'>below the median score</span> for {collegeName} and because a score is required for application, we recommend trying to <span className='fw-bold'>improve your SAT score and/or explore the ACT</span>.</div>

  //actScoreOutput = -2
  const caseB1 = <div>{collegeName} requires a test score from all applicants to be considered for admission. You have indicated you have an SAT score ({satSuperScore}), and there is insufficient data published by {collegeName} on their ACT score benchmarks, so therefore our <span className='fw-bold'>recommendation is to submit your SAT score</span>.</div>

  // actScoreOutput > 0
  const caseC1 = <div>{collegeName} requires a test score from all applicants to be considered for admission. Your SAT score ({satSuperScore}) is stronger than your ACT score ({actComposite}), as compared to the respective benchmarks published by {collegeName}, therefore our <span className='fw-bold'>recommendation is to submit your SAT score</span>.</div>

  const noteC1 = <div>Please note that your current SAT score is <span className='fw-bold'>below the median score</span> for {collegeName} and because a score is required, we recommend <span className='fw-bold'>trying to improve your SAT and/or ACT scores</span>.</div>

  //// TEST POLICY 2 ////
  // actScoreOutput = -1
  const caseA2 = <div>{collegeName} is test-optional, but we <span className='fw-bold'>recommend to submit your SAT score ({satSuperScore})</span> with above-average confidence that it could strengthen your application. This recommendation is based on the strength of your SAT score as compared to the benchmark values for {collegeName}.</div>

  // actScoreOutput = -2
  const caseB2 = <div>{collegeName} is test-optional, but we <span className='fw-bold'>recommend to submit your SAT score ({satSuperScore})</span> with above-average confidence that it could strengthen your application. This recommendation is based on the strength of your SAT score, as compared to the benchmark values for {collegeName}. Note there is insufficient data published by {collegeName} on their ACT score benchmarks to evaluate the relative strength of your ACT score and so that has not been considered for this recommendation.</div>

  // actScoreOutput > 0
  const caseC2 = <div>{collegeName} is test-optional, but we <span className='fw-bold'>recommend to submit your SAT score ({satSuperScore})</span> with above-average confidence that it could strengthen your application. This recommendation is based on the strength of your SAT score, as compared to the benchmark values for {collegeName}. Our recommendation is further based on assessing the relative strength of your SAT score vs your ACT score ({actComposite}).</div>

  //// Case 13, Submit SAT score for test optional school when SAT and ACT are equally strong
  const case3 = <div>{collegeName} is test-optional, but we <span className='fw-bold'>recommend to submit your SAT score ({satSuperScore})</span> with above-average confidence that it could strengthen your application. This recommendation is based on the strength of your SAT score as compared to the benchmark values for {collegeName}. Note that we estimate your <span className='fw-bold'>ACT score ({actComposite}) is equally strong as your SAT score</span> and therefore you may wish to consider submitting both scores. Our primary recommendation gives slight preference to the SAT score because the SAT is more commonly submitted by recent enrollees at {collegeName}.</div>

  //////////////////////
  //  END EDIT TEXT  //
  ////////////////////

  let subcase = 'a1'
  let append = false
  let case3Check = false

  const determineSubCase1 = (userSATScoreOutput, userACTScoreOutput) => {
    switch (true) {
      case userACTScoreOutput == -1:
        subcase = 'a1'
        break;
      case userACTScoreOutput == -2:
        subcase = 'b1'
        break;
      case userACTScoreOutput > 0:
        subcase = 'c1'
        break;
      default:
        subcase = subcase
    }

    userSATScoreOutput < 40 ? append = true : append = false
  }

  const determineSubCase2 = (userACTScoreOutput) => {

    switch (true) {
      case userACTScoreOutput == -1:
        subcase = 'a2'
        break;
      case userACTScoreOutput == -2:
        subcase = 'b2'
        break;
      case userACTScoreOutput > 0:
        subcase = 'c2'
        break;
      default:
        subcase = subcase
    }
  }

  const checkCase = (userSATScoreOutput, userACTScoreOutput, collegeCDS) => {

    if ((userSATScoreOutput == userACTScoreOutput) && (userSATScoreOutput >= 60)) {

      let submitSATPercent = collegeCDS[0].submitSATPercent
      let submitACTPercent = collegeCDS[0].submitACTPercent

      submitSATPercent >= submitACTPercent ? case3Check = true : case3Check = false
    }

    else {
      testPolicy == 1 ? determineSubCase1(userSATScoreOutput, userACTScoreOutput) : determineSubCase2(userACTScoreOutput)

    }
  }

  checkCase(userSATScoreOutput, userACTScoreOutput, collegeCDS)

  // Test Policy 1 Components to Render

  const a1False = (
    <div>{caseA1}</div>
  )

  const a1True = (
    <div className="d-flex flex-column">
      <div>{caseA1}</div>
      <div>{noteA1}</div>
    </div>
  )

  const b1 = (
    <div>{caseB1}</div>
  )

  const c1False = (
    <div>{caseC1}</div>
  )

  const c1True = (
    <div className="d-flex flex-column">
      <div>{caseC1}</div>
      <div>{noteC1}</div>
    </div>
  )

  // Test Policy 2 Components to Render

  const a2 = (
    <div>{caseA2}</div>
  )

  const b2 = (
    <div>{caseB2}</div>
  )

  const c2 = (
    <div>{caseC2}</div>
  )

  // Test Policy 2, satScoreOutput == actScoreOutput, satScoreOutput >= 60, submitSATPercent >= submitACTPercent
  const c3 = (
    <div>{case3}</div>
  )

  const returnExplanationTestPolicy1 = (subcase) => {
    switch (subcase) {
      case 'a1':
        return append ? a1True : a1False
      case 'b1':
        return b1
      case 'c1':
        return append ? c1True : c1False
      default:
        return <div> - </div>
    }
  }

  const returnExplanationTestPolicy2 = (subcase) => {
    switch (subcase) {
      case 'a2':
        return a2
      case 'b2':
        return b2
      case 'c2':
        return c2
      default:
        return <div> - </div>
    }
  }

  return testPolicy == 1 ? (
    <div>
      {returnExplanationTestPolicy1(subcase)}
    </div>
  ) : (
    <div>
      {case3Check ? c3 : returnExplanationTestPolicy2(subcase)}
    </div>
  )
}


export { SubmitSAT }
