import LoadingIndicatorGIF from "../../custom_assets/dca-loading-indicator.gif"

const LoadingSpinner = ({ title }) => {

  return (
    <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginLeft: '300px' }}>
      <div className='d-flex flex-column justify-content-center'>
        <img src={LoadingIndicatorGIF} alt='LoadingIndicator' className="dca-loading-indicator-image" />
        <div
          style={{
            textAlign: 'center',
            marginTop: '40px'
          }}>Loading {title} ... </div>
      </div>
    </div>
  )
}

export { LoadingSpinner }