import { useEffect } from "react";
import { useLocation } from "react-router";

const ECAgeSearch = ({ ageSelect, setAgeSelect }) => {

  const location = useLocation()
  const ageOptions = [
    { value: 0, label: 'Any' },
    { value: 6, label: '6' },
    { value: 7, label: '7' },
    { value: 8, label: '8' },
    { value: 9, label: '9' },
    { value: 10, label: '10' },
    { value: 11, label: '11' },
    { value: 12, label: '12' },
    { value: 13, label: '13' },
    { value: 14, label: '14' },
    { value: 15, label: '15' },
    { value: 16, label: '16' },
    { value: 17, label: '17' },
    { value: 18, label: '18' },
    { value: 19, label: '19' },
    { value: 20, label: '20' },
  ]

  const handleAgeSelectChange = (event) => {
    let res = event.target.value;
    setAgeSelect(parseInt(res))
  }

  useEffect(() => {

    const params = new URLSearchParams(location.search);
    const ageParam = params.get('age');

    if (ageParam) {
      setAgeSelect(parseInt(ageParam));
    }

    else {
      setAgeSelect(0)
    }
  }, [location.search]);

  return (
    <div className='mt-4 d-flex flex-column'>
      <div className='dca-aside-header'>CURRENT AGE</div>
      <select
        className='form-select form-select-solid'
        data-control="select2"
        value={ageSelect}
        onChange={handleAgeSelectChange}
        style={{ cursor: 'pointer' }}
      >
        {ageOptions.map((res, index) => (
          <option
            key={index}
            value={res.value}>
            {res.label}
          </option>
        ))}
      </select>
    </div>
  )
}

export { ECAgeSearch }