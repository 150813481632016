const DoNotSubmit = ({ collegeName, userACTScoreOutput, userSATScoreOutput, satSuperScore, actComposite, testPolicy }) => {

  ////////////////
  // EDIT TEXT //
  //////////////

  //// Case 3, College is test-blind
  // Condition: If college’s {testPolicy} = 3, then recommendation = 5 (Do not submit)
  const case3 = <div>{collegeName} has indicated they <span className='fw-bold'>do not consider SAT or ACT test scores</span> for admission, so test scores should not be submitted regardless of your individual score(s).</div>

  //// Case 9 (SAT do not submit for test optional school)
  // Condition: If college’s {testPolicy} = 2, if satScoreOuput > actScoreOutput, satScoreOutput < 40, recommendation = 5 (do not submit)
  //// SAT do not submit for test optional school ////
  // actScoreOutput = -1
  const SATcaseA = <div>We estimate your SAT score ({satSuperScore}) is <span className='fw-bold'>below the median value</span> for {collegeName}, and therefore we do not have high confidence that submitting your SAT score will strengthen your application. Because {collegeName} is test-optional, we recommend not submitting your current scores, and you should <span className='fw-bold'>consider trying to improve your score, or exploring the ACT</span>. Of course, each student should make an individual decision in the context of their overall application.</div>

  const SATcaseB = <div>We estimate your SAT score ({satSuperScore}) is <span className='fw-bold'>below the median value</span> for {collegeName}, and therefore we do not have high confidence that submitting your SAT score will strengthen your application. Because {collegeName} is test-optional, we recommend not submitting your current scores, and you should <span className='fw-bold'>consider trying to improve your score</span>. Of course, each student should make an individual decision in the context of their overall application. Note there is insufficient data published by {collegeName} on their ACT score benchmarks to evaluate the relative strength of your ACT score and so that has not been considered for this recommendation.</div>

  const SATcaseC = <div>We estimate your SAT score ({satSuperScore}) to be stronger than your ACT score ({actComposite}), as compared to the respective benchmarks published by {collegeName}. Therefore we prioritize consideration of submitting your SAT score. However, we estimate your SAT score is <span className='fw-bold'>below the median value</span> for {collegeName}, and therefore we do not have high confidence that submitting your SAT score will definitely strengthen your application. Because {collegeName} is test-optional, we recommend not submitting your current scores, and you should <span className='fw-bold'>consider trying to improve your scores</span>. Of course, each student should make an individual decision in the context of their overall application.</div>

  //// Case 12 (ACT do not submit for test optional school)
  // Condition: If college’s {testPolicy} = 2, if satScoreOuput < actScoreOutput, actScoreOutput < 40, recommendation = 5 (do not submit)
  //// ACT do not submit for test optional school ////
  // satScoreOutput = -1
  const ACTcaseA = <div>We estimate your ACT score ({actComposite}) is <span className='fw-bold'>below the median value</span> for {collegeName}, and therefore we do not have high confidence that submitting your ACT score will strengthen your application. Because {collegeName} is test-optional, we recommend not submitting your current scores, and you should <span className='fw-bold'>consider trying to improve your score, or exploring the SAT</span>. Of course, each student should make an individual decision in the context of their overall application.</div>

  // satScoreOutput = -2
  const ACTcaseB = <div>We estimate your ACT score ({actComposite}) is <span className='fw-bold'>below the median value</span> for {collegeName}, and therefore we do not have high confidence that submitting your ACT score will strengthen your application. Because {collegeName} is test-optional, we recommend not submitting your current scores, and you should <span className='fw-bold'>consider trying to improve your score</span>. Of course, each student should make an individual decision in the context of their overall application. Note there is insufficient data published by {collegeName} on their SAT score benchmarks to evaluate the relative strength of your SAT score and so that has not been considered for this recommendation.</div>

  // satScoreOutput > 0
  const ACTcaseC = <div>We estimate your ACT ({actComposite}) score to be stronger than your SAT score ({satSuperScore}), as compared to the respective benchmarks published by {collegeName}. Therefore we prioritize consideration of submitting your ACT score. However, we estimate your ACT score is <span className='fw-bold'>below the median value</span> for {collegeName}, and therefore we do not have high confidence that submitting your ACT score will definitely strengthen your application. Because {collegeName} is test-optional, we recommend not submitting your current scores, and you should <span className='fw-bold'>consider trying to improve your scores</span>. Of course, each student should make an individual decision in the context of their overall application.</div>

  //// Case 16, Both tests equal, do not submit, for test optional school
  // Condition: If college’s {testPolicy} = 2, if satScoreOutput = actScoreOutput, satScoreOutput < 40, recommendation = 5 (do not submit)
  const case16 = <div>We estimate your SAT score ({satSuperScore}) and ACT score ({actComposite}) are approximately equally strong relative to the respective benchmarks for {collegeName}. Both scores are <span className='fw-bold'>below the corresponding median values</span> for this school, and because {collegeName} is test-optional, we recommend not submitting your current scores. Of course, each student should make an individual decision in the context of their overall application. You should <span className='fw-bold'>consider trying to improve either or both of your scores</span> before deciding to submit.</div>

  //////////////////////
  //  END EDIT TEXT  //
  ////////////////////

  let subcase = 'a'
  let caseCheck = false
  let outputGreater = true

  userSATScoreOutput > userACTScoreOutput ? outputGreater = true : outputGreater = false

  const determineSATSubCase = (userACTScoreOutput) => {
    switch (true) {
      case userACTScoreOutput == -1:
        subcase = 'a'
        break;
      case userACTScoreOutput == -2:
        subcase = 'b'
        break;
      case userACTScoreOutput > 0:
        subcase = 'c'
        break;
      default:
        subcase = subcase
    }
  }

  const determineACTSubCase = (userSATScoreOutput) => {
    switch (true) {
      case userSATScoreOutput == -1:
        subcase = 'a'
        break;
      case userSATScoreOutput == -2:
        subcase = 'b'
        break;
      case userSATScoreOutput > 0:
        subcase = 'c'
        break;
      default:
        subcase = subcase
    }
  }

  const checkCase = (testPolicy, userSATScoreOutput, userACTScoreOutput) => {
    if ((testPolicy == 2) && (userSATScoreOutput == userACTScoreOutput && userSATScoreOutput < 40)) {
      caseCheck = true
    }

    else {

      userSATScoreOutput > userACTScoreOutput ? outputGreater = true : outputGreater = false

      outputGreater ? determineSATSubCase(userACTScoreOutput) : determineACTSubCase(userSATScoreOutput)

    }

  }

  checkCase(testPolicy, userSATScoreOutput, userACTScoreOutput)

  const case3Div = (
    <div>{case3}</div>
  )

  const aSAT = (
    <div>{SATcaseA}</div>
  )

  const bSAT = (
    <div>{SATcaseB}</div>
  )

  const cSAT = (
    <div>{SATcaseC}</div>
  )

  const aACT = (
    <div>{ACTcaseA}</div>
  )

  const bACT = (
    <div>{ACTcaseB}</div>
  )

  const cACT = (
    <div>{ACTcaseC}</div>
  )

  const c16 = (
    <div>{case16}</div>
  )

  const returnExplanationSAT = (subcase) => {
    switch (subcase) {
      case 'a':
        return aSAT
      case 'b':
        return bSAT
      case 'c':
        return cSAT
      default:
        return <div> - </div>
    }
  }

  const returnExplanationACT = (subcase) => {
    switch (subcase) {
      case 'a':
        return aACT
      case 'b':
        return bACT
      case 'c':
        return cACT
      default:
        return <div> - </div>
    }
  }

  const returnDoNotSubmitText = () => {

    if (testPolicy === 3) {
      return case3Div
    }

    else {
      return caseCheck ? (
        <div>
          {c16}
        </div>
      ) : (
        <div>
          {outputGreater ? returnExplanationSAT(subcase) : returnExplanationACT(subcase)}
        </div>
      )
    }
  }

  return returnDoNotSubmitText()
}

export { DoNotSubmit }
