import { LoadingSpinner } from "../../LoadingSpinner";
import { numberConverter, titleSpace } from "../../dictionary";

const AdmissionCorrelation = ({ result, returnCollegeGroupID }) => {

  //When result is not set, inform that it is loading
  if (!result || !result.insights || !result.insights['College Group Data']) {
    return <LoadingSpinner title={'Admission Correlation'} />;
  }

  let collegeGroupIDs = ["1", "2", "8", "9", "10", "11", "12", "13"]

  let collegeGroupData: any = result.insights['College Group Data'].filter((item) => collegeGroupIDs.includes(item.collegeGroupID))

  const returnNumber = (num) => {

    if (!num) {
      return '-'
    }

    return num

  }

  const returnPercent = (res) => {

    if (!res) {
      return '-'
    }

    return `${((res) * 100).toFixed(0)}%`
  }

  // const returnTotalSampleSize = (arr) => {
  //   let total: any = 0
  //   arr.map((res) => total = total + parseInt(res.number))
  //   return numberConverter(total)
  // }

  const returnTotalSampleSize = (result) => {
    if (!result || !result.insights || !result.insights['Core Data']) {
      return '-'
    }

    return result.insights['Core Data'][0].sampleSize
  }

  return (

    <div style={{ marginTop: '40px' }}>
      <div className='card shadow'>
        <div className='fw-bold' style={{ fontSize: '26px', paddingTop: '40px', paddingLeft: '40px' }}>Matriculation Outcomes For Activity Alumni - By College Group</div>
        <div>
          <table className="table table-borderless table-sm table-responsive" style={{ width: '65%', marginLeft: '40px', marginBottom: '20px', marginTop: '30px', borderSpacing: '0 15px', borderCollapse: 'separate' }}>
            <thead>
              <tr className="fw-bold">
                <th scope="col" style={{ letterSpacing: titleSpace }}>INDEX</th>
                <th scope="col" style={{ letterSpacing: titleSpace }}># STUDENTS</th>
                <th scope="col" style={{ letterSpacing: titleSpace }}>% STUDENTS</th>
                {/* <th scope="col" style={{ letterSpacing: titleSpace }}>PERCENTILE</th> */}
              </tr>
            </thead>
            <tbody>

              {collegeGroupData.map((res, index) => (
                <tr key={index} style={{ borderBottom: '1px solid rgba(207,207,207,1)' }}>
                  <th scope='row' className="text-start fw-bold">{returnCollegeGroupID(res.collegeGroupID) == 'HYPSM' ? `Top 5 ("${returnCollegeGroupID(res.collegeGroupID)}")` : returnCollegeGroupID(res.collegeGroupID)}</th>
                  <td className="fw-bold">{returnNumber(res.number)}</td>
                  <td className="fw-bold">{returnPercent(res.percent)}</td>
                  {/* <td className="fw-bold">{res.index ? `${res.index}%` : '-'}</td> */}
                </tr>
              ))}

              <tr>
                <th scope='row' className="text-start fw-bold">Total Sample Size</th>
                <td className="fw-bold">{returnTotalSampleSize(result)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )

}

export { AdmissionCorrelation }
