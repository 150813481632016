import noUiSlider from 'nouislider'
import { numberConverter } from '../../dictionary';
import { useEffect, useRef } from 'react'
import { useLocation } from 'react-router';


const ECCostSearch = ({ setCostSelect }) => {

  const location = useLocation()
  const costSliderRef = useRef(null);

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let maxCostParam = params.get('maxCost');

    if (maxCostParam) {
      setCostSelect(parseInt(maxCostParam))
    }

    else {
      setCostSelect(20000)
    }

  }, [location.search]);

  useEffect(() => {

    if (!costSliderRef.current) {

      const slider: any = document.querySelector("#kt_slider_cost");
      let params = new URLSearchParams(location.search);
      let maxCostParam = params.get('maxCost');

      noUiSlider.create(slider, {
        start: [maxCostParam ? parseInt(maxCostParam) : 20000],
        connect: true,
        step: 100,
        range: {
          "min": 0,
          "max": 20000
        },
        tooltips: {
          to: function (value) {
            return `$${numberConverter(value)}`;
          }
        },
      });

      slider.noUiSlider.on("set", function (values) {
        setCostSelect(parseInt(values[0]))
      });

      costSliderRef.current = slider;

    }


  }, [])

  return (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '10px' }}>
      <div className='dca-aside-header'>MAXIMUM COST</div>
      <div className="mb-0" style={{ marginTop: '50px' }}>
        <div id="kt_slider_cost"></div>
      </div>
    </div>
  )
}

export { ECCostSearch }