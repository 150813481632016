import Chart from 'react-apexcharts'

const AreaChart = ({ chartId, xaxis, seriesData, seriesName }) => {

  const returnYMax = (chartId) => {
    switch (true) {
      case chartId === "Average GPA":
        return 5.0
      default:
        return seriesData[0] * 1.5
    }
  }

  return (
    <Chart
      options={{
        chart: {
          id: chartId
        },
        xaxis: {
          categories: xaxis,
          labels: {
            show: true,
            style: {
              colors: '#9AA2B6',
            },
          }
        },
        tooltip: {
          enabled: false,
        },
        yaxis: {
          min: 0.00,
          max: returnYMax(chartId),
          forceNiceScale: true,
          labels: {
            style: {
              colors: ['#9AA2B6'],
            },
            formatter: function (val) {
              return val.toFixed(2);
            }
          }
        },
        colors: ["#90F892"],
        dataLabels: {
          enabled: true,
          background: {
            borderWidth: 0,
            borderRadius: 5,
            foreColor: '#000',
          },
          formatter: function (val: number, opts) {
            if (val != null && chartId != 'Average GPA') {
              return val + "%";
            }

            else if (val != null) {
              return val.toFixed(2)
            }

            else {
              return ""
            }
          },
        },
        fill: {
          colors: ['#DAF8B9'],
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.6,
            opacityTo: 0.95,
            stops: [0, 95, 100]
          }
        },
        stroke: {
          curve: 'smooth'
        }
      }}
      series={[
        {
          name: seriesName,
          data: seriesData
        }
      ]}
      type='area'
      // width={'450'}
      height={'300'}
    />
  )

}

export { AreaChart }
