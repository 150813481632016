import { useState, useEffect } from "react";
import { testPolicy } from "../../dictionary";
import { useLocation } from 'react-router-dom';

const TestPolicy = ({ setTestPolicyFilter, setTestPolicyParams }) => {

  const location = useLocation()
  const [testPolicySelect, setTestPolicySelect] = useState<any>('All')

  const handleTestPolicyChange = (event) => {
    const selectedTestPolicy = event.target.value
    setTestPolicySelect(selectedTestPolicy)
    setTestPolicyFilter(testPolicy[selectedTestPolicy])
  }

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let testPolicyParam = params.get('testPolicy');

    if (testPolicyParam) {
      setTestPolicySelect(testPolicyParam)
      setTestPolicyFilter(testPolicy[testPolicyParam])
    }

    else {
      setTestPolicySelect('All')
      setTestPolicyFilter('')
    }

  }, [location.search]);

  useEffect(() => {

    let params = new URLSearchParams(location.search);

    if (testPolicySelect != 'All') {
      params.set('testPolicy', testPolicySelect)
    } else {
      params.delete('testPolicy')
    }

    setTestPolicyParams(`${params.toString()}`)

  }, [testPolicySelect])

  return <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
    <div className='dca-aside-header'>TEST POLICY</div>
    <select
      className='form-select form-select-solid'
      data-control="select2"
      value={testPolicySelect}
      onChange={handleTestPolicyChange}
      style={{ cursor: 'pointer' }}
    >
      <option value={Object.keys(testPolicy)[0]}>{Object.keys(testPolicy)[0]}</option>
      <option value={Object.keys(testPolicy)[1]}>{Object.keys(testPolicy)[1]}</option>
      <option value={Object.keys(testPolicy)[2]}>{Object.keys(testPolicy)[2]}</option>
      <option value={Object.keys(testPolicy)[3]}>{Object.keys(testPolicy)[3]}</option>
    </select>
  </div>
}

export { TestPolicy }