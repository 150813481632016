// import Chart from 'react-apexcharts'
import { BarChart } from './Charts/BarChart'
import { AreaChart } from './Charts/AreaChart'
import { useEffect } from 'react'

const GPAData = ({ cds }) => {

  useEffect(() => {
    console.log('cds', cds)
  }, [cds])

  let latestYear = cds.length - 1

  let averageGPA = (<div></div>)
  let classRankDistribution = (<div></div>)
  let GPADistribution = (<div></div>)

  //Average GPA
  let averageXAxis: any[] = []
  let averageGPAArray: any[] = []

  //Class Rank Distribution
  const classRankXAxis = ['Top tenth', 'Top quarter', 'Top half', 'Bottom half']
  let [classRankTopTenth, classRankTopQuarter, classRankTopHalf, classRankBottomHalf]: any = []
  let classRankArray: any[] = []

  //GPA Distribution
  let [gpa40, gpa375399, gpa350374, gpa325349, gpa300324, gpa250299, gpa200249, gpa100199, gpaBelow100]: any = [null, null, null, null, null, null, null, null, null]
  let gpaDistributionArray = [gpa40, gpa375399, gpa350374, gpa325349, gpa300324, gpa250299, gpa200249, gpa100199, gpaBelow100]
  const gpaDistributionXAxis = ['4.0', '3.75 - 3.99', '3.50 - 3.74', '3.25 - 3.49', '3.00 - 3.24', '2.50 - 2.99', '2.0 - 2.49', '1.0 - 1.99', 'Below 1']

  const returnClassRankValues = (fieldVal) => {
    if (fieldVal != undefined || fieldVal != null) {
      return `${(fieldVal * 100).toFixed(0)}%`
    }

    else {
      return null
    }
  }

  const returnGPAValues = (fieldVal) => {
    if (fieldVal != undefined || fieldVal != null) {
      return `${(fieldVal * 100).toFixed(0)}%`
    }

    else {
      return null
    }
  }

  if (cds != null && cds[latestYear] != null) {

    cds.forEach((res) => {
      averageXAxis.push(`${(res.year)} - ${res.year + 1}`);

      if (res.averageGPA != null || res.averageGPA != undefined) {
        averageGPAArray.push(res.averageGPA);
      }
    })

    classRankTopTenth = returnClassRankValues(cds[latestYear].classRankTopTenth)

    classRankTopQuarter = returnClassRankValues(cds[latestYear].classRankTopQuarter)

    classRankTopHalf = returnClassRankValues(cds[latestYear].classRankTopHalf)

    classRankBottomHalf = returnClassRankValues(cds[latestYear].classRankBottomHalf)

    classRankArray = [classRankTopTenth, classRankTopQuarter, classRankTopHalf, classRankBottomHalf]

    gpa40 = returnGPAValues(cds[latestYear].gpa40)
    gpa100199 = returnGPAValues(cds[latestYear].gpa100199)
    gpa200249 = returnGPAValues(cds[latestYear].gpa200249)
    gpa250299 = returnGPAValues(cds[latestYear].gpa250299)
    gpa300324 = returnGPAValues(cds[latestYear].gpa300324)
    gpa325349 = returnGPAValues(cds[latestYear].gpa325349)
    gpa350374 = returnGPAValues(cds[latestYear].gpa350374)
    gpa375399 = returnGPAValues(cds[latestYear].gpa375399)
    gpaBelow100 = returnGPAValues(cds[latestYear].gpaBelow100)
    gpaDistributionArray = [gpa40, gpa375399, gpa350374, gpa325349, gpa300324, gpa250299, gpa200249, gpa100199, gpaBelow100]

  }

  averageGPA = cds ? (
    <div>
      <div className='fw-bold' style={{ marginLeft: '15px' }}>Average GPA</div>
      <AreaChart
        chartId={"Average GPA"}
        seriesName={"GPA"}
        seriesData={averageGPAArray}
        xaxis={averageXAxis}
      />
    </div>) : (<div></div>)

  classRankDistribution = (
    <div>
      <div className='fw-bold' style={{ marginLeft: '15px' }}>Class Rank Distribution ({averageXAxis[latestYear]})</div>
      <BarChart
        chartId={'Class Rank Distribution'}
        xaxis={classRankXAxis}
        seriesName={"Class Rank"}
        seriesData={classRankArray}
      />
    </div>
  )

  GPADistribution = (
    <div>
      <div className='fw-bold' style={{ marginLeft: '15px' }}>GPA Distribution ({averageXAxis[latestYear]})</div>
      <BarChart
        chartId={"GPA Distribution"}
        seriesName={"GPA Ranges"}
        xaxis={gpaDistributionXAxis}
        seriesData={gpaDistributionArray}
      />
    </div>
  )

  return cds.length > 0 ? (
    <div className='d-flex flex-column card shadow' style={{ marginTop: '40px' }}>

      <div className='d-flex flex-row' style={{ marginTop: '20px' }}>
        <div style={{ width: '50%', margin: '30px 0 0 30px' }}>{averageGPA}</div>

        <div style={{ width: '50%', margin: '30px 30px 0 30px' }}>{classRankDistribution}</div>
      </div>
      <div style={{ width: '50%', margin: '30px 0 0 30px' }}>
        {GPADistribution}
      </div>
    </div>
  ) : (<div>Loading college GPA data ... </div>)



}

export { GPAData }
