import { useEffect } from "react"
import { useLocation } from "react-router"

const AppDetails = ({ filteredColleges, setEarlyAppOptionFilter, setCoalitionFilter, setCommonAppFilter, setConsidersInterestFilter, setIndividualAppFilter, earlyApplicationOfferedCount, commonAppCount, coalitionCount, individualAppCount, considersInterestCount, earlyAppOptionFilter, commonAppFilter, coalitionFilter, individualAppFilter, considersInterestFilter, setApplicationDetailsParams }) => {

  const location = useLocation()

  const handleCheckChange = (event) => {
    let checkStatus = event.target.checked
    return checkStatus
  }

  const checkCheckCase = (filterVal) => {
    if (filterVal === true) {
      return true
    }

    else {
      return false
    }
  }

  // Old array with Individual App
  // let appDetailsArray = [
  //   { name: 'Early App Checkbox', onChange: setEarlyAppOptionFilter, spanText: 'Early Application Option', filterCount: earlyApplicationOfferedCount },
  //   { name: 'Common App Checkbox', onChange: setCommonAppFilter, spanText: 'On Common App', filterCount: commonAppCount },
  //   { name: 'Coalition App Checkbox', onChange: setCoalitionFilter, spanText: 'On Coalition App', filterCount: coalitionCount },
  //   { name: 'Individual App Checkbox', onChange: setIndividualAppFilter, spanText: 'Individual App', filterCount: individualAppCount },
  //   { name: 'Considers Interest Checkbox', onChange: setConsidersInterestFilter, spanText: 'Considers Interest', filterCount: considersInterestCount }
  // ]

  let appDetailsArray = [
    { name: 'Early App Checkbox', onChange: setEarlyAppOptionFilter, spanText: 'Early Application Option', filterCount: earlyApplicationOfferedCount, filter: earlyAppOptionFilter },
    { name: 'Common App Checkbox', onChange: setCommonAppFilter, spanText: 'On Common App', filterCount: commonAppCount, filter: commonAppFilter },
    { name: 'Coalition App Checkbox', onChange: setCoalitionFilter, spanText: 'On Coalition App', filterCount: coalitionCount, filter: coalitionFilter },
    { name: 'Considers Interest Checkbox', onChange: setConsidersInterestFilter, spanText: 'Considers Interest', filterCount: considersInterestCount, filter: considersInterestFilter }
  ]

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let appDetailsParam = params.get('appDetails');

    if (appDetailsParam) {
      const appDetailsSplit = appDetailsParam.split(',').map(app => app)

      appDetailsSplit.map((app) => {
        switch (true) {
          case app === 'earlyAppOption':
            setEarlyAppOptionFilter(true)
            break;
          case app === 'commonApp':
            setCommonAppFilter(true)
            break;
          case app === 'coalition':
            setCoalitionFilter(true)
            break;
          case app === 'considersInterest':
            setConsidersInterestFilter(true)
            break
        }
      })

    }

  }, [location.search]);

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let res: string[] = []

    if (earlyAppOptionFilter === true) {
      res.push('earlyAppOption')
    }

    if (commonAppFilter === true) {
      res.push('commonApp')
    }

    if (coalitionFilter === true) {
      res.push('coalition')
    }

    if (considersInterestFilter === true) {
      res.push('considersInterest')
    }

    if (res.length > 0) {
      const appDetails = res.map((checked) => checked).join(',')
      params.set('appDetails', appDetails)
    } else {
      params.delete('appDetails')
    }

    setApplicationDetailsParams(`${params.toString()}`)

  }, [earlyAppOptionFilter, commonAppFilter, coalitionFilter, individualAppFilter, considersInterestFilter])

  return (
    <div className='mt-4 d-flex flex-column' style={{ marginTop: '15px' }}>
      <div className='dca-aside-header'>APPLICATION DETAILS</div>
      <div className="checkbox-list d-flex flex-column" style={{ marginTop: '5px' }}>

        {appDetailsArray.map((res, index) => (
          <label key={index} className="d-flex justify-content-between" style={{ cursor: 'pointer', marginTop: index !== 0 ? '4px' : '' }}>
            <div>
              <input
                type="checkbox"
                className="dca-checkbox"
                checked={checkCheckCase(res.filter)}
                name={res.name}
                onChange={(event) => res.onChange((handleCheckChange(event)))}
                style={{ cursor: 'pointer' }}
              />
              <span className='ms-3 me-3'>{res.spanText}</span>
            </div>
            <span className='text-muted fs-7 fw-bolder'>{res.filterCount}</span>
          </label>
        ))}

      </div>
    </div>
  )
}

export { AppDetails }
