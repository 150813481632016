import Chart from 'react-apexcharts'

const ScoreSubmission = ({ modalData }) => {

  let cdsArr: any = []
  let xAxisArray: any = []
  let submitSATArray: any = []
  let submitACTArray: any = []
  let totalArray: any = []

  if (modalData && typeof modalData === 'object' && modalData.college_cds) {

    modalData.college_cds.forEach((res, index) => {
      if (index < 3) {
        cdsArr.push(res);
        cdsArr.sort((a, b) => a.year - b.year);
      }
    })

    cdsArr.map((cds) => {
      xAxisArray.push(`${cds.year} - ${cds.year + 1}`);
      submitSATArray.push(cds.submitSATPercent*100);
      submitACTArray.push(cds.submitACTPercent*100);
      totalArray.push((cds.submitSATPercent + cds.submitACTPercent)*100)
    })

  }

return (
    <div className="card card-custom shadow p-4" style={{ marginTop: '40px' }}>

      <div style={{ padding: '30px' }}>
        <div className='fw-bold' style={{ fontSize: '22px' }}>Score Submission Data</div>

        <Chart
          options={{
            chart: {
              id: "Total Admit Rate"
            },
            xaxis: {
              categories: xAxisArray,
              labels: {
                show: true,
                style: {
                  colors: '#9AA2B6',
                },
              }
            },
            tooltip: {
              enabled: false,
            },
            yaxis: {
              min: 0.00,
              max: totalArray[0] * 1.1,
              forceNiceScale: false,
              labels: {
                style: {
                  colors: ['#9AA2B6'],
                },
                formatter: function (val) {
                  return val.toFixed(0) + "%";
                }
              }
            },
            colors: ["#90F892", "#FFEF68", "#97E6FF"],
            legend: {
				show: true,
				onItemClick: {
					toggleDataSeries: false
				},
				onItemHover: {
					highlightDataSeries: true
				},					
			},
			dataLabels: {
              enabled: true,
              background: {
                borderWidth: 0,
		borderRadius: 5,
                foreColor: '#000',
              },
              formatter: function (val, opts) {
                //return val + "%";
		return val.toLocaleString("en-US") + "%";
              },
            },
            fill: {
              type: "solid",
              gradient: {
                shadeIntensity: 1,
                opacityFrom: 0.6,
                opacityTo: 0.9,
                stops: [0, 90, 100]
              }
            },
            stroke: {
              curve: 'smooth'
            }
          }}
          series={[
            {
              name: "% students submitted SAT score",
              data: submitSATArray
            },
            {
              name: "% students submitted ACT score",
              data: submitACTArray
            },
            {
              name: "Maximum % students submitted SAT and/or ACT score (some students may submit both)",
              data: totalArray
            }
          ]}
          type='line'
          width={'600'}
        />
      </div>
    </div>
  )
 
}

export { ScoreSubmission }
