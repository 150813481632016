import { RankColorRange } from "../../RankColorRange"
import { RankIndicator } from "../../RankIndicator"
import { TooltipComponent } from "../../Tooltip"

const IndustryRankings = ({ college }) => {

  let metaRank: any = null
  let currentYear = new Date().getFullYear()
  let [nationalUniversity, liberalArts, forbes, wsj, niche, arwu, qs, times, waMonthlyNational, waMonthlyLiberal]: any = [0, 0, 0, 0, 0, 0, 0, 0]
  let rankingsArray = [
    { 'US News & World Report National Universities (2024)': nationalUniversity },
    { 'US News & World Report National Liberal Arts Colleges (2024)': liberalArts },
    { 'Forbes Top Colleges (2023)': forbes },
    { 'Wall Street Journal Best Colleges In the U.S. (2024)': wsj },
    { 'Niche Best Colleges In America (2024)': niche },
    { 'Academic Ranking of World Universities (2023)': arwu },
    { 'QS World University Rankings (2024)': qs },
    { 'Times Higher Education World University Rankings (2024)': times },
    { 'Washington Monthly National Universities (2023)': waMonthlyNational },
    { 'Washington Monthly Liberal Arts Colleges (2023)': waMonthlyLiberal }
  ]

  if (college) {
    metaRank = college.metaRank
    nationalUniversity = college.usNewsNationalRank
    liberalArts = college.usNewsLiberalArtsRank
    forbes = college.forbesRank
    wsj = college.wsjRank
    niche = college.nicheRank
    arwu = college.arwuRank
    qs = college.qsRank
    times = college.timesHigherEdRank
    waMonthlyNational = college.waMonthlyNationalRank
    waMonthlyLiberal = college.waMonthlyLiberalArtsRank

    rankingsArray = [
      { 'US News & World Report National Universities (2024)': nationalUniversity },
      { 'US News & World Report National Liberal Arts Colleges (2024)': liberalArts },
      { 'Forbes Top Colleges (2023)': forbes },
      { 'Wall Street Journal Best Colleges In the U.S. (2024)': wsj },
      { 'Niche Best Colleges In America (2024)': niche },
      { 'Academic Ranking of World Universities (2023)': arwu },
      { 'QS World University Rankings (2024)': qs },
      { 'Times Higher Education World University Rankings (2024)': times },
      { 'Washington Monthly National Universities (2023)': waMonthlyNational },
      { 'Washington Monthly Liberal Arts Colleges (2023)': waMonthlyLiberal }
    ]
  }


  return (
    <div className="card shadow" style={{ marginTop: '40px' }}>
      <div className="d-flex flex-row align-items-end" style={{ marginLeft: '30px', marginTop: '30px' }}>

        <div className="d-flex flex-row me-4 align-items-end">
          <h3>Meta Rank:</h3>
          <div className="ms-4"><RankIndicator rank={metaRank} /></div>
          <div className="d-flex justify-content-center align-items-center align-self-center ms-2"><TooltipComponent content={`Metarank is our computed overall rank for ${college.name} based on aggregation of the industry ranks outlined below.`} /></div>
        </div>
        <div style={{ marginLeft: '50px' }}><RankColorRange /></div>
      </div>

      <ul className='d-flex flex-column justify-content-between' style={{ width: '40vw', marginTop: '30px', marginLeft: '15px', marginBottom: '80px' }}>
        <li className="mb-5 d-flex justify-content-end fw-bold" style={{ fontSize: 'smaller' }}>RANK</li>
        {rankingsArray.map((res, index) => (
          <li key={index} className='d-flex flex-column'>
            <div className='d-flex flex-row justify-content-between fw-bold'>
              <div className="align-self-center">{Object.keys(res)[0]}</div>
              <RankIndicator rank={rankingsArray[index][Object.keys(res)[0]]} />
            </div>
            <div className={index > rankingsArray.length - 2 ? 'm-2' : 'm-2 separator'}></div>
          </li>
        ))}
      </ul>
    </div>)

}



export { IndustryRankings }