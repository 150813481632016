import { useState, useEffect } from 'react'
import Select from 'react-select'
import { useLocation } from 'react-router';

const MajorCourseSearch = ({ courseOptions, setCourseSelect }) => {

  const location = useLocation()
  const [selectedCourses, setSelectedCourses] = useState<any>([]);

  const handleCourseSelectChange = (selectedCourses) => {
    setSelectedCourses(selectedCourses);
    // console.log('selectedCourses set to:', selectedCourses)
    let courseArr: any = []
    selectedCourses.map((college) =>
      courseArr.push(college.value))
    setCourseSelect(courseArr)
  }

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let coursesParam = params.get('highSchoolCourses');

    if (coursesParam && courseOptions) {
      let res: any[] = []
      coursesParam.split(',').map(val => {
        let courseObj = courseOptions.find((obj => obj.value === parseInt(val)))

        if (courseObj) {
          res.push(courseObj)
        }
      })

      if (res.length > 0) {
        // console.log('res passed to handleCourseSelectChange:', res)
        handleCourseSelectChange(res)
      }

      else {
        setSelectedCourses([])
      }
    }


    else {
      setSelectedCourses([])
    }

  }, [location.search, courseOptions]);


  return (
    <div className='mt-4 d-flex flex-column'>
      <div className='dca-aside-header'>RELATED HIGH SCHOOL COURSES</div>
      <Select
        options={courseOptions}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            border: 'transparent',
            backgroundColor: '#F9F9F9'
          })
        }}
        isMulti
        value={selectedCourses}
        onChange={handleCourseSelectChange}
      />
    </div>
  )
}

export { MajorCourseSearch }