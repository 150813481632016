import { useState, useEffect } from 'react'
import { useLocation } from 'react-router'
import { MajorNameSearch } from './components/MajorNameSearch'
import { MajorCollegeSearch } from './components/MajorCollegeSearch'
import { MajorCourseSearch } from './components/MajorCoursesSearch'
import { MajorCareerSearch } from './components/MajorCareersSearch'
import { MajorEarningsSearch } from './components/MajorEarningsSearch'
import { MajorPopularitySearch } from './components/MajorPopularitySearch'
import { MajorAreasSelect } from './components/MajorAreasSelect'
import { pageRoutes } from '../dictionary'
import { concatLocation, returnParameterString } from '../searchPageURLManager'
import { MajorOnlyInterests } from './components/MajorOnlyInterests'

const MajorSearchAside = ({ programData, interestCIP, careerOptions, careerCIP, nameOptions, collegeOptions, courseOptions, setNameFilter, setCollegeFilter, setRelatedCoursesFilter, setRelatedCareersFilter, setEarningsFilter, setPopularityFilter, setAreasFilter, majors, setFilteredResults, majorSearchLocation, setMajorSearchLocation, setSortKey, setSortOrder, sortKey, sortOrder, dataLoaded, onlyInterestsSelect, setOnlyInterestsSelect, setGuestClickShowOnlyInterest, showOnlyMyInterestsFilter, setShowOnlyMyInterestsFilter }) => {

  const location = useLocation()
  const [nameSelect, setNameSelect] = useState<any>([])
  const [collegeSelect, setCollegeSelect] = useState<any>([])
  const [coursesSelect, setCoursesSelect] = useState<any>([])
  const [careersSelect, setCareersSelect] = useState<any>([])
  const [earningsMin, setEarningsMin] = useState<any>(0)
  const [earningsMax, setEarningsMax] = useState<any>(250000)
  const [popularityMin, setPopularityMin] = useState<any>(0)
  const [popularityMax, setPopularityMax] = useState<any>(10)
  const [areasSelect, setAreasSelect] = useState<any>([])

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let sortParam = params.get('sort');

    if (sortParam) {
      const sorts = sortParam.split(',').map(res => res);
      setSortKey(sorts[0])
      setSortOrder(sorts[1])
    }

  }, [location.search]);

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let sortDetails: any = [sortKey, sortOrder]

    sortDetails = sortDetails.map((res) => res).join(',')
    params.set('sort', sortDetails)

    setSortParams(`${params.toString()}`)

  }, [sortKey, sortOrder])

  //// URL and filter store states
  const [sortParams, setSortParams] = useState('')
  const [majorNameParams, setMajorNameParams] = useState('')
  const [collegeNameParams, setCollegeNameParams] = useState('')
  const [hsCoursesParams, setHSCoursesParams] = useState('')
  const [careersParams, setCareersParams] = useState('')
  const [areasParams, setAreasParams] = useState('')
  const [earningsParams, setEarningsParams] = useState('')
  const [popularityParams, setPopularityParams] = useState('')
  const [onlyInterestsParams, setOnlyInterestsParams] = useState('')

  useEffect(() => {
    const returnNameFilter = (nameSelect) => {
      if (nameSelect.length !== 0) {

        let names: any[] = []
        nameSelect.forEach(obj => {
          names.push(obj.label)
        });

        setNameFilter((item) => {
          return item => names.includes(item.name)
        })
      }
      else {
        setNameFilter((item) => {
          return item => item
        })
      }
    };
    returnNameFilter(nameSelect)

    // Set URL params
    let params = new URLSearchParams(location.search);

    if (nameSelect.length > 0) {
      // const names = nameSelect.map((name) => name).join(',')
      // const ids: any[] = []
      // nameSelect.forEach(obj => {
      //   ids.push(obj.value)
      // });
      // const ids_string = ids.map((name) => name).join(',')
      const nameIDs = nameSelect.map(obj => obj.value).join(',');
      params.set('majorName', nameIDs)
    } else {
      params.delete('majorName')
    }

    setMajorNameParams(`${params.toString()}`)
  }, [nameSelect])

  useEffect(() => {
    const returnCollegeFilter = (collegeSelect) => {
      if (collegeSelect.length !== 0) {
        let programs: any = []
        programs = programData.filter((item) => collegeSelect.includes(item.college_id))

        let majorIds: any = []
        programs.map((res) => {
          majorIds.push(res.major_id)
        })

        majorIds = [...new Set(majorIds)]

        setCollegeFilter((item) => {
          return item => (item.id !== null && majorIds.includes(item.id))
        })

      }
      else {
        setCollegeFilter((item) => {
          return item => item
        })
      }
    };
    returnCollegeFilter(collegeSelect)

    // Set URL params
    let params = new URLSearchParams(location.search);

    if (collegeSelect.length > 0) {
      const colleges = collegeSelect.map((name) => name).join(',')
      params.set('collegeName', colleges)
    } else {
      params.delete('collegeName')
    }

    setCollegeNameParams(`${params.toString()}`)

  }, [collegeSelect])

  useEffect(() => {

    // console.log('courses selected', coursesSelect)
    // Set URL params
    let params = new URLSearchParams(location.search);

    if (coursesSelect.length > 0) {

      const courses = coursesSelect.map((name) => name).join(',')
      params.set('highSchoolCourses', courses)

      let ref: any = []
      let ciptIds: any = []
      ref = interestCIP

      ref = ref.filter((item) => (item.affinityScore !== null && coursesSelect.includes(item.interest_id)))

      ref.map((res) => {
        ciptIds.push(res.cipt_code)
      })

      ciptIds = [...new Set(ciptIds)]

      // console.log('ciptIds', ciptIds)

      if (ciptIds.length > 0) {
        setRelatedCoursesFilter((item) => {

          // if (item.cipt_code != null && item.cipt_code != undefined && item.secondaryCIPs != null && item.secondaryCIPs != undefined) {
          return item => (ciptIds.some((cip) => item.cipt_code == cip || item.cipt_code.startsWith(cip))) || (ciptIds.some((cip) => item.secondaryCIPs?.some((secondaryCIP) => secondaryCIP == cip || secondaryCIP.startsWith(cip))))
          // }

          // else {
          // return item => (ciptIds.some((cip) => item.cipt_code == cip || item.cipt_code.startsWith(cip)))
          // }

        })
      }

      else {
        setFilteredResults(false)
      }

    }

    else {
      setRelatedCoursesFilter((item) => {
        return item => item
      })

      params.delete('highSchoolCourses')
    }

    setHSCoursesParams(`${params.toString()}`)

  }, [coursesSelect, dataLoaded])

  useEffect(() => {

    // Set URL params
    let params = new URLSearchParams(location.search);

    if (careersSelect.length > 0) {

      const careers = careersSelect.map((name) => name).join(',')
      params.set('careers', careers)

      let resA: any = []
      resA = careerCIP.filter((item) => (item.affinityScore !== null && careersSelect.includes(item.career_code)))

      let resB: any = []
      resA.map((res) => {
        resB.push(res.cipt_code)
      })

      resB = [...new Set(resB)]

      setRelatedCareersFilter((item) => {
        return item => (item.cipt_code !== null && resB.includes(item.cipt_code))
      })

    }

    else {
      setRelatedCareersFilter((item) => {
        return item => item
      })

      params.delete('careers')
    }

    setCareersParams(`${params.toString()}`)
  }, [careersSelect, dataLoaded])

  useEffect(() => {

    let params = new URLSearchParams(location.search);

    const returnEarningsFilter = (earningsMin, earningsMax) => {

      if (earningsMin > 0 || earningsMax < 250000) {
        setEarningsFilter((item) => {
          return item => (
            item.averageEarnings !== null &&
            earningsMin <= item.averageEarnings &&
            earningsMax >= item.averageEarnings
          ) ||
            (item.averageEarnings === null)
        })

        let minMaxArr: any[] = []
        minMaxArr = [earningsMin, earningsMax]
        const minMax = minMaxArr.map((val) => val).join(',')
        params.set('earnings', minMax)
      }

      else {
        setEarningsFilter((item) => {
          return item => item
        })

        params.delete('earnings')
      }
    }

    returnEarningsFilter(earningsMin, earningsMax)

    setEarningsParams(`${params.toString()}`)

  }, [earningsMin, earningsMax])

  useEffect(() => {

    let params = new URLSearchParams(location.search);

    const returnPopularityFilter = (popularityMin, popularityMax) => {
      if (popularityMin > 0 || popularityMax < 10) {
        setPopularityFilter((item) => {
          return item => (item.popularityIndex !== null && popularityMin <= item.popularityIndex && popularityMax >= item.popularityIndex) || item.popularityIndex === null
        })

        let minMaxArr: any[] = []
        minMaxArr = [popularityMin, popularityMax]
        const minMax = minMaxArr.map((val) => val).join(',')
        params.set('popularity', minMax)
      }

      else {
        setPopularityFilter((item) => {
          return item => item
        })

        params.delete('popularity')
      }
    }

    returnPopularityFilter(popularityMin, popularityMax)

    setPopularityParams(`${params.toString()}`)

  }, [popularityMin, popularityMax])

  useEffect(() => {

    // Set URL params
    let params = new URLSearchParams(location.search);

    if (areasSelect.length > 0) {
      const areas = areasSelect.map((area) => area).join(',')
      params.set('areas', areas)
    } else {
      params.delete('areas')
    }

    setAreasParams(`${params.toString()}`)

    const returnAreasFilter = (areasSelect) => {

      if (areasSelect.length > 0) {
        setAreasFilter((item) => {

          if (areasSelect.length == 1) {
            return item => item.primaryGroup == areasSelect || item.secondaryGroup == areasSelect
          }

          else {
            return item => areasSelect.some((area) => area == item.primaryGroup || area == item.secondaryGroup)
          }

        })
      }

      else {
        setAreasFilter((item) => {
          return item => item
        })
      }
    }

    returnAreasFilter(areasSelect)

  }, [areasSelect])

  useEffect(() => {

    let params = new URLSearchParams(location.search);

    if (onlyInterestsSelect === true) {
      params.set('onlyInterests', 'true')
    }

    else {
      params.delete('onlyInterests')
    }

    setOnlyInterestsParams(`${params.toString()}`)

  }, [onlyInterestsSelect])

  useEffect(() => {

    //  Create an array of the parameter strings
    let params: string[] = []
    // Use the returnParameterString function to fix the string if needed
    params = [
      returnParameterString(onlyInterestsParams, 'onlyInterests'),
      returnParameterString(majorNameParams, 'majorName'),
      returnParameterString(collegeNameParams, 'collegeName'),
      returnParameterString(hsCoursesParams, 'highSchoolCourses'),
      returnParameterString(careersParams, 'careers'),
      returnParameterString(areasParams, 'areas'),
      returnParameterString(earningsParams, 'earnings'),
      returnParameterString(popularityParams, 'popularity'),
      returnParameterString(sortParams, 'sort')
    ]

    // Set the location string using the base pageRoute and the return from the concat location function
    setMajorSearchLocation(`${pageRoutes.majorSearch}${concatLocation(params)}`)

  }, [majorNameParams, collegeNameParams, hsCoursesParams, careersParams, areasParams, earningsParams, popularityParams, sortParams, onlyInterestsParams])

  useEffect(() => {

    window.history.replaceState({}, '', majorSearchLocation)

  }, [majorSearchLocation])

  return (
    <div style={{ width: '260px', maxWidth: '260px', whiteSpace: 'nowrap' }}>
      <div style={{ zIndex: 5 }}>
        <MajorNameSearch
          nameOptions={nameOptions}
          setNameSelect={setNameSelect} />
      </div>

      <div style={{ zIndex: 4 }}>
        <MajorCollegeSearch
          collegeOptions={collegeOptions}
          setCollegeSelect={setCollegeSelect} /></div>

      <div style={{ zIndex: 3 }}>
        <MajorCourseSearch
          setCourseSelect={setCoursesSelect}
          courseOptions={courseOptions} /></div>

      <div style={{ zIndex: 2 }}>
        <MajorCareerSearch
          setCareerSelect={setCareersSelect}
          careerOptions={careerOptions} />
      </div>

      <div style={{ zIndex: 1 }}>
        <MajorAreasSelect
          setAreasSelect={setAreasSelect}
          majors={majors} />
      </div>

      <div style={{ zIndex: 1 }}>
        <MajorEarningsSearch
          setEarningsMin={setEarningsMin}
          setEarningsMax={setEarningsMax} />
      </div>

      <div style={{ zIndex: 1 }}>
        <MajorPopularitySearch
          setPopularityMin={setPopularityMin}
          setPopularityMax={setPopularityMax} />
      </div>

      <div style={{ zIndex: 1 }}>
        <MajorOnlyInterests
          onlyInterestsSelect={onlyInterestsSelect}
          setOnlyInterestsSelect={setOnlyInterestsSelect} />
      </div>

    </div>
  )
}

export { MajorSearchAside }