const InsufficientCollegeData = ({ collegeName, userSATScoreOutput, userACTScoreOutput }) => {

  ////////////////
  // EDIT TEXT //
  //////////////

  let textDefault = `${collegeName} has not officially published sufficient data about test scores for their recently enrolled students to enable a confident, personalized recommendation at this time.`

  let case2a = `${collegeName} has not officially published sufficient data about SAT test scores for their recently enrolled students to enable a confident, personalized recommendation based on your SAT score at this time. You may try adding an ACT score if you have one.`

  let case2b = `${collegeName} has not officially published sufficient data about ACT test scores for their recently enrolled students to enable a confident, personalized recommendation based on your ACT score at this time. You may try adding an SAT score if you have one.`

  //////////////////////
  //  END EDIT TEXT  //
  ////////////////////


  const returnText = (userSATScoreOutput, userACTScoreOutput) => {
    switch (true) {
      case userSATScoreOutput === -2 && userACTScoreOutput === -2:
        return textDefault
      case userSATScoreOutput === -2 && userACTScoreOutput === -1:
        return case2a
      case userSATScoreOutput === -1 && userACTScoreOutput === -2:
        return case2b
      default:
        return textDefault

    }
  }


  return (
    <div>
      {returnText(userSATScoreOutput, userACTScoreOutput)}
    </div>)
}

export { InsufficientCollegeData }