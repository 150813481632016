import { LoadingSpinner } from "../LoadingSpinner"
import { TestScoresForm } from "./forms/TestScoresForm"
import { ProfileInfoForm } from "./forms/ProfileInfo"
import { GradesForm } from "./forms/Grades"

const ProfileInfo = ({ user, setUser, apiToken, userData, setUserData }) => {

  return user ? (

    <div>
      <div className="dca-userProfile-form-card">
        <div className="dca-userProfile-form-title">Profile Info</div>
        <div className="separator"></div>

        <ProfileInfoForm user={user} setUser={setUser} apiToken={apiToken} userData={userData} setUserData={setUserData} />
      </div>


      <div className="dca-userProfile-form-card">
        <div className="dca-userProfile-form-title">Grades</div>
        <div className="separator"></div>

        <GradesForm user={user} setUser={setUser} apiToken={apiToken} userData={userData} setUserData={setUserData} />
      </div>

      <div className="dca-userProfile-form-card">
        <div className="dca-userProfile-form-title">Test Scores</div>
        <div className="separator"></div>

        <TestScoresForm
          user={user}
          setUser={setUser}
          setUserData={setUserData}
          apiToken={apiToken}
          userData={userData} />
      </div>


    </div>
  ) : <LoadingSpinner title={'Profile Info'} />
}

export { ProfileInfo }