const SubmitBoth = ({ modalData, userSATScoreOutput, userACTScoreOutput, collegeName, satSuperScore, actComposite }) => {

  ////////////////
  // EDIT TEXT //
  //////////////

  // submitSATPercent > submitACTPercent
  const caseA = <div>{collegeName} requires a test score from all applicants to be considered for admission. Your SAT score ({satSuperScore}) and ACT score ({actComposite}) are equally strong, based on a comparison to the respective data-points published by {collegeName}, therefore <span className='fw-bold'>our recommendation is to submit either or both scores</span>. If you choose to submit only one score, note that the SAT is more commonly submitted by recent enrollees at {collegeName}.</div>

  const noteA = <div>Please note that your current test scores are <span className='fw-bold'>below the corresponding median scores</span> for {collegeName} and because a score is required, we recommend trying to <span className='fw-bold'>improve your ACT and/or SAT scores</span> before submitting.</div>

  //submitSATPercent < submitACTPercent
  const caseB = <div>{collegeName} requires a test score from all applicants to be considered for admission. Your SAT score ({satSuperScore}) and ACT score ({actComposite}) are equally strong, based on a comparison to the respective data-points published by {collegeName}, therefore <span className='fw-bold'>our recommendation is to submit either or both scores</span>. If you choose to submit only one score, note that the ACT is more commonly submitted by recent enrollees at {collegeName}.</div>

  const noteB = <div>Please note that your current test scores are <span className='fw-bold'>below the corresponding median scores</span> for {collegeName} and because a score is required, we recommend trying to <span className='fw-bold'>improve your ACT and/or SAT scores</span> before submitting.</div>

  // submitSATPercent == submitACTPercent
  const caseC = <div>{collegeName} requires a test score from all applicants to be considered for admission. Your SAT score ({satSuperScore}) and ACT score ({actComposite}) are equally strong, based on a comparison to the respective data-points published by {collegeName}, therefore our recommendation is to submit either or both scores.</div>

  const noteC = <div>Please note that your current test scores are <span className='fw-bold'>below the corresponding median scores</span> for {collegeName} and because a score is required, we recommend trying to <span className='fw-bold'>improve your ACT and/or SAT scores</span> before submitting.</div>

  //////////////////////
  //  END EDIT TEXT  //
  ////////////////////


  let subcase = 'a'
  let append = false


  let submitSATPercent = modalData.college_cds[0].submitSATPercent

  let submitACTPercent = modalData.college_cds[0].submitACTPercent

  switch (true) {
    case submitSATPercent > submitACTPercent:
      subcase = 'a'
      break;
    case submitSATPercent < submitACTPercent:
      subcase = 'b'
      break;
    case submitSATPercent == submitACTPercent:
      subcase = 'c'
      break;
    default:
      subcase = subcase
  }

  userACTScoreOutput < 40 ? append = true : append = false


  const aFalse = (
    <div>{caseA}</div>
  )

  const aTrue = (
    <div className="d-flex flex-column">
      <div>{caseA}</div>
      <div>{noteA}</div>
    </div>
  )

  const bFalse = (
    <div>{caseB}</div>
  )

  const bTrue = (
    <div className="d-flex flex-column">
      <div>{caseB}</div>
      <div>{noteB}</div>
    </div>
  )

  const cFalse = (
    <div>{caseC}</div>
  )

  const cTrue = (
    <div className="d-flex flex-column">
      <div>{caseC}</div>
      <div>{noteC}</div>
    </div>
  )

  const returnExplanation = (subcase) => {
    switch (subcase) {
      case 'a':
        return append ? aTrue : aFalse
      case 'b':
        return append ? bTrue : bFalse
      case 'c':
        return append ? cTrue : cFalse
      default:
        return append ? aTrue : aFalse
    }
  }

  return (
    <div>
      {returnExplanation(subcase)}
    </div>
  )
}


export { SubmitBoth }
