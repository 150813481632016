// import { useState, useEffect, useRef } from 'react'
// import axios from 'axios'
// import * as dictionary from '../components/dictionary'
// import { Aside } from '../components/CollegeDataVisualization/Aside'
// import { CollegeDataChart } from '../components/CollegeDataVisualization/CollegeDataChart'
// import { useNavigate } from 'react-router'
// import { LoadingIndicator } from '../components/LoadingIndicator'
// import { useLocation } from 'react-router'
// import { returnParameterString, concatLocation } from '../components/searchPageURLManager'

// const CollegeDataVisualization = ({ user, setUser, collegesId, prompt, setPrompt, navigateTo, setNavigateTo, collegeDataVisLocation, setCollegeDataVisLocation }) => {

//   const location = useLocation()
//   const navigate = useNavigate()
//   const collegeListLength = collegesId.length
//   // let today = new Date();
//   // let latestYear = (today.getFullYear() - 1)

//   //Per #165, hardcode 2022 - 2023 as latest year / primary data
//   let latestYear = 2022

//   const chartOptionsOptions = [
//     { value: 1, label: 'GPA vs SAT Composite' },
//     { value: 2, label: 'GPA vs ACT Composite' },
//     { value: 3, label: 'Class Rank vs SAT Composite' },
//     { value: 4, label: 'Class Rank vs ACT Composite' },
//     { value: 5, label: 'SAT Math vs SAT Reading/Writing' },
//     { value: 6, label: 'ACT Math vs ACT English' },
//     { value: 7, label: 'SAT Submission vs ACT Submission' }
//   ]

//   const getURLParam = (param) => {
//     const urlParams = new URLSearchParams(window.location.search);

//     return urlParams.get(param)
//   }

//   // Retrieve data
//   const [colleges, setColleges] = useState<any>([])
//   const [cdsAvailableSet, setCDSAvailableSet] = useState<any>([])
//   const [allOlderDataSet, setAllOlderDataSet] = useState<any>([])
//   const [allInsufficientDataSet, setAllInsufficientDataSet] = useState<any>([])

//   // Assign data
//   const [insufficientDataSet, setInsufficientDataSet] = useState<any>([])
//   const [olderDataSet, setOlderDataSet] = useState<any>([])
//   const [majors, setMajors] = useState<any>([])
//   const [collegeSetOptions, setCollegeSetOptions] = useState<any>([])

//   // Chart data states
//   const [olderNames, setOlderNames] = useState<any>([])
//   const [insufficientNames, setInsufficientNames] = useState<any>([])

//   const [gpaSeries, setGPASeries] = useState({
//     weightedSATSeries: [],
//     weightedSATSeriesNames: [],
//     unweightedSATSeries: [],
//     unweightedSATSeriesNames: [],
//     weightedACTSeries: [],
//     weightedACTSeriesNames: [],
//     unweightedACTSeries: [],
//     unweightedACTSeriesNames: []
//   })

//   // Data loaded state checks
//   const [retrieveDataComplete, setRetrieveDataComplete] = useState(false)
//   const [assignDataComplete, setAssignDataComplete] = useState(false)

//   // For College Data Chart component, renders chart when arrays are set
//   // const [dataLoaded, setDataLoaded] = useState(false);

//   // Aside select states
//   const [selectedChart, setSelectedChart] = useState(() => {
//     const initialParam = getURLParam('chart');
//     return (initialParam && Number(initialParam) <= 7) ? Number(initialParam) : 1;
//   });
//   const [selectedCollegeSet, setSelectedCollegeSet] = useState(() => {
//     const initialParam = getURLParam('collegeSet');
//     return (initialParam && Number(initialParam) <= 7) ? Number(initialParam) : 0;
//   });
//   const [selectedType, setSelectedType] = useState<any>(0)
//   const [selectedMajor, setSelectedMajor] = useState<any>('')

//   // Filters
//   const [collegeSetFilter, setCollegeSetFilter] = useState<any>((item) => {
//     return item => item
//   })
//   const [institutionTypeFilter, setInstitutionTypeFilter] = useState<any>((item) => {
//     return item => item
//   })
//   const [majorFilter, setMajorFilter] = useState<any>((item) => {
//     return item => item
//   })
//   const [showRelatedMajors, setShowRelatedMajors] = useState<any>(false)

//   useEffect(() => {

//     const fetchData = async () => {

//       try {

//         if (retrieveDataComplete === true) {
//           setRetrieveDataComplete(false)
//         }

//         const collegesAxios = axios.get(`${dictionary.URL}/colleges/0-to-${collegeListLength}`)
//         const collegeSetAxios = axios.get(`${dictionary.URL}/collegeset/`)
//         const majorsAxios = axios.get(`${dictionary.URL}/majors/`)

//         const [collegeRes, collegesetRes, majorsRes] = await Promise.all([
//           collegesAxios,
//           collegeSetAxios,
//           majorsAxios
//         ])

//         //All colleges == collegeRes.data
//         let sufficient = collegeRes.data.filter((item) =>
//           item.college_cds != null &&
//           item.college_cds[0] != null
//         );

//         // Applies a few more checks for the default chart GPA vs SAT
//         let resForCDSAvailable = sufficient.filter((item) => item.college_cds[0]['averageGPA'] != null &&
//           (
//             item.college_cds[0]['satComposite50'] != null ||
//             (
//               item.college_cds[0]['satComposite25'] != null &&
//               item.college_cds[0]['satComposite75'] != null
//             ) ||
//             (
//               item.college_cds[0]['satEvidence50'] != null &&
//               item.college_cds[0]['satMath50'] != null
//             )
//           ))

//         let insufficientData = sufficient.filter((item) => !resForCDSAvailable.includes(item))

//         // let olderData = resForCDSAvailable.filter((item) => (
//         //   (item.college_cds[0].year !== latestYear)
//         // ))

//         // Given update to CDS, set older data to anything that does not have latest year of 2022 or 2023
//         let olderData = resForCDSAvailable.filter((item) => (
//           (item.college_cds[0].year < 2022)
//         ))

//         let collegeSetArr: any = []
//         collegeSetArr.push({ value: 0, label: 'All' })
//         collegesetRes.data.forEach((res, index) => {
//           collegeSetArr.push({ value: index + 1, label: res.name, colleges: res.colleges })
//         });

//         await Promise.all([
//           setInsufficientDataSet(insufficientData),
//           setAllInsufficientDataSet(insufficientData),
//           setColleges(sufficient),
//           setCDSAvailableSet(resForCDSAvailable),
//           setOlderDataSet(olderData),
//           setAllOlderDataSet(olderData),
//           setCollegeSetOptions(collegeSetArr),
//           setMajors(majorsRes.data)
//         ])

//         setRetrieveDataComplete(true)
//         console.log('retrieve data completed')

//       } catch (error) {
//         console.error('Error grabbing College Data Visualization data:', error)
//       }
//     }

//     fetchData()

//   }, [])

//   useEffect(() => {

//     const assignData = () => {

//       if (assignDataComplete === true) {
//         setAssignDataComplete(false)
//       }
//       // college == sufficient from all colleges 
//       let res: any = colleges

//       switch (true) {
//         // GPA vs SAT Composite
//         case selectedChart == 1:
//           res = res.filter((item) => item.college_cds[0]['averageGPA'] != null &&
//             (
//               item.college_cds[0]['satComposite50'] != null ||
//               (
//                 item.college_cds[0]['satComposite25'] != null &&
//                 item.college_cds[0]['satComposite75'] != null
//               ) ||
//               (
//                 item.college_cds[0]['satEvidence50'] != null &&
//                 item.college_cds[0]['satMath50'] != null
//               )
//             ));
//           break;

//         // GPA vs ACT Composite
//         case selectedChart == 2:
//           res = res.filter((item) => item.college_cds[0]['averageGPA'] != null &&
//             (
//               item.college_cds[0]['actComposite50'] != null ||
//               (
//                 item.college_cds[0]['actComposite25'] != null &&
//                 item.college_cds[0]['actComposite75'] != null
//               )
//             ) &&
//             item.college_cds[0]['totalAdmitRate'] != null
//           );
//           break;

//         // Class Rank vs SAT
//         case selectedChart == 3:
//           res = res.filter((item) => item.college_cds[0]['classRankTopTenth'] != null &&
//             (
//               item.college_cds[0]['satComposite50'] != null ||
//               (
//                 item.college_cds[0]['satComposite25'] != null &&
//                 item.college_cds[0]['satComposite75'] != null
//               ) ||
//               (
//                 item.college_cds[0]['satEvidence50'] != null &&
//                 item.college_cds[0]['satMath50'] != null
//               )
//             ) &&
//             item.college_cds[0]['totalAdmitRate'] != null);
//           break;

//         // Class Rank vs ACT
//         case selectedChart == 4:
//           res = res.filter((item) => item.college_cds[0]['classRankTopTenth'] != null &&
//             (
//               item.college_cds[0]['actComposite50'] != null ||
//               (
//                 item.college_cds[0]['actComposite25'] != null &&
//                 item.college_cds[0]['actComposite75'] != null
//               )
//             ) &&
//             item.college_cds[0]['totalAdmitRate'] != null
//           );
//           break;

//         // SAT Math vs SAT Reading/Writing
//         case selectedChart == 5:
//           res = res.filter((item) => (item.college_cds[0]['satMath50'] != null && item.college_cds[0]['satEvidence50'] != null) || (item.college_cds[0]['satMath25'] != null && item.college_cds[0]['satMath75'] != null && item.college_cds[0]['satEvidence25'] != null && item.college_cds[0]['satEvidence75'] != null));
//           break;

//         // ACT Math vs ACT English
//         case selectedChart == 6:
//           res = res.filter((item) => (item.college_cds[0]['actMath50'] != null && item.college_cds[0]['actEnglish50'] != null) || (item.college_cds[0]['actMath25'] != null && item.college_cds[0]['actMath75'] != null && item.college_cds[0]['actEnglish25'] != null && item.college_cds[0]['actEnglish75'] != null));
//           break;

//         // SAT submit vs ACT submit
//         case selectedChart == 7:
//           res = res.filter((item) => (item.college_cds[0]['submitSATPercent'] != null && item.college_cds[0]['submitACTPercent'] != null));
//           break;

//         default:
//           res = res.filter((item) => item.college_cds[0]['averageGPA'] != null &&
//             (
//               item.college_cds[0]['satComposite50'] != null ||
//               (
//                 item.college_cds[0]['satComposite25'] != null &&
//                 item.college_cds[0]['satComposite75'] != null
//               )
//             ) &&
//             item.college_cds[0]['totalAdmitRate'] != null);
//       }

//       let insufficientData = colleges.filter((item) => !res.includes(item))

//       insufficientData = insufficientData.filter(institutionTypeFilter).filter(majorFilter).filter(collegeSetFilter)

//       res = res.filter(institutionTypeFilter).filter(majorFilter).filter(collegeSetFilter)

//       // let olderData = res.filter((item) => (
//       //   (item.college_cds[0].year !== latestYear)
//       // ))

//       // Per #165, part 2:
//       let olderData = res.filter((item) => (
//         (item.college_cds[0].year < 2022)
//       ))

//       setInsufficientDataSet(insufficientData)
//       setOlderDataSet(olderData)
//       setCDSAvailableSet(res)

//       setAssignDataComplete(true)
//       console.log('assign data complete')
//     }

//     if (retrieveDataComplete === true) {
//       assignData()
//     }

//     // const processSeriesData = () => {
//     //   try {

//     //     let weightedGPASet: any = []
//     //     let unweightedGPASet: any = []
//     //     let rankSet: any = []
//     //     let olderArray: any = []
//     //     let insufficientArray: any = []

//     //     // Per #165, set current year as 2023
//     //     // let currentYear = new Date().getFullYear()
//     //     let currentYear = 2023

//     //     // GPA SAT
//     //     let weightedSATSeries: any = []
//     //     let weightedSATSeriesNames: any = []
//     //     let unweightedSATSeries: any = []
//     //     let unweightedSATSeriesNames: any = []

//     //     // GPA ACT
//     //     let weightedACTSeries: any = []
//     //     let weightedACTSeriesNames: any = []
//     //     let unweightedACTSeries: any = []
//     //     let unweightedACTSeriesNames: any = []

//     //     // Class Rank SAT
//     //     let rankSATSeries: any = []
//     //     let rankSATSeriesNames: any = []

//     //     // Class Rank ACT
//     //     let rankACTSeries: any = []
//     //     let rankACTSeriesNames: any = []

//     //     // SAT Math vs SAT Reading/Writing
//     //     let satMathReadingSeries: any = []
//     //     let satMathReadingSeriesNames: any = []

//     //     // ACT Math vs ACT English
//     //     let actMathEnglishSeries: any = []
//     //     let actMathEnglishSeriesNames: any = []

//     //     // SAT Submission vs ACT Submission
//     //     let submissionSeries: any = []
//     //     let submissionSeriesNames: any = []

//     //     if (cdsAvailableSet.length > 0) {

//     //       // Weighted / Unweighted -- For GPA AVG Type == 1:
//     //       if (selectedChart === 1 || selectedChart === 2) {
//     //         weightedGPASet = cdsAvailableSet.filter((item) => item.college_cds[0].gpaAverageType === 1)

//     //         unweightedGPASet = cdsAvailableSet.filter((item) => ((item.college_cds[0].gpaAverageType !== 1) || (item.college_cds[0].gpaAverageType === null)))

//     //         // Create SAT series data
//     //         weightedGPASet.forEach((res) => {
//     //           if (res.college_cds[0].satComposite50 !== null) {
//     //             weightedSATSeries.push({
//     //               x: res.college_cds[0].averageGPA,
//     //               y: res.college_cds[0].satComposite50,
//     //               z: res.college_cds[0].totalAdmitRate,
//     //               name: res.name,
//     //               metaRank: res.metaRank
//     //             })

//     //             weightedSATSeriesNames.push(res.name)
//     //           }

//     //           else if (res.college_cds[0].satComposite25 != null && res.college_cds[0].satComposite75 != null) {
//     //             weightedSATSeries.push({
//     //               x: res.college_cds[0].averageGPA,
//     //               y: ((parseInt(res.college_cds[0].satComposite25) + parseInt(res.college_cds[0].satComposite75)) / 2),
//     //               z: res.college_cds[0].totalAdmitRate,
//     //               name: res.name,
//     //               metaRank: res.metaRank
//     //             })

//     //             weightedSATSeriesNames.push(res.name)
//     //           }

//     //           else {
//     //             weightedSATSeries.push({
//     //               x: res.college_cds[0].averageGPA,
//     //               y: (parseInt(res.college_cds[0].satEvidence50) + parseInt(res.college_cds[0].satMath50)),
//     //               z: res.college_cds[0].totalAdmitRate,
//     //               name: res.name,
//     //               metaRank: res.metaRank
//     //             })
//     //             weightedSATSeriesNames.push(res.name)
//     //           }

//     //         })

//     //         // Create SAT series data
//     //         unweightedGPASet.forEach((res) => {
//     //           if (res.college_cds[0].satComposite50 !== null) {
//     //             unweightedSATSeries.push({
//     //               x: res.college_cds[0].averageGPA,
//     //               y: res.college_cds[0].satComposite50,
//     //               z: res.college_cds[0].totalAdmitRate,
//     //               name: res.name,
//     //               metaRank: res.metaRank
//     //             })

//     //             unweightedSATSeriesNames.push(res.name)
//     //           }

//     //           else if (res.college_cds[0].satComposite25 != null && res.college_cds[0].satComposite75 != null) {
//     //             unweightedSATSeries.push({
//     //               x: res.college_cds[0].averageGPA,
//     //               y: ((parseInt(res.college_cds[0].satComposite25) + parseInt(res.college_cds[0].satComposite75)) / 2),
//     //               z: res.college_cds[0].totalAdmitRate,
//     //               name: res.name,
//     //               metaRank: res.metaRank
//     //             })

//     //             unweightedSATSeriesNames.push(res.name)
//     //           }

//     //           else {
//     //             unweightedSATSeries.push({
//     //               x: res.college_cds[0].averageGPA,
//     //               y: (parseInt(res.college_cds[0].satEvidence50) + parseInt(res.college_cds[0].satMath50)),
//     //               z: res.college_cds[0].totalAdmitRate,
//     //               name: res.name,
//     //               metaRank: res.metaRank
//     //             })

//     //             unweightedSATSeriesNames.push(res.name)
//     //           }
//     //         })

//     //         // Create ACT series data
//     //         weightedGPASet.forEach((res) => {
//     //           if (res.college_cds[0].actComposite50 !== null) {
//     //             weightedACTSeries.push({
//     //               x: res.college_cds[0].averageGPA,
//     //               y: res.college_cds[0].actComposite50,
//     //               z: res.college_cds[0].totalAdmitRate,
//     //               name: res.name,
//     //               metaRank: res.metaRank
//     //             })

//     //             weightedACTSeriesNames.push(res.name)
//     //           }

//     //           else {
//     //             weightedACTSeries.push({
//     //               x: res.college_cds[0].averageGPA,
//     //               y: ((parseInt(res.college_cds[0].actComposite25) + parseInt(res.college_cds[0].actComposite75)) / 2),
//     //               z: res.college_cds[0].totalAdmitRate,
//     //               name: res.name,
//     //               metaRank: res.metaRank
//     //             })

//     //             weightedACTSeriesNames.push(res.name)
//     //           }
//     //         })

//     //         // Create ACT series data
//     //         unweightedGPASet.forEach((res) => {
//     //           if (res.college_cds[0].actComposite50 !== null) {
//     //             unweightedACTSeries.push({
//     //               x: res.college_cds[0].averageGPA,
//     //               y: res.college_cds[0].actComposite50,
//     //               z: res.college_cds[0].totalAdmitRate,
//     //               name: res.name,
//     //               metaRank: res.metaRank
//     //             })

//     //             unweightedACTSeriesNames.push(res.name)
//     //           }

//     //           else {
//     //             unweightedACTSeries.push({
//     //               x: res.college_cds[0].averageGPA,
//     //               y: ((parseInt(res.college_cds[0].actComposite25) + parseInt(res.college_cds[0].actComposite75)) / 2),
//     //               z: res.college_cds[0].totalAdmitRate,
//     //               name: res.name,
//     //               metaRank: res.metaRank
//     //             })

//     //             unweightedACTSeriesNames.push(res.name)
//     //           }
//     //         })

//     //         let gpaSeriesPostProcessing = {
//     //           weightedSATSeries: weightedSATSeries,
//     //           weightedSATSeriesNames: weightedSATSeriesNames,
//     //           unweightedSATSeries: unweightedSATSeries,
//     //           unweightedSATSeriesNames: unweightedSATSeriesNames,
//     //           weightedACTSeries: weightedACTSeries,
//     //           weightedACTSeriesNames: weightedACTSeriesNames,
//     //           unweightedACTSeries: unweightedACTSeries,
//     //           unweightedACTSeriesNames: unweightedACTSeriesNames
//     //         }

//     //         setGPASeries(gpaSeriesPostProcessing)
//     //       }

//     //       // Class Rank data sets 
//     //       if (selectedChart === 3 || selectedChart === 4) {
//     //         rankSet = cdsAvailableSet.filter((item) => item.college_cds[0].classRankTopTenth)

//     //         // Create Class rank SAT series data
//     //         rankSet.forEach((res) => {
//     //           if (res.college_cds[0].satComposite50 !== null) {
//     //             rankSATSeries.push({
//     //               x: (res.college_cds[0].classRankTopTenth * 100),
//     //               y: res.college_cds[0].satComposite50,
//     //               z: res.college_cds[0].totalAdmitRate,
//     //               name: res.name,
//     //               metaRank: res.metaRank
//     //             })

//     //             rankSATSeriesNames.push(res.name)
//     //           }

//     //           else if (res.college_cds[0].satComposite25 !== null && res.college_cds[0].satComposite75 !== null) {
//     //             rankSATSeries.push({
//     //               x: (res.college_cds[0].classRankTopTenth * 100),
//     //               y: ((parseInt(res.college_cds[0].satComposite25) + parseInt(res.college_cds[0].satComposite75)) / 2),
//     //               z: res.college_cds[0].totalAdmitRate,
//     //               name: res.name,
//     //               metaRank: res.metaRank
//     //             })

//     //             rankSATSeriesNames.push(res.name)
//     //           }

//     //           else {
//     //             rankSATSeries.push({
//     //               x: (res.college_cds[0].classRankTopTenth * 100),
//     //               y: (parseInt(res.college_cds[0].satEvidence50) + parseInt(res.college_cds[0].satMath50)),
//     //               z: res.college_cds[0].totalAdmitRate,
//     //               name: res.name,
//     //               metaRank: res.metaRank
//     //             })

//     //             rankSATSeriesNames.push(res.name)
//     //           }
//     //         })

//     //         // Create Class rank ACT series data
//     //         rankSet.forEach((res) => {
//     //           if (res.college_cds[0].actComposite50 !== null) {
//     //             rankACTSeries.push({
//     //               x: (res.college_cds[0].classRankTopTenth * 100),
//     //               y: res.college_cds[0].actComposite50,
//     //               z: res.college_cds[0].totalAdmitRate,
//     //               name: res.name,
//     //               metaRank: res.metaRank
//     //             })

//     //             rankACTSeriesNames.push(res.name)
//     //           }

//     //           else {
//     //             rankACTSeries.push({
//     //               x: (res.college_cds[0].classRankTopTenth * 100),
//     //               y: ((parseInt(res.college_cds[0].actComposite25) + parseInt(res.college_cds[0].actComposite75)) / 2),
//     //               z: res.college_cds[0].totalAdmitRate,
//     //               name: res.name,
//     //               metaRank: res.metaRank
//     //             })

//     //             rankACTSeriesNames.push(res.name)
//     //           }
//     //         })

//     //       }

//     //       // SAT Math vs SAT Reading/Writing
//     //       if (selectedChart === 5) {
//     //         cdsAvailableSet.forEach((item) => {
//     //           if (item.college_cds[0]['satMath50'] != null && item.college_cds[0]['satEvidence50'] != null) {
//     //             satMathReadingSeries.push({
//     //               x: item.college_cds[0].satMath50,
//     //               y: item.college_cds[0].satEvidence50,
//     //               z: item.college_cds[0].totalAdmitRate,
//     //               name: item.name,
//     //               metaRank: item.metaRank
//     //             })

//     //             satMathReadingSeriesNames.push(item.name)
//     //           }

//     //           else if (item.college_cds[0]['satMath25'] != null && item.college_cds[0]['satMath75'] != null && item.college_cds[0]['satEvidence25'] != null && item.college_cds[0]['satEvidence75'] != null) {
//     //             satMathReadingSeries.push({
//     //               x: ((parseInt(item.college_cds[0].satMath25) + parseInt(item.college_cds[0].satMath75)) / 2),
//     //               y: ((parseInt(item.college_cds[0].satEvidence25) + parseInt(item.college_cds[0].satEvidence75)) / 2),
//     //               z: item.college_cds[0].totalAdmitRate,
//     //               name: item.name,
//     //               metaRank: item.metaRank
//     //             })

//     //             satMathReadingSeriesNames.push(item.name)
//     //           }
//     //         })
//     //       }

//     //       // ACT Math vs ACT Reading/Writing
//     //       if (selectedChart === 6) {
//     //         cdsAvailableSet.forEach((item) => {
//     //           if (item.college_cds[0]['actMath50'] != null && item.college_cds[0]['actEnglish50'] != null) {
//     //             actMathEnglishSeries.push({
//     //               x: item.college_cds[0].actMath50,
//     //               y: item.college_cds[0].actEnglish50,
//     //               z: item.college_cds[0].totalAdmitRate,
//     //               name: item.name,
//     //               metaRank: item.metaRank
//     //             })

//     //             actMathEnglishSeriesNames.push(item.name)
//     //           }

//     //           else if (item.college_cds[0]['actMath25'] != null && item.college_cds[0]['actMath75'] != null && item.college_cds[0]['actEnglish25'] != null && item.college_cds[0]['actEnglish75'] != null) {
//     //             actMathEnglishSeries.push({
//     //               x: ((parseInt(item.college_cds[0].actMath25) + parseInt(item.college_cds[0].actMath75)) / 2),
//     //               y: ((parseInt(item.college_cds[0].actEnglish25) + parseInt(item.college_cds[0].actEnglish75)) / 2),
//     //               z: item.college_cds[0].totalAdmitRate,
//     //               name: item.name,
//     //               metaRank: item.metaRank
//     //             })

//     //             actMathEnglishSeriesNames.push(item.name)
//     //           }


//     //         })
//     //       }

//     //       // SAT Submission vs ACT Submission
//     //       if (selectedChart === 7) {
//     //         cdsAvailableSet.forEach((item) => {
//     //           if (item.college_cds[0]['submitSATPercent'] != null && item.college_cds[0]['submitACTPercent'] != null) {
//     //             submissionSeries.push({
//     //               x: item.college_cds[0].submitSATPercent,
//     //               y: item.college_cds[0].submitACTPercent,
//     //               z: item.college_cds[0].totalAdmitRate,
//     //               name: item.name,
//     //               metaRank: item.metaRank
//     //             })

//     //             submissionSeriesNames.push(item.name)
//     //           }
//     //         })
//     //       }

//     //     }

//     //     if (olderDataSet.length > 0) {
//     //       olderDataSet.forEach((res, index) => {
//     //         if (index === olderDataSet.length - 1) {
//     //           olderArray.push(res.name)
//     //         }
//     //         else {
//     //           olderArray.push(`${res.name}, `)
//     //         }
//     //       })
//     //     }

//     //     if (insufficientDataSet.length > 0) {
//     //       insufficientDataSet.forEach((res, index) => {
//     //         if (index === insufficientDataSet.length - 1) {
//     //           insufficientArray.push(res.name)
//     //         }
//     //         else {
//     //           insufficientArray.push(`${res.name}, `)
//     //         }
//     //       })
//     //     }

//     //     setOlderNames(olderArray)
//     //     setInsufficientNames(insufficientArray)

//     //   } catch (error) {

//     //     console.error('Error setting the data for the College Data Visualization Charts:', error)

//     //   }
//     // }

//     // if (assignDataComplete === true) {
//     //   processSeriesData()
//     // }

//     console.log('colleges is set:', colleges)

//   }, [institutionTypeFilter, majorFilter, collegeSetFilter, selectedChart, showRelatedMajors, collegeSetOptions, colleges, assignDataComplete])

//   const [chartOptionParams, setChartOptionParams] = useState('')
//   const [collegeSetParams, setCollegeSetParams] = useState('')

//   useEffect(() => {

//     let params = new URLSearchParams(location.search);
//     params.set('chart', `${selectedChart}`)

//     setChartOptionParams(`${params.toString()}`)

//   }, [selectedChart])

//   useEffect(() => {

//     let params = new URLSearchParams(location.search);
//     params.set('collegeSet', `${selectedCollegeSet}`)

//     setCollegeSetParams(`${params.toString()}`)

//     const returnCollegeSetFilter = (selectedCollegeSet) => {

//       // if (selectedCollegeSet.length > 0) {
//       if (selectedCollegeSet > 0) {

//         let collegesFromSelectedCollegeSet = collegeSetOptions.find(item => item.value == selectedCollegeSet)

//         // setCollegeSetFilter((item) => {
//         //   return item => selectedCollegeSet.includes(item.id)
//         // })

//         setCollegeSetFilter((item) => {
//           return item => collegesFromSelectedCollegeSet?.colleges.includes(item.id)
//         })

//         // //update the older and insufficient data arrays in the notes section
//         // setOlderDataSet(allOlderDataSet.filter((item) => selectedCollegeSet.includes(item.id)))

//         // setInsufficientDataSet(allInsufficientDataSet.filter((item) => selectedCollegeSet.includes(item.id)))

//         //update the older and insufficient data arrays in the notes section
//         setOlderDataSet(allOlderDataSet.filter((item) => collegesFromSelectedCollegeSet?.colleges.includes(item.id)))

//         setInsufficientDataSet(allInsufficientDataSet.filter((item) => collegesFromSelectedCollegeSet?.colleges.includes(item.id)))
//       }

//       else {
//         setCollegeSetFilter((item) => {
//           return item => item
//         })

//         //update the older and insufficient data arrays in the notes section to original older and insufficient arrays
//         setOlderDataSet(allOlderDataSet)
//         setInsufficientDataSet(allInsufficientDataSet)
//       }
//     }
//     returnCollegeSetFilter(selectedCollegeSet)

//   }, [selectedCollegeSet, collegeSetOptions])

//   useEffect(() => {
//     const returnTypeFilter = (selectedType) => {
//       switch (selectedType) {
//         case 0:
//           setInstitutionTypeFilter((item) => {
//             return item => item
//           })
//           break;

//         case 1:
//           setInstitutionTypeFilter((item) => {
//             return item => item.type === 1
//           })
//           break;

//         case 2:
//           setInstitutionTypeFilter((item) => {
//             return item => [2, 3].includes(item.type)
//           })
//           break;

//         default:
//           setInstitutionTypeFilter((item) => {
//             return item => item
//           })
//       };
//     };
//     returnTypeFilter(selectedType)

//     const returnMajorFilter = (selectedMajor) => { }
//     returnMajorFilter(selectedMajor)

//   }, [selectedType, selectedMajor])

//   useEffect(() => {

//     let params: string[] = []
//     params = [
//       returnParameterString(chartOptionParams, 'chart'),
//       returnParameterString(collegeSetParams, 'collegeSet')
//     ]

//     setCollegeDataVisLocation(`${dictionary.pageRoutes.collegeDataVisualization}${concatLocation(params)}`)

//   }, [chartOptionParams, collegeSetParams])

//   useEffect(() => {

//     window.history.replaceState({}, '', collegeDataVisLocation)

//   }, [collegeDataVisLocation])

//   const dataVizHeader = (
//     <div className="dca-search-header-wrapper">
//       <div className="dca-search-header-title-button-container">
//         <span className='dca-search-title align-self-center'>College Data Scattergrams</span>

//         <div className="dca-search-header-bttn-container-double">
//           <button
//             type="button"
//             aria-pressed="true"
//             onClick={() => navigate(dictionary.pageRoutes.overviewWithId('admissions_advice#creating_a_college_interest_list'))}
//             className='dca-search-header-bttn'
//           >Advice: Building A College List</button>

//           <button
//             type="button"
//             aria-pressed="true"
//             onClick={() => navigate(dictionary.pageRoutes.collegeSearch)}
//             className='dca-search-header-bttn'
//             style={{ marginLeft: '8px' }}
//           >Explore More College Data</button>

//         </div>
//       </div>

//       <span className="dca-search-description">Explore benchmark values for the most important quantitative admissions considerations at the top-ranked colleges.</span>

//     </div>
//   )

//   return assignDataComplete ? (
//     <div>

//       {dataVizHeader}

//       <div className='d-flex flex-row' style={{ marginLeft: '35px', marginTop: '50px' }}>
//         <Aside
//           collegeSetOptions={collegeSetOptions}
//           chartOptionsOptions={chartOptionsOptions}
//           selectedChart={selectedChart}
//           setSelectedChart={setSelectedChart}
//           setSelectedType={setSelectedType}
//           selectedCollegeSet={selectedCollegeSet}
//           setSelectedCollegeSet={setSelectedCollegeSet}
//           user={user}
//           setUser={setUser}
//         />
//         <CollegeDataChart
//           selectedChart={selectedChart}
//           latestYear={latestYear}
//           assignDataComplete={assignDataComplete}
//           gpaSeries={gpaSeries}
//           olderNames={olderNames}
//           insufficientNames={insufficientNames}
//           cdsAvailableSet={cdsAvailableSet}
//         />
//       </div>
//     </div>
//   ) : (
//     <div>
//       {dataVizHeader}
//       <div className='d-flex flex-row' style={{ marginLeft: '35px', marginTop: '50px' }}>
//         <div >
//           <Aside
//             collegeSetOptions={collegeSetOptions}
//             chartOptionsOptions={chartOptionsOptions}
//             selectedChart={selectedChart}
//             setSelectedChart={setSelectedChart}
//             setSelectedType={setSelectedType}
//             selectedCollegeSet={selectedCollegeSet}
//             setSelectedCollegeSet={setSelectedCollegeSet}
//             user={user}
//             setUser={setUser}
//           /></div>

//         <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginLeft: '300px' }}>
//           <LoadingIndicator text={"Loading College Data Scattergrams..."} />
//         </div>
//       </div>
//     </div>
//   )
// }

// export { CollegeDataVisualization }
















// //// OLD
import { useState, useEffect, useRef } from 'react'
import axios from 'axios'
import * as dictionary from '../components/dictionary'
import { Aside } from '../components/CollegeDataVisualization/Aside'
import { CollegeDataChart } from '../components/CollegeDataVisualization/CollegeDataChart'
import { useNavigate } from 'react-router'
import { LoadingIndicator } from '../components/LoadingIndicator'
import { useLocation } from 'react-router'
import { returnParameterString, concatLocation } from '../components/searchPageURLManager'

const CollegeDataVisualization = ({ user, setUser, collegesId, prompt, setPrompt, navigateTo, setNavigateTo, collegeDataVisLocation, setCollegeDataVisLocation }) => {

  const location = useLocation()
  const navigate = useNavigate()
  const collegeListLength = collegesId.length
  // let today = new Date();
  // let latestYear = (today.getFullYear() - 1)

  //Per #165, hardcode 2022 - 2023 as latest year / primary data
  let latestYear = 2022

  const chartOptionsOptions = [
    { value: 1, label: 'GPA vs SAT Composite' },
    { value: 2, label: 'GPA vs ACT Composite' },
    { value: 3, label: 'Class Rank vs SAT Composite' },
    { value: 4, label: 'Class Rank vs ACT Composite' },
    { value: 5, label: 'SAT Math vs SAT Reading/Writing' },
    { value: 6, label: 'ACT Math vs ACT English' },
    { value: 7, label: 'SAT Submission vs ACT Submission' }
  ]

  const getURLParam = (param) => {
    const urlParams = new URLSearchParams(window.location.search);

    return urlParams.get(param)
  }

  const [colleges, setColleges] = useState<any>([])
  const [cdsAvailableSet, setCDSAvailableSet] = useState<any>([])
  const [allOlderDataSet, setAllOlderDataSet] = useState<any>([])
  const [allInsufficientDataSet, setAllInsufficientDataSet] = useState<any>([])
  const [insufficientDataSet, setInsufficientDataSet] = useState<any>([])
  const [olderDataSet, setOlderDataSet] = useState<any>([])
  const [collegeSetOptions, setCollegeSetOptions] = useState<any>([])
  const [majors, setMajors] = useState<any>([])
  const [loadingComplete, setLoadingComplete] = useState(false)

  // For College Data Chart component, renders chart when arrays are set
  const [dataLoaded, setDataLoaded] = useState(false);

  // Aside states
  const [selectedChart, setSelectedChart] = useState(() => {
    const initialParam = getURLParam('chart');
    return (initialParam && Number(initialParam) <= 7) ? Number(initialParam) : 1;
  });
  const [selectedCollegeSet, setSelectedCollegeSet] = useState(() => {
    const initialParam = getURLParam('collegeSet');
    return (initialParam && Number(initialParam) <= 7) ? Number(initialParam) : 0;
  });
  const [selectedType, setSelectedType] = useState<any>(0)
  const [selectedMajor, setSelectedMajor] = useState<any>('')

  // Aside filters
  const [collegeSetFilter, setCollegeSetFilter] = useState<any>((item) => {
    return item => item
  })
  const [institutionTypeFilter, setInstitutionTypeFilter] = useState<any>((item) => {
    return item => item
  })
  const [majorFilter, setMajorFilter] = useState<any>((item) => {
    return item => item
  })
  const [showRelatedMajors, setShowRelatedMajors] = useState<any>(false)

  useEffect(() => {

    const fetchData = async () => {

      try {

        if (loadingComplete === true) {
          setLoadingComplete(false)
        }

        const collegesAxios = axios.get(`${dictionary.URL}/colleges/0-to-${collegeListLength}`)
        const collegeSetAxios = axios.get(`${dictionary.URL}/collegeset/`)
        const majorsAxios = axios.get(`${dictionary.URL}/majors/`)

        const [collegeRes, collegesetRes, majorsRes] = await Promise.all([
          collegesAxios,
          collegeSetAxios,
          majorsAxios
        ])

        //All colleges == collegeRes.data
        let sufficient = collegeRes.data.filter((item) =>
          item.college_cds != null &&
          item.college_cds[0] != null
        );

        // Applies a few more checks for the default chart GPA vs SAT
        let resForCDSAvailable = sufficient.filter((item) => item.college_cds[0]['averageGPA'] != null &&
          (
            item.college_cds[0]['satComposite50'] != null ||
            (
              item.college_cds[0]['satComposite25'] != null &&
              item.college_cds[0]['satComposite75'] != null
            ) ||
            (
              item.college_cds[0]['satEvidence50'] != null &&
              item.college_cds[0]['satMath50'] != null
            )
          ))

        let insufficient = sufficient.filter((item) => !resForCDSAvailable.includes(item))

        // let olderData = resForCDSAvailable.filter((item) => (
        //   (item.college_cds[0].year !== latestYear)
        // ))

        // Given update to CDS, set older data to anything that does not have latest year of 2022 or 2023
        let olderData = resForCDSAvailable.filter((item) => (
          (item.college_cds[0].year < 2022)
        ))

        let collegeSetArr: any = []
        // collegeSetArr.push({ value: [], label: 'All' })
        // collegesetRes.data.forEach((res) => {
        //   collegeSetArr.push({ value: res.colleges, label: res.name })
        // });
        collegeSetArr.push({ value: 0, label: 'All' })
        collegesetRes.data.forEach((res, index) => {
          collegeSetArr.push({ value: index + 1, label: res.name, colleges: res.colleges })
        });

        await Promise.all([
          setInsufficientDataSet(insufficient),
          setAllInsufficientDataSet(insufficient),
          setColleges(sufficient),
          setCDSAvailableSet(resForCDSAvailable),
          setOlderDataSet(olderData),
          setAllOlderDataSet(olderData),
          setCollegeSetOptions(collegeSetArr),
          setMajors(majorsRes.data)
        ])

        setLoadingComplete(true)

      } catch (error) {
        console.error('Error grabbing College Data Visualization data:', error)
      }
    }

    fetchData()

  }, [collegesId])

  useEffect(() => {
    const returnTypeFilter = (selectedType) => {
      switch (selectedType) {
        case 0:
          setInstitutionTypeFilter((item) => {
            return item => item
          })
          break;

        case 1:
          setInstitutionTypeFilter((item) => {
            return item => item.type === 1
          })
          break;

        case 2:
          setInstitutionTypeFilter((item) => {
            return item => [2, 3].includes(item.type)
          })
          break;

        default:
          setInstitutionTypeFilter((item) => {
            return item => item
          })
      };
    };
    returnTypeFilter(selectedType)

    const returnMajorFilter = (selectedMajor) => { }
    returnMajorFilter(selectedMajor)

  }, [selectedType, selectedMajor])

  useEffect(() => {

    if (loadingComplete === true) {
      setLoadingComplete(false)
    }

    // college == sufficient from all colleges 
    let res: any = colleges

    switch (true) {
      // GPA vs SAT Composite
      case selectedChart == 1:
        res = res.filter((item) => item.college_cds[0]['averageGPA'] != null &&
          (
            item.college_cds[0]['satComposite50'] != null ||
            (
              item.college_cds[0]['satComposite25'] != null &&
              item.college_cds[0]['satComposite75'] != null
            ) ||
            (
              item.college_cds[0]['satEvidence50'] != null &&
              item.college_cds[0]['satMath50'] != null
            )
          ));
        break;

      // GPA vs ACT Composite
      case selectedChart == 2:
        res = res.filter((item) => item.college_cds[0]['averageGPA'] != null &&
          (
            item.college_cds[0]['actComposite50'] != null ||
            (
              item.college_cds[0]['actComposite25'] != null &&
              item.college_cds[0]['actComposite75'] != null
            )
          ) &&
          item.college_cds[0]['totalAdmitRate'] != null
        );
        break;

      // Class Rank vs SAT
      case selectedChart == 3:
        res = res.filter((item) => item.college_cds[0]['classRankTopTenth'] != null &&
          (
            item.college_cds[0]['satComposite50'] != null ||
            (
              item.college_cds[0]['satComposite25'] != null &&
              item.college_cds[0]['satComposite75'] != null
            ) ||
            (
              item.college_cds[0]['satEvidence50'] != null &&
              item.college_cds[0]['satMath50'] != null
            )
          ) &&
          item.college_cds[0]['totalAdmitRate'] != null);
        break;

      // Class Rank vs ACT
      case selectedChart == 4:
        res = res.filter((item) => item.college_cds[0]['classRankTopTenth'] != null &&
          (
            item.college_cds[0]['actComposite50'] != null ||
            (
              item.college_cds[0]['actComposite25'] != null &&
              item.college_cds[0]['actComposite75'] != null
            )
          ) &&
          item.college_cds[0]['totalAdmitRate'] != null
        );
        break;

      // SAT Math vs SAT Reading/Writing
      case selectedChart == 5:
        res = res.filter((item) => (item.college_cds[0]['satMath50'] != null && item.college_cds[0]['satEvidence50'] != null) || (item.college_cds[0]['satMath25'] != null && item.college_cds[0]['satMath75'] != null && item.college_cds[0]['satEvidence25'] != null && item.college_cds[0]['satEvidence75'] != null));
        break;

      // ACT Math vs ACT English
      case selectedChart == 6:
        res = res.filter((item) => (item.college_cds[0]['actMath50'] != null && item.college_cds[0]['actEnglish50'] != null) || (item.college_cds[0]['actMath25'] != null && item.college_cds[0]['actMath75'] != null && item.college_cds[0]['actEnglish25'] != null && item.college_cds[0]['actEnglish75'] != null));
        break;

      // SAT submit vs ACT submit
      case selectedChart == 7:
        res = res.filter((item) => (item.college_cds[0]['submitSATPercent'] != null && item.college_cds[0]['submitACTPercent'] != null));
        break;

      default:
        res = res.filter((item) => item.college_cds[0]['averageGPA'] != null &&
          (
            item.college_cds[0]['satComposite50'] != null ||
            (
              item.college_cds[0]['satComposite25'] != null &&
              item.college_cds[0]['satComposite75'] != null
            )
          ) &&
          item.college_cds[0]['totalAdmitRate'] != null);
    }

    let insufficient = colleges.filter((item) => !res.includes(item))

    insufficient = insufficient.filter(institutionTypeFilter).filter(majorFilter).filter(collegeSetFilter)

    res = res.filter(institutionTypeFilter).filter(majorFilter).filter(collegeSetFilter)

    // let olderData = res.filter((item) => (
    //   (item.college_cds[0].year !== latestYear)
    // ))

    // Per #165, part 2:
    let olderData = res.filter((item) => (
      (item.college_cds[0].year < 2022)
    ))

    setInsufficientDataSet(insufficient)
    setOlderDataSet(olderData)
    setCDSAvailableSet(res)
    setLoadingComplete(true)

  }, [institutionTypeFilter, majorFilter, collegeSetFilter, selectedChart, showRelatedMajors, collegeSetOptions])

  const [chartOptionParams, setChartOptionParams] = useState('')
  const [collegeSetParams, setCollegeSetParams] = useState('')

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    params.set('chart', `${selectedChart}`)

    setChartOptionParams(`${params.toString()}`)

  }, [selectedChart])

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    params.set('collegeSet', `${selectedCollegeSet}`)

    setCollegeSetParams(`${params.toString()}`)

    const returnCollegeSetFilter = (selectedCollegeSet) => {

      // if (selectedCollegeSet.length > 0) {
      if (selectedCollegeSet > 0) {

        let collegesFromSelectedCollegeSet = collegeSetOptions.find(item => item.value == selectedCollegeSet)

        // setCollegeSetFilter((item) => {
        //   return item => selectedCollegeSet.includes(item.id)
        // })

        setCollegeSetFilter((item) => {
          return item => collegesFromSelectedCollegeSet?.colleges.includes(item.id)
        })

        // //update the older and insufficient data arrays in the notes section
        // setOlderDataSet(allOlderDataSet.filter((item) => selectedCollegeSet.includes(item.id)))

        // setInsufficientDataSet(allInsufficientDataSet.filter((item) => selectedCollegeSet.includes(item.id)))

        //update the older and insufficient data arrays in the notes section
        setOlderDataSet(allOlderDataSet.filter((item) => collegesFromSelectedCollegeSet?.colleges.includes(item.id)))

        setInsufficientDataSet(allInsufficientDataSet.filter((item) => collegesFromSelectedCollegeSet?.colleges.includes(item.id)))
      }

      else {
        setCollegeSetFilter((item) => {
          return item => item
        })

        //update the older and insufficient data arrays in the notes section to original older and insufficient arrays
        setOlderDataSet(allOlderDataSet)
        setInsufficientDataSet(allInsufficientDataSet)
      }
    }
    returnCollegeSetFilter(selectedCollegeSet)

  }, [selectedCollegeSet, collegeSetOptions])

  useEffect(() => {

    let params: string[] = []
    params = [
      returnParameterString(chartOptionParams, 'chart'),
      returnParameterString(collegeSetParams, 'collegeSet')
    ]

    setCollegeDataVisLocation(`${dictionary.pageRoutes.collegeDataVisualization}${concatLocation(params)}`)

  }, [chartOptionParams, collegeSetParams])

  useEffect(() => {

    window.history.replaceState({}, '', collegeDataVisLocation)

  }, [collegeDataVisLocation])

  const dataVizHeader = (
    <div className="dca-search-header-wrapper">
      <div className="dca-search-header-title-button-container">
        <span className='dca-search-title align-self-center'>College Data Scattergrams</span>

        <div className="dca-search-header-bttn-container-double">
          <button
            type="button"
            aria-pressed="true"
            onClick={() => navigate(dictionary.pageRoutes.overviewAdmissionsAdviceWithHash('creating-a-college-interest-list'))}
            className='dca-search-header-bttn'
          >Advice: Building A College List</button>

          <button
            type="button"
            aria-pressed="true"
            onClick={() => navigate(dictionary.pageRoutes.collegeSearch)}
            className='dca-search-header-bttn'
            style={{ marginLeft: '8px' }}
          >Explore More College Data</button>

        </div>
      </div>

      <span className="dca-search-description">Explore benchmark values for the most important quantitative admissions considerations at the top-ranked colleges.</span>

    </div>
  )

  return loadingComplete ? (
    <div>
      {dataVizHeader}

      <div className='d-flex flex-row' style={{ marginLeft: '35px', marginTop: '50px' }}>
        <Aside
          collegeSetOptions={collegeSetOptions}
          chartOptionsOptions={chartOptionsOptions}
          selectedChart={selectedChart}
          setSelectedChart={setSelectedChart}
          setSelectedType={setSelectedType}
          selectedCollegeSet={selectedCollegeSet}
          setSelectedCollegeSet={setSelectedCollegeSet}
          user={user}
          setUser={setUser}
        />
        <CollegeDataChart
          cdsAvailableSet={cdsAvailableSet}
          selectedChart={selectedChart}
          olderDataSet={olderDataSet}
          insufficientDataSet={insufficientDataSet}
          latestYear={latestYear}
          loadingComplete={loadingComplete}
          dataLoaded={dataLoaded}
          setDataLoaded={setDataLoaded}
        />
      </div>
    </div>
  ) : (
    <div>
      {dataVizHeader}
      <div className='d-flex flex-row' style={{ marginLeft: '35px', marginTop: '50px' }}>
        <div >
          <Aside
            collegeSetOptions={collegeSetOptions}
            chartOptionsOptions={chartOptionsOptions}
            selectedChart={selectedChart}
            setSelectedChart={setSelectedChart}
            setSelectedType={setSelectedType}
            selectedCollegeSet={selectedCollegeSet}
            setSelectedCollegeSet={setSelectedCollegeSet}
            user={user}
            setUser={setUser}
          /></div>

        <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginLeft: '300px' }}>
          <LoadingIndicator text={"Loading College Data Scattergrams..."} />
        </div>
      </div>
    </div>
  )
}

export { CollegeDataVisualization }























//// Test 2, loads charts on new refresh, outside of navigate
// import { useState, useEffect } from 'react'
// import axios from 'axios'
// import * as dictionary from '../components/dictionary'
// import { Aside } from '../components/CollegeDataVisualization/Aside'
// import { CollegeDataChart } from '../components/CollegeDataVisualization/CollegeDataChart'
// import { useNavigate } from 'react-router'
// import { LoadingIndicator } from '../components/LoadingIndicator'
// import { useLocation } from 'react-router'
// import { returnParameterString, concatLocation } from '../components/searchPageURLManager'

// const CollegeDataVisualization = ({ user, setUser, collegesId, prompt, setPrompt, navigateTo, setNavigateTo, collegeDataVisLocation, setCollegeDataVisLocation }) => {

//   const location = useLocation()
//   const navigate = useNavigate()
//   const collegeListLength = collegesId.length
//   // let today = new Date();
//   // let latestYear = (today.getFullYear() - 1)

//   //Per #165, hardcode 2022 - 2023 as latest year / primary data
//   let latestYear = 2022

//   const chartOptionsOptions = [
//     { value: 1, label: 'GPA vs SAT Composite' },
//     { value: 2, label: 'GPA vs ACT Composite' },
//     { value: 3, label: 'Class Rank vs SAT Composite' },
//     { value: 4, label: 'Class Rank vs ACT Composite' },
//     { value: 5, label: 'SAT Math vs SAT Reading/Writing' },
//     { value: 6, label: 'ACT Math vs ACT English' },
//     { value: 7, label: 'SAT Submission vs ACT Submission' }
//   ]

//   const getURLParam = (param) => {
//     const urlParams = new URLSearchParams(window.location.search);

//     return urlParams.get(param)
//   }

//   const [colleges, setColleges] = useState<any>([])
//   const [cdsAvailableSet, setCDSAvailableSet] = useState<any>([])
//   const [allOlderDataSet, setAllOlderDataSet] = useState<any>([])
//   const [allInsufficientDataSet, setAllInsufficientDataSet] = useState<any>([])
//   const [insufficientDataSet, setInsufficientDataSet] = useState<any>([])
//   const [olderDataSet, setOlderDataSet] = useState<any>([])
//   const [collegeSetOptions, setCollegeSetOptions] = useState<any>([])
//   const [majors, setMajors] = useState<any>([])
//   const [loadingComplete, setLoadingComplete] = useState(false)

//   // For College Data Chart component, renders chart when arrays are set
//   const [dataLoaded, setDataLoaded] = useState(false);

//   // Aside states
//   const [selectedChart, setSelectedChart] = useState(() => {
//     const initialParam = getURLParam('chart');
//     return (initialParam && Number(initialParam) <= 7) ? Number(initialParam) : 1;
//   });
//   const [selectedCollegeSet, setSelectedCollegeSet] = useState(() => {
//     const initialParam = getURLParam('collegeSet');
//     return (initialParam && Number(initialParam) <= 7) ? Number(initialParam) : 0;
//   });
//   const [selectedType, setSelectedType] = useState<any>(0)
//   const [selectedMajor, setSelectedMajor] = useState<any>('')

//   // Aside filters
//   const [collegeSetFilter, setCollegeSetFilter] = useState<any>((item) => {
//     return item => item
//   })
//   const [institutionTypeFilter, setInstitutionTypeFilter] = useState<any>((item) => {
//     return item => item
//   })
//   const [majorFilter, setMajorFilter] = useState<any>((item) => {
//     return item => item
//   })
//   const [showRelatedMajors, setShowRelatedMajors] = useState<any>(false)

//   const fetchData = async () => {

//     try {

//       loadingComplete && setLoadingComplete(false);

//       const collegesAxios = await axios.get(`${dictionary.URL}/colleges/0-to-${collegeListLength}`);
//       const collegeSetAxios = await axios.get(`${dictionary.URL}/collegeset/`);
//       const majorsAxios = await axios.get(`${dictionary.URL}/majors/`)

//       // const [collegeRes, collegesetRes, majorsRes] = await Promise.all([
//       //   collegesAxios,
//       //   collegeSetAxios,
//       //   majorsAxios
//       // ])

//       console.log('full collegesAxios', collegesAxios)
//       console.log('fetchData running. collegesAxios data:', collegesAxios.data)

//       let sufficient = collegesAxios.data.filter((item) =>
//         item.college_cds != null &&
//         item.college_cds[0] != null
//       );

//       let resForCDSAvailable = sufficient.filter((item) => item.college_cds[0]['averageGPA'] != null &&
//         (
//           item.college_cds[0]['satComposite50'] != null ||
//           (
//             item.college_cds[0]['satComposite25'] != null &&
//             item.college_cds[0]['satComposite75'] != null
//           ) ||
//           (
//             item.college_cds[0]['satEvidence50'] != null &&
//             item.college_cds[0]['satMath50'] != null
//           )
//         ))

//       let insufficient = sufficient.filter((item) => !resForCDSAvailable.includes(item))

//       let olderData = resForCDSAvailable.filter((item) => (
//         (item.college_cds[0].year < 2022)
//       ))

//       let collegeSetArr: any = []
//       collegeSetArr.push({ value: 0, label: 'All' })
//       collegeSetAxios.data.forEach((res, index) => {
//         collegeSetArr.push({ value: index + 1, label: res.name, colleges: res.colleges })
//       });

//       await Promise.all([
//         setInsufficientDataSet(insufficient),
//         setAllInsufficientDataSet(insufficient),
//         setColleges(sufficient),
//         setCDSAvailableSet(resForCDSAvailable),
//         setOlderDataSet(olderData),
//         setAllOlderDataSet(olderData),
//         setCollegeSetOptions(collegeSetArr),
//         setMajors(majorsAxios.data)
//       ])
//     } catch (error) {
//       console.log('Error grabbing College Data Visualization data:', error)
//     }

//   }

//   const setDataSets = (colleges) => {

//     if (colleges.length > 0) {

//       loadingComplete && setLoadingComplete(false)

//       let res = colleges

//       switch (true) {
//         // GPA vs SAT Composite
//         case selectedChart == 1:
//           res = res.filter((item) => item.college_cds[0]['averageGPA'] != null &&
//             (
//               item.college_cds[0]['satComposite50'] != null ||
//               (
//                 item.college_cds[0]['satComposite25'] != null &&
//                 item.college_cds[0]['satComposite75'] != null
//               ) ||
//               (
//                 item.college_cds[0]['satEvidence50'] != null &&
//                 item.college_cds[0]['satMath50'] != null
//               )
//             ));
//           break;

//         // GPA vs ACT Composite
//         case selectedChart == 2:
//           res = res.filter((item) => item.college_cds[0]['averageGPA'] != null &&
//             (
//               item.college_cds[0]['actComposite50'] != null ||
//               (
//                 item.college_cds[0]['actComposite25'] != null &&
//                 item.college_cds[0]['actComposite75'] != null
//               )
//             ) &&
//             item.college_cds[0]['totalAdmitRate'] != null
//           );
//           break;

//         // Class Rank vs SAT
//         case selectedChart == 3:
//           res = res.filter((item) => item.college_cds[0]['classRankTopTenth'] != null &&
//             (
//               item.college_cds[0]['satComposite50'] != null ||
//               (
//                 item.college_cds[0]['satComposite25'] != null &&
//                 item.college_cds[0]['satComposite75'] != null
//               ) ||
//               (
//                 item.college_cds[0]['satEvidence50'] != null &&
//                 item.college_cds[0]['satMath50'] != null
//               )
//             ) &&
//             item.college_cds[0]['totalAdmitRate'] != null);
//           break;

//         // Class Rank vs ACT
//         case selectedChart == 4:
//           res = res.filter((item) => item.college_cds[0]['classRankTopTenth'] != null &&
//             (
//               item.college_cds[0]['actComposite50'] != null ||
//               (
//                 item.college_cds[0]['actComposite25'] != null &&
//                 item.college_cds[0]['actComposite75'] != null
//               )
//             ) &&
//             item.college_cds[0]['totalAdmitRate'] != null
//           );
//           break;

//         // SAT Math vs SAT Reading/Writing
//         case selectedChart == 5:
//           res = res.filter((item) => (item.college_cds[0]['satMath50'] != null && item.college_cds[0]['satEvidence50'] != null) || (item.college_cds[0]['satMath25'] != null && item.college_cds[0]['satMath75'] != null && item.college_cds[0]['satEvidence25'] != null && item.college_cds[0]['satEvidence75'] != null));
//           break;

//         // ACT Math vs ACT English
//         case selectedChart == 6:
//           res = res.filter((item) => (item.college_cds[0]['actMath50'] != null && item.college_cds[0]['actEnglish50'] != null) || (item.college_cds[0]['actMath25'] != null && item.college_cds[0]['actMath75'] != null && item.college_cds[0]['actEnglish25'] != null && item.college_cds[0]['actEnglish75'] != null));
//           break;

//         // SAT submit vs ACT submit
//         case selectedChart == 7:
//           res = res.filter((item) => (item.college_cds[0]['submitSATPercent'] != null && item.college_cds[0]['submitACTPercent'] != null));
//           break;

//         default:
//           res = res.filter((item) => item.college_cds[0]['averageGPA'] != null &&
//             (
//               item.college_cds[0]['satComposite50'] != null ||
//               (
//                 item.college_cds[0]['satComposite25'] != null &&
//                 item.college_cds[0]['satComposite75'] != null
//               )
//             ) &&
//             item.college_cds[0]['totalAdmitRate'] != null);
//       }

//       let insufficient = colleges.filter((item) => !res.includes(item))

//       insufficient = insufficient.filter(institutionTypeFilter).filter(majorFilter).filter(collegeSetFilter)

//       res = res.filter(institutionTypeFilter).filter(majorFilter).filter(collegeSetFilter)

//       let olderData = res.filter((item) => (
//         (item.college_cds[0].year < 2022)
//       ))

//       setInsufficientDataSet(insufficient)
//       setOlderDataSet(olderData)
//       setCDSAvailableSet(res)
//       setLoadingComplete(true)
//     }
//   }

//   useEffect(() => {

//     fetchData();

//   }, [collegesId])

//   useEffect(() => {

//     console.log('attempt to run setData sets with:', colleges)
//     setDataSets(colleges)

//   }, [colleges, institutionTypeFilter, majorFilter, collegeSetFilter, selectedChart, showRelatedMajors, collegeSetOptions])

//   const [chartOptionParams, setChartOptionParams] = useState('')
//   const [collegeSetParams, setCollegeSetParams] = useState('')

//   useEffect(() => {
//     const returnTypeFilter = (selectedType) => {
//       switch (selectedType) {
//         case 0:
//           setInstitutionTypeFilter((item) => {
//             return item => item
//           })
//           break;

//         case 1:
//           setInstitutionTypeFilter((item) => {
//             return item => item.type === 1
//           })
//           break;

//         case 2:
//           setInstitutionTypeFilter((item) => {
//             return item => [2, 3].includes(item.type)
//           })
//           break;

//         default:
//           setInstitutionTypeFilter((item) => {
//             return item => item
//           })
//       };
//     };
//     returnTypeFilter(selectedType)

//     const returnMajorFilter = (selectedMajor) => { }
//     returnMajorFilter(selectedMajor)

//   }, [selectedType, selectedMajor])

//   useEffect(() => {

//     let params = new URLSearchParams(location.search);
//     params.set('chart', `${selectedChart}`)

//     setChartOptionParams(`${params.toString()}`)

//   }, [selectedChart])

//   useEffect(() => {

//     let params = new URLSearchParams(location.search);
//     params.set('collegeSet', `${selectedCollegeSet}`)

//     setCollegeSetParams(`${params.toString()}`)

//     const returnCollegeSetFilter = (selectedCollegeSet) => {

//       // if (selectedCollegeSet.length > 0) {
//       if (selectedCollegeSet > 0) {

//         let collegesFromSelectedCollegeSet = collegeSetOptions.find(item => item.value == selectedCollegeSet)

//         // setCollegeSetFilter((item) => {
//         //   return item => selectedCollegeSet.includes(item.id)
//         // })

//         setCollegeSetFilter((item) => {
//           return item => collegesFromSelectedCollegeSet?.colleges.includes(item.id)
//         })

//         // //update the older and insufficient data arrays in the notes section
//         // setOlderDataSet(allOlderDataSet.filter((item) => selectedCollegeSet.includes(item.id)))

//         // setInsufficientDataSet(allInsufficientDataSet.filter((item) => selectedCollegeSet.includes(item.id)))

//         //update the older and insufficient data arrays in the notes section
//         setOlderDataSet(allOlderDataSet.filter((item) => collegesFromSelectedCollegeSet?.colleges.includes(item.id)))

//         setInsufficientDataSet(allInsufficientDataSet.filter((item) => collegesFromSelectedCollegeSet?.colleges.includes(item.id)))
//       }

//       else {
//         setCollegeSetFilter((item) => {
//           return item => item
//         })

//         //update the older and insufficient data arrays in the notes section to original older and insufficient arrays
//         setOlderDataSet(allOlderDataSet)
//         setInsufficientDataSet(allInsufficientDataSet)
//       }
//     }
//     returnCollegeSetFilter(selectedCollegeSet)

//   }, [selectedCollegeSet, collegeSetOptions])

//   useEffect(() => {

//     let params: string[] = []
//     params = [
//       returnParameterString(chartOptionParams, 'chart'),
//       returnParameterString(collegeSetParams, 'collegeSet')
//     ]

//     setCollegeDataVisLocation(`${dictionary.pageRoutes.collegeDataVisualization}${concatLocation(params)}`)

//   }, [chartOptionParams, collegeSetParams])

//   useEffect(() => {

//     window.history.replaceState({}, '', collegeDataVisLocation)

//   }, [collegeDataVisLocation])

//   const dataVizHeader = (
//     <div className="dca-search-header-wrapper">
//       <div className="dca-search-header-title-button-container">
//         <span className='dca-search-title align-self-center'>College Data Scattergrams</span>

//         <div className="dca-search-header-bttn-container-double">
//           <button
//             type="button"
//             aria-pressed="true"
//             onClick={() => navigate(dictionary.pageRoutes.overviewWithId('admissions_advice#creating_a_college_interest_list'))}
//             className='dca-search-header-bttn'
//           >Advice: Building A College List</button>

//           <button
//             type="button"
//             aria-pressed="true"
//             onClick={() => navigate(dictionary.pageRoutes.collegeSearch)}
//             className='dca-search-header-bttn'
//             style={{ marginLeft: '8px' }}
//           >Explore More College Data</button>

//         </div>
//       </div>

//       <span className="dca-search-description">Explore benchmark values for the most important quantitative admissions considerations at the top-ranked colleges.</span>

//     </div>
//   )

//   return loadingComplete ? (
//     <div>
//       {dataVizHeader}

//       <div className='d-flex flex-row' style={{ marginLeft: '35px', marginTop: '50px' }}>
//         <Aside
//           collegeSetOptions={collegeSetOptions}
//           chartOptionsOptions={chartOptionsOptions}
//           selectedChart={selectedChart}
//           setSelectedChart={setSelectedChart}
//           setSelectedType={setSelectedType}
//           selectedCollegeSet={selectedCollegeSet}
//           setSelectedCollegeSet={setSelectedCollegeSet}
//           user={user}
//           setUser={setUser}
//         />
//         <CollegeDataChart
//           cdsAvailableSet={cdsAvailableSet}
//           selectedChart={selectedChart}
//           olderDataSet={olderDataSet}
//           insufficientDataSet={insufficientDataSet}
//           latestYear={latestYear}
//           loadingComplete={loadingComplete}
//           dataLoaded={dataLoaded}
//           setDataLoaded={setDataLoaded}
//         />
//       </div>
//     </div>
//   ) : (
//     <div>
//       {dataVizHeader}
//       <div className='d-flex flex-row' style={{ marginLeft: '35px', marginTop: '50px' }}>
//         <div >
//           <Aside
//             collegeSetOptions={collegeSetOptions}
//             chartOptionsOptions={chartOptionsOptions}
//             selectedChart={selectedChart}
//             setSelectedChart={setSelectedChart}
//             setSelectedType={setSelectedType}
//             selectedCollegeSet={selectedCollegeSet}
//             setSelectedCollegeSet={setSelectedCollegeSet}
//             user={user}
//             setUser={setUser}
//           /></div>

//         <div className="d-flex flex-column justify-content-center align-items-center" style={{ marginLeft: '300px' }}>
//           <LoadingIndicator text={"Loading College Data Scattergrams..."} />
//         </div>
//       </div>
//     </div>
//   )
// }

// export { CollegeDataVisualization }
