import { closeModalIcon, closeModalFunction, selectedTabGreen, reactGACheckoutModalController, ecPremiumAccessCode, stripePromiseKey } from "../dictionary"
import { useNavigate, useParams } from "react-router-dom";
import { pageRoutes } from "../dictionary";
import { getAuth, useAuth } from '../../modules/auth'
import { useEffect, useState } from "react";
// import { handleUpdateUserTransactions } from "../../pages/Auth/UpdateUserTransaction";
import { ECReactGAHandlers } from "../ReactGAHandlers";

import { loadStripe } from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { LoadingIndicator } from "../LoadingIndicator";

// const stripePromise = loadStripe("pk_test_51OaR0tF7MQ6RjhDC0nWRzQPB3GvQzNDyAEu4Y9Vx2S33Xr9nlfwUZfUkMoYkFA1awRmoxWPBIvtPqWhXTtAObVuh00wMn17srK");

const stripePromise = loadStripe(stripePromiseKey);

const AddCardECInsights = ({ handleSubmitFunction, insights, clientSecret, setClientSecret, ecName }) => {

  let apiToken: any = undefined

  if (getAuth()?.api_token && getAuth()?.groups) {
    apiToken = getAuth()?.api_token
  }

  useEffect(() => {
    if (getAuth()?.api_token && getAuth()?.groups) {
      apiToken = getAuth()?.api_token
    }
  }, [])

  const { ecId } = useParams()
  const [showNavAfterPayment, setShowNavAfterPayment] = useState(false)
  let navigate = useNavigate();
  const routeChange = (id) => {
    navigate(pageRoutes.ecPremiumInsightsWithId(id))
  }

  const { handleECGAEventPurchaseComplete } = ECReactGAHandlers()
  const { requestUser } = useAuth()

  return (
    <div className="modal fade" id="add_payment_method_modal">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content" style={{ minWidth: 'fit-content', maxWidth: '75vw', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', overflowY: 'auto', maxHeight: '90vh' }}>
          <div style={{ margin: '8px' }}>
            {closeModalIcon('add_payment_method', reactGACheckoutModalController(ecPremiumAccessCode))}
          </div>

          <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">

            {/* <CheckoutForm /> */}
            <div id="checkout">
              {clientSecret ? (
                <EmbeddedCheckoutProvider
                  stripe={stripePromise}
                  options={{
                    clientSecret, onComplete() {

                      const runSubmit = async () => {
                        try {

                          if (getAuth()?.api_token && getAuth()?.groups) {
                            apiToken = getAuth()?.api_token
                          }

                          await handleSubmitFunction();

                          await requestUser(apiToken)

                          await setShowNavAfterPayment(true)

                          handleECGAEventPurchaseComplete(ecId)

                        } catch (error) {

                          console.log(error)
                          throw (error)

                        }
                      }

                      setTimeout(() => {
                        runSubmit()
                      }, 2000)

                    },
                  }}
                >
                  <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
              ) : <div className="d-flex flex-column justify-content-center align-items-center" >
                <LoadingIndicator text={""} />
              </div>}
            </div>

            {showNavAfterPayment === true ? <div className="d-flex justify-content-center align-items-center" style={{ marginTop: '30px' }}><a
              role="button"
              className="btn btn-sm fw-bold align-self-center"
              style={{ background: selectedTabGreen }}
              onClick={async () => {
                await closeModalFunction("add_payment_method")
                routeChange(ecId)
              }}
            >View Premium Data</a></div> : <div></div>}


          </div>
        </div>


      </div>
    </div>


  )
}

export { AddCardECInsights }

