import { useEffect, useState } from "react"
import { gray, selectedTabGreen } from "./dictionary"
import axios from "axios"
import { URL, advisorConnectAccessCode } from "./dictionary"
import { getAuth } from "../modules/auth"
import { setNoAuthSaveButton, setNoAuthAdvisorsConnectButton, setPurchaseModal } from "./Auth/setPrompts"
import { AuthReactGAHandlers, CollegeReactGAHandlers, MajorReactGAHandlers, ECReactGAHandlers } from "./ReactGAHandlers"

let apiToken: any = undefined
let permissionGroups: any = undefined

if (getAuth()?.api_token && getAuth()?.groups) {
  apiToken = getAuth()?.api_token
  permissionGroups = getAuth()?.groups
}

export const SaveButtonInCard = () => {
  //pass onclick function as prop when ready to include user saving functionality

  const [clicked, setClicked] = useState(false)

  const handleClick = () => {
    setClicked(!clicked);
    // await saveFunction();
  }

  return (
    <button
      type="button"
      className="btn btn-sm fw-bold"
      style={{ background: clicked ? selectedTabGreen : gray, position: 'absolute', top: '15px', right: '15px' }}
      onClick={(e) => {
        e.stopPropagation();
        handleClick();
      }}
    >
      <i
        className={`fa-heart${clicked ? " fa-solid" : " fa-regular"}`}
        style={clicked ? { color: 'rgba(123, 212, 125, 1)' } : {}}
      ></i>
      Save
    </button>
  )
}

export const SaveButtonInline = () => {
  //pass onclick function as prop when ready to include user saving functionality

  const [clicked, setClicked] = useState(false)

  return (
    <button
      type="button"
      className="btn btn-sm fw-bold dca-save-button-inline"
      style={{ background: clicked ? selectedTabGreen : gray }}
      onClick={(e) => {
        e.stopPropagation();
        setClicked(!clicked)
      }}
    >
      <i
        className={`fa-heart${clicked ? " fa-solid" : " fa-regular"}`}
        style={clicked ? { color: 'rgba(123, 212, 125, 1)' } : {}}
      ></i>
      Save
    </button>
  )
}

// Creating Connect button within file to use setter functions
export const ConnectButtonInCard = () => {
  //pass onclick function as prop when ready to include user saving functionality

  return (
    <button
      type="button"
      className="btn btn-sm dca-connect-button-card"
      data-bs-toggle="modal"
      data-bs-target="#purchase_modal"
    // onClick={(e) => {
    //   e.stopPropagation();
    //   // setPurchaseModal(setPurchaseID, advisorConnectAccessCode);
    //   setPurchaseID(advisorConnectAccessCode)
    //   setAdvisorID(advisorID)
    //   handleGAEventPurchaseModalOpen(advisorID)
    // }}
    >
      Connect
    </button>
  )
}

export const ConnectButtonInCardNoAuth = ({ setPrompt, setNavigateTo }) => {

  const { handleGAEventSignInModalOpen } = AuthReactGAHandlers()

  return (
    <div>
      <a
        type="button"
        className="btn btn-sm dca-connect-button-card"
        data-bs-toggle="modal"
        href="#login_modal"
        onClick={(e) => {
          e.stopPropagation();
          setNoAuthAdvisorsConnectButton(setPrompt, setNavigateTo);
          handleGAEventSignInModalOpen('forced')
        }}
      ><i className="bi bi-lock align-self-center" style={{ color: 'black' }}></i>Connect
      </a>
    </div>
  )

}

export const ConnectButtonNoAuth = ({ setPrompt, setNavigateTo }) => {

  const { handleGAEventSignInModalOpen } = AuthReactGAHandlers()

  return (
    <div>
      <a
        type="button"
        className="btn btn-sm dca-connect-button-card-detail"
        data-bs-toggle="modal"
        href="#login_modal"
        onClick={(e) => {
          e.stopPropagation();
          setNoAuthAdvisorsConnectButton(setPrompt, setNavigateTo);
          handleGAEventSignInModalOpen('forced')
        }}
      ><i className="bi bi-lock align-self-center" style={{ color: 'black' }}></i>Connect
      </a>
    </div>
  )

}

// College
export const SaveButtonCollegeCard = ({ collegeId, list, user, setUserCollegeInterest, testScoreStrategy = false }) => {

  const {
    handleGAEventSearchSave,
    handleTestScoreStrategyGAEventSave
  } = CollegeReactGAHandlers()
  const [check, setCheck] = useState(false)

  const checkCollegeList = () => {
    if (list && list.find((item) => item.college_id === collegeId && item.user_profile_id === user.user_id)) {
      setCheck(true)
    }
    else {
      setCheck(false)
    }
  }

  const handleSaveCollege = async () => {
    try {
      const post = await axios.post(`${URL}/user_college_interest/create/`, {
        user_profile_id: user.user_id,
        college_id: collegeId,
        fit: 1
      })

      console.log('Post successful,', post.data)

      const res = await axios.get(`${URL}/user_college_interest`)

      res.data.filter((item) => item.user_profile_id === user.user_id)
      setUserCollegeInterest(res.data.filter((item) => item.user_profile_id === user.user_id))

      console.log('User college list retrieved,', res.data)

    } catch (error) {
      console.log(error)
      throw (error)
    } finally {
      testScoreStrategy == true ? handleTestScoreStrategyGAEventSave(collegeId) : handleGAEventSearchSave(collegeId)
    }

  }

  const handleDeleteCollege = async () => {

    if (list && list.find((item) => item.college_id === collegeId && item.user_profile_id === user.user_id)) {

      let collegeToRemove = list.find((item) => item.college_id === collegeId && item.user_profile_id === user.user_id)

      let pk = collegeToRemove.id

      const del = await axios.delete(`${URL}/user_college_interest/delete/${pk}`)

      console.log('College removed from list,', del.data)

      const res = await axios.get(`${URL}/user_college_interest`)

      res.data.filter((item) => item.user_profile_id === user.user_id)
      setUserCollegeInterest(res.data.filter((item) => item.user_profile_id === user.user_id))

      console.log('User college list retrieved,', res.data)
    }

    else {
      return alert('Could not find pk number, try again later')
    }
  }

  const handleClick = async (check) => {
    check ? await handleDeleteCollege() : await handleSaveCollege()
  }

  useEffect(() => {
    checkCollegeList()
  }, [list])

  return (
    <button
      type="button"
      className="btn btn-sm fw-bold dca-save-button-card"
      style={{ background: check ? selectedTabGreen : gray }}
      onClick={(e) => {
        e.stopPropagation();
        handleClick(check)
      }}
    >
      <i
        className={`fa-heart${check ? " fa-solid" : " fa-regular"}`}
        style={check ? { color: 'rgba(123, 212, 125, 1)' } : {}}
      ></i>
      {check ? 'Saved' : 'Save'}
    </button>
  )
}

export const SaveButtonCollegeInline = ({ collegeId, list, user, setUserCollegeInterest }) => {

  const [check, setCheck] = useState(false)
  const { handleGAEventDetailsSave } = CollegeReactGAHandlers()

  const checkCollegeList = () => {
    if (list && list.find((item) => item.college_id === collegeId && item.user_profile_id === user.user_id)) {
      setCheck(true)
    }
    else {
      setCheck(false)
    }
  }

  const handleSaveCollege = async () => {
    try {
      const post = await axios.post(`${URL}/user_college_interest/create/`, {
        user_profile_id: user.user_id,
        college_id: collegeId,
        fit: 1
      })

      console.log('Post successful,', post.data)

      const res = await axios.get(`${URL}/user_college_interest`)

      res.data.filter((item) => item.user_profile_id === user.user_id)
      setUserCollegeInterest(res.data.filter((item) => item.user_profile_id === user.user_id))

      console.log('User college list retrieved,', res.data)

    } catch (error) {
      console.log(error)
      throw (error)
    } finally {
      handleGAEventDetailsSave(collegeId)
    }

  }

  const handleDeleteCollege = async () => {

    if (list && list.find((item) => item.college_id === collegeId && item.user_profile_id === user.user_id)) {
      let collegeToRemove = list.find((item) => item.college_id === collegeId && item.user_profile_id === user.user_id)

      let pk = collegeToRemove.id

      const del = await axios.delete(`${URL}/user_college_interest/delete/${pk}`)

      console.log('College removed from list,', del.data)

      const res = await axios.get(`${URL}/user_college_interest`)

      res.data.filter((item) => item.user_profile_id === user.user_id)
      setUserCollegeInterest(res.data.filter((item) => item.user_profile_id === user.user_id))

      console.log('User college list retrieved,', res.data)
    }

    else {
      return alert('Could not find pk number, try again later')
    }
  }

  const handleClick = async (check) => {
    check ? await handleDeleteCollege() : await handleSaveCollege()
  }

  useEffect(() => {
    checkCollegeList()
  }, [list])

  return (
    <button
      type="button"
      className="btn btn-sm fw-bold dca-save-button-inline"
      style={{ background: check ? selectedTabGreen : gray }}
      onClick={(e) => {
        e.stopPropagation();
        handleClick(check)
      }}
    >
      <i
        className={`fa-heart${check ? " fa-solid" : " fa-regular"}`}
        style={check ? { color: 'rgba(123, 212, 125, 1)' } : {}}
      ></i>
      {check ? 'Saved' : 'Save'}
    </button>
  )
}

export const SaveButtonCardNoAuth = ({ setPrompt, setNavigateTo }) => {

  const { handleGAEventSignInModalOpen } = AuthReactGAHandlers()

  return (
    <div>
      <a
        type="button"
        className="btn btn-sm fw-bold dca-save-button-card"
        style={{ background: gray }}
        data-bs-toggle="modal"
        href="#login_modal"
        onClick={(e) => {
          e.stopPropagation();
          setNoAuthSaveButton(setPrompt, setNavigateTo);
          handleGAEventSignInModalOpen('forced')
        }}
      >
        <i
          className="fa-heart fa-regular"
        ></i>Save
      </a>
    </div>
  )
}

export const SaveButtonInlineNoAuth = ({ setPrompt, setNavigateTo }) => {


  const { handleGAEventSignInModalOpen } = AuthReactGAHandlers()

  return (
    <div>
      <a
        type="button"
        className="btn btn-sm fw-bold dca-save-button-inline"
        style={{ background: gray }}
        data-bs-toggle="modal"
        href="#login_modal"
        onClick={(e) => {
          e.stopPropagation();
          setNoAuthSaveButton(setPrompt, setNavigateTo);
          handleGAEventSignInModalOpen('forced')
        }}
      >
        <i
          className="fa-heart fa-regular"
        ></i>Save
      </a>
    </div>
  )
}

// Major & EC Activities
// const handleAddToUser = async (userId, data, idToAdd, dataField, setUser) => {

//   let listToPatch: number[] = [idToAdd]

//   if (data) {
//     listToPatch = [...data, idToAdd]
//   }

//   const patchData = { [dataField]: listToPatch }

//   try {
//     const patch = await axios.patch(`${URL}/user_profiles/${userId}`, patchData)

//     console.log('Patch successful,', patch.data)

//     const res = await axios.get(`${URL}/user_profiles/${userId}`)
//     setUser(res.data)
//     console.log('Updated user:', res.data);

//   } catch (error) {
//     console.log(error)
//     throw (error)
//   }
// }

// const handleRemoveFromUser = async (userId, data, dataField: string, idToRemove, setUser) => {

//   try {

//     let listToUpdate = [...data]

//     let index = listToUpdate.indexOf(idToRemove)
//     if (index > -1) {
//       listToUpdate.splice(index, 1)
//     }

//     const patchData = { [dataField]: listToUpdate }

//     const remove = await axios.patch(`${URL}/user_profiles/${userId}`, patchData)

//     console.log('Patch successful,', remove.data)

//     const res = await axios.get(`${URL}/user_profiles/${userId}`)
//     setUser(res.data)
//     console.log('Updated user:', res.data);

//   } catch (error) {
//     console.log(error)
//     throw (error)
//   }

// }

// const handleClick = async (check, userId, data, dataField: string, id, setUser) => {
//   return check ? await handleRemoveFromUser(userId, data, dataField, id, setUser) : await handleAddToUser(userId, data, id, dataField, setUser)
// }

export const SaveButtonMajorCard = ({ user, majorId, setUser, programID = undefined }) => {
  const {
    handleGAEventSearchSave,
    handleGAEventDetailsSave
  } = MajorReactGAHandlers()

  const handleAddToUser = async (userId, data, idToAdd, dataField, setUser, programID = undefined) => {

    let listToPatch: number[] = [idToAdd]

    if (data) {
      listToPatch = [...data, idToAdd]
    }

    const patchData = { [dataField]: listToPatch }

    try {
      const patch = await axios.patch(`${URL}/user_profiles/${userId}`, patchData)

      console.log('Patch successful,', patch.data)

      const res = await axios.get(`${URL}/user_profiles/${userId}`)
      setUser(res.data)
      console.log('Updated user:', res.data);

    } catch (error) {
      console.log(error)
      throw (error)
    } finally {
      programID != undefined ? handleGAEventDetailsSave(idToAdd, programID) : handleGAEventSearchSave(idToAdd)
    }
  }

  const handleRemoveFromUser = async (userId, data, dataField: string, idToRemove, setUser) => {

    try {

      let listToUpdate = [...data]

      let index = listToUpdate.indexOf(idToRemove)
      if (index > -1) {
        listToUpdate.splice(index, 1)
      }

      const patchData = { [dataField]: listToUpdate }

      const remove = await axios.patch(`${URL}/user_profiles/${userId}`, patchData)

      console.log('Patch successful,', remove.data)

      const res = await axios.get(`${URL}/user_profiles/${userId}`)
      setUser(res.data)
      console.log('Updated user:', res.data);

    } catch (error) {
      console.log(error)
      throw (error)
    }

  }

  const handleClick = async (check, userId, data, dataField: string, id, setUser, programID = undefined) => {
    return check ? await handleRemoveFromUser(userId, data, dataField, id, setUser) : await handleAddToUser(userId, data, id, dataField, setUser, programID)
  }

  const [check, setCheck] = useState(false)

  const checkMajorsList = () => {
    if (user && user.majorsInterest) {
      user.majorsInterest.includes(majorId) ? setCheck(true) : setCheck(false)
    }
  }

  useEffect(() => {
    checkMajorsList()
  }, [user])

  return (
    <button
      type="button"
      className="btn btn-sm fw-bold dca-save-button-card"
      style={{ background: check ? selectedTabGreen : gray, position: 'absolute', top: '15px', right: '15px' }}
      onClick={(e) => {
        e.stopPropagation();
        handleClick(check, user.user_id, user.majorsInterest, 'majorsInterest', majorId, setUser, programID)
      }}
    >
      <i
        className={`fa-heart${check ? " fa-solid" : " fa-regular"}`}
        style={check ? { color: 'rgba(123, 212, 125, 1)' } : {}}
      ></i>
      {check ? 'Saved' : 'Save'}
    </button>
  )
}

// export const SaveButtonMajorInLine = ({ user, majorId, setUser }) => {

//   const handleAddToUser = async (userId, data, idToAdd, dataField, setUser) => {

//     let listToPatch: number[] = [idToAdd]

//     if (data) {
//       listToPatch = [...data, idToAdd]
//     }

//     const patchData = { [dataField]: listToPatch }

//     try {
//       const patch = await axios.patch(`${URL}/user_profiles/${userId}`, patchData)

//       console.log('Patch successful,', patch.data)

//       const res = await axios.get(`${URL}/user_profiles/${userId}`)
//       setUser(res.data)
//       console.log('Updated user:', res.data);

//     } catch (error) {
//       console.log(error)
//       throw (error)
//     }
//   }

//   const handleRemoveFromUser = async (userId, data, dataField: string, idToRemove, setUser) => {

//     try {

//       let listToUpdate = [...data]

//       let index = listToUpdate.indexOf(idToRemove)
//       if (index > -1) {
//         listToUpdate.splice(index, 1)
//       }

//       const patchData = { [dataField]: listToUpdate }

//       const remove = await axios.patch(`${URL}/user_profiles/${userId}`, patchData)

//       console.log('Patch successful,', remove.data)

//       const res = await axios.get(`${URL}/user_profiles/${userId}`)
//       setUser(res.data)
//       console.log('Updated user:', res.data);

//     } catch (error) {
//       console.log(error)
//       throw (error)
//     }

//   }

//   const handleClick = async (check, userId, data, dataField: string, id, setUser) => {
//     return check ? await handleRemoveFromUser(userId, data, dataField, id, setUser) : await handleAddToUser(userId, data, id, dataField, setUser)
//   }

//   const [check, setCheck] = useState(false)

//   const checkMajorsList = () => {
//     if (user && user.majorsInterest) {
//       user.majorsInterest.includes(majorId) ? setCheck(true) : setCheck(false)
//     }
//   }

//   useEffect(() => {
//     checkMajorsList()
//   }, [user])


//   return (
//     <button
//       type="button"
//       className="btn btn-sm fw-bold dca-save-button-inline"
//       style={{ background: check ? selectedTabGreen : gray }}
//       onClick={(e) => {
//         e.stopPropagation();
//         handleClick(check, user.user_id, user.majorsInterest, 'majorsInterest', majorId, setUser)
//       }}
//     >
//       <i
//         className={`fa-heart${check ? " fa-solid" : " fa-regular"}`}
//         style={check ? { color: 'rgba(123, 212, 125, 1)' } : {}}
//       ></i>
//       {check ? 'Saved' : 'Save'}
//     </button>
//   )
// }

export const SaveButtonECActivityCard = ({ user, ecId, setUser }) => {

  const { handleGAEventSearchSave } = ECReactGAHandlers()

  const handleAddToUser = async (userId, data, idToAdd, dataField, setUser) => {

    let listToPatch: number[] = [idToAdd]

    if (data) {
      listToPatch = [...data, idToAdd]
    }

    const patchData = { [dataField]: listToPatch }

    try {
      const patch = await axios.patch(`${URL}/user_profiles/${userId}`, patchData)

      console.log('Patch successful,', patch.data)

      const res = await axios.get(`${URL}/user_profiles/${userId}`)
      setUser(res.data)
      console.log('Updated user:', res.data);

    } catch (error) {
      console.log(error)
      throw (error)
    } finally {
      handleGAEventSearchSave(idToAdd)
    }
  }

  const handleRemoveFromUser = async (userId, data, dataField: string, idToRemove, setUser) => {

    try {

      let listToUpdate = [...data]

      let index = listToUpdate.indexOf(idToRemove)
      if (index > -1) {
        listToUpdate.splice(index, 1)
      }

      const patchData = { [dataField]: listToUpdate }

      const remove = await axios.patch(`${URL}/user_profiles/${userId}`, patchData)

      console.log('Patch successful,', remove.data)

      const res = await axios.get(`${URL}/user_profiles/${userId}`)
      setUser(res.data)
      console.log('Updated user:', res.data);

    } catch (error) {
      console.log(error)
      throw (error)
    }

  }

  const handleClick = async (check, userId, data, dataField: string, id, setUser) => {
    return check ? await handleRemoveFromUser(userId, data, dataField, id, setUser) : await handleAddToUser(userId, data, id, dataField, setUser)
  }

  const [check, setCheck] = useState(false)

  const checkECActivitiesList = () => {
    if (user && user.activitiesInterest) {
      user.activitiesInterest.includes(ecId) ? setCheck(true) : setCheck(false)
    }
  }

  useEffect(() => {
    checkECActivitiesList()
  }, [user])


  return (
    <button
      type="button"
      className="btn btn-sm fw-bold dca-save-button-card"
      style={{ background: check ? selectedTabGreen : gray }}
      onClick={(e) => {
        e.stopPropagation();
        handleClick(check, user.user_id, user.activitiesInterest, 'activitiesInterest', ecId, setUser)
      }}
    >
      <i
        className={`fa-heart${check ? " fa-solid" : " fa-regular"}`}
        style={check ? { color: 'rgba(123, 212, 125, 1)' } : {}}
      ></i>
      Save
    </button>
  )
}

export const SaveButtonECActivityInline = ({ user, ecId, setUser }) => {

  const { handleGAEventDetailsSave } = ECReactGAHandlers()

  const handleAddToUser = async (userId, data, idToAdd, dataField, setUser) => {


    let listToPatch: number[] = [idToAdd]

    if (data) {
      listToPatch = [...data, idToAdd]
    }

    const patchData = { [dataField]: listToPatch }

    try {
      const patch = await axios.patch(`${URL}/user_profiles/${userId}`, patchData)

      console.log('Patch successful,', patch.data)

      const res = await axios.get(`${URL}/user_profiles/${userId}`)
      setUser(res.data)
      console.log('Updated user:', res.data);

    } catch (error) {
      console.log(error)
      throw (error)
    } finally {
      handleGAEventDetailsSave(idToAdd)
    }
  }

  const handleRemoveFromUser = async (userId, data, dataField: string, idToRemove, setUser) => {

    try {

      let listToUpdate = [...data]

      let index = listToUpdate.indexOf(idToRemove)
      if (index > -1) {
        listToUpdate.splice(index, 1)
      }

      const patchData = { [dataField]: listToUpdate }

      const remove = await axios.patch(`${URL}/user_profiles/${userId}`, patchData)

      console.log('Patch successful,', remove.data)

      const res = await axios.get(`${URL}/user_profiles/${userId}`)
      setUser(res.data)
      console.log('Updated user:', res.data);

    } catch (error) {
      console.log(error)
      throw (error)
    }

  }

  const handleClick = async (check, userId, data, dataField: string, id, setUser) => {
    return check ? await handleRemoveFromUser(userId, data, dataField, id, setUser) : await handleAddToUser(userId, data, id, dataField, setUser)
  }

  const [check, setCheck] = useState(false)

  const checkECActivitiesList = () => {
    if (user && user.activitiesInterest) {
      user.activitiesInterest.includes(ecId) ? setCheck(true) : setCheck(false)
    }
  }

  useEffect(() => {
    checkECActivitiesList()
  }, [user])


  return (
    <button
      type="button"
      className="btn btn-sm fw-bold dca-save-button-inline"
      style={{ background: check ? selectedTabGreen : gray }}
      onClick={(e) => {
        e.stopPropagation();
        handleClick(check, user.user_id, user.activitiesInterest, 'activitiesInterest', ecId, setUser)
      }}
    >
      <i
        className={`fa-heart${check ? " fa-solid" : " fa-regular"}`}
        style={check ? { color: 'rgba(123, 212, 125, 1)' } : {}}
      ></i>
      Save
    </button>
  )
}
