import { useState } from 'react'
import { selectedTabGreen, unselectedTabGray } from '../dictionary'
import { ECDetailTabContent } from './ECDetailTabContent'
import { ECReactGAHandlers } from '../ReactGAHandlers'

const ECDetailTabs = ({ ec }) => {

  const { handleGAEventTabClick } = ECReactGAHandlers()

  const tabOptions = ['Activity Information', 'Logistics', 'Application Parameters']
  const [selectedTab, setSelectedTab] = useState(tabOptions[0])

  const handleSelectedTab = (val) => {
    setSelectedTab(val);
    handleGAEventTabClick(ec.id, val)
  }

  return (
    <div className='mt-5'>

      <div className='d-flex flex-row'>

        {tabOptions.map((tab, index) => (
          <div
            key={tab}
            className="d-flex rounded rounded-3 fw-bold justify-content-center align-items-center"
            style={{ backgroundColor: selectedTab === tabOptions[index] ? selectedTabGreen : unselectedTabGray, height: '70px', cursor: 'pointer', border: '1px rgba(219, 223, 233, 0.4) dashed', borderRadius: '50%', display: 'inline-block', marginLeft: index === 0 ? '0px' : '15px' }}
            onClick={() => handleSelectedTab(tabOptions[index])}
          >
            <span className='m-3 text-nowrap'>{tab}</span>
          </div>))}

      </div>

      <ECDetailTabContent
        ec={ec}
        selectedTab={selectedTab}
        tabOptions={tabOptions}
      />

    </div>
  )
}

export { ECDetailTabs }