import { useEffect, useRef } from 'react'
import noUiSlider from 'nouislider'
import { useLocation } from 'react-router';

const MajorPopularitySearch = ({ setPopularityMin, setPopularityMax }) => {

  const location = useLocation()
  const sliderRef = useRef(null);

  useEffect(() => {

    let params = new URLSearchParams(location.search);
    let popularityParam = params.get('popularity');

    if (popularityParam) {
      const popularityVals = popularityParam.split(',').map(admit => (parseInt(admit)))
      setPopularityMin(popularityVals[0])
      setPopularityMax(popularityVals[1])
    }

    else {
      setPopularityMin(0)
      setPopularityMax(100)
    }

  }, [location.search]);

  useEffect(() => {



    if (!sliderRef.current) {

      const slider: any = document.querySelector("#kt_slider_popularity");
      let params = new URLSearchParams(location.search);
      let popularityParam = params.get('popularity');

      if (popularityParam) {
        const popularityVals = popularityParam.split(',').map(admit => (parseInt(admit)))

        noUiSlider.create(slider, {
          start: [popularityVals[0], popularityVals[1]],
          step: 1,
          range: {
            "min": 0,
            "max": 10
          },
          format: {
            from: function (value) {
              return parseInt(value);
            },
            to: function (value) {
              return value
            }
          },
          tooltips: [true, true]
        });

      }

      else {
        noUiSlider.create(slider, {
          start: [0, 10],
          step: 1,
          range: {
            "min": 0,
            "max": 10
          },
          format: {
            from: function (value) {
              return parseInt(value);
            },
            to: function (value) {
              return value
            }
          },
          tooltips: [true, true]
        });
      }

      slider.noUiSlider.on("set", function (values, handle) {
        if (handle === 0) {
          setPopularityMin(parseInt(values[0]))
        }
        if (handle === 1) {
          setPopularityMax(parseInt(values[1]))
        }

      });

      sliderRef.current = slider;

    }


  }, [])

  return (
    <div className='mt-4 d-flex flex-column'>
      <div className='dca-aside-header'>POPULARITY</div>
      <div className="mb-0" style={{ marginTop: '50px', zIndex: '1' }}>
        <div id="kt_slider_popularity"></div>
      </div>
    </div>
  )
}

export { MajorPopularitySearch }